import React from "react";
import NumberFormat from "react-number-format";
import PropTypes from "prop-types";

const NumberFormatCustom = (props) => {
	const {
		inputRef, onChange, prefix, id: componentId, decimalScale, ...other
	} = props;

	return (
		<NumberFormat
			{...other}
			id={componentId}
			getInputRef={inputRef}
			onValueChange={(values) => {
				onChange({
					target: {
						id: componentId,
						value: values.value
					}
				});
			}}
			prefix={prefix}
			thousandSeparator
			decimalScale={decimalScale}
		/>
	);
};

NumberFormatCustom.propTypes = {
	inputRef: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
	prefix: PropTypes.string
};

export default NumberFormatCustom;
