import * as actionTypes from "../constants/actionTypes";
import localStorageApi from "../api/LocalStorageApi";
import * as common from "../constants/common";

// get Accountant Details 
export function getAccountantDetails() {
 
	return {
		type: actionTypes.ACTION_ACCOUNTANT_DETAILS,
		payload: {}
	};
}

export function getAccountantDetailsSuccess(accountantDetails, messageCode, message) {
	const userData = localStorageApi.getItem(common.STORAGE_SESSION_NAME);
	delete userData.message
	let newUserData = {
		...userData,
		...accountantDetails
	}
	if(accountantDetails && accountantDetails.login){
		newUserData.enabled2FA=accountantDetails.login.enabled2FA;
		newUserData.enable2FA=accountantDetails.login.enabled2FA;
	}
	localStorageApi.setItem(common.STORAGE_SESSION_NAME, newUserData);
	return {
		type: actionTypes.ACTION_ACCOUNTANT_DETAILS_SUCCESS,
		payload: {
			newUserData,
			accountantDetails: accountantDetails,
			messageCode,
			message
		}
	};
}

export function getAccountantDetailsFailure(messageCode, errorMessage) {

	return {
		type: actionTypes.ACTION_ACCOUNTANT_DETAILS_FAILURE,
		error: true,
		payload: {
			messageCode,
			errorMessage
		}
	};
}

// update accountant
export function updateAccountantDetails(accountantDetailsData) {

	return {
		type: actionTypes.ACTION_ACCOUNTANT_DETAILS_UPDATE,
		payload: {
			accountantDetailsData
		}
	};
}

export function updateAccountantDetailsSuccess(messageCode, message) {

	return {
		type: actionTypes.ACTION_ACCOUNTANT_DETAILS_UPDATE_SUCCESS,
		payload: {
			messageCode,
			message
		}
	};
}

export function updateAccountantDetailsFailure(messageCode, errorMessage) {

	return {
		type: actionTypes.ACTION_ACCOUNTANT_DETAILS_UPDATE_FAILURE,
		error: true,
		payload: {
			messageCode,
			errorMessage
		}
	};
}

// cleanMessage
export function cleanMessage() {
	return {
		type: actionTypes.ACTION_ACCOUNTANT_DETAILS_UPDATE_MESSAGE,
		payload: {
			errorMessage: null,
			messageCode: null,
			message: null
		}
	};
}