import * as actionTypes from "../../constants/actionTypes";
import employerInitialState, { employerDefaultData } from "./EmployerInitialState";

const employerReducer = (state = employerInitialState, action) => {
	switch (action.type) {
		case actionTypes.ACTION_FY_UPDATE_ACTIVE: {
			return {
				...state,
				isLoading: true,
				error: false,
				success: false,
			};
		}
		case actionTypes.ACTION_FY_UPDATE_ACTIVE_SUCCESS: {
			return {
				...state,
				isLoading: false,
				error: false,
				success: true,
				message: action.payload.message,
				messageCode: 2000,
			};
		}
		case actionTypes.ACTION_FY_UPDATE_ACTIVE_FAILURE: {
			return {
				...state,
				isLoading: false,
				error: true,
				success: false,
				message: action.payload.message,
				messageCode: 102,  // legacy
			};
		}
		case actionTypes.ACTION_FY_GET_AVAILABLE: {
			return {
				...state,
				isLoading: true,
				error: false,
				success: false,
			};
		}
		case actionTypes.ACTION_FY_GET_AVAILABLE_SUCCESS: {
			return {
				...state,
				isLoading: false,
				error: false,
				success: true,
				fydata: action.payload.fydata,
			};
		}
		case actionTypes.ACTION_FY_GET_AVAILABLE_FAILURE: {
			return {
				...state,
				isLoading: false,
				error: true,
				success: false,
				message: action.payload.message,
			};
		}

		// GENERATE Email
		case actionTypes.ACTION_EMPLOYER_CLEAN_DATA: {
			return {
				...state,
				employerData: {
					...employerDefaultData
				}
			}
		}
	
		case actionTypes.ACTION_EMPLOYER_EMAIL_GENERATE: {
			return {
				...state,
				isLoading: true
			};
		}
		case actionTypes.ACTION_EMPLOYER_EMAIL_GENERATE_SUCCESS: {

			return {
				...state,
				error: true,
				isLoading: false,
				messageCode: action.payload.messageCode,
				message: action.payload.message
			};
		}
		case actionTypes.ACTION_EMPLOYER_EMAIL_GENERATE_FAILURE: {
			return {
				...state,
				error: true,
				isLoading: false,
				messageCode: action.payload.messageCode,
				message: action.payload.message
			};
		}
		// VALIDATE
		case actionTypes.ACTION_EMPLOYER_EMAIL_VALIDATE: {
			return {
				...state,
				isLoading: true
			};
		}
		case actionTypes.ACTION_EMPLOYER_EMAIL_VALIDATE_SUCCESS: {
			// debugger
			return {
				...state,
				isLoading: false,
				isValidValidate: action.payload.isValidValidate,
				messageCode: action.payload.messageCode,
				errorMessage: action.payload.errorMessage
			};
		}
		case actionTypes.ACTION_EMPLOYER_EMAIL_VALIDATE_FAILURE: {
			return {
				...state,
				error: true,
				isLoading: false,
				messageCode: action.payload.messageCode,
				errorMessage: action.payload.errorMessage
			};
		}


		// GENERATE 2FA Text
		case actionTypes.ACTION_EMPLOYER_TEXT_GENERATE: {

			return {
				...state,
				isLoading: true
			};
		}
		case actionTypes.ACTION_EMPLOYER_TEXT_GENERATE_SUCCESS: {

			return {
				...state,
				error: true,
				isLoading: false,
				messageCode: action.payload.messageCode,
				message: action.payload.message
			};
		}
		case actionTypes.ACTION_EMPLOYER_TEXT_GENERATE_FAILURE: {

			return {
				...state,
				error: true,
				isLoading: false,
				messageCode: action.payload.messageCode,
				message: action.payload.message,
				errorMessage: action.payload.errorMessage
			};
		}
		// VALIDATE 2FA text
		case actionTypes.ACTION_EMPLOYER_TEXT_VALIDATE: {
			return {
				...state,
				isLoading: true
			};
		}
		case actionTypes.ACTION_EMPLOYER_TEXT_VALIDATE_SUCCESS: {
			return {
				...state,
				isLoading: false,
				isValidValidate: action.payload.isValidValidate,
				messageCode: action.payload.messageCode,
				errorMessage: action.payload.errorMessage
			};
		}
		case actionTypes.ACTION_EMPLOYER_TEXT_VALIDATE_FAILURE: {
			return {
				...state,
				error: true,
				isLoading: false,
				messageCode: action.payload.messageCode,
				errorMessage: action.payload.errorMessage
			};
		}


		// GENERATE TOPT
		case actionTypes.ACTION_EMPLOYER_TOPT_GENERATE: {
			return {
				...state,
				isLoading: true
			};
		}
		case actionTypes.ACTION_EMPLOYER_TOPT_GENERATE_SUCCESS: {
			return {
				...state,
				isLoading: false,
				manualSetupKey: action.payload.manualSetupKey,
				qrCodeImage: action.payload.qrCodeImage
			};
		}
		case actionTypes.ACTION_EMPLOYER_TOPT_GENERATE_FAILURE: {
			return {
				...state,
				error: true,
				isLoading: false,
				messageCode: action.payload.messageCode,
				message: action.payload.message
			};
		}
		// VALIDATE
		case actionTypes.ACTION_EMPLOYER_TOPT_VALIDATE: {
			return {
				...state,
				isLoading: true
			};
		}
		case actionTypes.ACTION_EMPLOYER_TOPT_VALIDATE_SUCCESS: {
			return {
				...state,
				isLoading: false,
				isValidValidate: action.payload.isValidValidate
			};
		}
		case actionTypes.ACTION_EMPLOYER_TOPT_VALIDATE_FAILURE: {
			return {
				...state,
				error: true,
				isLoading: false,
				messageCode: action.payload.messageCode,
				errorMessage: action.payload.errorMessage
			};
		}
		// REVOKE
		case actionTypes.ACTION_EMPLOYER_TOPT_REVOKE: {
			return {
				...state,
				isLoading: true
			};
		}
		case actionTypes.ACTION_EMPLOYER_TOPT_REVOKE_SUCCESS: {
			// debugger
			return {
				...state,
				isLoading: false,
				isValidRevoke: action.payload.isValidRevoke
			};
		}
		case actionTypes.ACTION_EMPLOYER_TOPT_REVOKE_FAILURE: {
			return {
				...state,
				error: true,
				isLoading: false,
				messageCode: action.payload.messageCode,
				errorMessage: action.payload.errorMessage
			};
		}
		// DISABLED
		case actionTypes.ACTION_EMPLOYER_TOPT_DISABLED: {
			return {
				...state,
				isLoading: true
			};
		}
		case actionTypes.ACTION_EMPLOYER_TOPT_DISABLED_SUCCESS: {

			return {
				...state,
				employerData: action.payload.userData,
				isLoading: false,
				userData: action.payload.userData,
				isValidDisabled: action.payload.isValidDisabled
			};
		}
		case actionTypes.ACTION_EMPLOYER_TOPT_DISABLED_FAILURE: {
			return {
				...state,
				error: true,
				isLoading: false,
				messageCode: action.payload.messageCode,
				errorMessage: action.payload.errorMessage
			};
		}
		// GET EMPLOYER
		case actionTypes.ACTION_EMPLOYER_REQUEST: {
			return {
				...state,
				isLoading: true
			};
		}
		case actionTypes.ACTION_EMPLOYER_REQUEST_SUCCESS: {
			return {
				...state,
				isLoading: false,
				employerData: {
					...employerDefaultData,
					...action.payload.employerData,
					company: {
						...employerDefaultData.company,
						...action.payload.employerData.company
					}
				},
				// nextSlid:true
			};
		}
		case actionTypes.ACTION_EMPLOYER_REQUEST_FAILURE: {
			return {
				...state,
				error: true,
				isLoading: false,
				messageCode: action.payload.messageCode,
				errorMessage: action.payload.errorMessage
			};
		}
		// UPDATE
		case actionTypes.ACTION_EMPLOYER_UPDATE: {
			return {
				...state,
				isLoading: true
			};
		}
		case actionTypes.ACTION_EMPLOYER_UPDATE_SUCCESS: {
			return {
				...state,
				isLoading: false,
				messageCode: action.payload.messageCode,
				message: action.payload.message,
				employerData: {
					...employerDefaultData,
					...action.payload.employerData,
					company: {
						...employerDefaultData.company,
						...action.payload.employerData.company
					}
				},
				nextSlid: true
			};
		}
		case actionTypes.ACTION_EMPLOYER_UPDATE_FAILURE: {
			return {
				...state,
				error: true,
				isLoading: false,
				messageCode: action.payload.messageCode,
				errorMessage: action.payload.errorMessage
			};
		}

		//update business info
		case actionTypes.ACTION_EMPLOYER_UPDATE_BUSINESS_INFO: {
			return {
				...state,
				isLoading: true
			};
		}
		case actionTypes.ACTION_EMPLOYER_UPDATE_BUSINESS_INFO_SUCCESS: {
			return {
				...state,
				isLoading: false,
				messageCode: action.payload.messageCode,
				message: action.payload.message,
				employerData: {
					...employerDefaultData,
					...action.payload.employerData,
					company: {
						...employerDefaultData.company,
						...action.payload.employerData.company
					}
				},
				nextSlid: true
			};
		}
		case actionTypes.ACTION_EMPLOYER_UPDATE_BUSINESS_INFO_FAILURE: {
			return {
				...state,
				error: true,
				isLoading: false,
				messageCode: action.payload.messageCode,
				errorMessage: action.payload.errorMessage
			};
		}

		// ABN Verification
		case actionTypes.ACTION_EMPLOYER_VERIFY_ABN: {
			return {
				...state,
				isLoading: true
			};
		}
		case actionTypes.ACTION_EMPLOYER_VERIFY_ABN_SUCCESS: {
			return {
				...state,
				isLoading: false,
				abnInfo: action.payload.abnInfo
			};
		}
		case actionTypes.ACTION_EMPLOYER_VERIFY_ABN_FAILURE: {
			return {
				...state,
				error: true,
				isLoading: false,
				messageCode: action.payload.messageCode,
				errorMessage: action.payload.errorMessage
			};
		}
		case actionTypes.ACTION_EMPLOYER_ABN_CLEAR_DATA: {
			return {
				...state,
				abnInfo: action.payload.abnInfo,
				wpnInfo: action.payload.wpnInfo
			};
		}
		// WPN Verification
		case actionTypes.ACTION_EMPLOYER_VERIFY_WPN: {
			return {
				...state,
				// error: false,
				isLoading: true
			};
		}
		case actionTypes.ACTION_EMPLOYER_VERIFY_WPN_SUCCESS: {
			return {
				...state,
				isLoading: false,
				error: false,
				wpnInfo: action.payload.wpnInfo
			};
		}
		case actionTypes.ACTION_EMPLOYER_VERIFY_WPN_FAILURE: {
			return {
				...state,
				error: true,
				isLoading: false,
				messageCode: action.payload.messageCode,
				errorMessage: action.payload.errorMessage,
				wpnInfo: null,
			};
		}
		case actionTypes.ACTION_EMPLOYER_WPN_CLEAR_DATA: {
			return {
				...state,
				abnInfo: action.payload.abnInfo,
				wpnInfo: action.payload.wpnInfo
			};
		}
		case actionTypes.ACTION_EMPLOYER_CLEAN_MESSAGE: {
			return {
				...state,
				error: false,
				isLoading: false,
				errorMessage: action.payload.errorMessage,
				messageCode: action.payload.messageCode,
				message: action.payload.message
			};
		}
		// Close FY
		case actionTypes.ACTION_EMPLOYER_CLOSE_FY_REQUEST: {
			return {
				...state,
				isLoading: true
			};
		}
		case actionTypes.ACTION_EMPLOYER_CLOSE_FY_REQUEST_SUCCESS: {
			return {
				...state,
				isLoading: false,
				closeFY: action.payload.closeFY
			};
		}
		case actionTypes.ACTION_EMPLOYER_CLOSE_FY_REQUEST_FAILURE: {
			return {
				...state,
				error: true,
				isLoading: false,
				messageCode: action.payload.messageCode,
				message: action.payload.message
			};
		}
		// Generate last payments summaries
		case actionTypes.ACTION_EMPLOYER_GENERATE_LAST_PAYMENTS_REQUEST: {
			return {
				...state,
				isLoading: true
			};
		}
		case actionTypes.ACTION_EMPLOYER_GENERATE_LAST_PAYMENTS_REQUEST_SUCCESS: {
			return {
				...state,
				isLoading: false,
				isGenerateLastPayments: action.payload.isGenerateLastPayments
			};
		}
		case actionTypes.ACTION_EMPLOYER_GENERATE_LAST_PAYMENTS_REQUEST_FAILURE: {
			return {
				...state,
				error: true,
				isLoading: false,
				messageCode: action.payload.messageCode,
				message: action.payload.message,
				isGenerateLastPayments: action.payload.isGenerateLastPayments
			};
		}
		case actionTypes.ACTION_SESSION_LOGOUT: {
			return employerInitialState;
		}
		// redirect To Your Business
		case actionTypes.ACTION_REDIRECT_TO_YOUR_BUSINESS: {
			return {
				...state,
				redirectToYourBusiness: action.payload.redirectToYourBusiness
			}
		}
		case actionTypes.ACTION_NEXT_SLID: {
			return {
				...state,
				nextSlid: action.payload.nextSlid
			}
		}
		case actionTypes.ACTION_TEMP_REQUEST_DATA_PAYROLL: {
			return {
				...state,
				tempRequestDataPayRoll: action.payload.tempRequestDataPayRoll
			}
		}
		case actionTypes.ACTION_TEMP_REQUEST_DATA_REDIRECT_TO_PAYSLIP: {
			return {
				...state,
				redirectToPaySlip: action.payload.redirectToPaySlip
			}
		}
		default: {
			return {
				...state
			};
		}
	}
};

export default employerReducer;
