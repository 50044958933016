import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Paper, Typography, Divider, Switch } from "@material-ui/core";
import Moment from "react-moment";

import singleTouchStyle from "./singleTouchStyle";
import STPCompletedEmpty from "../../assets/images/STPCompleted.empty.png";
import * as stpActions from "../../actions/STPActions";
import { SwitchCommonComponent } from "../common";
import ModalSTPSummaryComponent from "./modalSTPSummaryComponent";
import { getUTCToLocal } from "./../../helpers/dateextensions";
import _ from 'lodash';

const IOSSwitch = withStyles((theme) => ({
  root: {
		width: 42,
		height: 26,
		padding: 0,
    margin: theme.spacing(1),
    marginLeft: 40
	},
	switchBase: {
		padding: 1,
		"&$checked": {
			transform: "translateX(16px)",
			color: theme.palette.common.white,
			"& + $track": {
				backgroundColor: "#007AFF",
				opacity: 1,
				border: "none"
			}
		},
		"&$focusVisible $thumb": {
			color: "#007AFF",
			border: "6px solid #fff"
		}
	},
	thumb: {
		width: 24,
		height: 24
	},
	track: {
		borderRadius: 12,
		backgroundColor: "#D8D8D8",
		opacity: 1,
		transition: theme.transitions.create(["background-color", "border"])
	},
	checked: {},
	focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

class SingleTouchCompletedComponent extends Component {
  state = {
    showSummaryModal: false,
    urlResume: "",
    hideButton: false,
    completedSections: {}
  };

  // componentWillReceiveProps = (nextProps) => {
  // 	const { stpSummary } = nextProps;

  // 	if (stpSummary && stpSummary.urlResume && stpSummary.urlResume.length > 1) {
  // 		this.setState(() => ({
  // 			showSummaryModal: true
  // 		}));
  // 	}
  // };

  handleGenerateReportSummary = stp => {
    // const { generateReportSummary } = this.props;
    // debugger
    // const reportSummary = {
    // 	urlResume: stp.urlResume
    // };
    // debugger
    // generateReportSummary(reportSummary);
    this.setState(() => ({
      urlResume: stp.urlResume,
      showSummaryModal: true,
      hideButton: true
    }));
  };

  handleCloseSummary = () => {
    const { clearReportSummary } = this.props;
    this.setState(() => ({
      showSummaryModal: false
    }));
    clearReportSummary();
  };

  toggleCompletedSection = (fy) => {
    let completedSections = this.state.completedSections;
    if (!(fy in completedSections)) {
      completedSections[fy] = false;
    } else {
      completedSections[fy] = !completedSections[fy];
    }

    this.setState({ completedSections });
  }

  render() {
    const {
      classes,
      completedList,
      isLoading,
      pendingList,
      stpSummary
    } = this.props;
    const { showSummaryModal, urlResume, hideButton } = this.state;

    let completedByFY = _.groupBy(completedList, i => { return i.period });
    console.log(completedByFY);
    let FYsWithSTP = Object.keys(completedByFY).sort((a, b) => { return b < a; });

    return (
      <Typography component="div" className={classes.hiddenOverflow}>
        {pendingList && pendingList.length > 0 ? (
          <div>
            <Typography variant="h2" gutterBottom className="gray">
              <span className={clsx(classes.bullet, { yellow: true })}>• </span>
              Pending ATO Response
            </Typography>
            <Grid
              container
              direction="row"
              alignItems="flex-start"
              className={classes.CardTitle}
            >
              <Grid item xs={5}>
                <Paper className={clsx(classes.paperTitle, "Left")}>
                  Payroll Period
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper className={classes.paperTitle}>Payment Date</Paper>
              </Grid>
              <Grid item xs={2} />
              <Grid item xs={2}>
                <Paper className={classes.paperTitle}>STP Lodged</Paper>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              {pendingList.map(stp => (
                <Grid
                  item
                  xs={12}
                  container
                  direction="row"
                  onClick={() => this.handleGenerateReportSummary(stp)}
                  alignItems="flex-start"
                  className={classes.BorderCard}
                  key={stp.id}
                >
                  <Grid item xs={5}>
                    <Paper className={clsx(classes.paperNoBorder, "Left")}>
                      <Moment utc locale="au" local={true} format="DD/MM/YYYY">
                        {stp.payPeriodStart}
                      </Moment>
                      &nbsp;-&nbsp;
                      <Moment utc locale="au" local={true} format="DD/MM/YYYY">
                        {stp.payPeriodEnd}
                      </Moment>
                    </Paper>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    style={
                      stp.isFinal
                        ? { textAlign: "center", paddingTop: 16 }
                        : { textAlign: "center" }
                    }
                  >
                    {stp.isFinal ? (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <span style={{ textAlign: "left" }}>
                          {`${stp.period}`}
                          <br />
                          Final
                        </span>
                      </div>
                    ) : (
                      <Paper className={classes.paperNoBorder}>
                        <Moment
                          utc
                          locale="au"
                          local={true}
                          format="DD/MM/YYYY"
                        >
                          {stp.paymentDate}
                        </Moment>
                      </Paper>
                    )}
                  </Grid>
                  <Grid item xs={2} />
                  <Grid item xs={2}>
                    <Paper className={classes.paperNoBorder}>
                      <Moment utc locale="au" local={true} format="DD/MM/YYYY">
                        {stp.ato.dateSent}
                      </Moment>
                      {/* <Moment utc locale="au" local={false} format="DD/MM/YYYY">{stp.createdDate}</Moment> */}
                    </Paper>
                  </Grid>
                </Grid>
              ))}
            </Grid>
            {completedList && completedList.length > 0 ? (
              <Divider className={classes.BottomSpaceList} />
            ) : null}
          </div>
        ) : null}

        {FYsWithSTP.map(fy => (
          <div>
            <Typography variant="h2" gutterBottom={!(fy in this.state.completedSections) || this.state.completedSections[fy]} className="gray">
              <span className={clsx(classes.bullet, { green: true })}>• </span>
              Complete - {fy}
              <IOSSwitch checked={!(fy in this.state.completedSections) || this.state.completedSections[fy]} onChange={() => this.toggleCompletedSection(fy)} />
            </Typography>
            {
              (!(fy in this.state.completedSections) || this.state.completedSections[fy]) &&
              <>
                  <Grid
                  container
                  direction="row"
                  alignItems="flex-start"
                  className={classes.CardTitle}
                >
                  <Grid item xs={5}>
                    <Paper className={clsx(classes.paperTitle, "Left")}>
                      Payroll Period
                    </Paper>
                  </Grid>
                  <Grid item xs={3}>
                    <Paper className={classes.paperTitle}>Payment Date</Paper>
                  </Grid>
                  <Grid item xs={2} />
                  <Grid item xs={2}>
                    <Paper className={classes.paperTitle}>STP Lodged</Paper>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  {completedByFY[fy].map(stp => (
                    <Grid
                      item
                      xs={12}
                      container
                      direction="row"
                      onClick={() => this.handleGenerateReportSummary(stp)}
                      alignItems="flex-start"
                      className={classes.BorderCard}
                      key={stp.id}
                    >
                      <Grid item xs={5}>
                        <Paper className={clsx(classes.paperNoBorder, "Left")}>
                          <Moment utc locale="au" local={true} format="DD/MM/YYYY">
                            {stp.payPeriodStart}
                          </Moment>
                          &nbsp;-&nbsp;
                          <Moment utc locale="au" local={true} format="DD/MM/YYYY">
                            {stp.payPeriodEnd}
                          </Moment>
                        </Paper>
                      </Grid>

                      <Grid
                        item
                        xs={3}
                        style={
                          stp.isFinal
                            ? { textAlign: "center", paddingTop: 16 }
                            : { textAlign: "center" }
                        }
                      >
                        {stp.isFinal ? (
                          <div
                            style={{ display: "flex", justifyContent: "center" }}
                          >
                            <span style={{ textAlign: "left" }}>
                              {`${stp.period}`}
                              <br />
                              Final
                            </span>
                          </div>
                        ) : (
                          <Paper className={classes.paperNoBorder}>
                            <Moment
                              utc
                              locale="au"
                              local={true}
                              format="DD/MM/YYYY"
                            >
                              {stp.paymentDate}
                            </Moment>
                          </Paper>
                        )}
                      </Grid>

                      <Grid item xs={2} />
                      <Grid item xs={2}>
                        <Paper className={classes.paperNoBorder}>
                          <Moment utc locale="au" local={true} format="DD/MM/YYYY">
                            {stp.ato.dateSent}
                          </Moment>
                          {/* <Moment utc locale="au" local={true} format="DD/MM/YYYY">{stp.createdDate}</Moment> */}
                        </Paper>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
                </>
            }
            <Divider className={classes.BottomSpaceList} />
          </div>
        ))}

        {(!completedList || completedList.length <= 0) &&
        (!pendingList || pendingList.length <= 0) &&
        !isLoading ? (
          <div className={classes.imageEmptyContainer}>
            <img
              className={classes.imageEmptyImage}
              src={STPCompletedEmpty}
              alt=""
            />
            <Typography variant="body1" className="gray">
              {"Your completed STP files will appear here"}
            </Typography>
          </div>
        ) : null}

        {showSummaryModal ? (
          <ModalSTPSummaryComponent
            hideButton={hideButton}
            open={showSummaryModal}
            summary={{ urlResume }}
            onCloseModal={this.handleCloseSummary}
          />
        ) : null}
      </Typography>
    );
  }
}

SingleTouchCompletedComponent.propTypes = {
  // stpSummary: PropTypes.object,
  generateReportSummary: PropTypes.func,
  clearSTPData: PropTypes.func
};

const mapStateToProps = state => ({
  // stpSummary: state.stp.reportSummary,
  isLoading: state.singleTouch.isLoading
});

const mapDispatchToProps = dispatch => ({
  generateReportSummary: bindActionCreators(
    stpActions.generateReportSummaryRequest,
    dispatch
  )
  // clearReportSummary: bindActionCreators(stpActions.setCleanDataToStore, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(singleTouchStyle)(SingleTouchCompletedComponent));
