import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Formik } from "formik";
import * as Yup from "yup";

import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";

import {
	Grid, Paper, TextField, Typography
} from "@material-ui/core";
import settingStyle from "./settingStyle";

import * as parameterTypes from "../../constants/parameterTypes";

import { DialogCommonComponent, NumberFormatCustomComponent } from "../common";
import { accountantAdditionalDefaultData } from "../../reducers/Accountant/AccountantInitialState";

class modalAddAccountant extends Component {
	validationSchema = Yup.object({
		email: Yup.string().email("Must be a valid email address").required("Email is required")
	});

	handleSubmitAccountant = (value, actions) => {

		const { onHandleUser } = this.props;
		actions.setSubmitting(false);
		onHandleUser(value, actions);
	};

	handleChangeValues = (name, event, formProps) => {
		const { value } = event.target;
		formProps.setFieldValue(name, value);
	};

	renderForm = (formProps) => {
		const {
			classes, open, onCloseModal, isDelete, data
		} = this.props;

		return (
			<DialogCommonComponent title={isDelete ? `Remove additional user` : `Invite additional user`} primaryButtonName={isDelete ? "Revoke Access" : "Send"} size="xs" onPrimaryButtonClick={formProps.handleSubmit} open={open} onCloseModal={onCloseModal}>
				<Grid container direction="row" alignItems="center">
					<Typography variant="body1" gutterBottom>
						{!isDelete && `Enter their email address below and click the 'Send'
						button.`}
					</Typography>
					<Typography variant="body1" gutterBottom>
						{!isDelete && `They will recieve an email to set up their
						details to access your account.`}
					</Typography>

					<Grid item xs={12}>
						<Paper className={classes.paperGrid}>
							<TextField
								id="email"
								label="Email Address"
								variant="filled"
								fullWidth
								className={clsx(classes.textField, {
									error: formProps.errors.email && formProps.touched.email
								})}
								type="email"
								onChange={(event) => {
									this.handleChangeValues("email", event, formProps);
								}}
								readOnly={isDelete}
								onBlur={formProps.handleBlur}
								value={isDelete ? data.email : formProps.values.email}
							/>
						</Paper>
					</Grid>
				</Grid>
			</DialogCommonComponent>
		);
	};

	render() {
		const { data } = this.props;

		const dataDefault = {
			...accountantAdditionalDefaultData,
			...data
		};

		return <Formik initialValues={{ ...dataDefault }} validationSchema={this.validationSchema} onSubmit={this.handleSubmitAccountant} render={this.renderForm} enableReinitialize />;
	}
}

modalAddAccountant.propTypes = {
	onHandleUser: PropTypes.func.isRequired,
	data: PropTypes.object
};

const mapStateToProps = state => ({

});

export default connect(
	mapStateToProps,
	null
)(withStyles(settingStyle)(modalAddAccountant));
