import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";

import { LoadingComponent, SnackbarCommonComponent } from "../components/common";
import SidebarComponent from "../components/home/sidebarComponent";
import AccountsComponent from "../components/account/accountsComponent";
import { SessionUserStyles } from "./PagesStyle";
import { mapMessageCodeToIcon } from "../helpers/extensions";
import { cleanMessage } from "../actions/EmployeeActions";

class AccountsPage extends Component {
	render() {
		const {
			classes, isLoading, messageCode, message, cleanMessageInternal, mixpanel
		} = this.props;

		return (
			<div className={classes.root}>
				<CssBaseline />
				<SidebarComponent />
				<main className={classes.content}>
					<LoadingComponent isLoading={isLoading} />
					<SnackbarCommonComponent key="SnackBarNewEmployees" variant={mapMessageCodeToIcon(messageCode)} open={!!message} body={message} onCloseModal={cleanMessageInternal} />
					<div className={classes.appBarSpacer} />
					<AccountsComponent mixpanel={mixpanel} />
				</main>
			</div>
		);
	}
}

AccountsPage.propTypes = {
	classes: PropTypes.object.isRequired,
	isLoading: PropTypes.bool,
	messageCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	message: PropTypes.string
};

const mapStateToProps = state => ({
	isLoading: state.employees.isLoading,
	messageCode: state.employees.messageCode,
	message: state.employees.message
});

const mapDispatchToProps = dispatch => ({
	cleanMessageInternal: bindActionCreators(cleanMessage, dispatch)
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(SessionUserStyles)(AccountsPage));
