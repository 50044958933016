import { createLogic } from "redux-logic";

import STPApi from "../api/STPApi";
import * as singleTouchActions from "../actions/SingleTouchActions";
import * as actionTypes from "../constants/actionTypes";
import * as apiResponses from "../constants/apiResponses";
import STPStatus from "../constants/STPStatus";
import { dispatchProcess } from "../store/customMiddleWare";

const getAllSTP = createLogic({
	type: actionTypes.ACTION_STP_GET_REQUEST,
	latest: true,
	async process({ httpClient }, dispatch, done) {
		try {
			const STPData = await STPApi.getAllSTP(httpClient);
			if (STPData.messageCode === apiResponses.RESPONSE_OK) {
				STPData.pendingList = STPData.list.filter(stp => stp.status === STPStatus.Pending);
				STPData.lodgedList = STPData.list.filter(stp => stp.status === STPStatus.Lodged);
				STPData.invalidList = STPData.list.filter(stp => stp.status === STPStatus.Invalid);
				STPData.completedList = STPData.list.filter(stp => stp.status === STPStatus.Complete);

				dispatch(singleTouchActions.getAllSTPRequestSuccess(STPData.list, STPData.pendingList, STPData.lodgedList, STPData.invalidList, STPData.completedList, STPData.messageCode, STPData.message));
			} else {
				dispatch(singleTouchActions.getAllSTPRequestFailure(STPData.messageCode, STPData.message));
			}
		} catch (err) {
			dispatch(singleTouchActions.getAllSTPRequestFailure("", err.message));
		}
		done();
	}
});

const redirectToSTP = createLogic({
	type: actionTypes.ACTION_REDIRECT_TO_STP,
})

const updateSTP = createLogic({
	type: actionTypes.ACTION_STP_UPDATE_REQUEST,
	latest: true,
	async process({ httpClient, action }, dispatch, done) {
		try {
			const STPData = await STPApi.updateSTP(httpClient, action.payload.STPData);
			if (STPData.status === 200) {
				await dispatch(singleTouchActions.updateSTPRequestSuccess(STPData.item, STPData.messageCode, STPData.message));
				await dispatchProcess(singleTouchActions.getAllSTPRequest(), actionTypes.ACTION_STP_GET_REQUEST_SUCCESS, actionTypes.ACTION_STP_GET_REQUEST_FAILURE);
				await dispatch(singleTouchActions.clearSTPData());
			} else {
				dispatch(singleTouchActions.updateSTPRequestFailure(STPData.messageCode, STPData.message));
			}
		} catch (err) {
			dispatch(singleTouchActions.updateSTPRequestFailure("", err.response && err.response.data ? err.response.data.message : err.message));
		}
		done();
	}
});

export default [getAllSTP, updateSTP, redirectToSTP];
