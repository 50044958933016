import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import SwipeableViews from "react-swipeable-views";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
	Paper, Tabs, Tab, Typography
} from "@material-ui/core";

import reportsStyles from "./reportsStyle";
import * as employeeActions from "../../actions/EmployeeActions";
import ReportPayrollComponent from "./reportPayrollComponent";
import ReportPayrollComponentDetail from "./ReportPayrollComponentDetail";
import ReportPayslipsComponent from "./reportPayslipsComponent";
import ReportEmployerComponent from "./reportEmployerComponent";
import ReportEmployeesComponent from "./reportEmployeesComponent";

class ReportsComponent extends Component {
	constructor() {
		super();

		this.state = {
			tabValue: 0
		};
	}

	componentDidMount() {
		const { getAllEmployees, mixpanel } = this.props;
		getAllEmployees();

		if (window.location.href.includes("/reports/payslips")) {
			this.setState({tabValue: 2});
		}

		mixpanel.track("Reports.Screen");
	}

	tabHandleChange = (event, newValue) => {
		this.setState({ tabValue: newValue });
	};

	tabHandleChangeIndex = (index) => {
		this.setState({ tabValue: index });
	};

	render() {
		const { classes, employees } = this.props;
		const { tabValue } = this.state;

		return (
			<div className={classes.root}>
				<Paper className={classes.paperContainer}>
					<Typography variant="h1" gutterBottom>
						Reports
					</Typography>
					<Typography>Select the report you would like to process</Typography>
					<Tabs className={classes.tabs} indicatorColor="primary" value={tabValue} onChange={this.tabHandleChange}>
						<Tab label="Payroll Activity Summary" className={classes.tab}  wrapped />
						<Tab label="Payroll Activity Detail" className={classes.tab} wrapped />
						<Tab label={<div>Payslips <br/> <div style={{ fontSize: 15 }}>Incl. Payslip Delete</div></div>} className={classes.tab} xs={2} wrapped />
						<Tab label="Employer Payment Summary" className={classes.tab} wrapped />
						<Tab label="Employee Payment Summaries" className={classes.tab} wrapped />
					</Tabs>

					<SwipeableViews index={tabValue} onChangeIndex={this.tabHandleChangeIndex}>
						<ReportPayrollComponent employees={employees} />
						<ReportPayrollComponentDetail employees={employees} />
						<ReportPayslipsComponent employees={employees} />
						<ReportEmployerComponent />
						<ReportEmployeesComponent />
					</SwipeableViews>
				</Paper>
			</div>
		);
	}
}

ReportsComponent.propTypes = {
	employees: PropTypes.array,
	getAllEmployees: PropTypes.func
};

const mapStateToProps = state => ({
	isLoading: state.employees.isLoading,
	employees: state.employees.reportsEmployeeList
});

const mapDispatchToProps = dispatch => ({
	getAllEmployees: bindActionCreators(employeeActions.employeesRequest, dispatch)
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(reportsStyles)(ReportsComponent));
