import * as actionTypes from "../../constants/actionTypes";
import easyPaySlipPtyStateState from "./EasyPaySlipPtyState";

const EasyPaySlipPtyReducer = (state = easyPaySlipPtyStateState, action) => {
	switch (action.type) {

		// get Accountant Details 
		case actionTypes.ACTION_ACCOUNTANT_DETAILS: {

			return {
				...state,
				isLoading: true
			};
		}
		case actionTypes.ACTION_ACCOUNTANT_DETAILS_SUCCESS: {
// debugger
			return {
				...state,
				isLoading: false,
				accountantDetails: action.payload.accountantDetails,
			};
		}
		case actionTypes.ACTION_ACCOUNTANT_DETAILS_FAILURE: {

			return {
				...state,
				error: true,
				isLoading: false,
				errorMessage: action.payload.errorMessage,
				messageCode: action.payload.messageCode,
			};
		}

		// update Accountant Details 
		case actionTypes.ACTION_ACCOUNTANT_DETAILS_UPDATE: {
			return {
				...state,
				isLoading: true,
				isSaving: true,
				accountantDetailsData: {
					...state.accountantDetailsData
				}
			};
		}
		case actionTypes.ACTION_ACCOUNTANT_DETAILS_UPDATE_SUCCESS: {
			return {
				...state,
				isLoading: false,
				isSaving: false,
				messageCode: action.payload.messageCode,
				message: action.payload.message
			};
		}
		case actionTypes.ACTION_ACCOUNTANT_DETAILS_UPDATE_FAILURE: {
			return {
				...state,
				error: true,
				isLoading: false,
				isSaving: false,
				errorMessage: action.payload.errorMessage,
				messageCode: action.payload.messageCode,
				message: action.payload.errorMessage,
			};
		}

		case actionTypes.ACTION_ACCOUNTANT_DETAILS_UPDATE_MESSAGE: {
			return {
				...state,
				error: false,
				isLoading: false,
				errorMessage: action.payload.errorMessage,
				messageCode: action.payload.messageCode,
				message: action.payload.message
			};
		}

		default: {
			return {
				...state
			};
		}

	}
};

export default EasyPaySlipPtyReducer;
