import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import {
	Box, CircularProgress, Grid, Typography
} from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";
import clsx from "clsx";

import { LogoHeaderCommonComponent } from "../common";

import { ExternalUserStyle } from "./sessionStyle";
import { userForgotValidation } from "../../actions/ExternalUserActions";
import ForgotScreen from "../../assets/images/forgotScreen.png";

class RecoverComponent extends Component {
	componentDidMount() {
		const { forgotValidation, match, history } = this.props;
		forgotValidation(match.params.id, history);
	}

	render() {
		const { classes } = this.props;

		return (
			<Grid className={classes.root} container justify="center" alignItems="flex-start">
				<LogoHeaderCommonComponent />
				<Grid
					container
					className={clsx(classes.rootContainer, {
						forgot: true
					})}
				>
					<Grid item xs={6} className={classes.leftColumn}>
						<Typography variant="h1" gutterBottom>
							Recover Password
						</Typography>
						<Typography variant="body1" gutterBottom>
							{"We're validating the information"}
						</Typography>
						<Box m={3} />
						<CircularProgress />
					</Grid>
					<Grid item xs={6} className={classes.rigthColumn}>
						<img src={ForgotScreen} alt="" className={classes.rigthColumnImg} />
					</Grid>
				</Grid>
			</Grid>
		);
	}
}

RecoverComponent.propTypes = {
	forgotValidation: PropTypes.func
};

const mapDispatchToProps = dispatch => ({
	forgotValidation: bindActionCreators(userForgotValidation, dispatch)
});

export default connect(
	null,
	mapDispatchToProps
)(withRouter(withStyles(ExternalUserStyle)(RecoverComponent)));
