import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import {
	Grid, IconButton, Paper, Typography
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import employerStyle from "./employerStyle";

class WpnInformationComponent extends Component {
	render() {
		const {
			classes, border, onClose, formProps, error
		} = this.props;
		const WPNData = formProps.values.company.wpn;

		return (
			<div>
				{!error && WPNData.number ? (
					<Paper
						className={clsx(classes.paperContainer, {
							[classes.paperContainerBorder]: border
						})}
					>
						<Grid container direction="row" alignItems="center">
							<Grid item xs={6} className={classes.completeContent}>
								<Typography>WPN Verified</Typography>
								<IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
									<CloseIcon />
								</IconButton>
							</Grid>
						</Grid>
					</Paper>
				) : null}
			</div>
		);
	}
}

export default withStyles(employerStyle)(WpnInformationComponent);
