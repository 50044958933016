import * as actionTypes from "../constants/actionTypes";

export function getNotificationRequest() {
	return {
		type: actionTypes.ACTION_NOTIFICATION_GET_REQUEST,
		payload: {
			isLoading: true,
			isUpdate: false
		}
	};
}

export function getNotificationRequestSuccess(notificationData, messageCode, message) {
	return {
		type: actionTypes.ACTION_NOTIFICATION_GET_REQUEST_SUCCESS,
		payload: {
			notificationData,
			messageCode,
			message,
			isUpdate: false
		}
	};
}

export function getNotificationRequestFailure(messageCode, errorMessage) {
	return {
		type: actionTypes.ACTION_NOTIFICATION_GET_REQUEST_FAILURE,
		error: true,
		payload: {
			messageCode,
			errorMessage,
			isUpdate: false
		}
	};
}

export function updateNotificationRequest(notificationData) {
	return {
		type: actionTypes.ACTION_NOTIFICATION_UPDATE_REQUEST,
		payload: {
			isLoading: true,
			notificationData,
			isUpdate: false
		}
	};
}

export function updateNotificationRequestSuccess(notificationData, messageCode, message) {
	return {
		type: actionTypes.ACTION_NOTIFICATION_UPDATE_REQUEST_SUCCESS,
		payload: {
			notificationData,
			messageCode,
			message,
			isUpdate: true
		}
	};
}

export function updateNotificationRequestFailure(messageCode, errorMessage) {
	return {
		type: actionTypes.ACTION_NOTIFICATION_UPDATE_REQUEST_FAILURE,
		error: true,
		payload: {
			messageCode,
			errorMessage,
			isUpdate: false
		}
	};
}

export function cleanMessage() {
	return {
		type: actionTypes.ACTION_NOTIFICATION_CLEAN_MESSAGE,
		payload: {
			errorMessage: null,
			messageCode: null,
			message: null
		}
	};
}
