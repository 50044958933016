const easyPaySlipPtyStyle = theme => ({
	root: {
		...theme.root,
		paddingBottom: 20
	},
	paper: {
		width: "100%",
		height: 65,
		borderRadius: 5,
		border: "1px solid #e9e9e9",
		backgroundColor: "#ffffff",
		padding: "10px 20px",
		boxShadow: "none",
		marginBottom: 12,
		"&:last-child": {
			marginBottom: 0
		},
		"&:hover": {
			boxShadow: "0 2px 2px 0 #D8D8D8"
		}
	},
	employersListWrapper: {
		marginBottom: 20
	},
	IconCheck: {
		fontSize: 23,
		color: "#D8D8D8"
	},
	accountantsTitle: {
		width: 900,
		margin: " 2px 0 2px 16px",
		padding: "0 20px 20px 0",
	},
	IconCheckActive: {
		fontSize: 23,
		color: "#007aff"
	},
	alignItemMenu: {
		textAlign: "right"
	},
	divider: {
		marginBottom: 18,
		marginTop: 10
	},
	gridContainer: {
		marginBottom: 16
	},
	gridLeft: {
		paddingLeft: 30
	},
	gridRight: {
		textAlign: "right"
	},
	gridRightClearButton: {
		marginBottom: 0
	},
	imageEmptyContainer: {
		width: "100%",
		textAlign: "center",
		marginTop: 40
	},
	imageEmptyImage: {
		width: 580,
		marginLeft: -70,
		marginBottom: 20
	},
	subtitleHeader: {
		fontFamily: "AvenirNextLTPro-Regular",
		color: "#7D848B"
	},
	paperContainer: {
		...theme.paperContainer
	},
	paperGrid: {
		...theme.paperClear
	},
	paperGridClearRight: {
		paddingRight: 0
	},
	option: {
		...theme.option
	},
	keyboardDatePicker: {
		...theme.keyboardDatePicker
	},
	keyboardDatePickerInput: {
		...theme.keyboardDatePickerInput
	},
	borderTextField: {
		...theme.borderTextField
	},
	containerItem: {
		margin: -7
	}
});

export default easyPaySlipPtyStyle;
