import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter, Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import {
	Box, Button, TextField, Grid, Link, FormControl, FormHelperText, InputAdornment, Paper, IconButton
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Formik, getIn } from "formik";
import clsx from "clsx";
import * as Yup from "yup";

import { Analytics } from "../common";

import * as routes from "../../constants/routes";
import LogoEasyPayslip from "../../assets/images/LOGOEasyPayslipHorizontal.svg";
import { ExternalUserStyle } from "./sessionStyle";
import Slider from "react-slick";
import * as OnboardActions from "../../actions/OnboardActions";
import mixpanel from "mixpanel-browser";

class QuickSetupComponentAccountant extends Component {

	constructor(props) {
		super(props);
		this.state = {
			currentIndex: 0,
			dotsClass: "",
			lastActiveIndex: 0,
			isNameValid: false,
			isEmailValid: false,
			isPasswordValid: false,
			isFirstNameValid: false,
			isLastNameValid: false,
			isPhoneValid: false,
			isTitleValid: false,
			isRelationshipValid: false,
			showPassword: false
		};
	}

	validationSchema = Yup.object({
		businessData: Yup.object().shape({
			businessName: Yup.string().matches(/^([0-9a-zA-Z .,?!(){}:;'|\-_=\/@#$%*=&"]){0,50}$/, {
				message: 'Please enter a valid business name',
				excludeEmptyString: true
			})
				.required("Business Name is required"),
			firstName: Yup.string().required("First name is required"),
			lastName: Yup.string().required("Last name is requierd"),
			email: Yup.string().matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, {
				message: 'Please enter a valid email address',
				excludeEmptyString: true
			}).email("Please enter a valid email address").required("Email is required"),
			phone: Yup.string().matches(/^(0011[0-9]{2}?|(\+[0-9]{2})|0)?[0-9]{9}$/, {
				message: 'Please enter a valid phone number',
				excludeEmptyString: true
			}).required("Please enter a valid phone number."),
			password: Yup.string()
				.min(7, "Please enter at least 7 characters")
				.required("Password is required"),
			title: Yup.string()
				.required("Title is required"),
		}),
	});

	handleSubmitOnboardAccountant = (values, actions) => {
		const { onboardingRequest, history } = this.props;
		let onboard = {
			accountType: "accountant",
		}
		onboardingRequest(onboard, history);
	};

	componentDidMount() {
		Analytics.OB_1BusinessNameScreen();
		const { history, businessData } = this.props;
		const location = history.location;

		console.log('token', this.props.token);

		if (!this.props.token) {
			this.handleSubmitOnboardAccountant();
			mixpanel.reset();
			Analytics.OB_0Start();
		}

		try {

			if (location.state.goSlide && location.state.goSlide === 3 && businessData.businessName && businessData.email) {
				this.setState({
					currentIndex: 2,
					lastActiveIndex: 2,
					isNameValid: true,
					isEmailValid: true,
				});

				this.slider.slickGoTo(2, true);
			}

		}
		catch (e) { }
	}

	componentWillMount() {
		const { cleanMessageOnboard } = this.props;
		cleanMessageOnboard()
	}

	componentWillReceiveProps = (nextProps) => {
		const { updateOnboardingSucsee, history, messageCode, accountData, accountType, businessData } = nextProps;

		if (updateOnboardingSucsee) {

			switch (this.state.currentIndex) {
				case 0:
					Analytics.updateProfileNameAction(businessData)
					Analytics.OB_1BusinessNameAction();
					break;
				case 1:
					Analytics.updateProfileNameAction(businessData)
					Analytics.OB_2EmailAction();
					break;
				case 2:
					Analytics.MAAAccountSignupAction();
					break;
				case 3:
					Analytics.updateProfileNameAction(businessData)
					Analytics.OB_4ContactAAction();
					break;
				case 4:

					Analytics.updateProfileNameAction(businessData, accountData && accountData.accountant && accountData.accountant.id)
					Analytics.OB_RoleAction();
					messageCode === 200 && accountData && history.push(routes.PATH_QU_SE_ACCOUNTANT_SUCCESS);

					break;
			}
			this.state.currentIndex !== 4 && this.slider.slickNext();
		}
	};
	handleClickShowPassword = () => {
		const { showPassword } = this.state;
		this.setState({ showPassword: !showPassword });
	};

	handleSubmitSignUp = (values, actions) => {
		const { signUp, history } = this.props;
		actions.setSubmitting(false);
		signUp(values.email, values.password, history);
	};

	onKeyPress(event, formProps) {

		if ((event.charCode === 13 || event.charCode === 10) && this.shouldNextBtnBeActive(this.state.currentIndex, formProps)) {
			this.handleNext(event, formProps);
		}
	}

	handleNext = (event, formProps) => {
		const { updateDataboarding, cleanMessageOnboard, businessData } = this.props;

		const { currentIndex } = this.state;
		let businessInf = {}
		let businessDataNew = {
			...businessData
		}


		cleanMessageOnboard();

		switch (currentIndex) {
			case 0:
				businessInf["businessName"] = formProps.values.businessData.businessName
				businessDataNew.businessName = formProps.values.businessData.businessName
				break;
			case 1:
				businessInf["email"] = formProps.values.businessData.email;
				businessDataNew.email = formProps.values.businessData.email;
				break;
			case 2:
				businessInf["password"] = formProps.values.businessData.password
				businessDataNew.password = formProps.values.businessData.password
				break;
			case 3:
				businessInf["firstName"] = formProps.values.businessData.firstName
				businessInf["lastName"] = formProps.values.businessData.lastName
				if (formProps.values.businessData.phone != "") {
					businessInf["phone"] = formProps.values.businessData.phone
				}

				businessDataNew.firstName = formProps.values.businessData.firstName
				businessDataNew.lastName = formProps.values.businessData.lastName
				businessDataNew.phone = formProps.values.businessData.phone
				break;
			case 4:
				businessInf["title"] = formProps.values.businessData.title
				if (formProps.values.businessData.relationship != "") {
					businessInf["relationship"] = formProps.values.businessData.relationship
				}

				businessDataNew.title = formProps.values.businessData.title
				businessDataNew.relationship = formProps.values.businessData.relationship
				break;
		}

		let isQS_Employer = false
		let isQS_Accountant = true
		updateDataboarding(businessInf, businessDataNew, this.state.currentIndex, isQS_Employer, isQS_Accountant);
	};

	handlePrevious = () => {
		this.slider.slickPrev();
	};

	handleChange = (event, fromProps) => {
		const newValue = event.target.value;

		fromProps.setFieldValue(event.target.id, newValue);
		this.setValidationStates(this.state.currentIndex, newValue, fromProps, event);
	};

	afterChange = (current) => {

		switch (current) {
			case 0:
				Analytics.OB_1BusinessNameScreen();
				break;
			case 1:
				Analytics.OB_2EmailScreen();
				break;
			case 2:
				Analytics.OB_3PasswordScreen();
				break;
			case 3:
				Analytics.OB_4ContactAScreen()
				break;
			case 4:
				Analytics.OB_RoleScreen()
				break;
		}
		this.setState({
			currentIndex: current,
			lastActiveIndex: current > this.state.lastActiveIndex ? current : this.state.lastActiveIndex
		})
	};

	beforeChange = (oldIndex, newIndex) => {
		this.setState({
			currentIndex: newIndex
		});
	}

	//--return validation
	shouldNextBtnBeActive = (current, formProps) => {
		switch (current) {
			case 0:
				return this.state.isNameValid;
			case 1:
				return this.state.isEmailValid;
			case 2:
				return this.state.isPasswordValid
			case 3:
				return this.state.isFirstNameValid && this.state.isLastNameValid && this.state.isPhoneValid
			case 4:

				return this.state.isTitleValid ?
					formProps.values.businessData.title === "Other" ?
						this.state.isRelationshipValid : true
					: false
		}
	}

	setValidationStates = (current, newValue, formProps, event) => {

		switch (current) {
			case 0:
				if (newValue.trim().match(/^([0-9a-zA-Z .,?!(){}:;'|\-_=\/@#$%*=&"]){1,50}$/)) {
					this.setState({
						lastActiveIndex: current,
						isNameValid: true
					});
				}
				else {
					this.setState({
						lastActiveIndex: current,
						isNameValid: false
					});
				}
				break;
			case 1:
				if (newValue.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)) {
					this.setState({
						lastActiveIndex: current,
						isEmailValid: true,
					});
				}
				else {
					this.setState({
						lastActiveIndex: current,
						isEmailValid: false
					});
				}
				break;
			case 2:
				if (newValue.length >= 7) {

					this.setState({
						lastActiveIndex: current,
						isPasswordValid: true,
					});
				}
				else {

					this.setState({
						lastActiveIndex: current,
						isPasswordValid: false
					});
				}
				break;
			case 3:
				console.log('event', event);
				if (event.target.id === "businessData.firstName") {
					if (newValue.length > 0) {
						this.setState({
							lastActiveIndex: current,
							isFirstNameValid: true,
						});
					}
					else {
						this.setState({
							lastActiveIndex: current,
							isFirstNameValid: false
						});
					}
				}
				else if (event.target.id === "businessData.lastName") {
					if (newValue.length > 0) {
						this.setState({
							lastActiveIndex: current,
							isLastNameValid: true
						});
					}
					else {
						this.setState({
							lastActiveIndex: current,
							isLastNameValid: false
						});
					}
				}
				else if (event.target.id === "businessData.phone") {
					if (newValue.match(/^(0011[0-9]{2}?|(\+[0-9]{2})|0)?[0-9]{9}$/)) {
						this.setState({
							lastActiveIndex: current,
							isPhoneValid: true,
						});
					}
					else {
						this.setState({
							lastActiveIndex: current,
							isPhoneValid: false
						});
					}
				}
				break;
			case 4:
				console.log(this.state);
				if (event.target.id === "businessData.title") {

					if (newValue.length > 0) {
						this.setState({
							lastActiveIndex: current,
							isTitleValid: true
						});
					}
					else {
						this.setState({
							lastActiveIndex: current,
							isTitleValid: false
						});
					}

				}

				//--relationship
				if (event.target.id === "businessData.relationship") {
					if (newValue.length > 0) {
						this.setState({
							lastActiveIndex: current,
							isRelationshipValid: true
						});
					}
					else {
						this.setState({
							lastActiveIndex: current,
							isRelationshipValid: false
						});
					}

				}

				break;
		}
	}

	renderAustraliaStateOptions = () => {
		const { classes } = this.props;
		let stateList = [
			{
				"value": "",
				"order": 0
			},
			{
				"value": "Accountant / Bookkeeper",
				"order": 1
			},
			{
				"value": "Other",
				"order": 2
			}

		]
		return stateList
			.sort((firstEl, secondEl) => firstEl.order < secondEl.order)
			.map(item => <option key={`${item.id}`} value={`${item.value}`} className={classes.option}>{item.value}</option>);
	}

	// { values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, }
	renderForm = (formProps) => {
		const { classes, error, errorMessage, onboard } = this.props;
		const { currentIndex, showPassword } = this.state;

		var settings = {
			dots: true,
			dotsClass: `slick-dots slick-dots-quickSetup`,
			infinite: false,
			speed: 250,
			autoplaySpeed: 4000,
			slidesToShow: 1,
			touchMove: false,
			slidesToScroll: 1,
			fade: true,
			arrows: false,
			afterChange: (current, next) => this.afterChange(current, next),
			beforeChange: (current, next) => this.beforeChange(current, next),
			className: "quickSetupSlide",
			appendDots: () => <ul>
				<li class={this.state.currentIndex === 0 ? "slick-active" : ""} ><button onClick={() => this.slider.slickGoTo(0)}>1</button></li>
				<li class={`${this.state.currentIndex === 1 ? "slick-active" : ""} ${this.state.lastActiveIndex < 1 ? "qs-noHover" : ""}`}><button disabled={this.state.lastActiveIndex < 1 ? true : false} onClick={() => this.slider.slickGoTo(1)}>2</button></li>
				<li class={`${this.state.currentIndex === 2 ? "slick-active" : ""} ${this.state.lastActiveIndex < 2 ? "qs-noHover" : ""}`}><button disabled={this.state.lastActiveIndex < 2 ? true : false} onClick={() => this.slider.slickGoTo(2)}>3</button></li>
				<li class={`${this.state.currentIndex === 3 ? "slick-active" : ""} ${this.state.lastActiveIndex < 3 ? "qs-noHover" : ""}`}><button disabled={this.state.lastActiveIndex < 3 ? true : false} onClick={() => this.slider.slickGoTo(3)}>4</button></li>
				<li class={`${this.state.currentIndex === 4 ? "slick-active" : ""} ${this.state.lastActiveIndex < 4 ? "qs-noHover" : ""}`}><button disabled={this.state.lastActiveIndex < 4 ? true : false} onClick={() => this.slider.slickGoTo(4)}>5</button></li>
			</ul>
		};

		return (
			<Grid container className={classes.quickSetupRoot}>
				<Grid className={classes.quickSetupRoot} container alignItems="flex-start">
					<Grid container className={classes.rootContainer} style={{ height: "460px", padding: "50px" }}>
						<div className={classes.logoEasyPayslipAcountant} >
							<Grid item xl={6} lg={6} md={6} sm={6} style={{ textAlign: "left" }} >
								<img src={LogoEasyPayslip} alt="" />
							</Grid>
							<Grid item xl={6} lg={6} md={6} sm={6} style={{ textAlign: "left" }}>
								<label className={classes.subTitleOne} style={{ maxWidth: "initial", fontFamily: "AvenirNextLTPro-Regular", marginTop: "12px" }}>
									Accountants / Secondary Users
						    	</label>
							</Grid>
						</div>
						<Grid item xs={12}>
							<div className={classes.slideShow}>
								<Slider ref={c => (this.slider = c)} {...settings}>
									{/* slide item one */}
									<div>
										{this.state.currentIndex === 0 && <div className={classes.slideShowItemAcountant}>
											<div>
												<div>
													<div className={classes.titleWrapper}>
														<h1 className={classes.title}>
															What’s your
												<br />
															business name?
                                               </h1>
														<label className={classes.subTitleOne} style={{ maxWidth: "initial", fontFamily: "AvenirNextLTPro-Regular" }}>
															So we can get started
									        	</label>
													</div>
												</div>
											</div>
											<div>
												<Grid item>
													<Paper className={classes.paperGrid} style={{ marginTop: 25 }}>
														<TextField
															autoFocus={this.state.currentIndex === 0}
															fullWidth
															id="businessData.businessName"
															label="Business Name*"
															variant="filled"
															value={formProps.values.businessData.businessName}
															onChange={event => this.handleChange(event, formProps)}
															onBlur={formProps.handleBlur}
															onKeyPress={(event) => this.onKeyPress(event, formProps)}
															className={clsx(classes.textFieldQuickSetup, {
																error: getIn(formProps.errors, "businessData.businessName") && getIn(formProps.touched, "businessData.businessName")
															})}
														/>
														{getIn(formProps.errors, "businessData.businessName") && getIn(formProps.touched, "businessData.businessName") &&
															<FormHelperText error>{formProps.errors.businessData.businessName}</FormHelperText>}
													</Paper>
												</Grid>

											</div>
										</div>}
									</div>

									{/* slide item two */}
									<div>
										{this.state.currentIndex === 1 && <div className={classes.slideShowItemAcountant}>
											<div>
												<div>
													<div className={classes.titleWrapper}>
														<h1 className={classes.title}>
															What is your
												<br />
															email address?
                                               </h1>
														<label className={classes.subTitleOne} style={{ maxWidth: "initial", fontFamily: "AvenirNextLTPro-Regular" }}>
															So we can create
													 <br />
															your account
									        	</label>
													</div>
												</div>
											</div>
											<div>
												<Grid item>
													<Paper className={classes.paperGrid} style={{ marginTop: 25 }}>
														<TextField
															fullWidth
															InputProps={
																{
																	autocomplete: "new-email",
																	form: {
																		autocomplete: "off",
																	}
																}
															}
															type="email"
															autoComplete="off"
															id="businessData.email"
															label="Email*"
															variant="filled"
															autoFocus={this.state.currentIndex === 1}
															value={formProps.values.businessData.email}
															onChange={event => this.handleChange(event, formProps)}
															onBlur={formProps.handleBlur}
															onKeyPress={(event) => this.onKeyPress(event, formProps)}
															className={clsx(classes.textFieldQuickSetup, {
																error: getIn(formProps.errors, "businessData.email") && getIn(formProps.touched, "businessData.email")
															})}
														/>
														{getIn(formProps.errors, "businessData.email") && getIn(formProps.touched, "businessData.email") &&
															<FormHelperText error>{formProps.errors.businessData.email}</FormHelperText>}
													</Paper>
												</Grid>

											</div>
										</div>}
									</div>


									{/* slide item tree */}
									<div>
										{this.state.currentIndex === 2 && <div className={classes.slideShowItemAcountant}>
											<div>
												<div>
													<div className={classes.titleWrapper}>
														<h1 className={classes.title}>
															Add a
												<br />
															password
                                               </h1>
														<label className={classes.subTitleOne} style={{ maxWidth: "initial", fontFamily: "AvenirNextLTPro-Regular" }}>
															Make sure you add at
													 <br />
															least seven characters
									        	</label>
													</div>
												</div>
											</div>
											<div>
												<Grid item>
													<Paper className={classes.paperGrid} style={{ marginTop: 25 }}>
														<TextField
															fullWidth
															type={showPassword ? "text" : "password"}
															inputProps={
																{
																	autocomplete: "new-password",
																	form: {
																		autocomplete: "off",
																	}
																}
															}
															autoComplete="off"
															id="businessData.password"
															label="Password*"
															variant="filled"
															autoFocus={this.state.currentIndex === 2}
															value={formProps.values.businessData.password}
															onChange={event => this.handleChange(event, formProps)}
															onBlur={formProps.handleBlur}
															onKeyPress={(event) => this.onKeyPress(event, formProps)}
															className={clsx(classes.textFieldQuickSetup, {
																error: getIn(formProps.errors, "businessData.password") && getIn(formProps.touched, "businessData.password")
															})}
														// InputProps={{
														// 	endAdornment: (
														// 		<InputAdornment position="end">
														// 			<IconButton edge="end" aria-label="Toggle password visibility" onClick={this.handleClickShowPassword}>
														// 				{showPassword ? <VisibilityOff /> : <Visibility />}
														// 			</IconButton>
														// 		</InputAdornment>
														// 	)
														// }}
														/>
														{getIn(formProps.errors, "businessData.password") && getIn(formProps.touched, "businessData.password") &&
															<FormHelperText error>{formProps.errors.businessData.password}</FormHelperText>}
													</Paper>
												</Grid>
											</div>
										</div>}
									</div>

									{/* slide item four */}
									<div>
										{this.state.currentIndex === 3 && <div className={classes.slideShowItemAcountant}>
											<div>
												<div>
													<div className={classes.titleWrapper}>
														<h1 className={classes.title}>
															What do
												<br />
															we call you?
                                               </h1>
														<label className={classes.subTitleOne} style={{ maxWidth: "initial", fontFamily: "AvenirNextLTPro-Regular" }}>
															Add your preferred
													 <br />
															name & number
									        	</label>
													</div>
												</div>
											</div>
											<div>
												<Grid container spacing={2}>
													<Grid item xl={6} lg={6} md={6} sm={6} >
														<Paper className={classes.paperGrid} style={{ marginTop: 25 }}>
															<TextField
																fullWidth
																id="businessData.firstName"
																label="First name*"
																variant="filled"
																autoFocus={this.state.currentIndex === 3}
																value={formProps.values.businessData.firstName}
																onChange={event => this.handleChange(event, formProps)}
																onKeyPress={(event) => this.onKeyPress(event, formProps)}
																onBlur={formProps.handleBlur}
																className={clsx(classes.textFieldQuickSetup, {
																	error: getIn(formProps.errors, "businessData.firstName") && getIn(formProps.touched, "businessData.firstName")
																})}
															/>
															{getIn(formProps.errors, "businessData.firstName") && getIn(formProps.touched, "businessData.firstName") &&
																<FormHelperText error>{formProps.errors.businessData.firstName}</FormHelperText>}

														</Paper>
													</Grid>
													<Grid item xl={6} lg={6} md={6} sm={6} >
														<Paper className={classes.paperGrid} style={{ marginTop: 25 }}>
															<TextField
																fullWidth
																id="businessData.lastName"
																label="Last name*"
																variant="filled"
																value={formProps.values.businessData.lastName}
																onChange={event => this.handleChange(event, formProps)}
																onKeyPress={(event) => this.onKeyPress(event, formProps)}
																onBlur={formProps.handleBlur}
																className={clsx(classes.textFieldQuickSetup, {
																	error: getIn(formProps.errors, "businessData.lastName") && getIn(formProps.touched, "businessData.lastName")
																})}
															/>
															{getIn(formProps.errors, "businessData.lastName") && getIn(formProps.touched, "businessData.lastName") &&
																<FormHelperText error>{formProps.errors.businessData.lastName}</FormHelperText>}

														</Paper>
													</Grid>
													<Grid item xl={12} lg={12} md={12} sm={12} >
														<Paper className={classes.paperGrid} style={{ marginTop: 15 }}>
															<TextField
																id="businessData.phone"
																label="Phone number*"
																variant="filled"
																value={formProps.values.businessData.phone}
																onChange={event => this.handleChange(event, formProps)}
																onKeyPress={(event) => this.onKeyPress(event, formProps)}
																onBlur={formProps.handleBlur}
																fullWidth
																className={clsx(classes.textFieldQuickSetup, {
																	error: getIn(formProps.errors, "businessData.phone") && getIn(formProps.touched, "businessData.phone")
																})}
															/>
															{getIn(formProps.errors, "businessData.phone") && getIn(formProps.touched, "businessData.phone") &&
																<FormHelperText error>{formProps.errors.businessData.phone}</FormHelperText>}

														</Paper>
													</Grid>
												</Grid>
											</div>
										</div>}
									</div>

									{/* slide item five */}
									<div>
										{this.state.currentIndex === 4 && <div className={classes.slideShowItemAcountant}>
											<div>
												<div>
													<div className={classes.titleWrapper}>
														<h1 className={classes.title}>
															What’s your role
                                               </h1>
														<label className={classes.subTitleOne} style={{ maxWidth: "initial", fontFamily: "AvenirNextLTPro-Regular", width: 300 }}>
															Tell us your connection

															to other Easy Payslip

															account holder
									        	</label>
													</div>
												</div>
											</div>
											<div>
												<Grid container spacing={2}>
													<Grid item xl={12} lg={12} md={12} sm={12} >
														<Paper className={classes.paperGrid} style={{ marginTop: 25 }}>
															<TextField
																id="businessData.title"
																label="Title *"
																variant="filled"
																autoFocus={this.state.currentIndex === 4}
																value={formProps.values.businessData.title}
																onChange={event => this.handleChange(event, formProps)}
																onKeyPress={(event) => this.onKeyPress(event, formProps)}
																onBlur={formProps.handleBlur}
																className={clsx(classes.textFieldQuickSetup, classes.textFieldQuickSetupSelected, {
																	error: (getIn(formProps.errors, "businessData.title") && getIn(formProps.touched, "businessData.title"))
																})}
																SelectProps={{
																	native: true,
																	MenuProps: {
																		className: classes.menu
																	}
																}}
																select
																fullWidth
															>
																{this.renderAustraliaStateOptions()}
															</TextField>
															{(getIn(formProps.errors, "details.title") && getIn(formProps.touched, "details.title") && <FormHelperText error>{formProps.errors.details.title}</FormHelperText>)}

														</Paper>
													</Grid>
													<Grid item xl={12} lg={12} md={12} sm={12} >
														<Paper className={classes.paperGrid} style={{ marginTop: 20, height: "49px" }}>
															{formProps.values.businessData.title === "Other" && <TextField
																fullWidth
																id="businessData.relationship"
																label="Relationship to account holder*"
																variant="filled"
																value={formProps.values.businessData.relationship}
																onChange={event => this.handleChange(event, formProps)}
																onKeyPress={(event) => this.onKeyPress(event, formProps)}
																onBlur={formProps.handleBlur}
																className={clsx(classes.textFieldQuickSetup, {
																	error: getIn(formProps.errors, "businessData.relationship") && getIn(formProps.touched, "businessData.relationship")
																})}
															/>}
															{getIn(formProps.errors, "businessData.relationship") && getIn(formProps.touched, "businessData.relationship") &&
																formProps.values.businessData.title === "Other" && <FormHelperText error>{formProps.errors.businessData.relationship}</FormHelperText>}

														</Paper>
													</Grid>
												</Grid>
												<Grid item >
													<FormControl fullWidth margin="normal">
														<Box m={0.1} />
														<Button disabled={!this.shouldNextBtnBeActive(currentIndex, formProps)} variant="contained" color="primary" style={{ width: "100%" }} className={classes.signupBtn}
															onClick={event => this.handleNext(event, formProps)}
															type="submit">
															Create My Account
										              	</Button>
													</FormControl>
												</Grid>
											</div>
										</div>}
									</div>
								</Slider>
								<div className={classes.quSeBtnWrapper} style={{ top: `${currentIndex === 3 ? "170px" : (currentIndex === 4 ? "230px" : "")}` }}>
									{currentIndex > 0 && <div style={{ paddingTop: "5px", marginRight: "auto" }}>
										<Button style={{ minWidth: "70px" }} color="primary"
											className={classes.backText}
											onClick={event => this.handlePrevious(event, formProps)}>Back</Button>
									</div>}
									<div>
										{currentIndex < 4 && <Button variant="contained" color="primary"
											disabled={!this.shouldNextBtnBeActive(currentIndex, formProps)}
											className={clsx(classes.quSeBtn, { [classes.disabledButtonPrimary]: !this.shouldNextBtnBeActive(currentIndex, formProps) })}
											onClick={event => this.handleNext(event, formProps)}
											type="submit">
											Next
											</Button>}
									</div>
								</div>
							</div>
						</Grid>
					</Grid>
				</Grid>
			</Grid >
		);
	};

	render() {
		const { classes, businessData } = this.props;
		const quickSetupData = {
			businessData: {
				...businessData
			}
		};

		return (
			<Grid container className={classes.root}>
				<Formik initialValues={{ ...quickSetupData }} validationSchema={this.validationSchema} onSubmit={this.handleSubmitSignUp} render={this.renderForm} />
			</Grid>
		);
	}
}

QuickSetupComponentAccountant.propTypes = {
	history: PropTypes.object,
	classes: PropTypes.object,
	onboard: PropTypes.object,
	businessData: PropTypes.object,
	accountData: PropTypes.object,
	error: PropTypes.bool,
	updateOnboardingSucsee: PropTypes.bool,
	errorMessage: PropTypes.string,
	message: PropTypes.string,
	messageCode: PropTypes.number,
	onboardSessionid: PropTypes.string,
	accountType: PropTypes.string,
	signUp: PropTypes.func,
	onboardingRequest: PropTypes.func,
};

const mapStateToProps = state => ({
	error: state.external.error,
	onboard: state.onboard,
	message: state.onboard.message,
	onboardSessionid: state.onboard.sessionToken,
	businessData: state.onboard.businessData,
	messageCode: state.onboard.messageCode,
	updateOnboardingSucsee: state.onboard.updateOnboardingSucsee,
	accountData: state.onboard.accountData,
	accountType: state.onboard.accountType,
});

const mapDispatchToProps = dispatch => ({
	updateDataboarding: bindActionCreators(OnboardActions.updateDataDuringOnboarding, dispatch),
	finishOnboardingGetDataAccount: bindActionCreators(OnboardActions.finishOnboardingGetDataAccount, dispatch),
	cleanMessageOnboard: bindActionCreators(OnboardActions.cleanMessageOnboard, dispatch),
	onboardingRequest: bindActionCreators(OnboardActions.InitialiseAnOnboardingRequest, dispatch),
});

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(withStyles(ExternalUserStyle)(QuickSetupComponentAccountant))
);
