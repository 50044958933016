import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import {
	Grid, Paper, TextField, FormHelperText
} from "@material-ui/core";
import clsx from "clsx";

import { getIn } from "formik";
import EmployerStyles from "./employerStyle";
import { CardCommonComponent } from "../common";
import * as parameterTypes from "../../constants/parameterTypes";
import * as parameterActions from "../../actions/ParameterActions";

class EmployerBusinessAddressComponent extends Component {
	componentDidMount() {
		const { loadParamters } = this.props;

		loadParamters(parameterTypes.PARAMETER_STATES_AUSTRALIA.parameterType,
			parameterTypes.PARAMETER_STATES_AUSTRALIA.section,
			parameterTypes.PARAMETER_STATES_AUSTRALIA.typeSection);
	}

	renderAustraliaStateOptions = () => {
		const { stateList, classes } = this.props;

		return stateList
			.sort((firstEl, secondEl) => firstEl.order < secondEl.order)
			.map(item => <option key={`${item.id}`} value={`${item.value}`} className={classes.option}>{item.value}</option>);
	};

	render() {
		const { classes, formProps } = this.props;
		return (
			<CardCommonComponent title="Business address" complete={!(getIn(formProps.errors, "company.address"))}>
				<Grid container direction="row" alignItems="flex-start">
					<Grid item xs={4}>
						<Paper className={classes.paperGrid}>
							<TextField
								id="company.address.addressLine1"
								label="Street line 1 *"
								variant="filled"
								InputProps={{
									inputProps: {
										maxLength: "37"
									}
								}}
								value={formProps.values.company.address.addressLine1}
								onChange={formProps.handleChange}
								onBlur={formProps.handleBlur}
								className={clsx(classes.textField, {
									error: (getIn(formProps.errors, "company.address.addressLine1") && getIn(formProps.touched, "company.address.addressLine1"))
								})}
								fullWidth
							/>
							{(getIn(formProps.errors, "company.address.addressLine1") && getIn(formProps.touched, "company.address.addressLine1") && <FormHelperText error>{formProps.errors.company.address.addressLine1}</FormHelperText>)}
						</Paper>
					</Grid>
					<Grid item xs={4}>
						<Paper className={classes.paperGrid}>
							<TextField
								id="company.address.addressLine2"
								label="Street line 2 (If applicable)"
								className={classes.textField}
								variant="filled"
								InputProps={{
									inputProps: {
										maxLength: "37"
									}
								}}
								maxLength="37"
								value={formProps.values.company.address.addressLine2}
								onChange={formProps.handleChange}
								onBlur={formProps.handleBlur}
								fullWidth
							/>
						</Paper>
					</Grid>
					<Grid item xs={4} />

					<Grid item xs={4}>
						<Paper className={classes.paperGrid}>
							<TextField
								id="company.address.townCity"
								label="Town/City *"
								variant="filled"
								value={formProps.values.company.address.townCity}
								onChange={formProps.handleChange}
								onBlur={formProps.handleBlur}
								className={clsx(classes.textField, {
									error: (getIn(formProps.errors, "company.address.townCity") && getIn(formProps.touched, "company.address.townCity"))
								})}
								fullWidth
							/>
							{(getIn(formProps.errors, "company.address.townCity") && getIn(formProps.touched, "company.address.townCity") && <FormHelperText error>{formProps.errors.company.address.townCity}</FormHelperText>)}
						</Paper>
					</Grid>
					<Grid item xs={4}>
						<Paper className={classes.paperGrid}>
							<TextField
								id="company.address.postCode"
								label="Postcode *"
								variant="filled"
								InputProps={{
									inputProps: {
										maxLength: "4"
									}
								}}
								value={formProps.values.company.address.postCode}
								onChange={formProps.handleChange}
								onBlur={formProps.handleBlur}
								className={clsx(classes.textField, {
									error: (getIn(formProps.errors, "company.address.postCode") && getIn(formProps.touched, "company.address.postCode"))
								})}
								fullWidth
							/>
							{(getIn(formProps.errors, "company.address.postCode") && getIn(formProps.touched, "company.address.postCode") && <FormHelperText error>{formProps.errors.company.address.postCode}</FormHelperText>)}
						</Paper>
					</Grid>
					<Grid item xs={4}>
						<Paper className={classes.paperGrid}>
							<TextField
								id="company.address.state"
								label="State/Territory *"
								variant="filled"
								value={formProps.values.company.address.state}
								onChange={formProps.handleChange}
								onBlur={formProps.handleBlur}
								className={clsx(classes.textField, {
									error: (getIn(formProps.errors, "company.address.state") && getIn(formProps.touched, "company.address.state"))
								})}
								SelectProps={{
									native: true,
									MenuProps: {
										className: classes.menu
									}
								}}
								select
								fullWidth
							>
								<option></option>
								{this.renderAustraliaStateOptions()}
							</TextField>
							{(getIn(formProps.errors, "company.address.state") && getIn(formProps.touched, "company.address.state") && <FormHelperText error>{formProps.errors.company.address.state}</FormHelperText>)}
						</Paper>
					</Grid>
				</Grid>
			</CardCommonComponent>
		);
	}
}

const mapStateToProps = state => ({
	stateList: state.parameters.parameterByTypeData[parameterTypes.PARAMETER_STATES_AUSTRALIA.parameterType],
});

const mapDispatchToProps = dispatch => ({
	loadParamters: bindActionCreators(parameterActions.getParametersByTypeRequest, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(EmployerStyles)(EmployerBusinessAddressComponent));
