export const RESPONSE_NOTCOMPLETE = 100;
export const RESPONSE_PARTIAL = 101;
export const RESPONSE_FAIL = 102;
export const RESPONSE_NODATE = 103;
export const RESPONSE_FOUND = 302;
export const RESPONSE_BADREQUEST = 400;
export const RESPONSE_UNAUTHORIZED = 401;
export const RESPONSE_BADREQUESTPOPUP = 402;
export const RESPONSE_INVALIDDATA = 403;
export const RESPONSE_NOTFOUND = 404;
export const RESPONSE_EMPLOYEENOTFOUND = 405;
export const RESPONSE_EMPLOYEECONTAINPAYROLL = 406;
export const RESPONSE_EMPLOYEEISINACTIVE = 407;
export const RESPONSE_OK = 2000;
export const RESPONSE_OK_SUCCESS_CODE = 200;
export const RESPONSE_OK_MESSAGE = "Success";
export const RESPONSE_OK_STATUS = 200;
