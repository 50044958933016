import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";

import SidebarComponent from "../components/home/sidebarComponent";
import { SessionUserStyles } from "./PagesStyle";

class HomePage extends Component {
	render() {
		const { classes } = this.props;

		return (
			<div className={classes.root}>
				<CssBaseline />
				<SidebarComponent />
				<main className={classes.content}>
					<div className={classes.appBarSpacer} />
					{"Home"}
				</main>
			</div>
		);
	}
}

HomePage.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(SessionUserStyles)(HomePage);
