
// get Accountant Details 
const getAccountantDetails = async httpClient => {	
	const { goEpsApi } = window.envVariables;
	const response = await httpClient.get(`${goEpsApi}/api/v1/accountant`);		
	return response;
};

// Login As Linked Employer
const updateAccountantDetails = async (httpClient, accountant) => {	
	const { goEpsApi } = window.envVariables;
	const response = await httpClient.post(`${goEpsApi}/api/v1/accountant`, accountant);	
	return response;
};

export default {
	getAccountantDetails,
	updateAccountantDetails
};





