export const PARAMETER_ALLOWANCE_PAYROLL = {
	parameterType: "ALLOWANCE_PAYROLL",
	section: "Process_Payroll",
	typeSection: "Allowance"
};
export const PARAMETER_OVERTIME_PAYROLL = {
	parameterType: "OVERTIME_PAYROLL",
	section: "Process_Payroll",
	typeSection: "Overtime"
};
export const PARAMETER_PAYMENT_TYPE_PAYROLL = {
	parameterType: "PAYMENT_TYPE_PAYROLL",
	section: "Process_Payroll",
	typeSection: "Payment_type"
};
export const PARAMETER_STATES_AUSTRALIA = {
	parameterType: "STATES_AUSTRALIA",
	section: "States",
	typeSection: "Australian"
};
export const PARAMETER_EMPLOYEE_CATEGORY = {
	parameterType: "EMPLOYEE_CATEGORY",
	section: "Employee",
	typeSection: "Categories"
};
export const PARAMETER_EMPLOYEE_PAYROLL_PAY_BASIS = {
	parameterType: "EMPLOYEE_PAYROLL_PAY_BASIS",
	section: "Employee",
	typeSection: "Payroll_pay_basis"
};
export const PARAMETER_EMPLOYEE_PAYROLL_PAY_FREQUENTLY = {
	parameterType: "EMPLOYEE_PAYROLL_PAY_FREQUENTLY",
	section: "Employee",
	typeSection: "Payroll_pay_frequently"
};
export const PARAMETER_EMPLOYEE_STATUS = {
	parameterType: "EMPLOYEE_STATUS",
	section: "Employee",
	typeSection: "Status"
};
export const PARAMETER_EMPLOYEE_TAX_RESIDENCY = {
	parameterType: "EMPLOYEE_TAX_RESIDENCY",
	section: "Employee",
	typeSection: "Tax_residency"
};
export const PARAMETER_EMPLOYEE_TAX_BAND = {
	parameterType: "EMPLOYEE_TAX_BAND",
	section: "Employee",
	typeSection: "Tax_band"
};
export const PAYROLL_BASE_RATE_ADDITIONAL = {
	parameterType: "BASE_RATE_ADDITIONAL",
	section: "Process_Payroll",
	typeSection: "Base_Rate_Additional"
};
export const PARAMETER_QUANTITY_PAYROLL = {
	parameterType: "QUANTITY_PAYROLL",
	section: "Process_Payroll",
	typeSection: "Quantity"
};
