import * as common from "../constants/common";

const signUpUser = async (httpClient, email, password, ip, browser) => {
	const { goEpsApi } = window.envVariables;
	const requestData = {
		email,
		password,
		device: {
			platform: common.COMMON_LOGIN_PLATFORM,
			id: `${ip} ${browser.version} ${browser.os}`,
			deviceData: {
				ipPublic: `${ip}`,
				browserInfo: `${browser.version} ${browser.os}`,
				platform: `${browser.name}`
			}
		}
	};
	const response = await httpClient.post(`${goEpsApi}/api/v1/signup`, requestData);
	return response;
};

const forgotPasswordUser = async (httpClient, email) => {
	const { goEpsApi } = window.envVariables;
	const requestData = {
		login: {
			email
		}
	};

	const response = await httpClient.post(`${goEpsApi}/api/v1/employer/forgotpassword/generate`, requestData);
	return response;
};

const forgotValidationUser = async (httpClient, tokenForgot, ip, browser) => {
	const { goEpsApi } = window.envVariables;
	const requestData = {
		login: {
			tokenForgot,
			device: {
				platform: common.COMMON_LOGIN_PLATFORM,
				id: `${ip} ${browser.version} ${browser.os}`,
				deviceData: {
					ipPublic: `${ip}`,
					browserInfo: `${browser.version} ${browser.os}`,
					platform: `${browser.name}`
				}
			}
		}
	};

	const response = await httpClient.post(`${goEpsApi}/api/v1/employer/forgotpassword/validate`, requestData);
	return response.data;
};

const loginUnifyRequest = async (httpClient, tokenUnify, ip, browser) => {
	const { goEpsApi } = window.envVariables;
	const requestData = {
		tokenUnify,
		platform: common.COMMON_LOGIN_PLATFORM,
		id: `${ip} ${browser.version} ${browser.os}`,
		deviceData: {
			ipPublic: `${ip}`,
			browserInfo: `${browser.version} ${browser.os}`,
			platform: `${browser.name}`
		}
	};

	const response = await httpClient.post(`${goEpsApi}/api/v1/loginunify`, requestData);
	return response.data;
};

export default {
	signUpUser,
	forgotPasswordUser,
	forgotValidationUser,
	loginUnifyRequest
};
