import * as actionTypes from "../../constants/actionTypes";
import singleTouchInitialState from "./SingleTouchInitialState";

const singleTouchReducer = (state = singleTouchInitialState, action) => {
	switch (action.type) {
		case actionTypes.ACTION_STP_GET_REQUEST: {
			return {
				...state,
				isLoading: true
			};
		}
		case actionTypes.ACTION_STP_GET_REQUEST_SUCCESS: {
			return {
				...state,
				isLoading: false,
				STPList: action.payload.STPList,
				pendingList: action.payload.pendingList,
				lodgedList: action.payload.lodgedList,
				invalidList: action.payload.invalidList,
				completedList: action.payload.completedList,
				messageCode: action.payload.messageCode,
				message: action.payload.message
			};
		}
		case actionTypes.ACTION_STP_GET_REQUEST_FAILURE: {
			return {
				...state,
				error: true,
				isLoading: false,
				errorMessage: action.payload.errorMessage,
				messageCode: action.payload.messageCode,
				message: action.payload.message
			};
		}

		case actionTypes.ACTION_STP_UPDATE_REQUEST: {
			return {
				...state,
				isLoading: true
			};
		}
		case actionTypes.ACTION_STP_UPDATE_REQUEST_SUCCESS: {
			return {
				...state,
				isLoading: false,
				STPData: action.payload.STPData,
				messageCode: action.payload.messageCode,
				message: action.payload.message
			};
		}
		case actionTypes.ACTION_STP_UPDATE_REQUEST_FAILURE: {
			return {
				...state,
				error: true,
				isLoading: false,
				errorMessage: action.payload.errorMessage,
				messageCode: action.payload.messageCode,
				message: action.payload.message
			};
		}
		case actionTypes.ACTION_STP_CLEAN_DATA: {
			return {
				...state,
				STPData: action.payload.STPData
			};
		}
		case actionTypes.ACTION_SESSION_LOGOUT: {
			return singleTouchInitialState;
		}
		case actionTypes.ACTION_REDIRECT_TO_STP:{
			return{
				...state,
				redirectToSTP:action.payload.redirectToSTP
			}
		}
		default: {
			return {
				...state
			};
		}
	}
};

export default singleTouchReducer;
