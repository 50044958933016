import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";

import { LogoHeaderStyles } from "./commonStyles";

import logo from "../../assets/images/logo.svg";

const LogoHeaderCommonComponent = (props) => {
	const { classes } = props;

	return (
		<Grid container alignItems="center" justify={props.center ? "center"  : ""} spacing={2} className={classes.root} style={{ paddingLeft: (props.center ? 0 : 40)}} >
			<a href="https://www.easypayslip.com.au/" style={{ all: "unset", cursor: "pointer" }}>
				<Grid item>
					<img src={logo} alt="" className={classes.imageLogo} />
				</Grid>
			</a>
			<a href="https://www.easypayslip.com.au/" style={{ all: "unset", cursor: "pointer", marginLeft: "20px" }}>
				<Grid item>
					<Typography variant="h3" className="headerLogo">
						{"Easy Payslip"}
					</Typography>
				</Grid>
			</a>
		</Grid>
	);
};

export default withStyles(LogoHeaderStyles)(LogoHeaderCommonComponent);
