import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import {
	Drawer, Divider, List, Badge, ListItem, ListItemText
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import PayrollStyle from "./payrollStyle";
import { AvatarCommonComponent } from "../common";

class DrawerComponent extends Component {
	onHandleChoiceEmployee=(employee)=>()=>{
		 this.props.handleChoiceEmployee(employee)
	}
	render() {
		const { classes, selectedPayrollData } = this.props;
		return (
			<Drawer
				className={classes.drawer}
				variant="permanent"
				classes={{
					paper: classes.drawerPaper
				}}
				anchor="right"
			>
				<div className={classes.toolbar} />
				<Divider />
				<List>
					{selectedPayrollData
						&& selectedPayrollData.employees
						&& selectedPayrollData.employees.map(employee => (
							<ListItem button key={employee.id} onClick={this.onHandleChoiceEmployee(employee)} className={classes.employeeActive} selected={selectedPayrollData.employeeActive.id === employee.id}>
								{employee.isProcessed ? (
									<Badge className={classes.avatarBadge} badgeContent={<CheckIcon />} color="secondary">
										&nbsp;
									</Badge>
								) : null}
								<AvatarCommonComponent src={employee.imageUrl} text={employee.initials} isAct={selectedPayrollData.employeeActive.id === employee.id} />
								<ListItemText className={classes.avatarText} primary={employee.name} />
							</ListItem>
						))}
				</List>
			</Drawer>
		);
	}
}

DrawerComponent.propTypes = {
	selectedPayrollData: PropTypes.object,
	handleChoiceEmployee: PropTypes.func
};

const mapStateToProps = state => ({
	selectedPayrollData: state.payroll.selectedPayrollData
});

export default connect(
	mapStateToProps,
	null
)(withStyles(PayrollStyle)(DrawerComponent));
