import { createLogic } from "redux-logic";

import * as actionTypes from "../constants/actionTypes";
import * as apiResponses from "../constants/apiResponses";
import * as employerActions from "../actions/EmployerActions";
import * as accountantActions from "../actions/EasyPaySlipPtyActions";
import * as sessionActions from "../actions/SessionActions";
import employerApi from "../api/EmployerApi";
import commonApi from "../api/CommonApi";
import * as messages from "../constants/messages";
import * as route from "../constants/routes";
import { Analytics } from "../components/common";
import * as constants_common from "../constants/common";
import localStorageApi from "../api/LocalStorageApi";


const employerEmailGenerate = createLogic({
	type: actionTypes.ACTION_EMPLOYER_EMAIL_GENERATE,
	latest: true,
	async process({ httpClient }, dispatch, done) {
		try {
			const response = await employerApi.employerEmailGenerate(httpClient);
			if (response.status === 200) {
				dispatch(employerActions.employerEmailGenerateSuccess(response.status, "An email has been sent with your code. Please check your 'junk' folder if your do not receive it shortly."));
			} else {
				throw new Error(response.message);
			}
		} catch (err) {
			dispatch(employerActions.employerEmailGenerateFailure(err.response.data.message));
		}
		done();
	}
});

const employerEmailValidate = createLogic({
	type: actionTypes.ACTION_EMPLOYER_EMAIL_VALIDATE,
	latest: true,
	async process({ httpClient, action }, dispatch, done) {
		try {
			const response = await employerApi.employerEmailValidate(httpClient, action.payload.code2fa);

			if (response.status === 200) {

				dispatch(employerActions.employerEmailValidateSuccess(true, response.status, response.data.message));
				if (action.payload.accountType == "accountant") {
					dispatch(accountantActions.getAccountantDetails());
				} else {
					dispatch(employerActions.getEmployer());
				}

				dispatch(sessionActions.loadUserInformationThunk());
			} else {
				throw new Error(response.data.message);
			}
		} catch (err) {

			dispatch(employerActions.employerEmailValidateFailure(err.response.data.message));
		}
		done();
	}
});

// logic for 2FA text
const employerTextGenerate = createLogic({
	type: actionTypes.ACTION_EMPLOYER_TEXT_GENERATE,
	latest: true,
	async process({ httpClient }, dispatch, done) {
		try {
			const response = await employerApi.employerTextGenerate(httpClient);
			if (response.status === 200) {
				dispatch(employerActions.employerTextGenerateSuccess(response.status, "A text has been sent with your code"));
			} else {
				throw new Error(response.message);
			}
		} catch (err) {
			dispatch(employerActions.employerTextGenerateFailure(err.response.data.message));
		}
		done();
	}
});

const employerTextValidate = createLogic({
	type: actionTypes.ACTION_EMPLOYER_TEXT_VALIDATE,
	latest: true,
	async process({ httpClient, action }, dispatch, done) {
		try {
			const response = await employerApi.employerTextValidate(httpClient, action.payload.code2fa);
			if (response.status === 200) {

				dispatch(employerActions.employerTextValidateSuccess(true, response.status, response.data.message));

				dispatch(sessionActions.loadUserInformationThunk());
			} else {
				throw new Error(response.data.message);
			}
		} catch (err) {

			dispatch(employerActions.employerTextValidateFailure(err.response.data.message));
		}
		done();
	}
});



const employerTOPTGenerate = createLogic({
	type: actionTypes.ACTION_EMPLOYER_TOPT_GENERATE,
	latest: true,
	async process({ httpClient }, dispatch, done) {
		try {
			const response = await employerApi.employerTOPTGenerate(httpClient);
			if (response.messageCode === apiResponses.RESPONSE_OK) {
				const userData = response.item;

				dispatch(employerActions.employerTOPTGenerateSuccess(userData, userData.manualSetupKey, userData.qrCodeImage, response.messageCode, response.message));
			} else {
				throw new Error(response.message);
			}
		} catch (err) {
			dispatch(employerActions.employerTOPTGenerateFailure(err.message));
		}
		done();
	}
});

const employerTOPTValidate = createLogic({
	type: actionTypes.ACTION_EMPLOYER_TOPT_VALIDATE,
	latest: true,
	async process({ httpClient, action }, dispatch, done) {
		try {
			const response = await employerApi.employerTOPTValidate(httpClient, action.payload.code2fa);

			if (response.status === 200) {

				dispatch(employerActions.employerTOPTValidateSuccess(false, 2000, response.data.message));

				if (action.payload.accountType == "accountant") {

					dispatch(accountantActions.getAccountantDetails());
				} else {
					dispatch(employerActions.getEmployer());
				}
			} else {
				throw new Error(response.data.message);
			}
		} catch (err) {

			dispatch(employerActions.employerTOPTValidateFailure(err.response && err.response.data && err.response.data.message ? err.response.data.message : err.message));
		}
		done();
	}
});

const employerTOPTRevoke = createLogic({
	type: actionTypes.ACTION_EMPLOYER_TOPT_REVOKE,
	latest: true,
	async process({ httpClient, action }, dispatch, done) {
		try {
			const response = await employerApi.employerTOPTRevoke(httpClient, action.payload.password);

			if (response.status === 200) {

				dispatch(employerActions.employerTOPTRevokeSuccess(false, 2000, response.data.message));

				if (action.payload.accountType == "accountant") {

					dispatch(accountantActions.getAccountantDetails());
				} else {
					dispatch(employerActions.getEmployer());
				}
			} else {
				throw new Error(response.data.message);
			}
		} catch (err) {

			dispatch(employerActions.employerTOPTRevokeFailure(err.response && err.response.data && err.response.data.message ? err.response.data.message : err.message));
		}
		done();
	}
});

const employerTOPTDisabled = createLogic({
	type: actionTypes.ACTION_EMPLOYER_TOPT_DISABLED,
	latest: true,
	async process({ httpClient, action }, dispatch, done) {
		try {
			const response = await employerApi.employerTOPTDisabled(httpClient, action.payload.password);

			if (response.status === 200) {

				dispatch(employerActions.employerTOPTDisabledSuccess(false, 2000, response.data.message));

				if (action.payload.accountType == "accountant") {

					dispatch(accountantActions.getAccountantDetails());
				} else {
					dispatch(employerActions.getEmployer());
				}
			} else {
				if (response.status == 401) {
					dispatch(sessionActions.cleanSession());
				}
				throw new Error(response.data.message);
			}
		} catch (err) {
			dispatch(employerActions.employerTOPTDisabledFailure(err.response && err.response.data && err.response.data.message ? err.response.data.message : err.message));
		}
		done();
	}
});

const getEmployer = createLogic({
	type: actionTypes.ACTION_EMPLOYER_REQUEST,
	latest: true,
	async process({ httpClient, action }, dispatch, done) {
		try {
			const response = await employerApi.getEmployer(httpClient);

			if (response.messageCode === apiResponses.RESPONSE_OK) {

				//#region save user in STORAGE
				let userData = localStorageApi.getItem(constants_common.STORAGE_SESSION_NAME);
				let newuserdata = {
					...userData,
					...response.item
				}
				localStorageApi.setItem(constants_common.STORAGE_SESSION_NAME, newuserdata);
				//#endregion
				dispatch(employerActions.getEmployerSuccess(newuserdata, response.messageCode, response.message));
				dispatch(sessionActions.userUpdateInfo(newuserdata, response.messageCode, response.message));
				console.log("GET EMPLOYER");


				Analytics.updateProfileInfoAction(response.item);
			} else {
				dispatch(employerActions.getEmployerFailure(response.messageCode, response.message));
			}
		} catch (err) {
			if (err.response && err.response.status == 401) {
				dispatch(sessionActions.cleanSession());
			}

			dispatch(employerActions.getEmployerFailure("", err.message));
		}
		done();
	}
});

const getAuthorizedPerson = createLogic({
	type: actionTypes.ACTION_AUTHORIZED_PERSON_REQUEST,
	latest: true,
	async process({ httpClient, action }, dispatch, done) {
		try {
			const response = await employerApi.getAuthorizedPerson(httpClient);
			if (response.status == 200) {
				dispatch(employerActions.getAuthorizedPersonSuccess(response.authorizedName, response.status, response.message));
				dispatch(sessionActions.updateAuthorizedPerson(response.authorizedName, response.status, response.message));
			} else {
				dispatch(employerActions.getAuthorizedPersonFailure(response.status, response.message));
			}
		} catch (err) {
			dispatch(employerActions.getAuthorizedPersonFailure("", err.message));
		}
		done();
	}
});

const employerUpdate = createLogic({
	type: actionTypes.ACTION_EMPLOYER_UPDATE,
	latest: true,
	async process({ httpClient, action }, dispatch, done) {
		// debugger
		try {
			const response = await employerApi.employerUpdate(httpClient, action.payload.employerData);
			if (response.messageCode === apiResponses.RESPONSE_OK) {
				console.log('complete register');

				Analytics.completeRegisterAction();
				Analytics.updateProfileInfoAction(action.payload.employerData);

				dispatch(employerActions.employerUpdateSuccess(response.item, response.messageCode, messages.MESSAGE_EMPLOYER_UPDATE));
				dispatch(sessionActions.userUpdateInfo(response.item, response.messageCode, response.message));
			} else {
				dispatch(employerActions.employerUpdateFailure(response.messageCode, response.message));
			}
		} catch (err) {
			dispatch(employerActions.employerUpdateFailure("", err.message));
		}
		done();
	}
});

const employerUpdateBusinessInfo = createLogic({
	type: actionTypes.ACTION_EMPLOYER_UPDATE_BUSINESS_INFO,
	latest: true,
	async process({ httpClient, action }, dispatch, done) {
		// debugger
		try {
			const response = await employerApi.employerUpdate(httpClient, action.payload.employerData);
			if (response.messageCode === apiResponses.RESPONSE_OK) {

				Analytics.updateProfileInfoAction(action.payload.employerData);

				dispatch(employerActions.employerUpdateBusinessInfoSuccess(response.item, response.messageCode));
				dispatch(sessionActions.userUpdateInfo(response.item, response.messageCode, response.message));

			} else {
				dispatch(employerActions.employerUpdateBusinessInfoFailure(response.messageCode, response.message));
			}
		} catch (err) {
			dispatch(employerActions.employerUpdateBusinessInfoFailure("", err.message));
		}
		done();
	}
});

const verifyAbn = createLogic({
	type: actionTypes.ACTION_EMPLOYER_VERIFY_ABN,
	latest: true,
	async process({ httpClient, action }, dispatch, done) {
		try {
			const response = await commonApi.ABNVerification(httpClient, action.payload.abn);
			if (response.status === 200) {
				response.data.number = response.data.number || action.payload.abn;
				dispatch(employerActions.verifyAbnSuccess(response.data, response.messageCode, response.message));
			} else {
				dispatch(employerActions.verifyAbnFailure(response.status, response.data.message));
			}
		} catch (err) {
			dispatch(employerActions.verifyAbnFailure("", err.response && err.response.data && err.response.data.message ? err.response.data.message : "Something went wrong, please try again later"));
		}
		done();
	}
});

const verifyWpn = createLogic({
	type: actionTypes.ACTION_EMPLOYER_VERIFY_WPN,
	latest: true,
	async process({ httpClient, action }, dispatch, done) {
		try {
			const response = await commonApi.WPNVerification(httpClient, action.payload.wpn);
			if (response.status === 200 && response.data && response.data.valid) {
				dispatch(employerActions.verifyWpnSuccess({ number: action.payload.wpn }, response.status, response.data.message));
			} else {
				dispatch(employerActions.verifyWpnFailure(response.status, response.data.message));
			}
		} catch (err) {
			dispatch(employerActions.verifyWpnFailure("", err.response && err.response.data && err.response.data.message ? err.response.data.message : "Something went wrong, please try again later"));
		}
		done();
	}
});

const closeFYRequest = createLogic({
	type: actionTypes.ACTION_EMPLOYER_CLOSE_FY_REQUEST,
	latest: true,
	async process({ httpClient, action }, dispatch, done) {
		try {
			const response = await employerApi.closeFYRequest(httpClient, action.payload.closeFY);
			if (response.messageCode === apiResponses.RESPONSE_OK) {
				const item = response.item.employer;

				const userData = item;
				userData.email = item.login.email;
				userData.token = item.login.device.sessionToken;
				userData.enable2FA = item.login.enable2FA;
				userData.messageCode = response.messageCode;
				userData.message = response.message;
				// debugger
				dispatch(employerActions.closeFYRequestSuccess(userData, response.messageCode, response.message));
				dispatch(sessionActions.userLoggingInSuccess(userData, userData.token, userData.enable2FA, response.messageCode, response.message));

				dispatch(sessionActions.loadUserInformationThunk());

				action.payload.history.push(route.PATH_EMPLOYEE);
			} else {
				dispatch(employerActions.closeFYRequestFailure(response.messageCode, response.message));
			}
		} catch (err) {
			dispatch(employerActions.closeFYRequestFailure(apiResponses.RESPONSE_FAIL, err.message));
		}
		done();
	}
});

const generateLastPaymentsRequest = createLogic({
	type: actionTypes.ACTION_EMPLOYER_GENERATE_LAST_PAYMENTS_REQUEST,
	latest: true,
	async process({ httpClient, action }, dispatch, done) {
		try {
			const response = await employerApi.generateLastPaymentsRequest(httpClient, action.payload.closeFY);
			if (response.messageCode === apiResponses.RESPONSE_OK) {
				dispatch(employerActions.generateLastPaymentsRequestSuccess(true, response.messageCode, response.message));
			} else {
				dispatch(employerActions.generateLastPaymentsRequestFailure(response.messageCode, response.message));
			}
		} catch (err) {
			dispatch(employerActions.generateLastPaymentsRequestFailure(apiResponses.RESPONSE_FAIL, err.message));
		}
		done();
	}
});
const redirectToYourBusiness = createLogic({
	type: actionTypes.ACTION_REDIRECT_TO_YOUR_BUSINESS,
})
const nextSlid = createLogic({
	type: actionTypes.ACTION_NEXT_SLID,
	latest: true,
	async process({ httpClient, action }, dispatch, done) {

		done();
	}
})
const tempRequestDataPayRoll = createLogic({
	type: actionTypes.ACTION_TEMP_REQUEST_DATA_PAYROLL,
})
const redirectToPaySlip = createLogic({
	type: actionTypes.ACTION_TEMP_REQUEST_DATA_REDIRECT_TO_PAYSLIP
})

const cleanData = createLogic({
	type: actionTypes.ACTION_EMPLOYER_CLEAN_DATA
})

export default [getAuthorizedPerson, employerEmailGenerate, employerEmailValidate,
	employerTOPTGenerate, employerTOPTValidate, employerTOPTRevoke, employerTOPTDisabled,
	getEmployer, employerUpdate, verifyAbn, verifyWpn, closeFYRequest, generateLastPaymentsRequest,
	employerTextGenerate, employerTextValidate, redirectToYourBusiness, nextSlid, employerUpdateBusinessInfo,
	tempRequestDataPayRoll, redirectToPaySlip, cleanData
];
