import initialState from "../../store/initialState";

// export const accountantAdditionalDefaultData = {
//   email: ""
// };

const employerAccountsInitialState = {
  ...initialState,
  employersAccountant: [],
  employerAccountsData: {}
};

export default employerAccountsInitialState;
