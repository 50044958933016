const getAllSTP = async httpClient => {
  const { epsApi, goEpsApi } = window.envVariables;

  // Hey Samaneh please leave this here, I'm testing a separate api for this route
  // const response = await httpClient.get(`https://eps-api-dev.ataylor.xyz/api/v1/stp`);
  const response = await httpClient.get(`${goEpsApi}/api/v1/stp`);
  // console.log(response.data);
  return response.data;
};

const updateSTP = async (httpClient, STPData) => {
  const { goEpsApi } = window.envVariables;
  const response = await httpClient.put(`${goEpsApi}/api/v1/stp/update`, STPData);
  return response;
};

const generateReportSummary = async (httpClient, reportSummary) => {
  const { epsApi } = window.envVariables;
  const response = await httpClient.post(`${epsApi}/api/v2/STP`, reportSummary);
  return response.data;
};

const sendToSTP = async (httpClient, reportSummary) => {
	const { goEpsApi } = window.envVariables;
	const response = await httpClient.put(`${goEpsApi}/api/v1/stp/send`, reportSummary);
	return response;
};

const deleteSTP = async (httpClient, id) => {
  const { goEpsApi } = window.envVariables;
  const response = await httpClient.delete(
    `${goEpsApi}/api/v1/stp/${id}`
  );

  return response;
}

export default {
  getAllSTP,
  updateSTP,
  generateReportSummary,
  sendToSTP,
  deleteSTP
};
