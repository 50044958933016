import * as actionTypes from "../../constants/actionTypes";
import reportsInitialState from "./ReportsInitialState";

const reportsReducer = (state = reportsInitialState, action) => {
	switch (action.type) {
		case actionTypes.ACTION_REPORTS_PROCESS_REQUEST: {
			return {
				...state,
				isLoading: true
			};
		}
		case actionTypes.ACTION_REPORTS_PROCESS_REQUEST_SUCCESS: {
			return {
				...state,
				isLoading: false,
				reportData: action.payload.reportData,
				messageCode: action.payload.messageCode,
				message: action.payload.message
			};
		}
		case actionTypes.ACTION_REPORTS_PROCESS_REQUEST_FAILURE: {
			return {
				...state,
				error: true,
				isLoading: false,
				errorMessage: action.payload.errorMessage,
				messageCode: action.payload.messageCode,
				message: action.payload.message
			};
		}

		case actionTypes.ACTION_REPORTS_PAYSLIPS_REQUEST: {
			return {
				...state,
				isLoading: true,
				filterData: action.payload.filterData
			};
		}
		case actionTypes.ACTION_REPORTS_PAYSLIPS_REQUEST_SUCCESS: {
			return {
				...state,
				isLoading: false,
				payslips: action.payload.payslips,
				messageCode: action.payload.messageCode,
				message: action.payload.message
			};
		}
		case actionTypes.ACTION_REPORTS_PAYSLIPS_REQUEST_FAILURE: {
			return {
				...state,
				error: true,
				isLoading: false,
				errorMessage: action.payload.errorMessage,
				messageCode: action.payload.messageCode,
				message: action.payload.message
			};
		}

		case actionTypes.ACTION_PAYROLL_SEND_EMAIL_REQUEST: {
			return {
				...state,
				isLoading: true
			};
		}
		case actionTypes.ACTION_PAYROLL_SEND_EMAIL_REQUEST_SUCCESS: {
			return {
				...state,
				isLoading: false,
				messageCode: action.payload.messageCode,
				message: action.payload.message
			};
		}
		case actionTypes.ACTION_PAYROLL_SEND_EMAIL_REQUEST_FAILURE: {
			return {
				...state,
				error: true,
				isLoading: false,
				errorMessage: action.payload.errorMessage,
				messageCode: action.payload.messageCode,
				message: action.payload.message
			};
		}

		case actionTypes.ACTION_PAYROLL_DELETE_REQUEST: {
			return {
				...state,
				isLoading: true
			};
		}
		case actionTypes.ACTION_PAYROLL_DELETE_REQUEST_SUCCESS: {
			return {
				...state,
				isLoading: false,
				messageCode: action.payload.messageCode,
				message: action.payload.message
			};
		}
		case actionTypes.ACTION_PAYROLL_DELETE_REQUEST_FAILURE: {
			return {
				...state,
				error: true,
				isLoading: false,
				errorMessage: action.payload.errorMessage,
				messageCode: action.payload.messageCode,
				message: action.payload.message
			};
		}

		case actionTypes.ACTION_REPORTS_CLEAN_DATA: {
			return {
				...state,
				reportData: action.payload.reportData
			};
		}

		case actionTypes.ACTION_REPORTS_PAYSLIPS_CLEAN_DATA: {
			return {
				...state,
				payslips: action.payload.payslips
			};
		}
		case actionTypes.ACTION_REPORTS_CLEAN_MESSAGE: {
			return {
				...state,
				error: false,
				isLoading: false,
				errorMessage: action.payload.errorMessage,
				messageCode: action.payload.messageCode,
				message: action.payload.message
			};
		}
		case actionTypes.ACTION_SESSION_LOGOUT: {
			return reportsInitialState;
		}

		// process Report PayrollActivityDetail
		case actionTypes.ACTION_REPORTS_PROCESS_ACTIVITY_DETAIL_REQUEST: {
			return {
				...state,
				isLoading: true
			};
		}
		case actionTypes.ACTION_REPORTS_PROCESS_ACTIVITY_DETAIL_REQUEST_SUCCESS: {
			return {
				...state,
				isLoading: false,
				reportData: action.payload.reportData,
				messageCode: action.payload.messageCode,
				message: action.payload.message
			};
		}
		case actionTypes.ACTION_REPORTS_PROCESS_ACTIVITY_DETAIL_REQUEST_FAILURE: {
			return {
				...state,
				error: true,
				isLoading: false,
				errorMessage: action.payload.errorMessage,
				messageCode: action.payload.messageCode,
				message: action.payload.message
			};
		}



		default: {
			return {
				...state
			};
		}
	}
};

export default reportsReducer;
