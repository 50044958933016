import initialState from "../../store/initialState";

export const accountantDetailsDefaultData = {
  login: {
    email: ""
  },
  details: {
    first: "",
    middle: "",
    last: "",
    phone: "",
    title: "Other",
    jobDesc: "",
    RAN: ""
  },
  id: null,
  status: 0
}

const accountantInitialState = {
  ...initialState,
  accountantDetails: [],
  accountantDetailsData: {
    ...accountantDetailsDefaultData
  }
};

export default accountantInitialState;
