import { createLogic } from "redux-logic";

import onboardApi from "../api/OnboardApi";
import * as onboardActions from "../actions/OnboardActions";
import * as actionTypes from "../constants/actionTypes";
import * as apiResponses from "../constants/apiResponses";
import { detect } from "detect-browser";
import publicIp from "public-ip";
import mixpanel from "mixpanel-browser";
import * as route from "../constants/routes";
import localStorageApi from "../api/LocalStorageApi";
import * as common from "../constants/common";
import store from "../store";
import { loadUserInformationThunk } from "../actions/SessionActions";
import { getAllParametersRequest } from "../actions/ParameterActions";
import { Analytics } from "../components/common";

// Initialise An Onboarding Session
const InitialiseAnOnboardingRequest = createLogic({
	type: actionTypes.ACTION_SEND_ONBOARD,
	latest: true,
	async process({ httpClient, action }, dispatch, done) {
		try {
			let ip;
			try {
				ip = await publicIp.v4();
			} catch {
				ip = "127.0.0." + Math.floor(255 * Math.random());
			}
			const browser = detect();
			const response = await onboardApi.InitialiseAnOnboardingRequest(httpClient, action.payload.OnboardingData, ip, browser);

			if (response.status === apiResponses.RESPONSE_OK_STATUS) {
				dispatch(onboardActions.InitialiseAnOnboardingRequestSuccess(response.data.sessionToken, response.status, response.data.message));

				//--set localStorage sessionToken
				let userData = {
					token: response.data.sessionToken
				}
				localStorageApi.setItem(common.STORAGE_SESSION_NAME, userData);

				//--change route for latest slide in QS
				action.payload.OnboardingData.accountType === "employer" && action.payload.history.push(route.PATH_QUICK_SETUP);
				action.payload.OnboardingData.accountType === "accountant" && action.payload.history.push(route.PATH_QUICK_SETUP_ACCOUNTANT);

			} else {
				dispatch(onboardActions.InitialiseAnOnboardingRequestFailure(response.status, response.data.message));
			}
		} catch (err) {

			err.response && err.response.status && dispatch(onboardActions.InitialiseAnOnboardingRequestFailure(err.response.status, err.response.data.message));
		}
		done();
	}
});

// Update Data Fields During Onboarding
const updateDataDuringOnboarding = createLogic({
	type: actionTypes.ACTION_ONBOARD_UPDATE_REQUEST,
	latest: true,
	async process({ httpClient, action }, dispatch, done) {
		try {

			const response = await onboardApi.updateDataDuringOnboarding(httpClient, action.payload.businessInf);

			if (response.status === apiResponses.RESPONSE_OK_STATUS) {
				dispatch(onboardActions.updateDataDuringOnboardingSuccess(response.data.accountType, response.status, response.data.message));
				action.payload.isQS_Employer && action.payload.currentIndex === 3 && Analytics.OB_4ContactAAction();
				action.payload.isQS_Employer && action.payload.currentIndex === 3 && dispatch(onboardActions.finishOnboardingGetDataAccount());
				action.payload.isQS_Accountant && action.payload.currentIndex === 4 && dispatch(onboardActions.finishOnboardingGetDataAccount());

			} else {
				dispatch(onboardActions.updateDataDuringOnboardingFailure(response.status, response.data.message));
			}
		} catch (err) {
			err.response && err.response.status && dispatch(onboardActions.updateDataDuringOnboardingFailure(err.response.status, err.response.data.message));
		}
		done();
	}
});

// Complete The Initial Onboarding and Create Account
const finishOnboardingGetDataAccount = createLogic({
	type: actionTypes.ACTION_ONBOARD_GET_REQUEST,
	latest: true,
	async process({ httpClient, action }, dispatch, done) {
		try {

			const response = await onboardApi.finishOnboardingGetDataAccount(httpClient);

			if (response.status === apiResponses.RESPONSE_OK_STATUS) {
				dispatch(onboardActions.finishOnboardingGetDataAccountSuccess(response.data, response.status, response.data.message));
				// mixpanel.alias(response.data.employer.id)

				//--set localStorage
				const userData = localStorageApi.getItem(common.STORAGE_SESSION_NAME);
				let userNewData = {}
				if (response.data.accountType === "accountant") {
					mixpanel.alias(response.data.accountant.id);

					userNewData = {
						accountType: response.data.accountType,
						...userData,
						...response.data.accountant
					}
				} else {
					mixpanel.alias(response.data.employer.id);

					userNewData = {
						accountType: response.data.accountType,
						...userData,
						...response.data.employer
					}

				}

				localStorageApi.setItem(common.STORAGE_SESSION_NAME, userNewData);
				store.dispatch(loadUserInformationThunk());
				store.dispatch(getAllParametersRequest());
			} else {
				dispatch(onboardActions.finishOnboardingGetDataAccountFailure(response.status, response.data.message));
			}
		} catch (err) {
			err.response && err.response.status && dispatch(onboardActions.finishOnboardingGetDataAccountFailure(err.response.status, err.response.data.message));
		}
		done();
	}
});

export default [InitialiseAnOnboardingRequest, updateDataDuringOnboarding, finishOnboardingGetDataAccount];
