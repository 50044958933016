import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Paper, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import reportsStyle from "./reportsStyle";
import * as reportPaymentActions from "../../actions/ReportPaymentActions";
import reportsEmpty from "../../assets/images/reports.empty.png";
import { RemoveRedEye } from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import DialogCommonPDFComponent from "../common/dialogCommonPDFComponent";

class ReportEmployerComponent extends Component {
	state = {
		dialogOpen: false,
		url: ''
	};

	componentDidMount() {
		const { getAllPaymentsEmployer } = this.props;
		getAllPaymentsEmployer();
	}

	handleShowClick = (url) => {
		this.setState({ dialogOpen: true, url });
	};

	handleDialogClose = () => {
		this.setState({ dialogOpen: false });
	};

	render() {
		const { classes, paymentsEmployer, isLoading } = this.props;
		const { dialogOpen, url } = this.state;

		const payments = (paymentsEmployer && paymentsEmployer.length > 0) ? paymentsEmployer.map(payment => (
			<Paper className={classes.paper} key={payment.id}>
				<Grid container wrap="nowrap" spacing={2} alignItems="center">
					<Grid item>
						<Typography variant="body1" className="bold">{payment.period}</Typography>
					</Grid>
					<Grid item xs>
						<Typography variant="body1">{payment.employerName}</Typography>
					</Grid>
					<Grid item md className={classes.alignItemMenu}>
						<IconButton onClick={() => { this.handleShowClick(payment.urlResume); }}>
							<RemoveRedEye />
						</IconButton>
					</Grid>
				</Grid>
			</Paper>
		)) : null;

		return (
			<Typography component="div">
				{(!paymentsEmployer || paymentsEmployer.length <= 0) && !isLoading ? (
					<div className={classes.imageEmpty}>
						<img className={classes.imageEmptyImage} src={reportsEmpty} alt="" />
					</div>
				) : null}

				{payments}

				<DialogCommonPDFComponent
					key="reportEmployerPayment"
					title="Employer Payment Summary"
					open={dialogOpen}
					onCloseModal={this.handleDialogClose}
					url={url}
				/>
			</Typography>
		);
	}
}

ReportEmployerComponent.propTypes = {
	paymentsEmployer: PropTypes.array,
	getAllPaymentsEmployer: PropTypes.func
};

const mapStateToProps = state => ({
	paymentsEmployer: state.reportsPayment.paymentsEmployer,
	isLoading: state.reportsPayment.isLoading || state.payment.isLoading,
});

const mapDispatchToProps = dispatch => ({
	getAllPaymentsEmployer: bindActionCreators(reportPaymentActions.paymentsEmployerRequest, dispatch)
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(reportsStyle)(ReportEmployerComponent));
