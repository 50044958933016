import React, { Component } from "react";
import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

import { connect } from "react-redux";
import { withRouter } from "react-router";
import EmployerStyles from "./employerStyle";

import { CardCommonComponent } from "../common";
import ModalSetupABNComponent from "./modalSetupABNComponent";
import ModalSetupWPNComponent from "./modalSetupWPNComponent";
import AbnInformationComponent from "./abnInformationComponent";
import WpnInformationComponent from "./wpnInformationComponent";

class EmployerBusinessEntitlementComponent extends Component {
	state = {
		selectedABN: false,
		selectedWPN: false,
		showModalABN: false,
		showModalWPN: false
	};

	handleClickOptionABN = () => {
		const { isLoading } = this.props;
		if (isLoading) return;
		this.setState(state => ({
			selectedABN: !state.selectedABN,
			selectedWPN: !state.selectedABN ? false : state.selectedWPN,
			showModalABN: true
		}));
	};

	handleClickOptionWPN = () => {
		const { isLoading } = this.props;
		if (isLoading) return;
		this.setState(state => ({
			selectedWPN: !state.selectedWPN,
			selectedABN: !state.selectedWPN ? false : state.selectedABN,
			showModalWPN: true
		}));
	};

	handleCloseModalABN = () => {
		this.setState(() => ({ showModalABN: false }));
		this.handleClose();
	};

	handleCloseModalWPN = () => {
		this.setState(() => ({ showModalWPN: false }));
		this.handleClose();
	};

	handlePrimaryClickModal = () => {
		this.setState(() => ({ showModalABN: false, showModalWPN: false }));
	};

	handleClose = () => {
		const { formProps } = this.props;
		const ABNVerified = !!(formProps.values.company.abn || {}).number;
		if (!ABNVerified) formProps.setFieldValue("company.abn", {});
		formProps.setFieldValue("company.wpn", {});
	};

	handleEdit = () => {
		this.setState({ showModalABN: true });
	}

	render() {
		const { classes, formProps, abn } = this.props;
		const {
			selectedABN, selectedWPN, showModalABN, showModalWPN
		} = this.state;

		const ABNVerified = !!(formProps.values.company.abn || {}).number;
		const savedABNVerified = !!(abn || {}).number;
		const WPNVerified = !!(formProps.values.company.wpn || {}).number;

		let control = "";

		if (ABNVerified) {
			control = <AbnInformationComponent {...this.props} onClose={this.handleClose} onEdit={this.handleEdit} />;
		} else if (WPNVerified) {
			control = <WpnInformationComponent {...this.props} onClose={this.handleClose} />;
		} else {
			control = (
				<div className={clsx(classes.selectContainer, classes.cardBottomSpace)}>
					<div
						className={clsx(classes.selectOptions, {
							[classes.selectOptionsActive]: selectedABN,
							[classes.selectOptionsActiveABN]: selectedABN,
							hoverParent: true
						})}
						onClick={this.handleClickOptionABN}
						role="presentation"
					>
						<Typography variant="h3" className={clsx("hoverFont", { [classes.selectOptionsTitleActive]: selectedABN })}>
							Australian Business Number
						</Typography>
						<Typography variant="h5" className={clsx(classes.selectOptionsSubTitle, { hoverChildren: true })}>
							Click to select
						</Typography>
					</div>
					<div
						className={clsx(classes.selectOptions, {
							[classes.selectOptionsActive]: selectedWPN,
							[classes.selectOptionsActiveWPN]: selectedWPN,
							hoverParent: true
						})}
						onClick={this.handleClickOptionWPN}
						role="presentation"
					>
						<Typography variant="h3" className={clsx("hoverFont", { [classes.selectOptionsTitleActive]: selectedWPN })}>
							Withholding Payer Number
						</Typography>
						<Typography variant="h5" className={clsx(classes.selectOptionsSubTitle, { hoverChildren: true })}>
							Click to select
						</Typography>
					</div>
				</div>
			);
		}

		return (
			<CardCommonComponent title="Business entitlement" complete={ABNVerified || WPNVerified} collapseExtend>
				{control}

				<ModalSetupABNComponent readonly={savedABNVerified} open={showModalABN} onPrimaryButtonClick={this.handlePrimaryClickModal} onCloseModal={this.handleCloseModalABN} formProps={formProps} />
				<ModalSetupWPNComponent open={showModalWPN} onPrimaryButtonClick={this.handlePrimaryClickModal} onCloseModal={this.handleCloseModalWPN} formProps={formProps} />
			</CardCommonComponent>
		);
	}
}

const mapStateToProps = state => ({
	isLoading: state.employer.isLoading,
	abn: state.employer.employerData.company ? state.employer.employerData.company.abn : {},
});

export default connect(
	mapStateToProps,
	null
)(withRouter(withStyles(EmployerStyles)(EmployerBusinessEntitlementComponent)));
