export const MESSAGE_GENERIC_ERROR = "Error, please try again later";
export const MESSAGE_GENERIC_TEST = "CHANGE";

export const MESSAGE_AVATAR_UPDATED = "Photo successfully updated";
export const MESSAGE_AVATAR_ERROR = "Error loading image,  please try again";

export const MESSAGE_EMPLOYEE_UPDATE = "Employee successfully updated";
export const MESSAGE_EMPLOYEE_INACTIVE = "The employee has been successfully inactivated";
export const MESSAGE_EMPLOYEE_ACTIVE = "The employee has been successfully activated";

export const MESSAGE_EMPLOYER_UPDATE = "Employer successfully updated";

export const MESSAGE_PAYSLIP_DELETED = "Payslip successfully deleted";
export const MESSAGE_EMAIL_SENT = "Email sent";

export const MESSAGE_PAYROLL_ADDITIONAL_EXISTS = "An Additional Base Rate with this name has already been added.";
export const MESSAGE_PAYROLL_QUANTITIE_EXISTS = "A quantity with this name has already been added.";
export const MESSAGE_PAYROLL_OVERTIME_EXISTS = "An Overtime with this same data has already been added.";
export const MESSAGE_PAYROLL_OVERTIME_LIMIT = "Maximum 6 Overtimes.";
export const MESSAGE_PAYROLL_ALLOWANCES_EXISTS = "An Allowance with this same data has already been added.";
export const MESSAGE_PAYROLL_DEDUCTIONS_EXISTS = "An Deduction with this same data has already been added.";
export const MESSAGE_PAYROLL_ALLOWANCES_LIMIT = "Maximum 10 Allowances.";
export const MESSAGE_PAYROLL_DEDUCTIONS_LIMIT = "Maximum 6 Deductions.";
export const MESSAGE_PAYROLL_UNTAXED_ALLOWANCES_EXISTS = "An Untaxed Allowance with this same data has already been added.";
export const MESSAGE_PAYROLL_UNTAXED_ALLOWANCES_LIMIT = "Maximum 10 Untaxed Allowance.";


export const MESSAGE_NOTIFICATION_UPDATE = "Notifications successfully updated";
