import React from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { PropTypes } from "prop-types";
import axios from "axios";
import { CircularProgress } from "@material-ui/core";

import DialogCommonComponent from "./dialogCommonComponent";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class DialogCommonPDFComponent extends React.Component {
  constructor() {
    super();

    this.state = {
      NumPages: null
    };
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ NumPages: numPages });
  };

  handleDownloadPDF = () => {
    const { url, title } = this.props;

    axios({
      url,
      method: "GET",
      responseType: "blob" // important
    }).then(response => {
      const urlLink = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = urlLink;
      link.setAttribute("download", `${title}.pdf`);
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(link);
    });
  };

  render() {
    const {
      title,
      textInfo,
      textInfoStyle,
      subtitle,
      subtitle2,
      style,
      size,
      style2,
      secondaryButtonNameDiscard,
      btnBack,
      styleBtnBack,
      styleDiscard,
      styleSubtitle,
      styleSubtitle2,
      styleTitle,
      open,
      onCloseModal,
      url,
      primaryButtonName,
      onPrimaryButtonClick,
      secondaryButtonName,
      onSecondaryButtonClick,
      showScroll,
      downloadPdf,
      downloadStyle
    } = this.props;

    const { NumPages } = this.state;

    return (
      <DialogCommonComponent
        title={title}
        subtitle={subtitle}
        subtitle2={subtitle2}
        style={style}
        style2={style2}
        styleTitle={styleTitle}
        styleSubtitle={styleSubtitle}
        styleSubtitle2={styleSubtitle2}
        styleDiscard={styleDiscard}
        open={open}
        btnBack={btnBack}
        styleBtnBack={styleBtnBack}
        onCloseModal={onCloseModal}
        size={size ? size : "lg"}
        primaryButtonName={primaryButtonName || "Download"}
        onPrimaryButtonClick={onPrimaryButtonClick || this.handleDownloadPDF}
        secondaryButtonNameDiscard={secondaryButtonNameDiscard}
        secondaryButtonName={secondaryButtonName}
        onSecondaryButtonClick={onSecondaryButtonClick}
        showScroll={showScroll}
        textInfo={textInfo}
        textInfoStyle={textInfoStyle}
        downloadPdf={downloadPdf}
        downloadStyle={downloadStyle}
      >
        {!url ? (
          <CircularProgress />
        ) : (
          <Document
            file={`${url}`}
            onLoadSuccess={this.onDocumentLoadSuccess}
            loading={<CircularProgress />}
          >
            {Array.from(new Array(NumPages), (page, index) => (
              <div
                key={`page_${index + 1}`}
                style={{
                  boxShadow: "0 25px 50px 0 rgba(62,62,62,.15)",
                  marginBottom: 15,
                  marginTop: 15
                }}
              >
                <Page
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  renderAnnotationLayer
                  width={920}
                />
              </div>
            ))}
          </Document>
        )}
      </DialogCommonComponent>
    );
  }
}

DialogCommonPDFComponent.propTypes = {
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
  showScroll: PropTypes.bool
};

export default DialogCommonPDFComponent;
