import * as actionTypes from "../constants/actionTypes";

export function savePayrollDataRequest(payrollData) {
	return {
		type: actionTypes.ACTION_PAYROLL_SAVE_DATA_REQUEST,
		payload: {
			selectedPayrollData: {
				...payrollData
			}
		}
	};
}

export function savePayrollDataRequestSuccess(payrollData, messageCode, message) {
	return {
		type: actionTypes.ACTION_PAYROLL_SAVE_DATA_REQUEST_SUCCESS,
		payload: {
			selectedPayrollData: {
				...payrollData
			},
			messageCode,
			message
		}
	};
}

export function savePayrollDataRequestFailure(messageCode, message) {
	return {
		type: actionTypes.ACTION_PAYROLL_SAVE_DATA_REQUEST_FAILURE,
		error: true,
		payload: {
			messageCode,
			message
		}
	};
}

export function setCleanDataToStore(payrollData) {
	return {
		type: actionTypes.ACTION_PAYROLL_CLEAN_DATA,
		payload: {
			selectedPayrollData: {
				...payrollData
			}
		}
	};
}

export function getEmployeeDataRequest() {
	return {
		type: actionTypes.ACTION_PAYROLL_GET_EMPLOYEE_REQUEST,
		payload: {}
	};
}

export function getEmployeeDataRequestSuccess(employeeData, messageCode, message) {
	return {
		type: actionTypes.ACTION_PAYROLL_GET_EMPLOYEE_REQUEST_SUCCESS,
		payload: {
			employeeData,
			messageCode,
			message
		}
	};
}

export function getEmployeeDataRequestFailure(messageCode, message) {
	return {
		type: actionTypes.ACTION_PAYROLL_GET_EMPLOYEE_REQUEST_FAILURE,
		error: true,
		payload: {
			messageCode,
			message
		}
	};
}

export function addPayrollRequest(payroll) {
	return {
		type: actionTypes.ACTION_PAYROLL_ADD_REQUEST,
		payload: {
			payroll
		}
	};
}

export function addPayrollRequestSuccess(payroll, messageCode, message) {
	return {
		type: actionTypes.ACTION_PAYROLL_ADD_REQUEST_SUCCESS,
		payload: {
			payroll,
			messageCode,
			message
		}
	};
}

export function addPayrollRequestFailure(messageCode, message) {
	return {
		type: actionTypes.ACTION_PAYROLL_ADD_REQUEST_FAILURE,
		error: true,
		payload: {
			messageCode,
			message
		}
	};
}

export function sendPayrollRequest(payroll) {
	return {
		type: actionTypes.ACTION_PAYROLL_SEND_MAIL_REQUEST,
		payload: {
			payroll,
			isSendEmail: false
		}
	};
}

export function sendPayrollRequestSuccess(payroll, messageCode, message) {
	return {
		type: actionTypes.ACTION_PAYROLL_SEND_MAIL_REQUEST_SUCCESS,
		payload: {
			payroll,
			messageCode,
			message,
			isSendEmail: true
		}
	};
}

export function sendPayrollRequestFailure(messageCode, message) {
	return {
		type: actionTypes.ACTION_PAYROLL_SEND_MAIL_REQUEST_FAILURE,
		error: true,
		payload: {
			messageCode,
			message,
			isSendEmail: false
		}
	};
}

export function deletedPayrollRequest(id, mixpanel) {
	return {
		type: actionTypes.ACTION_PAYROLL_DELETED_REQUEST,
		payload: {
			id,
			mixpanel
		}
	};
}

export function deletedPayrollRequestSuccess(deleted, messageCode, message) {
	return {
		type: actionTypes.ACTION_PAYROLL_DELETED_REQUEST_SUCCESS,
		payload: {
			deleted,
			messageCode,
			message
		}
	};
}

export function deletedPayrollRequestFailure(messageCode, message) {
	return {
		type: actionTypes.ACTION_PAYROLL_DELETED_REQUEST_FAILURE,
		error: true,
		payload: {
			messageCode,
			message
		}
	};
}

export function nextEmployeePayrollRequest() {
	return {
		type: actionTypes.ACTION_PAYROLL_SAVE_PAYLOAD_DATA_REQUEST,
		payload: {}
	};
}

export function nextEmployeePayrollRequestSuccess(selectedPayrollData, isFinishAllEmployees, messageCode, message) {
	return {
		type: actionTypes.ACTION_PAYROLL_SAVE_PAYLOAD_DATA_REQUEST_SUCCESS,
		payload: {
			selectedPayrollData,
			payroll: null,
			messageCode,
			message,
			isFinishAllEmployees
		}
	};
}

export function nextEmployeePayrollRequestFailure(messageCode, message) {
	return {
		type: actionTypes.ACTION_PAYROLL_SAVE_PAYLOAD_DATA_REQUEST_FAILURE,
		error: true,
		payload: {
			messageCode,
			message
		}
	};
}

export function nextEmployeePayrollRequestIsFinal(isFinalPayrollData) {
	return {
		type: actionTypes.ACTION_PAYROLL_VALIDATE_IS_FINAL_SUCCESS,
		payload: {
			listPayrollDataisFinal: isFinalPayrollData
		}
	};
}
export function isFinalClean() {
	return {
		type: actionTypes.ACTION_PAYROLL_VALIDATE_IS_FINAL_SUCCESS,
		payload: {
			listPayrollDataisFinal: null
		}
	};
}

export function validatePayrollRequest() {
	return {
		type: actionTypes.ACTION_PAYROLL_VALIDATE_REQUEST,
		payload: {}
	};
}

export function validatePayrollRequestSuccess(selectedPayrollData, messageCode, message) {
	return {
		type: actionTypes.ACTION_PAYROLL_VALIDATE_REQUEST_SUCCESS,
		payload: {
			selectedPayrollData: { ...selectedPayrollData },
			messageCode,
			message
		}
	};
}

export function validatePayrollRequestFailure(messageCode, message) {
	return {
		type: actionTypes.ACTION_PAYROLL_VALIDATE_REQUEST_FAILURE,
		error: true,
		payload: {
			messageCode,
			message
		}
	};
}

export function nextEmployeePayrollRequestThunk(selectedPayrollData, isFinishAllEmployees, messageCode, message) {
	return async (dispatch) => {
		try {
			await Promise.resolve(dispatch(nextEmployeePayrollRequestSuccess(selectedPayrollData, isFinishAllEmployees, messageCode, message)));
			return (await !isFinishAllEmployees) ? Promise.resolve(dispatch(getEmployeeDataRequest())) : null;
		} catch (err) {
			// TODO: Add redirect to login
		}
		return null;
	};
}

export function savePayrollDataComplete(payrollData, history, location) {

	return {
		type: actionTypes.ACTION_PAYROLL_SAVE_PAYLOAD_DATA_COMPLETE,
		payload: {
			payrollData,
			history,
			location
		}
	};
}

export function sendPayrollNextData(payroll) {
	return {
		type: actionTypes.ACTION_PAYROLL_SEND_MAIL_NEXT,
		payload: {
			payroll
		}
	};
}
