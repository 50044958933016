import localStorageApi from "../api/LocalStorageApi";
import * as common from "../constants/common";

const zendeskConfigScript = () => {
	const userData = localStorageApi.getItem(common.STORAGE_SESSION_NAME);

	if (userData && userData.companyName) {
		let searchTerm = "";
		let headings = document.getElementsByTagName("h1");
		if (headings.length > 0)  {
			searchTerm = headings[0].textContent;
		} else if (window.location.href.endsWith("/employer/register")) {
			searchTerm = "employer"
		} else {
			let pathSplit = window.location.href.split("/");
			searchTerm = pathSplit[pathSplit.length - 1];
		}

		const content = [
			"zE(function () {",
			"   zE.setLocale('en-au');",
			"   zE.identify({",
			`       name: '${userData.companyName}',`,
			`       email: '${userData.login.email}',`,
			`       organization: '${userData.subscription.typeSubscription === 0 ? "Free" : "Subscription"}'`,
			"   });",
			"   zE('webWidget', 'helpCenter:setSuggestions', { search: '" + searchTerm + "' })",
			"});",
			"",
			"window.zESettings = {",
			"   webWidget: {",
			"       color: {",
			"           theme: '#2A3845'",
			"       },",
			"       contactOptions: {",
			"           enabled: true,",
			"           contactButton: { '*': 'Get in touch' }",
			"       },",
			"       chat: {",
			"           suppress: false",
			"       },",
			"       launcher: {",
			"           chatLabel: {",
			"               '*': 'Chat now'",
			"           }",
			"       },",
			// "       helpCenter: {",
			// "           setSuggestions: {",
			// "			    search: '{ pageHeading }'",
			// "           }",
			// "       }",
			"   }",
			"};"
		].join("\n");

		const scriptZE = document.createElement("script");
		scriptZE.id = "ze-snippet-config";
		scriptZE.textContent = content;
		document.head.appendChild(scriptZE);

		scriptZE.onload = () => {};
	}
};

const zendeskScript = () => {
	const scriptZE = document.createElement("script");
	scriptZE.src = "https://static.zdassets.com/ekr/snippet.js?key=3ecff2d0-019e-4cb1-af96-6402fca8e43a";
	scriptZE.id = "ze-snippet";
	document.head.appendChild(scriptZE);

	scriptZE.onload = () => {
		zendeskConfigScript();
	};
};

const zendeskScriptRemove = () => {
	const existScriptConfig = document.getElementById("ze-snippet-config");
	if (existScriptConfig) {
		existScriptConfig.parentNode.removeChild(existScriptConfig);
	}

	const existScript = document.getElementById("ze-snippet");
	if (existScript) {
		existScript.parentNode.removeChild(existScript);
	}
};

export { zendeskScript as ZendeskScript, zendeskScriptRemove as ZendeskScriptRemove };
