import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import {
	Box, Grid, FormControl, Typography, Paper, Button
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Formik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";

import { LogoHeaderCommonComponent, Input2FACommonComponent } from "../common";

import * as sessionActions from "../../actions/SessionActions";
import * as employerActions from "../../actions/EmployerActions";
import { ExternalUserStyle } from "./sessionStyle";

class LoginComponent extends Component {
	validationSchema = Yup.object({
		code2fa: Yup.string().required("Code 2FA is required"),
		ElementCodeNumber1: Yup.string().required("Element Code Number1 is required"),
		ElementCodeNumber2: Yup.string().required("Element Code Number2 is required"),
		ElementCodeNumber3: Yup.string().required("Element Code Number3 is required"),
		ElementCodeNumber4: Yup.string().required("Element Code Number4 is required"),
		ElementCodeNumber5: Yup.string().required("Element Code Number5 is required"),
		ElementCodeNumber6: Yup.string().required("Element Code Number6 is required"),
	});
	constructor(props) {
		super(props);

		this.state = {
			empty: false,
		};
	}
	componentWillReceiveProps(nextProps) {
		if (nextProps.error && this.props.error != nextProps.error) {
			this.setState({ empty: true });
		}
	}
	handleSubmitLogin = (values, actions) => {
		const { logging2FA, method2FA, logging2FAEmail, logging2FAText, history } = this.props;
		actions.setSubmitting(false);
		const code2fa = `${values.ElementCodeNumber1}${values.ElementCodeNumber2}${values.ElementCodeNumber3}${values.ElementCodeNumber4}${values.ElementCodeNumber5}${values.ElementCodeNumber6}`;

		if (method2FA === "email") {
			this.setState({ empty: false });
			logging2FAEmail(code2fa, history);
		} else if (method2FA === 'text') {
			this.setState({ empty: false });
			logging2FAText(code2fa, history);
		} else {
			logging2FA(code2fa, history);
		}

	};
	protect_email(user_email) {
		// return user_email.replace(/^(.).+(.)@(.)[a-zA-z0-9]+(.)\./, "$1***$2@$3****$4.")
		return user_email;
	};
	handleEmail2FAClick = () => {
		const { employerEmailGenerate, history } = this.props;
		employerEmailGenerate(history);
	};
	handleText2FAClick = () => {
		const { employerTextGenerate, history } = this.props;
		employerTextGenerate(history);
	};
	// { values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, }
	renderForm = (formProps) => {
		const { classes, error, method2FA, userdata } = this.props;

		return (
			<Grid className={classes.root} container justify="center" alignItems="flex-start">
				<LogoHeaderCommonComponent />
				<Grid container className={classes.rootContainer} justify="center" alignContent="center">
					<Grid item xs={7}>

						<Typography variant="h1" gutterBottom>
							{"Two-Factor Authentication"}
						</Typography>
						{method2FA !== "email" && method2FA !== "text" ? (
							<React.Fragment>
								<Typography variant="body1" gutterBottom>
									{"Access your authentification app to get the 6 digits code, enter below to access."}
								</Typography>

							</React.Fragment>
						) : (
								<React.Fragment>
									{method2FA === "email" && <Typography variant="body1" gutterBottom>
										{"Click the 'Get Code' button to receive an email with your 6 digit code that you will need to enter below to continue."}
									</Typography>}
									{method2FA === "text" && <Typography variant="body1" gutterBottom>
										{"Click the 'Get Code' button to receive a text with your 6 digit code that you will need to enter below to continue."}
									</Typography>}
									<Paper className={classes.paper}>
										<Grid container wrap="nowrap" alignItems="center">
											<Grid item xs alignItems="center">

												{method2FA === "email" &&
													<Typography variant="body1"> {userdata.accountType === "accountant" ? this.protect_email(userdata.email) : this.protect_email(userdata.login.email)}</Typography>
												}

												{method2FA === "text" && <Typography variant="body1">{'Text : '}{userdata.phoneNumber}</Typography>}

											</Grid>
											<Grid>
												{method2FA === 'email' && <Button
													variant="contained"
													onClick={this.handleEmail2FAClick}
													className={clsx(classes.btnRectangle, classes.orangeRectangularBtn)}
													color="secondary">
													{"Get Code"}
												</Button>}
												{method2FA === 'text' && <Button
													variant="contained"
													onClick={this.handleText2FAClick}
													className={clsx(classes.btnRectangle, classes.orangeRectangularBtn)}
													color="secondary">
													{"Get Code"}
												</Button>}
											</Grid>
										</Grid>
									</Paper>
								</React.Fragment>
							)}
						<Box m={2.5} />
						<form className={classes.container} onSubmit={formProps.handleSubmit}>
							<FormControl fullWidth margin="normal" className={classes.input2FA}>
								<Input2FACommonComponent iconInTop formik={this.addForm} formProps={formProps} error={error || this.state.empty} submitOnChange />
							</FormControl>
						</form>
					</Grid>
				</Grid>
			</Grid>
		);
	};

	render() {
		const { classes } = this.props;
		return (
			<Grid container justify="center" className={classes.root}>

				<Formik
					ref={(innerForm) => {
						this.addForm = innerForm;
					}}
					initialValues={{
						code2fa: "",
						ElementCodeNumber1: "",
						ElementCodeNumber2: "",
						ElementCodeNumber3: "",
						ElementCodeNumber4: "",
						ElementCodeNumber5: "",
						ElementCodeNumber6: ""
					}}
					validationSchema={this.validationSchema}
					onSubmit={this.handleSubmitLogin}
					render={this.renderForm}
				/>
			</Grid>
		);
	}
}

LoginComponent.propTypes = {
	history: PropTypes.object,
	classes: PropTypes.object,
	error: PropTypes.bool,
	method2FA: PropTypes.string,
	// errorMessage: PropTypes.string,

	logging2FA: PropTypes.func
};

const mapStateToProps = state => {

	return ({
		isLoading: state.session.isLoading,
		error: state.session.error,
		method2FA: state.session.method2FA,
		userdata: state.session.userData,
		errorMessage: state.session.errorMessage
	})
};

const mapDispatchToProps = dispatch => ({
	employerEmailGenerate: bindActionCreators(employerActions.employerEmailGenerate, dispatch),
	employerTextGenerate: bindActionCreators(employerActions.employerTextGenerate, dispatch),
	logging2FAEmail: bindActionCreators(sessionActions.userLogging2FAEmail, dispatch),
	logging2FAText: bindActionCreators(sessionActions.userLogging2FAText, dispatch),
	logging2FA: bindActionCreators(sessionActions.userLogging2FA, dispatch)
});

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(withStyles(ExternalUserStyle)(LoginComponent))
);
