const reportsStyles = theme => ({
	root: {
		...theme.root,
		paddingBottom: 20
	},
	paper: {
		width: "100%",
		height: 65,
		borderRadius: 5,
		border: "1px solid #e9e9e9",
		backgroundColor: "#ffffff",
		padding: "10px 20px",
		boxShadow: "none",
		marginBottom: 12,
		"&:last-child": {
			marginBottom: 0
		},
		"&:hover": {
			boxShadow: "0 2px 2px 0 #D8D8D8"
		}
	},
	payslip: {
		marginBottom: 0,
		borderRadius: 0
	},
	period: {
		background: "#f9f9f9",
		padding: "10px 20px 9px 43px",
		fontSize: 17,
		fontWeight: "600"
	},
	titleMR: {
		background: "#ffffff",
		padding: "0 43px 10px 43px"
	},
	titleMRText: {
		marginLeft: "-1px",
		"&:last-child": {
			marginLeft: 7
		}
	},
	titleMRAvatar: {
		paddingLeft: 20
	},
	IconCheck: {
		fontSize: 23,
		color: "#D8D8D8"
	},
	IconCheckActive: {
		fontSize: 23,
		color: "#007aff"
	},
	alignItemMenu: {
		textAlign: "right"
	},
	divider: {
		marginBottom: 18,
		marginTop: 10
	},
	gridContainer: {
		marginBottom: 16
	},
	gridLeft: {
		paddingLeft: 30
	},
	gridRight: {
		textAlign: "right"
	},
	gridRightClearButton: {
		marginBottom: 0
	},
	imageEmpty: {
		width: "100%",
		textAlign: "center",
		marginTop: 40
	},
	imageEmptyImage: {
		width: 738,
		marginBottom: 20
	},
	imageEmptyModal: {
		width: 550,
		marginBottom: 20
	},
	subtitleHeader: {
		fontFamily: "AvenirNextLTPro-Regular",
		color: "#7D848B"
	},
	paperContainer: {
		...theme.paperContainer
	},
	paperGrid: {
		...theme.paperClear
	},
	paperGridClearRight: {
		paddingRight: 0
	},
	option: {
		...theme.option
	},
	keyboardDatePicker: {
		...theme.keyboardDatePicker
	},
	keyboardDatePickerInput: {
		...theme.keyboardDatePickerInput
	},
	tabs: {
		backgroundColor: "transparent",
		marginTop: theme.spacing(5),
		marginBottom: theme.spacing(5),
		width:'100% !important'
	},
	tab: {
		backgroundColor: "transparent",
		width:'20% !important',
		textTransform: "none",
		fontWeight: "200",
		color: "#7D848B",
		fontSize: theme.typography.pxToRem(27),
		height: 'max-content',
		 padding: 0,
		"&:first-child": {
			// padding: "6px 20px 6px 0"
		},
		"&:nth-child(2)": {
			minWidth: 100
		},
		"&:last-child": {
			// padding: "6px 0 6px 20px"
		}
	},
	formContainer: {
		width: "100%"
	},
	orangeRectangularBtn: {
		...theme.overrides.orangeRectangularBtn
	},
	disabledButtonSecondary: {
		...theme.overrides.disabledButtonSecondary
	}
});

export default reportsStyles;
