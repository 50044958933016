import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter, Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import {
	Box, Button, TextField, Grid, Link, FormControl, FormHelperText, Typography
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Formik } from "formik";
import clsx from "clsx";
import * as Yup from "yup";

import { LogoHeaderCommonComponent } from "../common";

import * as routes from "../../constants/routes";
import * as externalUsers from "../../actions/ExternalUserActions";
import ForgotScreen from "../../assets/images/forgotScreen.png";
import { ExternalUserStyle } from "./sessionStyle";

class ForgotPasswordComponent extends Component {
	validationSchema = Yup.object({
		email: Yup.string()
			.email("Email is invalid")
			.required("Email is required")
	});

	handleSubmitForgot = (values, actions) => {
		const { forgotPassword, history } = this.props;
		actions.setSubmitting(false);
		forgotPassword(values.email, history);
	};

	// { values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, }
	renderForm = (formProps) => {
		const { classes, error, errorMessage } = this.props;

		console.log(error, errorMessage);

		return (
			<Grid className={classes.root} container justify="center" alignItems="flex-start">
				<LogoHeaderCommonComponent />
				<Grid
					container
					className={clsx(classes.rootContainer, {
						forgot: true
					})}
				>
					<Grid item xs={6} className={classes.leftColumn}>
						<Typography variant="h1" gutterBottom>
							Forgot Password
						</Typography>
						<Typography variant="body1" gutterBottom>
							{"We'll send you an email with instructions to reset your password"}
						</Typography>
						<Box m={3} />
						<form className={classes.container}>
							<FormControl fullWidth margin="normal">
								<TextField
									autoFocus
									fullWidth
									id="email"
									label="Email*"
									placeholder="test@test.com"
									type="email"
									variant="filled"
									value={formProps.values.email}
									onChange={formProps.handleChange}
									onBlur={formProps.handleBlur}
									className={clsx(classes.textField, {
										error: formProps.errors.email && formProps.touched.email
									})}
								/>
							</FormControl>
							<FormControl fullWidth margin="normal">
								<Box m={2.5} />
								<Button variant="contained" color="primary" className={classes.loginBtn} onClick={formProps.handleSubmit} type="submit">
									Recover
								</Button>
								{error && <FormHelperText error>{errorMessage}</FormHelperText>}
							</FormControl>
							<FormControl fullWidth>
								<Link component={RouterLink} to={routes.PATH_SIGN_UP} className={clsx("pointer", { medium: true })} underline="none" variant="inherit">
									{"Don’t have an account? "}
									<Typography variant="subtitle1" className="orange">
										{"Sign up"}
									</Typography>
								</Link>
							</FormControl>
						</form>
					</Grid>
					<Grid item xs={6} className={classes.rigthColumn}>
						<img src={ForgotScreen} alt="" className={classes.rigthColumnImg} />
					</Grid>
				</Grid>
			</Grid>
		);
	};

	render() {
		const { classes } = this.props;
		return (
			<Grid container justify="center" className={classes.root}>
				<Formik initialValues={{ email: "" }} validationSchema={this.validationSchema} onSubmit={this.handleSubmitForgot} render={this.renderForm} />
			</Grid>
		);
	}
}

ForgotPasswordComponent.propTypes = {
	history: PropTypes.object,
	classes: PropTypes.object,
	error: PropTypes.bool,
	errorMessage: PropTypes.string,

	forgotPassword: PropTypes.func
};

const mapStateToProps = state => ({
	error: state.session.error,
	errorMessage: state.session.errorMessage
});

const mapDispatchToProps = dispatch => ({
	forgotPassword: bindActionCreators(externalUsers.userForgotPassword, dispatch)
});

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(withStyles(ExternalUserStyle)(ForgotPasswordComponent))
);
