import sessionLogic from "./SessionLogic";
import employeeLogic from "./EmployeeLogic";
import reportsLogic from "./ReportLogic";
import paymentLogic from "./PaymentLogic";
import externalUserLogic from "./ExternalUserLogic";
import employerTOPTGenerate from "./EmployerLogic";
import parametersLogic from "./ParametersLogic";
import activateSubscription from "./SubscriptionLogic";
import singleTouchLogin from "./SingleTouchLogic";
import payrollLogic from "./PayrollLogic";
import stpLogic from "./STPLogic";
import notificationLogic from "./NotificationLogic";
import AdditionalUserLogic from "./AdditionalUserLogic";
import CouponLogic from './CouponLogic'
import accountantLogic from "./AccountantLogic";
import easyPaySlipPtyLogic from "./EasyPaySlipPtyLogic";
import onboardLogic from "./OnboardLogic";
import yecpLogic from "./YECPLogic"
import fyLogic from "./FYLogic"

const logics = [
	...sessionLogic,
	...employeeLogic,
	...reportsLogic,
	...paymentLogic,
	...externalUserLogic,
	...employerTOPTGenerate,
	...parametersLogic,
	...activateSubscription,
	...singleTouchLogin,
	...payrollLogic,
	...stpLogic,
	...notificationLogic,
	...AdditionalUserLogic,
	...CouponLogic,
	...accountantLogic,
	...easyPaySlipPtyLogic,
	...onboardLogic,
	...yecpLogic,
	...fyLogic,
];

export default logics;
