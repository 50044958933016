// <<<<<<< HEAD
// import React, { Component } from "react";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";

// import clsx from "clsx";
// import { withStyles } from "@material-ui/core/styles";

// import { Formik, Field } from "formik";
// import * as Yup from "yup";

// import {
// 	Button, Grid, Typography, Paper
// } from "@material-ui/core";

// import { KeyboardDatePicker } from "@material-ui/pickers";
// import PropTypes from "prop-types";
// import reportsStyles from "./reportsStyle";
// import * as reportActions from "../../actions/ReportActions";
// import reportsEmpty from "../../assets/images/reports.empty.png";
// import EmployeesComponent from "./employeesComponent";

// import DialogPayslipsComponent from "./dialogPayslipsComponent";
// import { getFirstDay, getLastDay } from "../../helpers/dateextensions";
// import { CardCommonComponent } from "../common";

// const DatePickerField = ({
// 	field, form, classes, minDate, maxDate, ...other
// }) => {
// 	const currentError = form.errors[field.name];
// 	return (
// 		<KeyboardDatePicker type="date-local"
// 			name={field.name}
// 			label={field.label}
// 			value={field.value}
// 			format="DD/MM/YYYY"
// 			fullWidth
// 			error={Boolean(currentError)}
// 			onError={(_, error) => form.setFieldError(field.name, error)}
// 			onChange={date => form.setFieldValue(field.name, date, true)}
// 			className={clsx(classes.textField, {
// 				errorDatePicker: Boolean(currentError)
// 			})}
// 			InputProps={{
// 				classes: {
// 					root: classes.keyboardDatePicker,
// 					input: classes.keyboardDatePickerInput
// 				}
// 			}}
// 			minDate={minDate}
// 			maxDate={maxDate}
// 			{...other}
// 		/>
// 	);
// };

// class ReportPayslipsComponent extends Component {
// 	validationSchema = Yup.object({
// 		startDate: Yup.date().required("Start Date is required"),
// 		finishDate: Yup.date()
// 			.required("Finish Date is required")
// 			.when("startDate", (startDate, schema) => startDate && schema.min(startDate, "The start date must be less than the end date.")),
// 		employeeIds: Yup.array().required("Selected employees")
// 	});

// 	state = {
// 		dialogOpen: false
// 	};

// 	componentWillReceiveProps = (nextProps) => {
// 		if (nextProps.payslips) {
// 			this.setState({ dialogOpen: true });
// 		}
// 	};

// 	handleSubmitReport = async (values, actions) => {
// 		const { getAllPayslips } = this.props;

// 		actions.setSubmitting(false);

// 		await getAllPayslips({
// 			filterStartDate: values.startDate,
// 			filterEndDate: values.finishDate,
// 			filterEmployees: values.employeeIds.map(employeeId => ({
// 				id: employeeId
// 			}))
// 		});
// 	};

// 	handleDialogClose = () => {
// 		const { clearPayslips } = this.props;
// 		this.setState({ dialogOpen: false });
// 		clearPayslips(null);
// 	};

// 	renderForm = (formProps) => {
// 		const {
// 			classes, employees, payslips, isLoading
// 		} = this.props;
// 		const { dialogOpen } = this.state;
// 		return (
// 			<form className={classes.formContainer}>
// 				<Typography component="div">
// 					<Grid container alignItems="center" className={classes.gridContainer}>
// 						<Grid item xs={12} className={classes.gridRight}>
// 							<Button variant="contained" color="primary" className={classes.gridRightClearButton} onClick={formProps.handleSubmit} disabled={!formProps.isValid || isLoading}>
// 								{"Process Report"}
// 							</Button>
// 						</Grid>
// 					</Grid>
// 					{(!employees || employees.length <= 0) && !isLoading ? (
// 						<div className={classes.imageEmpty}>
// 							<img className={classes.imageEmptyImage} src={reportsEmpty} alt="" />
// 						</div>
// 					) : null}
// 					{employees && employees.length > 0 ? (
// 						<div>
// 							<CardCommonComponent title="Payment Dates Between" complete={false} hideExpandCheck>
// 								<Grid container alignItems="flex-start">
// 									<Grid item xs={3}>
// 										<Paper className={classes.paperGrid}>
// 											<Field name="startDate" label="Start Date" component={DatePickerField} classes={classes} />
// 										</Paper>
// 									</Grid>
// 									<Grid item xs={3}>
// 										<Paper className={classes.paperGrid}>
// 											<Field name="finishDate" label="Finish Date" component={DatePickerField} classes={classes} />
// 										</Paper>
// 									</Grid>
// 								</Grid>
// 							</CardCommonComponent>
// 							<EmployeesComponent employees={employees} formProps={formProps} name="employeeIds" />
// 						</div>
// 					) : null}

// 					{payslips ? <DialogPayslipsComponent key="payslipsReport" title="Payslips Report" open={dialogOpen} onCloseModal={this.handleDialogClose} payslips={payslips} /> : null}

// 					<Grid container alignItems="center" className={classes.gridContainer}>
// 						<Grid item xs={12} className={classes.gridRight}>
// 							<Button variant="contained" color="primary" className={classes.gridRightClearButton} onClick={formProps.handleSubmit} disabled={!formProps.isValid || isLoading}>
// 								{"Process Report"}
// 							</Button>
// 						</Grid>
// 					</Grid>
// 				</Typography>
// 			</form>
// 		);
// 	};

// 	render() {
// 		const { classes } = this.props;

// 		const dataReport = {
// 			startDate: getFirstDay(),
// 			finishDate: getLastDay(),
// 			employeeIds: []
// 		};

// 		return (
// 			<Grid container className={classes.root}>
// 				<Formik initialValues={{ ...dataReport }} validationSchema={this.validationSchema} onSubmit={this.handleSubmitReport} render={this.renderForm} enableReinitialize />
// 			</Grid>
// 		);
// 	}
// }

// ReportPayslipsComponent.propTypes = {
// 	employees: PropTypes.array,
// 	getAllPayslips: PropTypes.func
// };

// const mapStateToProps = state => ({
// 	payslips: state.reports.payslips,
// 	isLoading: state.reports.isLoading || state.employees.isLoading
// });

// const mapDispatchToProps = dispatch => ({
// 	getAllPayslips: bindActionCreators(reportActions.payslipsRequest, dispatch),
// 	clearPayslips: bindActionCreators(reportActions.clearPayslips, dispatch)
// });

// export default connect(
// 	mapStateToProps,
// 	mapDispatchToProps
// )(withStyles(reportsStyles)(ReportPayslipsComponent));
// =======
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";

import { Formik, Field } from "formik";
import * as Yup from "yup";

import {
	Button, Grid, Typography, Paper
} from "@material-ui/core";

import { KeyboardDatePicker } from "@material-ui/pickers";
import PropTypes from "prop-types";
import reportsStyles from "./reportsStyle";
import * as reportActions from "../../actions/ReportActions";
import reportsEmpty from "../../assets/images/reports.empty.png";
import EmployeesComponent from "./employeesComponent";

import DialogPayslipsComponent from "./dialogPayslipsComponent";
import { getFirstDay, getLastDay, getLocalToUTC, getUTCToLocal, getUTCDate } from "../../helpers/dateextensions";
import { CardCommonComponent } from "../common";

const DatePickerField = ({
	field, form, classes, minDate, maxDate, ...other
}) => {
	const currentError = form.errors[field.name];
	return (
		<KeyboardDatePicker type="date-local"
			name={field.name}
			label={field.label}
			value={getUTCToLocal(field.value)}
			format="DD/MM/YYYY"
			fullWidth
			error={Boolean(currentError)}
			onError={(_, error) => form.setFieldError(field.name, error)}
			onChange={date => form.setFieldValue(field.name, date, true)}
			className={clsx(classes.textField, {
				errorDatePicker: Boolean(currentError)
			})}
			InputProps={{
				classes: {
					root: classes.keyboardDatePicker,
					input: classes.keyboardDatePickerInput
				}
			}}
			minDate={minDate}
			maxDate={maxDate}
			{...other}
		/>
	);
};

class ReportPayslipsComponent extends Component {
	validationSchema = Yup.object({
		startDate: Yup.date().required("Start Date is required"),
		finishDate: Yup.date()
			.required("Finish Date is required")
			.when("startDate", (startDate, schema) => startDate && schema.min(startDate, "The start date must be less than the end date.")),
		employeeIds: Yup.array().required("Selected employees")
	});

	state = {
		dialogOpen: false
	};

	componentWillReceiveProps = (nextProps) => {
		if (nextProps.payslips) {
			this.setState({ dialogOpen: true });
		}
	};

	handleSubmitReport = async (values, actions) => {
		const { getAllPayslips } = this.props;

		actions.setSubmitting(false);
		values.finishDate=getLocalToUTC(values.finishDate)
		values.startDate=getLocalToUTC(values.startDate)

		await getAllPayslips({
			filterStartDate: values.startDate,
			filterEndDate: values.finishDate,
			filterEmployees: values.employeeIds.map(employeeId => ({
				id: employeeId
			}))
		});
	};

	handleDialogClose = () => {
		const { clearPayslips } = this.props;
		this.setState({ dialogOpen: false });
		clearPayslips(null);
	};

	renderForm = (formProps) => {
		const {
			classes, employees, payslips, isLoading
		} = this.props;
		const { dialogOpen } = this.state;
		return (
			<form className={classes.formContainer}>
				<Typography component="div">
					<Grid container alignItems="center" className={classes.gridContainer}>
						<Grid item xs={12} className={classes.gridRight}>
							<Button
								variant="contained"
								color="secondary"
								disabled={!formProps.isValid || isLoading}
								className={clsx(classes.buttonFinish, classes.gridRightClearButton, classes.orangeRectangularBtn, { [classes.disabledButtonSecondary]: !formProps.isValid || isLoading })}
								onClick={formProps.handleSubmit}
							>
								{"Process Report"}
							</Button>
						</Grid>
					</Grid>
					{(!employees || employees.length <= 0) && !isLoading ? (
						<div className={classes.imageEmpty}>
							<img className={classes.imageEmptyImage} src={reportsEmpty} alt="" />
						</div>
					) : null}
					{employees && employees.length > 0 ? (
						<div>
							<CardCommonComponent title="Payment Dates Between" complete={false} hideExpandCheck>
								<Grid container alignItems="flex-start">
									<Grid item xs={3}>
										<Paper className={classes.paperGrid}>
											<Field name="startDate" label="From" component={DatePickerField} classes={classes} />
										</Paper>
									</Grid>
									<Grid item xs={3}>
										<Paper className={classes.paperGrid}>
											<Field name="finishDate" label="To" component={DatePickerField} classes={classes} />
										</Paper>
									</Grid>
								</Grid>
								<Typography variant="body1">
									Report search is based on payslip payment date, not pay period dates
								</Typography>
							</CardCommonComponent>
							<EmployeesComponent employees={employees} formProps={formProps} name="employeeIds" />
						</div>
					) : null}

					{payslips ? <DialogPayslipsComponent key="payslipsReport" title="Payslips Report" open={dialogOpen} onCloseModal={this.handleDialogClose} payslips={payslips} /> : null}

					<Grid container alignItems="center" className={classes.gridContainer}>
						<Grid item xs={12} className={classes.gridRight}>
							<Button
								variant="contained"
								color="secondary"
								disabled={!formProps.isValid || isLoading}
								className={clsx(classes.buttonFinish, classes.gridRightClearButton, classes.orangeRectangularBtn, { [classes.disabledButtonSecondary]: !formProps.isValid || isLoading })}
								onClick={formProps.handleSubmit}
							>
								{"Process Report"}
							</Button>
						</Grid>
					</Grid>
				</Typography>
			</form>
		);
	};

	render() {
		const { classes } = this.props;

		const dataReport = {
			startDate: getFirstDay(),
			finishDate: getLastDay(),
			employeeIds: []
		};

		return (
			<Grid container className={classes.root}>
				<Formik initialValues={{ ...dataReport }} validationSchema={this.validationSchema} onSubmit={this.handleSubmitReport} render={this.renderForm} enableReinitialize />
			</Grid>
		);
	}
}

ReportPayslipsComponent.propTypes = {
	employees: PropTypes.array,
	getAllPayslips: PropTypes.func
};

const mapStateToProps = state => ({
	payslips: state.reports.payslips,
	isLoading: state.reports.isLoading || state.employees.isLoading
});

const mapDispatchToProps = dispatch => ({
	getAllPayslips: bindActionCreators(reportActions.payslipsRequest, dispatch),
	clearPayslips: bindActionCreators(reportActions.clearPayslips, dispatch)
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(reportsStyles)(ReportPayslipsComponent));
// >>>>>>> EPS-136
