import { combineReducers } from "redux";

import sessionReducer from "./Session/SessionReducer";
import employeeReducer from "./Employees/EmployeeReducer";
import reportsReducer from "./Reports/ReportsReducer";
import reportsPaymentReducer from "./Reports/ReportsPaymentReducer";
import paymentReducer from "./Payment/PaymentReducer";
import externalUserReducer from "./Session/ExternalUserReducer";
import employerReducer from "./Employer/EmployerReducer";
import parameterReducer from "./Parameters/ParameterReducer";
import subscriptionReducer from "./Subscription/SubscriptionReducer";
import singleTouchReducer from "./SingleTouch/SingleTouchReducer";
import payrollReducer from "./Payroll/PayrollReducer";
import stpReducer from "./STP/STPReducer";
import notificationReducer from "./Notification/NotificationReducer";
import AdditionalUserAccountantReducer from "./Accountant/AccountantReducer";
import couponReducer from './Coupon/CouponReducer'
import EmployersAccountantReducer from "./EmployerAccountant/EmployerAccountantReducer";
import EasyPaySlipPtyReducer from "./EasyPaySlipPty/EasyPaySlipPtyReducer";
import OnboardReducer from "./Onboard/OnboardReducer";
import yecpReducer from "./YECP/YECPReducer"

const reducers = combineReducers({
	session: sessionReducer,
	employees: employeeReducer,
	reports: reportsReducer,
	reportsPayment: reportsPaymentReducer,
	payment: paymentReducer,
	external: externalUserReducer,
	employer: employerReducer,
	parameters: parameterReducer,
	subscription: subscriptionReducer,
	singleTouch: singleTouchReducer,
	payroll: payrollReducer,
	stp: stpReducer,
	notification: notificationReducer,
	AdditionalUserAccountant: AdditionalUserAccountantReducer,
	coupon: couponReducer,
	employersAccountant: EmployersAccountantReducer,
	accountantDetails: EasyPaySlipPtyReducer,
	onboard: OnboardReducer,
	yecp: yecpReducer
});

export default reducers;
