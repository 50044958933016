const facebookSDKConfigScript = () => {
	const existScript = document.getElementById("fb-snippet-config");

	if (!existScript) {
		const scriptFB = document.createElement("script");
		scriptFB.id = "fb-snippet-config";
		scriptFB.src = "https://connect.facebook.net/en_US/sdk.js";
		document.head.appendChild(scriptFB);

		scriptFB.onload = () => {};
	}
};

const facebookSDKScript = () => {
	const existScript = document.getElementById("fb-snippet");

	if (!existScript) {
		const { facebookId } = window.envVariables;

		const content = ["window.fbAsyncInit = function() {", "   FB.init({", `       appId: '${facebookId}',`, "       xfbml: true,", "       version: 'v2.8'", "   });", "   FB.AppEvents.logPageView();", "};"].join("\n");

		const scriptFB = document.createElement("script");
		scriptFB.id = "fb-snippet";
		scriptFB.textContent = content;
		document.head.appendChild(scriptFB);

		scriptFB.onload = () => {
			facebookSDKConfigScript();
		};
	}
};

export { facebookSDKScript as FacebookSDKScript };
