import initialState from "../../store/initialState";

const yecpInitialState = {
  ...initialState,
  errorMessage: "",
  message: "",
  yecpList: null,
  closeFY: {},
  signature: ""
};

export default yecpInitialState;
