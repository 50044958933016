import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Moment from "react-moment";
import clsx from "clsx";
import withStyles from "@material-ui/core/styles/withStyles";
import { Divider, Grid } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import { MoreVert } from "@material-ui/icons";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import * as PropTypes from "prop-types";
import reportsStyles from "./reportsStyle";
import * as reportActions from "../../actions/ReportActions";
import AvatarCommonComponent from "../common/avatarCommonComponent";
import DialogCommonComponent from "../common/dialogCommonComponent";
import DialogConfirmCommonComponent from "../common/dialogConfirmCommonComponent";
import reportsEmpty from "../../assets/images/reports.empty.png";

class DialogPayslipsComponent extends React.Component {
	state = {
		anchorEl: null,
		payslip: null,
		dialogSendEmailOpen: false,
		dialogDeleteOpen: false,
		canDelete: false,
		index:0
	};

	handleOpenMenu = (event, payslip,i) => {
		this.setState({ anchorEl: event.currentTarget, payslip });
		event.persist();
		this.setState({
			index:i,
			canDelete: payslip.canDelete
		})
	};

	handleCloseMenu = () => {
		this.setState({ anchorEl: null, payslip: null, canDelete: false });
	};

	handleMenuView = () => {
		const { payslip } = this.state;
		window.open(payslip.urlResume, "_blank");
		this.setState({ anchorEl: null });
	};

	handleMenuSendEmail = () => {
		this.setState({ dialogSendEmailOpen: true });
		this.setState({ anchorEl: null });
	};

	handleMenuDelete = () => {
		this.setState({ dialogDeleteOpen: true });
		this.setState({ anchorEl: null });
	};

	handleSendEmail = () => {
		const { sendEmail } = this.props;
		const { payslip } = this.state;
		sendEmail({ id: payslip.id });
		this.setState({ payslip: null, dialogSendEmailOpen: false });
	};

	handleDelete = () => {
		const { deleteById, filterData } = this.props;
		const { payslip } = this.state;
		deleteById(payslip.id, filterData);
		this.setState({ payslip: null, dialogDeleteOpen: false });
	};

	handleCloseDialogSendEmail = () => {
		this.setState({ payslip: null, dialogSendEmailOpen: false });
	};

	handleCloseDialogDelete = () => {
		this.setState({ payslip: null, dialogDeleteOpen: false });
	};

	render() {
		const {
			classes, title, open, onCloseModal, payslips
		} = this.props;
		const { anchorEl, dialogSendEmailOpen, dialogDeleteOpen } = this.state;
		const payslipRender = (payslip, i) => (
			<Paper className={clsx(classes.paper, classes.payslip)} key={payslip.id}>
				<Grid container wrap="nowrap" spacing={2} alignItems="center" className={classes.titleMRAvatar}>
					<Grid item>
						<AvatarCommonComponent text={payslip.employeeDetail.initials} hover={false} src={payslip.employeeDetail.imageUrl} employeeId={payslip.employeeDetail.id} />
					</Grid>
					<Grid
						item
						xs={5}
						onClick={() => {
							this.handleMenuView(payslip.urlResume);
						}}
					>
						<Typography variant="body1">{payslip.employeeDetail.name}</Typography>
						<Typography variant="subtitle1">{payslip.employeeDetail.jobTitle}</Typography>
					</Grid>
					<Grid
						item
						xs={3}
						onClick={() => {
							this.handleMenuView(payslip.urlResume);
						}}
					>
						<Typography variant="body1">
							<Moment utc locale="au" local={false} format="DD/MM/YY">{payslip.payPeriodStart}</Moment>
							&nbsp;to&nbsp;
							<Moment utc locale="au" local={false} format="DD/MM/YY">{payslip.payPeriodEnd}</Moment>
						</Typography>
					</Grid>
					<Grid
						item
						xs={2}
						onClick={() => {
							this.handleMenuView(payslip.urlResume);
						}}
					>
						<Typography variant="body1">
							<Moment utc locale="au" local={false} format="DD/MM/YY">{payslip.paymentDate}</Moment>
						</Typography>
					</Grid>

					<Grid item md className={classes.alignItemMenu}>
						<IconButton onClick={e => this.handleOpenMenu(e, payslip,i)}>
							<MoreVert />
						</IconButton>
					</Grid>
				</Grid>

			</Paper>

		);

		const periods = Object.keys(payslips).map(period => (
			<div key={period}>
				<Typography variant="body1" className={classes.period}>
					{period}
				</Typography>
				{payslips[period].map((p, i) => { return (payslipRender(p, i)) })}

			</div>
		));

		return (
			<DialogCommonComponent showDivider={false} size="lg" title={title} open={open} payslipDialog onCloseModal={onCloseModal} primaryButtonName="Done" onPrimaryButtonClick={onCloseModal}>
				{!payslips || Object.keys(payslips).length <= 0 ? (
					<div className={classes.imageEmpty}>
						<img className={classes.imageEmptyModal} src={reportsEmpty} alt="" />
					</div>
				) : null}
				{payslips && Object.keys(payslips).length > 0 ? (
					<div>
						<Grid container alignItems="center" className={classes.titleMR}>
							<Grid item xs={6}>
								<Typography variant="body1">Employee</Typography>
							</Grid>
							<Grid item xs={3} className={classes.titleMRText}>
								<Typography variant="body1">Payment Period</Typography>
							</Grid>
							<Grid item xs={2} className={classes.titleMRText}>
								<Typography variant="body1">Payment Date</Typography>
							</Grid>
						</Grid>
						<Divider />
						{periods}

						{this.state.canDelete?<Menu anchorEl={anchorEl} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} open={Boolean(anchorEl)} onClose={this.handleCloseMenu} getContentAnchorEl={null} PaperProps={{ style: { width: 150 } }}>
							<MenuItem onClick={this.handleMenuView}>View</MenuItem>
							<MenuItem onClick={this.handleMenuSendEmail}>Resend email</MenuItem>
							<MenuItem onClick={this.handleMenuDelete}>Delete</MenuItem>
						</Menu>: <Menu anchorEl={anchorEl} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} open={Boolean(anchorEl)} onClose={this.handleCloseMenu} getContentAnchorEl={null} PaperProps={{ style: { width: 150 } }}>
							<MenuItem onClick={this.handleMenuView}>View</MenuItem>
							<MenuItem onClick={this.handleMenuSendEmail}>Resend email</MenuItem>
						</Menu>}
					</div>
				) : null}

				<DialogConfirmCommonComponent open={dialogSendEmailOpen} title="Send email" primaryButtonName="Send" onPrimaryButtonClick={this.handleSendEmail} onCloseModal={this.handleCloseDialogSendEmail}>
					<Typography variant="body1" gutterBottom className={classes.description}>
						{"Are you sure?"}
					</Typography>
				</DialogConfirmCommonComponent>
				<DialogConfirmCommonComponent open={dialogDeleteOpen} title="Delete payslip" primaryButtonName="Delete" onPrimaryButtonClick={this.handleDelete} onCloseModal={this.handleCloseDialogDelete}>
					<Typography variant="body1" gutterBottom className={classes.description}>
						{"Are you sure?"}
					</Typography>
				</DialogConfirmCommonComponent>
			</DialogCommonComponent>
		);
	}
}

DialogPayslipsComponent.propTypes = {
	sendEmail: PropTypes.func,
	deleteById: PropTypes.func
};

const mapStateToProps = state => ({
	payslips: state.reports.payslips,
	filterData: state.reports.filterData
});

const mapDispatchToProps = dispatch => ({
	sendEmail: bindActionCreators(reportActions.payrollSendEmailRequest, dispatch),
	deleteById: bindActionCreators(reportActions.payrollDeleteRequest, dispatch)
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(reportsStyles)(DialogPayslipsComponent));
