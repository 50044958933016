const getPaymentsEmployer = async (httpClient) => {
	const { goEpsApi } = window.envVariables;
	const response = await httpClient.get(`${goEpsApi}/api/v1/reports/paymentemployer`);
	return response.data;
};

const getPaymentsEmployees = async (httpClient) => {
	const { goEpsApi } = window.envVariables;
	const response = await httpClient.get(`${goEpsApi}/api/v1/reports/paymentemployee`);
	return response.data;
};

const sendEmail = async (httpClient, requestData) => {
	const { epsApi } = window.envVariables;
	const response = await httpClient.post(`${epsApi}/api/v2/PaymentEmployee/SendMail`, requestData);
	return response.data;
};

const processPayment = async (httpClient, requestData) => {
	const { epsApi } = window.envVariables;
	const response = await httpClient.post(`${epsApi}/api/v2/PaymentEmployee`, requestData);
	return response.data;
};


export default {
	getPaymentsEmployer,
	getPaymentsEmployees,
	sendEmail,
	processPayment
};
