import initialState from "../../store/initialState";
import { employeeDefaultData } from "../Employees/EmployeeInitialState";
import { getDateToday, getFirstDay, getLastDay } from "../../helpers/dateextensions";

export const payrollProcessed = {
	id: null,
	url: null
};

export const payrollAdditionalDefaultData = {
	name: "-",
	description: "",
	hours: "",
	rate: "",
	totalAmount: 0
};
export const payrollQuantitiesDefaultData = {
	id: null,
	name: "-",
	description: "",
	quantity: "",
	rate: "",
	totalAmount: 0
};

export const payrollOvertimeDefaultData = {
	overtime: "-",
	overtimeManualRate: "",
	overtimeTotal: "",
	overtimeTotalAmount: 0
};

export const payrollAllowancesDefaultData = {
	value: "-",
	manualValue: "",
	qty: "",
	amount: "",
	totalAmount: 0
};

export const payrollDeductionsDefaultData = {
	type: "-",
	description: "",
	total: 0
};

export const payrollProcessDefaultData = {
	employeeId: "",
	employerId: "",
	payPeriodEnd: getDateToday(),
	payPeriodStart: getDateToday(),
	paymentDate: getDateToday(),
	additionals: [],
	quantities: [],
	allowances: [],
	deductions: [],
	allowancesNonTaxed: [],
	annualLeaveHours: 0,
	annualLeaveHoursTotal: 0,
	isAnnualLeave: false,
	isLongServiceLeave: false,
	isOverwrite: false,
	isPersonalLeave: false,
	isSickLeave: false,
	longLeaveHours: 0,
	longLeaveHoursTotal: 0,
	overtimes: [],
	overwrite_PAYG: "",
	overwrite_Super: "",
	payLeave: false,
	payType: "",
	paymentTypeBaseHours: 0,
	quantityDesc: "",
	quantityCount: 0,
	quantityRate: 0,
	quantityPaymentTypeTotal: 0,
	paymentTypeHours: 0,
	paymentTypeTotal: 0,
	personalLeaveHours: 0,
	personalLeaveHoursTotal: 0,
	showAllowance: false,
	showEntitlements: false,
	showOvertime: false,
	showBonus: false,
	showDirectorsFees: false,
	showCommission: false,
	sickLeaveHours: 0,
	sickLeaveHoursTotal: 0,
	commissions: [],
	bonus: {},
	directorsFees: {},
	showDeductions: false
};

export const payrollDefaultData = {
	startDate: getFirstDay(),
	finishDate: getLastDay(),
	paymentDate: getDateToday(),
	employerId: "",
	paymentType: "EFT",
	employees: [],
	employeeActive: {
		...employeeDefaultData,
		deductions: [],
		allowances: [],
	},
	payroll: {
		...payrollProcessDefaultData
	}
};

const payrollInitialState = {
	...initialState,
	errorMessage: "",
	message: "",
	selectedPayrollData: {
		...payrollDefaultData
	}
};

export default payrollInitialState;
