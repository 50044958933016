import * as common from "../constants/common";

// Initialise An Onboarding Session
const InitialiseAnOnboardingRequest = async (httpClient, OnboardingData, ip, browser) => {
	const { goEpsApi } = window.envVariables;

	let device = {
		platform: common.COMMON_LOGIN_PLATFORM,
		id: `${ip} ${browser.version} ${browser.os}`,
		deviceData: {
			ipPublic: `${ip}`,
			browserInfo: `${browser.version} ${browser.os}`,
			platform: `${browser.name}`
		}
	}
	OnboardingData.device = device

	const response = await httpClient.put(`${goEpsApi}/api/v1/onboard/create`, OnboardingData);

	return response;
};

// Update Data Fields During Onboarding
const updateDataDuringOnboarding = async (httpClient, OnboardingData) => {
	const { goEpsApi } = window.envVariables;

	// for new session onboard
	let data = {
		headers: {
			common: {
				new_session: true
			}
		}
	};
	const response = await httpClient.post(`${goEpsApi}/api/v1/onboard/update`, OnboardingData, data);
	
	return response;
};

// Complete The Initial Onboarding and Create Account
const finishOnboardingGetDataAccount = async (httpClient) => {
	const { goEpsApi } = window.envVariables;
	// for new session onboard
	let data = {
		headers: {
			common: {
				new_session: true
			}
		}
	};
	
	const response = await httpClient.post(`${goEpsApi}/api/v1/onboard/finish`, null, data);
	
	return response;
};

export default {
	InitialiseAnOnboardingRequest,
	updateDataDuringOnboarding,
	finishOnboardingGetDataAccount
}