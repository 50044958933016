import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Paper, Typography } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { DialogCommonComponent } from "../common";
import employeeStyle from "./employeeStyle";
import { getDateToday } from "../../helpers/dateextensions";

class ModalActiveComponent extends Component {
	constructor(props) {
		super(props);

		const { date } = this.props;

		this.state = {
			date: date || getDateToday()
		};
	}

	handleChangeDate = (date) => {
		this.setState({ date });
	};

	render() {
		const {
			classes, open, onPrimaryButtonClick, onCloseModal
		} = this.props;
		const { date } = this.state;

		return (
			<DialogCommonComponent
				title="Reactivate Employee"
				size="xs"
				primaryButtonName="Make Active"
				onPrimaryButtonClick={() => {
					onPrimaryButtonClick(date);
				}}
				open={open}
				onCloseModal={onCloseModal}
			>
				<Typography variant="body1" gutterBottom className={classes.description}>
					You will be able to Process Payroll for this employee.
					<br />
					<br />
					They will be included in Reports and Payment summaries.
				</Typography>
				<Grid container direction="row" alignItems="center">
					<Grid item xs={12}>
						<Paper className={classes.paperGrid}>
							<KeyboardDatePicker type="date-local"
								id="RecommencementDate"
								label="Recommencement Date"
								value={date}
								onChange={this.handleChangeDate}
								format="DD/MM/YYYY"
								fullWidth
								className={classes.textField}
								InputProps={{
									classes: {
										root: classes.keyboardDatePicker,
										input: classes.keyboardDatePickerInput
									}
								}}
							/>
						</Paper>
					</Grid>
				</Grid>
			</DialogCommonComponent>
		);
	}
}

export default withStyles(employeeStyle)(ModalActiveComponent);
