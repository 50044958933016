import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";
import { history } from "react-router";

import SidebarComponent from "../components/home/sidebarComponent";
import SettingComponentEmployer from "../components/setting/settingComponent";
import SettingComponentAccountant from "../components/setting/settingComponentAccountant";
import { SessionUserStyles } from "./PagesStyle";
import {
  LoadingComponent,
  SnackbarCommonComponent
} from "../components/common";
import { cleanMessage } from "../actions/NotificationActions";
import { cleanMessage as cleanMessageClose } from "../actions/EmployerActions";
import { mapMessageCodeToIcon } from "../helpers/extensions";
import { withRouter } from "react-router";

class SettingsPage extends Component {
  render() {
    const {
      classes,
      isLoading,
      messageCode,
      message,
      cleanMessageInternal,
      messageCodeClose,
      messageClose,
      cleanMessageInternalClose,
      mixpanel,
      employer,
      isAccountant
    } = this.props;

    return (
      <div className={classes.root}>
        <CssBaseline />
        <SidebarComponent />
        <main className={classes.content}>
          <LoadingComponent isLoading={isLoading} />
          <SnackbarCommonComponent
            key="SnackBarUpdateNotification"
            variant={mapMessageCodeToIcon(messageCode)}
            open={!!message}
            body={message}
            onCloseModal={cleanMessageInternal}
          />
          <SnackbarCommonComponent
            key="SnackBarUpdateNotificationClose"
            variant={mapMessageCodeToIcon(messageCodeClose)}
            open={!!messageClose}
            body={messageClose}
            onCloseModal={cleanMessageInternalClose}
          />
          <div className={classes.appBarSpacer} />
          {isAccountant ? (
            <SettingComponentAccountant mixpanel={mixpanel} />
          ) : (
            <SettingComponentEmployer mixpanel={mixpanel} />
          )}
        </main>
      </div>
    );
  }
}

SettingsPage.propTypes = {
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  messageCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  message: PropTypes.string,
  messageCodeClose: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  messageClose: PropTypes.string,
  isAccountant: PropTypes.bool
};

const mapStateToProps = state => ({
  isLoading: state.notification.isLoading || state.employer.isLoading,
  messageCode: state.notification.messageCode,
  message: state.notification.message,
  messageCodeClose: state.employer.messageCode,
  messageClose: state.employer.message,
  employer: state.session.userData,
  isAccountant:
    !state.session.isEmployee && state.employersAccountant.isAccountant
});

const mapDispatchToProps = dispatch => ({
  cleanMessageInternal: bindActionCreators(cleanMessage, dispatch),
  cleanMessageInternalClose: bindActionCreators(cleanMessageClose, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(SessionUserStyles)(SettingsPage)));
