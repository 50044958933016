import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Formik, getIn } from "formik";
import * as Yup from "yup";
import DeleteIcon from '@material-ui/icons/Delete';
import { withStyles } from "@material-ui/core/styles";

import {
    Grid, TableCell, TableRow, Paper, TextField, InputAdornment
} from "@material-ui/core";
import PayrollStyle from "./payrollStyle";
import clsx from "clsx";
import * as parameterTypes from "../../constants/parameterTypes";

import { NumberFormatCustomComponent } from "../common";

class EditUntaxabledAllowanceComponent extends Component {
    validationSchema = Yup.object({
        name: Yup.string()
            .required("Selected Additional base rate")
            .test("name-default", "Selected Additional base rate", value => value !== "-"),
        amount: Yup.number().required("amount is required"),
        qty: Yup.number().required("Quantity is required"),
        totalAmount: Yup.number().required("Total is required")
    });
    handleChangeValues = (index, name, value) => {
        let { parentProps } = this.props;
        const { allowancesNonTaxed } = parentProps.values.payroll;
        switch (name) {
            case "amount":
                allowancesNonTaxed[index].totalAmount = allowancesNonTaxed[index].qty * value
                allowancesNonTaxed[index].amount = value
                break;
            case "qty":
                allowancesNonTaxed[index].totalAmount = allowancesNonTaxed[index].amount * value
                allowancesNonTaxed[index].qty = value
                break;
            default:
                break;
        }
        parentProps.setFieldValue("payroll.allowancesNonTaxed", allowancesNonTaxed);
    };
    renderForm = (formProps) => {
        const {
            classes, row, index, handleRemoveItem
        } = this.props;


        return (
            <TableRow key={row.name} className={classes.tableRow, classes.tableCustompayroll}>
                <TableCell className={classes.tableText, classes.tableCellHover}>

                    <TextField
                        id="name"
                        defaultValue={`${row.value} ${row.manualValue}`}
                        InputProps={{
                            readOnly: true,
                        }}
                        onChange={formProps.handelChange}
                        onBlur={formProps.handleBlur}
                        className={clsx(classes.textFieldCustomPayroll,{
                            error: getIn(formProps.errors, "name") && getIn(formProps.touched, "name")
                        })}
                    />

                </TableCell>

                <TableCell className={classes.tableText, classes.tableCellHover}>
					<TextField
						id="qty"
						defaultValue={row.qty}
						fullWidth
						onChange={(value) => {
							this.handleChangeValues(index, "qty", value.target.value)
						}}
						
						InputProps={{
							inputComponent: NumberFormatCustomComponent,
							inputProps: {
								decimalScale: 2,
								fixedDecimalScale: true,
							}
                        }}
                        onBlur={formProps.handleBlur}
						className={clsx('tableCellHover', classes.textFieldCustomPayroll,{
							error: getIn(formProps.errors, "qty") && getIn(formProps.touched, "qty")
						})}
					/>
				</TableCell>

                <TableCell className={classes.tableText, classes.tableCellHover}  >
                    <TextField
                        id="amount"
                        fullWidth
                        value={row.amount}
                        onChange={(value) => {
                            this.handleChangeValues(index, "amount", value.target.value)
                        }}
                        onBlur={formProps.handleBlur}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            inputComponent: NumberFormatCustomComponent,
                            inputProps: {
                                decimalScale: 2,
                                fixedDecimalScale: true,

                            }

                        }}
                        className={clsx('tableCellHover', classes.textFieldCustomPayroll,{
                            error: getIn(formProps.errors, "amount") && getIn(formProps.touched, "amount")
                        })}
                    />
                </TableCell>
                <TableCell className={classes.tableText, classes.tableCellHover}>

                    <TextField
                        id="total"

                        fullWidth
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            readOnly: true,
                            inputComponent: NumberFormatCustomComponent,
                            inputProps: {
                                decimalScale: 2,
                                fixedDecimalScale: true
                            }
                        }}
                        value={row.totalAmount}
                        onChange={formProps.handelChange}
                        className={clsx(classes.textFieldCustomPayroll,{
							error: getIn(formProps.errors, "total") && getIn(formProps.touched, "total")
						})}
                    />


                </TableCell>
                <TableCell className={classes.tableText, classes.tableCellHoverDelete} align="right">
                    <DeleteIcon
                        fontSize="large"
                        onClick={e => handleRemoveItem(e, index)}
                        className={classes.textFieldCustomPayrollDelete}
                    />

                </TableCell>
            </TableRow>
        )
    };

    render() {
        const {
            data, row, index, handleRemoveItem, handelChange, parentProps
        } = this.props;
        const dataDefault = {
            ...data,
            ...row,
            index,
            handleRemoveItem,
            handelChange,
            ...parentProps
        };
        return <Formik
            initialValues={{ ...dataDefault }}
            validationSchema={this.validationSchema}
            render={this.renderForm}
            // onSubmit={handelChange}
            enableReinitialize />;
    }
}

EditUntaxabledAllowanceComponent.propTypes = {
    handleRemoveItem: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    payrollBaseRateAdditionalList: state.parameters.parameterByTypeData[parameterTypes.PAYROLL_BASE_RATE_ADDITIONAL.parameterType]
});

export default connect(
    mapStateToProps,
    null
)(withStyles(PayrollStyle)(EditUntaxabledAllowanceComponent));
