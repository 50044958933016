const externalUserStyle = theme => ({
  root: {
    ...theme.root,
    backgroundColor: "#F9F9F9",
    textAlign: "center",
    display: "inline-block",
    height: "100vh",
    ...theme.hiddenOverflow
  },
  rootContainer: {
    backgroundColor: "#FFFFFF",
    height: 460,
    width: 788,
    borderRadius: 8,
    boxShadow: "0 2px 26px 0 #DFDFE2",
    display: "inline-flex",
    marginTop: "78px",
    "&.forgot": {
      height: 378
    }
  },
  leftColumn: {
    textAlign: "left",
    padding: "0px 22px 0 54px",
    marginTop: 8
  },
  leftColumn2: {
    textAlign: "left",
    padding: "0px 22px 0 54px",
    marginTop: 36
  },
  rigthColumn: {
    textAlign: "center",
    alignItems: "center",
    display: "inline-flex",
    padding: "0px 22px 0 54px"
  },
  rigthColumnImg: {
    marginLeft: 25,
    width: "90%"
  },
  container1: {
    display: "flex",
    flexWrap: "wrap",
    width: 342,
    marginTop: 0
  },
  rememberLabel: {
    marginTop: 2,
    marginLeft: -5
  },
  loginBtn: {
    marginLeft: "0px",
    marginBottom: "0px",
    width: "100%",
    borderRadius: "5px !important",
    height: 40,
    marginTop: 1,
    fontFamily: "AvenirNextLTPro-Demi !important",
    "&:hover": {
      opacity: 0.9
    },
    "& span": {
      fontFamily: "AvenirNextLTPro-Demi !important",
      fontSize: "15px",
      fontWeight: "bold"
    }
  },
  signupBtn: {
    marginLeft: "0px",
    borderRadius: "5px",
    marginBottom: "5px",
    width: 200,
    height: 48,
    fontSize: "18px",
    backgroundColor: "#005CFF",
    "&:hover": {
      backgroundColor: "#005CFF"
    },
    "& span": {
      textAlign: "center",
      fontFamily: "AvenirNextLTPro-Demi",
      fontSize: "18px",
      letterSpacing: 0,
      color: "#ffffff",
      opacity: 1
    }
  },
  signupBtn1: {
    marginLeft: "0px",
    borderRadius: "5px",
    marginBottom: "5px",
    // fontWeight: 'bold',
    width: 200,
    height: 48,
    fontSize: "16px",
    "& span": {
      textAlign: "center",
      fontFamily: "AvenirNextLTPro-Demi",
      fontSize: "18px",
      letterSpacing: 0,
      color: "#ffffff",
      opacity: 1
    }
  },
  disabledButtonPrimary: {
    ...theme.overrides.disabledButtonPrimary
  },
  paper: {
    margin: `${theme.spacing}px auto`
    // padding: theme.spacing * 2,
  },
  textField: {
    backgroundColor: "#f5f5f5 !important",
    margin: `${theme.spacing}px auto`
  },
  signupRoot: {
    ...theme.root,
    backgroundColor: "#F9F9F9",
    display: "flex",
    ...theme.hiddenOverflow,
    alignItems: "flex-start",
    justifyContent: "center",
    overflow: "auto",
    paddingBottom: "10px"
  },
  quickSetupRoot: {
    ...theme.root,
    backgroundColor: "#F9F9F9",
    display: "flex",
    ...theme.hiddenOverflow,
    alignItems: "flex-start",
    justifyContent: "center",
    overflow: "auto",
    paddingBottom: "10px"
  },
  slideShow: {
    width: "100%",
    position: "relative"
  },
  slideShowItem: {
    display: "flex",
    "& > div": {
      flex: 1
    },
    textAlign: "left"
  },
  slideShowItemAcountant: {
    minHeight: "305px",
    display: "flex",
    "& > div": {
      flex: 1
    },
    textAlign: "left"
  },
  logoEasyPayslip: {
    textAlign: "left",
    alignItems: "center"
  },
  logoEasyPayslipAcountant: {
    display: "flex",
    alignItems: "flex-start",
    width: "100%",
    height: "70px"
  },
  subTitleOne: {
    fontFamily: "AvenirNextLTPro-Regular",
    fontSize: 20,
    lineHeight: "32px",
    display: "block",
    color: "#000",
    letterSpacing: 0,
    paddingBottom: 5,
    marginTop: 16
  },
  subTitleTwo: {
    fontFamily: "AvenirNextLTPro-Regular",
    fontSize: 18,
    color: "#000",
    fontWeight: "bold"
  },
  title: {
    fontSize: "36px",
    lineHeight: "56px",
    fontWeight: "normal",
    fontFamily: "AvenirNextLTPro-Demi",
    margin: "0px 0px",
    textAlign: "left",
    letterSpacing: "-0.58px",
    color: "#000000",
    opacity: 1
  },
  accountantsSignup: {
    fontSize: "16px",

    padding: 0,
    margin: "0 !important",
    "&:hover": {
      boxShadow: "none"
    },
    "& span": {
      padding: 0,
      color: theme.palette.primary.dark,
      // fontWeight: "bold",
      fontFamily: "AvenirNextLTPro-Medium !important",
      fontSize: "16px !important"
    }
  },
  doLater: {
    margin: "0 auto !important",
    "& span": {
      padding: 0,
      color: theme.palette.primary.dark,
      fontWeight: "bold"
    }
  },
  textFullAccess: {
    fontSize: "18px",
    fontWeight: "700",
    color: "#000"
  },
  subtitleSignUp: {
    fontSize: "23px",
    color: "#000",
    lineHeight: "normal"
  },
  SignIn: {
    position: "absolute",
    top: "365px",
    right: 140,
    zedIndex: 1,
    fontSize: "16px",
    fontFamily: "AvenirNextLTPro-Medium !important",
    "& a": {
      fontFamily: "AvenirNextLTPro-Medium !important"
    }
  },
  rigthColumnImgSignUp: {
    width: "100%"
  },
  rateWrapper: {
    position: "relative"
  },
  rate: {
    position: "absolute",
    fontSize: "23px",
    lineHeight: "33px",
    width: "100%",
    textAlign: "center",
    top: "20px",
    color: theme.palette.black
  },
  AppleLogoWrapperShow: {
    transition: "all 200ms ease-out",
    opacity: 1
  },
  AppleLogoWrapper: {
    opacity: 0
  },
  textFullAccessSignUp: {
    fontSize: "16px",
    // fontWeight: "bold",
    color: "#000",
    fontFamily: "AvenirNextLTPro-Regular"
  },
  textFieldQuickSetup: {
    backgroundColor: "#F5F5F5 !important",
    marginTop: -16,
    borderRadius: "5px !important",
    height: "49px !important",
    "& div": {
      height: "100%",
      backgroundColor: "#F5F5F5 !important",
      "& input": {
        // height: '47px !important',
        borderRadius: "8px !important",
        //  boxSizing: 'inherit !important',
        padding: "20px 10px 0px !important",
        backgroundColor: "#F5F5F5 !important",
        "&::placeholder": {
          color: "#6D9FEE !important"
        }
      }
    },
    "& label": {
      color: "#6D9FEE !important",
      textAlign: "left",
      fontFamily: "AvenirNextLTPro-Regular",
      fontSize: 18,
      letterSpacing: 0.01,
      color: "#6D9FEE",
      opacity: 1
    }
  },

  checkedbox: {
    fontFamily: "AvenirNextLTPro-Regular",
    fontSize: 18
  },
  headerLogo: {
    color: "#F02F0F",
    fontSize: 18
  },
  imageLogo: {
    height: 41,
    width: 41
  },
  logoroot: {
    marginTop: 20,
    marginLeft: 50
  },
  btnRectangle: {
    borderRadius: '5px !important',
    minWidth: "200px",
    "& span": {
      fontFamily: 'AvenirNextLTPro-Demi !important',
    }

  },
  orangeRectangularBtn: {
    ...theme.overrides.orangeRectangularBtn
  },
  textFieldLogIn: {
    backgroundColor: "#F5F5F5 !important",
    // marginTop: -16,
    borderRadius: "5px !important",
    height: "49px !important",
    "& div": {
      height: "100%",
      backgroundColor: "#F5F5F5 !important",
      "& input": {
        height: "40px !important",
        marginTop: 7,
        borderRadius: "8px !important",
        //  boxSizing: 'inherit !important',
        padding: "0px 10px 0px !important",
        backgroundColor: "#F5F5F5 !important",
        "&::placeholder": {
          color: "#6D9FEE !important"
        }
      }
    },
    "& label": {
      color: "#6D9FEE !important",
      textAlign: "left",
      fontFamily: "AvenirNextLTPro-Regular",
      fontSize: 18,
      letterSpacing: "-0.01px",
      color: "#6D9FEE !important",
      opacity: 1
    }
  },
  marginTopFiled: {
    marginTop: 7
  },

  forgetpassword: {
    textAlign: "left",
    fontFamily: "AvenirNextLTPro-Medium",
    letterSpacing: "-0.01px",
    color: "#004FDC !important",
    opacity: 1,
    fontSize: "16px !important",
    marginTop: 32
  },

  // textFieldLogin: {
  //   fontSize: 36,
  //   fontFamily: "AvenirNextLTPro-Demi",
  //   marginTop: 32,
  //   letterSpacing: "-0.58px",
  //   lineHeight: 56,
  //   color: "#000000",
  //   opacity: 1,
  //   fontWeight: "normal",
  //   textAlign: "left"
  // },
  textFieldwelcome: {
    fontSize: 20,
    fontFamily: "AvenirNextLTPro-Regular",
    marginTop: 36,
    letterSpacing: 0,
    lineHeight: 34,
    opacity: 1
  },
  textFieldwelcome2: {
    fontSize: 20,
    fontFamily: "AvenirNextLTPro-Regular",
    letterSpacing: 0,
    lineHeight: "34px !important",
    marginTop: -8,
    lineHeight: 34,
    opacity: 1
  },
  signupText: {
    fontSize: 16,
    fontFamily: "AvenirNextLTPro-Medium",
    color: "#004FDC",
    letterSpacing: "-0.01px",

    textAlign: "left",

    opacity: 1
  },
  signupText2: {
    fontSize: 16,
    fontFamily: "AvenirNextLTPro-Medium",
    marginTop: -16,
    color: "#004FDC",
    letterSpacing: "-0.01px",

    textAlign: "left",

    opacity: 1
  },
  signupbox: {
    marginTop: 94
  },
  textDownLogin: {
    textAlign: "left",
    marginLeft: 51
  },

  textFieldQuickSetupSelected: {
    "& svg": {
      height: "47px"
    },

    letterSpacing: 1.67,
    lineHeight: 56,
    color: "#000000",
    opacity: 1,
    fontWeight: "normal"
  },

  textdowncondition: {
    fontFamily: "AvenirNextLTPro-Medium",
    fontSize: "10px !important",
    color: "#000000",
    letterSpacing: 0,
    textAlign: "left"
  },
  textdownconditionLink: {
    fontFamily: "AvenirNextLTPro-Medium",
    fontSize: "10px !important",
    color: "#004FDC",
    letterSpacing: 0,
    textAlign: "left"
  },

  quSeBtnWrapper: {
    height: "88px",
    position: "absolute",
    right: 0,
    top: "90px",
    width: "50%",
    display: "flex",
    justifyContent: "flex-end"
  },
  quSeBtn: {
    borderRadius: "5px",
    marginBottom: "5px",
    width: 152,
    height: 48,
    fontSize: "18px",
    "& span": {
      textAlign: "center",
      fontFamily: "AvenirNextLTPro-Demi",
      fontSize: "18px",
      letterSpacing: 0,
      color: "#ffffff",
      opacity: 1
    }
  },
  backText: {
    "&:hover": {
      boxShadow: "0px 0px #ffffff"
    },

    "& span": {
      fontFamily: "AvenirNextLTPro-Medium",
      fontSize: "16px",
      letterSpacing: 0,
      color: "#004FDC",
      opacity: 1
    }
  },
  titleWrapper1: {
    marginTop: 27
  },
  titleWrapper: {
    marginTop: 7
  },
  mobileContainer: {
    padding: 20
  }
});

export { externalUserStyle as ExternalUserStyle };
