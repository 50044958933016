import React, { Component } from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import {
	Button, Grid, Table, TableHead, TableBody, TableCell, TableRow, Typography, MenuItem
} from "@material-ui/core";

import { MenuItemComponent, SnackbarCommonComponent } from "../common";

import PayrollStyle from "./payrollStyle";
import ModalAddUntaxedAllowance from "./modalAddUntaxedAllowance";
import { payrollAllowancesDefaultData } from "../../reducers/Payroll/PayrollInitialState";
import * as messages from "../../constants/messages";
import EditUntaxabledAllowanceComponent from "./editUntaxabledAllowanceComponent";

class UntaxabledAllowanceComponent extends Component {
	state = {
		showModal: false,
		error: {
			show: false,
			message: ""
		},
		editItem: {
			isEditing: false,
			data: null,
			index: -1
		}
	};

	handleClickOption = () => {
		this.setState(state => ({
			...state,
			showModal: true,
			error: {
				...state.error,
				show: false
			}
		}));
	};

	handleCloseModal = () => {
		this.setState(state => ({
			...state,
			showModal: false,
			error: {
				...state.error,
				show: false
			},
			editItem: {
				isEditing: false,
				data: null,
				index: -1
			}
		}));
	};

	setValueAdd = (value) => {
		const valueAdd = {
			...payrollAllowancesDefaultData,
			value: value.value,
			manualValue: value.manualValue,
			qty: parseFloat(value.qty).toFixed(2),
			amount: parseFloat(value.amount).toFixed(2),
			totalAmount: parseFloat(value.totalAmount).toFixed(2)
		};

		return valueAdd;
	};

	handleAdd = (value) => {
		const { formProps } = this.props;
		const { allowancesNonTaxed } = formProps.values.payroll;

		this.handleShowError(false, "");

		if (allowancesNonTaxed.length >= 10) {
			this.handleShowError(true, messages.MESSAGE_PAYROLL_UNTAXED_ALLOWANCES_LIMIT);
			return;
		}

		if (!allowancesNonTaxed.some(item => `${value.value}_${value.manualValue}` === `${item.value}_${item.manualValue}`)) {
			const newAllowancesNonTaxed = [...allowancesNonTaxed];
			newAllowancesNonTaxed.push(this.setValueAdd(value));

			formProps.setFieldValue("payroll.allowancesNonTaxed", newAllowancesNonTaxed);

			this.handleCloseModal();
		} else {
			this.handleShowError(true, messages.MESSAGE_PAYROLL_UNTAXED_ALLOWANCES_EXISTS);
		}
	};

	handleUpdate = (value) => {
		const { formProps } = this.props;
		const { editItem } = this.state;
		const { allowancesNonTaxed } = formProps.values.payroll;

		const newAllowancesNonTaxed = [...allowancesNonTaxed];
		newAllowancesNonTaxed.splice(editItem.index, 1);

		newAllowancesNonTaxed.push(this.setValueAdd(value));

		formProps.setFieldValue("payroll.allowancesNonTaxed", newAllowancesNonTaxed);

		this.handleCloseModal();
	};

	handleRemoveItem = (event, index) => {
		const { formProps } = this.props;
		const { allowancesNonTaxed } = formProps.values.payroll;
		const newAllowancesNonTaxed = [...allowancesNonTaxed];
		newAllowancesNonTaxed.splice(index, 1);

		formProps.setFieldValue("payroll.allowancesNonTaxed", newAllowancesNonTaxed);
	};

	handleEditItem = (event, index) => {
		const { formProps } = this.props;
		const { allowancesNonTaxed } = formProps.values.payroll;

		this.setState(state => ({
			...state,
			showModal: true,
			editItem: {
				isEditing: true,
				data: allowancesNonTaxed[index],
				index
			}
		}));
	};

	handleShowError = (show, message) => {
		this.setState(state => ({
			...state,
			error: {
				show,
				message
			}
		}));
	};

	render() {
		const { classes, formProps, isFinishAllEmployees } = this.props;
		const { showModal, error, editItem } = this.state;

		return (
			<Grid className={classes.gridContainer}>
				<Grid container direction="row" alignItems="flex-start">
					<Grid item xs={6}>
						<Typography variant="h4" gutterBottom>
							{"Untaxed allowances"}
						</Typography>
					</Grid>
					<Grid item xs={6} className={classes.gridRight}>
						<Button variant="outlined" color="secondary" onClick={this.handleClickOption} disabled={isFinishAllEmployees}>
							{"+ Add untaxed allowances"}
						</Button>
						{showModal ? <ModalAddUntaxedAllowance open={showModal} edit={editItem.isEditing} data={editItem.data} onAddValues={editItem.isEditing ? this.handleUpdate : this.handleAdd} onCloseModal={this.handleCloseModal} /> : null}
					</Grid>
				</Grid>
				{!formProps.values.payroll.allowancesNonTaxed || formProps.values.payroll.allowancesNonTaxed.length <= 0 ? (
					<Typography variant="h4" component="div" className={classes.placeHolder}>
						{"You haven’t added untaxed allowances"}
					</Typography>
				) : null}
				{formProps.values.payroll.allowancesNonTaxed && formProps.values.payroll.allowancesNonTaxed.length > 0 ? (
					
					<Table className={classes.table} size="medium">
						<TableHead className={classes.tableH}>
							<TableRow>
								<TableCell className={classes.tableTitle}>Name</TableCell>
								<TableCell className={classes.tableTitle}>Quantity</TableCell>
								<TableCell className={classes.tableTitle}>Rate</TableCell>
								<TableCell className={classes.tableTitle}>Total</TableCell>
								<TableCell className={classes.tableTitle} align="right" />
							</TableRow>
						</TableHead>
						<TableBody>
							{formProps.values.payroll.allowancesNonTaxed.map((row, index) => (
								// <TableRow key={`${row.value}_${row.manualValue}`} className={classes.tableRow}>
								// 	<TableCell className={classes.tableText} component="th" scope="row">
								// 		{`${row.value} ${row.manualValue}`}
								// 	</TableCell>
								// 	<TableCell className={classes.tableText}>{row.qty}</TableCell>
								// 	<TableCell className={classes.tableText}>{`$ ${row.amount}`}</TableCell>
								// 	<TableCell className={classes.tableText}>{`$ ${row.totalAmount}`}</TableCell>
								// 	<TableCell className={classes.tableText} align="right">
								// 		<MenuItemComponent>
								// 			<MenuItem onClick={e => this.handleEditItem(e, index)}>Edit</MenuItem>
								// 			<MenuItem onClick={e => this.handleRemoveItem(e, index)}>Delete</MenuItem>
								// 		</MenuItemComponent>
								// 	</TableCell>
								// </TableRow>
								<EditUntaxabledAllowanceComponent
								
								row={row}
								index={index}
								key={index}
								parentProps={formProps}
								handelChange={this.handelChange}
								handleRemoveItem={this.handleRemoveItem}
								
								/>
							))}
						</TableBody>
					</Table>
				) : null}
				{error && error.show ? <SnackbarCommonComponent variant="error" open={error.show} body={error.message} /> : null}
			</Grid>
		);
	}
}

UntaxabledAllowanceComponent.propTypes = {
	formProps: PropTypes.object.isRequired
};

export default withStyles(PayrollStyle)(UntaxabledAllowanceComponent);
