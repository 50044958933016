import * as common from "../constants/common";
import * as actionTypes from "../constants/actionTypes";
import localStorageApi from "../api/LocalStorageApi";


export function employerLoggingIn(email, password, history) {
	return {
		type: actionTypes.ACTION_SESSION_EMPLOYER_LOGGING_IN,
		payload: {
			isLoading: true,
			email,
			password,
			history
		}
	};
}

export function employerLoggingInSuccess(userData, token, enable2FA, messageCode, message) {
	return {
		type: actionTypes.ACTION_SESSION_EMPLOYER_LOGGING_IN_SUCCESS,
		payload: {
			userData,
			isAccountant: false,
			isEmployee: true,
			token,
			enable2FA,
			method2FA: userData.method2FA,
			messageCode,
			message
		}
	};
}

export function employerLoggingInFailure(message) {
	return {
		type: actionTypes.ACTION_SESSION_EMPLOYER_LOGGING_IN_FAILURE,
		error: true,
		payload: {
			errorMessage: message
		}
	};
}

export function userLoggingIn(email, password, history) {
	return {
		type: actionTypes.ACTION_SESSION_USER_LOGGING_IN,
		payload: {
			isLoading: true,
			email,
			password,
			history
		}
	};
}

export function userLoggingInSuccess(userData, token, enable2FA, messageCode, message, accountType) {

	return {
		type: actionTypes.ACTION_SESSION_USER_LOGGING_IN_SUCCESS,
		payload: {
			accountType,
			userData,
			token,
			isAccountant: !!accountType,
			isEmployee: !accountType,
			enable2FA,
			enabled2FA: enable2FA,
			valid2FA: enable2FA,
			method2FA: userData.method2FA,
			messageCode,
			message
		}
	};
}

export function userLoggingInFailure(message) {
	return {
		type: actionTypes.ACTION_SESSION_USER_LOGGING_IN_FAILURE,
		error: true,
		payload: {
			errorMessage: message
		}
	};
}

export function loadUserInformationThunk() {
	return (dispatch) => {
		try {

			const userData = localStorageApi.getItem(common.STORAGE_SESSION_NAME);

			if (userData) {
				if (userData.accountType === "accountant") {

					dispatch(userLoggingInSuccess(userData, userData.token, userData.valid2FA, userData.messageCode, userData.message, userData.accountType));
				} else {
					dispatch(userLoggingInSuccess(userData, userData.token, userData.enable2FA, userData.messageCode, userData.message));
				}

			}
		} catch (err) {
			// TODO: Add redirect to login
		}
	};
}
/* Login 2FA Email by Sajadweb */
export function userLogging2FAEmail(code2fa, history) {
	return {
		type: actionTypes.ACTION_SESSION_USER_LOGGING_2FA_EMAIL,
		payload: {
			isLoading: true,
			code2fa,
			history
		}
	};
}

export function userLogging2FAEmailSuccess(messageCode, message) {
	const userData = localStorageApi.getItem(common.STORAGE_SESSION_NAME);

	userData.accountType === "accountant" ? userData.valid2FA = true : userData.login.device.isValid2FA = true;
	localStorageApi.setItem(common.STORAGE_SESSION_NAME, userData);

	return {
		type: actionTypes.ACTION_SESSION_USER_LOGGING_2FA_EMAIL_SUCCESS,
		payload: {
			userData,
			messageCode,
			message
		}
	};
}

export function userLogging2FAEmailFailure(message) {
	return {
		type: actionTypes.ACTION_SESSION_USER_LOGGING_2FA_EMAIL_FAILURE,
		error: true,
		payload: {
			errorMessage: message
		}
	};
}
/* Login 2FA with Text */
export function userLogging2FAText(code2fa, history) {
	return {
		type: actionTypes.ACTION_SESSION_USER_LOGGING_2FA_TEXT,
		payload: {
			isLoading: true,
			code2fa,
			history
		}
	};
}

export function userLogging2FATextSuccess(messageCode, message) {
	const userData = localStorageApi.getItem(common.STORAGE_SESSION_NAME);
	if (userData.accountType === "accountant") {
		userData.valid2FA = true;
	} else {
		userData.login.device.isValid2FA = true;
	}

	localStorageApi.setItem(common.STORAGE_SESSION_NAME, userData);

	return {
		type: actionTypes.ACTION_SESSION_USER_LOGGING_2FA_TEXT_SUCCESS,
		payload: {
			userData,
			messageCode,
			message
		}
	};
}

export function userLogging2FATextFailure(message) {
	return {
		type: actionTypes.ACTION_SESSION_USER_LOGGING_2FA_TEXT_FAILURE,
		error: true,
		payload: {
			errorMessage: message
		}
	};
}


/* Login 2FA by John */
export function userLogging2FA(code2fa, history) {
	return {
		type: actionTypes.ACTION_SESSION_USER_LOGGING_2FA,
		payload: {
			isLoading: true,
			code2fa,
			history
		}
	};
}

export function userLogging2FASuccess(messageCode, message) {
	const userData = localStorageApi.getItem(common.STORAGE_SESSION_NAME);

	userData.login.device.isValid2FA = true;
	localStorageApi.setItem(common.STORAGE_SESSION_NAME, userData);

	return {
		type: actionTypes.ACTION_SESSION_USER_LOGGING_2FA_SUCCESS,
		payload: {
			userData,
			messageCode,
			message
		}
	};
}

export function userLogging2FAFailure(message) {
	return {
		type: actionTypes.ACTION_SESSION_USER_LOGGING_2FA_FAILURE,
		error: true,
		payload: {
			errorMessage: message
		}
	};
}

/* Change Password by John */
export function userChangePassword(id, email, password, history) {
	return {
		type: actionTypes.ACTION_SESSION_USER_CHANGE_PASSWORD,
		payload: {
			isLoading: true,
			id,
			email,
			password,
			history
		}
	};
}

export function userChangePasswordSuccess(messageCode, message) {
	const userData = localStorageApi.getItem(common.STORAGE_SESSION_NAME);

	if (userData.login && userData.login.device) {
		userData.login.device.isValid2FA = true;
	}

	localStorageApi.setItem(common.STORAGE_SESSION_NAME, userData);

	return {
		type: actionTypes.ACTION_SESSION_USER_CHANGE_PASSWORD_SUCCESS,
		payload: {
			userData,
			messageCode,
			message
		}
	};
}

export function userChangePasswordFailure(message) {
	return {
		type: actionTypes.ACTION_SESSION_USER_CHANGE_PASSWORD_FAILURE,
		error: true,
		payload: {
			errorMessage: message
		}
	};
}

export function userUpdateInfo(userData, messageCode, message) {

	return {
		type: actionTypes.ACTION_SESSION_USER_UPDATE_INFO,
		payload: {
			userData,
			messageCode,
			message
		}
	};
}

export function updateAuthorizedPerson(authorizedName, messageCode, message) {
	return {
		type: actionTypes.ACTION_SESSION_UPDATE_AUTHORIZED_PERSON,
		payload: {
			authorizedName: authorizedName,
			messageCode,
			message
		}
	};
}

export function cleanSession() {
	localStorageApi.deleteItem(common.STORAGE_SESSION_NAME);

	return {
		type: actionTypes.ACTION_SESSION_LOGOUT,
		payload: {
			errorMessage: null,
			messageCode: null,
			message: null,
			userData: null,
			token: null,
			enable2FA: null,
			email: null
		}
	};
}

export function cleanSessionApi() {
	return {
		type: actionTypes.ACTION_SESSION_LOGOUT_API,
		payload: {}
	};
}

export function cleanSessionApiSuccess(messageCode, message) {
	return {
		type: actionTypes.ACTION_SESSION_LOGOUT_API_SUCCESS,
		payload: {
			messageCode,
			message
		}
	};
}

export function cleanSessionApiFailure(messageCode, message) {
	return {
		type: actionTypes.ACTION_SESSION_LOGOUT_API_FAILURE,
		error: true,
		payload: {
			messageCode,
			message
		}
	};
}

export function cleanMessage() {
	return {
		type: actionTypes.ACTION_SESSION_CLEAN_MESSAGE,
		payload: {
			errorMessage: null,
			messageCode: null,
			message: null
		}
	};
}
