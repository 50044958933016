import * as actionTypes from "../constants/actionTypes";

// -------------------- GET YECP LIST -----------------------------
export function getAllYECPRequest() {
  return {
    type: actionTypes.ACTION_GET_ALL_YECP_REQUEST,
    payload: {}
  };
}

export function getAllYECPSuccess(yecpList, messageCode, message, history) {
  return {
    type: actionTypes.ACTION_GET_ALL_YECP_SUCCESS,
    payload: {
      yecpList,
      messageCode,
      message,
      history
    }
  };
}

export function getAllYECPFailure(messageCode, message) {
  return {
    type: actionTypes.ACTION_GET_ALL_YECP_FAILURE,
    error: true,
    payload: {
      messageCode,
      message
    }
  };
}

// -------------------- SEND CLOSE YF ---------------------------------
export function sendCloseYFRequest(requestData) {
  return {
    type: actionTypes.ACTION_SEND_CLOSE_FY_REQUEST,
    payload: {
      requestData
    }
  };
}

export function sendCloseYFSuccess(closeFY, messageCode, message, yecp) {
  return {
    type: actionTypes.ACTION_SEND_CLOSE_FY_SUCCESS,
    payload: {
      closeFY,
      messageCode,
      message,
      yecp
    }
  };
}

export function sendCloseYFFailure(messageCode, message) {
  return {
    type: actionTypes.ACTION_SEND_CLOSE_FY_FAILURE,
    error: true,
    payload: {
      messageCode,
      message
    }
  };
}

// -------------------- SEND CLOSE FY SIGNATURE -------------------
export function sendCloseYFSignatureRequest(yecp, requestData) {
  return {
    type: actionTypes.ACTION_SEND_CLOSE_FY_SIGNATURE_REQUEST,
    payload: {
      requestData,
      yecp
    }
  };
}
export function sendCloseYFSignatureSuccess(
  closeFYSignature,
  messageCode,
  message,
  history
) {
  return {
    type: actionTypes.ACTION_SEND_CLOSE_FY_SIGNATURE_SUCCESS,
    payload: {
      closeFYSignature,
      messageCode,
      message,
      history
    }
  };
}
export function sendCloseYFSignatureFailure(messageCode, message) {
  return {
    type: actionTypes.ACTION_SEND_CLOSE_FY_SIGNATURE_FAILURE,
    payload: {
      messageCode,
      message
    }
  };
}

// -------------------- GET AUTHORIZED NAME FOR SIGNATURE -----------------
export function getAuthorizedNameSignatureRequest() {
  return {
    type: actionTypes.ACTION_GET_AUTHORIZEDNAME_SIGNATURE_REQUEST,
    payload: {}
  };
}

export function getAuthorizedNameSignatureSuccess(
  signature,
  messageCode,
  message,
  history
) {
  return {
    type: actionTypes.ACTION_GET_AUTHORIZEDNAME_SIGNATURE_SUCCESS,
    payload: {
      signature,
      messageCode,
      message,
      history
    }
  };
}

export function getAuthorizedNameSignatureFailure(messageCode, message) {
  return {
    type: actionTypes.ACTION_GET_AUTHORIZEDNAME_SIGNATURE_FAILURE,
    payload: {
      messageCode,
      message
    }
  };
}
// -------------------- empty closeFY ------------
export function emptyCloseFY() {
  return {
    type: actionTypes.ACTION_EMPTY_CLOSE_FY,
    payload: {}
  };
}
// -------------------- redirect to stp compeleted ---------
export function redirectToStpCompeleted(bool) {
  return {
    type: actionTypes.ACTION_REDIRECT_TO_STP_COMPELETED,
    payload: {
      redirect: bool
    }
  };
}

// -------------------- PUT EMPLOYEES FOR CLOSE FY -----------------
export function putEployeesForCloseFYRequest(yecp, employees, employeeList) {
  return {
    type: actionTypes.ACTION_PUT_EMPLOYEES_FOR_CLOSE_FY_REQUEST,
    payload: {
      yecp,
      employees,
      employeeList
    }
  };
}

export function putEployeesForCloseFYSuccess(
  data,
  messageCode,
  message,
  employeeList
) {
  return {
    type: actionTypes.ACTION_PUT_EMPLOYEES_FOR_CLOSE_FY_SUCCESS,
    payload: {
      data,
      messageCode,
      message,
      employeeList
    }
  };
}

export function putEployeesForCloseFYFailure(messageCode, message) {
  return {
    type: actionTypes.ACTION_PUT_EMPLOYEES_FOR_CLOSE_FY_FAILURE,
    payload: {
      messageCode,
      message
    }
  };
}

// -------------------- EDIT EMPLOYEES PAYROLL FOR CLOSE FY -----------------
export function editEmployeePayrollForCloseFYRequest(
  yecp,
  id,
  requestData,
  formProps
) {
  return {
    type: actionTypes.ACTION_EDIT_EMPLOYEE_PAYROLL_FOR_CLOSE_FY_REQUEST,
    payload: {
      yecp,
      id,
      requestData,
      formProps
    }
  };
}

export function editEmployeePayrollForCloseFYSuccess(
  data,
  messageCode,
  message,
  formProps
) {
  return {
    type: actionTypes.ACTION_EDIT_EMPLOYEE_PAYROLL_FOR_CLOSE_FY_SUCCESS,
    payload: {
      data,
      messageCode,
      message,
      formProps
    }
  };
}

export function editEmployeePayrollForCloseFYFailure(messageCode, message) {
  return {
    type: actionTypes.ACTION_EDIT_EMPLOYEE_PAYROLL_FOR_CLOSE_FY_FAILURE,
    payload: {
      messageCode,
      message
    }
  };
}

// -------------------- CHANGE EMPLOYEE LIST FOR COLSE FY -----------------------
export function changeEmpolyeeListForCloseFY(id, requestData) {
  return {
    type: actionTypes.ACTION_CHANGE_EMPLOYEE_LIST_FOR_CLOSE_FY,
    payload: {
      id,
      requestData
    }
  };
}

// -------------------- SEND CLOSE FY AFTER EIDT EMPLOYEES ----------------------
export function sendCloseFYAfterEditEmployeesRequest(yecp) {
  return {
    type: actionTypes.ACTION_SEND_CLOSE_FY_AFTER_EDIT_EMPLOYEES_REQUEST,
    payload: {
      yecp
    }
  };
}

export function sendCloseFYAfterEditEmployeesSuccess(
  data,
  messageCode,
  message
) {
  return {
    type: actionTypes.ACTION_SEND_CLOSE_FY_AFTER_EDIT_EMPLOYEES_SUCCESS,
    payload: {
      data,
      messageCode,
      message
    }
  };
}

export function sendCloseFYAfterEditEmployeesFailure(messageCode, message) {
  return {
    type: actionTypes.ACTION_SEND_CLOSE_FY_AFTER_EDIT_EMPLOYEES_FAILURE,
    payload: {
      messageCode,
      message
    }
  };
}
