import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import {
	Button, Grid, Paper, Typography, MenuItem, Table, TableHead, TableBody, TableCell, TableRow, Icon, IconButton
} from "@material-ui/core";
import clsx from "clsx";

import DeleteIcon from '@material-ui/icons/Delete';

import { LoadingComponent, SnackbarCommonComponent } from "../common";

import accountsStyle from "./accountsStyle";
import ModalAccountant from "./modalAccountant";
import { payrollAdditionalDefaultData } from "../../reducers/Payroll/PayrollInitialState";
import * as messages from "../../constants/messages";
import * as accountantActions from "../../actions/AccountantActions";
import AvatarCommonComponent from "../common/avatarCommonComponent";
import { mapMessageCodeToIcon } from "../../helpers/extensions";
import additionalUserEmpty from "../../assets/images/employees.empty.png";
import * as routes from "../../constants/routes";
import localStorageApi from "../../api/LocalStorageApi";
import * as common from "../../constants/common";
import PersonAddIcon from '@material-ui/icons/PersonAdd';

class accountsComponent extends Component {
	constructor(props) {
		super(props);
	}

	state = {
		check: true,
		showModal: false,
		isDelete: false,
		accountantRevoke: {},
		error: {
			show: false,
			message: ""
		},
		editItem: {
			isEditing: false,
			data: null,
			index: -1
		}
	};

	componentDidMount() {

		const { getListLinkedEmployerAccounts } = this.props;
		getListLinkedEmployerAccounts();
		// console.log("clean account action");
		accountantActions.cleanMessage();
	}

	handleClickOption = () => {
		this.setState(state => ({
			...state,
			showModal: true,
			isDelete: false,
		}));
	};

	handleCloseModal = () => {
		this.setState(state => ({
			...state,
			showModal: false,
			isDelete: false,
			isInvite: false,
			editItem: {
				isEditing: false,
				data: null,
				index: -1
			}
		}));
	};

	setValueAdd = (value) => {
		const valueAdd = {
			...payrollAdditionalDefaultData,
			name: value.name
		};
		return valueAdd;
	};

	handleOpenModalRemoveItem = (event, accountant, index) => {

		this.setState({
			showModal: true,
			isDelete: true,
			accountantRevoke: accountant
		})
	};

	handleOpenModalInviteItem = (event, accountant, index) => {

		this.setState({
			showModal: true,
			isInvite: true,
			accountantInvite: accountant
		})
	};

	handleRemoveItem = (value, actions) => {
		const { linkedEmployerAccountsRevoke } = this.props;
		linkedEmployerAccountsRevoke(value.id);

		this.handleCloseModal();
	};

	handleInviteUser = (value) => {
		const { inviteEmployer } = this.props;

		inviteEmployer(value.id);

		this.handleCloseModal();
	}

	handleClickLinkedEmployer = (employerId) => {
		const { loginAsLinkedEmployer, history, messageCode } = this.props;
		loginAsLinkedEmployer(employerId, history);
		// history.push("/employee");

		// if (messageCode == 200) {
		// 	history.push(routes.PATH_EMPLOYEE);
		// }
	};

	handleShowError = (show, message) => {
		this.setState(state => ({
			...state,
			error: {
				show,
				message
			}
		}));
	};

	handleCreateEmployer = () => {
		const { history } = this.props;

		history.push(routes.PATH_ACCOUNTS_CREATE);
	}

	render() {
		const { classes, messageCode, message, errorMessage, cleanMessageInternal, employersAccountant, isSaving, isLoading } = this.props;
		const { showModal, error, editItem, isDelete, isInvite, accountantRevoke, accountantInvite } = this.state;


		const employersList = employersAccountant && employersAccountant.map((accountant, index) => (
			<Paper className={classes.paper} key={accountant.id} style={{ paddingTop: "15px", cursor: "pointer" }}>
				<Grid container wrap="nowrap" spacing={2} alignItems="center">
					<Grid container wrap="nowrap" spacing={2} alignItems="center"
						onClick={() => {
							this.handleClickLinkedEmployer(accountant.id);
						}}
					>
						<Grid item>
							<AvatarCommonComponent text={`${accountant.name ? accountant.name.substring(0, 1) : ""}`} hover={false} accountantId={accountant.id} src={accountant.logo} />
						</Grid>
						<Grid item xs>
							<Typography variant="body1">{accountant.name}</Typography>
						</Grid>
					</Grid>
					<Grid justify="flex-end" container style={{ marginRight: "30px" }}>
						{
							accountant.canInvite &&
							<IconButton className={classes.button} aria-label="Invice"
								color="black"
								onClick={(event) => {
									this.handleOpenModalInviteItem(event, accountant, accountant.id);
								}}>
								<PersonAddIcon style={{ fontSize: 23 }} />
							</IconButton>
						}
						<IconButton className={classes.button} aria-label="Delete"
							color="black"
							onClick={(event) => {
								this.handleOpenModalRemoveItem(event, accountant, accountant.id);
							}}>
							<DeleteIcon style={{ fontSize: 23 }} />
						</IconButton>
					</Grid>
				</Grid>
			</Paper>
		));

		return (
			<Grid className={classes.gridContainer}>
				<Grid container direction="row" alignItems="flex-start">
					<Paper className={classes.paperContainer}>
						<Grid container direction="row">
							<Grid item xs={8}>
								<Typography variant="h1">
									{"Accounts"}
								</Typography>
							</Grid>
							<Grid item xs={4} className={classes.gridRight}>
								<Button variant="contained" color="secondary"
									className={classes.orangeRectangularBtn}
									onClick={this.handleCreateEmployer}>
									Add Employer Account
								</Button>
							</Grid>
						</Grid>

						<Grid container alignItems="center" className={classes.gridContainer}>
							<Grid item xs={12} className={classes.gridRight}>
								{showModal ? <ModalAccountant open={showModal} isDelete={isDelete} isInvite={isInvite} data={isDelete ? accountantRevoke : (isInvite ? accountantInvite : "")} onHandleUser={isDelete ? this.handleRemoveItem : (isInvite ? this.handleInviteUser : this.handleAddUser)} onCloseModal={this.handleCloseModal} /> : null}
							</Grid>
						</Grid>
					</Paper>
				</Grid>

				<Grid container direction="row" alignItems="flex-start">
					<Paper className={classes.paperContainer}>
						{(employersAccountant && employersAccountant.length <= 0 || employersAccountant === null) && !isLoading ? (
							<div className={classes.imageEmptyContainer}>
								<img className={classes.imageEmptyImage} src={additionalUserEmpty} alt="" />
								<Typography variant="body1" className="gray">
									{"You don’t have any linked Employers"}
								</Typography>
							</div>
						) : null}

						{employersAccountant && employersAccountant.length > 0 ? <Grid container alignItems="center">
							<Grid item xs={12}>
								{employersList}
							</Grid>
						</Grid>
							: null
						}
					</Paper>
				</Grid>
				<LoadingComponent isLoading={isLoading} />

				<SnackbarCommonComponent key="SnackBarAccountantMessages" variant={messageCode === 200 ? "" : mapMessageCodeToIcon(messageCode)} open={(!!message)} body={message} onCloseModal={cleanMessageInternal} />
			{!(messageCode === 200) &&	<SnackbarCommonComponent key="SnackBarAccountantMessages" variant="error" open={(!!errorMessage)} body={errorMessage} onCloseModal={cleanMessageInternal} />}

			</Grid>
		);
	}
}

accountsComponent.propTypes = {
	formProps: PropTypes.object.isRequired,
	getListLinkedEmployerAccounts: PropTypes.func,
	linkedEmployerAccountsRevoke: PropTypes.func,
	loginAsLinkedEmployer: PropTypes.func,
	messageCode: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),
	message: PropTypes.string,
	errorMessage: PropTypes.string,
	employersAccountant: PropTypes.array,
	isSaving: PropTypes.bool,
	isLoading: PropTypes.bool
};

const mapStateToProps = state => ({
	isLoading: state.employersAccountant.isLoading,
	messageCode: state.employersAccountant.messageCode,
	errorMessage: state.employersAccountant.errorMessage,
	message: state.employersAccountant.message,
	employersAccountant: state.employersAccountant.employersAccountant,
	isSaving: state.employersAccountant.isSaving,
});

const mapDispatchToProps = dispatch => ({
	getListLinkedEmployerAccounts: bindActionCreators(accountantActions.getListLinkedEmployerAccounts, dispatch),
	linkedEmployerAccountsRevoke: bindActionCreators(accountantActions.linkedEmployerAccountsRevoke, dispatch),
	loginAsLinkedEmployer: bindActionCreators(accountantActions.loginAsLinkedEmployer, dispatch),
	inviteEmployer: bindActionCreators(accountantActions.inviteEmployer, dispatch),
	cleanMessageInternal: bindActionCreators(accountantActions.cleanMessage, dispatch),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(withStyles(accountsStyle)(accountsComponent)));
