export const PATH_ACCOUNTS = "/accounts";
export const PATH_ACCOUNTS_CREATE = "/accounts/create";

export const PATH_EMPLOYER_REGISTER = "/employer/register";
export const PATH_EMPLOYER = "/employer";

export const PATH_EMPLOYEE = "/employee";
export const PATH_EMPLOYEE_ADD = "/employee/add";
export const PATH_EMPLOYEE_EDIT = "/employee/edit";

export const PATH_PAYROLL = "/payroll";
export const PATH_PAYROLL_PROCESS = "/payroll/process";

export const PATH_SINGLETOUCH_PAYROLL = "/singletouchpayroll";
export const PATH_PAYMENT_SUMMARY = "/paymentsummaries";

export const PATH_REPORTS = "/reports";

export const PATH_SINGLE_TOUCH_PAYROLL = "/singletouchpayroll";

export const PATH_LOGIN = "/login";
export const PATH_LOGIN_2FA = "/login2fa";
export const PATH_LOGOUT = "/logout";
export const PATH_SIGN_UP = "/signup";
export const PATH_FORGOT_PASSWORD = "/forgotpassword";
export const PATH_RESET_PASSWORD = "/resetpassword";
export const PATH_RECOVER_PASSWORD = "/recover";
export const PATH_LOGIN_UNIFY = "/loginunify";

export const PATH_SETTINGS_2FA = "/settings/twofactorauthentication";
export const PATH_SETTINGS_SELECT = "/settings/select";
export const PATH_SETTINGS_SELECT_UPDATE = "/settings/update";

export const PATH_ACCOUNTANT_REGISTER = "/accountant/register";
export const MANAGE_ADDITIONAL_USER = "/settings/manageAdditionalUser";
export const PATH_SETTINGS_2FA_CHOICE = "/twofactorchoiceauthentication";
export const PATH_SETTINGS = "/settings";
// export const PATH__ACCOUNTANT_SETTINGS = "/accountantSettings";

export const PATH_SUBSCRIPTION_PLAN = "/subscription/plan";
export const PATH_SUBSCRIPTION = "/subscription";

//--onboard
export const PATH_QUICK_SETUP = "/quickSetup";
export const PATH_QU_SE_SUCCESS = "/quickSetupSuccess";
export const PATH_QUICK_SETUP_ACCOUNTANT = "/quickSetupAccountant";
export const PATH_QU_SE_ACCOUNTANT_SUCCESS = "/quickSetupAccountantSuccess";
