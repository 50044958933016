import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import { Button, Link, Grid, Paper, Typography } from "@material-ui/core";
import { MailOutline, ContactPhone } from "@material-ui/icons";
import { Link as RouterLink } from "react-router-dom";
import { withRouter } from "react-router";

import SettingStyle from "./settingStyle";
import * as routes from "../../constants/routes";
import * as settingsFiles from "../../constants/settingsFiles";

import {
  DialogCommonPDFComponent,
  SnackbarCommonComponent,
  DialogConfirmCommonComponent,
  LoadingComponent
} from "../common";
import { getUTCDate, getDateToday } from "../../helpers/dateextensions";
import { cleanMessage } from "../../actions/EmployerActions";
import {
  getAllYECPRequest,
  emptyCloseFY,
  redirectToStpCompeleted
} from "../../actions/YECPActions";
import { getAvailableFYs } from "../../actions/FYActions";

import ModalNotificationComponent from "./modalNotificationsComponent";
import ModalCloseFYComponent from "./modalCloseFYComponent";
import ModalNewCloseFYComponent from "./modalNewCloseFYComponent";
import ModalCloseFYDoneComponent from "./modalCloseFYDoneComponent";
import ModalCloseFYAuthorizedComponent from "./modalCloseFYAuthorizedComponent";
import ModalSettingDeclaration from "./modalSettingDeclaration";
import ModalSelectFY from "./modalSelectFY";
import ChooseEmployeesForEditSTP from "./chooseEmployeesForEditSTP";

class SettingComponent extends Component {
  state = {
    PDF: {
      Open: false,
      Url: "",
      Name: ""
    },
    showModalNotification: false,
    showModalCloseFY: false,
    showModalNewCloseFY: false,
    yecpName: "",
    showModalCLoseFYDone: false,
    isValidCloseFY: false,
    showAuthorizedModal: false,
    showSnackBarGenerateLast: false,
    hasLodged: "",
    showCloseFYModalPDF: false,
    showCloseFYModalBanner: false,
    openSettingModalDeclartion: false,
    openEnable2FA: false,
    chooseEmployeesForEdit: false,
    showModalSelectFY: false
  };

  componentDidMount() {
    const {
      employer,
      cleanMessages,
      isGenerateLastPayments,
      mixpanel,
      yecpList,
      getAvailableFYs
    } = this.props;

    mixpanel.track("Settings.Screen");

    const now = getDateToday();
    const fyYearTo = getUTCDate(employer.financialYear.dateTo);

    this.setState({
      isValidCloseFY:
        employer.financialYear.year === now.toDate().getFullYear() ||
        now >= fyYearTo,
      showSnackBarGenerateLast: isGenerateLastPayments
    });
    cleanMessages();
    yecpList();
    getAvailableFYs();
  }

  componentWillReceiveProps = nextProps => {
    const { isGenerateLastPayments, hasLodged, message } = nextProps;
    if (isGenerateLastPayments) {
      this.setState({ showSnackBarGenerateLast: isGenerateLastPayments });
      this.handleCloseAuthorizedModal();
    }
    if (message && message === "Success") {
      this.setState(
        {
          openSettingModalDeclartion: false
        },
        () => {
          this.props.redirectToStpCompeleted(true);
        }
      );
      const { history } = this.props;
      history.push(routes.PATH_SINGLETOUCH_PAYROLL);
    }
    this.setState({
      hasLodged
    });
  };

  showCloseFYModalPDF = showCloseFYModalPDF => {
    this.setState({
      showCloseFYModalPDF,
      showCloseFYModalBanner: showCloseFYModalPDF
    });
  };
  handelNewCloseFYOpen = yecp => {
    const { emptyCloseFY } = this.props;
    emptyCloseFY();
    this.setState({
      showModalNewCloseFY: true,
      yecpName: yecp
    });
  };
  handleNewCloseFYClose = () => {
    this.setState({
      showModalNewCloseFY: false
    });
  };

  handleUpdateFY = (fyName) => {
    this.handleSelectFYClose();
    this.props.updateActiveFY(fyName);
  }

  handleSelectFYOpen = () => {
    this.setState({
      showModalSelectFY: true
    });
  }

  handleSelectFYClose = () => {
    this.setState({
      showModalSelectFY: false
    });
  }

  handleCloseFYOpen = () => {
    const { cleanMessages } = this.props;
    cleanMessages();

    this.setState({
      showModalCloseFY: true
    });
  };

  handleCloseFYClose = () => {
    this.setState({
      showModalCloseFY: false
    });
  };

  handleCloseFYDoneOpen = () => {
    const { cleanMessages } = this.props;
    cleanMessages();

    this.setState({
      showModalCLoseFYDone: true
    });
  };

  handleCloseFYDoneClose = () => {
    this.setState({
      showModalCLoseFYDone: false
    });
  };

  handleOpenModalPDF = settingConf => () => {
    this.setState({
      PDF: {
        Open: true,
        Url: settingConf.url,
        Name: settingConf.name
      }
    });
  };

  handleCloseModalPDF = () => {
    this.setState({
      PDF: {
        Open: false,
        Url: "",
        Name: ""
      }
    });
  };

  handleClickOptionNotification = () => {
    this.setState({
      showModalNotification: true
    });
  };

  handleCloseModalNotification = () => {
    this.setState(() => ({
      showModalNotification: false
    }));
  };

  handleOpenAuthorizedModal = () => {
    this.setState({ showAuthorizedModal: true });
  };

  handleCloseAuthorizedModal = () => {
    this.setState({ showAuthorizedModal: false });
  };

  handleCloseSnackBarGenerateLast = () => {
    this.setState({
      showSnackBarGenerateLast: false,
      showCloseFYModalBanner: false
    });
  };
  closeModalPDF = () => {
    this.setState({
      showCloseFYModalPDF: false,
      showCloseFYModalBanner: false
    });
  };
  handelLodgeFile = () => {
    const { employer } = this.props;
    if (!employer.login.enable2FA) {
      this.setState({
        openEnable2FA: true
      });
    } else {
      this.setState({
        openSettingModalDeclartion: true,
        showCloseFYModalPDF: false
      });
    }
  };
  handleEditAmounts = () => {
    this.setState({
      showCloseFYModalPDF: false
      // chooseEmployeesForEdit: true
    });
    const { history } = this.props;
    history.push(routes.PATH_SETTINGS_SELECT);
  };
  handleCloseEnable2FA = () => {
    this.setState(() => ({
      openEnable2FA: false
    }));
  };
  handleRedirect2FA = () => {
    const { history } = this.props;
    history.push(routes.PATH_SETTINGS_2FA);
  };
  handleCloseSettingModal = () => {
    this.setState(() => ({
      openSettingModalDeclartion: false
    }));
  };
  render() {
    const {
      classes,
      employer,
      isAccountant,
      yecp,
      isLoading,
      history
    } = this.props;
    const {
      PDF,
      showModalNotification,
      showModalCloseFY,
      showModalNewCloseFY,
      yecpName,
      showModalCLoseFYDone,
      isValidCloseFY,
      hasLodged,
      showAuthorizedModal,
      showSnackBarGenerateLast,
      showCloseFYModalPDF,
      showCloseFYModalBanner,
      openSettingModalDeclartion,
      openEnable2FA,
      chooseEmployeesForEdit,
      showModalSelectFY,
    } = this.state;
    if (chooseEmployeesForEdit) {
      history.push(routes.PATH_SETTINGS_2FA);
      // return (
      //   <ChooseEmployeesForEditSTP
      //     employees={this.props.closeFY.employees}
      //     yecp={yecpName}
      //   />
      // );
    } else
      return (
        <div className={classes.root}>
          <Paper className={classes.paperContainer}>
            <Typography variant="h1" gutterBottom>
              Settings
            </Typography>
            <Typography variant="body1">
              Configure and adjust your business preferences
            </Typography>

            <Grid container spacing={4} className={classes.gridContainer}>
              <Grid item xs={6}>
                <Paper className={classes.settingPaper}>
                  <Typography variant="h4" gutterBottom>
                    {"Profile"}
                  </Typography>

                  <Link
                    component={RouterLink}
                    to={routes.PATH_EMPLOYER_REGISTER}
                    className="block"
                  >
                    {employer.companyName}
                  </Link>
                </Paper>

                <Paper className={classes.settingPaper}>
                  <Typography variant="h4" gutterBottom>
                    {"Documents"}
                  </Typography>

                  <Button
                    component={Link}
                    onClick={this.handleOpenModalPDF(
                      settingsFiles.SETTINGS_TFN_DECLARATION
                    )}
                    classes={{ root: "block", label: classes.blockLabel }}
                  >
                    {"TFN Declaration"}
                  </Button>

                  <Button
                    component={Link}
                    onClick={this.handleOpenModalPDF(
                      settingsFiles.SETTINGS_EMPLOYEE_SUPERANNUATION
                    )}
                    classes={{ root: "block", label: classes.blockLabel }}
                  >
                    {"Superforms"}
                  </Button>

                  <Button
                    component={Link}
                    onClick={this.handleOpenModalPDF(
                      settingsFiles.SETTINGS_PAYG_PAYMENT_SUMMARY
                    )}
                    classes={{ root: "block", label: classes.blockLabel }}
                  >
                    {"PAYG Payment Summary Statement"}
                  </Button>

                  <Button
                    component={Link}
                    onClick={this.handleOpenModalPDF(
                      settingsFiles.SETTINGS_ATO_EMPLOYEE_PAYMENT_SUMMARY
                    )}
                    classes={{ root: "block", label: classes.blockLabel }}
                  >
                    {"ATO Employee Payment Summary Form"}
                  </Button>

                  <Button
                    component={Link}
                    onClick={this.handleOpenModalPDF(
                      settingsFiles.SETTINGS_FAIR_WORK_INFO_STATEMENT
                    )}
                    classes={{ root: "block", label: classes.blockLabel }}
                  >
                    {"Fair Work Info Statement"}
                  </Button>
                </Paper>

                <Paper className={classes.settingPaper}>
                  <Typography variant="h4" gutterBottom>
                    {"Information"}
                  </Typography>

                  <Button
                    component={Link}
                    onClick={this.handleOpenModalPDF(
                      settingsFiles.SETTINGS_PRIVACY_POLICY
                    )}
                    classes={{ root: "block", label: classes.blockLabel }}
                  >
                    {"Privacy Policy"}
                  </Button>

                  <Button
                    component={Link}
                    onClick={this.handleOpenModalPDF(
                      settingsFiles.SETTINGS_TERMS_OF_USE
                    )}
                    classes={{ root: "block", label: classes.blockLabel }}
                  >
                    {"Terms of Use"}
                  </Button>
                </Paper>

                <Paper className={classes.settingPaper}>
                  <Typography variant="h4" gutterBottom>
                    {"Contact us"}
                  </Typography>

                  <Typography variant="h4" className={classes.telphoneText}>
                    <ContactPhone className={classes.iconPhone} />
                    {"02 8084 9222"}
                  </Typography>

                  <Button
                    component={Link}
                    href="mailto:info@easypayslip.com"
                    target="_block"
                    classes={{ root: "block", label: classes.blockLabel }}
                  >
                    <MailOutline className={classes.iconMail} />
                    {"info@easypayslip.com"}
                  </Button>
                </Paper>
              </Grid>

              <Grid item xs={6}>
                <Paper className={classes.settingPaper}>
                  <Typography variant="h4" gutterBottom>
                    {"Settings"}
                  </Typography>
                  <Button
                    component={Link}
                    onClick={this.handleClickOptionNotification}
                    classes={{ root: "block", label: classes.blockLabel }}
                  >
                    {"Notifications"}
                  </Button>

                  {!isAccountant && (
                    <Link
                      component={RouterLink}
                      to={routes.PATH_SETTINGS_2FA}
                      className="block"
                    >
                      {"Two-Factor Authentication"}
                    </Link>
                  )}
                </Paper>
                <Paper className={classes.settingPaper}>
                  <Typography variant="h4" gutterBottom>
                    {employer &&
                    employer.financialsYear &&
                    employer.financialsYear > 0
                      ? `Financial Year ${employer.financialYear.year}`
                      : ""}
                  </Typography>
                  <Link component={RouterLink} className="block" onClick={() => this.handleSelectFYOpen()}>
                    {"Select Financial Year"}
                  </Link>
                  {yecp &&
                    yecp.map(year => {
                      return (
                        <Link
                          component={RouterLink}
                          key={year.name}
                          onClick={() => this.handelNewCloseFYOpen(year.name)}
                          className="block"
                        >
                          {`${year.name} Final STP File`}
                        </Link>
                      );
                    })}
                  {/* <Typography variant="body1" gutterBottom>
                  {"Process financial year close"}
                </Typography> */}

                  {/* <Button
                  variant="contained"
                  color="secondary"
                  classes={{
                    root: "block",
                    label: classes.blockLabel1,
                    root: classes.btnProcessClose
                  }}
                  className={classes.btnRectangle}
                  onClick={
                    isValidCloseFY
                      ? this.handleCloseFYOpen
                      : this.handleCloseFYDoneOpen
                  }
                >
                  {"Process close"}
                </Button> */}

                  {/* {employer &&
                employer.financialsYear &&
                employer.financialsYear > 1 ? (
                  <Button
                    variant="contained"
                    color="secondary"
                    classes={{
                      root: "block",
                      label: classes.blockLabel1,
                      root: classes.btnProcessClose
                    }}
                    className={
                      (classes.gridLeftClearButton, classes.btnRectangle)
                    }
                    onClick={this.handleOpenAuthorizedModal}
                  >
                    {"Generate payments summaries last period"}
                  </Button>
                ) : null} */}
                </Paper>

                {!isAccountant && (
                  <Paper className={classes.settingPaper}>
                    <Typography variant="h4" gutterBottom>
                      {"Additional User Access"}
                    </Typography>

                    <Link
                      component={RouterLink}
                      to={routes.MANAGE_ADDITIONAL_USER}
                      className="block"
                    >
                      {"Manage Additional Users"}
                    </Link>
                  </Paper>
                )}
              </Grid>
            </Grid>
            {/* NEW CLOSE FY */}
            {showModalNewCloseFY ? (
              <ModalNewCloseFYComponent
                open={showModalNewCloseFY}
                onCloseModal={this.handleNewCloseFYClose}
                showCloseFYModalPDF={this.showCloseFYModalPDF}
                yecp={yecpName}
              />
            ) : null}
            {openSettingModalDeclartion ? (
              <ModalSettingDeclaration
                open={openSettingModalDeclartion}
                onCloseModal={this.handleCloseSettingModal}
                yecp={yecpName}
              />
            ) : null}
            {openEnable2FA ? (
              <DialogConfirmCommonComponent
                open={openEnable2FA}
                title="Two-Factor Authentication is required"
                primaryButtonName="Enable"
                onPrimaryButtonClick={this.handleRedirect2FA}
                secondaryButtonName="Cancel"
                onSecondaryButtonClick={this.handleCloseEnable2FA}
                onCloseModal={this.handleCloseEnable2FA}
              >
                <Typography
                  variant="body1"
                  gutterBottom
                  className={classes.description}
                >
                  {
                    "We have added a new security layer to lodge STP files with ATO, enable the Two-Factor Authentication in 3 simple steps"
                  }
                </Typography>
              </DialogConfirmCommonComponent>
            ) : null}
            {/* show pdf when hasLodged is false */}
            {showCloseFYModalPDF && !hasLodged && hasLodged !== undefined && (
              <DialogCommonPDFComponent
                title={`${yecpName} Final STP File - Pre Lodgement Report`}
                open={showCloseFYModalPDF}
                onCloseModal={this.closeModalPDF}
                url={
                  this.props.closeFY.reportURL
                    ? this.props.closeFY.reportURL
                    : false
                }
                primaryButtonName="Lodge File"
                onPrimaryButtonClick={this.handelLodgeFile}
                downloadPdf={
                  this.props.closeFY.reportURL
                    ? this.props.closeFY.reportURL
                    : false
                }
                downloadStyle={{
                  position: "absolute",

                  width: 54,
                  height: 54,
                  backgroundColor: "#F2F2F2",
                  bottom: 130,
                  right: 50,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "50%"
                }}
                style={{
                  borderRadius: 5,
                  height: 48,
                  width: 154,
                  padding: 0,
                  marginRight: 16,
                  background: "#FD5739 0% 0% no-repeat padding-box",

                  opacity: 1
                }}
                style2={{
                  textAlign: "center",
                  letterSpacing: 0,
                  color: "#ffffff",
                  opacity: 1,
                  fontWeigth: "bold",
                  fontSize: "18px",
                  fontFamily: "AvenirNextLTPro-Demi"
                }}
                styleDiscard={{
                  color: "#004FDC",
                  fontFamily: "AvenirNextLTPro-Demi",
                  position: "absolute",
                  left: 40,
                  opacity: 1,
                  bottom: 28,
                  cursor: "pointer",
                  fontSize: 16,
                  letterSpacing: 0
                }}
                styleTitle={{
                  textAlign: "left",
                  letterSpacing: 0,
                  color: "#000000",
                  opacity: 1,
                  fontSize: 17,
                  fontFamily: "AvenirNextLTPro-Demi"
                }}
                secondaryButtonNameDiscard="Edit Amounts"
                onSecondaryButtonClick={this.handleEditAmounts}
                showScroll
                textInfo={`Click 'Lodge File' to submit Final ${yecpName} STP File or click 'Edit Amounts' to make changes`}
                textInfoStyle={{
                  textAlign: "end !important",
                  fontFamily: "AvenirNextLTPro-Demi",
                  marginBottom: 5,
                  fontSize: 16,
                  letterSpacing: 0,
                  color: "#000000",
                  opacity: 1
                }}
              />
            )}
            {/* show pdf when hasLodged is true */}
            {showCloseFYModalPDF && hasLodged && hasLodged !== undefined && (
              <DialogCommonPDFComponent
                title={`Employer ${yecpName} Final STP File Summary Report`}
                open={showCloseFYModalPDF}
                onCloseModal={this.closeModalPDF}
                url={
                  this.props.closeFY.reportURL
                    ? this.props.closeFY.reportURL
                    : false
                }
                primaryButtonName="Update Amounts with ATO"
                onPrimaryButtonClick={this.handleEditAmounts}
                downloadPdf={
                  this.props.closeFY.reportURL
                    ? this.props.closeFY.reportURL
                    : false
                }
                downloadStyle={{
                  position: "absolute",
                  width: 54,
                  height: 54,
                  backgroundColor: "#F2F2F2",
                  bottom: 130,
                  right: 50,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "50%"
                }}
                style={{
                  borderRadius: 5,
                  height: 48,
                  width: 280,
                  padding: 0,
                  marginRight: 16,
                  background: "#FD5739 0% 0% no-repeat padding-box",
                  opacity: 1
                }}
                style2={{
                  textAlign: "center",
                  letterSpacing: 0,
                  color: "#ffffff",
                  opacity: 1,
                  fontWeigth: "bold",
                  fontSize: "18px",
                  fontFamily: "AvenirNextLTPro-Demi"
                }}
                styleTitle={{
                  textAlign: "left",
                  letterSpacing: 0,
                  color: "#000000",
                  opacity: 1,
                  fontSize: 17,
                  fontFamily: "AvenirNextLTPro-Demi"
                }}
                showScroll
              />
            )}

            {showModalNotification ? (
              <ModalNotificationComponent
                open={showModalNotification}
                onCloseModal={this.handleCloseModalNotification}
              />
            ) : null}
            {showModalSelectFY &&
              <ModalSelectFY open onCloseModal={this.handleSelectFYClose} handleUpdateFY={this.handleUpdateFY} />
            }
            {PDF && PDF.Url && PDF.Url.length > 0 ? (
              <DialogCommonPDFComponent
                title={PDF.Name}
                open={PDF.Open}
                onCloseModal={this.handleCloseModalPDF}
                url={PDF.Url}
              />
            ) : null}
            {showModalCloseFY ? (
              <ModalCloseFYComponent
                open={showModalCloseFY}
                onCloseModal={this.handleCloseFYClose}
              />
            ) : null}
            {showModalCLoseFYDone ? (
              <ModalCloseFYDoneComponent
                open={showModalCLoseFYDone}
                onCloseModal={this.handleCloseFYDoneClose}
              />
            ) : null}
            {showAuthorizedModal ? (
              <ModalCloseFYAuthorizedComponent
                open={showAuthorizedModal}
                onCloseModal={this.handleCloseAuthorizedModal}
                generateLastSummaries
              />
            ) : null}
            {showSnackBarGenerateLast ? (
              <SnackbarCommonComponent
                open={showSnackBarGenerateLast}
                body="The payments summaries have been generated"
                onCloseModal={this.handleCloseSnackBarGenerateLast}
              />
            ) : null}

            {showCloseFYModalBanner &&
            hasLodged !== undefined &&
            hasLodged === false ? (
              <SnackbarCommonComponent
                open={showCloseFYModalBanner}
                body={
                  <div
                    style={{
                      textAlign: "left",
                      // fontFamily: "AvenirNextLTPro-Medium",
                      fontSize: 17,
                      letterSpacing: 0,
                      // color: "#505050",
                      opacity: 1
                    }}
                  >
                    When you have reviewed the Employee Payment amounts, <br />
                    click 'Lodge File' to submit {yecpName} Final STP File or
                    <br />
                    click 'Edit Amounts' to make changes
                  </div>
                }
                onCloseModal={this.handleCloseSnackBarGenerateLast}
                variant="info"
              />
            ) : null}
          </Paper>
          <LoadingComponent isLoading={isLoading} />
        </div>
      );
  }
}

const mapStateToProps = state => ({
  employer: state.session.userData,
  isGenerateLastPayments: state.employer.isGenerateLastPayments,
  isAccountant: state.session.isAccountant,
  yecp: state.yecp.yecpList,
  hasLodged: state.yecp.closeFY.hasLodged,
  closeFY: state.yecp.closeFY,
  message: state.yecp.message,
  isLoading: state.yecp.isLoading
});

const mapDispatchToProps = dispatch => ({
  cleanMessages: bindActionCreators(cleanMessage, dispatch),
  yecpList: bindActionCreators(getAllYECPRequest, dispatch),
  emptyCloseFY: bindActionCreators(emptyCloseFY, dispatch),
  redirectToStpCompeleted: bindActionCreators(redirectToStpCompeleted, dispatch),
  getAvailableFYs: bindActionCreators(getAvailableFYs, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(SettingStyle)(SettingComponent)));
