const employerStyle = theme => ({
	root: {
		...theme.root
	},
	closeButton: {
		position: "absolute",
		right: 5,
		top: 3,
		color: "#FFF",
		padding: 5
	},
	completeContent: {
		height: 33,
		borderRadius: 16,
		backgroundColor: "#c1d24f",
		alignItems: "center",
		display: "flex",
		paddingLeft: 15,
		color: "#fff",
		marginBottom: 10,
		position: "relative"
	},
	errorContent: {
		height: 33,
		borderRadius: 16,
		backgroundColor: "#fd5739",
		alignItems: "center",
		display: "flex",
		paddingLeft: 15,
		color: "#fff",
		marginBottom: 10,
		position: "relative"
	},
	paperContainer: {
		border: "none",
		boxShadow: "none",
		borderRadius: 8,
		height: "auto"
	},
	paperContainerBorder: {
		border: "solid 1px #f1f1f1",
		boxShadow: "0 2px 4px 0 #f5f5f5",
		borderRadius: 8,
		padding: 20
	},
	gridContainer: {
		height: 40
	},
	paper: {
		...theme.paperContainer
	},
	selectContainer: {
		border: "dashed 2px #e9e9e9",
		borderRadius: 5,
		height: 120,
		display: "flex",
		backgroundColor: "#f9f9f9"
	},
	cardBottomSpace: {
		marginBottom: 15
	},
	selectOptions: {
		width: "50%",
		textAlign: "center",
		alignItems: "center",
		display: "block",
		cursor: "pointer",
		paddingTop: 45,
		marginLeft: 1,
		borderRight: "dashed 2px #e9e9e9",
		"&:hover": {
			background: "#F3F8FF",
			borderRadius: 5
		},
		"&:last-child": {
			borderRight: "none"
		}
	},
	selectOptionsSubTitle: {
		...theme.colorActive,
		visibility: "hidden"
	},
	selectOptionsActive: {
		background: "#F3F8FF",
		borderRadius: 5
	},
	selectOptionsActiveWPN: {
		borderBottomLeftRadius: 0,
		borderTopLeftRadius: 0
	},
	selectOptionsActiveABN: {
		borderTopRightRadius: 0,
		borderBottomRightRadius: 0
	},
	selectOptionsTitleActive: {
		...theme.colorActive
	},
	paperGrid: {
		...theme.paperClear,
		padding: '0px 0px 15px 0px !important'
	},
	paperGrid2: {
		...theme.paperClear,
		padding: '0px 10px 15px 0px !important'
	},
	paperGridNotRight: {
		padding: "0 0 20px 0"
	},
	description: {
		marginBottom: 15,
		fontSize: 15
	},
	rigthColumn: {
		textAlign: "center",
		alignItems: "center",
		display: "inline-flex"
	},
	rigthColumnImg: {
		marginLeft: 25,
		width: "90%"
	},
	dialogBusinessInfo: {

		"& div": {

			"& div": {

				"&:first-child": {
					// borderRadius: 25,

				}
			},

		},

	},
	quickSetupRoot: {
		padding: '0px 0px 20px 0px !important',

	},
	titleWrapper: {
		padding: 0,

	},
	title: {
		textAlign: 'left',
		fontSize: 20,
		fontFamily: 'AvenirNextLTPro-Demi',
		letterSpacing: 0,
		color: '#000000',
		opacity: 1,
		marginBottom: 28,
		marginLeft: 10
	},
	title1: {
		textAlign: 'left',
		fontSize: 20,
		fontFamily: 'AvenirNextLTPro-Demi',
		letterSpacing: 0,
		color: '#000000',
		opacity: 1,
		marginBottom: 28,
		marginLeft: 0
	},
	subTitleOne: {
		textAlign: 'left',
		fontSize: 15,
		fontFamily: 'AvenirNextLTPro-Regular',
		letterSpacing: 0,
		color: '#000000',
		opacity: 1,
		marginLeft: 15,
		maxWidth: "initial",
	},
	subTitleOne1: {
		textAlign: 'left',
		fontSize: 15,
		fontFamily: 'AvenirNextLTPro-Regular',
		letterSpacing: 0,
		color: '#000000',
		opacity: 1,
		marginLeft: 0,
		maxWidth: "initial",
	},
	slideShow: {
		// width:640
	},
	slideShowItem: {
		paddingLeft: 5
	},
	saveContact: {

		width: '200px !important',
		height: '40px !important',
		textAlign: 'center',
		fontSize: "15px",
		borderRadius: '5px !important',
		letterSpacing: 0,
		color: '#FFFFFF',
		opacity: 1,
		padding: 0,
		fontFamily: 'AvenirNextLTPro-Demi !important',
		"& span": {
			textAlign: 'center',
			fontFamily: 'AvenirNextLTPro-Demi',
			fontSize: '15px',
			color: '#ffffff',
			opacity: 1,
			fontWeight: "bold",
		}
		// padding: '0px !important',
		// marginTop: '-16px !important',
		// marginBottom: '8px !important'
	},
	textFieldBusinessInfo: {
		backgroundColor: '#F5F5F5 !important',
		// padding: '0 0px 20px 0',
		borderRadius: '5px !important',
		height: '49px !important',
		"& div": {
			height: '100%',
			backgroundColor: '#F5F5F5 !important',

			"& input": {
				height: '47px !important',
				borderRadius: "5px !important",
				//  boxSizing: 'inherit !important',
				padding: '0px 10px 0px !important',
				backgroundColor: '#F5F5F5 !important',
				"&::placeholder": {
					color: '#6D9FEE !important',
				},
			},
		},
		"& label": {
			color: '#6D9FEE !important',
		},

	},
	saveABN: {

		minWidth: "200px !important",
		height: '40px !important',
		textAlign: 'center',
		fontSize: "15px",
		borderRadius: '5px !important',
		letterSpacing: 0,
		color: '#FFFFFF',
		opacity: 1,
		fontFamily: 'AvenirNextLTPro-Demi !important',
		padding: '0px !important',
		marginTop: '-16px !important',
		marginBottom: '8px !important',

		"& span": {
			textAlign: 'center',
			fontFamily: 'AvenirNextLTPro-Demi',
			fontSize: '15px',
			color: '#ffffff',
			opacity: 1,
			fontWeight: "bold",
		}
	},
	disabledButtonSecondary: {
		...theme.overrides.disabledButtonSecondary
	},
	labelUpButton: {
		textAlign: 'left',
		fontSize: 15,
		fontFamily: 'AvenirNextLTPro-Regular !important',
		letterSpacing: 0,
		color: '#000000',
		opacity: 1,
		minHeight: 11,

	},
	labelUpButton1: {
		textAlign: 'center',
		fontSize: 15,
		fontFamily: 'AvenirNextLTPro-Regular !important',
		letterSpacing: 0,
		color: '#000000',
		opacity: 1,
		minHeight: 11,

	},
	labelBack: {
		textAlign: 'left',
		fontFamily: 'AvenirNextLTPro-Regular !important',
		fontSize: 18,
		letterSpacing: 0,
		color: '#004FDC',
		opacity: 1,
		cursor: 'pointer !important',
		position: 'relative',
		top: -8
	},
	imageLogoATO: {
		width: 191,
		height: 191,
		opacity: 1,

	},
	buttonFinish: {
		borderRadius: '5px !important',
		opacity: 1,
		width: 200,
		height: 48,
		textAlign: 'center',
		letterSpacing: 0,
		color: '#FFFFFF',
		"& span": {
			fontSize: "18px",
			fontFamily: 'AvenirNextLTPro-Demi !important',
		}
	},
	orangeRectangularBtn: {
		...theme.overrides.orangeRectangularBtn
	},
	disabledButtonSecondary: {
		...theme.overrides.disabledButtonSecondary
	}
});

export default employerStyle;
