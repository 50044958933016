import { createLogic } from "redux-logic";
import paymentApi from "../api/PaymentApi";
import * as paymentActions from "../actions/PaymentActions";
import * as actionTypes from "../constants/actionTypes";
import * as apiResponses from "../constants/apiResponses";

const processPayment = createLogic({
	type: actionTypes.ACTION_PAYMENT_PROCESS_REQUEST,
	latest: true,
	async process({ httpClient, action }, dispatch, done) {
		try {
			const paymentData = await paymentApi.processPayment(httpClient, action.payload.paymentData);
			if (paymentData.messageCode === apiResponses.RESPONSE_OK) {
				dispatch(paymentActions.processPaymentRequestSuccess(paymentData.item, paymentData.messageCode, paymentData.message));
			} else {
				dispatch(paymentActions.processPaymentRequestFailure(paymentData.messageCode, paymentData.message));
			}
		} catch (err) {
			dispatch(paymentActions.processPaymentRequestFailure("", err.message));
		}
		done();
	}
});

export default [processPayment];
