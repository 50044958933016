import { createLogic } from "redux-logic";

import * as actionTypes from "../constants/actionTypes";
import * as apiResponses from "../constants/apiResponses";
import * as AccountantActions from "../actions/AccountantActions";
import * as employerActions from "../actions/EmployerActions";
import * as employeeActions from "../actions/EmployeeActions";
import * as sessionActions from "../actions/SessionActions";
import accountantApi from "../api/AccountantApi";
import employerApi from "../api/EmployerApi";
import commonApi from "../api/CommonApi";
import * as messages from "../constants/messages";
import * as route from "../constants/routes";
import { Analytics } from "../components/common";

// Get List Linked Employer Accounts
const getEmployerAccounts = createLogic({
	type: actionTypes.ACTION_LIST_LINKED_EMPLOYER_ACCOUNTS,
	latest: true,
	async process({ httpClient, action }, dispatch, done) {
		try {

			const response = await accountantApi.getListLinkedEmployerAccounts(httpClient);

			if (response.status === apiResponses.RESPONSE_OK_STATUS) {

				dispatch(employerActions.cleanData());
				dispatch(AccountantActions.getListLinkedEmployerAccountsSuccess(response.data.employers, response.status, response.data.message));
			} else {

				dispatch(AccountantActions.getListLinkedEmployerAccountsFailure(response.status, response.data.message));
			}
		} catch (err) {

			dispatch(AccountantActions.getListLinkedEmployerAccountsFailure("", err.message));
		}
		done();
	}
});

// Revoke Employer accountant
const linkedEmployerAccountsRevoke = createLogic({
	type: actionTypes.ACTION_LIST_LINKED_EMPLOYER_ACCOUNTS_REVOKE,
	latest: true,
	async process({ httpClient, action }, dispatch, done) {
		try {

			const response = await accountantApi.linkedEmployerAccountsRevoke(httpClient, action.payload.employerId);

			if (response.status === apiResponses.RESPONSE_OK_STATUS) {

				dispatch(AccountantActions.linkedEmployerAccountsRevokeSuccess(response.status, response.data.message));
				dispatch(AccountantActions.getListLinkedEmployerAccounts());
			} else {

				dispatch(AccountantActions.linkedEmployerAccountsRevokeFailure(response.status, response.data.message));
			}
		} catch (err) {

			dispatch(AccountantActions.linkedEmployerAccountsRevokeFailure(err.response.status, err.message));
		}
		done();
	}
});


// linked Employer accountant
const loginAsLinkedEmployer = createLogic({
	type: actionTypes.ACTION_LOGIN_AS_LINKED_EMPLOYER,
	latest: true,
	async process({ httpClient, action }, dispatch, done) {
		try {
			const response = await accountantApi.loginAsLinkedEmployer(httpClient, action.payload.employerId);

			if (response.status === apiResponses.RESPONSE_OK_STATUS) {

				dispatch(AccountantActions.loginAsLinkedEmployerSuccess(action.payload.employerId, response.status, response.data.message));
				dispatch(employeeActions.employeesRequest());
				dispatch(employerActions.getEmployer());
				dispatch(employerActions.getAuthorizedPerson());
				console.log(action.payload);

				action.payload.history.push(route.PATH_EMPLOYEE);
			} else {

				dispatch(AccountantActions.loginAsLinkedEmployerFailure(response.status, response.data.message));
			}
		} catch (err) {
			console.log(err);
			dispatch(AccountantActions.loginAsLinkedEmployerFailure(err.response.status, err.response.data.message));
		}
		done();
	}
});

const createEmployer = createLogic({
	type: actionTypes.ACTION_ACCOUNTANT_CREATE_EMPLOYER,
	latest: true,
	async process({ httpClient, action }, dispatch, done) {
		// debugger
		try {
			const response = await employerApi.create(httpClient, action.payload.employerData);
			if (response.status === 200) {
				console.log('complete register');

				// Analytics.completeRegisterAction();
				// Analytics.updateProfileInfoAction(action.payload.employerData);

				dispatch(AccountantActions.createEmployerSuccess(response.data.item, response.data.message));
				// dispatch(sessionActions.userUpdateInfo(response.item, response.messageCode, response.message));
				action.payload.history.push(route.PATH_ACCOUNTS);
			} else {
				dispatch(AccountantActions.createEmployerFailure(response.data.message));
			}
		} catch (err) {
			dispatch(AccountantActions.createEmployerFailure(err.response.data.message));
		}
		done();
	}
});

const inviteEmployer = createLogic({
	type: actionTypes.ACTION_ACCOUNTANT_INVITE_EMPLOYER,
	latest: true,
	async process({ httpClient, action}, dispatch, done) {
		try {
			const response = await accountantApi.inviteEmployer(httpClient, action.payload.employerId);
			if (response.status === 200) {
				console.log('complete register');

				// Analytics.completeRegisterAction();
				// Analytics.updateProfileInfoAction(action.payload.employerData);

				dispatch(AccountantActions.inviteEmployerSuccess(response.data.message));
				// dispatch(sessionActions.userUpdateInfo(response.item, response.messageCode, response.message));
				// action.payload.history.push(route.PATH_ACCOUNTS);
			} else {
				dispatch(AccountantActions.inviteEmployerFailure(response.data.message));
			}
		} catch (err) {
			dispatch(AccountantActions.inviteEmployerFailure(err.response.data.message));
		}
		done();
	}
})

export default [linkedEmployerAccountsRevoke, getEmployerAccounts, loginAsLinkedEmployer, createEmployer, inviteEmployer];
