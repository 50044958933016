export const AccountSignupAction = "AccountSignup.Action";
export const MAAAccountSignupAction = "MAAAccountSignup.Action";
export const CompleteRegisterAction = "CompleteRegister.Action";
export const AddEmployeeAction = "AddEmployee.Action";
export const SaveEmployeeAction = "SaveEmployee.Action";
export const InitiatePayrollAction = "InitiatePayroll.Action";
export const SubmitPayrollAction = "SubmitPayroll.Action";
export const SendPayrollAction = "SendPayroll.Action";
export const InitiateSTPAction = "InitiateSTP.Action";
export const ProcessSTPAction = "ProcessSTP.Action";
export const SendSTPAction = "SendSTP.Action";
export const UpdateProfileInfoAction = "UpdateProfileInfo.Action";
export const EditEmployeeAction = "EditEmployee.Action";
export const CancelSubscriptionAction = "CancelSubscription.Action";
export const ChangeSubscriptionAction = "ChangeSubscription.Action";
export const NewSubscriptionAction = "NewSubscription.Action";
export const LoginScreen = "Login.Screen";
export const LoginAction = "Login.Action";
export const updateProfileNameAction = "updateProfileNameAction.Action";
export const OB_6EmployeeSuccessScreen = "OB_6EmployeeSuccess.Screen";
export const PR_0SampleAction = "PR_0Sample.Action";
export const OB_6LaterPayslipAction = "OB_6LaterPayslip.Action";
export const PR_1InitiatePayrollScreen = "PR_1InitiatePayroll.Screen";
export const PR_1InitiatePayrollAction = "PR_1InitiatePayroll.Action";
export const PR_2ReviewPayrollScreen = "PR_2ReviewPayroll.Screen";
export const PR_2ReviewPayrollAction = "PR_2ReviewPayroll.Action";
export const OB_7ReviewDraftPayslipScreen = "OB_7ReviewDraftPayslip.Screen";
export const OB_7CompleteBusinessInfoAction = "OB_7CompleteBusinessInfo.Action";
export const OnboardActionWC_1 = "WC_1.Screen";
export const OnboardActionWC_2 = "WC_2.Screen";
export const OnboardActionWC_3 = "WC_3.Screen";
export const OnboardActionWC_4 = "WC_4.Screen";
export const OnboardActionWC_5 = "WC_5.Screen";
export const OnboardActionWC_6 = "WC_6.Screen";


export const OB_1BusinessNameScreen = "OB_1BusinessName.Screen";
export const OB_1BusinessNameAction = "OB_1BusinessName.Action";

export const OB_2EmailScreen = "OB_2Email.Screen";
export const OB_2EmailAction = "OB_2Email.Action";

export const OB_3PasswordScreen = "OB_3Password.Screen";

export const OB_5EmployerSuccessScreen = "OB_5EmployerSuccess.Screen";
export const OB_5LaterEmployeeAction = "OB_5LaterEmployee.Action";

export const OB_4ContactAScreen = "OB_4ContactA.Screen";
export const OB_4ContactAAction = "OB_4ContactA.Action";

export const OB_RoleScreen = "OB_Role.Screen";
export const OB_RoleAction = "OB_Role.Action";

export const OB_5AccountantSuccessScreen = "OB_5AccountantSuccess.Screen";
export const OB_5AccountantSuccessAction = "OB_5AccountantSuccess.Action";

export const PR_3ReviewPayslipScreen = "PR_3ReviewPayslip.Screen"
export const PR_3SendPayslipAction = "PR_3SendPayslip.Action"
export const PR_DiscardPayslipAction = "PR_DiscardPayslip.Action"
// Business Info
export const OB_8ABNWPNScreen = "OB_8ABNWPN.Screen";
export const OB_8ABNWPNAction = "OB_8ABNWPN.Action";
export const OB_9ContactBScreen = "OB_9ContactB.Screen";
export const OB_9ContactBAction = "OB_9ContactB.Action";
export const OB_10STPContactScreen = "OB_10STPContact.Screen";


