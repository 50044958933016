import * as common from "../constants/common";

const loginEmployer =  async (httpClient, email, password, ip, browser) => {
	const { epsApi } = window.envVariables;
	const requestData = {
		login: {
			email,
			password,
			device: {
				platform: common.COMMON_LOGIN_PLATFORM,
				id: `${ip} ${browser.version} ${browser.os}`,
				deviceData: {
					ipPublic: `${ip}`,
					browserInfo: `${browser.version} ${browser.os}`,
					platform: `${browser.name}`
				}
			}
		}
	};

	const response = await httpClient.post(`${epsApi}/api/v2/Employer/Login`, requestData);
	return response.data;
};

const loginUser = async (httpClient, email, password, ip, browser) => {
	const { goEpsApi } = window.envVariables;
	const requestData = {
		email,
		password,
		device: {
			platform: common.COMMON_LOGIN_PLATFORM,
			id: `${ip} ${browser.version} ${browser.os}`,
			deviceData: {
				ipPublic: `${ip}`,
				browserInfo: `${browser.version} ${browser.os}`,
				platform: `${browser.name}`
			}
		}
	};

	const response = await httpClient.post(`${goEpsApi}/api/v1/login`, requestData);

	return response;
};

const login2FAUser = async (httpClient, code2fa) => {
	const { goEpsApi } = window.envVariables;
	const requestData = {
		code: code2fa
	};

	const response = await httpClient.post(`${goEpsApi}/api/v1/2fa/validate`, requestData);
	return response;
};

const login2FAEmailUser = async (httpClient, code2fa) => {
	const { goEpsApi } = window.envVariables;
	const requestData = {
		code: code2fa
	};
	const response = await httpClient.post(`${goEpsApi}/api/v1/2fa/validate`, requestData);

	return  response;
};

const login2FATextUser = async (httpClient, code2fa) => {
	const { goEpsApi } = window.envVariables;
	const requestData = {
		code: code2fa
	};
	const response = await httpClient.post(`${goEpsApi}/api/v1/2fa/validate`, requestData);
	return  response;
};

const changePassword = async (httpClient, id, email, password, ip, browser) => {
	const { goEpsApi } = window.envVariables;
	const requestData = {
		id,
		login: {
			email,
			password,
			device: {
				platform: common.COMMON_LOGIN_PLATFORM,
				id: `${ip} ${browser.version} ${browser.os}`,
				deviceData: {
					ipPublic: `${ip}`,
					browserInfo: `${browser.version} ${browser.os}`,
					platform: `${browser.name}`
				}
			}
		}
	};

	const response = await httpClient.put(`${goEpsApi}/api/v1/changepassword`, requestData);
	return response.data;
};

const cleanSession = async (httpClient) => {
	const { goEpsApi } = window.envVariables;
	const response = await httpClient.post(`${goEpsApi}/api/v1/logout`);
	return response.data;
};

export default {
	loginEmployer,
	loginUser,
	login2FAUser,
	login2FAEmailUser,
	login2FATextUser,
	changePassword,
	cleanSession
};
