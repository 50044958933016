import { createLogic } from "redux-logic";
import { detect } from "detect-browser";
import publicIp from "public-ip";

import * as actionTypes from "../constants/actionTypes";
import * as apiResponses from "../constants/apiResponses";
import * as statusEmployer from "../constants/statusEmployer";
import * as common from "../constants/common";
import * as routes from "../constants/routes";
import * as sessionActions from "../actions/SessionActions";
import * as accountantActions from "../actions/AccountantActions";
import * as employerActions from "../actions/EmployerActions";
import sessionApi from "../api/SessionApi";
import accountantApi from "../api/EasyPaySlipPtyApi";
import localStorageApi from "../api/LocalStorageApi";
import mixpanel from "mixpanel-browser";
import { DeepOpen } from "../scripts";
import { Analytics } from "../components/common";
import { DEEP_LINK } from "../constants/common";

// loginEmployer
const loginEmployer = createLogic({
	type: actionTypes.ACTION_SESSION_EMPLOYER_LOGGING_IN,
	latest: true,
	async process({ httpClient, action }, dispatch, done) {
		try {
			const ip = await publicIp.v4();
			const browser = detect();

			const response = await sessionApi.loginEmployer(httpClient, action.payload.email, action.payload.password, ip, browser);
			// debugger
			if (response.messageCode === apiResponses.RESPONSE_OK) {
				const userData = response.item;
				userData.email = response.item.login.email;
				userData.token = response.item.login.device.sessionToken;
				userData.enable2FA = response.item.login.enable2FA;
				userData.messageCode = response.messageCode;
				userData.message = response.message;
				userData.method2FA = response.item.login.method2FA;
				userData.accountType = "employer";
				userData.status = response.item.status;

				// dispatch(employerActions.getAuthorizedPerson());
				dispatch(sessionActions.employerLoggingInSuccess(userData, userData.token, userData.enable2FA, response.messageCode, response.message));
				// dispatch(employerActions.getAuthorizedPerson());
				localStorageApi.setItem(common.STORAGE_SESSION_NAME, userData);

				// redirect after login if 2FA is Enable
				// redirect after login information is updated.
				if (userData.enable2FA) {
					action.payload.history.push(routes.PATH_LOGIN_2FA);
				} else if (userData.status === statusEmployer.STATUS_INIT || userData.status === statusEmployer.STATUS_UPDATE) {
					action.payload.history.push(routes.PATH_EMPLOYER_REGISTER);
					dispatch(employerActions.getAuthorizedPerson());
				} else {
					action.payload.history.push(routes.PATH_EMPLOYEE);
					dispatch(employerActions.getAuthorizedPerson());
				}
				// dispatch(employerActions.getAuthorizedPerson());
			} else {
				throw new Error(response.message);
			}
		} catch (err) {

			dispatch(sessionActions.employerLoggingInFailure(err.message));
		}
		done();
	}
});

const loginUser = createLogic({
	type: actionTypes.ACTION_SESSION_USER_LOGGING_IN,
	latest: true,
	async process({ httpClient, action }, dispatch, done) {
		try {
			let ip;
			try {
				ip = await publicIp.v4();
			} catch {
				ip = "127.0.0." + Math.floor(255 * Math.random());
			}

			const browser = detect();
			const response = await sessionApi.loginUser(httpClient, action.payload.email, action.payload.password, ip, browser);
			if (response.status === apiResponses.RESPONSE_OK_SUCCESS_CODE) {
				//#region last code
				// const userData = response.item;
				// userData.email = response.item.login.email;
				// userData.token = response.item.login.device.sessionToken;
				// userData.enable2FA = response.item.login.enable2FA;
				// userData.messageCode = response.messageCode;
				// userData.message = response.message;
				//#endregion
				//#region set user data
				const userData = response.data;
				// userData.enable2FA = response.data.enable2FA;
				// userData.valid2FA = true;
				userData.token = response.data.sessionToken;
				userData.messageCode = response.status;
				userData.message = response.data.message;
				userData.accountType = response.data.accountType;
				userData.valid2FA = response.data.valid2FA;
				userData.method2FA = response.data.method2FA;
				userData.accountStatus = response.data.accountStatus;
				userData.employerId = response.data.employerId;
				userData.phoneNumber = response.data.phoneNumber;

				let redirectURL = getUrlParam("redirect_uri", null);

				if (userData.accountType == "accountant") {
					//#region accountant
					userData.isEmployee = false;
					userData.isAccountant = true;

					userData.email = action.payload.email;
					localStorageApi.setItem(common.STORAGE_SESSION_NAME, userData);
					if (!userData.valid2FA) {

						dispatch(sessionActions.userLoggingInSuccess(userData, userData.token, true, response.status.messageCode, response.status.message, userData.accountType));
						if (redirectURL != null) {
							action.payload.history.push(routes.PATH_LOGIN_2FA + "?redirect_uri=" + encodeURIComponent(redirectURL));
						} else {
							action.payload.history.push(routes.PATH_LOGIN_2FA);
						}

					} else {
						let resp = await accountantApi.getAccountantDetails(httpClient);
						let enabled2FA = false;
						// valid2FA is true
						// if the account has 2fa setup & your device is setup for 2fa
						// OR the account does not have 2fa setup
						if (resp && resp.data && resp.data.accountant && resp.data.accountant.login) {
							enabled2FA = resp.data.accountant.login.enabled2FA;
							userData.login = resp.data.accountant.login;
						}

						userData.enable2FA = enabled2FA;


						dispatch(sessionActions.userLoggingInSuccess(userData, userData.token, enabled2FA, response.status.messageCode, response.status.message, userData.accountType));
						localStorageApi.setItem(common.STORAGE_SESSION_NAME, userData);
						if (userData.accountStatus < 2) {
							action.payload.history.push(redirectURL || routes.PATH_ACCOUNTANT_REGISTER);
						} else {
							action.payload.history.push(redirectURL || routes.PATH_ACCOUNTS);
							dispatch(employerActions.getAuthorizedPerson());
						}
					}
					localStorageApi.setItem(common.STORAGE_SESSION_NAME, userData);

					//#endregion
					// let resp = await accountantApi.getAccountantDetails(httpClient);
					// let enabled2FA = false;
					// if (resp && resp.data && resp.data.accountant && resp.data.accountant.login) {
					// 	enabled2FA = resp.data.accountant.login.enabled2FA;
					// 	userData.login = resp.data.accountant.login;
					// }


					// userData.enable2FA = enabled2FA;
					// userData.valid2FA = enabled2FA;
					// userData.method2FA = enabled2FA ? "email" : "";

					// userData.accountantId = response.data.accountantId;
					// userData.email = action.payload.email;
					//debugger
					// dispatch(sessionActions.userLoggingInSuccess(userData, userData.token, enabled2FA, response.status.messageCode, response.status.message, userData.accountType));
					// localStorageApi.setItem(common.STORAGE_SESSION_NAME, userData);



				} else if (userData.accountType == "employer") {

					//#region employer4
					userData.isEmployee = true;
					//#endregion
					//#region employer
					mixpanel.reset();
					mixpanel.identify(userData.employerId);
					Analytics.loginAction();
					if (!userData.valid2FA) {

						userData.login = {
							email: action.payload.email,
							enable2FA: true,
							device: {
								isValid2FA: false
							}
						}
						dispatch(sessionActions.userLoggingInSuccess(userData, userData.token, true, response.status.messageCode, response.status.message, userData.accountType));
						localStorageApi.setItem(common.STORAGE_SESSION_NAME, userData);
						if (redirectURL != null) {
							action.payload.history.push(routes.PATH_LOGIN_2FA + "?redirect_uri=" + encodeURIComponent(redirectURL));
						} else {
							action.payload.history.push(routes.PATH_LOGIN_2FA);
						}
					} else {

						userData.login = {
							email: action.payload.email,
						}
						dispatch(sessionActions.userLoggingInSuccess(userData, userData.token, false, response.status.messageCode, response.status.message));
						localStorageApi.setItem(common.STORAGE_SESSION_NAME, userData);
						dispatch(employerActions.getEmployer())

						action.payload.history.push(redirectURL || routes.PATH_EMPLOYEE);
					}
					// dispatch(employerActions.getAuthorizedPerson());
					//#endregion
				}
			} else {
				throw new Error(response.message);
			}
		} catch (err) {
			let er = err ? ((err.response && err.response.data) ? err.response.data.message : err.message) : "Error"
			dispatch(sessionActions.userLoggingInFailure(er));
		}
		done();
	}
});

const login2FAUser = createLogic({
	type: actionTypes.ACTION_SESSION_USER_LOGGING_2FA,
	latest: true,
	async process({ httpClient, action }, dispatch, done) {
		try {
			const response = await sessionApi.login2FAUser(httpClient, action.payload.code2fa);

			if (response.status === 200) {
				let redirectURL = getUrlParam("redirect_uri", null);

				dispatch(sessionActions.userLogging2FASuccess(2000, response.data.message));
				const userData = localStorageApi.getItem(common.STORAGE_SESSION_NAME);
				dispatch(employerActions.getAuthorizedPerson());
				if (userData.accountType === "accountant") {

					//todo get accountant
					action.payload.history.push(redirectURL || routes.PATH_ACCOUNTS)
				} else {
					dispatch(employerActions.getEmployer())
					action.payload.history.push(redirectURL || routes.PATH_EMPLOYEE);
				}

			} else {
				throw new Error(response.data.message);
			}
		} catch (err) {
			dispatch(sessionActions.userLogging2FAFailure(err.response.data.message));
		}
		done();
	}
});

function getUrlVars() {
	var vars = {};
	var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
		vars[key] = decodeURIComponent(value);
	});

	return vars;
}

function getUrlParam(parameter, defaultvalue){
	var urlparameter = defaultvalue;
	if (window.location.href.indexOf(parameter) > -1){
		urlparameter = getUrlVars()[parameter];
	}
	return urlparameter;
}

const login2FAEmailUser = createLogic({
	type: actionTypes.ACTION_SESSION_USER_LOGGING_2FA_EMAIL,
	latest: true,
	async process({ httpClient, action }, dispatch, done) {

		try {
			const response = await sessionApi.login2FAEmailUser(httpClient, action.payload.code2fa);

			if (response.status === 200) {
				let redirectURL = getUrlParam("redirect_uri", null);

				dispatch(sessionActions.userLogging2FAEmailSuccess(response.status, response.data.message));
				const userData = localStorageApi.getItem(common.STORAGE_SESSION_NAME);
				dispatch(employerActions.getAuthorizedPerson());
				if (userData.accountType === "accountant") {

					//todo get accontant
					action.payload.history.push(redirectURL || routes.PATH_ACCOUNTS)
				} else {
					dispatch(employerActions.getEmployer())
					action.payload.history.push(redirectURL || routes.PATH_EMPLOYEE);
				}

			} else {
				throw new Error(response.data.message);
			}
		} catch (err) {
			dispatch(sessionActions.userLogging2FAEmailFailure(err.response.data.message));
		}
		done();
	}
});

//Login 2FA Text
const login2FATextUser = createLogic({
	type: actionTypes.ACTION_SESSION_USER_LOGGING_2FA_TEXT,
	latest: true,
	async process({ httpClient, action }, dispatch, done) {

		try {
			const response = await sessionApi.login2FATextUser(httpClient, action.payload.code2fa);
			dispatch(employerActions.getAuthorizedPerson());
			if (response.status === 200) {
				let redirectURL = getUrlParam("redirect_uri", null);
				dispatch(sessionActions.userLogging2FATextSuccess(response.status, response.data.message));
				const userData = localStorageApi.getItem(common.STORAGE_SESSION_NAME);
				if (userData.accountType === "accountant") {
					//todo get accountant
					action.payload.history.push(redirectURL || routes.PATH_ACCOUNTS)
				} else {
					dispatch(employerActions.getEmployer())
					action.payload.history.push(redirectURL || routes.PATH_EMPLOYEE);
				}
			} else {
				throw new Error(response.data.message);
			}
		} catch (err) {

			dispatch(sessionActions.userLogging2FATextFailure(err.response.data.message));
		}
		done();
	}
});

const changePassword = createLogic({
	type: actionTypes.ACTION_SESSION_USER_CHANGE_PASSWORD,
	latest: true,
	async process({ httpClient, action }, dispatch, done) {
		try {
			const ip = await publicIp.v4();
			const browser = detect();

			const response = await sessionApi.changePassword(httpClient, action.payload.id, action.payload.email, action.payload.password, ip, browser);

			if (response.messageCode === apiResponses.RESPONSE_OK) {
				dispatch(sessionActions.userChangePasswordSuccess(response.messageCode, response.message));
				localStorageApi.deleteItem(common.STORAGE_SESSION_NAME);
				DeepOpen(DEEP_LINK);

				action.payload.history.push(routes.PATH_LOGIN);
			} else {
				throw new Error(response.message);
			}
		} catch (err) {
			dispatch(sessionActions.userChangePasswordFailure(err.message));
		}
		done();
	}
});

const cleanSessionApi = createLogic({
	type: actionTypes.ACTION_SESSION_LOGOUT_API,
	latest: true,
	async process({ httpClient }, dispatch, done) {
		try {
			await sessionApi.cleanSession(httpClient);

			dispatch(sessionActions.cleanSession());
			dispatch(accountantActions.cleanMessage());

		} catch (err) {
			// TODO: Catch
		}
		done();
	}
});


export default [loginUser, loginEmployer, login2FAEmailUser, login2FAUser, changePassword, cleanSessionApi, login2FATextUser];

