import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";

import employeeStyle from "../employee/employeeStyle";
import { DialogCommonComponent } from "../common";
import UpdateYourDetails from "../../assets/images/updateYourDetails.png";
import * as statusEmployer from "../../constants/statusEmployer";

class ModalUpdateYourDetails extends Component {
	constructor(props) {
		super(props);
		this.state = { dialogProcessOpen: false };
	}

	componentDidMount() {
		const { companyStatus } = this.props;
		const isOpen = companyStatus === statusEmployer.STATUS_UPDATE;

		// console.log('company status');
		// console.log(companyStatus);

		if (window.isOpen !== false && (companyStatus == statusEmployer.STATUS_INIT || companyStatus == statusEmployer.STATUS_UPDATE)) {
			window.isOpen = true;
		} else {
			window.isOpen = undefined;
		}

		this.setState(prevState => ({ ...prevState, dialogProcessOpen: isOpen }));
	}

	handleCancelDialogProcess = () => {
		this.setState(prevState => ({ ...prevState, dialogProcessOpen: false }));
	};

	render() {
		const { dialogProcessOpen } = this.state;

		if (dialogProcessOpen) {
			return (
				<DialogCommonComponent title="We improved for you!" size="sm" onPrimaryButtonClick={this.handleCancelDialogProcess} onCloseModal={this.handleCancelDialogProcess} primaryButtonName="Update my details" open>
					<Grid container direction="row" alignItems="center">
						<Grid item xs={6}>
							<Typography variant="body1" gutterBottom>
								{"We have made new improvements to our system, such as the new feature to lodge Single Touch Payroll (STP) files with the ATO. "}
							</Typography>
							<Typography variant="body1" gutterBottom>
								{"Before continue using the app, please complete the required details for your business."}
							</Typography>
							<Typography variant="body1" gutterBottom>
								{"Thanks for helping us to improve our system and making Easy Payslip part of your team."}
							</Typography>
						</Grid>
						<Grid item xs={6} className="rigthColumn">
							<img src={UpdateYourDetails} alt="" className="rigthColumnImg" />
						</Grid>
					</Grid>
				</DialogCommonComponent>
			);
		}
		return null;
	}
}

ModalUpdateYourDetails.propTypes = {
	companyStatus: PropTypes.any
};

const mapStateToProps = state => {
	// console.log('company state');
	// console.log(state);
	return ({
		companyStatus: state.session.userData.status
	});
}

export default connect(
	mapStateToProps,
	null
)(withStyles(employeeStyle)(ModalUpdateYourDetails));
