import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { Typography } from "@material-ui/core";
import PayrollStyle from "./payrollStyle";
import { DialogConfirmCommonComponent, Analytics } from "../common";
import ModalSTPDeclaration from "./modalSTPDeclaration";
import DialogCommonPDFComponent from "./dialogCommonPDFComponent";
import * as routes from "../../constants/routes";
import * as singleTouchActions from "../../actions/SingleTouchActions";

class ModalSTPSummaryComponent extends Component {
  state = {
    openSTPModal: false,
    openEnable2FA: false
  };

  handleOpenEnable2FA = () => {
    this.setState(() => ({
      openEnable2FA: true
    }));
  };

  handleCloseEnable2FA = () => {
    this.setState(() => ({
      openEnable2FA: false
    }));
  };

  handleOpenSTPModal = () => {
    Analytics.processSTPAction();
    this.setState(() => ({
      openSTPModal: true
    }));
  };

  handleCloseModal = () => {
    const { onCloseModal } = this.props;
    if (onCloseModal) {
      onCloseModal();
    }
  };

  handleCloseSTPModal = () => {
    this.setState(() => ({
      openSTPModal: false
    }));
  };

  handleRedirect2FA = () => {
    const { history } = this.props;
    history.push(routes.PATH_SETTINGS_2FA);
  };

  redirectToCompletedTab = () => {
    const { redirectToCompletedTab, getAllSTP } = this.props;
    redirectToCompletedTab();
    this.setState(() => ({
      openSTPModal: false
    }));
    getAllSTP();
  };

  render() {
    const { classes, open, summary, employer, hideButton } = this.props;
    const { openSTPModal, openEnable2FA } = this.state;

    return (
      <div className={classes.root}>
        <DialogCommonPDFComponent
          title="Payroll Summary"
          open={open}
          onCloseModal={this.handleCloseModal}
          url={(summary || {}).urlResume}
          primaryButtonName="Lodge STP File with ATO"
          style2={{
            textAlign: "center",
            letterSpacing: 0,
            color: "#ffffff",
            opacity: 1,
            fontWeight: "bold",
            fontSize: "15px",
            fontFamily: "AvenirNextLTPro-Demi"
          }}
          style={{ borderRadius: 5 }}
          onPrimaryButtonClick={
            employer.login.enable2FA ||
            (employer.accountType === "accountant" && employer.enabled2FA)
              ? this.handleOpenSTPModal
              : this.handleOpenEnable2FA
          }
          hideButton={hideButton}
        />
        {openSTPModal ? (
          <ModalSTPDeclaration
            {...this.props}
            open={openSTPModal}
            onCloseModal={this.handleCloseSTPModal}
            redirectToCompletedTab={this.redirectToCompletedTab}
          />
        ) : null}
        {openEnable2FA ? (
          <DialogConfirmCommonComponent
            open={openEnable2FA}
            title="Two-Factor Authentication is required"
            primaryButtonName="Enable"
            onPrimaryButtonClick={this.handleRedirect2FA}
            secondaryButtonName="Cancel"
            onSecondaryButtonClick={this.handleCloseEnable2FA}
            onCloseModal={this.handleCloseEnable2FA}
          >
            <Typography
              variant="body1"
              gutterBottom
              className={classes.description}
            >
              {
                "We have added a new security layer to lodge STP files with ATO, enable the Two-Factor Authentication in 3 simple steps"
              }
            </Typography>
          </DialogConfirmCommonComponent>
        ) : null}
      </div>
    );
  }
}

ModalSTPSummaryComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  summary: PropTypes.object,
  open: PropTypes.bool.isRequired,
  employer: PropTypes.object,
  hideButton: PropTypes.bool
};

const mapStateToProps = state => ({
  employer: state.session.userData
});

const mapDispatchToProps = dispatch => ({
  getAllSTP: bindActionCreators(singleTouchActions.getAllSTPRequest, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(PayrollStyle)(ModalSTPSummaryComponent)));
