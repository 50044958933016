import * as actionTypes from "../constants/actionTypes";

export function processPaymentRequest(paymentData) {
	return {
		type: actionTypes.ACTION_PAYMENT_PROCESS_REQUEST,
		payload: {
			isLoading: true,
			paymentData
		}
	};
}

export function processPaymentRequestSuccess(
	paymentData,
	messageCode,
	message
) {
	return {
		type: actionTypes.ACTION_PAYMENT_PROCESS_REQUEST_SUCCESS,
		payload: {
			paymentData,
			messageCode,
			message
		}
	};
}

export function processPaymentRequestFailure(messageCode, errorMessage) {
	return {
		type: actionTypes.ACTION_PAYMENT_PROCESS_REQUEST_FAILURE,
		error: true,
		payload: {
			messageCode,
			errorMessage
		}
	};
}

export function cleanMessage() {
	return {
		type: actionTypes.ACTION_PAYMENT_CLEAN_MESSAGE,
		payload: {
			errorMessage: null,
			messageCode: null,
			message: null
		}
	};
}
