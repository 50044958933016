import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter, Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import {
	Box, Button, TextField, Grid, Link, FormControl, FormHelperText, Typography, Paper
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Analytics } from "../common";
import * as routes from "../../constants/routes";
import quSeSuccess from "../../assets/images/quSeSuccess.svg";
import LogoEasyPayslip from "../../assets/images/LOGOEasyPayslipHorizontal.svg";
import { ExternalUserStyle } from "./sessionStyle";
import * as OnboardActions from "../../actions/OnboardActions";
import ReactGA from "react-ga";


class QuickSetupSuccessComponent extends Component {

	componentDidMount() {
		Analytics.OB_5EmployerSuccessScreen();
		ReactGA.pageview(window.location.pathname + window.location.search);
	}

	AddanEmployee = () => {
		const { history } = this.props;
		Analytics.addEmployeeAction();
		history.push(routes.PATH_EMPLOYEE_ADD);
	}

	handleDoThisLater = () => {
		const { history } = this.props;
		Analytics.OB_5LaterEmployeeAction();
		history.push(routes.PATH_EMPLOYEE);
	}

	render() {
		const { classes, businessData } = this.props;

		return (
			<Grid container className={classes.quickSetupRoot}>
				<Grid className={classes.quickSetupRoot} container alignItems="flex-start">
					<Grid container className={classes.rootContainer} style={{ padding: "30px" }}>
						<Grid xs={12}>
							<div className={classes.slideShow}>
								<div>
									<div className={classes.slideShowItem}>
										<div>
											<div>
												<Grid xl={6} lg={6} md={6} sm={6} >
													<img src={LogoEasyPayslip} alt="" />
												</Grid>
												<div className={classes.titleWrapper1}>
													<h1 className={classes.title}>
														Success!
                                               </h1>
													<label className={classes.subTitleOne} style={{ maxWidth: "initial", fontFamily: "AvenirNextLTPro-Regular", marginTop: 21 }}>
														Let’s show you how
													 <br />
														easy it is to make a payslip
									        	</label>
													<label className={classes.subTitleOne} style={{ fontFamily: "AvenirNextLTPro-Regular", marginTop: 21 }}>
														It will take just a minute
									        	</label>
												</div>
											</div>
										</div>
										<div style={{ textAlign: "center" }}>
											<div item >
												<img style={{ marginTop: 20, width: "184px", height: "184px" }} src={quSeSuccess} alt="" />
												{/* <label className={classes.subTitleOne} style={{ fontSize: "22px", maxWidth: "initial", fontFamily: "AvenirNextLTPro-Regular", marginBottom: 10, marginTop: 30 }}>
													It will take just a minute
									        	</label> */}
											</div>
											<div item style={{ marginTop: 70 }}>
												<FormControl fullWidth margin="normal">
													<Box m={0.1} />
													<Button variant="contained" color="primary" style={{ width: "100%" }} className={classes.signupBtn}
														onClick={this.AddanEmployee}
														type="submit">
														Add an Employee
										              	</Button>
												</FormControl>
												<FormControl fullWidth>
													<Button color="primary" className={classes.backText} onClick={this.handleDoThisLater} type="submit" style={{ minWidth: "100%" }}>
														{"Do this later"}
													</Button>
													{/* <Link component={RouterLink} to={routes.PATH_LOGIN}
														onClick={this.handleDoThisLater}
														className={clsx(classes.primaryColor, classes.accountantsSignup, "pointer", { primaryColor: true })} underline="none" variant="inherit">
														{"Do this later"}
													</Link> */}
												</FormControl>
											</div>
										</div>
									</div>
								</div>
							</div>
						</Grid>
					</Grid>
				</Grid>
			</Grid >
		);
	}
}

QuickSetupSuccessComponent.propTypes = {
	history: PropTypes.object,
	classes: PropTypes.object,
	onboard: PropTypes.object,
	businessData: PropTypes.object,
	error: PropTypes.bool,
	updateOnboardingSucsee: PropTypes.bool,
	errorMessage: PropTypes.string,
	onboardSessionid: PropTypes.string,
	signUp: PropTypes.func,
	onboardingRequest: PropTypes.func,
};

const mapStateToProps = state => ({
	error: state.external.error,
	errorMessage: state.external.errorMessage,
	onboard: state.onboard,
	message: state.onboard.message,
	onboardSessionid: state.onboard.sessionToken,
	businessData: state.onboard.businessData,
	messageCode: state.onboard.messageCode,
	updateOnboardingSucsee: state.onboard.updateOnboardingSucsee,
});

const mapDispatchToProps = dispatch => ({
	updateDataboarding: bindActionCreators(OnboardActions.updateDataDuringOnboarding, dispatch),
	cleanMessageOnboard: bindActionCreators(OnboardActions.cleanMessageOnboard, dispatch),
});

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(withStyles(ExternalUserStyle)(QuickSetupSuccessComponent))
);

