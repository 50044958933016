import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import { IconButton, Menu } from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";
import { MenuItemStyles } from "./commonStyles";

class MenuItemComponent extends Component {
	state = {
		anchorEl: null
	};

	handleCloseMenu = () => {
		this.setState({ anchorEl: null });
	};

	handleOpenMenu = (event) => {
		this.setState({ anchorEl: event.currentTarget });
		event.persist();
	};

	render() {
		const { children, classes } = this.props;
		const { anchorEl } = this.state;

		return (
			<div>
				<IconButton className={classes.buttonMenu} onClick={this.handleOpenMenu}>
					<MoreVert />
				</IconButton>

				<Menu anchorEl={anchorEl} keepMounted anchorOrigin={{ vertical: "bottom", horizontal: "right" }} open={Boolean(anchorEl)} onClose={this.handleCloseMenu} getContentAnchorEl={null} PaperProps={{ style: { width: 150 } }}>
					<div role="presentation" onClick={this.handleCloseMenu}>
						{children}
					</div>
				</Menu>
			</div>
		);
	}
}

MenuItemComponent.propTypes = {
	children: PropTypes.array.isRequired
};

export default withStyles(MenuItemStyles)(MenuItemComponent);
