import * as Yup from "yup";
import {
	validatePostCode, validatePhoneNumber, validateTfn
} from "../../helpers/extensions";

const validationSchema = Yup.object({
	detail: Yup.object().shape({
		firstName: Yup.string().required("First name is required"),
		middleName: Yup.string(),
		lastName: Yup.string().required("Last name is required"),
		dob: Yup.date().typeError("Invalid date").required("Date of birth is required"),
		phoneNumber: Yup.string()
			.test("phonenumber-validation", "Must be a valid Australian phone number", validatePhoneNumber),
		email: Yup.string().email("Must be a valid email address").required("Email is required"),
		address: Yup.object().shape({
			addressLine1: Yup.string().required("Address line 1 is required"),
			addressLine2: Yup.string(),
			townCity: Yup.string().required("Town / City is required"),
			postCode: Yup.string()
				.typeError("Must be an Australian valid postcode")
				.required("Postcode is required")
				.test("postcode-validation", "Must be an Australian valid postcode", validatePostCode),
			state: Yup.string().required("State/Territory is required"),
		}),
	}),
	payroll: Yup.object().shape({
		jobTitle: Yup.string(),
		employmentCategory: Yup.string().required("Employment term is required"),
		employmentDate: Yup.date().typeError("Invalid employment date").required("Employment start date is required"),
		status: Yup.string().required("Employment status is required"),
		payType: Yup.string().required("Payment basis is required"),
		annualSalary: Yup.number().typeError("Invalid annual salary"),
		// quantityDesc: Yup.string().required("Description is required"),
		// quantityCount: Yup.number().required("Quantity is required"),
		// quantityRate: Yup.number().required("Rate is required"),
		payFrequency: Yup.string().required("Pay frequency is required"),
		payrollWeek: Yup.number().typeError("Invalid hours in period").required("Hours in period is required"),
		hourlyRate: Yup.number().typeError("Invalid hourly Rate").required("Hourly Rate is required"),
		overtime: Yup.bool(),
		allowances: Yup.bool(),
	}),
	entitlement: Yup.object().shape({
		isAnnualLeavePay: Yup.boolean(),
		annualLeavePay: Yup.number().when("isAnnualLeavePay", {
			is: true,
			then: Yup.number().typeError("Invalid annual leave pay format").required("Annual leave pay is required"),
			otherwise: Yup.number()
		}),
		isPersonalLeavePay: Yup.boolean(),
		personalLeavePayCarrieOver: Yup.number().when("isPersonalLeavePay", {
			is: true,
			then: Yup.number().typeError("Invalid personal leave pay format").required("Personal leave pay is required"),
			otherwise: Yup.number()
		}),
		isLongServiceLeave: Yup.boolean(),
		longServiceLeaveCarrieOver: Yup.number().when("isLongServiceLeave", {
			is: true,
			then: Yup.number().typeError("Invalid long leave pay format").required("Long leave pay is required"),
			otherwise: Yup.number()
		}),
		isAnnualLeaveLoading: Yup.boolean(),
	}),
	taxe: Yup.object().shape({
		tfn: Yup.string()
			.required("tfn is required")
			.test("tfn-validation", "Use default TFN if unknown 000 000 000", validateTfn),
		taxResidency: Yup.string(),
		taxBand: Yup.string(),
		stsl: Yup.bool(),
	}),
	superannuation: Yup.object().shape({
		superRate: Yup.number().typeError("Invalid super guarantee rate format").required("Super guarantee rate is required"),
		superFundName: Yup.string(),
		usi: Yup.string(),
		superFundAbn: Yup.string(),
		membershipReference: Yup.string(),
		salarySacrifice: Yup.string(),
		additional: Yup.string(),
		spouse: Yup.string(),
	}),
});

export default validationSchema;
