import React, { Component } from "react";
import PropTypes from "prop-types";

import { getIn } from "formik";

import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import {
	Grid, Typography, Paper, TextField, InputAdornment
} from "@material-ui/core";

import PayrollStyle from "./payrollStyle";
import { NumberFormatCustomComponent } from "../common";

class PaymentDetailsComponent extends Component {

	handleChangeBase = (name, event, formProps) => {
		const { value } = event.target;

		formProps.setFieldValue(name, value);
		const total = parseFloat(value * (name === "payroll.paymentTypeBaseHours" ? formProps.values.payroll.paymentTypeHours : formProps.values.payroll.paymentTypeBaseHours)).toFixed(name === "payroll.paymentTypeBaseHours" ? 4 : 2);
		formProps.setFieldValue("payroll.paymentTypeTotal", isNaN(total) ? 0 : total);

		// const quantityTotal = parseFloat(value * (name === "payroll.quantityCount" ? formProps.values.payroll.quantityRate : formProps.values.payroll.quantityCount)).toFixed(name === "payroll.quantityCount" ? 4 : 2);
		// formProps.setFieldValue("payroll.quantityPaymentTypeTotal", isNaN(quantityTotal) ? 0 : quantityTotal);

		if (name === "payroll.paymentTypeBaseHours") {
			formProps.setFieldValue("payroll.annualLeaveHoursTotal", parseFloat(value * formProps.values.payroll.annualLeaveHours).toFixed(2));
			formProps.setFieldValue("payroll.sickLeaveHoursTotal", parseFloat(value * formProps.values.payroll.sickLeaveHours).toFixed(2));
			formProps.setFieldValue("payroll.personalLeaveHoursTotal", parseFloat(value * formProps.values.payroll.personalLeaveHours).toFixed(2));
			formProps.setFieldValue("payroll.longLeaveHoursTotal", parseFloat(value * formProps.values.payroll.longLeaveHours).toFixed(2));

			const { overtimes } = formProps.values.payroll;
			if (overtimes) {
				overtimes.forEach((overtime) => {
					overtime.overtimeTotalAmount = parseFloat(overtime.overtimeManualRate * overtime.overtimeTotal * value).toFixed(2);
				});
			}
		}
	};

	render() {
		const { classes, formProps } = this.props;
		// console.log("formProps.values.payroll.quantityPaymentTypeTotal", formProps.values.payroll.quantityPaymentTypeTotal)
		// let quantityPaymentTypeTotal = parseFloat(parseFloat(isNaN(formProps.values.payroll.quantityCount) ? 0 :
		// 	formProps.values.payroll.quantityCount).toFixed(4) * parseFloat(isNaN(formProps.values.payroll.quantityRate) ? 0 : formProps.values.payroll.quantityRate).toFixed(2)).toFixed(2);

		return (
			<Grid className={classes.gridContainer}>
				<Typography variant="h4" gutterBottom>
					{"Payment details"}
				</Typography>

				{/* <Grid container direction="row" alignItems="flex-start">
					<Grid item xs={4}
						style={{ display: formProps.values.employeeActive.payroll.payType === "Quantity" ? "block" : "none" }}
					>
						<Paper
							className={clsx(classes.paperGrid, {
								[classes.paperGridClearRight]: true
							})}
						>
							<TextField
								id="payroll.quantityDesc"
								label="Description *"
								variant="filled"
								InputProps={{
									inputProps: {
										maxLength: "40"
									}
								}}
								value={formProps.values.employeeActive.payroll.quantityDesc}
								onChange={event => this.handleChangeBase("payroll.quantityDesc", event, formProps)}
								onBlur={formProps.handleBlur}
								disabled={formProps.values.employeeActive.payroll.payType !== "Quantity"}
								className={clsx(classes.textField, {
									error: getIn(formProps.errors, "payroll.quantityDesc") && getIn(formProps.touched, "payroll.quantityDesc")
								})}
								fullWidth
							/>
							{getIn(formProps.errors, "payroll.quantityDesc") && getIn(formProps.touched, "payroll.quantityDesc")}
						</Paper>
					</Grid>

					<Grid item xs={4}
						style={{ display: formProps.values.employeeActive.payroll.payType === "Quantity" ? "block" : "none" }}
					>
						<Paper
							className={clsx(classes.paperGrid, {
								[classes.paperGridClearRight]: true
							})}
						>
							<TextField
								id="payroll.quantityRate"
								label="Rate *"
								variant="filled"
								InputProps={{
									inputComponent: NumberFormatCustomComponent,
									inputProps: {
										decimalScale: 2,
										fixedDecimalScale: true
									}
								}}
								value={formProps.values.payroll.quantityRate}
								onChange={event => this.handleChangeBase("payroll.quantityRate", event, formProps)}
								onBlur={formProps.handleBlur}
								disabled={formProps.values.employeeActive.payroll.payType !== "Quantity"}
								className={clsx(classes.textField, {
									error: getIn(formProps.errors, "payroll.quantityRate") && getIn(formProps.touched, "payroll.quantityRate")
								})}
								fullWidth
							/>
							{getIn(formProps.errors, "payroll.quantityRate") && getIn(formProps.touched, "payroll.quantityRate")}
						</Paper>
					</Grid>

					<Grid item xs={4}
						style={{ display: formProps.values.employeeActive.payroll.payType === "Quantity" ? "block" : "none" }}
					>
						<Paper className={classes.paperGrid}>
							<TextField
								id="payroll.quantityCount"
								label="Quantity *"
								variant="filled"
								type="number"
								value={formProps.values.payroll.quantityCount}
								onChange={event => this.handleChangeBase("payroll.quantityCount", event, formProps)}
								onBlur={formProps.handleBlur}
								className={clsx(classes.textField, {
									error: getIn(formProps.errors, "payroll.quantityCount") && getIn(formProps.touched, "payroll.quantityCount")
								})}
								fullWidth
							/>
							{getIn(formProps.errors, "payroll.quantityCount") && getIn(formProps.touched, "payroll.quantityCount")}
						</Paper>
					</Grid>

					<Grid item xs={4}
						style={{ display: formProps.values.employeeActive.payroll.payType === "Quantity" ? "block" : "none" }}
					>
						<Paper className={classes.paperGrid}>
							<TextField
								id="payroll.quantityPaymentTypeTotal"
								label="Total "
								fullWidth
								InputProps={{
									startAdornment: <InputAdornment position="start">$</InputAdornment>,
									readOnly: true,
									inputComponent: NumberFormatCustomComponent,
									inputProps: {
										decimalScale: 4
									}
								}}
								value={quantityPaymentTypeTotal}
								onChange={formProps.handleChange}
								onBlur={formProps.handleBlur}
								className={clsx("onlyReadTitle", {
									error: getIn(formProps.errors, "payroll.quantityPaymentTypeTotal") && getIn(formProps.touched, "payroll.quantityPaymentTypeTotal")
								})}
							/>
						</Paper>
					</Grid>
				</Grid> */}



				<Grid container direction="row" alignItems="flex-start">
					<Grid item xs={4}>
						<Paper className={classes.paperGrid}>
							<TextField
								id="payroll.paymentTypeBaseHours"
								label="Base Hourly Rate"
								InputProps={{
									startAdornment: <InputAdornment position="start">$</InputAdornment>,
									inputComponent: NumberFormatCustomComponent,
									inputProps: {
										decimalScale: 4
									}
								}}
								variant="filled"
								fullWidth
								value={formProps.values.payroll.paymentTypeBaseHours}
								onChange={(value) => {
									this.handleChangeBase("payroll.paymentTypeBaseHours", value, formProps);
								}}
								onBlur={formProps.handleBlur}
								className={clsx(classes.textField, {
									error: getIn(formProps.errors, "payroll.paymentTypeBaseHours") && getIn(formProps.touched, "payroll.paymentTypeBaseHours")
								})}
							/>
						</Paper>
					</Grid>

					<Grid item xs={4}>
						<Paper className={classes.paperGrid}>
							<TextField
								id="payroll.paymentTypeHours"
								label="Hours"
								variant="filled"
								fullWidth
								InputProps={{
									inputComponent: NumberFormatCustomComponent,
									inputProps: {
										decimalScale: 2
									}
								}}
								value={formProps.values.payroll.paymentTypeHours}
								onChange={(value) => {
									this.handleChangeBase("payroll.paymentTypeHours", value, formProps);
								}}
								onBlur={formProps.handleBlur}
								className={clsx(classes.textField, {
									error: getIn(formProps.errors, "payroll.paymentTypeHours") && getIn(formProps.touched, "payroll.paymentTypeHours")
								})}
							/>
						</Paper>
					</Grid>

					<Grid item xs={4}>
						<Paper className={classes.paperGrid}>
							<TextField
								id="payroll.paymentTypeTotal"
								label="Total"
								fullWidth
								InputProps={{
									startAdornment: <InputAdornment position="start">$</InputAdornment>,
									readOnly: true,
									inputComponent: NumberFormatCustomComponent,
									inputProps: {
										decimalScale: 4
									}
								}}
								value={formProps.values.payroll.paymentTypeTotal}
								onChange={formProps.handleChange}
								onBlur={formProps.handleBlur}
								className={clsx("onlyReadTitle", {
									error: getIn(formProps.errors, "payroll.paymentTypeTotal") && getIn(formProps.touched, "payroll.paymentTypeTotal")
								})}
							/>
						</Paper>
					</Grid>
				</Grid>
			</Grid>
		);
	}
}

PaymentDetailsComponent.propTypes = {
	formProps: PropTypes.object.isRequired
};

export default withStyles(PayrollStyle)(PaymentDetailsComponent);
