import * as actionTypes from "../constants/actionTypes";

export function getAllParametersRequest() {
	
	return {
		type: actionTypes.ACTION_PARAMTERS_GETALL_REQUEST,
		payload: {}
	};
}

export function getAllParametersRequestSuccess(parametersData) {
	return {
		type: actionTypes.ACTION_PARAMTERS_GETALL_REQUEST_SUCCESS,
		payload: {
			parametersData
		}
	};
}

export function getAllParametersRequestFailure(messageCode, errorMessage) {
	return {
		type: actionTypes.ACTION_PARAMTERS_GETALL_REQUEST_FAILURE,
		error: true,
		payload: {
			messageCode,
			errorMessage
		}
	};
}

export function getParametersByTypeRequest(parameterType, section, typeSection) {
	return {
		type: actionTypes.ACTION_PARAMTERS_GETBYTYPE_REQUEST,
		payload: {
			parameterType,
			section,
			typeSection
		}
	};
}

export function getParametersByTypeRequestSuccess(parameterType, parametersData) {
	return {
		type: actionTypes.ACTION_PARAMTERS_GETBYTYPE_REQUEST_SUCCESS,
		payload: {
			parametersData,
			parameterType
		}
	};
}

export function getParametersByTypeRequestFailure(messageCode, errorMessage) {
	return {
		type: actionTypes.ACTION_PARAMTERS_GETBYTYPE_REQUEST_SUCCESS,
		error: true,
		payload: {
			messageCode,
			errorMessage
		}
	};
}

export function getPlansRequest() {
	return {
		type: actionTypes.ACTION_PARAMTERS_PLANS_REQUEST,
		payload: {}
	};
}

export function getPlansRequestSuccess(plansData) {
	return {
		type: actionTypes.ACTION_PARAMTERS_PLANS_REQUEST_SUCCESS,
		payload: {
			plansData
		}
	};
}

export function getPlansRequestFailure(messageCode, errorMessage) {
	return {
		type: actionTypes.ACTION_PARAMTERS_PLANS_REQUEST_FAILURE,
		error: true,
		payload: {
			messageCode,
			errorMessage
		}
	};
}
