import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
	Grid, Paper, TextField, Typography, FormHelperText
} from "@material-ui/core";
import clsx from "clsx";
import { getIn } from "formik";
import EasyPaySlipPtyStyle from "./easyPaySlipPtyStyle";

import { CardCommonComponent } from "../common";


class EasyPaySlipPtyDetailsComponent extends Component {

	constructor(props) {
		super(props);
		this.state = { isComplete: false };
	}

	renderAustraliaStateOptions = () => {
		const { classes } = this.props;
		let stateList = [{
			"value": "Accountant / Bookkeeper",
			"order": 0
		},
		{
			"value": "Other",
			"order": 1
		}

		]
		return stateList
			.sort((firstEl, secondEl) => firstEl.order < secondEl.order)
			.map(item => <option key={`${item.id}`} value={`${item.value}`} className={classes.option}>{item.value}</option>);
	}

	render() {
		const { isComplete, classes, formProps } = this.props;

		return (
			<CardCommonComponent title="Your details" complete={isComplete}>
				<Typography variant="body1" gutterBottom>
					Enter your contact details below
				</Typography>
				<Grid container direction="row" alignItems="flex-start">
					<Grid item xs={4}>
						<Paper className={classes.paperGrid}>
							<TextField
								id="details.first"
								label="First name *"
								variant="filled"
								value={formProps.values.details.first ? formProps.values.details.first.trim() : " "}
								onChange={formProps.handleChange}
								onBlur={formProps.handleBlur}
								className={clsx(classes.textField, {
									error: getIn(formProps.errors, "details.first") && getIn(formProps.touched, "details.first")
								})}
								fullWidth
							/>
							{getIn(formProps.errors, "details.first") && getIn(formProps.touched, "details.first") && <FormHelperText error>{formProps.errors.details.first}</FormHelperText>}
						</Paper>
					</Grid>

					<Grid item xs={4}>
						<Paper className={classes.paperGrid}>
							<TextField
								id="details.middle"
								label="Middle name (If applicable)"
								variant="filled"
								value={formProps.values.details.middle ? formProps.values.details.middle.trim() : " "}
								onChange={formProps.handleChange}
								onBlur={formProps.handleBlur}
								className={clsx(classes.textField)}
								fullWidth
							/>
						</Paper>
					</Grid>

					<Grid item xs={4}>
						<Paper className={classes.paperGrid}>
							<TextField
								id="details.last"
								label="Last name *"
								variant="filled"
								value={formProps.values.details.last ? formProps.values.details.last.trim() : " "}
								onChange={formProps.handleChange}
								onBlur={formProps.handleBlur}
								className={clsx(classes.textField, {
									error: getIn(formProps.errors, "details.last") && getIn(formProps.touched, "details.last")
								})}
								fullWidth
							/>
							{getIn(formProps.errors, "details.last") && getIn(formProps.touched, "details.last") && <FormHelperText error>{formProps.errors.details.last}</FormHelperText>}
						</Paper>
					</Grid>

					<Grid item xs={4}>
						<Paper className={classes.paperGrid}>
							<TextField
								id="details.phone"
								label="Account contact phone number *"
								variant="filled"
								value={formProps.values.details.phone ? formProps.values.details.phone.trim() : " "}
								onChange={formProps.handleChange}
								onBlur={formProps.handleBlur}
								className={clsx(classes.textField, {
									error: getIn(formProps.errors, "details.phone") && getIn(formProps.touched, "details.phone")
								})}
								fullWidth
							/>
							{getIn(formProps.errors, "details.phone") && getIn(formProps.touched, "details.phone") && <FormHelperText error>{formProps.errors.details.phone}</FormHelperText>}
						</Paper>
					</Grid>

					<Grid item xs={4}>
						<Paper className={classes.paperGrid}>
							<TextField
								id="login.email"
								label="Email address *"
								variant="filled"
								type="email"
								value={formProps.values.login.email ? formProps.values.login.email.trim() : " "}
								InputProps={{
									readOnly: true
								}}
								onChange={formProps.handleChange}
								onBlur={formProps.handleBlur}
								disabled={true}
								className={clsx(classes.textField, {
									error: getIn(formProps.errors, "login.email") && getIn(formProps.touched, "login.email")
								})}
								fullWidth
							/>
						</Paper>
					</Grid>

					<Grid item xs={4} />

					<Grid item xs={4}>
						<Paper className={classes.paperGrid}>
							<TextField
								id="details.title"
								label="Title *"
								variant="filled"
								value={formProps.values.details.title ? formProps.values.details.title.trim() : " "}
								onChange={formProps.handleChange}
								onBlur={formProps.handleBlur}
								className={clsx(classes.textField, {
									error: (getIn(formProps.errors, "details.title") && getIn(formProps.touched, "details.title"))
								})}
								SelectProps={{
									native: true,
									MenuProps: {
										className: classes.menu
									}
								}}
								select
								fullWidth
							>
								{this.renderAustraliaStateOptions()}
							</TextField>
							{(getIn(formProps.errors, "details.title") && getIn(formProps.touched, "details.title") && <FormHelperText error>{formProps.errors.details.title}</FormHelperText>)}
						</Paper>
					</Grid>
					{
						formProps.values.details && formProps.values.details.title !== "Other" ?
								<Grid item xs={4}>
									<Paper className={classes.paperGrid}
											style={{ display: `${formProps.values.details && formProps.values.details.title !== "Other" ? "" : "none"}` }}>
										<TextField
											id="details.RAN"
											label={"Registered Agent Number"}
											variant="filled"
											value={formProps.values.details.RAN ? formProps.values.details.RAN.trim() : " "}
											onChange={formProps.handleChange}
											onBlur={formProps.handleBlur}
											className={clsx(classes.textField, {
												error: getIn(formProps.errors, "details.RAN") && getIn(formProps.touched, "details.RAN")
											})}
											fullWidth
										/>
										{getIn(formProps.errors, "details.RAN") && getIn(formProps.touched, "details.RAN") && <FormHelperText error>{formProps.errors.details.RAN}</FormHelperText>}
									</Paper>
								</Grid>
								:
								<Grid item xs={4}>
									<Paper className={classes.paperGrid}
									>
										<TextField
											id="details.jobDesc"
											label="Relationship to account holder *"
											variant="filled"
											value={formProps.values.details.jobDesc ? formProps.values.details.jobDesc : ""}
											onChange={formProps.handleChange}
											onBlur={formProps.handleBlur}
											className={clsx(classes.textField, {
												error: getIn(formProps.errors, "details.jobDesc") && getIn(formProps.touched, "details.jobDesc")
											})}
											fullWidth
										/>
										{getIn(formProps.errors, "details.jobDesc") && getIn(formProps.touched, "details.jobDesc") && <FormHelperText error>{formProps.errors.details.jobDesc}</FormHelperText>}
									</Paper>
								</Grid>
					}

				</Grid>
			</CardCommonComponent >
		);
	}
}

export default withStyles(EasyPaySlipPtyStyle)(EasyPaySlipPtyDetailsComponent);
