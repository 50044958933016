import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";

import { LoadingComponent, SnackbarCommonComponent } from "../components/common";
import LoginComponent from "../components/Session/LoginComponent";
import { ExternaUserStyles } from "./PagesStyle";
import { mapMessageCodeToIcon } from "../helpers/extensions";
import { cleanMessage as externalCleanMessage } from "../actions/ExternalUserActions";
import { cleanMessage as sessionCleanMessage } from "../actions/SessionActions";

class LoginPage extends Component {
	render() {
		const {
			classes, isLoading, externalMessageCode, externalMessage, externalCleanMessageInternal, sessionMessageCode, sessionMessage, sessionCleanMessageInternal
		} = this.props;

		return (
			<div className={classes.root}>
				<CssBaseline />
				<LoginComponent />
				<LoadingComponent isLoading={isLoading} />
			{	
				externalMessage !== "Success" && <SnackbarCommonComponent key="SnackBarExternal" variant={mapMessageCodeToIcon(externalMessageCode)} 
				open={!!externalMessage} body={externalMessage} onCloseModal={externalCleanMessageInternal} 
				/>
				}

				<SnackbarCommonComponent key="SnackBarSession" variant={mapMessageCodeToIcon(sessionMessageCode)}
				 open={!!sessionMessage} body={sessionMessage} onCloseModal={sessionCleanMessageInternal} 
				 />
		
			</div>
		);
	}
}

LoginPage.propTypes = {
	classes: PropTypes.object.isRequired,
	isLoading: PropTypes.bool,
	externalMessageCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	externalMessage: PropTypes.string,
	sessionMessageCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	sessionMessage: PropTypes.string
};

const mapStateToProps = state => ({
	isLoading: state.external.isLoading || state.session.isLoading,
	externalMessageCode: state.external.messageCode,
	externalMessage: state.external.message,
	sessionMessageCode: state.session.messageCode,
	sessionMessage: state.session.message
});

const mapDispatchToProps = dispatch => ({
	externalCleanMessageInternal: bindActionCreators(externalCleanMessage, dispatch),
	sessionCleanMessageInternal: bindActionCreators(sessionCleanMessage, dispatch)
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(ExternaUserStyles)(LoginPage));
