
// get List Linked Employer Accounts
const getListLinkedEmployerAccounts = async httpClient => {

	const { goEpsApi } = window.envVariables;
	const response = await httpClient.get(`${goEpsApi}/api/v1/accountant/employers`);
	return response;
};

// linked Employer Accounts Revoke
const linkedEmployerAccountsRevoke = async (httpClient, employerId) => {
	const { goEpsApi } = window.envVariables;
	const response = await httpClient.delete(`${goEpsApi}/api/v1/accountant/employers/${employerId}`);
	return response;
}

// Login As Linked Employer
const loginAsLinkedEmployer = async (httpClient, employerId) => {
	const { goEpsApi } = window.envVariables;
	const response = await httpClient.post(`${goEpsApi}/api/v1/accountant/employers/${employerId}`);
	return response;
};

const inviteEmployer = async (httpClient, employerId) => {
	const { goEpsApi } = window.envVariables;

	const response = await httpClient.post(`${goEpsApi}/api/v1/accountant/employers/${employerId}/invite`);

	return response;
}

export default {
	inviteEmployer,
	getListLinkedEmployerAccounts,
	linkedEmployerAccountsRevoke,
	loginAsLinkedEmployer
};





