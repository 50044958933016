import axios from "axios";

import localStorageApi from "../api/LocalStorageApi";
import * as common from "../constants/common";

axios.interceptors.request.use((config) => {
	let finalConfig = { ...config };

	if (finalConfig.headers.common.new_session) {
		delete finalConfig.headers.common.new_session;

		const userData = localStorageApi.getItem(common.STORAGE_SESSION_NAME);
		if (userData) {
			finalConfig.headers.common.SessionId = `${userData.token}`;
			
		} else {
			finalConfig.headers.common.SessionId = null;
		}

	} else {
		const userData = localStorageApi.getItem(common.STORAGE_SESSION_NAME);
		
		if (userData) {
			finalConfig.headers.common.SessionId = `${userData.token}`;
		} else {
			finalConfig.headers.common.SessionId = null;
		}
	}

	return finalConfig;
});

export default axios;

