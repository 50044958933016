import React, { Component } from "react";
import PropTypes from "prop-types";

import { getIn } from "formik";

import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import {
	Grid, Typography, Paper, TextField, InputAdornment
} from "@material-ui/core";

import PayrollStyle from "./payrollStyle";

class EntitlementsComponent extends Component {
	handleChangeBase = (name, event, formProps, nameTotal) => {
		const { value } = event.target;

		formProps.setFieldValue(name, value);

		formProps.setFieldValue(nameTotal, parseFloat(value * formProps.values.payroll.paymentTypeBaseHours).toFixed(4));
	};

	render() {
		const { classes, formProps } = this.props;

		return (
			<Grid className={classes.gridContainer}>
				<Typography variant="h4" gutterBottom>
					Entitlements
				</Typography>
				<Grid container direction="row" alignItems="flex-start">
					{formProps.values.payroll.isAnnualLeave
						? [
							<Grid item xs={4} key="isAnnualLeave_1">
								<Paper className={classes.paperGrid}>
									<TextField
										id="payroll.annualLeaveHours"
										label="Annual leave hours taken"
										variant="filled"
										fullWidth
										value={formProps.values.payroll.annualLeaveHours}
										onChange={(value) => {
											this.handleChangeBase("payroll.annualLeaveHours", value, formProps, "payroll.annualLeaveHoursTotal");
										}}
										onBlur={formProps.handleBlur}
										className={clsx(classes.textField, {
											error: getIn(formProps.errors, "payroll.annualLeaveHours") && getIn(formProps.touched, "payroll.annualLeaveHours")
										})}
									/>
								</Paper>
							</Grid>,
							<Grid item xs={4} key="isAnnualLeave_2">
								<Paper className={classes.paperGrid}>
									<TextField
										id="payroll.annualLeaveHoursTotal"
										label="Total"
										InputProps={{
											startAdornment: <InputAdornment position="start">$</InputAdornment>,
											readOnly: true
										}}
										fullWidth
										value={formProps.values.payroll.annualLeaveHoursTotal}
										onChange={formProps.handleChange}
										onBlur={formProps.handleBlur}
										className={clsx("onlyReadTitle", {
											error: getIn(formProps.errors, "payroll.annualLeaveHoursTotal") && getIn(formProps.touched, "payroll.annualLeaveHoursTotal")
										})}
									/>
								</Paper>
							</Grid>,
							<Grid item xs={4} key="isAnnualLeave_3" />
						  ]
						: null}

					{formProps.values.payroll.isSickLeave
						? [
							<Grid item xs={4} key="isSickLeave_1">
								<Paper className={classes.paperGrid}>
									<TextField
										id="payroll.sickLeaveHours"
										label="Sick leave hours taken"
										variant="filled"
										fullWidth
										value={formProps.values.payroll.sickLeaveHours}
										onChange={(value) => {
											this.handleChangeBase("payroll.sickLeaveHours", value, formProps, "payroll.sickLeaveHoursTotal");
										}}
										onBlur={formProps.handleBlur}
										className={clsx(classes.textField, {
											error: getIn(formProps.errors, "payroll.sickLeaveHours") && getIn(formProps.touched, "payroll.sickLeaveHours")
										})}
									/>
								</Paper>
							</Grid>,
							<Grid item xs={4} key="isSickLeave_2">
								<Paper className={classes.paperGrid}>
									<TextField
										id="payroll.sickLeaveHoursTotal"
										label="Total"
										InputProps={{
											startAdornment: <InputAdornment position="start">$</InputAdornment>,
											readOnly: true
										}}
										fullWidth
										value={formProps.values.payroll.sickLeaveHoursTotal}
										onChange={formProps.handleChange}
										onBlur={formProps.handleBlur}
										className={clsx("onlyReadTitle", {
											error: getIn(formProps.errors, "payroll.sickLeaveHoursTotal") && getIn(formProps.touched, "payroll.sickLeaveHoursTotal")
										})}
									/>
								</Paper>
							</Grid>,
							<Grid item xs={4} key="isSickLeave_3" />
						  ]
						: null}

					{formProps.values.payroll.isPersonalLeave
						? [
							<Grid item xs={4} key="isPersonalLeave_1">
								<Paper className={classes.paperGrid}>
									<TextField
										id="payroll.personalLeaveHours"
										label="Personal leave hours taken"
										variant="filled"
										fullWidth
										value={formProps.values.payroll.personalLeaveHours}
										onChange={(value) => {
											this.handleChangeBase("payroll.personalLeaveHours", value, formProps, "payroll.personalLeaveHoursTotal");
										}}
										onBlur={formProps.handleBlur}
										className={clsx(classes.textField, {
											error: getIn(formProps.errors, "payroll.personalLeaveHours") && getIn(formProps.touched, "payroll.personalLeaveHours")
										})}
									/>
								</Paper>
							</Grid>,
							<Grid item xs={4} key="isPersonalLeave_2">
								<Paper className={classes.paperGrid}>
									<TextField
										id="payroll.personalLeaveHoursTotal"
										label="Total"
										InputProps={{
											startAdornment: <InputAdornment position="start">$</InputAdornment>,
											readOnly: true
										}}
										fullWidth
										value={formProps.values.payroll.personalLeaveHoursTotal}
										onChange={formProps.handleChange}
										onBlur={formProps.handleBlur}
										className={clsx("onlyReadTitle", {
											error: getIn(formProps.errors, "payroll.personalLeaveHoursTotal") && getIn(formProps.touched, "payroll.personalLeaveHoursTotal")
										})}
									/>
								</Paper>
							</Grid>,
							<Grid item xs={4} key="isPersonalLeave_3" />
						  ]
						: null}

					{formProps.values.payroll.isLongServiceLeave
						? [
							<Grid item xs={4} key="isLongServiceLeave_1">
								<Paper className={classes.paperGrid}>
									<TextField
										id="payroll.longLeaveHours"
										label="Long service leave taken"
										variant="filled"
										fullWidth
										value={formProps.values.payroll.longLeaveHours}
										onChange={(value) => {
											this.handleChangeBase("payroll.longLeaveHours", value, formProps, "payroll.longLeaveHoursTotal");
										}}
										onBlur={formProps.handleBlur}
										className={clsx(classes.textField, {
											error: getIn(formProps.errors, "payroll.longLeaveHours") && getIn(formProps.touched, "payroll.longLeaveHours")
										})}
									/>
								</Paper>
							</Grid>,
							<Grid item xs={4} key="isLongServiceLeave_2">
								<Paper className={clsx(classes.paperGrid)}>
									<TextField
										id="payroll.longLeaveHoursTotal"
										label="Total"
										InputProps={{
											startAdornment: <InputAdornment position="start">$</InputAdornment>,
											readOnly: true
										}}
										fullWidth
										value={formProps.values.payroll.longLeaveHoursTotal}
										onChange={formProps.handleChange}
										onBlur={formProps.handleBlur}
										className={clsx("onlyReadTitle", {
											error: getIn(formProps.errors, "payroll.longLeaveHoursTotal") && getIn(formProps.touched, "payroll.longLeaveHoursTotal")
										})}
									/>
								</Paper>
							</Grid>
						  ]
						: null}
				</Grid>
			</Grid>
		);
	}
}

EntitlementsComponent.propTypes = {
	formProps: PropTypes.object.isRequired
};

export default withStyles(PayrollStyle)(EntitlementsComponent);
