import initialState from "../../store/initialState";

export const couponDefaultData = {
    item: {
        id: '',
        name: '',
        duration: '',
        percentageOff: null
    }
}

const couponInitialState = {
    ...initialState,
    ...couponDefaultData
};

export default couponInitialState;
