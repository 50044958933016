const SettingStyle = theme => ({
  root: {
    ...theme.root
  },
  paperContainer: {
    ...theme.paperContainer
  },
  paper: {
    width: "100%",
    height: 65,
    borderRadius: 5,
    border: "1px solid #e9e9e9",
    backgroundColor: "#ffffff",
    padding: "10px 20px",
    boxShadow: "none",
    marginBottom: 12,
    "&:last-child": {
      marginBottom: 0
    },
    "&:hover": {
      boxShadow: "0 2px 2px 0 #D8D8D8"
    }
  },
  employersListWrapper: {
    marginBottom: 20
  },
  paperGrid: {
    ...theme.paperGrid,
    padding: "0 15px 20px 0;"
  },
  paperGrid2: {
    ...theme.paperClear
  },
  divider: {
    width: 980,
    position: "relative",
    left: -40,
    margin: "16px 0"
  },
  gridRightClearButton: {
    marginTop: 21
  },
  alignCenter: {
    textAlign: "center",
    paddingLeft: 40
  },
  imageQR: {
    width: 200,
    heigth: 200
  },
  paperKey: {
    wordBreak: "break-all",
    backgroundColor: "#F5F5F5",
    color: "#7D848B",
    marginTop: 10,
    padding: 16,
    boxShadow: "none"
  },
  iconKey: {
    fontSize: 25
  },
  iconCheck: {
    fontSize: 20,
    color: "#C1D24F"
  },
  cardRevoke: {
    width: 380,
    border: "1px solid #F1F1F1",
    borderRadius: 8,
    backgroundColor: "#FFFFFF",
    boxShadow: "0 2px 6px 0 #DFDFE2",
    padding: "18px 20px 16px 32px"
  },
  cardButton: {
    width: "100%",
    textAlign: "right"
  },
  gridRight: {
    textAlign: "right",
    marginRight: 29
  },
  gridContainer: {
    marginTop: 56
  },
  settingPaper: {
    borderRadius: 5,
    boxShadow: "none",
    border: "1px solid #E9E9E9",
    padding: "15px 10px 16px 50px",
    marginBottom: 22
  },
  blockLabel: {
    ...theme.blockLabel,
    fontSize: "15px !important"
  },
  blockLabel1: {
    ...theme.blockLabel,
    fontSize: "15px !important",
    fontWeight: "bold"
  },
  btnProcessClose: {
    ...theme.root,
    marginLeft: 0
  },
  gridLeftClearButton: {
    padding: 0,
    margin: "0 0 20px 0"
  },
  centerText: {
    textAlign: "center"
  },
  imageEmptyContainer: {
    width: "100%",
    textAlign: "center",
    marginTop: 40
  },
  imageEmptyImage: {
    width: 580,
    marginLeft: -70,
    marginBottom: 20
  },
  telphoneText: {
    fontSize: 16
  },
  iconMail: {
    fontSize: 21,
    marginRight: 3,
    position: "relative",
    top: 4,
    left: -1
  },
  iconPhone: {
    fontSize: 17,
    marginRight: 3,
    position: "relative",
    top: 3
  },
  linkMail: {
    color: "#007AFF !important",
    fontSize: "25px !important"
  },
  paper2fa: {
    padding: "20px"
  },
  getCode: {
    marginBottom: 0
  },
  iconInTop: {
    width: 100,
    height: 100,
    fontSize: 100,
    color: "#C1D24F",
    "&.error": {
      color: "#FD5739"
    }
  },
  containerInputs: {
    alignItems: "center",
    display: "flex",
    justifyItems: "center"
  },
  containerControls: {
    display: "block"
  },
  btnRectangle: {
    borderRadius: "5px !important",

    "& span": {
      fontFamily: "AvenirNextLTPro-Demi !important"
    }
  },
  paragraphModal: {
    display: "flex",
    flexDirection: "row"
  },
  textModal: {
    textAlign: "left",
    fontFamily: "AvenirNextLTPro-Regular",
    fontSize: 15,
    letterSpacing: 0,
    color: "rgba(0, 0, 0, 0.87)",
    opacity: 1
  },
  textModal1: {
    textAlign: "left",
    fontFamily: "AvenirNextLTPro-Regular",
    fontSize: 15,
    letterSpacing: 0,
    // color: "#505050",
    opacity: 1,
    marginLeft: 8,

    fontWeight: 400,
    lineHeight: 1.5,
    color: "rgba(0, 0, 0, 0.87)"
  },
  textcircle: {
    fontSize: 15
  },
  signatureContainer: {
    padding: "60px 0px"
  },
  iconInTop: {
    width: 30,
    height: 30,
    fontSize: 30,
    marginLeft: 10
  },
  signatureText: {
    textAlign: "center",
    fontFamily: "AvenirNextLTPro-Regular",
    fontSize: 15,
    letterSpacing: "0.01px",
    color: "#A5A5A8",
    opacity: 1
  },
  signatureDescriptionText: {
    textAlign: "center",
    fontFamily: "AvenirNextLTPro-Regular",
    fontSize: 15,
    letterSpacing: 0,
    color: "#5C5C5C",
    opacity: 1
  },
  checkIcon: {
    color: "#BBBBBB",
    fontSize: 30,
    marginLeft: 10,
    "&.active": {
      color: "#C1D24F"
    }
  },
  IconCheckActive: {
    fontSize: 23,
    color: "#007aff"
  },
  IconCheck: {
    fontSize: 23,
    color: "#D8D8D8"
  },
  gridContainer: {
    marginBottom: 16
  },
  editEmployeesTitle: {
    textAlign: "left",
    fontFamily: "AvenirNextLTPro-Demi",
    fontSize: 32,
    letterSpacing: "0.03px",
    color: "#000000",
    opacity: 1,
    marginTop: 12
  },
  editEmployeesdescription: {
    textAlign: "left",
    fontFamily: "AvenirNextLTPro-Regular",
    fontSize: 17,
    letterSpacing: "0.02px",
    color: "#000000",
    opacity: 1
  },
  selectedAllIcon: {
    marginLeft: 21,
    marginTop: 16
  },
  editEmployeesBtn: {
    background: "#FD5739 0% 0% no-repeat padding-box",
    borderRadius: 5,
    opacity: 1,
    width: 200,
    height: 48,
    "& span": {
      textAlign: "center",
      fontFamily: "AvenirNextLTPro-Demi",
      fontSize: 18,
      letterSpacing: 0,
      color: "#ffffff",
      opacity: 1
    }
  },
  editEmployeesBtnPosition: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    maxWidth: 878,
    padding: "50px 0px"
  },
  editEmployee: {
    backgroundColor: "#fafafa",
    width: "calc(100% - 200px)"
  },
  editEmployeeForm: {
    backgroundColor: "#fafafa",
    height: "100%"
  },
  editEmployeeBox: {
    marginTop: 70
  },
  drawerPaper: {
    width: 200,
    ...theme.hiddenOverflow
  },
  content: {
    ...theme.content,
    flexGrow: 1,
    padding: "0 24px 80px 24px",
    height: "100vh",
    width: "calc(100% - 460px)"
  },
  employeeActive: {
    borderRadius: "10px 0 0 10px",
    margin: "5px 0 0 20px",
    "&:hover": {
      background: "#f5f5f5",
      "&.avatarActText": {
        color: "inherit"
      }
    }
  },
  avatarBadge: {
    position: "absolute",
    left: 0,
    "& span": {
      color: "#fff"
    }
  },
  avatarText: {
    ...theme.hiddenOverflow,
    color: "inherit",
    fontWeight: "600",
    marginLeft: 10,
    fontSize: "12px",
    overflowWrap: "break-word",
    textOverflow: "ellipsis",
    marginRight: 25,
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical"
  },
  paddingAll: {
    padding: "20px"
  },
  textField: {
    width: 171,
    height: "50px !important",
    backgroundColor: "#f5f5f5 !important",
    "& div": {
      height: "48px !important",
      "& div": {
        "& button": {
          "& span": {
            "& svg": {
              fontSize: "24px !important",
              "& path": {
                color: "red !important"
              }
            }
          }
        }
      }
    },
    "& label": {
      color: "#6D9FEE !important",
      textAlign: "left",
      fontFamily: "AvenirNextLTPro-Regular",
      // fontSize: "12px !important",
      letterSpacing: 0,
      opacity: 1
    }
  },
  textFieldEdit: {
    width: 171,
    height: "35px !important",
    backgroundColor: "#f5f5f5 !important",
    marginRight: 3,
    "& div": {
      "& input": {
        textAlign: "end",
        marginRight: 16,
        fontSize: 17,
        fontFamily: "AvenirNextLTPro-Regular",
        letterSpacing: "-0.01px",
        color: "#000000",
        opacity: 1
      },
      height: "33px !important",
      "& div": {
        "& p": {
          textAlign: "left",
          fontSize: 17,
          fontFamily: "AvenirNextLTPro-Regular",
          letterSpacing: "-0.01px",
          color: "#000000",
          opacity: 1,
          marginLeft: 17
        },
        "& button": {
          "& span": {
            "& svg": {
              fontSize: "24px !important",
              "& path": {
                color: "red !important"
              }
            }
          }
        }
      }
    },
    "& label": {
      color: "#6D9FEE !important",
      textAlign: "left",
      fontFamily: "AvenirNextLTPro-Regular",
      // fontSize: "12px !important",
      letterSpacing: 0,

      opacity: 1
    }
  },
  textFieldEditReadOnly: {
    width: 171,
    height: "35px !important",
    backgroundColor: "#fefefe !important",
    border: "1px solid #B6B6B6",
    marginRight: 3,
    "& div": {
      "& input": {
        textAlign: "end",
        marginRight: 16,
        fontSize: 17,
        fontFamily: "AvenirNextLTPro-Regular",
        letterSpacing: "-0.01px",
        color: "#000000",
        opacity: 1
      },
      height: "33px !important",
      "& div": {
        "& p": {
          textAlign: "left",
          fontSize: 17,
          fontFamily: "AvenirNextLTPro-Regular",
          letterSpacing: "-0.01px",
          color: "#000000",
          opacity: 1,
          marginLeft: 17
        },
        "& button": {
          "& span": {
            "& svg": {
              fontSize: "24px !important",
              "& path": {
                color: "red !important"
              }
            }
          }
        }
      }
    },
    "& label": {
      color: "#6D9FEE !important",
      textAlign: "left",
      fontFamily: "AvenirNextLTPro-Regular",
      // fontSize: "12px !important",
      letterSpacing: 0,

      opacity: 1
    }
  },
  keyboardDatePicker: {
    ...theme.keyboardDatePicker
  },
  keyboardDatePickerInput: {
    ...theme.keyboardDatePickerInput
  },
  editEmployeeLabel: {
    color: "#000000",
    textAlign: "left",
    fontFamily: "AvenirNextLTPro-Regular",
    fontSize: 17,
    letterSpacing: "0.02px",
    opacity: 1
  },
  editEmployeeLabelTitle: {
    color: "#000000",
    textAlign: "left",
    fontFamily: "AvenirNextLTPro-Regular",
    fontSize: 17,
    letterSpacing: "0.02px",
    opacity: 1,
    fontStyle: "italic",
    fontWeight: "bold",
    marginBottom: 16
  },
  orangeRectangularBtn: {
    ...theme.overrides.orangeRectangularBtn
  }
});

export default SettingStyle;
