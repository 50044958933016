import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { isMobile } from "react-device-detect";

import PropTypes from "prop-types";
import {
	Box, Button, TextField, Grid, FormControl, FormHelperText, Typography
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Formik } from "formik";
import clsx from "clsx";
import * as Yup from "yup";

import { LogoHeaderCommonComponent } from "../common";
import * as sessionActions from "../../actions/SessionActions";
import ForgotScreen from "../../assets/images/forgotScreen.png";
import { ExternalUserStyle } from "./sessionStyle";

class ResetPasswordComponent extends Component {
	validationSchema = Yup.object({
		password: Yup.string().required("Password is required"),
		confirmPassword: Yup.string()
			.oneOf([Yup.ref("password"), null], "Passwords must match")
			.required("Confirm password is required")
	});

	handleSubmitForgot = (values, actions) => {
		const { changePassword, history, employer } = this.props;
		actions.setSubmitting(false);
		changePassword(employer.id, employer.login.email, values.password, history);
	};

	// { values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, }
	renderForm = (formProps) => {
		const { classes, error, errorMessage } = this.props;

		return (
			<Grid className={classes.root} container justify="center" alignItems="flex-start">
				<LogoHeaderCommonComponent />
				<Grid
					container
					className={clsx(classes.rootContainer, {
						forgot: true
					})}
				>
					<Grid item xs={6} className={classes.leftColumn}>
						<Typography variant="h1" gutterBottom>
							{"Reset Password"}
						</Typography>
						<Typography variant="body1" gutterBottom>
							{"Set a new password for your account, must be 7 characters at least."}
						</Typography>
						<Box m={3} />
						<form className={classes.container}>
							<FormControl fullWidth margin="normal">
								<TextField
									fullWidth
									error={formProps.errors.password && formProps.touched.password}
									id="password"
									label="Password*"
									type="password"
									variant="filled"
									autoComplete="current-password"
									value={formProps.values.password}
									onChange={formProps.handleChange}
									onBlur={formProps.handleBlur}
									className={clsx(classes.textField, {
										error: formProps.errors.password && formProps.touched.password
									})}
								/>
							</FormControl>
							<FormControl fullWidth margin="normal">
								<TextField
									fullWidth
									error={formProps.errors.confirmPassword && formProps.touched.confirmPassword}
									id="confirmPassword"
									label="Repeat Password*"
									type="password"
									variant="filled"
									autoComplete="off"
									value={formProps.values.confirmPassword}
									onChange={formProps.handleChange}
									onBlur={formProps.handleBlur}
									className={clsx(classes.textField, {
										error: formProps.errors.confirmPassword && formProps.touched.confirmPassword
									})}
								/>
							</FormControl>
							<FormControl margin="normal">
								<Box m={1.0} />
								<Button variant="contained" color="primary" onClick={formProps.handleSubmit} type="submit">
									Update
								</Button>
								{error && <FormHelperText error>{errorMessage}</FormHelperText>}
							</FormControl>
						</form>
					</Grid>
					{!isMobile ? (
						<Grid item xs={6} className={classes.rigthColumn}>
							<img src={ForgotScreen} alt="" className={classes.rigthColumnImg} />
						</Grid>
					) : null}
				</Grid>
			</Grid>
		);
	};

	render() {
		const { classes } = this.props;
		return (
			<Grid container justify="center" className={classes.root}>
				<Formik initialValues={{ password: "", confirmPassword: "" }} validationSchema={this.validationSchema} onSubmit={this.handleSubmitForgot} render={this.renderForm} />
			</Grid>
		);
	}
}

ResetPasswordComponent.propTypes = {
	history: PropTypes.object,
	classes: PropTypes.object,
	error: PropTypes.bool,
	errorMessage: PropTypes.string,

	changePassword: PropTypes.func
};

const mapStateToProps = state => ({
	error: state.session.error,
	errorMessage: state.session.errorMessage,
	employer: state.session.userData
});

const mapDispatchToProps = dispatch => ({
	changePassword: bindActionCreators(sessionActions.userChangePassword, dispatch)
});

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(withStyles(ExternalUserStyle)(ResetPasswordComponent))
);
