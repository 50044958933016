import React, { Component } from "react";
import { connect } from "react-redux";
import clsx from "clsx";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  Paper,
  TextField,
  FormHelperText,
  InputAdornment
} from "@material-ui/core";
import { Formik, Form, getIn } from "formik";
import * as Yup from "yup";

import EmployeeStyles from "./employeeStyle";

import { CardCommonComponent, NumberFormatCustomComponent } from "../common";
import { employeeDefaultData } from "../../reducers/Employees/EmployeeInitialState";

class EmployeeSuperannuationComponent extends Component {
  validationSchema = Yup.object({
    superannuation: Yup.object().shape({
      superRate: Yup.number()
        .typeError("Invalid super guarantee rate format")
        .required("Super guarantee rate is required"),
      superFundName: Yup.string(),
      usi: Yup.string(),
      superFundAbn: Yup.string(),
      membershipReference: Yup.string(),
      salarySacrifice: Yup.string(),
      additional: Yup.string(),
      spouse: Yup.string()
    })
  });

  constructor(props) {
    super(props);
    this.state = { isComplete: false };
  }

  handleSubmitSaveSuperAnnuation = async (values, actions) => {
    const {
      saveEmployeeData,
      employerId,
      employeeId,
      parentFormProps
      //   handleFinishEmployeeClick
    } = this.props;
    // handleFinishEmployeeClick(parentFormProps)

    actions.setSubmitting(false);
    await saveEmployeeData(employerId, employeeId, {
      ...parentFormProps.values
    });
    this.setState(prevState => ({ ...prevState, isComplete: true }));
  };

  handleOnChange = (event, fromProps, parentFormProps) => {
    fromProps.setFieldValue(event.target.id, event.target.value);
    parentFormProps.setFieldValue(event.target.id, event.target.value);
  };

  checkIsComplete = formProps => {
    let finalResult = false;
    try {
      finalResult = this.validationSchema.isValidSync(formProps.values);
    } catch (err) {
      // console.error("Error validating super annuation as complete: ", err);
    }
    return finalResult;
  };

  renderForm = formProps => {
    const { classes, parentFormProps } = this.props;
    const { isComplete } = this.state;
    const isFormComplete = this.checkIsComplete(formProps);

    return (
      <CardCommonComponent
        title="Superannuation"
        complete={isFormComplete || isComplete}
        collapseExtend
      >
        <Form>
          <Grid container direction="row" alignItems="flex-start">
            <Grid item xs={4}>
              <Paper className={classes.paperGrid}>
                <TextField
                  id="superannuation.superRate"
                  label="Super guarantee rate *"
                  variant="filled"
                  type="number"
                  value={formProps.values.superannuation.superRate}
                  onChange={event =>
                    this.handleOnChange(event, formProps, parentFormProps)
                  }
                  onBlur={formProps.handleBlur}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    )
                  }}
                  className={clsx(classes.textField, {
                    error:
                      getIn(formProps.errors, "superannuation.superRate") &&
                      getIn(formProps.touched, "superannuation.superRate")
                  })}
                  fullWidth
                />
                {getIn(formProps.errors, "superannuation.superRate") &&
                  getIn(formProps.touched, "superannuation.superRate") && (
                    <FormHelperText error>
                      {formProps.errors.superannuation.superRate}
                    </FormHelperText>
                  )}
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper className={classes.paperGrid}>
                <TextField
                  id="superannuation.superFundName"
                  label="Super fund name"
                  variant="filled"
                  value={formProps.values.superannuation.superFundName}
                  onChange={event =>
                    this.handleOnChange(event, formProps, parentFormProps)
                  }
                  onBlur={formProps.handleBlur}
                  className={clsx(classes.textField, {
                    error:
                      getIn(formProps.errors, "superannuation.superFundName") &&
                      getIn(formProps.touched, "superannuation.superFundName")
                  })}
                  fullWidth
                />
                {getIn(formProps.errors, "superannuation.superFundName") &&
                  getIn(formProps.touched, "superannuation.superFundName") && (
                    <FormHelperText error>
                      {formProps.errors.superannuation.superFundName}
                    </FormHelperText>
                  )}
              </Paper>
            </Grid>
            <Grid item xs={4} />
            <Grid item xs={4}>
              <Paper className={classes.paperGrid}>
                <TextField
                  id="superannuation.usi"
                  label="Super fund USI"
                  variant="filled"
                  value={formProps.values.superannuation.usi}
                  onChange={event =>
                    this.handleOnChange(event, formProps, parentFormProps)
                  }
                  onBlur={formProps.handleBlur}
                  className={clsx(classes.textField, {
                    error:
                      getIn(formProps.errors, "superannuation.usi") &&
                      getIn(formProps.touched, "superannuation.usi")
                  })}
                  fullWidth
                />
                {getIn(formProps.errors, "superannuation.usi") &&
                  getIn(formProps.touched, "superannuation.usi") && (
                    <FormHelperText error>
                      {formProps.errors.superannuation.usi}
                    </FormHelperText>
                  )}
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper className={classes.paperGrid}>
                <TextField
                  id="superannuation.superFundAbn"
                  label="Super fund ABN"
                  variant="filled"
                  type="number"
                  value={formProps.values.superannuation.superFundAbn}
                  onChange={event =>
                    this.handleOnChange(event, formProps, parentFormProps)
                  }
                  onBlur={formProps.handleBlur}
                  className={clsx(classes.textField, {
                    error:
                      getIn(formProps.errors, "superannuation.superFundAbn") &&
                      getIn(formProps.touched, "superannuation.superFundAbn")
                  })}
                  fullWidth
                />
                {getIn(formProps.errors, "superannuation.superFundAbn") &&
                  getIn(formProps.touched, "superannuation.superFundAbn") && (
                    <FormHelperText error>
                      {formProps.errors.superannuation.superFundAbn}
                    </FormHelperText>
                  )}
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper className={classes.paperGrid}>
                <TextField
                  id="superannuation.membershipReference"
                  label="Membership reference"
                  variant="filled"
                  value={formProps.values.superannuation.membershipReference}
                  onChange={event =>
                    this.handleOnChange(event, formProps, parentFormProps)
                  }
                  onBlur={formProps.handleBlur}
                  className={clsx(classes.textField, {
                    error:
                      getIn(
                        formProps.errors,
                        "superannuation.membershipReference"
                      ) &&
                      getIn(
                        formProps.touched,
                        "superannuation.membershipReference"
                      )
                  })}
                  fullWidth
                />
                {getIn(
                  formProps.errors,
                  "superannuation.membershipReference"
                ) &&
                  getIn(
                    formProps.touched,
                    "superannuation.membershipReference"
                  ) && (
                    <FormHelperText error>
                      {formProps.errors.superannuation.membershipReference}
                    </FormHelperText>
                  )}
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper className={classes.paperGrid}>
                <TextField
                  id="superannuation.salarySacrifice"
                  label="Salary sacrifice amount"
                  helperText="(Per Payslip)"
                  variant="filled"
                  value={formProps.values.superannuation.salarySacrifice}
                  onChange={event =>
                    this.handleOnChange(event, formProps, parentFormProps)
                  }
                  onBlur={formProps.handleBlur}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                    inputComponent: NumberFormatCustomComponent,
                    inputProps: {
                      decimalScale: 2,
                      fixedDecimalScale: true
                    }
                  }}
                  className={clsx(classes.textField, {
                    error:
                      getIn(
                        formProps.errors,
                        "superannuation.salarySacrifice"
                      ) &&
                      getIn(formProps.touched, "superannuation.salarySacrifice")
                  })}
                  fullWidth
                />
                {getIn(formProps.errors, "superannuation.salarySacrifice") &&
                  getIn(
                    formProps.touched,
                    "superannuation.salarySacrifice"
                  ) && (
                    <FormHelperText error>
                      {formProps.errors.superannuation.salarySacrifice}
                    </FormHelperText>
                  )}
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper className={classes.paperGrid}>
                <TextField
                  id="superannuation.additional"
                  label="Employee additional amount"
                  helperText="(Per Payslip)"
                  variant="filled"
                  value={formProps.values.superannuation.additional}
                  onChange={event =>
                    this.handleOnChange(event, formProps, parentFormProps)
                  }
                  onBlur={formProps.handleBlur}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                    inputComponent: NumberFormatCustomComponent,
                    inputProps: {
                      decimalScale: 2,
                      fixedDecimalScale: true
                    }
                  }}
                  className={clsx(classes.textField, {
                    error:
                      getIn(formProps.errors, "superannuation.additional") &&
                      getIn(formProps.touched, "superannuation.additional")
                  })}
                  fullWidth
                />
                {getIn(formProps.errors, "superannuation.additional") &&
                  getIn(formProps.touched, "superannuation.additional") && (
                    <FormHelperText error>
                      {formProps.errors.superannuation.additional}
                    </FormHelperText>
                  )}
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper className={classes.paperGrid}>
                <TextField
                  id="superannuation.spouse"
                  label="Spouse contribution amount"
                  helperText="(Per Payslip)"
                  variant="filled"
                  value={formProps.values.superannuation.spouse}
                  onChange={event =>
                    this.handleOnChange(event, formProps, parentFormProps)
                  }
                  onBlur={formProps.handleBlur}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                    inputComponent: NumberFormatCustomComponent,
                    inputProps: {
                      decimalScale: 2,
                      fixedDecimalScale: true
                    }
                  }}
                  className={clsx(classes.textField, {
                    error:
                      getIn(formProps.errors, "superannuation.spouse") &&
                      getIn(formProps.touched, "superannuation.spouse")
                  })}
                  fullWidth
                />
                {getIn(formProps.errors, "superannuation.spouse") &&
                  getIn(formProps.touched, "superannuation.spouse") && (
                    <FormHelperText error>
                      {formProps.errors.superannuation.spouse}
                    </FormHelperText>
                  )}
              </Paper>
            </Grid>
            <Grid
              item
              xs={12}
              className={classes.gridRight}
              style={{ marginTop: "2.5em" }}
            >
              <Button
                variant="outlined"
                color="secondary"
                // onClick={parentFormProps.handleSubmit}
                onClick={formProps.handleSubmit}
                className={classes.buttonSaveDetails}
              >
                {"Save Super"}
              </Button>
            </Grid>
          </Grid>
        </Form>
      </CardCommonComponent>
    );
  };

  render() {
    const { employeeData } = this.props;
    const employeeDataValues = {
      ...employeeDefaultData,
      ...employeeData,
      detail: {
        ...employeeDefaultData.detail,
        ...employeeData.detail,
        imageUpload: {
          ...employeeDefaultData.detail.imageUpload,
          ...employeeData.detail.imageUpload
        },
        address: {
          ...employeeDefaultData.detail.address,
          ...employeeData.detail.address
        }
      },
      payroll: {
        ...employeeDefaultData.payroll,
        ...employeeData.payroll
      },
      entitlement: {
        ...employeeDefaultData.entitlement,
        ...employeeData.entitlement
      },
      taxe: {
        ...employeeDefaultData.taxe,
        ...employeeData.taxe
      },
      superannuation: {
        ...employeeDefaultData.superannuation,
        ...employeeData.superannuation
      }
    };

    return (
      <Formik
        initialValues={{ ...employeeDataValues }}
        validationSchema={this.validationSchema}
        onSubmit={this.handleSubmitSaveSuperAnnuation}
        render={this.renderForm}
        enableReinitialize="true"
      />
    );
  }
}

EmployeeSuperannuationComponent.propTypes = {
  employeeData: PropTypes.object.isRequired,
  parentFormProps: PropTypes.object.isRequired,

  saveEmployeeData: PropTypes.func
};

const mapStateToProps = (state, ownProps) => ({
  employeeData: ownProps.parentFormProps.values
});

export default connect(
  mapStateToProps,
  null
)(withStyles(EmployeeStyles)(EmployeeSuperannuationComponent));
