import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";
import { SnackbarCommonComponent, LoadingComponent } from "../components/common";
import { bindActionCreators } from "redux";
import { mapMessageCodeToIcon } from "../helpers/extensions";
import QuickSetupComponentAccountant from "../components/Session/QuickSetupComponentAccountant";
import { ExternaUserStyles } from "./PagesStyle";
import { cleanMessageOnboard } from "../actions/OnboardActions";

class QuickSetupAccountantPage extends Component {
	render() {
		const { classes, mixpanel, isLoading, messageCode, message, cleanMessageInternal } = this.props;
		console.log(" messageCode, message,", messageCode, message)
		return (
			<div className={classes.root}>
				<CssBaseline />
				<QuickSetupComponentAccountant mixpanel={mixpanel} />
				<LoadingComponent isLoading={isLoading} />
				{message && messageCode !== 200 ? <SnackbarCommonComponent key="SnackBarOnboardOne"
					variant="error" open={!!message} body={message}
					onCloseModal={cleanMessageInternal} />
					: messageCode !== 200 && <SnackbarCommonComponent key="SnackBarOnboardTwo"
						variant="error" open={!!message} body="Something went wrong. Please try again later"
						onCloseModal={cleanMessageInternal} />}
			</div>
		);
	}
}

QuickSetupAccountantPage.propTypes = {
	classes: PropTypes.object.isRequired,
	message: PropTypes.string,
	messageCode: PropTypes.number,
};

const mapStateToProps = state => ({
	isLoading: state.onboard.isLoading,
	messageCode: state.onboard.messageCode,
	message: state.onboard.message
});

const mapDispatchToProps = dispatch => ({
	cleanMessageInternal: bindActionCreators(cleanMessageOnboard, dispatch)
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(ExternaUserStyles)(QuickSetupAccountantPage));