import { createLogic } from "redux-logic";

import employeeApi from "../api/EmployeeApi";
import * as employeeActions from "../actions/EmployeeActions";
import * as sessionActions from "../actions/SessionActions";
import * as actionTypes from "../constants/actionTypes";
import * as apiResponses from "../constants/apiResponses";
import * as messages from "../constants/messages";
import { payrollProcessed } from "../reducers/Payroll/PayrollInitialState";
import { dispatchProcess } from "../store/customMiddleWare";
import { Analytics } from "../components/common";

const getAllEmployees = createLogic({
	type: actionTypes.ACTION_EMPLOYEES_REQUEST,
	latest: true,
	async process({ httpClient }, dispatch, done) {
		try {
			const employeeData = await employeeApi.getAllEmployees(httpClient);
			if (employeeData.messageCode === apiResponses.RESPONSE_OK) {
				employeeData.activeEmployeesList = employeeData.list
					.filter(employee => employee.active && !employee.temporal)
					.map(employee => ({
						...employee.detail,
						id: employee.id,
						jobTitle: !!employee.payroll && !!employee.payroll.jobTitle ? employee.payroll.jobTitle : null,
						temporal: employee.temporal,
						active: employee.active,
						selected: false,
						isProcessed: false,
						payroll: {
							...payrollProcessed
						}
					}))
					.sort((a, b) => (a.firstName > b.firstName ? 1 : -1));

				employeeData.inactiveEmployeesList = employeeData.list
					.filter(employee => !employee.active && !employee.temporal)
					.map(employee => ({
						...employee.detail,
						id: employee.id,
						jobTitle: !!employee.payroll && !!employee.payroll.jobTitle ? employee.payroll.jobTitle : null,
						temporal: employee.temporal,
						active: employee.active,
						selected: false,
						isProcessed: false,
						payroll: {
							...payrollProcessed
						}
					}))
					.sort((a, b) => (a.firstName > b.firstName ? 1 : -1));

				employeeData.unfinishedEmployeeList = employeeData.list
					.filter(employee => employee.temporal)
					.map(employee => ({
						...employee.detail,
						id: employee.id,
						jobTitle: !!employee.payroll && !!employee.payroll.jobTitle ? employee.payroll.jobTitle : null,
						temporal: employee.temporal,
						active: employee.active,
						selected: false,
						isProcessed: false,
						payroll: {
							...payrollProcessed
						}
					}))
					.sort((a, b) => (a.firstName > b.firstName ? 1 : -1));

				employeeData.reportsEmployeeList = employeeData.list
					.filter(employee => !employee.temporal)
					.map(employee => ({
						...employee.detail,
						id: employee.id,
						jobTitle: (employee.payroll || {}).jobTitle,
						temporal: employee.temporal,
						active: employee.active,
						selected: false,
						isProcessed: false,
						payroll: {
							...payrollProcessed
						}
					}))
					.sort((a, b) => (a.firstName > b.firstName ? 1 : -1));

				dispatch(employeeActions.employeesRequestSuccess(employeeData.list, employeeData.activeEmployeesList, employeeData.inactiveEmployeesList, employeeData.unfinishedEmployeeList, employeeData.reportsEmployeeList, employeeData.messageCode, employeeData.message));
			} else {
				console.log(employeeData.messageCode);
				if (employeeData.messageCode == 401) {
					dispatch(sessionActions.cleanSession());
				}

				dispatch(employeeActions.employeesRequestFailure(employeeData.messageCode, employeeData.message));
			}
		} catch (err) {
			console.error(err);
			if (err.response) {
				console.log(err.response.status);
				if (err.response.status == 401) {
					dispatch(sessionActions.cleanSession());
				}
			}

			dispatch(employeeActions.employeesRequestFailure("", err.message));
		}
		done();
	}
});

const saveEmployeeData = createLogic({
	type: actionTypes.ACTION_EMPLOYEE_SAVE_DATA_REQUEST,
	latest: true,
	async process({ httpClient, action }, dispatch, done) {
		try {
			let finalEmployeeId = action.payload.employeeId;
			if (!action.payload.employeeId) {
				const responseAdding = await employeeApi.addEmployee(httpClient, action.payload.employerId);
				if (responseAdding.messageCode === apiResponses.RESPONSE_OK) {

					finalEmployeeId = responseAdding.item.id;
				} else {
					throw new Error(responseAdding.message);
				}
			}
			const employeeData = await employeeApi.saveEmployeeDetails(httpClient, action.payload.employerId, finalEmployeeId, action.payload.selectedEmployeeData);
			if (employeeData.messageCode === apiResponses.RESPONSE_OK) {

				dispatch(employeeActions.saveEmployeeDataRequestSuccess(employeeData.item, employeeData.messageCode, employeeData.message));
			} else {
				dispatch(employeeActions.saveEmployeeDataRequestFailure(employeeData.messageCode, employeeData.message));
			}
		} catch (err) {
			console.error(err);
			dispatch(employeeActions.saveEmployeeDataRequestFailure("", err.message));
		}
		done();
	}
});

const getEmployeeData = createLogic({
	type: actionTypes.ACTION_EMPLOYEE_DATA_REQUEST,
	latest: true,
	async process({ httpClient, action }, dispatch, done) {
		try {
			const employeeResponse = await employeeApi.getEmployeeById(httpClient, action.payload.employeeId);
			if (employeeResponse.messageCode === apiResponses.RESPONSE_OK) {
				dispatch(employeeActions.getEmployeeDataRequestSuccess(employeeResponse.item, employeeResponse.messageCode, employeeResponse.message));
			} else {
				if (employeeResponse.messageCode == 401) {
					dispatch(sessionActions.cleanSession());
				}

				dispatch(employeeActions.getEmployeeDataRequestFailure(employeeResponse.messageCode, employeeResponse.message));
			}
		} catch (err) {
			console.error(err);
			dispatch(employeeActions.getEmployeeDataRequestFailure("", err.message));
		}
		done();
	}
});

const uploadImageLogic = createLogic({
	type: actionTypes.ACTION_EMPLOYEE_UPLOAD_IMAGE_REQUEST,
	latest: true,
	async process({ httpClient, getState, action }, dispatch, done) {
		try {
			const imageUploadResult = await employeeApi.uploadEmployeeImage(httpClient, action.payload.employeeId, action.payload.filename, action.payload.imageFile);
			console.log("UPLOAD IMAGE RESULT: ", imageUploadResult);
			if (imageUploadResult && imageUploadResult.messageCode === apiResponses.RESPONSE_OK) {
				const { selectedEmployeeData } = getState().employees;
				const finalEmployeeData = {
					...selectedEmployeeData,
					detail: {
						...selectedEmployeeData.detail,
						imageUrl: imageUploadResult.item.image.urlImage,
						imageUpload: {
							...imageUploadResult.item.image
						}
					}
				};
				await dispatch(employeeActions.uploadImageRequestSuccess(imageUploadResult.item, imageUploadResult.messageCode, messages.MESSAGE_AVATAR_UPDATED));
				await dispatch(employeeActions.saveEmployeeDataRequest(finalEmployeeData.employerId, finalEmployeeData.id, finalEmployeeData));
			} else {
				throw new Error(messages.MESSAGE_AVATAR_ERROR);
			}
		} catch (err) {
			console.error(err);
			await dispatch(employeeActions.uploadImageRequestFailure(apiResponses.RESPONSE_FAIL, err.message));
		}
		done();
	}
});

const activeOrInactiveEmployee = createLogic({
	type: actionTypes.ACTION_EMPLOYEE_ACTIVE_OR_INACTIVE,
	latest: false,
	async process({ httpClient, action }, dispatch, done) {
		try {
			const response = await employeeApi.activeOrInactiveEmployee(httpClient, action.payload.employeeData);
			if (response.messageCode === apiResponses.RESPONSE_OK) {
				const { active } = action.payload.employeeData[0];
				const finalMessage = active ? messages.MESSAGE_EMPLOYEE_ACTIVE : messages.MESSAGE_EMPLOYEE_INACTIVE;
				await dispatch(employeeActions.activeOrInactiveEmployeeSuccess(response.item, response.messageCode, finalMessage));
				await dispatch(employeeActions.employeesRequest());
			} else {
				dispatch(employeeActions.activeOrInactiveEmployeeFailure(response.messageCode, response.message));
			}
		} catch (err) {
			console.error(err);
			dispatch(employeeActions.activeOrInactiveEmployeeFailure("", err.message));
		}
		done();
	}
});

const finishEmployeeLogic = createLogic({
	type: actionTypes.ACTION_EMPLOYEE_FINISH_REQUEST,
	latest: true,
	async process({ action }, dispatch, done) {
		try {
			const {
				employerId, employeeId, employeeData, cleanEmployeeData, history, location
			} = action.payload;
			await dispatchProcess(
				employeeActions.saveEmployeeDataRequest(employerId, employeeId, employeeData),
				actionTypes.ACTION_EMPLOYEE_SAVE_DATA_REQUEST_SUCCESS,
				actionTypes.ACTION_EMPLOYEE_SAVE_DATA_REQUEST_FAILURE
			);
			await dispatch(employeeActions.addEmployeeWithFinish())
			Analytics.saveEmployeeAction();
			await dispatch(employeeActions.setCleanDataToStore(cleanEmployeeData));
			history.push(location);
		} catch (err) {
			console.error(err);
			// dispatch(employeeActions.saveEmployeeDataRequestFailure("", err.message));
		}
		done();
	}
});

export default [
	getAllEmployees,
	saveEmployeeData,
	getEmployeeData,
	uploadImageLogic,
	activeOrInactiveEmployee,
	finishEmployeeLogic
];
