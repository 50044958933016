import { createLogic } from "redux-logic";
import notificationApi from "../api/NotificationApi";
import * as notificationActions from "../actions/NotificationActions";
import * as actionTypes from "../constants/actionTypes";
import * as apiResponses from "../constants/apiResponses";
import * as messages from "../constants/messages";

const getNotification = createLogic({
	type: actionTypes.ACTION_NOTIFICATION_GET_REQUEST,
	latest: true,
	async process({ httpClient }, dispatch, done) {
		try {
			const response = await notificationApi.getNotification(httpClient);
			if (response.messageCode === apiResponses.RESPONSE_OK) {
				dispatch(notificationActions.getNotificationRequestSuccess(response.item, response.messageCode, response.message));
			} else {
				dispatch(notificationActions.getNotificationRequestFailure(response.messageCode, response.message));
			}
		} catch (err) {
			dispatch(notificationActions.getNotificationRequestFailure("", err.message));
		}
		done();
	}
});

const updateNotification = createLogic({
	type: actionTypes.ACTION_NOTIFICATION_UPDATE_REQUEST,
	latest: true,
	async process({ httpClient, action }, dispatch, done) {
		try {
			const response = await notificationApi.updateNotification(httpClient, action.payload.notificationData);
			if (response.messageCode === apiResponses.RESPONSE_OK) {
				dispatch(notificationActions.updateNotificationRequestSuccess(response.item, response.messageCode, messages.MESSAGE_NOTIFICATION_UPDATE));
			} else {
				dispatch(notificationActions.updateNotificationRequestFailure(response.messageCode, response.message));
			}
		} catch (err) {
			dispatch(notificationActions.updateNotificationRequestFailure("", err.message));
		}
		done();
	}
});

export default [getNotification, updateNotification];
