import * as routes from "./routes";

const menuHome = [
	{
		name: "Employees",
		icon: "iconEmployee",
		url: routes.PATH_EMPLOYEE
	},
	{
		name: "Payroll",
		icon: "iconPayroll",
		url: routes.PATH_PAYROLL
	},
	{
		name: "Single Touch Payroll",
		icon: "iconStp",
		url: routes.PATH_SINGLE_TOUCH_PAYROLL
	},
	{
		name: "Reports",
		icon: "iconReport",
		url: routes.PATH_REPORTS
	},
	{
		name: "Subscription",
		icon: "iconSubscription",
		url: routes.PATH_SUBSCRIPTION
	},
	{
		name: "Settings",
		icon: "iconSettings",
		url: routes.PATH_SETTINGS
	}
];
export const menuAccounts = [
	{
		name: "Accounts",
		icon: "iconEmployee",
		url: routes.PATH_ACCOUNTS
	},
	{
		name: "Settings",
		icon: "iconSettings",
		url: routes.PATH_SETTINGS
	}
];
export default menuHome;
