import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import { Box, Button, Grid, Paper, Typography } from "@material-ui/core";
import SettingStyle from "./settingStyle";

import Modal2FAConfigComponent from "./modal2FAConfigComponent";
import Formick2FAEmailComponent from "./formick2FAEmailComponent";
import * as routes from "../../constants/routes";
import * as employerActions from "../../actions/EmployerActions";
import * as common from "../../constants/common";
import localStorageApi from "../../api/LocalStorageApi";
import clsx from "clsx";

class twoFactorChoiceAuthenticationComponent extends Component {
  constructor() {
    super();

    this.state = {
      showPopup2FA: false,
      enable2FA: false,
      show2FA: false,
      showPopupConfirmPassword: false,
      isRevoke: false,
      isLoadingEmail: false,
      isLoadingText: false,
      phoneNumber: ""
    };
  }

  componentDidMount() {
    const { employer, accountant, history, isAccountant } = this.props;
    if (isAccountant) {
      if (accountant && accountant.login && accountant.login.enabled2FA) {
        history.push(routes.PATH_SETTINGS_2FA);
      }
      var enabled2FA =
        accountant && accountant.login && accountant.login.enabled2FA;

      this.setState(state => ({
        ...state,
        enable2FA:
          employer.accountType == "accountant"
            ? enabled2FA
              ? accountant.login.enabled2FA
              : employer && employer.login
              ? employer.login.enabled2FA
              : false
            : employer.login.enable2FA
      }));
    } else {
      if (employer.login.enable2FA) {
        history.push(routes.PATH_SETTINGS_2FA);
      }
      this.setState(state => ({
        ...state,
        enable2FA: employer.login.enable2FA
      }));
    }
  }

  componentWillReceiveProps = nextProps => {
    const {
      employer,
      history,
      accountant,
      isAccountant,
      isLoading
    } = nextProps;
    if (isAccountant) {
      const userData = localStorageApi.getItem(common.STORAGE_SESSION_NAME);
      if (userData.login.enabled2FA) {
        history.push(routes.PATH_SETTINGS_2FA);
      }
    } else {
      if (employer && employer.login && employer.login.enable2FA) {
        history.push(routes.PATH_SETTINGS_2FA);
      }
    }

    if (!isLoading) {
      this.setState({
        isLoadingEmail: false,
        isLoadingText: false
      });
    }
  };

  handleChangeSwitch = event => {
    this.setState({
      show2FA: event.target.checked
    });
  };

  handleShowPopup2FAClick = () => {
    const { cleanMessages, employer } = this.props;
    const { show2FA } = this.state;
    cleanMessages();
    this.setState({
      enable2FA: true,
      showPopup2FA: true
    });
  };

  handleEnabled2FA = () => {
    this.setState({ showPopup2FA: false });
  };

  handleCloseModal = () => {
    const {
      isValidValidate,
      history,
      accountant,
      employer,
      isAccountant
    } = this.props;
    if (isAccountant) {
      if (accountant.login.enabled2FA) {
        history.push(routes.PATH_SETTINGS_2FA);
      }
    } else {
      if (employer.login.enable2FA) {
        history.push(routes.PATH_SETTINGS_2FA);
      }
    }

    this.setState({ showPopup2FA: false, enable2FA: isValidValidate });
  };

  handleCloseModalConfirmPassword = () => {
    const { isValidDisabled, isValidRevoke, employer } = this.props;
    const { isRevoke } = this.state;

    this.setState({
      showPopupConfirmPassword: false,
      showPopup2FA: isRevoke,
      enable2FA:
        employer.accountType === "accountant"
          ? !isValidRevoke
          : !isValidDisabled
    });
  };
  handleEmail2FAClick = () => {
    const { employerEmailGenerate, history } = this.props;
    this.setState({
      isLoadingEmail: true
    });
    employerEmailGenerate(history);
  };

  handleText2FAClick = () => {
    const { employerTextGenerate, history } = this.props;
    this.setState({
      isLoadingText: true
    });

    employerTextGenerate(history);
  };

  render() {
    const { classes, employer, isLoading, history, isAccountant } = this.props;

    if (
      (employer.accountType === "employer" || employer.type === "Employer") &&
      this.state.phoneNumber === "" &&
      employer.contact.phoneNumber != ""
    ) {
      this.setState({
        phoneNumber: employer.contact.phoneNumber
      });
    } else if (
      employer.accountType === "accountant" &&
      this.state.phoneNumber === "" &&
      employer.details.phone != ""
    ) {
      this.setState({
        phoneNumber: employer.details.phone
      });
    }

    const { showPopup2FA, isLoadingText, isLoadingEmail } = this.state;

    return (
      <div className={classes.root}>
        <Paper className={classes.paperContainer}>
          <Typography variant="h1">Two-Factor Authentication</Typography>
          <Typography variant="body1">
            Two-Factor authentication is an extra layer of security for your
            Easy Payslip account designed to ensure that you&#39;re the only
            person who can access your account, even if someone knows your
            password.
          </Typography>
          <Box m={3} />
          <Typography variant="body1">
            {
              "By selecting an Authentication option below, you will receive a verification code that you will need to enter in order to access your account."
            }
          </Typography>

          <Box m={7} />
          <Grid container wrap="nowrap" spacing={2} alignItems="center">
            <Grid item xs={8}>
              <Paper className={classes.paper2fa}>
                <Grid container wrap="nowrap" spacing={2} alignItems="center">
                  {console.log("employer.login", employer)}
                  <Grid item xs>
                    {employer.accountType === "accountant" ? (
                      <Typography variant="body1">
                        {"Email:"} {employer.email || employer.login.email}
                      </Typography>
                    ) : (
                      <Typography variant="body1">
                        {"Email:"} {employer.login.email}
                      </Typography>
                    )}
                  </Grid>
                  <Grid>
                    <Button
                      className={clsx(
                        classes.btnRectangle,
                        classes.getCode,
                        classes.orangeRectangularBtn
                      )}
                      disabled={isLoadingEmail}
                      variant="contained"
                      onClick={this.handleEmail2FAClick}
                      color="secondary"
                    >
                      {"Get Code"}
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
              <Paper className={classes.paper2fa}>
                <Grid container wrap="nowrap" spacing={2} alignItems="center">
                  <Grid item xs>
                    {/* <Typography variant="body1">{"Text : "} {employer.contact ? employer.contact.phoneNumber : ""}</Typography> */}
                    <Typography variant="body1">
                      {"Text : "} {this.state.phoneNumber}
                    </Typography>
                  </Grid>
                  <Grid>
                    <Button
                      disabled={isLoadingText}
                      variant="contained"
                      onClick={this.handleText2FAClick}
                      color="secondary"
                      className={clsx(
                        classes.btnRectangle,
                        classes.orangeRectangularBtn
                      )}
                    >
                      {"Get Code"}
                    </Button>
                  </Grid>
                </Grid>
              </Paper>

              {!isAccountant ? (
                <Paper className={classes.paper2fa}>
                  <Grid container wrap="nowrap" spacing={2} alignItems="center">
                    <Grid item xs>
                      <Typography variant="body1">
                        {"Authentication App"}
                      </Typography>
                    </Grid>
                    <Grid>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={this.handleShowPopup2FAClick}
                        className={clsx(
                          classes.btnRectangle,
                          classes.orangeRectangularBtn
                        )}
                      >
                        {"Enter Code"}
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              ) : null}
            </Grid>
          </Grid>
          <Box m={2.5} />
          {!showPopup2FA && (
            <Paper className={classes.paper2fa}>
              <Formick2FAEmailComponent history={history} />
            </Paper>
          )}
        </Paper>
        {showPopup2FA ? (
          <Modal2FAConfigComponent
            onPrimaryButtonClick={this.handleEnabled2FA}
            open={showPopup2FA}
            onCloseModal={this.handleCloseModal}
          />
        ) : null}
      </div>
    );
  }
}

twoFactorChoiceAuthenticationComponent.propTypes = {
  isValidDisabled: PropTypes.bool,
  isValidRevoke: PropTypes.bool,
  isLoading: PropTypes.bool,
  isValidValidate: PropTypes.bool,

  isAccountant: PropTypes.bool
};

const mapStateToProps = state => {
  return {
    error: state.employer.error,
    isLoading: state.employer.isLoading,
    errorMessage: state.employer.errorMessage,
    employer: state.session.userData,
    accountant: state.accountantDetails.accountantDetails,
    isAccountant:
      !state.session.isEmployee && state.employersAccountant.isAccountant,

    isValidValidate: state.employer.isValidValidate,
    isValidDisabled: state.employer.isValidDisabled,
    isValidRevoke: state.employer.isValidRevoke
  };
};

const mapDispatchToProps = dispatch => ({
  employerEmailGenerate: bindActionCreators(
    employerActions.employerEmailGenerate,
    dispatch
  ),
  employerEmailValidate: bindActionCreators(
    employerActions.employerEmailValidate,
    dispatch
  ),
  employerTextGenerate: bindActionCreators(
    employerActions.employerTextGenerate,
    dispatch
  ),
  employerTextValidate: bindActionCreators(
    employerActions.employerTextValidate,
    dispatch
  ),
  cleanMessages: bindActionCreators(employerActions.cleanMessage, dispatch)
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(SettingStyle)(twoFactorChoiceAuthenticationComponent))
);
