import * as common from "../constants/common";
import * as actionTypes from "../constants/actionTypes";
import * as employerActions from "../actions/EmployerActions";
import localStorageApi from "../api/LocalStorageApi";

/* Email Generate by Sajjad */
export function employerEmailGenerate(history) {
	return {
		type: actionTypes.ACTION_EMPLOYER_EMAIL_GENERATE,
		payload: {
			messageCode: null,
			message: null,
			isLoading: true,
			history
		}
	};
}

export function employerEmailGenerateSuccess(messageCode, message) {
	return {
		type: actionTypes.ACTION_EMPLOYER_EMAIL_GENERATE_SUCCESS,
		payload: {
			messageCode,
			message
		}
	};
}

export function employerEmailGenerateFailure(message) {
	return {
		type: actionTypes.ACTION_EMPLOYER_EMAIL_GENERATE_FAILURE,
		error: true,
		payload: {
			messageCode: null,
			message: null,
			errorMessage: message
		}
	};
}
/* Email Validate by Sajjad */
export function employerEmailValidate(code2fa, history, accountType) {
	return {
		type: actionTypes.ACTION_EMPLOYER_EMAIL_VALIDATE,
		payload: {
			isLoading: true,
			code2fa,
			history,
			accountType
		}
	};
}



export function employerEmailValidateSuccess(isValidValidate, messageCode, message) {
	const userData = localStorageApi.getItem(common.STORAGE_SESSION_NAME);
	if (userData.accountType === "accountant") {
		userData.login.method2FA = isValidValidate;
		userData.login.method2FA = "email";
		// userData.login.enabled2FA = true;
		// TODO remove userData.login.device.isValid2FA
	} else {
		userData.login.enable2FA = isValidValidate;
		userData.login.method2FA = "email";
		userData.login.device.isValid2FA = isValidValidate;
	}
	localStorageApi.setItem(common.STORAGE_SESSION_NAME, userData);
	return {
		type: actionTypes.ACTION_EMPLOYER_EMAIL_VALIDATE_SUCCESS,
		payload: {

			messageCode,
			message
		}
	};
}

export function employerEmailValidateFailure(message) {
	return {
		type: actionTypes.ACTION_EMPLOYER_EMAIL_VALIDATE_FAILURE,
		error: true,
		payload: {
			errorMessage: message
		}
	};
}




//2FA Text
export function employerTextGenerate(history) {
	return {
		type: actionTypes.ACTION_EMPLOYER_TEXT_GENERATE,
		payload: {
			messageCode: null,
			message: null,
			isLoading: true,
			history
		}
	};
}

export function employerTextGenerateSuccess(messageCode, message) {
	return {
		type: actionTypes.ACTION_EMPLOYER_TEXT_GENERATE_SUCCESS,
		payload: {
			messageCode,
			message
		}
	};
}

export function employerTextGenerateFailure(message) {
	return {
		type: actionTypes.ACTION_EMPLOYER_TEXT_GENERATE_FAILURE,
		error: true,
		payload: {
			messageCode: null,
			message: null,
			errorMessage: message
		}
	};
}

// 2FA Text validation


export function employerTextValidate(code2fa, history) {
	return {
		type: actionTypes.ACTION_EMPLOYER_TEXT_VALIDATE,
		payload: {
			isLoading: true,
			code2fa,
			history
		}
	};
}



export function employerTextValidateSuccess(isValidValidate, messageCode, message) {
	const userData = localStorageApi.getItem(common.STORAGE_SESSION_NAME);
	userData.login.enable2FA = isValidValidate;
	userData.login.method2FA = "text";
	userData.login.device.isValid2FA = isValidValidate;
	localStorageApi.setItem(common.STORAGE_SESSION_NAME, userData);
	return {
		type: actionTypes.ACTION_EMPLOYER_TEXT_VALIDATE_SUCCESS,
		payload: {
			isValidValidate,
			messageCode,
			message
		}
	};
}

export function employerTextValidateFailure(message) {
	return {
		type: actionTypes.ACTION_EMPLOYER_TEXT_VALIDATE_FAILURE,
		error: true,
		payload: {
			errorMessage: message
		}
	};
}



/* TOPT Generate by John */
export function employerTOPTGenerate(history) {
	return {
		type: actionTypes.ACTION_EMPLOYER_TOPT_GENERATE,
		payload: {
			isLoading: true,
			history
		}
	};
}

export function employerTOPTGenerateSuccess(userData, manualSetupKey, qrCodeImage, messageCode, message) {
	return {
		type: actionTypes.ACTION_EMPLOYER_TOPT_GENERATE_SUCCESS,
		payload: {
			userData,
			manualSetupKey,
			qrCodeImage,
			messageCode,
			message
		}
	};
}

export function employerTOPTGenerateFailure(message) {
	return {
		type: actionTypes.ACTION_EMPLOYER_TOPT_GENERATE_FAILURE,
		error: true,
		payload: {
			errorMessage: message
		}
	};
}

/* TOPT Validate by John */
export function employerTOPTValidate(code2fa, history) {
	return {
		type: actionTypes.ACTION_EMPLOYER_TOPT_VALIDATE,
		payload: {
			isLoading: true,
			code2fa,
			history
		}
	};
}

export function employerTOPTValidateSuccess(isValidValidate, messageCode, message) {
	const userData = localStorageApi.getItem(common.STORAGE_SESSION_NAME);
	userData.login.enable2FA = isValidValidate;
	userData.login.method2FA = "authenticator";
	userData.login.device.isValid2FA = isValidValidate;
	localStorageApi.setItem(common.STORAGE_SESSION_NAME, userData);

	// dispatch(employerActions.getEmployer());

	return {
		type: actionTypes.ACTION_EMPLOYER_TOPT_VALIDATE_SUCCESS,
		payload: {
			isValidValidate,
			messageCode,
			message
		}
	};
}

export function employerTOPTValidateFailure(message) {
	return {
		type: actionTypes.ACTION_EMPLOYER_TOPT_VALIDATE_FAILURE,
		error: true,
		payload: {
			errorMessage: message
		}
	};
}

/* TOPT Revoke by John */
export function employerTOPTRevoke(password, history, accountType) {
	return {
		type: actionTypes.ACTION_EMPLOYER_TOPT_REVOKE,
		payload: {
			isLoading: true,
			password,
			history,
			accountType
		}
	};
}

export function employerTOPTRevokeSuccess(isValidRevoke, messageCode, message) {
	const userData = localStorageApi.getItem(common.STORAGE_SESSION_NAME);
	userData.accountType === "accountant" ? userData.login.enabled2FA = !isValidRevoke : userData.login.enable2FA = !isValidRevoke;
	localStorageApi.setItem(common.STORAGE_SESSION_NAME, userData);

	return {
		type: actionTypes.ACTION_EMPLOYER_TOPT_REVOKE_SUCCESS,
		payload: {
			isValidRevoke,
			messageCode,
			message
		}
	};
}

export function employerTOPTRevokeFailure(message) {
	return {
		type: actionTypes.ACTION_EMPLOYER_TOPT_REVOKE_FAILURE,
		error: true,
		payload: {
			errorMessage: message
		}
	};
}

/* TOPT Disabled by John */
export function employerTOPTDisabled(password, history) {
	return {
		type: actionTypes.ACTION_EMPLOYER_TOPT_DISABLED,
		payload: {
			isLoading: true,
			password,
			history
		}
	};
}

export function employerTOPTDisabledSuccess(isValidDisabled, messageCode, message) {

	const userData = localStorageApi.getItem(common.STORAGE_SESSION_NAME);
	userData.login.enable2FA = false;
	userData.login.device.isValid2FA = !isValidDisabled;
	localStorageApi.setItem(common.STORAGE_SESSION_NAME, userData);

	return {
		type: actionTypes.ACTION_EMPLOYER_TOPT_DISABLED_SUCCESS,
		payload: {
			userData,
			isValidDisabled,
			messageCode,
			message
		}
	};
}

export function employerTOPTDisabledFailure(message) {
	return {
		type: actionTypes.ACTION_EMPLOYER_TOPT_DISABLED_FAILURE,
		error: true,
		payload: {
			errorMessage: message
		}
	};
}

/* Get Employer */
export function getEmployer() {
	return {
		type: actionTypes.ACTION_EMPLOYER_REQUEST,
		payload: {
			isLoading: true
		}
	};
}

export function getEmployerSuccess(employerData, messageCode, message) {
	return {
		type: actionTypes.ACTION_EMPLOYER_REQUEST_SUCCESS,
		payload: {
			employerData,
			messageCode,
			message,

		}
	};
}

export function getEmployerFailure(messageCode, errorMessage) {
	return {
		type: actionTypes.ACTION_EMPLOYER_REQUEST_FAILURE,
		error: true,
		payload: {
			messageCode,
			errorMessage
		}
	};
}

export function getAuthorizedPerson() {
	return {
		type: actionTypes.ACTION_AUTHORIZED_PERSON_REQUEST,
		payload: {
			isLoading: true
		}
	};
}

export function getAuthorizedPersonSuccess(employerData, messageCode, message) {
	return {
		type: actionTypes.ACTION_AUTHORIZED_PERSON_REQUEST_SUCCESS,
		payload: {
			employerData,
			messageCode,
			message
		}
	};
}

export function getAuthorizedPersonFailure(messageCode, errorMessage) {
	return {
		type: actionTypes.ACTION_AUTHORIZED_PERSON_REQUEST_FAILURE,
		error: true,
		payload: {
			messageCode,
			errorMessage
		}
	};
}

/* Employer Update */
export function employerUpdate(employerData) {
	return {
		type: actionTypes.ACTION_EMPLOYER_UPDATE,
		payload: {
			isLoading: true,
			employerData
		}
	};
}

export function employerUpdateSuccess(employerData, messageCode, message) {
	return {
		type: actionTypes.ACTION_EMPLOYER_UPDATE_SUCCESS,
		payload: {
			employerData,
			messageCode,
			message
		}
	};
}

export function employerUpdateFailure(messageCode, errorMessage) {
	return {
		type: actionTypes.ACTION_EMPLOYER_UPDATE_FAILURE,
		error: true,
		payload: {
			messageCode,
			errorMessage
		}
	};
}


/*employer update business */

export function employerUpdateBusinessInfo(employerData) {
	return {
		type: actionTypes.ACTION_EMPLOYER_UPDATE_BUSINESS_INFO,
		payload: {
			isLoading: true,
			employerData
		}
	};
}

export function employerUpdateBusinessInfoSuccess(employerData, messageCode, message) {
	return {
		type: actionTypes.ACTION_EMPLOYER_UPDATE_BUSINESS_INFO_SUCCESS,
		payload: {
			employerData,
			messageCode,
			message
		}
	};
}

export function employerUpdateBusinessInfoFailure(messageCode, errorMessage) {
	return {
		type: actionTypes.ACTION_EMPLOYER_UPDATE_BUSINESS_INFO_FAILURE,
		error: true,
		payload: {
			messageCode,
			errorMessage
		}
	};
}

/* Employer Verify ABN */
export function verifyAbn(abn) {
	return {
		type: actionTypes.ACTION_EMPLOYER_VERIFY_ABN,
		payload: {
			isLoading: true,
			abn
		}
	};
}

export function verifyAbnSuccess(abnInfo, messageCode, message) {
	return {
		type: actionTypes.ACTION_EMPLOYER_VERIFY_ABN_SUCCESS,
		payload: {
			abnInfo,
			messageCode,
			message
		}
	};
}

export function verifyAbnFailure(messageCode, errorMessage) {
	return {
		type: actionTypes.ACTION_EMPLOYER_VERIFY_ABN_FAILURE,
		error: true,
		payload: {
			messageCode,
			errorMessage
		}
	};
}

export function clearAbnData() {
	return {
		type: actionTypes.ACTION_EMPLOYER_ABN_CLEAR_DATA,
		payload: {
			abnInfo: null,
			wpnInfo: null
		}
	};
}

/* Employer Verify WPN */
export function verifyWpn(wpn) {
	return {
		type: actionTypes.ACTION_EMPLOYER_VERIFY_WPN,
		payload: {
			isLoading: true,
			wpn
		}
	};
}

export function verifyWpnSuccess(wpnInfo, messageCode, message) {
	return {
		type: actionTypes.ACTION_EMPLOYER_VERIFY_WPN_SUCCESS,
		error: false,
		payload: {
			wpnInfo,
			messageCode,
			message
		}
	};
}

export function verifyWpnFailure(messageCode, errorMessage) {
	return {
		type: actionTypes.ACTION_EMPLOYER_VERIFY_WPN_FAILURE,
		error: true,
		payload: {
			messageCode,
			errorMessage
		}
	};
}

export function clearWpnData() {
	return {
		type: actionTypes.ACTION_EMPLOYER_WPN_CLEAR_DATA,
		payload: {
			abnInfo: null,
			wpnInfo: null
		}
	};
}

export function cleanMessage() {
	return {
		type: actionTypes.ACTION_EMPLOYER_CLEAN_MESSAGE,
		payload: {
			error: false,
			errorMessage: null,
			messageCode: null,
			message: null
		}
	};
}

/* Employer Close FY */
export function closeFYRequest(closeFY, history) {
	return {
		type: actionTypes.ACTION_EMPLOYER_CLOSE_FY_REQUEST,
		payload: {
			isLoading: true,
			closeFY,
			history
		}
	};
}

export function closeFYRequestSuccess(employerData, messageCode, message) {
	localStorageApi.setItem(common.STORAGE_SESSION_NAME, employerData);

	return {
		type: actionTypes.ACTION_EMPLOYER_CLOSE_FY_REQUEST_SUCCESS,
		payload: {
			employerData,
			messageCode,
			message
		}
	};
}

export function closeFYRequestFailure(messageCode, message) {
	return {
		type: actionTypes.ACTION_EMPLOYER_CLOSE_FY_REQUEST_FAILURE,
		error: true,
		payload: {
			messageCode,
			message
		}
	};
}

/* Employer Close FY */
export function generateLastPaymentsRequest(closeFY) {
	return {
		type: actionTypes.ACTION_EMPLOYER_GENERATE_LAST_PAYMENTS_REQUEST,
		payload: {
			isLoading: true,
			closeFY
		}
	};
}

export function generateLastPaymentsRequestSuccess(isGenerateLastPayments, messageCode, message) {
	return {
		type: actionTypes.ACTION_EMPLOYER_GENERATE_LAST_PAYMENTS_REQUEST_SUCCESS,
		payload: {
			isGenerateLastPayments,
			messageCode,
			message
		}
	};
}

export function generateLastPaymentsRequestFailure(messageCode, message) {
	return {
		type: actionTypes.ACTION_EMPLOYER_GENERATE_LAST_PAYMENTS_REQUEST_FAILURE,
		error: true,
		payload: {
			messageCode,
			message,
			isGenerateLastPayments: false
		}
	};
}

// redirect to your business
export function redirectToYourBusiness(show) {
	return {
		type: actionTypes.ACTION_REDIRECT_TO_YOUR_BUSINESS,
		payload: {
			redirectToYourBusiness: show
		}
	}
}


export function nextSlid(nextSlid) {
	return {
		type: actionTypes.ACTION_NEXT_SLID,
		payload: {
			nextSlid
		}
	}
}
export function tempRequestDataPayRoll(requestData) {
	return {
		type: actionTypes.ACTION_TEMP_REQUEST_DATA_PAYROLL,
		payload: {
			tempRequestDataPayRoll: requestData
		}
	}
}

export function redirectToPaySlip(data) {
	return {
		type: actionTypes.ACTION_TEMP_REQUEST_DATA_REDIRECT_TO_PAYSLIP,
		payload: {
			redirectToPaySlip: data
		}
	}
}

export function cleanData() {
	return {
		type: actionTypes.ACTION_EMPLOYER_CLEAN_DATA,
	}
}
