import React, { Component } from "react";
import PropTypes from "prop-types";

import { getIn } from "formik";

import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import {
	Grid, Typography, Paper, TextField, InputAdornment
} from "@material-ui/core";

import PayrollStyle from "./payrollStyle";
import { NumberFormatCustomComponent } from "../common";

class bonusComponent extends Component {
	handleChangeBase = (name, event, formProps) => {
		let bonus=formProps.values.payroll.bonus;

		if(Object.entries(bonus).length === 0 && bonus.constructor === Object){

			bonus={
				description: "",
				amount: ""
			}
		}
		switch (event.target.id) {
			case "bonusAmount":
				bonus.amount = event.target.value
				break;
			case "bonusDescription":
				bonus.description = event.target.value
				break;
			default:
				break;
		}


		formProps.setFieldValue("payroll.bonus", bonus);
	};

	render() {
		const { classes, formProps } = this.props;

		let bonus = formProps.values.payroll.bonus;

		return (
			<Grid className={classes.gridContainer}>
				<Typography variant="h4" gutterBottom>
					{"Bonus"}
				</Typography>

				<Grid container direction="row" alignItems="flex-start">
					<Grid item xs={4}>
						<Paper className={classes.paperGrid}>
							<TextField
								id={`bonusAmount`}
								label="Amount"
								variant="filled"
								InputProps={{
									startAdornment: <InputAdornment position="start">$</InputAdornment>,
									inputComponent: NumberFormatCustomComponent,
									inputProps: {
										decimalScale: 2,
										fixedDecimalScale: true
									}
								}}
								value={bonus ? bonus.amount : ""}
								onChange={(event) => {
									this.handleChangeBase(`bonusAmount`, event, formProps);
								}}
								onBlur={formProps.handleBlur}
								className={clsx(classes.textField, {
									error: getIn(formProps.errors, `bonusAmount`) && getIn(formProps.touched, `bonusAmount`)
								})}
								fullWidth
							/>
						</Paper>
					</Grid>

					<Grid item xs={4}>
						<Paper className={classes.paperGrid}>
							<TextField
								id={`bonusDescription`}
								label="Description"
								variant="filled"
								fullWidth
								value={ bonus ? bonus.description : ""}
								onChange={(value) => {
									this.handleChangeBase(`bonusDescription`, value, formProps);
								}}
								onBlur={formProps.handleBlur}
								className={clsx(classes.textField, {
									error: getIn(formProps.errors, `bonusDescription`) && getIn(formProps.touched, `bonusDescription`)
								})}
							/>
						</Paper>
					</Grid>
				</Grid>
			</Grid>
		);
	}
}

bonusComponent.propTypes = {
	formProps: PropTypes.object.isRequired
};

export default withStyles(PayrollStyle)(bonusComponent);
