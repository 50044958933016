/*eslint-disable */
import { isMobile, isIOS, isChrome, isFirefox } from "react-device-detect";

const launchiOSApp = url => {
	const appleAppStoreLink = "itms-apps://itunes.apple.com/app/easy-payslip-payroll-payslip/id1283832568";
	const now = new Date().valueOf();
	setTimeout(() => {
		if (new Date().valueOf() - now > 500) return;
		window.location = appleAppStoreLink;
	}, 100);
	window.location = url;
};

const launchIframeApproach = (url, alt) => {
	const iframe = document.createElement("iframe");
	iframe.style.border = "none";
	iframe.style.width = "1px";
	iframe.style.height = "1px";
	iframe.onload = function() {
		document.location = alt;
	};
	document.body.appendChild(iframe);
};

const launchWebkitApproach = (url, alt) => {
	document.location = url;
	setTimeout(() => {
		document.location = alt;
	}, 2500);
};

const launchAndroidApp = url => {
	const packageURL = "au.com.fusemobile.easypayslip";
	const androidAppStore = `http://market.android.com/details?id=${packageURL}`;
	const g_intent = `intent://open/#Intent;scheme=easypayslip;package=${packageURL};S.browser_fallback_url=${androidAppStore};end`; // see notes below

	if (isChrome) {
		window.location.href = g_intent;
	} else if (isFirefox) {
		launchWebkitApproach(url, androidAppStore);
		setTimeout(() => {
			launchIframeApproach(url, androidAppStore);
		}, 1500);
	} else {
		launchIframeApproach(url, androidAppStore);
	}
};

export const open = uri => {
	if (!isMobile) {
		return;
	}

	if (!isIOS) {
		launchAndroidApp(uri);
	} else {
		launchiOSApp(uri);
	}
};
