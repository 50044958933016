import { lighten } from "@material-ui/core/styles";

const cardStyles = theme => ({
	card: {
		borderRadius: 5,
		marginBottom: 20,
		border: "1px solid #E9E9E9",
		boxShadow: "none"
	},
	cardClose: {
		transition: theme.transitions.create("transform", {
			duration: theme.transitions.duration.shortest
		}),
		height: 66
	},
	iconComplete: {
		// color: "#F02F0F"
		color: "#C1D24F"

	},
	iconIncomplete: {
		color: "#BBBBBB"
		// color: "#F02F0F"
	},
	hideIcon: {
		display: "none"
	},
	hideIconTitle: {
		paddingLeft: 25
	},
	expand: {
		transform: "rotate(0deg)",
		marginLeft: "auto",
		transition: theme.transitions.create("transform", {
			duration: theme.transitions.duration.shortest
		})
	},
	expandOpen: {
		transform: "rotate(180deg)"
	},
	titleAvatar: {
		marginRight: 10
	},
	collapse: {
		padding: "0 50px 20px 50px",
		transitionDuration: "200ms!important",
		transitionTimingFunction: "linear"
	},
	collapseExtend: {
		padding: "0 50px 25px 50px",
		transitionDuration: "200ms!important",
		transitionTimingFunction: "linear"
	}
});

const avatarStyles = () => ({
	bigAvatar: {
		marginRight: 20,
		width: 100,
		height: 100,
		fontSize: 40
	},

	actAvatar: {
		border: "1px solid #007AFF",
		width: 48,
		height: 48
	},

	avatarParent: {
		position: "relative"
	},
	avatarOverlay: {
		visibility: "hidden",
		position: "absolute",
		top: 0,
		paddingTop: 36,
		borderRadius: "50%",
		textAlign: "center",
		backgroundColor: "rgba(42, 56, 69, 0.8)",
		width: 100,
		height: 100,
		cursor: "pointer"
	},
	avatarOverlayText: {
		color: "#FFF",
		fontSize: 10
	},
	avatarOverlayIcon: {
		color: "#FFF",
		fontSize: 16,
		width: "100%"
	},
	p: {
		color: "#7D848D"
	}
});

const twoFaInputStyles = theme => ({
	paperGrid: {
		...theme.paperClear,
		padding: 0,
		alignItems: "center",
		display: "inline-flex"
	},
	textOneCharacter: {
		width: 64,
		marginRight: 5,
		"& .MuiInputBase-formControl": {
			height: 72
		},
		"& .MuiFilledInput-input": {
			padding: "12px 12px 10px",
			textAlign: "center",
			fontSize: 16
		}
	},
	checkIcon: {
		color: "#BBBBBB",
		fontSize: 30,
		"&.active": {
			color: "#C1D24F"
		}
	},
	errorField: {
		...theme.errorField
	},
	errorRequest: {
		color: "#FD5739"
	},
	containerInputs: {
		alignItems: "center",
		display: "flex",
		justifyItems: "center"
	},
	iconInTop: {
		width: 100,
		height: 100,
		fontSize: 100,
		color: "#C1D24F",
		"&.error": {
			color: "#FD5739"
		}
	},
	containerControls: {
		display: "block"
	}
});

const logoHeaderStyles = () => ({
	root: {
		paddingTop: 40,
		paddingLeft: 40
	},
	imageLogo: {
		height: 50,
		width: 50
	}
});

const switchStyles = theme => ({
	root: {
		width: 42,
		height: 26,
		padding: 0,
		margin: theme.spacing(1)
	},
	switchBase: {
		padding: 1,
		"&$checked": {
			transform: "translateX(16px)",
			color: theme.palette.common.white,
			"& + $track": {
				backgroundColor: "#007AFF",
				opacity: 1,
				border: "none"
			}
		},
		"&$focusVisible $thumb": {
			color: "#007AFF",
			border: "6px solid #fff"
		}
	},
	thumb: {
		width: 24,
		height: 24
	},
	track: {
		borderRadius: 12,
		backgroundColor: "#D8D8D8",
		opacity: 1,
		transition: theme.transitions.create(["background-color", "border"])
	},
	checked: {},
	focusVisible: {},
	container: {
		border: "1px solid #D8D8D8",
		borderRadius: 5,
		cursor: "pointer"
	},
	containerLeft: {
		paddingLeft: "15px !important"
	},
	containerRight: {
		textAlign: "right"
	},
	input: {
		marginTop: "0px !important"
	}
});

const dialogTitleStyles = () => ({
	root: {
		margin: 0,
		padding: "22px 20px 14px 20px"
	},
	title: {
		top: 6
	},
	closeButton: {
		position: "absolute",
		right: 24,
		top: 13,
		color: "#000000"
	}
});

const dialogContentStyles = theme => ({
	root: {
		margin: 0,
		padding: "0 40px 0 40px",
		"&.divider": {
			padding: "16px 40px 10px 40px"
		},
		"&.background2FA": {
			background: "linear-gradient(to bottom, #F5F5F5 0%, #F5F5F5 100%) no-repeat",
			backgroundSize: "36%",
			backgroundPosition: "left"
		},
		"&.payslipDialog": {
			padding: 0,
			margin: 0,
			borderTop: "3px solid #fff",
			marginTop: "-2px"
		},
		"&.showScroll": {
			...theme.showScroll
		}
	}
});

const dialogActionsStyles = () => ({
	root: {
		margin: 0,
		padding: "20px 40px 0 0",
		"&.background2FA": {
			background: "linear-gradient(to bottom, #F5F5F5 0%, #F5F5F5 100%) no-repeat",
			backgroundSize: "36%",
			backgroundPosition: "left"
		}
	}
});

const dialogModalStyles = () => ({
	paper: {
		borderRadius: 10
	}
});

const snackBarStyles = () => ({
	success: {
		backgroundColor: "#FFF"
	},
	error: {
		backgroundColor: "#FFF"
	},
	icon: {
		fontSize: 20,
		color: "#000"
	},
	iconSuccess: {
		opacity: 0.9,
		color: "#C1D24F",
		marginRight: 10,
		"&.error": {
			color: "#FD5739"
		}
	},
	iconInfo: {
		opacity: 0.9,
		color: "#000",
		marginRight: 10
	},
	iconError: {
		opacity: 0.9,
		color: "#fd5739",
		marginRight: 10
	},
	message: {
		display: "flex",
		alignItems: "center",
		color: "#000",
		"&.error": {
			color: "#FD5739"
		}
	},
	btnPrimary:{
		borderRadius:'5px !important'
	}
});

const loadingStyles = () => ({
	root: {
		flexGrow: 1,
		padding: "0 !important",
		position: "absolute",
		top: "0",
		left: "0",
		width: "100%",
		zIndex: "9999"
	}
});

const customLoadingBarStyles = () => ({
	root: {
		backgroundColor: lighten("#FD5739", 0.5)
	},
	bar: {
		backgroundColor: "#FD5739"
	}
});

const menuItemStyles = () => ({
	buttonMenu: {
		padding: 1
	},
	listItemMenuPrimary: {
		color: "green"
	}
});

const subscriptionUpgradeStyles = theme => ({
	root: {
		...theme.root
	}
});

export {
	cardStyles as CardStyles,
	avatarStyles as AvatarStyles,
	twoFaInputStyles as TwoFaInputStyles,
	logoHeaderStyles as LogoHeaderStyles,
	switchStyles as SwitchStyles,
	dialogTitleStyles as DialogTitleStyles,
	dialogContentStyles as DialogContentStyles,
	dialogActionsStyles as DialogActionsStyles,
	dialogModalStyles as DialogModalStyles,
	snackBarStyles as SnackbarStyles,
	loadingStyles as LoadingStyes,
	customLoadingBarStyles as CustomLoadingBarStyes,
	menuItemStyles as MenuItemStyles,
	subscriptionUpgradeStyles as SubscriptionUpgradeStyles
};
