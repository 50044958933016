import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Formik } from "formik";
import * as Yup from "yup";

import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";

import {
	Grid, InputAdornment, Paper, TextField
} from "@material-ui/core";
import PayrollStyle from "./payrollStyle";

import * as parameterTypes from "../../constants/parameterTypes";

import { DialogCommonComponent, NumberFormatCustomComponent } from "../common";
import { payrollOvertimeDefaultData } from "../../reducers/Payroll/PayrollInitialState";

class ModalAddOvertime extends Component {
	validationSchema = Yup.object({
		overtime: Yup.string().required("Selected Overtime").test("name-default", "Selected Overtime", value => value !== "-"),
		overtimeManualRate: Yup.number().when("overtime", {
			is: "Manually Enter Rate Multiple",
			then: Yup.number().required("Manually  rate multiple is required")
		}),
		overtimeManualHourlyRate: Yup.number().when("overtime", {
			is: "Manually Enter Hourly Rate",
			then: Yup.number().required("Manually hourly rate is required")
		}),
		overtimeTotal: Yup.number().required("Total is required"),
		overtimeTotalAmount: Yup.number().required("Total Amount is required")
	});

	renderOvertimeOptions = () => {
		const { payrollOvertimeList, classes } = this.props;
		const values = [
			<option key="option_0" value="-" className={classes.option}>
				Select option
			</option>
		];

		values.push(payrollOvertimeList
			.sort((firstEl, secondEl) => firstEl.order < secondEl.order)
			.map(item => (
				<option key={item.id.toString()} value={item.value.toString()} className={classes.option}>
					{item.value}
				</option>
			)));

		return values;
	};

	handleSubmitOvertime = (value, actions) => {
		const { onAddValues } = this.props;
		actions.setSubmitting(false);
		onAddValues(value);
	};

	handleSelectOvertime = (event, formProps) => {
		const { value } = event.target;
		formProps.setFieldValue("overtime", value);

		let valueManual = "";

		switch (value) {
			case "1.5x":
				valueManual = "1.5";
				break;
			case "2.0x":
				valueManual = "2";
				break;
			default:
				valueManual = "";
				break;
		}

		formProps.setFieldValue("overtimeManualRate", valueManual);

		const { baseHourly } = this.props;
		let total=0;
		if(value==="overtimeManualHourlyRate"){
			total = parseFloat(parseFloat(valueManual === "" ? 0 : valueManual) * formProps.values.overtimeTotal * formProps.values.overtimeManualHourlyRate).toFixed(4);
		}else{
			  total = parseFloat(parseFloat(valueManual === "" ? 0 : valueManual) * baseHourly * formProps.values.overtimeTotal).toFixed(2);
		}
		
		formProps.setFieldValue("overtimeTotalAmount", isNaN(total) ? 0 : total);
	};

	handleChangeValues = (name, event, formProps, nameTotal, overtimeManualHourlyRate) => {
		const { value } = event.target;
		const valueFix = parseFloat(value).toFixed(2);
		const { baseHourly } = this.props;

		formProps.setFieldValue(name, value);
		if (name === "overtimeManualHourlyRate" ||  overtimeManualHourlyRate) {
			let total = parseFloat(valueFix  *  (name === "overtimeManualHourlyRate" ? formProps.values.overtimeTotal : formProps.values.overtimeManualHourlyRate));
			if (total !== 0) {
				total = total.toFixed(2);
			}
			formProps.setFieldValue(nameTotal, total);
		} else {
			let total = parseFloat(valueFix * baseHourly * (name === "overtimeManualRate" ? formProps.values.overtimeTotal : formProps.values.overtimeManualRate));
			if (total !== 0) {
				total = total.toFixed(2);
			}
			formProps.setFieldValue(nameTotal, total);
		}

	};

	renderForm = (formProps) => {
		const {
			classes, open, onCloseModal, edit
		} = this.props;

		return (
			<DialogCommonComponent title="Overtime" primaryButtonName={edit ? "Update" : "Save"} size="xs" onPrimaryButtonClick={formProps.handleSubmit} open={open} onCloseModal={onCloseModal}>
				<Grid container direction="row" alignItems="center">
					<Grid item xs={12}>
						<Paper className={classes.paperGrid}>
							<TextField
								id="overtime"
								label="Overtime type *"
								select
								variant="filled"
								fullWidth
								disabled={edit}
								value={formProps.values.overtime}
								onChange={(event) => {
									this.handleSelectOvertime(event, formProps);
								}}
								onBlur={formProps.handleBlur}
								className={clsx(classes.textField, {
									error: formProps.errors.overtime && formProps.touched.overtime
								})}
								SelectProps={{
									native: true,
									MenuProps: {
										className: classes.menu
									}
								}}
							>
								{this.renderOvertimeOptions()}
							</TextField>
						</Paper>
					</Grid>
					{formProps.values.overtime === "Manually Enter Rate Multiple" ? (
						<Grid item xs={12}>
							<Paper className={classes.paperGrid}>
								<TextField
									id="overtimeManualRate"
									label="Enter rate multiple *"
									variant="filled"
									fullWidth
									InputProps={{
										inputComponent: NumberFormatCustomComponent,
										inputProps: {
											decimalScale: 4
										}
									}}
									disabled={edit}
									value={formProps.values.overtimeManualRate}
									onChange={(event) => {
										this.handleChangeValues("overtimeManualRate", event, formProps, "overtimeTotalAmount");
									}}
									onBlur={formProps.handleBlur}
									className={clsx(classes.textField, {
										error: formProps.errors.overtimeManualRate && formProps.touched.overtimeManualRate
									})}
								/>
							</Paper>
						</Grid>
					) : null}
					{formProps.values.overtime === "Manually Enter Hourly Rate" ? (
						<Grid item xs={12}>
							<Paper className={classes.paperGrid}>
								<TextField
									id="overtimeManualHourlyRate"
									label="Enter hourly rate *"
									variant="filled"
									fullWidth
									InputProps={{
										startAdornment: <InputAdornment position="start">$</InputAdornment>,
										inputComponent: NumberFormatCustomComponent,
										inputProps: {
											decimalScale: 4
										}
									}}
									disabled={edit}
									value={formProps.values.overtimeManualHourlyRate}
									onChange={(event) => {
										this.handleChangeValues("overtimeManualHourlyRate", event, formProps, "overtimeTotalAmount");
									}}
									onBlur={formProps.handleBlur}
									className={clsx(classes.textField, {
										error: formProps.errors.overtimeManualHourlyRate && formProps.touched.overtimeManualHourlyRate
									})}
								/>
							</Paper>
						</Grid>
					) : null}
					<Grid item xs={6}>
						<Paper className={classes.paperGrid}>
							<TextField
								id="overtimeTotal"
								label="Hours *"
								variant="filled"
								fullWidth
								InputProps={{
									inputComponent: NumberFormatCustomComponent,
									inputProps: {
										decimalScale: 2
									}
								}}
								value={formProps.values.overtimeTotal}
								onChange={(event) => {
									this.handleChangeValues("overtimeTotal", event, formProps, "overtimeTotalAmount" , formProps.values.overtime === "Manually Enter Hourly Rate");
								}}
								onBlur={formProps.handleBlur}
								className={clsx(classes.textField, {
									error: formProps.errors.overtimeTotal && formProps.touched.overtimeTotal
								})}
							/>
						</Paper>
					</Grid>
					<Grid item xs={6}>
						<Paper className={classes.paperGrid}>
							<TextField
								id="overtimeTotalAmount"
								label="Total *"
								fullWidth
								InputProps={{
									startAdornment: <InputAdornment position="start">$</InputAdornment>,
									readOnly: true,
									inputComponent: NumberFormatCustomComponent,
									inputProps: {
										decimalScale: 4
									}
								}}
								value={formProps.values.overtimeTotalAmount}
								onChange={formProps.handleChange}
								onBlur={formProps.handleBlur}
								className={clsx("onlyReadTitle", {
									onlyRead: true,
									error: formProps.errors.overtimeTotalAmount && formProps.touched.overtimeTotalAmount
								})}
							/>
						</Paper>
					</Grid>
				</Grid>
			</DialogCommonComponent>
		);
	};

	render() {
		const { data } = this.props;

		const dataDefault = {
			...payrollOvertimeDefaultData,
			...data
		};

		return <Formik initialValues={{ ...dataDefault }} validationSchema={this.validationSchema} onSubmit={this.handleSubmitOvertime} render={this.renderForm} enableReinitialize />;
	}
}

ModalAddOvertime.propTypes = {
	payrollOvertimeList: PropTypes.array,
	baseHourly: PropTypes.number.isRequired,
	onAddValues: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
	// payrollOvertimeList: state.parameters.parameterByTypeData[parameterTypes.PARAMETER_OVERTIME_PAYROLL.parameterType]
	payrollOvertimeList: [
		{
			"id": "1079bdfa-8c63-4d23-b3fd-58e1c0868593",
			"section": "Process_Payroll",
			"typeSection": "Overtime",
			"value": "1.5x",
			"order": 0
		},
		{
			"id": "1079bdfa-8c63-4d23-b3fd-58e1c0868592",
			"section": "Process_Payroll",
			"typeSection": "Overtime",
			"value": "2.0x",
			"order": 1
		},
		{
			"id": "854b7120-bf7f-4fce-a83a-60d10392ccee",
			"section": "Process_Payroll",
			"typeSection": "Overtime",
			"value": "Manually Enter Rate Multiple",
			"order": 2
		},
		{
			"id": "80cca8b9-20ce-458f-bc5e-76caabdef6b5",
			"section": "Process_Payroll",
			"typeSection": "Overtime",
			"value": "Manually Enter Hourly Rate",
			"order": 3
		}
	]
});

export default connect(
	mapStateToProps,
	null
)(withStyles(PayrollStyle)(ModalAddOvertime));
