import * as actionTypes from "../constants/actionTypes";
import localStorageApi from "../api/LocalStorageApi";
import * as common from "../constants/common";

// Initialise An Onboarding Session
export function InitialiseAnOnboardingRequest(OnboardingData, history) {
	return {
		type: actionTypes.ACTION_SEND_ONBOARD,
		payload: {
			OnboardingData,
			history
		}
	};
}

export function InitialiseAnOnboardingRequestSuccess(sessionToken, messageCode, message, history) {

	localStorageApi.setItem(common.ONBOARD_PARAMETERS, { tokenOnboard: sessionToken });

	return {
		type: actionTypes.ACTION_SEND_ONBOARD_SUCCESS,
		payload: {
			sessionToken,
			messageCode,
			message,
			onboarding: true,
			history
		}
	};
}

export function InitialiseAnOnboardingRequestFailure(messageCode, message) {
	return {
		type: actionTypes.ACTION_SEND_ONBOARD_FAILURE,
		error: true,
		payload: {
			messageCode,
			message
		}
	};
}

// Update Data Fields During Onboarding
export function updateDataDuringOnboarding(businessInf, businessData, currentIndex, isQS_Employer, isQS_Accountant, history) {
	return {
		type: actionTypes.ACTION_ONBOARD_UPDATE_REQUEST,
		payload: {
			businessInf: {
				...businessInf
			},
			businessData: {
				...businessData
			},
			updateOnboardingSucsee: false,
			currentIndex: currentIndex,
			isQS_Employer: isQS_Employer,
			isQS_Accountant: isQS_Accountant,
			history
		}
	};
}

export function updateDataDuringOnboardingSuccess(accountType, messageCode, message, history) {
	return {
		type: actionTypes.ACTION_ONBOARD_UPDATE_REQUEST_SUCCESS,
		payload: {
			messageCode,
			message,
			updateOnboardingSucsee: true,
			history,
			accountType
		}
	};
}

export function updateDataDuringOnboardingFailure(messageCode, message) {
	return {
		type: actionTypes.ACTION_ONBOARD_UPDATE_REQUEST_FAILURE,
		error: true,
		payload: {
			messageCode,
			message,
			updateOnboardingSucsee: false
		}
	};
}

// Complete The Initial Onboarding and Create Account
export function finishOnboardingGetDataAccount() {

	return {
		type: actionTypes.ACTION_ONBOARD_GET_REQUEST,
		payload: {}
	};
}

export function finishOnboardingGetDataAccountSuccess(accountData, messageCode, message) {

	return {
		type: actionTypes.ACTION_ONBOARD_GET_REQUEST_SUCCESS,
		payload: {
			messageCode,
			message,
			accountData: {
				...accountData
			}
		}
	};
}

export function finishOnboardingGetDataAccountFailure(messageCode, message) {
	return {
		type: actionTypes.ACTION_ONBOARD_GET_REQUEST_FAILURE,
		error: true,
		payload: {
			messageCode,
			message,
		}
	};
}

// clean onboard
export function cleanMessageOnboard() {
	return {
		type: actionTypes.ACTION_ONBOARD_CLEAN_MESSAGE,
		payload: {
			OnboardingData: null,
			errorMessage: null,
			messageCode: null,
			message: null,
			onboarding: false,
			updateOnboardingSucsee: false
		}
	};
}
