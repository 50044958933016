import * as actionTypes from "../constants/actionTypes";

export function paymentsEmployerRequest() {
	return {
		type: actionTypes.ACTION_REPORTS_PAYMENT_EMPLOYER_REQUEST,
		payload: {
			isLoading: true
		}
	};
}

export function paymentsEmployerRequestSuccess(
	paymentsEmployer,
	messageCode,
	message
) {
	return {
		type: actionTypes.ACTION_REPORTS_PAYMENT_EMPLOYER_REQUEST_SUCCESS,
		payload: {
			paymentsEmployer,
			messageCode,
			message
		}
	};
}

export function paymentsEmployerRequestFailure(messageCode, errorMessage) {
	return {
		type: actionTypes.ACTION_REPORTS_PAYMENT_EMPLOYER_REQUEST_FAILURE,
		error: true,
		payload: {
			messageCode,
			errorMessage
		}
	};
}


export function paymentsEmployeesRequest() {
	return {
		type: actionTypes.ACTION_REPORTS_PAYMENT_EMPLOYEE_REQUEST,
		payload: {
			isLoading: true
		}
	};
}

export function paymentsEmployeesRequestSuccess(
	paymentsEmployees,
	messageCode,
	message
) {
	return {
		type: actionTypes.ACTION_REPORTS_PAYMENT_EMPLOYEE_REQUEST_SUCCESS,
		payload: {
			paymentsEmployees,
			messageCode,
			message
		}
	};
}

export function paymentsEmployeesRequestFailure(messageCode, errorMessage) {
	return {
		type: actionTypes.ACTION_REPORTS_PAYMENT_EMPLOYEE_REQUEST_FAILURE,
		error: true,
		payload: {
			messageCode,
			errorMessage
		}
	};
}


export function paymentSendEmailRequest(filterData) {
	return {
		type: actionTypes.ACTION_PAYMENT_SEND_EMAIL_REQUEST,
		payload: {
			isLoading: true,
			filterData
		}
	};
}

export function paymentSendEmailRequestSuccess(
	emailSent,
	messageCode,
	message
) {
	return {
		type: actionTypes.ACTION_PAYMENT_SEND_EMAIL_REQUEST_SUCCESS,
		payload: {
			emailSent,
			messageCode,
			message
		}
	};
}

export function paymentSendEmailRequestFailure(messageCode, errorMessage) {
	return {
		type: actionTypes.ACTION_PAYMENT_SEND_EMAIL_REQUEST_FAILURE,
		error: true,
		payload: {
			messageCode,
			errorMessage
		}
	};
}
