import * as actionTypes from "../../constants/actionTypes";
import subscriptionInitialState from "./SubscriptionInitialState";

const subscriptionReducer = (state = subscriptionInitialState, action) => {
	switch (action.type) {
		case actionTypes.ACTION_SUBSCRIPTION_ACTIVATE: {
			return {
				...state,
				isLoading: true,
				email: action.payload.email,
				error: false,
				errorMessage: "",
			};
		}
		case actionTypes.ACTION_SUBSCRIPTION_ACTIVATE_SUCCESS: {
			return {
				...state,
				isLoading: false,
				subscriptionData: action.payload.subscriptionData,
				messageCode: action.payload.messageCode,
				message: action.payload.message
			};
		}
		case actionTypes.ACTION_SUBSCRIPTION_ACTIVATE_FAILURE: {
			return {
				...state,
				isLoading: false,
				error: true,
				errorMessage: action.payload.errorMessage,
			};
		}
		case actionTypes.ACTION_SUBSCRIPTION_GET_STATUS: {
			return {
				...state,
				isLoading: true
			};
		}
		case actionTypes.ACTION_SUBSCRIPTION_GET_STATUS_SUCCESS: {
			return {
				...state,
				isLoading: false,
				statusSubscriptionData: action.payload.statusSubscriptionData,
				messageCode: action.payload.messageCode,
				message: action.payload.message
			};
		}
		case actionTypes.ACTION_SUBSCRIPTION_GET_STATUS_FAILURE: {
			return {
				...state,
				isLoading: false,
				error: true,
				errorMessage: action.payload.errorMessage
			};
		}
		case actionTypes.ACTION_SUBSCRIPTION_EMPLOYEE_EXTRA: {
			return {
				...state,
				isLoading: true,
				error: false,
				success: false,
				quantityEmployees: action.payload.quantityEmployees
			};
		}
		case actionTypes.ACTION_SUBSCRIPTION_EMPLOYEE_EXTRA_SUCCESS: {
			return {
				...state,
				isLoading: false,
				error: false,
				success: true,
				subscriptionData: action.payload.subscriptionData,
				messageCode: action.payload.messageCode,
				message: action.payload.message
			};
		}
		case actionTypes.ACTION_SUBSCRIPTION_EMPLOYEE_EXTRA_FAILURE: {
			return {
				...state,
				isLoading: false,
				error: true,
				success: false,
				errorMessage: action.payload.errorMessage
			};
		}
		case actionTypes.ACTION_SUBSCRIPTION_UPDATE_CREDIT_CARD: {
			return {
				...state,
				error: false,
				errorMessage: "",
				isLoading: true,
				token: action.payload.token
			};
		}
		case actionTypes.ACTION_SUBSCRIPTION_UPDATE_CREDIT_CARD_SUCCESS: {
			return {
				...state,
				isLoading: false,
				error: false,
				subscriptionData: action.payload.subscriptionData,
				messageCode: action.payload.messageCode,
				message: action.payload.message
			};
		}
		case actionTypes.ACTION_SUBSCRIPTION_UPDATE_CREDIT_CARD_FAILURE: {
			return {
				...state,
				isLoading: false,
				error: true,
				errorMessage: action.payload.errorMessage
			};
		}
		case actionTypes.ACTION_SUBSCRIPTION_CANCEL: {
			return {
				...state,
				isLoading: true
			};
		}
		case actionTypes.ACTION_SUBSCRIPTION_CANCEL_SUCCESS: {
			return {
				...state,
				isLoading: false,
				subscriptionData: action.payload.subscriptionData,
				messageCode: action.payload.messageCode,
				message: action.payload.message
			};
		}
		case actionTypes.ACTION_SUBSCRIPTION_CANCEL_FAILURE: {
			return {
				...state,
				isLoading: false,
				error: true,
				errorMessage: action.payload.errorMessage
			};
		}
		case actionTypes.ACTION_SESSION_LOGOUT: {
			return subscriptionInitialState;
		}
		default: {
			return {
				...state
			};
		}
	}
};

export default subscriptionReducer;
