import React, { Component } from "react";
import { Redirect } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import { Formik } from "formik";
import * as Yup from "yup";

import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Paper, Button, Typography, Box } from "@material-ui/core";

import * as routes from "../../constants/routes";
import * as payrollActions from "../../actions/PayrollActions";
import * as parameterActions from "../../actions/ParameterActions";
import * as stpActions from "../../actions/STPActions";
import * as singleTouchActions from "../../actions/SingleTouchActions";
import * as employerActions from "../../actions/EmployerActions";

import * as parameterTypes from "../../constants/parameterTypes";

import {
  AvatarCommonComponent,
  DialogCommonPDFComponent,
  SnackbarCommonComponent,
  Analytics
} from "../common";
import PayrollStyle from "./payrollStyle";
import AdditionalBaseHourlyRateComponent from "./additionalBaseHourlyRateComponent";
import QuantityComponent from "./quantityComponent";
import OvertimeComponent from "./overtimeComponent";
import DeductionsComponent from "./deductionsComponent";
import TaxableAllowancesComponent from "./taxableAllowancesComponent";
import UntaxedAllowancesComponent from "./untaxedAllowancesComponent";
import PaymentDetailsComponent from "./paymentDetailsComponent";
import BonusComponent from "./bonusComponent";
import CommissionComponent from "./commissionComponent";
import EntitlementsComponent from "./entitlementsComponent";
import ManualOverwriteComponent from "./manualOverwriteComponent";
import DrawerComponent from "./drawerComponent";
import ModalSTPSummaryComponent from "./modalSTPSummaryComponent";
import ModalCloseFYComponent from "./modalCloseFYComponent";
import { getDateToday, getUTCDate } from "../../helpers/dateextensions";
import { ThreeSixty } from "@material-ui/icons";
import DirectorsFeesComponent from "./directorsFeesComponent";

class PayrollProcessComponent extends Component {
	state = {
		showSummaryModal: false,
		isFinal: false,
		error: false,
		isValidCloseFY: false,
		showModalCLoseFYDone: false,
		employeeActive: {},
		isFinishAllEmployees: false,
		redirectToYourBusiness: false,
		showModalBeforGetResponse: false
	};

	validationSchema = Yup.object({
		payroll: Yup.object().shape({
			paymentTypeBaseHours: Yup.number().required("Base Hourly Rate is required"),
			paymentTypeHours: Yup.number().required("Hours is required"),
			paymentTypeTotal: Yup.number().required("Total is required"),
			payPeriodEnd: Yup.date().typeError("Invalid date"),
			payPeriodStart: Yup.date().typeError("Invalid date"),
			paymentDate: Yup.date().typeError("Invalid date"),
			annualLeaveHours: Yup.number().typeError("Invalid number"),
			annualLeaveHoursTotal: Yup.number().typeError("Invalid number"),
			longLeaveHours: Yup.number().typeError("Invalid number"),
			longLeaveHoursTotal: Yup.number().typeError("Invalid number"),
			personalLeaveHours: Yup.number().typeError("Invalid number"),
			personalLeaveHoursTotal: Yup.number().typeError("Invalid number"),
			sickLeaveHours: Yup.number().typeError("Invalid number"),
			sickLeaveHoursTotal: Yup.number().typeError("Invalid number"),
			isOverwrite: Yup.bool().notRequired(),
			overwrite_PAYG: Yup.string().when("isOverwrite", { is: true, then: Yup.number(), otherwise: Yup.string().notRequired() }),
			overwrite_Super: Yup.string().when("isOverwrite", { is: true, then: Yup.number(), otherwise: Yup.string().notRequired() })
		})
	});

	componentDidMount() {
		const {
			selectedPayrollData, history, loadParamters, employer, mixpanel
		} = this.props;
		Analytics.PR_2ReviewPayrollScreen();
		// mixpanel.track("ProcessPayroll.Screen");

		if (!selectedPayrollData || !selectedPayrollData.employees || selectedPayrollData.employees.length <= 0) {
			history.push(routes.PATH_PAYROLL);
		} else {
			loadParamters(parameterTypes.PAYROLL_BASE_RATE_ADDITIONAL.parameterType, parameterTypes.PAYROLL_BASE_RATE_ADDITIONAL.section, parameterTypes.PAYROLL_BASE_RATE_ADDITIONAL.typeSection);
			loadParamters(parameterTypes.PARAMETER_QUANTITY_PAYROLL.parameterType, parameterTypes.PARAMETER_QUANTITY_PAYROLL.section, parameterTypes.PARAMETER_QUANTITY_PAYROLL.typeSection);
			loadParamters(parameterTypes.PARAMETER_OVERTIME_PAYROLL.parameterType, parameterTypes.PARAMETER_OVERTIME_PAYROLL.section, parameterTypes.PARAMETER_OVERTIME_PAYROLL.typeSection);
			loadParamters(parameterTypes.PARAMETER_ALLOWANCE_PAYROLL.parameterType, parameterTypes.PARAMETER_ALLOWANCE_PAYROLL.section, parameterTypes.PARAMETER_ALLOWANCE_PAYROLL.typeSection);

			const now = getDateToday();
			const fyYearTo = getUTCDate(employer.financialYear.dateTo);

			this.setState({
				isValidCloseFY: employer.financialYear.year === now.toDate().getFullYear() || now >= fyYearTo
			});
		}

	}

	componentWillReceiveProps = (nextProps) => {
		const { stpSummary, isFinishAllEmployees, payrollData, employer } = nextProps;
		const { redirectToPaySlip2, redirectToPaySlip, tempRequestDataPayRoll2, addPayroll } = this.props
		if (stpSummary && stpSummary.urlResume && stpSummary.urlResume.length > 1) {
			this.setState(() => ({
				showSummaryModal: true
			}));
		}
		if (isFinishAllEmployees) {
			this.setState({
				isFinishAllEmployees: true
			})
		}

		if (redirectToPaySlip2 && !this.state.showModalBeforGetResponse) {
			// if (redirectToPaySlip2) {
			redirectToPaySlip(false)
			this.setState({
				showModalBeforGetResponse: true
			})
			addPayroll(tempRequestDataPayRoll2)
		}

		if (employer && employer.status < 1 && payrollData && payrollData.urlResume && payrollData.urlResume.length > 0) {
			this.setState({
				showModalBeforGetResponse: false
			})
			Analytics.OB_7ReviewDraftPayslipScreen()
		} else if (employer && employer.status >= 1 && payrollData && payrollData.urlResume && payrollData.urlResume.length > 0) {
			Analytics.PR_3ReviewPayslipScreen()
			this.setState({
				showModalBeforGetResponse: false
			})
		}
	};

	handleCloseSummary = () => {
		this.setState(() => ({
			showSummaryModal: false
		}));
	};

	handleSendPayroll = () => {
		const { sendPayrollNextData, payrollData } = this.props;
		sendPayrollNextData(payrollData);

	};
	handelCompleteBusinessInformation = () => {
		const { redirectToYourBusiness, deletedPayroll, payrollData } = this.props
		redirectToYourBusiness(true);
		Analytics.OB_7CompleteBusinessInfoAction();
		deletedPayroll(payrollData.id, false);
		this.setState({
			redirectToYourBusiness: true,
			showModalCLoseFYDone: false
		})

	}
	handleDiscardPayroll = () => {
		const { deletedPayroll, payrollData } = this.props;
		deletedPayroll(payrollData.id, true);
	};

	handelSubmitAfterBusinessInfo = () => {
		const { addPayroll, tempRequest } = this.props

		addPayroll(tempRequest);
	}

	handleSubmitProcess = (values, actions) => {
		const { addPayroll, tempRequestDataPayRoll } = this.props;
		const valuesProcess = {
			...values.payroll,
			paymentType: values.paymentType,
      bonus: values.payroll.bonus && values.payroll.bonus.amount ? values.payroll.bonus : null,
      directorsFees: values.payroll.directorsFees && values.payroll.directorsFees.amount ? values.payroll.directorsFees : null,
			commissions: values.payroll.commissions &&
				(values.payroll.commissions[0] && values.payroll.commissions[0].amount
					|| values.payroll.commissions[1] && values.payroll.commissions[1].amount) ? values.payroll.commissions.filter(item => item.amount) : null,
			overwrite_PAYG: values.payroll.isOverwrite ? values.payroll.overwrite_PAYG : null,
			overwrite_Super: values.payroll.isOverwrite ? values.payroll.overwrite_Super : null
		};
		actions.setSubmitting(false);
		const find = this.props.listPayrollDataisFinal.find(i => i.employeeId === valuesProcess.employeeId && i.isFinal);

		if (find) {
			valuesProcess.paymentTypeTotal = 0;
			valuesProcess.paymentTypeHours = "";
			valuesProcess.quantityDesc = "";
			valuesProcess.quantityCount = null;
			valuesProcess.quantityRate = null;
			valuesProcess.bonus = null;
			valuesProcess.commissions = null;
			valuesProcess.additionals = [];
			valuesProcess.allowances = [];
			valuesProcess.overtimes = [];
			valuesProcess.quantities = [];
			valuesProcess.showAllowance = false;
			valuesProcess.showBonus = false;
			valuesProcess.showCommission = false;
			valuesProcess.isFinal = true;
		}

		if (this.props.employer.status < 1) {
			tempRequestDataPayRoll(valuesProcess)
		}
		addPayroll(valuesProcess);
	};

	handleGenerateReportSummary = () => {
		const { generateReportSummary, selectedPayrollData } = this.props;
		const { employees } = selectedPayrollData;
		const payrolls = [];

		employees.forEach((employee) => {
			payrolls.push(employee.payroll.id);
		});

		const reportSummary = {
			paymentDate: selectedPayrollData.paymentDate,
			payPeriodStart: selectedPayrollData.startDate,
			payPeriodEnd: selectedPayrollData.finishDate,
			payrolls: [...payrolls]
		};

		generateReportSummary(reportSummary);
	};

	handleMessage = () => {
		this.setState({ error: true });
	}

	handleMessageClose = () => {
		this.setState({ error: false });
	}

	handleCloseFYDoneOpen = () => {
		console.log('all')
		this.setState({
			showModalCLoseFYDone: true
		});
	};

	handleCloseFYDoneClose = () => {
		this.setState({
			showModalCLoseFYDone: false
		});
	};
	handleChoiceEmployee = (employee) => {
		const { savePayrollData, history, selectedPayrollData } = this.props;
		if (selectedPayrollData) {
			let { employeeActive, payroll, ...values } = selectedPayrollData;
			let p = payroll;
			p.employeeId = employee.id;
			const payrollData = {
				...values,
				payroll: p,
				employeeActive: {
					detail: {
						...employee
					}
				}
			};

			savePayrollData(payrollData, history, routes.PATH_PAYROLL_PROCESS);
		}
	};

	renderForm = (formProps) => {

		const {
			classes, payrollData, isFinishAllEmployees, stpSummary, listPayrollDataisFinal, redirectToSTP, employer, redirectToPaySlip2
		} = this.props;

		const {
			showSummaryModal, error, isValidCloseFY, showModalCLoseFYDone, redirectToYourBusiness, showModalBeforGetResponse
		} = this.state;

		if (this.state.isFinishAllEmployees && redirectToSTP(true)) { return (<Redirect to={routes.PATH_SINGLE_TOUCH_PAYROLL} />) }
		if (redirectToYourBusiness) {

			return (
				<Redirect to={routes.PATH_EMPLOYER_REGISTER} />
			)
		}
		let isFinal = false;
		if (formProps.values.employeeActive == null) {
			return null;
		}

		if (listPayrollDataisFinal) {
			const find = listPayrollDataisFinal.find(i => i.employeeId === formProps.values.employeeActive.id && i.isFinal);
			if (find) {
				isFinal = true;
				if (this.state.isFinal) {
					this.setState({
						isFinal: isFinal
					})
				}
			}
		}
		return (
			<form>

				<div className={classes.root}>
					<Paper className={classes.paperContainerProcess}>
						<Typography variant="h1" className={classes.payrollTitle}>Process Payroll</Typography>
						<Typography variant="body1" className={classes.payrollSubTitle}>Confirm/update payroll information for your employee, and click &apos;Preview Payslip&apos; to proceed</Typography>
						{/* <Grid item xs={12} className={clsx(classes.gridRight, classes.margen)}>
							<Button variant="contained" color="primary" onClick={!isValidCloseFY ? this.handleGenerateReportSummary : this.handleCloseFYDoneOpen} disabled={!isFinishAllEmployees}>
								{"Proceed to STP"}
							</Button>
							{showSummaryModal ? <ModalSTPSummaryComponent open={showSummaryModal} summary={stpSummary} onCloseModal={this.handleCloseSummary} /> : null}
						</Grid> */}
            <Grid item xs={12} className={classes.reviewPayrollBox}>
              <Paper className={classes.paddingAll}>
                <Grid container wrap="nowrap" spacing={2} alignItems="center">
                  <Grid item>
                    <AvatarCommonComponent
                      src={formProps.values.employeeActive.detail.imageUrl}
                      text={formProps.values.employeeActive.detail.initials}
                      isBig
                    />
                  </Grid>
                  <Grid item xs>
                    <Typography variant="h4" className={classes.subtitleHeader}>
                      {formProps.values.employeeActive.detail.name}
                    </Typography>
                    <Typography variant="body1" className="gray">
                      {formProps.values.employeeActive.detail.jobTitle
                        ? formProps.values.employeeActive.detail.jobTitle
                        : null}
                      {formProps.values.employeeActive.payroll.jobTitle
                        ? formProps.values.employeeActive.payroll.jobTitle
                        : null}
                    </Typography>
                  </Grid>
                </Grid>
                {!isFinal ? (
                  <React.Fragment>
                    {/* <PaymentDetailsComponent formProps={formProps} {...this.props} />


										<QuantityComponent formProps={formProps} {...this.props} /> */}

                    {formProps.values.employeeActive.payroll.payType !==
                    "Per Job / Unit" ? (
                      <PaymentDetailsComponent
                        formProps={formProps}
                        {...this.props}
                      />
                    ) : null}

                    {formProps.values.employeeActive.payroll.payType ===
                    "Per Job / Unit" ? (
                      <QuantityComponent
                        formProps={formProps}
                        {...this.props}
                      />
                    ) : null}

                    <AdditionalBaseHourlyRateComponent
                      formProps={formProps}
                      {...this.props}
                    />

                    {formProps.values.employeeActive.payroll.payType !==
                    "Per Job / Unit" ? (
                      <QuantityComponent
                        formProps={formProps}
                        {...this.props}
                      />
                    ) : null}

                    {formProps.values.payroll.showOvertime ? (
                      <OvertimeComponent
                        formProps={formProps}
                        {...this.props}
                      />
                    ) : null}

                    {formProps.values.payroll.showAllowance ? (
                      <TaxableAllowancesComponent
                        formProps={formProps}
                        {...this.props}
                      />
                    ) : null}

                    {formProps.values.payroll.showAllowance ? (
                      <UntaxedAllowancesComponent
                        formProps={formProps}
                        {...this.props}
                      />
                    ) : null}

                    {formProps.values.payroll.showDeductions ? (
                      <DeductionsComponent
                        formProps={formProps}
                        {...this.props}
                      />
                    ) : null}

{                    formProps.values.payroll.showDirectorsFees ? (
                      <DirectorsFeesComponent formProps={formProps} {...this.props} />
                    ) : null}

                    {formProps.values.payroll.showBonus ? (
                      <BonusComponent formProps={formProps} {...this.props} />
                    ) : null}

                    {formProps.values.payroll.showCommission ? (
                      <CommissionComponent
                        formProps={formProps}
                        {...this.props}
                      />
                    ) : null}
                  </React.Fragment>
                ) : (
                  <Box m={2.5} />
                )}

                {formProps.values.payroll.showEntitlements ? (
                  <EntitlementsComponent
                    formProps={formProps}
                    {...this.props}
                  />
                ) : null}

                <ManualOverwriteComponent
                  formProps={formProps}
                  {...this.props}
                />

                <Grid item xs={12} className={classes.gridRight}>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={clsx(
                      classes.btnProcessPayrol1,
                      classes.orangeRectangularBtn
                    )}
                    onClick={
                      isFinishAllEmployees
                        ? this.handleMessage
                        : formProps.handleSubmit
                    }
                  >
                    {"Preview Payslip"}
                  </Button>
                </Grid>
              </Paper>
            </Grid>

            {/* <Grid item xs={12} className={clsx(classes.gridRight, classes.margen)}>
							{this.state.isFinishAllEmployees && redirectToSTP(true) && <Redirect to={routes.PATH_SINGLE_TOUCH_PAYROLL} />}

							<Button variant="contained" color="primary" onClick={!isValidCloseFY ? this.handleGenerateReportSummary : this.handleCloseFYDoneOpen} disabled={!isFinishAllEmployees}>
								{"Proceed to STP"}
							</Button>
							{showSummaryModal ? <ModalSTPSummaryComponent open={showSummaryModal} summary={stpSummary} onCloseModal={this.handleCloseSummary} /> : null}
						</Grid> */}
            <DrawerComponent
              handleChoiceEmployee={this.handleChoiceEmployee}
              {...this.props}
            />
            {employer &&
            employer.status < 1 &&
            payrollData &&
            payrollData.urlResume &&
            payrollData.urlResume.length > 0 ? (
              <DialogCommonPDFComponent
                // title={`Payslip for ${formProps.values.employeeActive.detail.name}`}
                title={`Well done!`}
                subtitle={`Here is your payslip `}
                subtitle2={`To send an actual payslip and go on to STP, there’s a bit more info we need to keep the ATO happy.`}
                open={
                  payrollData &&
                  payrollData.urlResume &&
                  payrollData.urlResume.length > 0
                }
                onCloseModal={this.handleDiscardPayroll}
                url={payrollData.urlResume}
                primaryButtonName="Complete Business Information"
                onPrimaryButtonClick={this.handelCompleteBusinessInformation}
                // secondaryButtonName="Discard"
                // onSecondaryButtonClick={this.handleDiscardPayroll}
                showScroll
                style={{ borderRadius: 5, height: 40, minWidth: 200 }}
                style2={{
                  fontFamily: "AvenirNextLTPro-Demi",
                  fontSize: "15px",
                  fontWeight: "bold"
                }}
                styleTitle={{
                  textAlign: "left",
                  fontFamily: "AvenirNextLTPro-Demi",
                  letterSpacing: -0.67,
                  color: "#000000",
                  opacity: 1,
                  fontSize: 42
                }}
                styleSubtitle={{
                  textAlign: "left",
                  letterSpacing: -0.37,
                  color: "#000000",
                  opacity: 1,
                  fontSize: 23,
                  fontWeight: "bolder"
                }}
                styleSubtitle2={{
                  textAlign: "left",
                  letterSpacing: -0.37,
                  color: "#000000",
                  opacity: 1,
                  fontSize: 16
                }}
                btnBack={true}
                styleBtnBack={{
                  color: "#004FDC",
                  fontFamily: "AvenirNextLTPro-Regular",
                  position: "absolute",
                  left: 40,
                  opacity: 1,
                  bottom: 28,
                  cursor: "pointer",
                  fontSize: 18,
                  letterSpacing: 0
                }}
              />
            ) : null}

            {(employer &&
              employer.status >= 1 &&
              payrollData &&
              payrollData.urlResume &&
              payrollData.urlResume.length > 0) ||
            showModalBeforGetResponse ? (
              <DialogCommonPDFComponent
                title={`Here is ${formProps.values.employeeActive.detail.firstName}’s Payslip`}
                open={
                  (payrollData &&
                    payrollData.urlResume &&
                    payrollData.urlResume.length > 0) ||
                  showModalBeforGetResponse
                }
                onCloseModal={this.handleDiscardPayroll}
                url={
                  payrollData && payrollData.urlResume
                    ? payrollData.urlResume
                    : false
                }
                primaryButtonName="Save & Send Payslip"
                onPrimaryButtonClick={this.handleSendPayroll}
                style={{ borderRadius: 5, height: 40, width: 200, padding: 0 }}
                style2={{
                  textAlign: "center",
                  letterSpacing: 0,
                  color: "#ffffff",
                  opacity: 1,
                  fontWeigth: "bold",
                  fontSize: "15px",
                  fontFamily: "AvenirNextLTPro-Demi"
                }}
                styleDiscard={{
                  color: "#004FDC",
                  fontFamily: "AvenirNextLTPro-Medium",
                  position: "absolute",
                  left: 40,
                  opacity: 1,
                  bottom: 28,
                  cursor: "pointer",
                  fontSize: 16,
                  letterSpacing: 0
                }}
                styleTitle={{
                  textAlign: "left",
                  letterSpacing: -0.37,
                  color: "#000000",
                  opacity: 1,
                  fontSize: 23,
                  fontWeight: "bolder"
                }}
                secondaryButtonNameDiscard="Discard"
                onSecondaryButtonClick={this.handleDiscardPayroll}
                showScroll
                textInfo={`Save and send to proceed, or discard to make changes`}
                textInfoStyle={{
                  textAlign: "end !important",
                  fontFamily: "AvenirNextLTPro-Medium",
                  marginBottom: 5,
                  fontSize: 18,
                  letterSpacing: 0,
                  color: "#000000",
                  opacity: 1
                }}
              />
            ) : null}
          </Paper>
          {error ? (
            <SnackbarCommonComponent
              onCloseModal={this.handleMessageClose}
              open={error}
              body="This employees already has a payslip for this time period."
            />
          ) : null}
          {showModalCLoseFYDone ? (
            <ModalCloseFYComponent
              open={showModalCLoseFYDone}
              onCloseModal={this.handleCloseFYDoneClose}
            />
          ) : null}
        </div>
      </form>
    );
  };

  render() {
    const { selectedPayrollData, redirectToSTP } = this.props;

    return (
      <Formik
        initialValues={{ ...selectedPayrollData, redirectToSTP }}
        validationSchema={this.validationSchema}
        onSubmit={this.handleSubmitProcess}
        render={this.renderForm}
        enableReinitialize
      />
    );
  }
}

PayrollProcessComponent.propTypes = {
  selectedPayrollData: PropTypes.object,
  payrollData: PropTypes.object,
  isSendEmail: PropTypes.bool,
  isFinishAllEmployees: PropTypes.bool,
  stpSummary: PropTypes.object,

  loadParamters: PropTypes.func,
  addPayroll: PropTypes.func,
  sendPayrollNextData: PropTypes.func,
  deletedPayroll: PropTypes.func,
  generateReportSummary: PropTypes.func
};

const mapStateToProps = state => ({
  selectedPayrollData: state.payroll.selectedPayrollData,
  listPayrollDataisFinal: state.payroll.listPayrollDataisFinal,
  payrollData: state.payroll.payroll,
  isSendEmail: state.payroll.isSendEmail,
  isFinishAllEmployees: state.payroll.isFinishAllEmployees,
  stpSummary: state.stp.reportSummary,
  employer: state.session.userData,
  tempRequest: state.employer.tempRequestDataPayRoll,
  redirectToPaySlip2: state.employer.redirectToPaySlip,
  tempRequestDataPayRoll2: state.employer.tempRequestDataPayRoll
});

const mapDispatchToProps = dispatch => ({
  getEmployeeDataRequest: bindActionCreators(
    payrollActions.getEmployeeDataRequest,
    dispatch
  ),
  loadParamters: bindActionCreators(
    parameterActions.getParametersByTypeRequest,
    dispatch
  ),
  addPayroll: bindActionCreators(payrollActions.addPayrollRequest, dispatch),
  sendPayrollNextData: bindActionCreators(
    payrollActions.sendPayrollNextData,
    dispatch
  ),
  deletedPayroll: bindActionCreators(
    payrollActions.deletedPayrollRequest,
    dispatch
  ),
  generateReportSummary: bindActionCreators(
    stpActions.generateReportSummaryRequest,
    dispatch
  ),
  savePayrollData: bindActionCreators(
    payrollActions.savePayrollDataComplete,
    dispatch
  ),
  redirectToSTP: bindActionCreators(singleTouchActions.redirectToSTP, dispatch),
  redirectToYourBusiness: bindActionCreators(
    employerActions.redirectToYourBusiness,
    dispatch
  ),
  tempRequestDataPayRoll: bindActionCreators(
    employerActions.tempRequestDataPayRoll,
    dispatch
  ),
  redirectToPaySlip: bindActionCreators(
    employerActions.redirectToPaySlip,
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(PayrollStyle)(PayrollProcessComponent)));
