import React, { Component } from "react";
import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Icon,
  Paper,
  Typography,
  Button
} from "@material-ui/core";
import { AvatarCommonComponent, LoadingComponent } from "../common";
import settingStyle from "./settingStyle";
import { putEployeesForCloseFYRequest } from "./../../actions/YECPActions";
import EditEmployeesForCloseFY from "./editEmployeesForCloseFY";
import * as routes from "../../constants/routes";

class ChooseEmployeesForEditSTP extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedAll: false,
      employees: props.employees && JSON.parse(JSON.stringify(props.employees)),
      check: false,
      select: false,
      clickBtn: false,
      editEmployeeComponent: false,
      employeeList: []
    };
  }

  componentDidMount = () => {
    const { employees, history } = this.props;
    if (employees == undefined) {
      history.push(routes.PATH_SETTINGS);
    }
  };
  componentWillReceiveProps = nextProps => {
    const { messageCode } = nextProps;
    let { editEmployeeComponent } = this.state;
    if (messageCode === "Success" && !editEmployeeComponent) {
      this.setState({
        editEmployeeComponent: true
      });
      const { history } = this.props;
      history.push(routes.PATH_SETTINGS_SELECT_UPDATE);
    }
  };
  handleSelectAll = e => {
    const { employees, check, select } = this.state;
    const selected = e.target.checked;
    this.setState({ selectedAll: e.target.checked });
    employees.map((employee, i) => {
      employee.selected = selected;
    });
    this.setState({
      check: selected,
      select: true
    });
    let select1 = employees.find(employee => {
      return employee.selected;
    });
    if (select1) {
      this.setState({
        clickBtn: true
      });
    } else {
      this.setState({
        clickBtn: false
      });
    }
  };

  handleSelect = (e, i) => {
    const { employees } = this.state;
    employees[i].selected = e.target.checked;

    this.setState({
      selectedAll: !employees.some(employee => !employee.selected),
      select: false
    });
    let selected = employees.find(employee => {
      return employee.selected;
    });
    if (selected) {
      this.setState({
        clickBtn: true
      });
    } else {
      this.setState({
        clickBtn: false
      });
    }
  };
  handleSendEmployees = () => {
    const { employees } = this.state;
    const { yecp, putEployeesForCloseFYRequest } = this.props;
    let employee = employees
      .filter(employee => employee.selected !== undefined && employee.selected)
      .map(employee => employee.id);
    let employeeList = employees.filter(
      employee => employee.selected !== undefined && employee.selected
    );
    this.setState({
      employeeList
    });
    putEployeesForCloseFYRequest(yecp, { employees: employee }, employeeList);
  };

  handleName = name1 => {
    let name = name1;
    let n = name.split(" ");
    let abb = n[0].slice(0, 1) + n[1].slice(0, 1);
    return abb.toUpperCase();
  };
  changeEmployeeList = employeeList => {
    this.setState({
      employeeList
    });
  };
  render() {
    const {
      classes,
      singleSelection = false,
      error,
      yecp,
      isLoading
    } = this.props;
    const { employees, selectedAll, clickBtn, employeeList } = this.state;
    if (employees == undefined) {
      return <p>back to setting</p>;
    }
    let listEmployees = employees.map((employee, index) => {
      return (
        <Paper
          className={classes.paper}
          key={employee.id}
          style={{ width: "100%", maxWidth: 878 }}
        >
          <Grid container wrap="nowrap" spacing={2} alignItems="center">
            <Grid item>
              <Checkbox
                color="primary"
                icon={
                  <Icon className={classes.IconCheck}>panorama_fish_eye</Icon>
                }
                checkedIcon={
                  <Icon className={classes.IconCheckActive}>check_circle</Icon>
                }
                id={employee.id}
                value={employee.id}
                checked={
                  (this.state.select && this.state.check) ||
                  employee.selected ||
                  false
                }
                //   checked={(employee || {}).selected}
                onChange={event => {
                  this.handleSelect(event, index);
                }}
              />
            </Grid>
            <Grid item>
              <AvatarCommonComponent
                text={this.handleName(employee.name)}
                hover={false}
                src={employee.imageUrl}
                employeeId={employee.id}
              />
            </Grid>
            <Grid item xs>
              <Typography variant="body1">{employee.name}</Typography>
              <Typography variant="subtitle1">{employee.jobTitle}</Typography>
            </Grid>
          </Grid>
        </Paper>
      );
    });

    return (
      <div className={classes.root}>
        <h1 className={classes.editEmployeesTitle}>
          Review Employees {yecp} Final STP File Values
        </h1>
        <span className={classes.editEmployeesdescription}>
          Select the Employees you want to include in the Final {yecp} STP File
        </span>
        <Grid container alignItems="center" className={classes.gridContainer}>
          <Grid item xs={3} className={classes.gridLeft}>
            {employees && employees.length > 0 ? (
              <FormControlLabel
                label={selectedAll ? "Deselect" : "Select all"}
                className={classes.selectedAllIcon}
                control={
                  <Checkbox
                    color="primary"
                    icon={
                      <Icon className={classes.IconCheck}>
                        panorama_fish_eye
                      </Icon>
                    }
                    checkedIcon={
                      <Icon className={classes.IconCheckActive}>
                        check_circle
                      </Icon>
                    }
                    checked={selectedAll}
                    onChange={this.handleSelectAll}
                  />
                }
              />
            ) : null}
          </Grid>
        </Grid>
        <Typography
          variant="h4"
          gutterBottom
          className={clsx("", {
            error
          })}
        >
          {`Select employee${!singleSelection ? "s" : ""}`}
        </Typography>
        {this.state.select ? listEmployees : listEmployees}
        <div className={classes.editEmployeesBtnPosition}>
          <Button
            onClick={this.handleSendEmployees}
            variant="contained"
            className={classes.editEmployeesBtn}
            color="secondary"
            disabled={!clickBtn}
          >
            Continue
          </Button>
        </div>
        <LoadingComponent isLoading={isLoading} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  messageCode: state.yecp.messageCode,
  isLoading: state.yecp.isLoading,
  employees: state.yecp.closeFY.employees,
  yecp: state.yecp.yecpName
});

const mapDispatchToProps = dispatch => ({
  putEployeesForCloseFYRequest: bindActionCreators(
    putEployeesForCloseFYRequest,
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(settingStyle)(ChooseEmployeesForEditSTP));
