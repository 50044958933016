import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

import { Typography } from "@material-ui/core";
import { DialogCommonComponent } from "../common";
import SettingStyle from "./settingStyle";

class ModalCloseFYDoneComponent extends Component {
	render() {
		const {
			classes, employer, open, onCloseModal
		} = this.props;

		return (
			<DialogCommonComponent size="xs" open={open} title={`Process Close ${employer.financialYear.name}`} primaryButtonName="Ok" onPrimaryButtonClick={onCloseModal} onCloseModal={onCloseModal}>
				<Typography className={classes.centerText}>{`You are already in the ${employer.financialYear.year} Financial Year.`}</Typography>
			</DialogCommonComponent>
		);
	}
}

ModalCloseFYDoneComponent.propTypes = {
	employer: PropTypes.object
};

const mapStateToProps = state => ({
	employer: state.session.userData
});

export default connect(
	mapStateToProps,
	null
)(withRouter(withStyles(SettingStyle)(ModalCloseFYDoneComponent)));
