import initialState from "../../store/initialState";

const parameterInitialState = {
	...initialState,
	parametersData: [],
	parameterByTypeData: {
		ALLOWANCE_PAYROLL: [],
		OVERTIME_PAYROLL: [],
		STATES_AUSTRALIA: [],
		EMPLOYEE_CATEGORY: [],
		EMPLOYEE_PAYROLL_PAY_BASIS: [],
		EMPLOYEE_PAYROLL_PAY_FREQUENTLY: [],
		EMPLOYEE_STATUS: [],
		EMPLOYEE_TAX_RESIDENCY: [],
		EMPLOYEE_TAX_BAND: [],
		PAYMENT_TYPE_PAYROLL: [],
		BASE_RATE_ADDITIONAL: [],
		OVERTIME_RATE: [],
		QUANTITY_PAYROLL: [],
	},
	plansData: []
};

export default parameterInitialState;
