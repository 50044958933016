import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";

import * as routes from "../../constants/routes";
import { ZendeskScript } from "../../scripts";
import { getStatusSubscription } from "../../helpers/extensions";
import { Analytics } from "../common";

class AuthenticatedComponent extends Component {
	componentWillMount() {
		// ZendeskScript();

		const { mixpanel, employer } = this.props;

		console.log("employer");
		console.log(employer);
		if (mixpanel && employer && employer.id) {
			Analytics.updateProfileInfoAction(employer);
			// mixpanel.identify(employer.id);
			// mixpanel.people.set({
			// 	$first_name: employer.companyName,
			// 	$ABN: employer.company ? (employer.company.abn || {}).number : "",
			// 	$WPN: employer.company ? (employer.company.wpn || {}).number : "",
			// 	$email: employer.login.email,
			// 	$subscription_status: getStatusSubscription(employer.subscription.status),
			// 	$phone: employer.contact ? employer.contact.phoneNumber : ""
			// });
		}
	}

	render() {
		ZendeskScript();

		const {
			component: InternalComponent, path, token, mixpanel
		} = this.props;

		if (token) {

			return <Route path={path} render={props => <InternalComponent {...props} mixpanel={mixpanel} />} />;
		}

		if (path != null) {
			return <Redirect to={`${routes.PATH_LOGIN}?redirect_uri=${encodeURIComponent(path)}`} />;
		}

		return <Redirect to={`${routes.PATH_LOGIN}`} />;
	}
}

AuthenticatedComponent.propTypes = {
	component: PropTypes.any.isRequired,
	path: PropTypes.string.isRequired,
	token: PropTypes.string.isRequired
};

const mapStateToProps = state => {
	return ({
		token: state.session.token,
		employer: state.employer && state.employer.employerData ? state.employer.employerData : state.session.userData
	})
};

export default connect(
	mapStateToProps,
	null
)(AuthenticatedComponent);
