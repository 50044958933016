import { createLogic } from "redux-logic";

import stpApi from "../api/STPApi";
import * as stpActions from "../actions/STPActions";
import * as singleTouchActions from "../actions/SingleTouchActions";
import * as actionTypes from "../constants/actionTypes";
import * as apiResponses from "../constants/apiResponses";
import { Analytics } from "../components/common";

const generateReportSummaryRequest = createLogic({
  type: actionTypes.ACTION_STP_REPORT_SUMMARY_REQUEST,
  latest: true,
  async process({ httpClient, action }, dispatch, done) {
    try {
      const response = await stpApi.generateReportSummary(
        httpClient,
        action.payload.reportSummary
      );

      if (response.messageCode === apiResponses.RESPONSE_OK) {
        Analytics.initiateSTPAction();
        dispatch(
          stpActions.generateReportSummaryRequestSuccess(
            response.item,
            response.messageCode,
            response.message
          )
        );
      } else {
        dispatch(
          stpActions.generateReportSummaryRequestFailure(
            response.messageCode,
            response.message
          )
        );
      }
    } catch (err) {
      dispatch(
        stpActions.generateReportSummaryRequestFailure(
          apiResponses.RESPONSE_FAIL,
          err.message
        )
      );
    }
    done();
  }
});

const deleteSTPRequest = createLogic({
  type: actionTypes.ACTION_STP_DELETE_REQUEST,
  latest: true,
  async process({ httpClient, action }, dispatch, done) {
    try {
      const response = await stpApi.deleteSTP(
        httpClient,
        action.payload.id
      );

      if (response.status === 200) {
        dispatch(stpActions.deleteSTPRequestSuccess(response.data.message));
        dispatch(singleTouchActions.getAllSTPRequest());
      } else {
        dispatch(stpActions.deleteSTPRequestFailure(response.data.message));
      }
    } catch (err) {
      dispatch(
        stpActions.deleteSTPRequestFailure(
          err.response && err.response.data && err.response.data.message ? err.response.data.message : err.message
        )
      );
    }
    done();
  }
})

const sendSTPRequest = createLogic({
  type: actionTypes.ACTION_STP_SEND_REQUEST,
  latest: true,
  async process({ httpClient, action }, dispatch, done) {
    try {
      const response = await stpApi.sendToSTP(
        httpClient,
        action.payload.sendToSTP
      );

      if (response.status === 200) {
        Analytics.sendSTPAction();
        dispatch(
          stpActions.sendSTPRequestSuccess(
            response.item,
            response.messageCode,
            response.message
          )
        );
      } else {
        dispatch(
          stpActions.sendSTPRequestFailure(
            response.status,
            response.data.message
          )
        );
      }
    } catch (err) {
      dispatch(
        stpActions.sendSTPRequestFailure(
          apiResponses.RESPONSE_FAIL,
          err.response.data.message
        )
      );
    }
    done();
  }
});

const setCleanDataToStore = createLogic({
  type: actionTypes.ACTION_STP_CLEAR_REQUEST,
  latest: true,
  async process({ actions }, dispatch, done) {
    try {
      dispatch(stpActions.setCleanDataToStoreSuccess());
    } catch (err) {
      dispatch(
        stpActions.setCleanDataToStoreFailure(
          apiResponses.RESPONSE_FAIL,
          err.message
        )
      );
    }
    done();
  }
});

export default [
  generateReportSummaryRequest,
  sendSTPRequest,
  deleteSTPRequest,
  setCleanDataToStore
];
