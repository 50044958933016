import { createLogic } from "redux-logic";

import * as actionTypes from "../constants/actionTypes";
import * as apiResponses from "../constants/apiResponses";
import * as subscriptionActions from "../actions/SubscriptionActions";
import * as employerActions from "../actions/EmployerActions";
import * as sessionActions from "../actions/SessionActions";
// import * as employerActions from "../actions/EmployerActions";
import * as routes from "../constants/routes";
import { Analytics } from "./../components/common"

import subscriptionApi from "../api/SubscriptionApi";

const activateSubscription = createLogic({
	type: actionTypes.ACTION_SUBSCRIPTION_ACTIVATE,
	latest: true,
	async process({ httpClient, action }, dispatch, done) {
		try {

			const response = await subscriptionApi.activateSubscription(httpClient, action.payload.token, action.payload.planId, action.payload.plan);

			if (response.status === 200) {
				const subscriptionData = response.data.item;

				dispatch(subscriptionActions.activateSubscriptionSuccess(subscriptionData, response.status, response.data.message));

				dispatch(employerActions.getEmployer);

				if (action.payload.plan) {
					Analytics.ChangeSubscriptionAction()
				} else {
					Analytics.NewSubscriptionAction();
				}
				dispatch(sessionActions.loadUserInformationThunk());
			} else {
				console.log('resp');
				console.log(response);
				throw new Error(response.data.message);
			}
		} catch (err) {
			let msg = err.message;
			if (err.response && err.response.data) {
				msg = err.response.data.message;
			}
			console.log("activate sub error", msg)
			dispatch(subscriptionActions.activateSubscriptionFailure(msg));
		}
		done();
	}
});

const getStatusSubscription = createLogic({
	type: actionTypes.ACTION_SUBSCRIPTION_GET_STATUS,
	latest: true,
	async process({ httpClient }, dispatch, done) {
		try {
			const response = await subscriptionApi.getStatusSubscription(httpClient);

			if (response.messageCode === apiResponses.RESPONSE_OK) {
				const statusSubscriptionData = response.item;

				dispatch(subscriptionActions.getStatusSubscriptionSuccess(statusSubscriptionData, response.messageCode, response.message));
			} else {
				throw new Error(response.message);
			}
		} catch (err) {
			if (err.response && err.response.status == 401) {
				dispatch(sessionActions.cleanSession());
			}
		
			dispatch(subscriptionActions.getStatusSubscriptionFailure(err.message));
		}
		done();
	}
});

const addEmployeeExtraSubscription = createLogic({
	type: actionTypes.ACTION_SUBSCRIPTION_EMPLOYEE_EXTRA,
	latest: true,
	async process({ httpClient, action }, dispatch, done) {
		try {
			const response = await subscriptionApi.addEmployeeExtraSubscription(httpClient, action.payload.quantityEmployees);

			if (response.messageCode === apiResponses.RESPONSE_OK) {
				const subscriptionData = response.item;

				dispatch(subscriptionActions.addEmployeeExtraSubscriptionSuccess(subscriptionData, response.messageCode, response.message));
				Analytics.ChangeSubscriptionAction();
				dispatch(sessionActions.loadUserInformationThunk());
			} else {
				throw new Error(response.message);
			}
		} catch (err) {
			let msg = err.message;
			if (err.response && err.response.data) {
				msg = err.response.data.message;
			}
			dispatch(subscriptionActions.addEmployeeExtraSubscriptionFailure(msg));
		}
		done();
	}
});

const updateCreditCardSubscription = createLogic({
	type: actionTypes.ACTION_SUBSCRIPTION_UPDATE_CREDIT_CARD,
	latest: true,
	async process({ httpClient, action }, dispatch, done) {
		try {
			const response = await subscriptionApi.updateCreditCardSubscription(httpClient, action.payload.token);

			// console.log(JSON.stringify(response));
			if (response.messageCode ==2000) {
				const subscriptionData = response.item;

				dispatch(subscriptionActions.updateCreditCardSubscriptionSuccess(subscriptionData, response.messageCode, response.message));

				dispatch(getStatusSubscription);

				dispatch(sessionActions.loadUserInformationThunk());

				dispatch(subscriptionActions.getStatusSubscription());
				dispatch(employerActions.getEmployer());
			} else {
				throw new Error(response);
			}
		} catch (err) {
			console.log(err);
			let msg = err.message;
			if (err.response && err.response.data) {
				msg = err.response.data.message;
			}
			dispatch(subscriptionActions.updateCreditCardSubscriptionFailure(msg));
		}
		done();
	}
});

const cancelSubscription = createLogic({
	type: actionTypes.ACTION_SUBSCRIPTION_CANCEL,
	latest: true,
	async process({ httpClient, action }, dispatch, done) {
		try {
			const response = await subscriptionApi.cancelSubscription(httpClient);

			if (response.messageCode === apiResponses.RESPONSE_OK) {
				const subscriptionData = response.item;

				dispatch(subscriptionActions.cancelSubscriptionSuccess(subscriptionData, response.messageCode, response.message));

				dispatch(sessionActions.loadUserInformationThunk());

				action.payload.history.push(routes.PATH_SUBSCRIPTION);
			} else {
				throw new Error(response.message);
			}
		} catch (err) {
			dispatch(subscriptionActions.cancelSubscriptionFailure(err.message));
		}
		done();
	}
});

export default [activateSubscription, getStatusSubscription, addEmployeeExtraSubscription, updateCreditCardSubscription, cancelSubscription];
