import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import {
	Grid, Paper, TextField, Typography
} from "@material-ui/core";
import AwesomeDebouncePromise from "awesome-debounce-promise/dist/index";
import employerStyle from "./employerStyle";
import { DialogCommonComponent } from "../common";
import WpnInformationComponent from "./wpnInformationComponent";
import InvalidInformationComponent from "./invalidInformationComponent";
import { clearWpnData, verifyWpn, cleanMessage } from "../../actions/EmployerActions";

class ModalSetupWPNComponent extends Component {
	state = {
		textWpn: ""
	};

	componentWillReceiveProps = (nextProps) => {
		const { formProps } = this.props;
		if (nextProps.wpnInfo && nextProps.wpnInfo.number !== formProps.values.company.wpn.number) {
			formProps.setFieldValue("company.wpn", nextProps.wpnInfo);
		}
	};

	handleOnChangeText = async (e) => {
		const { formProps, clearWpn, cleanMessage } = this.props;
		formProps.setFieldValue("company.wpn", {});
		clearWpn();
		cleanMessage();

		this.setState({ textWpn: e.target.value });
		const { verifyWpn } = this.props;
		if (e.target.value.length >= 8) {
			const verifyWpnDebounced = AwesomeDebouncePromise(verifyWpn, 600);
			await verifyWpnDebounced(e.target.value);
		}
	};

	handleClose = () => {
		const { formProps, cleanMessage } = this.props;
		formProps.setFieldValue("company.wpn", {});
		this.setState({ textWpn: "" });
		cleanMessage();
	};

	onPrimaryButtonClick = () => {
		const { onPrimaryButtonClick, clearWpn } = this.props;
		this.setState({ textWpn: "" });
		clearWpn();
		onPrimaryButtonClick();
	};

	onCloseModal = () => {
		const { onCloseModal, clearWpn } = this.props;
		this.setState({ textWpn: "" });
		clearWpn();
		onCloseModal();
	};

	render() {
		const {
			classes, open, formProps, isLoading, error
		} = this.props;
		const { textWpn } = this.state;

		return (
			<DialogCommonComponent title="Setup WPN" size="xs" onPrimaryButtonClick={this.onPrimaryButtonClick} open={open} onCloseModal={this.onCloseModal} primaryButtonDisabled={isLoading}>
				<Typography variant="body1" gutterBottom className={classes.description}>
					{"Provide your WPN deails"}
				</Typography>
				<Grid container direction="row" alignItems="center">
					<Grid item xs={12}>
						<Paper className={clsx(classes.paperGrid, { [classes.paperGridNotRight]: true })}>
							<TextField id="WPNNumber" label="Enter your WPN" className={classes.textField} variant="filled" value={textWpn} fullWidth onChange={this.handleOnChangeText} />
						</Paper>
					</Grid>
				</Grid>
				<WpnInformationComponent border error={error} formProps={formProps} onClose={this.handleClose} />
				<InvalidInformationComponent border formProps={formProps} onClose={this.handleClose} />
			</DialogCommonComponent>
		);
	}
}

const mapStateToProps = state => ({
	wpnInfo: state.employer.wpnInfo,
	isLoading: state.employer.isLoading,
	error: state.employer.error
});

const mapDispatchToProps = dispatch => ({
	verifyWpn: bindActionCreators(verifyWpn, dispatch),
	clearWpn: bindActionCreators(clearWpnData, dispatch),
	cleanMessage: bindActionCreators(cleanMessage, dispatch)
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(employerStyle)(ModalSetupWPNComponent));
