import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";
import { bindActionCreators } from "redux";
import { LoadingComponent, SnackbarCommonComponent } from "../components/common";
import Login2FAComponent from "../components/Session/Login2FAComponent";
import { ExternaUserStyles } from "./PagesStyle";
import * as employerActions from "../actions/EmployerActions";
import * as sessionAction from "../actions/SessionActions";

class Login2FAPage extends Component {
	render() {
		const { classes, isLoading, mixpanel, errorSession, error, errorMessage, message, messageCode, cleanMessageInternal, cleanMessageSessionInternal, sessionSuccess } = this.props;
		console.log('isLoading',isLoading)
		return (
			<div className={classes.root}>
				<LoadingComponent isLoading={isLoading} />
				<SnackbarCommonComponent autoHideDuration={5000} open={!isLoading && message && messageCode === 200} body={message} onCloseModal={sessionSuccess ? cleanMessageSessionInternal : cleanMessageInternal} />
				<SnackbarCommonComponent variant="error" autoHideDuration={5000} open={!isLoading && error && messageCode !== 200} body={errorMessage} onCloseModal={errorSession ? cleanMessageSessionInternal : cleanMessageInternal} />
				<CssBaseline />
				<Login2FAComponent mixpanel={mixpanel} />
			</div>
		);
	}
}

Login2FAPage.propTypes = {
	classes: PropTypes.object.isRequired,
	isLoading: PropTypes.bool
};

const mapStateToProps = state => ({
	isLoading: state.external.isLoading || state.employer.isLoading  || state.session.isLoading,
	error: state.employer.error || state.session.error,
	errorSession: state.session.error,
	errorMessage: state.employer.errorMessage || state.session.errorMessage,
	message: state.employer.message || state.session.message,
	sessionSuccess: !!state.session.message,
	messageCode: state.employer.messageCode || state.session.messageCode,
});
const mapDispatchToProps = dispatch => ({
	cleanMessageInternal: bindActionCreators(employerActions.cleanMessage, dispatch),
	cleanMessageSessionInternal: bindActionCreators(sessionAction.cleanMessage, dispatch),
});
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(ExternaUserStyles)(Login2FAPage));
