import React, { Component } from "react";
import { Provider } from "react-redux";
import ReactGA from "react-ga";
import TagManager from "react-gtm-module";

import { MuiThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import moment from "moment-timezone";
import MomentUtils from "@date-io/moment";

import mixpanel from "mixpanel-browser";
import { MixpanelProvider, MixpanelConsumer } from "react-mixpanel";

import Root from "./Root";
import theme from "./theme";
import store from "./store";
import { loadUserInformationThunk } from "./actions/SessionActions";
import { getAllParametersRequest } from "./actions/ParameterActions";
import { FacebookSDKScript } from "./scripts";

moment.locale("en-AU");
moment.tz.setDefault("Australia/Sydney");
mixpanel.init(window.envVariables.mixpanelId);
mixpanel.set_config({ignore_dnt: true});
mixpanel.config.debug = true;

store.dispatch(loadUserInformationThunk());
store.dispatch(getAllParametersRequest());

class App extends Component {
	componentWillMount() {
		const { googleTagManagerId, googleAnalitycsId } = window.envVariables;

		TagManager.initialize({
			gtmId: googleTagManagerId
		});

		ReactGA.initialize(googleAnalitycsId);
		ReactGA.pageview(window.location.pathname + window.location.search);
		FacebookSDKScript();
	}

	render() {
		return (
			<MuiThemeProvider theme={theme}>
				<Provider store={store}>
					<MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
						<MixpanelProvider mixpanel={mixpanel}>
							<MixpanelConsumer>{mixpanelprops => <Root mixpanel={mixpanelprops} />}</MixpanelConsumer>
						</MixpanelProvider>
					</MuiPickersUtilsProvider>
				</Provider>
			</MuiThemeProvider>
		);
	}
}

export default App;
