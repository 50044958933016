import { createLogic } from "redux-logic";

import employeeApi from "../api/EmployeeApi";
import payrollApi from "../api/PayrollApi";
import * as payrollActions from "../actions/PayrollActions";
import * as stpActions from "../actions/STPActions";
import * as actionTypes from "../constants/actionTypes";
import * as apiResponses from "../constants/apiResponses";
import { payrollProcessDefaultData } from "../reducers/Payroll/PayrollInitialState";
import { dispatchProcess } from "../store/customMiddleWare";
import { Analytics } from "../components/common";

const savePayrollDataRequest = createLogic({
	type: actionTypes.ACTION_PAYROLL_SAVE_DATA_REQUEST,
	latest: true,
	async process({ action }, dispatch, done) {
		try {
			dispatch(payrollActions.savePayrollDataRequestSuccess(action.payload.selectedPayrollData, apiResponses.RESPONSE_OK, ""));
		} catch (err) {
			dispatch(payrollActions.savePayrollDataRequestFailure("", err.message));
		}
		done();
	}
});

const getEmployeeDataRequest = createLogic({
	type: actionTypes.ACTION_PAYROLL_GET_EMPLOYEE_REQUEST,
	latest: true,
	async process({ httpClient, getState }, dispatch, done) {
		try {
			const { employeeActive } = getState().payroll.selectedPayrollData;
			const employeeResponse = await employeeApi.getEmployeeById(httpClient, employeeActive.id);
			if (employeeResponse && employeeResponse.messageCode === apiResponses.RESPONSE_OK) {
				dispatch(payrollActions.getEmployeeDataRequestSuccess(employeeResponse.item, employeeResponse.messageCode, "Employee information loaded"));
			} else {
				throw new Error("Error loading employee information, please try again");
			}
		} catch (err) {
			dispatch(payrollActions.getEmployeeDataRequestFailure(apiResponses.RESPONSE_FAIL, err.message));
		}
		done();
	}
});

const addPayrollRequest = createLogic({
	type: actionTypes.ACTION_PAYROLL_ADD_REQUEST,
	latest: true,
	async process({ httpClient, action }, dispatch, done) {
		try {
			const response = await payrollApi.addPayroll(httpClient, action.payload.payroll);

			if (response.messageCode === apiResponses.RESPONSE_OK) {
				// Analytics.submitPayrollAction();
				Analytics.PR_2ReviewPayrollAction()
				dispatch(payrollActions.addPayrollRequestSuccess(response.item, response.messageCode, response.message));
			} else {
				dispatch(payrollActions.addPayrollRequestFailure(response.messageCode, response.message));
			}
		} catch (err) {
			dispatch(payrollActions.addPayrollRequestFailure(apiResponses.RESPONSE_FAIL, err.message));
		}
		done();
	}
});

const sendPayrollRequest = createLogic({
	type: actionTypes.ACTION_PAYROLL_SEND_MAIL_REQUEST,
	latest: true,
	async process({ httpClient, action }, dispatch, done) {
		try {
			const response = await payrollApi.sendEmail(httpClient, action.payload.payroll);

			if (response.status === 200) {
				Analytics.PR_3SendPayslipAction()
				dispatch(payrollActions.sendPayrollRequestSuccess(action.payload.payroll, response.data.messageCode, response.data.message));
			} else {
				dispatch(payrollActions.sendPayrollRequestFailure(response.data.messageCode, response.data.message));
			}
		} catch (err) {
			dispatch(payrollActions.sendPayrollRequestFailure(apiResponses.RESPONSE_FAIL, err.response.data.message));
		}
		done();
	}
});

const nextEmployeePayrollRequest = createLogic({
	type: actionTypes.ACTION_PAYROLL_SAVE_PAYLOAD_DATA_REQUEST,
	latest: true,
	async process({ getState }, dispatch, done) {
		try {
			const { payroll, selectedPayrollData } = getState().payroll;
			const { employees, employeeActive } = selectedPayrollData;

			const newEmployees = [...employees];

			const newEmployeesValues = newEmployees.map((employee) => {
				if (employee.id === employeeActive.id && payroll && payroll.id !== undefined) {
					return {
						...employee,
						isProcessed: true,
						payroll: {
							id: payroll.id,
							url: payroll.urlResume
						}
					};
				}
				return employee;
			});
			let newEmployeeActive = {}
			let find = newEmployeesValues.find(employee => !employee.isProcessed && selectedPayrollData.employeeActive.id == employee.id);
			if (find) {
				newEmployeeActive = {
					detail: find
				};
			} else {
				newEmployeeActive = {
					detail: {
						...newEmployeesValues.filter(employee => !employee.isProcessed)[0]
					}
				};
			}


			const newData = {
				...selectedPayrollData,
				employeeActive: {
					...newEmployeeActive,
					id: newEmployeeActive.detail.id
				},
				employees: [...newEmployeesValues],
				payroll: {
					...payrollProcessDefaultData
				}
			};

			dispatch(payrollActions.nextEmployeePayrollRequestThunk(newData, newData.employeeActive.id === undefined));
		} catch (err) {
			dispatch(payrollActions.nextEmployeePayrollRequestFailure(apiResponses.RESPONSE_FAIL, err.message));
		}
		done();
	}
});

const deletedPayrollRequest = createLogic({
	type: actionTypes.ACTION_PAYROLL_DELETED_REQUEST,
	latest: true,
	async process({ httpClient, action }, dispatch, done) {
		try {
			const response = await payrollApi.deleteById(httpClient, action.payload.id);

			if (response.messageCode === apiResponses.RESPONSE_OK) {
				if (action.payload.mixpanel) {
					Analytics.PR_DiscardPayslipAction();
				}
				dispatch(payrollActions.deletedPayrollRequestSuccess(true, response.messageCode, response.message));
			} else {
				dispatch(payrollActions.deletedPayrollRequestFailure(response.messageCode, response.message));
			}
		} catch (err) {
			dispatch(payrollActions.deletedPayrollRequestFailure(apiResponses.RESPONSE_FAIL, err.message));
		}
		done();
	}
});

const validatePayrollRequest = createLogic({
	type: actionTypes.ACTION_PAYROLL_VALIDATE_REQUEST,
	latest: true,
	async process({ httpClient, getState }, dispatch, done) {
		try {
			const { selectedPayrollData, listPayrollDataisFinal } = getState().payroll;
			const { employees } = selectedPayrollData;
			let isFinal = listPayrollDataisFinal ? !!listPayrollDataisFinal.find(i => i.isFinal) : false;
			const payrollDataValidate = [];
			employees.forEach((employee) => {
				payrollDataValidate.push({
					employerId: selectedPayrollData.employerId,
					employeeId: employee.id,
					payPeriodStart: selectedPayrollData.startDate,
					payPeriodEnd: selectedPayrollData.finishDate,
					isFinal: selectedPayrollData.payroll ? selectedPayrollData.payroll.isFinal : false,
					isFinal: selectedPayrollData.payroll ? (selectedPayrollData.payroll.isFinal != null ? selectedPayrollData.payroll.isFinal : isFinal) : isFinal
				});
			});
			const response = await payrollApi.validatePayroll(httpClient, payrollDataValidate);
			if (response.messageCode === apiResponses.RESPONSE_OK) {
				const validateInformation = [...response.list];
				const newEmployees = [];
				const isFinalPayrollData = [];
				validateInformation.forEach((payroll) => {
					const employeeInfo = employees.filter(employee => employee.id === payroll.employeeId)[0];
					const newEmployeeInfo = {
						...employeeInfo
					};
					newEmployeeInfo.isProcessed = payroll.generated;
					if (payroll.generated) {
						newEmployeeInfo.payroll = {
							id: payroll.id,
							url: payroll.urlResume
						};
					}
					isFinalPayrollData.push({
						isFinal: payroll.isFinal ? true : false,
						employeeId: payroll.employeeId
					});
					newEmployees.push(newEmployeeInfo);
				});
				const newEmployeeData = {
					...selectedPayrollData,
					employees: [...newEmployees]
				};
				// Analytics.initiatePayrollAction();
				dispatch(payrollActions.nextEmployeePayrollRequestIsFinal(isFinalPayrollData));
				dispatch(payrollActions.validatePayrollRequestSuccess(newEmployeeData, response.messageCode, response.message));
			} else {
				dispatch(payrollActions.validatePayrollRequestFailure(response.messageCode, response.message));
			}
		} catch (err) {
			dispatch(payrollActions.validatePayrollRequestFailure(apiResponses.RESPONSE_FAIL, err.message));
		}
		done();
	}
});

const savePayrollDataComplete = createLogic({
	type: actionTypes.ACTION_PAYROLL_SAVE_PAYLOAD_DATA_COMPLETE,
	latest: true,
	async process({ action }, dispatch, done) {
		try {
			const { payrollData, history, location } = action.payload;
			Analytics.PR_1InitiatePayrollAction();
			dispatch(stpActions.setCleanDataToStore());
			await dispatchProcess(payrollActions.savePayrollDataRequest(payrollData), actionTypes.ACTION_PAYROLL_SAVE_DATA_REQUEST_SUCCESS, actionTypes.ACTION_PAYROLL_SAVE_DATA_REQUEST_FAILURE);
			await dispatchProcess(payrollActions.validatePayrollRequest(), actionTypes.ACTION_PAYROLL_VALIDATE_REQUEST_SUCCESS, actionTypes.ACTION_PAYROLL_VALIDATE_REQUEST_FAILURE);
			await dispatchProcess(payrollActions.nextEmployeePayrollRequest(), actionTypes.ACTION_PAYROLL_SAVE_PAYLOAD_DATA_REQUEST_SUCCESS, actionTypes.ACTION_PAYROLL_SAVE_PAYLOAD_DATA_REQUEST_FAILURE);
			history.push(location);
		} catch (err) {
			console.error("Error processing payroll data: ", err);
		}
		done();
	}
});

const sendPayrollNextData = createLogic({
	type: actionTypes.ACTION_PAYROLL_SEND_MAIL_NEXT,
	latest: true,
	async process({ action }, dispatch, done) {
		try {
			const { payroll } = action.payload;
			// Analytics.sendPayrollAction();
			await dispatchProcess(payrollActions.sendPayrollRequest(payroll), actionTypes.ACTION_PAYROLL_SEND_MAIL_REQUEST_SUCCESS, actionTypes.ACTION_PAYROLL_SEND_MAIL_REQUEST_FAILURE);
			await dispatchProcess(payrollActions.nextEmployeePayrollRequest(), actionTypes.ACTION_PAYROLL_SAVE_PAYLOAD_DATA_REQUEST_SUCCESS, actionTypes.ACTION_PAYROLL_SAVE_PAYLOAD_DATA_REQUEST_FAILURE);
		} catch (err) {
			console.error("Error processing payroll data: ", err);
		}
		done();
	}
});

export default [savePayrollDataRequest, getEmployeeDataRequest, addPayrollRequest, sendPayrollRequest, deletedPayrollRequest, nextEmployeePayrollRequest, validatePayrollRequest, savePayrollDataComplete, sendPayrollNextData];
