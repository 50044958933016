import * as actionTypes from "../constants/actionTypes";

//add additonal user
export function inviteAdditionalUser(email) {

	return {
		type: actionTypes.ACTION_INVITE_ADDITIONAL_USER,
		payload: {
			email
		}
	};
}

export function inviteAdditionalUserSuccess(messageCode, message) {
	return {
		type: actionTypes.ACTION_INVITE_ADDITIONAL_USER_SUCCESS,
		payload: {
			messageCode,
			message
		}
	};
}

export function inviteAdditionalUserFailure(messageCode, errorMessage) {
	return {
		type: actionTypes.ACTION_INVITE_ADDITIONAL_USER_FAILURE,
		error: true,
		payload: {
			messageCode,
			errorMessage
		}
	};
}

export function cleanMessage() {
	
	return {
		type: actionTypes.ACTION_INVITE_ADDITIONAL_USER_CLEAN_MESSAGE,
		payload: {
			errorMessage: null,
			messageCode: null,
			message: null
		}
	};
}


// get all additonal user
export function getInviteAdditionalUserAllData() {

	return {
		type: actionTypes.ACTION_INVITE_ADDITIONAL_USER_DATA_REQUEST,
		payload: {}
	};
}

export function getInviteAdditionalUserAllDataSuccess(accountantsData) {

	return {
		type: actionTypes.ACTION_INVITE_ADDITIONAL_USER_DATA_REQUEST_SUCCESS,
		payload: {
			accountants: accountantsData
		}
	};
}

export function getInviteAdditionalUserAllDataFailure(messageCode, errorMessage) {

	return {
		type: actionTypes.ACTION_INVITE_ADDITIONAL_USER_DATA_REQUEST_FAILURE,
		error: true,
		payload: {
			messageCode,
			errorMessage
		}
	};
}

// revoke all additonal user
export function AdditionalUserRevoke(accountantId) {

	return {
		type: actionTypes.ACTION_ADDITIONAL_USER_REVOKE,
		payload: {
			accountantId
		}
	};
}

export function AdditionalUserRevokeSuccess(messageCode, message) {

	return {
		type: actionTypes.ACTION_ADDITIONAL_USER_REVOKET_SUCCESS,
		payload: {
			messageCode,
			message
		}
	};
}

export function AdditionalUserRevokeFailure(messageCode, errorMessage) {

	return {
		type: actionTypes.ACTION_ADDITIONAL_USER_REVOKE_FAILURE,
		error: true,
		payload: {
			messageCode,
			errorMessage
		}
	};
}


