import * as actionTypes from "../../constants/actionTypes";
import sessionInitialState from "./SessionInitialState";
import { Analytics } from "../../components/common";

const sessionReducer = (state = sessionInitialState, action) => {
	switch (action.type) {
		case actionTypes.ACTION_SESSION_USER_LOGGING_IN: {

			return {
				...state,
				isLoading: true,
				email: action.payload.email
			};
		}
		case actionTypes.ACTION_SESSION_USER_LOGGING_IN_SUCCESS: {

			let isEmployee = false;
			let isAccountant = action.payload.isAccountant;
			if (action.payload.userData.accountType === "employer") {
				isEmployee = true;
				isAccountant = false;
			}
			if (action.payload.isEmployee) {
				isEmployee = action.payload.isEmployee;
			}
			Analytics.LoginAction()

			return {
				...state,
				isLoading: false,
				isAccountant: isAccountant,
				isEmployee: isEmployee,
				userData: action.payload.userData,
				token: action.payload.token,
				enable2FA: action.payload.enable2FA,
				enabled2FA: action.payload.enabled2FA,
				method2FA: action.payload.method2FA,
				messageCode: action.payload.messageCode,
				message: action.payload.message
			};
		}
		case actionTypes.ACTION_SESSION_USER_LOGGING_IN_FAILURE: {
			return {
				...state,
				isLoading: false,
				error: true,
				errorMessage: action.payload.errorMessage
			};
		}

		case actionTypes.ACTION_SESSION_USER_FORGOT_PASSWORD: {
			return {
				...state,
				isLoading: true,
				error: false
			}
		}

		case actionTypes.ACTION_SESSION_USER_FORGOT_PASSWORD_SUCCESS: {
			return {
				...state,
				isLoading: false,
				error: false
			}
		}

		case actionTypes.ACTION_SESSION_USER_FORGOT_PASSWORD_FAILURE: {
			return {
				...state,
				isLoading: false,
				error: true,
				errorMessage: action.payload.message,
			}
		}

		case actionTypes.ACTION_SESSION_EMPLOYER_LOGGING_IN: {
			return {
				...state,
				isLoading: true,
				email: action.payload.email
			};
		}
		case actionTypes.ACTION_SESSION_EMPLOYER_LOGGING_IN_SUCCESS: {

			return {
				...state,
				isLoading: false,
				userData: action.payload.userData,
				isAccountant: action.payload.isAccountant,
				isEmployee: true,
				token: action.payload.token,
				enable2FA: action.payload.enable2FA,
				method2FA: action.payload.method2FA,
				messageCode: action.payload.messageCode,
				message: action.payload.message
			};
		}
		case actionTypes.ACTION_SESSION_EMPLOYER_LOGGING_IN_FAILURE: {
			return {
				...state,
				isLoading: false,
				error: true,
				errorMessage: action.payload.errorMessage
			};
		}

		/* Login 2FA Email by Sajadweb */
		case actionTypes.ACTION_SESSION_USER_LOGGING_2FA_EMAIL: {
			return {
				...state,
				isLoading: true,
				code2fa: action.payload.code2fa
			};
		}
		case actionTypes.ACTION_SESSION_USER_LOGGING_2FA_EMAIL_SUCCESS: {
			return {
				...state,
				isLoading: false,
				userData: action.payload.userData,
				messageCode: action.payload.messageCode,
				message: action.payload.message
			};
		}
		case actionTypes.ACTION_SESSION_USER_LOGGING_2FA_EMAIL_FAILURE: {
			return {
				...state,
				isLoading: false,
				error: true,
				errorMessage: action.payload.errorMessage
			};
		}
		/* Login 2FA with Text */
		case actionTypes.ACTION_SESSION_USER_LOGGING_2FA_TEXT: {
			return {
				...state,
				isLoading: true,
				code2fa: action.payload.code2fa
			};
		}
		case actionTypes.ACTION_SESSION_USER_LOGGING_2FA_TEXT_SUCCESS: {
			return {
				...state,
				isLoading: false,
				userData: action.payload.userData,
				messageCode: action.payload.messageCode,
				message: action.payload.message
			};
		}
		case actionTypes.ACTION_SESSION_USER_LOGGING_2FA_TEXT_FAILURE: {
			return {
				...state,
				isLoading: false,
				error: true,
				errorMessage: action.payload.errorMessage
			};
		}
		/* Login 2FA  by John */
		case actionTypes.ACTION_SESSION_USER_LOGGING_2FA: {
			return {
				...state,
				isLoading: true,
				code2fa: action.payload.code2fa
			};
		}
		case actionTypes.ACTION_SESSION_USER_LOGGING_2FA_SUCCESS: {
			return {
				...state,
				isLoading: false,
				userData: action.payload.userData,
				messageCode: action.payload.messageCode,
				message: action.payload.message
			};
		}
		case actionTypes.ACTION_SESSION_USER_LOGGING_2FA_FAILURE: {
			return {
				...state,
				isLoading: false,
				error: true,
				errorMessage: action.payload.errorMessage
			};
		}
		/* Change Password by John */
		case actionTypes.ACTION_SESSION_USER_CHANGE_PASSWORD: {
			return {
				...state,
				isLoading: true,
				password: action.payload.password
			};
		}
		case actionTypes.ACTION_SESSION_USER_CHANGE_PASSWORD_SUCCESS: {
			return {
				...state,
				isLoading: false,
				userData: action.payload.userData,
				messageCode: action.payload.messageCode,
				message: action.payload.message
			};
		}
		case actionTypes.ACTION_SESSION_USER_CHANGE_PASSWORD_FAILURE: {
			return {
				...state,
				isLoading: false,
				error: true,
				errorMessage: action.payload.errorMessage
			};
		}
		case actionTypes.ACTION_SESSION_USER_UPDATE_INFO: {

			return {
				...state,
				isLoading: false,
				userData: action.payload.userData,
				messageCode: action.payload.messageCode,
				message: action.payload.message,

			};
		}
		case actionTypes.ACTION_SESSION_UPDATE_AUTHORIZED_PERSON: {
			console.log('case statement');
			console.log({
				...state,
				isLoading: false,
				authorizedName: action.payload.authorizedName,
				messageCode: action.payload.messageCode,
				message: action.payload.message
			});
			return {
				...state,
				isLoading: false,
				authorizedName: action.payload.authorizedName,
				messageCode: action.payload.messageCode,
				message: action.payload.message
			};
		}
		case actionTypes.ACTION_SESSION_LOGOUT: {
			return sessionInitialState;
		}
		case actionTypes.ACTION_SESSION_LOGOUT_API: {
			return {
				...state,
				isLoading: true
			};
		}
		case actionTypes.ACTION_SESSION_LOGOUT_API_SUCCESS: {
			return {
				...state,
				isLoading: false,
				messageCode: action.payload.messageCode,
				message: action.payload.message
			};
		}
		case actionTypes.ACTION_SESSION_LOGOUT_API_FAILURE: {
			return {
				...state,
				isLoading: false,
				messageCode: action.payload.messageCode,
				message: action.payload.message
			};
		}
		case actionTypes.ACTION_SESSION_CLEAN_MESSAGE: {
			return {
				...state,
				error: false,
				isLoading: false,
				errorMessage: action.payload.errorMessage,
				messageCode: action.payload.messageCode,
				message: action.payload.message
			};
		}

		default: {
			return {
				...state
			};
		}
	}
};

export default sessionReducer;
