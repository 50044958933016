import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";

import { SessionUserStyles } from "./PagesStyle";

import { LoadingComponent, SnackbarCommonComponent } from "../components/common";
import SidebarComponent from "../components/home/sidebarComponent";
import EditEmployeeComponent from "../components/employee/editEmployeeComponent";
import { mapMessageCodeToIcon } from "../helpers/extensions";
import { cleanMessage } from "../actions/EmployeeActions";

class EditEmployeePage extends Component {
	render() {
		const {
			classes, isLoading, message, messageCode, isSaving, cleanMessageInternal, mixpanel
		} = this.props;

		return (
			<div className={classes.root}>
				<CssBaseline />
				<SidebarComponent />
				<main className={classes.content}>
					<LoadingComponent isLoading={isLoading} />
					<SnackbarCommonComponent key="SnackBarSavingData" open={isSaving} body="Saving" />
					<SnackbarCommonComponent key="SnackBarEditEmployee" variant={mapMessageCodeToIcon(messageCode)} open={(!!message)} body={message} onCloseModal={cleanMessageInternal} />
					<div className={classes.appBarSpacer} />
					<EditEmployeeComponent mixpanel={mixpanel} />
				</main>
			</div>
		);
	}
}

EditEmployeePage.propTypes = {
	classes: PropTypes.object.isRequired,
	isSaving: PropTypes.bool,
	isLoading: PropTypes.bool,
	messageCode: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),
	message: PropTypes.string,
};

const mapStateToProps = state => ({
	isLoading: state.employees.isLoading,
	isSaving: state.employees.isSaving,
	messageCode: state.employees.messageCode,
	message: state.employees.message,
});

const mapDispatchToProps = dispatch => ({
	cleanMessageInternal: bindActionCreators(cleanMessage, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(SessionUserStyles)(EditEmployeePage));
