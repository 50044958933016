const employerEmailGenerate = async (httpClient) => {
	const { goEpsApi } = window.envVariables;
	const response = await httpClient.post(`${goEpsApi}/api/v1/2fa/email/generate`);
	return response;
};
const employerEmailValidate = async (httpClient, code2fa) => {
	const { goEpsApi } = window.envVariables;
	const requestData = {
		code: code2fa
	};
	const response = await httpClient.post(`${goEpsApi}/api/v1/2fa/validate`, requestData);

	return response;
};

const employerTextGenerate = async (httpClient) => {
	const { goEpsApi } = window.envVariables;
	const response = await httpClient.post(`${goEpsApi}/api/v1/2fa/text/generate`);

	return response;
};
const employerTextValidate = async (httpClient, code2fa) => {
	const { goEpsApi } = window.envVariables;
	const requestData = {
		code: code2fa
	};
	const response = await httpClient.post(`${goEpsApi}/api/v1/2fa/text/validate`, requestData);

	return response;
};

const employerTOPTGenerate = async (httpClient) => {
	const { goEpsApi } = window.envVariables;
	const response = await httpClient.post(`${goEpsApi}/api/v1/2fa/authenticator/generate`);
	return response.data;
};

const employerTOPTValidate = async (httpClient, code2fa) => {
	const { goEpsApi } = window.envVariables;
	const requestData = {
		code: code2fa
	};

	const response = await httpClient.post(`${goEpsApi}/api/v1/2fa/validate`, requestData);
	return response;
};

const employerTOPTRevoke = async (httpClient, password) => {
	const { goEpsApi } = window.envVariables;

	console.log("revoke");
	const response = await httpClient.post(`${goEpsApi}/api/v1/2fa/revoke`, { password });

	return response;
};

const employerTOPTDisabled = async (httpClient, password) => {
	const { goEpsApi } = window.envVariables;

	console.log("disable");
	const response = await httpClient.post(`${goEpsApi}/api/v1/2fa/revoke`, { password });
	return response;
};

const getEmployer = async (httpClient) => {
	const { goEpsApi } = window.envVariables;
	const response = await httpClient.get(`${goEpsApi}/api/v1/employer`);
	return response.data;
};

const getAuthorizedPerson = async (httpClient) => {
	console.log("getting authorized porsen");
	const { goEpsApi } = window.envVariables;
	const response = await httpClient.get(`${goEpsApi}/api/v1/stp/authorizedperson`);
	console.log(Object.assign({ status: response.status }, response.data));
	return Object.assign({ status: response.status }, response.data);
}

const employerUpdate = async (httpClient, requestData) => {
	const { goEpsApi } = window.envVariables;
	const response = await httpClient.put(`${goEpsApi}/api/v1/employer`, requestData);
	return response.data;
};

const closeFYRequest = async (httpClient, requestData) => {
	const { epsApi } = window.envVariables;
	const response = await httpClient.post(`${epsApi}/api/v2/Employer/CloseFY`, requestData);
	return response.data;
};

const generateLastPaymentsRequest = async (httpClient, requestData) => {
	const { epsApi } = window.envVariables;
	const response = await httpClient.post(`${epsApi}/api/v2/Employer/GenerateLastPaymentSummaries`, requestData);
	return response.data;
};

const create = async (httpClient, requestData) => {
	const { goEpsApi } = window.envVariables;

	const response = await httpClient.post(`${goEpsApi}/api/v1/employer`, requestData);

	return response;
}

export default {
	create,
	employerEmailGenerate,
	employerEmailValidate,
	employerTextGenerate,
	employerTextValidate,
	employerTOPTGenerate,
	employerTOPTValidate,
	employerTOPTRevoke,
	employerTOPTDisabled,
	getEmployer,
	getAuthorizedPerson,
	employerUpdate,
	closeFYRequest,
	generateLastPaymentsRequest
};
