import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter, Link as RouterLink, Redirect } from "react-router-dom";
import PropTypes, { func } from "prop-types";
import {
    Box, Button, TextField, Grid, Link, FormControl, FormHelperText, Typography, Paper, Dialog, RadioGroup, Radio, FormControlLabel
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Formik, getIn } from "formik";
import clsx from "clsx";
import * as Yup from "yup";
import { Analytics } from "../common";
import { DEEP_LINK } from "../../constants/common";
import employerStyle from './employerStyle'
import { DeepOpen } from "../../scripts";
import * as parameterTypes from "../../constants/parameterTypes";
import logoATO from "../../assets/images/logoATO.png";
import WpnInformationComponent from "./wpnInformationComponent";
import AbnInformationComponent from "./abnInformationComponent";
import InvalidInformationComponent from "./invalidInformationComponent";
import AwesomeDebouncePromise from "awesome-debounce-promise/dist/index";
import * as routes from "../../constants/routes";
import { nextSlid, redirectToYourBusiness, clearWpnData, verifyWpn, cleanMessage, verifyAbn, clearAbnData, redirectToPaySlip } from '../../actions/EmployerActions'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

class ModalSetupBusinessInfoComponent extends Component {
    state = {
        currentIndex: 0,
        showABN: false,
        showWPN: false,
        showContactDetalis: false,
        showSTPContact: false,
        lastActiveIndex: 0,
        nextSlider: false,
        isvalidation: false,
        isvalidation2: false,
        isvalidation3: false,
        isvalidationLocal: false,
        confirmationChecked: false,
        textAbn: '',
        textWpn: '',
        userTradingName: ''
    };

    validationSchema = Yup.object({
        company: Yup.object().shape({
            abn: Yup.object().shape({ number: Yup.string().required('ABN is required') }),
            wpn: Yup.object().shape({ number: Yup.string().required('WPN is required') }),
        }),
    });
    componentWillMount() {
        DeepOpen(DEEP_LINK);
        Analytics.OB_8ABNWPNScreen()

    }
    componentWillReceiveProps = (nextProps) => {

        this.setState({
            isvalidation: false
        })
        const { nextSlid2, handelCloseBusinessInfoModal, formProps, } = nextProps
        const { isValidation, redirectToYourBusiness, nextSlid, redirectToPaySlip } = this.props
        let { currentIndex, showWPN, showABN } = this.state;
        if (this.state.userTradingName === '') {
            this.setState({
                userTradingName: formProps.values.company.abn.userTradingName
            })
        } else if (formProps.values.company.abn.userTradingName === '') {
            formProps.setFieldValue('company.abn.userTradingName', this.state.userTradingName)
        }

        // console.log("formProps", formProps.values.company);
        if (nextSlid2 && currentIndex != 2) {
            if (currentIndex === 0) {
                Analytics.OB_8ABNWPNAction()
            } else if (currentIndex === 1) {
                Analytics.OB_9ContactBAction()
            }
            this.handleNext();
        } else if (nextSlid2 && currentIndex === 2) {
            Analytics.completeOnlyRegisterAction()
            handelCloseBusinessInfoModal()
            isValidation(false)
            nextSlid(false);
            redirectToYourBusiness(false)
            redirectToPaySlip(true)
        }
        let errors = formProps.errors
        if (errors) {
            switch (currentIndex) {
                case 0:
                    if (showABN) {
                        // formProps.values.company.abn.name.length <= 0 ? this.setState({ isvalidation: false }) : this.setState({ isvalidation: true });
                        // formProps.values.company.abn.number === "" ? formProps.values.company.abn.number.length <= 0 ? this.setState({ isvalidation: false }) : this.setState({ isvalidation: true }) : this.setState({ isvalidation: true });
                        formProps.values.company.abn.number ? this.setState({
                            isvalidation: true
                        }) : this.setState({
                            isvalidation: false
                        })
                        break;
                    } else if (showWPN) {

                        formProps.values.company.wpn.number === "" ? formProps.values.company.wpn.number.length <= 0 ? this.setState({ isvalidation: false }) : this.setState({ isvalidation: true }) : this.setState({ isvalidation: true });
                        break;
                    }
                    break;
                case 1:
                    if (errors.contact) {
                        this.setState({ isvalidation2: false })
                        break;
                    } else if (errors.company) {
                        if (errors.company.address) {
                            this.setState({ isvalidation2: false })
                            break;
                        } else {
                            this.setState({ isvalidation2: true })
                            break;
                        }
                    } else {
                        if (formProps.values.contact.firstName) {
                            this.setState({
                                isvalidation2: true
                            })
                            break;
                        } else {
                            this.setState({
                                isvalidation2: false
                            })
                            break;
                        }
                    }
                    break;
                case 2:
                    if (errors.company) {
                        if (errors.company.authorizedPerson) {
                            this.setState({ isvalidation3: false })
                            break;
                        } else {
                            this.setState({ isvalidation3: true })
                            break;
                        }
                    } else {
                        if (formProps.values.company.authorizedPerson.firstName) {
                            this.setState({ isvalidation3: true })
                            break;
                        } else {
                            this.setState({ isvalidation3: false })
                            break;
                        }
                    }
                    break;
                default:
                    break;
            }
        }
    }
    renderAustraliaStateOptions = () => {
        const { stateList, classes } = this.props;
        return stateList
            .sort((firstEl, secondEl) => firstEl.order < secondEl.order)
            .map(item => <option key={`${item.id}`} value={`${item.value}`} className={classes.option}>{item.value}</option>);
    };

    handleNext = (event, formProps) => {
        console.log("going to the next index");
        this.setState({
            isvalidation: false,
            lastActiveIndex: this.state.lastActiveIndex + 1,
            // currentIndex: this.state.currentIndex + 1,
        })
        this.props.nextSlid(false)
        this.slider.slickNext();
    };

    handleSaveContactDetails = (event, formProps) => {
        this.setState({
            showContactDetalis: true,
            isvalidation: false,
            lastActiveIndex: this.state.lastActiveIndex + 1
        })
        this.slider.slickNext();
    }

    handlePrevious = () => {
        this.slider.slickPrev();
    };

    handleChange = (event, fromProps, formProps1) => {
        const newValue = event.target.value;
        if (event.target.id === 'company.abn.userTradingName') {
            this.setState({
                userTradingName: newValue
            }, () => {
                fromProps.setFieldValue('company.abn.userTradingName', newValue);
                formProps1 && formProps1.setFieldValue('company.abn.userTradingName', newValue)
            })
        } else {
            fromProps.setFieldValue(event.target.id, newValue);
            formProps1 && formProps1.setFieldValue(event.target.id, newValue)
        }


    };

    handleOnChangeText = async (e, formProps1) => {
        const { clearWpn, cleanMessage, formProps } = this.props;
        // formProps.setFieldValue(e.target.id, e.target.value);

        formProps.setFieldValue("company.wpn", {});
        formProps.setFieldValue("company.abn", {});
        formProps.setFieldValue("company.abn.userTradingName", this.state.userTradingName)

        formProps1 && formProps1.setFieldValue(e.target.id, e.target.value);
        clearWpn();
        cleanMessage();
        this.setState({
            textWpn: e.target.value,
            textAbn: '',
        });
        if (e.target.value.length >= 8) {
            const { verifyWpn } = this.props
            const verifyWpnDebounced = AwesomeDebouncePromise(verifyWpn, 600);
            await verifyWpnDebounced(e.target.value);
        }
    };

    handelOnchangeTextAbn = async (e, formProps1) => {
        const { cleanMessage, formProps, clearAbn, verifyAbn, } = this.props;
        formProps.setFieldValue("company.abn.number", '');

        formProps.setFieldValue("company.wpn", {});





        formProps1 && formProps1.setFieldValue(e.target.id, e.target.value);
        clearAbn();
        cleanMessage();
        this.setState({
            textAbn: e.target.value,
            textWpn: ''

        });
        if (e.target.value.length >= 11) {
            const { verifyAbn } = this.props
            const verifyAbnDebounced = AwesomeDebouncePromise(verifyAbn, 600);
            await verifyAbnDebounced(e.target.value);

        }
    }

    handelRadiobtnABN = () => {
        this.setState({
            showABN: true,
            showWPN: false,

        })
    }
    handelRadiobtnWPN = () => {
        this.setState({
            showABN: false,
            showWPN: true,

        })
    }

    afterChange = (current) => {
        console.log("changing to", current);
        switch (current) {
            case 0:
                Analytics.OB_8ABNWPNScreen()
                break;
            case 1:
                Analytics.OB_9ContactBScreen()
                break;
            case 2:
                Analytics.OB_10STPContactScreen()
                break;
        }
        this.setState({
            currentIndex: current,
            isvalidation: false,
            lastActiveIndex: current > this.state.lastActiveIndex ? current : this.state.lastActiveIndex
        })
    };
    handleClose = () => {
        const { formProps, cleanMessage } = this.props;
        formProps.setFieldValue("company.wpn", {});
        formProps.setFieldValue("company.abn.number", '');
        formProps.setFieldValue("company.abn.name", '');
        formProps.setFieldValue("company.abn.userTradingName", '');
        this.setState({ textWpn: "" });
        this.setState({ textAbn: "" });
        cleanMessage();
    };

    beforeChange = (oldIndex, newIndex) => {
        console.log("will go to", newIndex);
        this.setState({
            hideBtnNext: newIndex == 3,
            hideBtnPrev: oldIndex == 0,
        });
    }
    handelUpdateBusinessInfo = async () => {
        const { isValidation, formProps } = this.props;
        await isValidation(true)
        console.log("start handle submit");
        formProps.handleSubmit()
        console.log("end handle submit");

    }
    handelchangetrading = (e, formProps) => {

    }

    handleCheckConfirmation = () => {
		this.setState({ confirmationChecked: !this.state.confirmationChecked });
	};

    // { values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, }
    renderForm = (formProps1) => {

        const { classes, open, formProps } = this.props;

        let { showABN, showWPN, currentIndex, textAbn, textWpn, confirmationChecked } = this.state
        if (formProps.values.company.wpn.number && textWpn === '') {
            this.setState({

                textWpn: this.props.employer.company.wpn.number
            })
        }
        if (formProps.values.company.abn.number && textAbn === '') {
            this.setState({
                textAbn: this.props.employer.company.abn.number,

            })
        }
        const ABNData = formProps.values.company.abn;

        var settings = {
            dots: true,
            infinite: false,
            speed: 250,
            dotsClass: `slick-dots slick-dots-businessInfo`,
            autoplaySpeed: 4000,
            slidesToShow: 1,
            touchMove: false,
            slidesToScroll: 1,
            // adaptiveHeight: true,
            // fade: true,
            arrows: false,
            afterChange: (current, next) => this.afterChange(current, next),
            beforeChange: (current, next) => { this.beforeChange(current, next); setTimeout(this.afterChange(next), 250); },
            className: "quickSetupSlide",
            appendDots: () => <ul>
                <li class={this.state.currentIndex === 0 ? "slick-active" : ""} ><button onClick={() => this.slider.slickGoTo(0)}>1</button></li>
                <li class={`${this.state.currentIndex === 1 ? "slick-active" : ""} ${this.state.lastActiveIndex < 1 ? "qs-noHover" : ""}`}><button disabled={this.state.lastActiveIndex < 1 ? true : false} onClick={() => this.slider.slickGoTo(1)}>2</button></li>
                <li class={`${this.state.currentIndex === 2 ? "slick-active" : ""} ${this.state.lastActiveIndex < 2 ? "qs-noHover" : ""}`}><button disabled={this.state.lastActiveIndex < 2 ? true : false} onClick={() => this.slider.slickGoTo(2)}>3</button></li>
            </ul>
        };

        console.log(currentIndex);

        return (
            <Dialog open={open} className={classes.dialogBusinessInfo} PaperProps={{
                style: {
                    minWidth: '650px',
                    // padding: '52px',
                    borderRadius: '25px',
                    width: '640px !important',
                    padding: '0px 0px 20px 0px !important',

                },
            }}>
                <Grid container   >
                    <Grid container alignItems="flex-start">
                        <Grid container className={classes.rootContainer} style={{ padding: "17px 24px" }}>

                            <Grid xs={12} style={{ padding: '0px !important' }}>
                                <div className={classes.slideShow}>
                                    <Slider ref={c => (this.slider = c)} {...settings}>
                                        {/* slide Item one */}
                                        <div >
                                            {currentIndex === 0 && <div className={classes.slideShowItem} style={(!showWPN && !showABN) ? { minHeight: 268, borderRadius: '55px !important' } : { minHeight: 364 }}>
                                                <div>
                                                    <div>
                                                        <div className={classes.titleWrapper}>
                                                            <h1 className={classes.title}>
                                                                {showABN && 'Set up ABN'}
                                                                {showWPN && 'Set up WPN'}
                                                                {!showWPN && !showABN && 'Business Details'}
                                                            </h1>
                                                            <label className={classes.subTitleOne} onClick={this.test}>
                                                                Select either ABN or WPN
									        	            </label>
                                                        </div>
                                                        <RadioGroup style={{ display: 'flex', flexDirection: 'row', marginTop: 15, marginLeft: 10 }}>
                                                            <FormControlLabel value="female" style={{ color: '#6D9FEE' }} control={<Radio
                                                                onChange={this.handelRadiobtnABN}
                                                                name="radio-button-ABN"
                                                                style={{ color: '#6D9FEE' }}
                                                                checked={showABN}
                                                            />} label="ABN*" />
                                                            <FormControlLabel value="male" style={{ color: '#6D9FEE' }} control={<Radio
                                                                onChange={this.handelRadiobtnWPN}
                                                                name="radio-button-WPN"
                                                                style={{ color: '#6D9FEE' }}
                                                                checked={showWPN}
                                                            />} label="WPN*" />
                                                        </RadioGroup>
                                                        {showABN &&
                                                            <div>
                                                                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
                                                                    <Grid container alignItems="center" xs={6}>
                                                                        <label className={classes.subTitleOne} style={{ marginBottom: 0 }}>
                                                                            Provide your ABN
									        	                        </label>
                                                                        <Paper className={classes.paperGrid2} style={{ marginTop: 10, width: "100%", borderRadius: '5px !important', height: 83 }}>
                                                                            <TextField
                                                                                autoFocus
                                                                                fullWidth
                                                                                id="company.abn.name"
                                                                                label="ABN*"
                                                                                variant="filled"
                                                                                value={this.state.textAbn}
                                                                                onChange={event => this.handelOnchangeTextAbn(event, formProps1)}
                                                                                onBlur={formProps.handleBlur}
                                                                                className={clsx(classes.textFieldBusinessInfo, {
                                                                                    error: getIn(!this.state.textAbn) && getIn(!this.state.textAbn)
                                                                                })}
                                                                            />
                                                                            {!this.state.textAbn &&
                                                                                <FormHelperText error>{'ABN is required'}</FormHelperText>}

                                                                        </Paper>
                                                                    </Grid>
                                                                    <Grid container alignItems="center" xs={6}>
                                                                        <label className={classes.subTitleOne} style={{ marginBottom: 0 }}>
                                                                            Enter your Trading Name (if applicable)
									        	                </label>
                                                                        <Paper className={classes.paperGrid} style={{ marginTop: 10, width: "100%", borderRadius: '5px !important', height: 83 }}>

                                                                            <TextField
                                                                                id="company.abn.userTradingName"
                                                                                label="Trading Name"
                                                                                className={classes.textFieldBusinessInfo}
                                                                                variant="filled"
                                                                                // value={this.state.userTradingName != '' ? this.state.userTradingName : formProps.values.company.abn.userTradingName}
                                                                                value={formProps.values.company.abn.userTradingName != '' ? formProps.values.company.abn.userTradingName : this.state.userTradingName}
                                                                                // onChange={event => this.handleChange(event, formProps)}
                                                                                onChange={event => this.handleChange(event, formProps)}
                                                                                onBlur={formProps.handleBlur}
                                                                                style={{ borderRadius: '5px !important' }}
                                                                                fullWidth />

                                                                        </Paper>

                                                                    </Grid>

                                                                </div>
                                                                <Grid xl={12} lg={12} md={12} sm={12} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginTop: -8 }}>
                                                                    <Grid xl={6} lg={6} md={6} sm={6}>

                                                                    </Grid>
                                                                    {/* <Grid xl={6} lg={6} md={6} sm={6} style={{ paddingLeft: 15 }}>
                                                                        <label className={classes.labelUpButton} >
                                                                            This name will be used on payslips if entered
									        	                        </label>
                                                                    </Grid> */}
                                                                </Grid>
                                                                {textAbn.length > 10 && <AbnInformationComponent isPopup border formProps={formProps} onClose={this.handleClose} />}
                                                                {!textWpn && <InvalidInformationComponent border formProps={formProps} onClose={this.handleClose} />}
                                                                {
                                                                    ABNData.name &&
                                                                        <Paper className={classes.paperContainer}>
                                                                            <br/>
                                                                            <Grid container alignItems="center">
                                                                                <Grid item xs={1}>
                                                                                    <Checkbox
                                                                                        style={{marginLeft: -10}}
                                                                                        checkedIcon={<CheckBoxIcon fontSize={"large"} />}
                                                                                        icon={<CheckBoxOutlineBlankIcon fontSize={"large"} />}
                                                                                        checked={confirmationChecked}
                                                                                        onChange={this.handleCheckConfirmation}
                                                                                        inputProps={{ 'aria-label': 'primary checkbox', fontSize: 50 }}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={11}>
                                                                                    <Typography variant="body1">
                                                                                        I confirm that I am authorised to set up this account and lodge Single Touch Payroll files on behalf of {ABNData.name}.
                                                                                        <br /><br />
                                                                                        Please be aware that the ATO may contact you directly regarding any STP files lodged.
                                                                                    </Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Paper>
                                                                }
                                                                <Grid xl={12} lg={12} md={12} sm={12} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', }}>
                                                                    {/* <label className={classes.labelUpButton} >
                                                                        This name will be used on payslips if entered
									        	                </label> */}
                                                                    <FormControl fullWidth margin="normal" style={{ width: 'max-content' }}>
                                                                        <Box m={0.1} />
                                                                        <Button variant="contained" color="secondary"
                                                                            className={clsx(classes.saveABN)}
                                                                            onClick={this.handelUpdateBusinessInfo}
                                                                            disabled={!formProps.values.company.abn.number || !confirmationChecked}
                                                                            type="submit">
                                                                            Save ABN
											                            </Button>
                                                                    </FormControl>
                                                                </Grid>
                                                            </div>
                                                        }
                                                        {showWPN &&
                                                            <div>
                                                                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
                                                                    <Grid container alignItems="center" xs={12}>
                                                                        <label className={classes.subTitleOne} style={{ marginBottom: 0 }}>
                                                                            Provide your WPN
                                                                    </label>
                                                                        <Paper className={classes.paperGrid} style={{ marginTop: 10, width: "100%", borderRadius: '5px !important', height: 83 }}>
                                                                            <TextField
                                                                                autoFocus
                                                                                fullWidth
                                                                                id="company.wpn"
                                                                                label="WPN*"
                                                                                variant="filled"
                                                                                value={this.state.textWpn}
                                                                                onChange={event => this.handleOnChangeText(event, formProps1)}
                                                                                onBlur={formProps.handleBlur}
                                                                                className={clsx(classes.textFieldBusinessInfo, {
                                                                                    error: getIn(!this.state.textWpn) && getIn(!this.state.textWpn)
                                                                                })}
                                                                            />
                                                                            {!this.state.textWpn &&
                                                                                <FormHelperText error>{'WPN is required'}</FormHelperText>}
                                                                        </Paper>

                                                                    </Grid>
                                                                </div>
                                                                <WpnInformationComponent border formProps={formProps} onClose={this.handleClose} />
                                                                {!textAbn && <InvalidInformationComponent border formProps={formProps} onClose={this.handleClose} />}

                                                                <Grid xl={12} lg={12} md={12} sm={12} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', }}>
                                                                    <label className={classes.labelUpButton} >

                                                                    </label>
                                                                    <FormControl fullWidth margin="normal" style={{ width: 'max-content' }}>
                                                                        <Box m={0.1} />
                                                                        <Button variant="contained" color="secondary"
                                                                            className={clsx(classes.saveABN)}
                                                                            onClick={this.handelUpdateBusinessInfo}
                                                                            disabled={!formProps.values.company.wpn.number}
                                                                            type="submit">
                                                                            Save WPN
                                                                    </Button>
                                                                    </FormControl>
                                                                </Grid>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>}
                                        </div>
                                        {/* Slide Item two */}
                                        <div>
                                            {currentIndex === 1 && <div className={classes.slideShowItem} style={{ minHeight: 482 }}>
                                                <div>
                                                    <div>
                                                        <div className={classes.titleWrapper}>
                                                            <h1 className={classes.title1}>
                                                                Contact Details
                                                        </h1>
                                                            <label className={classes.subTitleOne1}>
                                                                Enter your contact details below
                                                        </label>
                                                        </div>
                                                        <Grid container direction="row" alignItems="flex-start" style={{ marginTop: 10 }}>
                                                            <Grid item xs={12}>
                                                                <Paper className={classes.paperGrid}>
                                                                    <TextField
                                                                        id="contact.phoneNumber"
                                                                        label="Preferred contact number*"
                                                                        variant="filled"
                                                                        value={formProps.values.contact.phoneNumber}
                                                                        // autoFocus
                                                                        onChange={formProps.handleChange}
                                                                        onBlur={formProps.handleBlur}
                                                                        className={clsx(classes.textFieldBusinessInfo, {
                                                                            error: getIn(formProps.errors, "contact.phoneNumber") && getIn(formProps.touched, "contact.phoneNumber")
                                                                        })}
                                                                        fullWidth
                                                                    />
                                                                    {getIn(formProps.errors, "contact.phoneNumber") && getIn(formProps.touched, "contact.phoneNumber") && <FormHelperText error>{formProps.errors.contact.phoneNumber}</FormHelperText>}
                                                                </Paper>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Paper className={classes.paperGrid2} style={{ padding: '0px 10px 20px 0px !important' }}>
                                                                    <TextField
                                                                        id="contact.firstName"
                                                                        label="First name*"
                                                                        variant="filled"
                                                                        value={formProps.values.contact.firstName}
                                                                        onChange={formProps.handleChange}
                                                                        onBlur={formProps.handleBlur}
                                                                        className={clsx(classes.textFieldBusinessInfo, {
                                                                            error: getIn(formProps.errors, "contact.firstName") && getIn(formProps.touched, "contact.firstName")
                                                                        })}
                                                                        fullWidth
                                                                    />
                                                                    {getIn(formProps.errors, "contact.firstName") && getIn(formProps.touched, "contact.firstName") && <FormHelperText error>{formProps.errors.contact.firstName}</FormHelperText>}
                                                                </Paper>
                                                            </Grid>

                                                            <Grid item xs={6}>
                                                                <Paper className={classes.paperGrid}>
                                                                    <TextField
                                                                        id="contact.lastName"
                                                                        label="Last name*"
                                                                        variant="filled"
                                                                        value={formProps.values.contact.lastName}
                                                                        onChange={formProps.handleChange}
                                                                        onBlur={formProps.handleBlur}
                                                                        className={clsx(classes.textFieldBusinessInfo, {
                                                                            error: getIn(formProps.errors, "contact.lastName") && getIn(formProps.touched, "contact.lastName")
                                                                        })}
                                                                        fullWidth
                                                                    />
                                                                    {getIn(formProps.errors, "contact.lastName") && getIn(formProps.touched, "contact.lastName") && <FormHelperText error>{formProps.errors.contact.lastName}</FormHelperText>}
                                                                </Paper>
                                                            </Grid>


                                                            <Grid item xs={12}>
                                                                <Paper className={classes.paperGrid}>
                                                                    <TextField
                                                                        id="company.address.addressLine1"
                                                                        label="Address line 1*"
                                                                        variant="filled"
                                                                        InputProps={{
                                                                            inputProps: {
                                                                                maxLength: "37"
                                                                            }
                                                                        }}
                                                                        value={formProps.values.company.address.addressLine1}
                                                                        onChange={formProps.handleChange}
                                                                        onBlur={formProps.handleBlur}
                                                                        className={clsx(classes.textFieldBusinessInfo, {
                                                                            error: (getIn(formProps.errors, "company.address.addressLine1") && getIn(formProps.touched, "company.address.addressLine1"))
                                                                        })}
                                                                        fullWidth
                                                                    />
                                                                    {(getIn(formProps.errors, "company.address.addressLine1") && getIn(formProps.touched, "company.address.addressLine1") && <FormHelperText error>{formProps.errors.company.address.addressLine1}</FormHelperText>)}
                                                                </Paper>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Paper className={classes.paperGrid2}>
                                                                    <TextField
                                                                        id="company.address.addressLine2"
                                                                        label="Address line 2 (If applicable)"
                                                                        className={classes.textField}
                                                                        variant="filled"
                                                                        InputProps={{
                                                                            inputProps: {
                                                                                maxLength: "37"
                                                                            }
                                                                        }}
                                                                        maxLength="37"
                                                                        value={formProps.values.company.address.addressLine2}
                                                                        onChange={formProps.handleChange}
                                                                        className={clsx(classes.textFieldBusinessInfo)}
                                                                        onBlur={formProps.handleBlur}
                                                                        fullWidth
                                                                    />
                                                                </Paper>
                                                            </Grid>


                                                            <Grid item xs={6}>
                                                                <Paper className={classes.paperGrid}>
                                                                    <TextField
                                                                        id="company.address.townCity"
                                                                        label="Town/City*"
                                                                        variant="filled"
                                                                        value={formProps.values.company.address.townCity}
                                                                        onChange={formProps.handleChange}
                                                                        onBlur={formProps.handleBlur}
                                                                        className={clsx(classes.textFieldBusinessInfo, {
                                                                            error: (getIn(formProps.errors, "company.address.townCity") && getIn(formProps.touched, "company.address.townCity"))
                                                                        })}
                                                                        fullWidth
                                                                    />
                                                                    {(getIn(formProps.errors, "company.address.townCity") && getIn(formProps.touched, "company.address.townCity") && <FormHelperText error>{formProps.errors.company.address.townCity}</FormHelperText>)}
                                                                </Paper>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Paper className={classes.paperGrid2}>
                                                                    <TextField
                                                                        id="company.address.state"
                                                                        label="State/Territory*"
                                                                        variant="filled"
                                                                        value={formProps.values.company.address.state}
                                                                        onChange={formProps.handleChange}
                                                                        onBlur={formProps.handleBlur}
                                                                        className={clsx(classes.textFieldBusinessInfo, {
                                                                            error: (getIn(formProps.errors, "company.address.state") && getIn(formProps.touched, "company.address.state"))
                                                                        })}
                                                                        SelectProps={{
                                                                            native: true,
                                                                            MenuProps: {
                                                                                className: classes.menu
                                                                            }
                                                                        }}
                                                                        select
                                                                        fullWidth
                                                                    >
                                                                        <option></option>
                                                                        {this.renderAustraliaStateOptions()}
                                                                    </TextField>
                                                                    {(getIn(formProps.errors, "company.address.state") && getIn(formProps.touched, "company.address.state") && <FormHelperText error>{formProps.errors.company.address.state}</FormHelperText>)}
                                                                </Paper>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Paper className={classes.paperGrid}>
                                                                    <TextField
                                                                        id="company.address.postCode"
                                                                        label="Postcode*"
                                                                        variant="filled"
                                                                        InputProps={{
                                                                            inputProps: {
                                                                                maxLength: "4"
                                                                            }
                                                                        }}
                                                                        value={formProps.values.company.address.postCode}
                                                                        onChange={formProps.handleChange}
                                                                        onBlur={formProps.handleBlur}
                                                                        className={clsx(classes.textFieldBusinessInfo, {
                                                                            error: (getIn(formProps.errors, "company.address.postCode") && getIn(formProps.touched, "company.address.postCode"))
                                                                        })}
                                                                        fullWidth
                                                                    />
                                                                    {(getIn(formProps.errors, "company.address.postCode") && getIn(formProps.touched, "company.address.postCode") && <FormHelperText error>{formProps.errors.company.address.postCode}</FormHelperText>)}
                                                                </Paper>
                                                            </Grid>

                                                            <Grid xs={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: -8, marginBottom: -16 }}>
                                                                <Grid xs={6}>
                                                                    <label className={classes.labelBack} onClick={this.handlePrevious} >
                                                                        Back
                                                                        </label>
                                                                </Grid>
                                                                <Grid xs={6} style={{ textAlign: '-webkit-right' }}>
                                                                    <FormControl fullWidth margin="normal" >

                                                                        <Button variant="contained" color="secondary" className={classes.saveContact}
                                                                            onClick={this.handelUpdateBusinessInfo}
                                                                            disabled={!this.state.isvalidation2}
                                                                            type="submit">
                                                                            Save Contact Details
                                                                    </Button>
                                                                    </FormControl>
                                                                </Grid>

                                                            </Grid>
                                                        </Grid>

                                                    </div>
                                                </div>
                                            </div>}
                                        </div>

                                        {/* slide Item three */}
                                        <div>
                                            {currentIndex === 2 && <div className={classes.slideShowItem} style={{ height: 482 }}>
                                                <div>
                                                    <div>
                                                        <div className={classes.titleWrapper}>
                                                            <h1 className={classes.title1}>
                                                                STP Contact Person
                                                            </h1>
                                                            <label className={classes.subTitleOne1}>
                                                                The person below is responsible for lodging STP files on behalf of the business
									        	            </label>
                                                        </div>

                                                        <Grid container direction="row" alignItems="flex-start" style={{ marginTop: 10 }}>

                                                            <Grid item xs={6}>
                                                                <Paper className={classes.paperGrid2}>
                                                                    <TextField
                                                                        id="company.authorizedPerson.firstName"
                                                                        label="First name*"
                                                                        variant="filled"
                                                                        value={formProps.values.company.authorizedPerson.firstName}
                                                                        onChange={formProps.handleChange}
                                                                        onBlur={formProps.handleBlur}
                                                                        className={clsx(classes.textFieldBusinessInfo, {
                                                                            error: getIn(formProps.errors, "company.authorizedPerson.firstName") && getIn(formProps.touched, "company.authorizedPerson.firstName")
                                                                        })}
                                                                        fullWidth
                                                                    />
                                                                    {getIn(formProps.errors, "company.authorizedPerson.firstName") && getIn(formProps.touched, "company.authorizedPerson.firstName") && <FormHelperText error>{formProps.errors.company.authorizedPerson.firstName}</FormHelperText>}
                                                                </Paper>
                                                            </Grid>

                                                            <Grid item xs={6}>
                                                                <Paper className={classes.paperGrid}>
                                                                    <TextField
                                                                        id="company.authorizedPerson.lastName"
                                                                        label="Last name*"
                                                                        variant="filled"
                                                                        value={formProps.values.company.authorizedPerson.lastName}
                                                                        onChange={formProps.handleChange}
                                                                        onBlur={formProps.handleBlur}
                                                                        className={clsx(classes.textFieldBusinessInfo, {
                                                                            error: getIn(formProps.errors, "company.authorizedPerson.lastName") && getIn(formProps.touched, "company.authorizedPerson.lastName")
                                                                        })}
                                                                        fullWidth
                                                                    />
                                                                    {getIn(formProps.errors, "company.authorizedPerson.lastName") && getIn(formProps.touched, "company.authorizedPerson.lastName") && <FormHelperText error>{formProps.errors.company.authorizedPerson.lastName}</FormHelperText>}
                                                                </Paper>
                                                            </Grid>

                                                            <Grid xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 210 }}>
                                                                <img src={logoATO} alt="" className={classes.imageLogoATO} />
                                                            </Grid>
                                                            <Grid xl={12} lg={12} md={12} sm={12} style={{ display: 'flex', flexDirection: 'row', marginTop: 16 }}>
                                                                <Grid xl={6} lg={6} md={6} sm={6}>
                                                                </Grid>
                                                                <Grid xl={6} lg={6} md={6} sm={6} style={{ textAlign: 'end', paddingLeft: 40, marginBottom: -10 }}>
                                                                    <label className={classes.labelUpButton1} >
                                                                        Take me back to my Payslip
									        	                </label>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid xs={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                <Grid xs={6}>
                                                                    <label className={classes.labelBack} onClick={this.handlePrevious}>
                                                                        Back
                                                                </label>
                                                                </Grid>
                                                                <Grid xs={6} style={{ textAlign: '-webkit-right' }}>
                                                                    <FormControl fullWidth margin="normal" >
                                                                        <Button variant="contained" color="secondary" className={classes.saveContact}
                                                                            // onClick={event => this.handleNext(event, formProps)}
                                                                            onClick={this.handelUpdateBusinessInfo}
                                                                            disabled={!this.state.isvalidation3}
                                                                            type="submit">
                                                                            Let's Go
                                                                        </Button>
                                                                    </FormControl>
                                                                </Grid>

                                                            </Grid>
                                                        </Grid>

                                                    </div>
                                                </div>
                                            </div>}
                                        </div>
                                    </Slider>
                                    <Grid container>

                                        <div> <br /></div>
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid >
            </Dialog>
        );
    };
    render() {
        const { classes, open, formProps, isValidation, redirectToPaySlip2 } = this.props;
        if (redirectToPaySlip2) {
            return (
                <Redirect to={routes.PATH_PAYROLL_PROCESS} />
            )
        }
        let quickSetupData = {
            ...formProps.values,
            open,
            isValidation


        };


        return (
            <Grid container className={classes.root}>
                <Formik initialValues={{ ...quickSetupData }} validationSchema={this.state.isvalidation || this.validationSchema} onSubmit={this.handleSubmitSave} render={this.renderForm} />
            </Grid>
        );
    }
}
ModalSetupBusinessInfoComponent.propTypes = {
    // formProps: PropTypes.object,
    // classes: PropTypes.object,
    // onboard: PropTypes.object,
    // businessData: PropTypes.object,
    // error: PropTypes.bool,
    // errorMessage: PropTypes.string,
    // onboardSessionid: PropTypes.string,
    // signUp: PropTypes.func,
    // onboardingRequest: PropTypes.func,
};
const mapStateToProps = state => ({
    error: state.external.error,
    errorMessage: state.external.errorMessage,
    employer: state.employer.employerData,
    employer2: state.employer,
    stateList: state.parameters.parameterByTypeData[parameterTypes.PARAMETER_STATES_AUSTRALIA.parameterType],
    nextSlid2: state.employer.nextSlid,
    redirectToPaySlip2: state.employer.redirectToPaySlip
});
const mapDispatchToProps = dispatch => ({
    nextSlid: bindActionCreators(nextSlid, dispatch),
    redirectToYourBusiness: bindActionCreators(redirectToYourBusiness, dispatch),
    verifyWpn: bindActionCreators(verifyWpn, dispatch),
    clearWpn: bindActionCreators(clearWpnData, dispatch),
    cleanMessage: bindActionCreators(cleanMessage, dispatch),
    clearAbn: bindActionCreators(clearAbnData, dispatch),
    verifyAbn: bindActionCreators(verifyAbn, dispatch),
    redirectToPaySlip: bindActionCreators(redirectToPaySlip, dispatch)

});
export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withStyles(employerStyle)(ModalSetupBusinessInfoComponent))
);

