import React, { Component } from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  MenuItem
} from "@material-ui/core";

import { MenuItemComponent, SnackbarCommonComponent } from "../common";

import PayrollStyle from "./payrollStyle";
import ModalAddDeductions from "./modalAddDeductions";
import { payrollDeductionsDefaultData } from "../../reducers/Payroll/PayrollInitialState";
import * as messages from "../../constants/messages";
import DeductionsItemComponent from "./deductionsItemComponent";

class DeductionsComponent extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    showModal: false,
    error: {
      show: false,
      message: ""
    },
    editItem: {
      isEditing: false,
      data: null,
      index: -1
    }
  };

  componentDidMount() {
    const { formProps } = this.props;
    formProps.setFieldValue(
      "payroll.deductions",
      formProps.values.employeeActive.deductions
    );
  }

  componentWillReceiveProps = nextProps => {
    const { formProps } = this.props;

    let b = nextProps.formProps.values.payroll.deductions || [];
    let a = formProps.values.payroll.deductions || [];
    var idsA = a.map(function(x) {
      return x.total;
    });

    var idsB = b.map(function(x) {
      return x.total;
    });
    // let compairDeductions2 = b.filter(o => !a.find(o2 => o.total !== o2.total))

    if (
      nextProps.formProps.values &&
      nextProps.formProps.values.payroll &&
      nextProps.formProps.values.payroll.deductions &&
      nextProps.formProps.values.payroll.deductions !== null &&
      formProps.values.payroll.deductions &&
      nextProps.formProps.values.payroll.deductions.length !==
        formProps.values.payroll.deductions.length
    ) {
      const newDeductions = [...nextProps.formProps.values.payroll.deductions];
      formProps.setFieldValue("payroll.deductions", newDeductions);
    }
    // if(compairDeductions){
    // 	const newDeductions = [...nextProps.formProps.values.payroll.deductions]
    // 	formProps.setFieldValue("payroll.deductions", newDeductions);
    // }
  };

  handleClickOption = () => {
    this.setState(state => ({
      ...state,
      showModal: true,
      error: {
        ...state.error,
        show: false
      }
    }));
  };

  handleCloseModal = () => {
    this.setState(state => ({
      ...state,
      showModal: false,
      error: {
        ...state.error,
        show: false
      },
      editItem: {
        isEditing: false,
        data: null,
        index: -1
      }
    }));
  };

  setValueAdd = value => {
    const valueAdd = {
      ...payrollDeductionsDefaultData,
      type: value.type,
      description: value.description,
      total: parseFloat(value.total).toFixed(2)
    };
    return valueAdd;
  };

  handleAdd = value => {
    const { formProps } = this.props;
    const { deductions } = formProps.values.payroll;
    let newDeductions = [];
    this.handleShowError(false, "");

    if (deductions && deductions.length >= 6) {
      this.handleShowError(true, messages.MESSAGE_PAYROLL_DEDUCTIONS_LIMIT);
      return;
    }

    if (deductions) {
      newDeductions = deductions;

      if (value.type === "Manual entry") {
        newDeductions.push(this.setValueAdd(value));
        formProps.setFieldValue("payroll.deductions", newDeductions);
        this.handleCloseModal();
      } else {
        if (!newDeductions.some(item => `${value.type}` === `${item.type}`)) {
          newDeductions.push(this.setValueAdd(value));
          formProps.setFieldValue("payroll.deductions", newDeductions);
          this.handleCloseModal();
        } else {
          this.handleShowError(
            true,
            messages.MESSAGE_PAYROLL_DEDUCTIONS_EXISTS
          );
        }
      }
    } else {
      newDeductions.push(this.setValueAdd(value));
      formProps.setFieldValue("payroll.deductions", newDeductions);
      this.handleCloseModal();
    }
  };

  // handleUpdate = (value) => {
  // 	const { formProps } = this.props;
  // 	const { editItem } = this.state;
  // 	const { deductions } = formProps.values.payroll;

  // 	const newDeductions = [...deductions];
  // 	newDeductions.splice(editItem.index, 1);

  // 	newDeductions.push(this.setValueAdd(value));

  // 	formProps.setFieldValue("payroll.deductions", newDeductions);
  // 	this.handleCloseModal();
  // };

  handleRemoveItem = (event, index) => {
    const { formProps } = this.props;
    const { deductions } = formProps.values.payroll;
    const newDeductions = [...deductions];
    newDeductions.splice(index, 1);

    formProps.setFieldValue("payroll.deductions", newDeductions);
  };

  handleChangeValues = (index, name, value) => {
    let { formProps } = this.props;
    const { deductions } = formProps.values.payroll;
    const newDeductions = [...deductions];
    newDeductions[index][`${name}`] = parseFloat(value);
    formProps.setFieldValue("payroll.deductions", newDeductions);
  };

  handleEditItem = (event, index) => {
    const { formProps } = this.props;
    const { deductions } = formProps.values.payroll;

    this.setState(state => ({
      ...state,
      showModal: true,
      editItem: {
        isEditing: true,
        data: deductions[index],
        index
      }
    }));
  };

  handleShowError = (show, message) => {
    this.setState(state => ({
      ...state,
      error: {
        show,
        message
      }
    }));
  };

  render() {
    const { classes, formProps, isFinishAllEmployees } = this.props;
    const { showModal, error, editItem } = this.state;
    console.log(formProps.values.payroll.deductions);
    return (
      <Grid className={classes.gridContainer}>
        <Grid container direction="row" alignItems="flex-start">
          <Grid item xs={6}>
            <Typography variant="h4" gutterBottom>
              Deductions
            </Typography>
          </Grid>
          <Grid item xs={6} className={classes.gridRight}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={this.handleClickOption}
              disabled={isFinishAllEmployees}
            >
              + Add deductions
            </Button>
            {showModal ? (
              <ModalAddDeductions
                open={showModal}
                edit={editItem.isEditing}
                data={editItem.data}
                onAddValues={
                  editItem.isEditing ? this.handleUpdate : this.handleAdd
                }
                onCloseModal={this.handleCloseModal}
              />
            ) : null}
          </Grid>
        </Grid>
        {!formProps.values.payroll.deductions ||
        formProps.values.payroll.deductions.length <= 0 ? (
          <Typography
            variant="h4"
            component="div"
            className={classes.placeHolder}
          >
            {"You haven’t added deductions"}
          </Typography>
        ) : null}
        {formProps.values.payroll.deductions &&
        formProps.values.payroll.deductions.length > 0 ? (
          <Table className={classes.table} size="medium">
            <TableHead className={classes.tableH}>
              <TableRow>
                <TableCell className={classes.tableTitle}>
                  Description
                </TableCell>
                <TableCell className={classes.tableTitle}>Total</TableCell>
                <TableCell className={classes.tableTitle} align="right" />
              </TableRow>
            </TableHead>
            <TableBody>
              {formProps.values.payroll.deductions.map((row, index) => (
                // <TableRow key={`${row.value}_${row.manualValue}`} className={classes.tableRow}>
                // 	<TableCell className={classes.tableText} component="th" scope="row">
                // 		{`${row.value} ${row.manualValue}`}
                // 	</TableCell>
                // 	<TableCell className={classes.tableText}>{row.qty}</TableCell>
                // 	<TableCell className={classes.tableText}>{`$ ${row.amount}`}</TableCell>
                // 	<TableCell className={classes.tableText}>{`$ ${row.totalAmount}`}</TableCell>
                // 	<TableCell className={classes.tableText} align="right">
                // 		<MenuItemComponent>
                // 			<MenuItem onClick={e => this.handleEditItem(e, index)}>Edit</MenuItem>
                // 			<MenuItem onClick={e => this.handleRemoveItem(e, index)}>Delete</MenuItem>
                // 		</MenuItemComponent>
                // 	</TableCell>
                // </TableRow>

                <DeductionsItemComponent
                  row={row}
                  index={index}
                  key={index}
                  parentProps={formProps}
                  handelChange={this.handelChange}
                  handleRemoveItem={this.handleRemoveItem}
                  handleChangeValues={this.handleChangeValues}
                />
              ))}
            </TableBody>
          </Table>
        ) : null}
        {error && error.show ? (
          <SnackbarCommonComponent
            variant="error"
            open={error.show}
            body={error.message}
          />
        ) : null}
      </Grid>
    );
  }
}

DeductionsComponent.propTypes = {
  formProps: PropTypes.object.isRequired
};

export default withStyles(PayrollStyle)(DeductionsComponent);
