import React, { Component } from "react";
import { connect } from "react-redux";
import clsx from "clsx";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
	Button, Grid, Paper, TextField, FormHelperText
} from "@material-ui/core";
import { Formik, Form, getIn } from "formik";
import * as Yup from "yup";

import EmployeeStyles from "./employeeStyle";

import { CardCommonComponent, SwitchCommonComponent, NumberFormatCustomComponent } from "../common";

class EmployeeEntitlementsComponent extends Component {
	validationSchema = Yup.object({
		entitlement: Yup.object().shape({
			isAnnualLeavePay: Yup.boolean(),
			annualLeavePay: Yup.number().when("isAnnualLeavePay", {
				is: true,
				then: Yup.number()
					.typeError("Invalid annual leave pay format")
					.required("Annual leave pay is required"),
				otherwise: Yup.number()
			}),
			isPersonalLeavePay: Yup.boolean(),
			personalLeavePayCarrieOver: Yup.number().when("isPersonalLeavePay", {
				is: true,
				then: Yup.number()
					.typeError("Invalid personal leave pay format")
					.required("Personal leave pay is required"),
				otherwise: Yup.number()
			}),
			isLongServiceLeave: Yup.boolean(),
			longServiceLeaveCarrieOver: Yup.number().when("isLongServiceLeave", {
				is: true,
				then: Yup.number()
					.typeError("Invalid long leave pay format")
					.required("Long leave pay is required"),
				otherwise: Yup.number()
			}),
			isAnnualLeaveLoading: Yup.boolean()
		})
	});

	constructor(props) {
		super(props);
		this.state = { isComplete: false };
	}

	handleSubmitSaveEntitlement = async (values, actions) => {
		const {
			saveEmployeeData, employerId, employeeId, parentFormProps
		} = this.props;
		actions.setSubmitting(false);
		await saveEmployeeData(employerId, employeeId, { ...parentFormProps.values });
		this.setState(prevState => ({ ...prevState, isComplete: true }));
	};

	handleOnChange = (event, fromProps, parentFormProps) => {
		fromProps.setFieldValue(event.target.id, event.target.value);
		parentFormProps.setFieldValue(event.target.id, event.target.value);
	};

	handleNumberOnChange = (event, fromProps, parentFormProps) => {
		const newValue = event.target.value;

		fromProps.setFieldValue(event.target.id, newValue);
		parentFormProps.setFieldValue(event.target.id, newValue);
	};

	checkIsComplete = (formProps) => {
		let finalResult = false;
		try {
			finalResult = this.validationSchema.isValidSync(formProps.values);
		} catch (err) {
			// console.error("Error validating entitlement as complete: ", err);
		}
		return finalResult;
	};

	renderForm = (formProps) => {
		const { classes, parentFormProps } = this.props;
		const { isComplete } = this.state;
		const isFormComplete = this.checkIsComplete(formProps);

		return (
			<CardCommonComponent title="Entitlements" complete={isFormComplete || isComplete} collapseExtend>
				<Form>
					<Grid container direction="row" alignItems="flex-start">
						<Grid item xs={4}>
							<Paper className={classes.paperGrid}>
								<SwitchCommonComponent
									id="entitlement.isAnnualLeavePay"
									title="Annual leave pay"
									checked={formProps.values.entitlement.isAnnualLeavePay}
									onChange={(event, checked) => this.handleOnChange({ ...event, target: { id: "entitlement.isAnnualLeavePay", value: checked } }, formProps, parentFormProps)}
									onBlur={formProps.handleBlur}
								/>
							</Paper>
						</Grid>
						<Grid item xs={4}>
							<Paper className={classes.paperGrid}>
								<SwitchCommonComponent
									id="entitlement.isPersonalLeavePay"
									title="Personal leave pay"
									checked={formProps.values.entitlement.isPersonalLeavePay}
									onChange={(event, checked) => this.handleOnChange({ ...event, target: { id: "entitlement.isPersonalLeavePay", value: checked } }, formProps, parentFormProps)}
									onBlur={formProps.handleBlur}
								/>
							</Paper>
						</Grid>
						<Grid item xs={4}>
							<Paper className={classes.paperGrid}>
								<SwitchCommonComponent
									id="entitlement.isLongServiceLeave"
									title="Long service leave"
									checked={formProps.values.entitlement.isLongServiceLeave}
									onChange={(event, checked) => this.handleOnChange({ ...event, target: { id: "entitlement.isLongServiceLeave", value: checked } }, formProps, parentFormProps)}
									onBlur={formProps.handleBlur}
								/>
							</Paper>
						</Grid>
						<Grid item xs={4}>
							{formProps.values.entitlement.isAnnualLeavePay ? (
								<Paper className={clsx(classes.paperGrid)}>
									<TextField
										id="entitlement.annualLeavePay"
										label="Carried over balance hours *"
										variant="filled"
										InputProps={{
											inputComponent: NumberFormatCustomComponent,
											inputProps: {
												decimalScale: 2
											}
										}}
										value={formProps.values.entitlement.annualLeavePay}
										onChange={event => this.handleNumberOnChange(event, formProps, parentFormProps)}
										onBlur={formProps.handleBlur}
										className={clsx(classes.textField, {
											error: getIn(formProps.errors, "entitlement.annualLeavePay") && getIn(formProps.touched, "entitlement.annualLeavePay")
										})}
										disabled={!formProps.values.entitlement.isAnnualLeavePay}
										fullWidth
									/>
									{getIn(formProps.errors, "entitlement.annualLeavePay") && getIn(formProps.touched, "entitlement.annualLeavePay") && <FormHelperText error>{formProps.errors.entitlement.annualLeavePay}</FormHelperText>}
								</Paper>
							) : null}
						</Grid>
						<Grid item xs={4}>
							{formProps.values.entitlement.isPersonalLeavePay ? (
								<Paper className={clsx(classes.paperGrid)}>
									<TextField
										id="entitlement.personalLeavePayCarrieOver"
										label="Carried over balance hours *"
										variant="filled"
										InputProps={{
											inputComponent: NumberFormatCustomComponent,
											inputProps: {
												decimalScale: 2
											}
										}}
										value={formProps.values.entitlement.personalLeavePayCarrieOver}
										onChange={event => this.handleNumberOnChange(event, formProps, parentFormProps)}
										onBlur={formProps.handleBlur}
										className={clsx(classes.textField, {
											error: getIn(formProps.errors, "entitlement.personalLeavePayCarrieOver") && getIn(formProps.touched, "entitlement.personalLeavePayCarrieOver")
										})}
										disabled={!formProps.values.entitlement.isPersonalLeavePay}
										fullWidth
									/>
									{getIn(formProps.errors, "entitlement.personalLeavePayCarrieOver") && getIn(formProps.touched, "entitlement.personalLeavePayCarrieOver") && <FormHelperText error>{formProps.errors.entitlement.personalLeavePayCarrieOver}</FormHelperText>}
								</Paper>
							) : null}
						</Grid>
						<Grid item xs={4}>
							{formProps.values.entitlement.isLongServiceLeave ? (
								<Paper className={clsx(classes.paperGrid)}>
									<TextField
										id="entitlement.longServiceLeaveCarrieOver"
										label="Carried over balance hours *"
										variant="filled"
										InputProps={{
											inputComponent: NumberFormatCustomComponent,
											inputProps: {
												decimalScale: 2
											}
										}}
										value={formProps.values.entitlement.longServiceLeaveCarrieOver}
										onChange={event => this.handleNumberOnChange(event, formProps, parentFormProps)}
										onBlur={formProps.handleBlur}
										className={clsx(classes.textField, {
											error: getIn(formProps.errors, "entitlement.longServiceLeaveCarrieOver") && getIn(formProps.touched, "entitlement.longServiceLeaveCarrieOver")
										})}
										disabled={!formProps.values.entitlement.isLongServiceLeave}
										fullWidth
									/>
									{getIn(formProps.errors, "entitlement.longServiceLeaveCarrieOver") && getIn(formProps.touched, "entitlement.longServiceLeaveCarrieOver") && <FormHelperText error>{formProps.errors.entitlement.longServiceLeaveCarrieOver}</FormHelperText>}
								</Paper>
							) : null}
						</Grid>
						<Grid item xs={4}>
							<Paper className={classes.paperGrid}>
								<SwitchCommonComponent
									id="entitlement.isAnnualLeaveLoading"
									title="Annual leave loading"
									checked={formProps.values.entitlement.isAnnualLeaveLoading}
									onChange={(event, checked) => this.handleOnChange({ ...event, target: { id: "entitlement.isAnnualLeaveLoading", value: checked } }, formProps, parentFormProps)}
									onBlur={formProps.handleBlur}
								/>
							</Paper>
						</Grid>
						<Grid item xs={4}>
							<Paper className={clsx(classes.paperGrid)} />
						</Grid>
						<Grid item xs={12} className={classes.gridRight}>
							<Button variant="outlined" color="secondary" onClick={formProps.handleSubmit} className={classes.buttonSaveDetails}>
								{"Save Entitlements"}
							</Button>
						</Grid>
					</Grid>
				</Form>
			</CardCommonComponent>
		);
	};

	render() {
		const { employeeData, isCasual } = this.props;

		if (isCasual === true) {
			return null;
		}

		return <Formik initialValues={{ ...employeeData }} validationSchema={this.validationSchema} onSubmit={this.handleSubmitSaveEntitlement} render={this.renderForm} enableReinitialize="true" />;
	}
}

EmployeeEntitlementsComponent.propTypes = {
	employeeData: PropTypes.object.isRequired,
	parentFormProps: PropTypes.object.isRequired,

	saveEmployeeData: PropTypes.func
};

const mapStateToProps = (state, ownProps) => ({
	employeeData: ownProps.parentFormProps.values,
	isCasual: ownProps.parentFormProps.values.payroll.status === "Casual"
});

export default connect(
	mapStateToProps,
	null
)(withStyles(EmployeeStyles)(EmployeeEntitlementsComponent));
