import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";

import { LoadingComponent } from "../components/common";
import SidebarComponent from "../components/home/sidebarComponent";
import EditEmployeesForCloseFY from "../components/setting/editEmployeesForCloseFY";
import { SessionUserStyles } from "./PagesStyle";

class EditEmployeeCloseFY extends Component {
  render() {
    const { classes, isLoading, mixpanel, history } = this.props;

    return (
      <div className={classes.root}>
        <CssBaseline />
        <SidebarComponent />
        <main className={classes.content} style={{ width: "calc(100% - 460px)", flexGrow: 0 }}>
          <LoadingComponent isLoading={isLoading} />
          <div className={classes.appBarSpacer} />
          <EditEmployeesForCloseFY mixpanel={mixpanel} history={history} />
        </main>
      </div>
    );
  }
}

EditEmployeeCloseFY.propTypes = {
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool
};

const mapStateToProps = state => ({
  isLoading: state.employees.isLoading || state.payroll.isLoading
});

export default connect(
  mapStateToProps,
  null
)(withStyles(SessionUserStyles)(EditEmployeeCloseFY));
