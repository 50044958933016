import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Formik } from "formik";
import * as Yup from "yup";

import { withStyles } from "@material-ui/core/styles";
import {
	Divider, Grid, Paper, Typography, Icon, Link
} from "@material-ui/core";

import SettingStyle from "./settingStyle";

import * as employerActions from "../../actions/EmployerActions";

import { DialogCommonComponent, Input2FACommonComponent } from "../common";

class Modal2FAConfigComponent extends Component {
	validationSchema = Yup.object({
		ElementCodeNumber1: Yup.string().required("Element Code Number1 is required"),
		ElementCodeNumber2: Yup.string().required("Element Code Number2 is required"),
		ElementCodeNumber3: Yup.string().required("Element Code Number3 is required"),
		ElementCodeNumber4: Yup.string().required("Element Code Number4 is required"),
		ElementCodeNumber5: Yup.string().required("Element Code Number5 is required"),
		ElementCodeNumber6: Yup.string().required("Element Code Number6 is required")
	});

	constructor(props) {
		super(props);

		this.state = {
			open: props.open,
			submit: false,
			isValidValidate: false
		};
	}

	componentDidMount() {
		const { employerTOPTGenerate } = this.props;
		employerTOPTGenerate();
	}

	componentWillReceiveProps = (nextProps) => {
		const { submit } = this.state;
		const { isValidValidate } = nextProps;

		if (isValidValidate && submit) {
			this.setState({ open: false, isValidValidate: false });
			nextProps.onCloseModal();
		}
	};

	handleSubmitTOPTValidate = (values, actions) => {
		const { employerTOPTValidate, history, cleanMessages } = this.props;
		cleanMessages();
		actions.setSubmitting(false);

		const code2fa = `${values.ElementCodeNumber1}${values.ElementCodeNumber2}${values.ElementCodeNumber3}${values.ElementCodeNumber4}${values.ElementCodeNumber5}${values.ElementCodeNumber6}`;
		employerTOPTValidate(code2fa, history);

		this.setState({ submit: true });
	};

	// { values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, }
	renderForm = (formProps) => {
		const {
			classes, onCloseModal, manualSetupKey, qrCodeImage, error, errorMessage
		} = this.props;

		const { open } = this.state;

		return (
			<form className={classes.container} onSubmit={formProps.handleSubmit}>
				<DialogCommonComponent title="Enable Two-Factor Authentication (2FA)" size="lg" showDivider background2FA primaryButtonName="Enable 2FA" onPrimaryButtonClick={formProps.handleSubmit} open={open} onCloseModal={onCloseModal}>
					<Grid container direction="row">
						<Grid item xs={4}>
							<Typography variant="h4" gutterBottom className="subTitle">
								{"Step 1: Configure your 2FA app"}
							</Typography>
							<Typography variant="body1" gutterBottom>
								{"Download and link an authenticator app to get a dynamic code to securely sign in."}
								<br />
								{"Scan the QR code with the app or copy the code to manually enter it in the app."}
								<br />
								<br />
								{"Below are some authenticator apps to install on your device:"}
								<br />
								{"- Google Authenticator "}
								<Link target="_blank" href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2" className={classes.link}>
									(Android)
								</Link>
								&nbsp;
								<Link target="_blank" href="https://apps.apple.com/us/app/google-authenticator/id388497605" className={classes.link}>
									(iOS)
								</Link>
								<br />
								{"- Duo Mobile "}
								<Link target="_blank" href="https://play.google.com/store/apps/details?id=com.duosecurity.duomobile" className={classes.link}>
									(Android)
								</Link>
								&nbsp;
								<Link target="_blank" href="https://apps.apple.com/us/app/duo-mobile/id422663827" className={classes.link}>
									(iOS)
								</Link>
								<br />
								{"- Authy "}
								<Link target="_blank" href="https://play.google.com/store/apps/details?id=com.authy.authy" className={classes.link}>
									(Android)
								</Link>
								&nbsp;
								<Link target="_blank" href="https://apps.apple.com/us/app/authy/id494168017" className={classes.link}>
									(iOS)
								</Link>
							</Typography>
						</Grid>
						<Grid item xs={8} className={classes.alignCenter}>
							<Typography variant="body1">Scan code with your authenticator app</Typography>
							<img className={classes.imageQR} src={qrCodeImage} alt="" />
							<Typography variant="body1">Or enter the key manually</Typography>
							<Paper className={classes.paperKey}>
								<Grid container direction="row" alignItems="center">
									<Grid item xs={1}>
										<Icon className={classes.iconKey}>vpn_key</Icon>
									</Grid>
									<Grid item xs={11}>
										<Typography variant="body1" className="gray">
											{manualSetupKey}
										</Typography>
									</Grid>
								</Grid>
							</Paper>
						</Grid>
					</Grid>
					<Divider className={classes.divider} />
					<Grid container direction="row">
						<Grid item xs={4}>
							<Typography variant="h4" gutterBottom className="subTitle">
								{"Step 2: Enter the 2FA Code"}
							</Typography>
							<Typography variant="body1" gutterBottom>
								{"Enter the 6 digits code given in the authenticator app Once linked, the authenticator app will generate a dynamic code to enter it below to confirm that you're all set up."}
							</Typography>
						</Grid>
						<Grid item xs={8} className={classes.alignCenter}>
							<Typography variant="body1" gutterBottom>
								{"Enter 6 digits code given in the app"}
							</Typography>
							<Input2FACommonComponent iconInTop={false} formik={this.totpValidateForm} formProps={formProps} error={error} errorMessage={errorMessage} submitOnChange={false} />
						</Grid>
					</Grid>
				</DialogCommonComponent>
			</form>
		);
	};

	render() {
		const { classes } = this.props;

		return (
			<Grid container justify="center" className={classes.root}>
				<Formik
					ref={(innerForm) => {
						this.totpValidateForm = innerForm;
					}}
					initialValues={{
						code2fa: "",
						ElementCodeNumber1: "",
						ElementCodeNumber2: "",
						ElementCodeNumber3: "",
						ElementCodeNumber4: "",
						ElementCodeNumber5: "",
						ElementCodeNumber6: ""
					}}
					validationSchema={this.validationSchema}
					onSubmit={this.handleSubmitTOPTValidate}
					render={this.renderForm}
				/>
			</Grid>
		);
	}
}

Modal2FAConfigComponent.propTypes = {
	manualSetupKey: PropTypes.string,
	qrCodeImage: PropTypes.string,
	error: PropTypes.bool,
	errorMessage: PropTypes.string,
	isValidValidate: PropTypes.bool,

	employerTOPTGenerate: PropTypes.func,
	employerTOPTValidate: PropTypes.func
};

const mapStateToProps = state => ({
	error: state.employer.error,
	errorMessage: state.employer.errorMessage,
	isValidValidate: state.employer.isValidValidate,

	manualSetupKey: state.employer.manualSetupKey,
	qrCodeImage: state.employer.qrCodeImage
});

const mapDispatchToProps = dispatch => ({
	employerTOPTGenerate: bindActionCreators(employerActions.employerTOPTGenerate, dispatch),
	employerTOPTValidate: bindActionCreators(employerActions.employerTOPTValidate, dispatch),
	cleanMessages: bindActionCreators(employerActions.cleanMessage, dispatch)
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(SettingStyle)(Modal2FAConfigComponent));
