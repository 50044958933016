import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import {
	Grid, IconButton, Paper, Typography
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import employerStyle from "./employerStyle";

class AbnInformationComponent extends Component {
	render() {
		const {
			classes, border, formProps, onEdit, onClose
		} = this.props;
		const ABNData = formProps.values.company.abn;

		return (
			<div>
				{ABNData.number ? (
					<Paper
						className={clsx(classes.paperContainer, {
							[classes.paperContainerBorder]: border
						})}
					>
						<Grid container direction="row" alignItems="center">
							<Grid item xs={6} className={classes.completeContent}>
								<Typography>ABN Verified</Typography>
								{ !this.props.isPopup &&
									<IconButton aria-label="Edit" className={classes.closeButton} onClick={onEdit}>
										<EditIcon />
									</IconButton>
									// :
									// !this.props.readonly &&
									// 	<IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
									// 		<CloseIcon />
									// 	</IconButton>
								}
							</Grid>
						</Grid>
						<Grid container direction="row" alignItems="center" className={classes.gridContainer}>
							<Grid item xs={2}>
								<Typography variant="subtitle2">Entity Type</Typography>
							</Grid>
							<Grid item xs={8}>
								<Typography variant="body1">{((ABNData || {}).entityType || {}).description}</Typography>
							</Grid>
						</Grid>
						{(ABNData || {}).name ? (
							<Grid container direction="row" alignItems="center" className={classes.gridContainer}>
								<Grid item xs={2}>
									<Typography variant="subtitle2">Entity Name</Typography>
								</Grid>
								<Grid item xs={8}>
									<Typography variant="body1">{(ABNData || {}).name}</Typography>
								</Grid>
							</Grid>
						) : null}
						{(ABNData || {}).userTradingName || (ABNData || {}).tradingName ? (
							<Grid container direction="row" alignItems="center" className={classes.gridContainer}>
								<Grid item xs={2}>
									<Typography variant="subtitle2">Trading Name</Typography>
								</Grid>
								<Grid item xs={8}>
									<Typography variant="body1">{(ABNData || {}).userTradingName || (ABNData || {}).tradingName}</Typography>
								</Grid>
							</Grid>
						) : null}

						{ !this.props.isPopup &&
						<>
							<br/>
							<Typography variant="body1">
								If you need to change your ABN, please contact us on 02 8084 9222 or <a href="mailto:info@easypayslip.com" target="_blank">info@easypayslip.com</a>
							</Typography>
						</>
						}
					</Paper>
				) : null}
			</div>
		);
	}
}

export default withStyles(employerStyle)(AbnInformationComponent);
