import * as actionTypes from "../constants/actionTypes";

export function generateReportSummaryRequest(reportSummary) {
	return {
		type: actionTypes.ACTION_STP_REPORT_SUMMARY_REQUEST,
		payload: {
			reportSummary
		}
	};
}

export function generateReportSummaryRequestSuccess(reportSummary, messageCode, message) {
	return {
		type: actionTypes.ACTION_STP_REPORT_SUMMARY_REQUEST_SUCCESS,
		payload: {
			reportSummary,
			messageCode,
			message
		}
	};
}

export function generateReportSummaryRequestFailure(messageCode, message) {
	return {
		type: actionTypes.ACTION_STP_REPORT_SUMMARY_REQUEST_FAILURE,
		error: true,
		payload: {
			messageCode,
			message
		}
	};
}

export function deleteSTPRequest(id) {
	return {
		type: actionTypes.ACTION_STP_DELETE_REQUEST,
		payload: {
			id,
			loading: true,
			error: false
		}
	};
}

export function deleteSTPRequestSuccess(message) {
	return {
		type: actionTypes.ACTION_STP_DELETE_REQUEST_SUCCESS,
		payload: {
			loading: false,
			message: message
		}
	};
}

export function deleteSTPRequestFailure(message) {
	return {
		type: actionTypes.ACTION_STP_DELETE_REQUEST_FAILURE,
		error: true,
		payload: {
			loading: false,
			error: true,
			message: message
		}
	};
}

export function sendSTPRequest(sendToSTP, history) {
	return {
		type: actionTypes.ACTION_STP_SEND_REQUEST,
		payload: {
			sendToSTP,
			history,
			isSendToSTP: false
		}
	};
}

export function sendSTPRequestSuccess(sendToSTP, messageCode, message) {
	return {
		type: actionTypes.ACTION_STP_SEND_REQUEST_SUCCESS,
		payload: {
			sendToSTP,
			messageCode,
			message,
			isSendToSTP: true
		}
	};
}

export function sendSTPRequestFailure(messageCode, message) {
	return {
		type: actionTypes.ACTION_STP_SEND_REQUEST_FAILURE,
		error: true,
		payload: {
			messageCode,
			message,
			isSendToSTP: false
		}
	};
}

export function setCleanDataToStore() {
	return {
		type: actionTypes.ACTION_STP_CLEAR_REQUEST,
		payload: {
			reportSummary: null
		}
	};
}

export function setCleanDataToStoreSuccess() {
	return {
		type: actionTypes.ACTION_STP_CLEAR_REQUEST_SUCCESS,
		payload: {
			reportSummary: null
		}
	};
}

export function setCleanDataToStoreFailure() {
	return {
		type: actionTypes.ACTION_STP_CLEAR_REQUEST_FAILURE,
		payload: {
			reportSummary: null
		}
	};
}
