import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import {
	Button, Grid, Paper, Typography, MenuItem, Table, TableHead, TableBody, TableCell, TableRow, Icon, IconButton
} from "@material-ui/core";
import clsx from "clsx";

import DeleteIcon from '@material-ui/icons/Delete';

import { LoadingComponent, SnackbarCommonComponent } from "../common";

import * as Yup from "yup";
// import accountsStyle from "../employer/employerStyle";
import ModalAccountant from "./modalAccountant";
import { payrollAdditionalDefaultData } from "../../reducers/Payroll/PayrollInitialState";
import * as messages from "../../constants/messages";
import * as accountantActions from "../../actions/AccountantActions";
import AvatarCommonComponent from "../common/avatarCommonComponent";
import { mapMessageCodeToIcon } from "../../helpers/extensions";
import additionalUserEmpty from "../../assets/images/employees.empty.png";
import * as routes from "../../constants/routes";
import localStorageApi from "../../api/LocalStorageApi";
import * as common from "../../constants/common";
import ModalUpdateYourDetails from "../employer/modalUpdateYourDetails";
import EmployerBusinessEntitlementComponent from "../employer/employerBusinessEntitlementComponent";
import EmployerBusinessAddressComponent from "../employer/employerBusinessAddressComponent";
import EmployerAuthorisedPersonComponent from "../employer/employerAuthorisedPersonComponent";
import EmployerContactDetailsComponent from "../employer/employerContactDetailsComponent";
import { employerUpdate, getEmployer, redirectToYourBusiness, employerUpdateBusinessInfo } from "../../actions/EmployerActions";
import { validatePostCode } from "../../helpers/extensions";
import { employerDefaultData } from "../../reducers/Employer/EmployerInitialState";
import { getTodayTime } from "../../helpers/dateextensions";
import employerStyle from "../employer/employerStyle";
import ModalSetupBusinessInfoComponent from '../employer/ModalSetupBusinessInfoComponent'
import { Formik } from "formik";

class accountCreateComponent extends Component {
	state = {
		nextSlider: false,
		messageCode: 0,
		showModalBusinessInfo: false,
		isValidation: false
	}
	validationSchema = Yup.object({
		company: Yup.object().shape({
			abn: Yup.object(),
			wpn: Yup.object(),
			address: Yup.object().shape({
				addressLine1: Yup.string()
					.ensure()
					.required("Street line 1 is required"),
				addressLine2: Yup.string(),
				postCode: Yup.string()
					.typeError("Must be an Australian valid postcode")
					.required("Postcode is required")
					.test("postcode-validation", "Must be an Australian valid postcode", validatePostCode),
				townCity: Yup.string()
					.ensure()
					.required("Town/City is required"),
				state: Yup.string().required('State/Territory is required')
			}),
			authorizedPerson: Yup.object().shape({
				firstName: Yup.string()
					.ensure()
					.required("First name is required"),
				middleName: Yup.string(),
				lastName: Yup.string()
					.ensure()
					.required("Last name is required")
			})
		}),
		contact: Yup.object().shape({
			firstName: Yup.string()
				.ensure()
				.required("First name is required"),
			middleName: Yup.string(),
			lastName: Yup.string()
				.ensure()
				.required("Last name is required"),
			phoneNumber: Yup.string().matches(/^(0011[0-9]{2}?|(\+[0-9]{2})|0)?[0-9]{9}$/, {
				message: 'Please enter a valid phone number',
				excludeEmptyString: true
			})
				.ensure()
				.required("Contact number is required"),
			email: Yup.string()
				.email()
				.ensure()
			    .required("Email address is required")
		})
	});

	form = React.createRef();

	componentDidMount() {
        const { businessInfo } = this.props;

		this.form.current.validateForm();
		this.setState({
			showModalBusinessInfo: businessInfo
		})
	}
	isValidation = (isValidation) => {
		this.setState({
			isValidation
		})
	}
	handleSubmitSave = (values, actions) => {
		const { createEmployer } = this.props;
		actions.setSubmitting(false);

        createEmployer({ ...values }, this.props.history);

		this.setState({
			isValidation: false
		})
	};

	componentWillMount = () => {
		const { } = this.props
		redirectToYourBusiness(false)
	}
	handelCloseBusinessInfoModal = () => {

		this.setState({
			showModalBusinessInfo: false
		})
    }

	renderForm = (formProps) => {
		const { classes, employer, isLoading, message, messageCode, cleanMessageInternal } = this.props;
		const logo = (employer.company || {}).logo ? `${(employer.company || {}).logo}?${getTodayTime()}` : "";

		return (
			<div className={classes.root}>
				<Paper className={classes.paper}>
					{/* <Grid container alignItems="center">
						{!employer.initials || employer.initials.length <= 0 ? <AvatarCommonComponent text="YB" src={logo} employerId={(employer || {}).id} isBig hover /> :
							<AvatarCommonComponent hover isBig src={logo} text={employer.initials} employerId={(employer || {}).id} />}
						{!employer.companyName || employer.companyName.length <= 0 ? <Typography variant="h1">Your business</Typography> : null}
						<Typography component="h2" variant="h1">
							{employer.companyName}
						</Typography>
					</Grid> */}
                    <Typography component="h2" variant="h1">
						Add Employer Account
                    </Typography>

					<Grid container alignItems="center" justify="flex-end">
						<Button
							variant="contained"
							color="secondary"
							disabled={!formProps.isValid}
							className={clsx(classes.buttonFinish, classes.orangeRectangularBtn, { [classes.disabledButtonSecondary]: !formProps.isValid })}
							onClick={formProps.handleSubmit}
						>
							{"Create"}
						</Button>
					</Grid>

					<EmployerBusinessEntitlementComponent formProps={formProps} {...this.props} />
					<EmployerBusinessAddressComponent formProps={formProps} {...this.props} />
					<EmployerAuthorisedPersonComponent formProps={formProps} {...this.props} />
					<EmployerContactDetailsComponent formProps={formProps} {...this.props} isAccountant />

					<Grid container alignItems="center" justify="flex-end">
						<Button
							variant="contained"
							color="secondary"
							disabled={!formProps.isValid}
							className={clsx(classes.buttonFinish, classes.orangeRectangularBtn, { [classes.disabledButtonSecondary]: !formProps.isValid })}
							onClick={formProps.handleSubmit}
						>
							{"Create"}
						</Button>
						{/* <Button variant="contained" color="primary" disabled={!formProps.isValid} onClick={formProps.handleSubmit}>
							Save
						</Button> */}
					</Grid>
				</Paper>

                <LoadingComponent isLoading={isLoading} />
                <SnackbarCommonComponent key="SnackBarAccountantMessages" variant={messageCode === 200 ? "" : mapMessageCodeToIcon(messageCode)} open={(!!message)} body={message} onCloseModal={cleanMessageInternal} />

				<ModalUpdateYourDetails />
				{this.state.showModalBusinessInfo && <ModalSetupBusinessInfoComponent
					open={this.state.showModalBusinessInfo} handelCloseBusinessInfoModal={this.handelCloseBusinessInfoModal}
					isValidation={this.isValidation} handleSubmitSave={this.handleSubmitSaveBusinessInfo}
					onPrimaryButtonClick={this.handlePrimaryClickModal} onCloseModal={this.handleCloseModalBusinessInfo} formProps={formProps} {...this.props} />}
				{/* {true && <ModalSetupBusinessInfoComponent open={true} handelCloseBusinessInfoModal={this.handelCloseBusinessInfoModal} isValidation={this.isValidation} handleSubmitSave={this.handleSubmitSaveBusinessInfo} onPrimaryButtonClick={this.handlePrimaryClickModal} onCloseModal={this.handleCloseModalBusinessInfo} formProps={formProps} {...this.props} />} */}
			</div>
		);
	};

	render() {
        const { employer, businessInfo } = this.props;

		const employerData = {
			isValidation: this.isValidation,
			...employerDefaultData,
			// ...employer,

			businessInfo,
			company: {
				...employerDefaultData.company,
				// ...employer.company,
				abn: {
					...employerDefaultData.company.abn,
					// ...employer.company.abn,
					entityType: {
						...employerDefaultData.company.abn.entityType,
						// ...employer.company.abn.entityType
					}
				},
				wpn: {
					...employerDefaultData.company.wpn,
					// ...employer.company.wpn
				},
				address: {
					...employerDefaultData.company.address,
					// ...employer.company.address
				},
				authorizedPerson: {
					...employerDefaultData.company.authorizedPerson,
					// ...employer.company.authorizedPerson
				}
			},
			contact: {
				...employerDefaultData.contact,
				// ...employer.contact,
			}
		};

		return <Formik initialValues={{ ...employerData }} validationSchema={this.state.isValidation || this.validationSchema} onSubmit={this.handleSubmitSave} render={this.renderForm} ref={this.form} enableReinitialize="true" />;
	}
}

accountCreateComponent.propTypes = {
	formProps: PropTypes.object.isRequired,
	getListLinkedEmployerAccounts: PropTypes.func,
	linkedEmployerAccountsRevoke: PropTypes.func,
	loginAsLinkedEmployer: PropTypes.func,
	messageCode: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),
	message: PropTypes.string,
	errorMessage: PropTypes.string,
	employersAccountant: PropTypes.array,
	isSaving: PropTypes.bool,
	isLoading: PropTypes.bool
};

const mapStateToProps = state => ({
    employer: state.employer.employerData,
	isLoading: state.employersAccountant.isLoading,
	messageCode: state.employersAccountant.messageCode,
	errorMessage: state.employersAccountant.errorMessage,
	message: state.employersAccountant.message,
	employersAccountant: state.employersAccountant.employersAccountant,
	isSaving: state.employersAccountant.isSaving,
});

const mapDispatchToProps = dispatch => ({
    createEmployer: bindActionCreators(accountantActions.createEmployer, dispatch),
	getListLinkedEmployerAccounts: bindActionCreators(accountantActions.getListLinkedEmployerAccounts, dispatch),
	linkedEmployerAccountsRevoke: bindActionCreators(accountantActions.linkedEmployerAccountsRevoke, dispatch),
	loginAsLinkedEmployer: bindActionCreators(accountantActions.loginAsLinkedEmployer, dispatch),
    cleanMessageInternal: bindActionCreators(accountantActions.cleanMessage, dispatch),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(withStyles(employerStyle)(accountCreateComponent)));
