import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import PropTypes from "prop-types";

import { Formik } from "formik";
import * as Yup from "yup";

import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import {
  Box,
  Typography,
  Grid,
  Paper,
  TextField,
  Icon
} from "@material-ui/core";

import SettingStyle from "./settingStyle";
import lockImage from "../../assets/images/lock.png";

import * as routes from "../../constants/routes";
import * as yecpAction from "../../actions/YECPActions";
import * as employerActions from "../../actions/EmployerActions";

import { DialogCommonComponent } from "../common";

class ModalSettingDeclaration extends Component {
  state = {
    openCongratulation: false,
    showPopup: false,
    signature: "",
    isValid: false
  };

  handleSubmit = () => {
    const { sendCloseYFSignatureRequest, yecp } = this.props;

    sendCloseYFSignatureRequest(yecp, { signature: this.state.signature });
  };

  redirectToPayroll = () => {
    const { history } = this.props;
    this.setState({ openCongratulation: false });

    history.push(routes.PATH_PAYROLL);
  };

  componentWillReceiveProps = nextProps => {
    const {
      isSendToSTP,
      authorizedName,
      getAuthorizedPerson,
      signature,
      getSignature
    } = nextProps;

    getSignature();

    const { showPopup } = this.state;
    if (this.state.authorizedName != signature) {
      this.setState({
        authorizedName: signature
      });
    }
  };
  handleGetAuthorizedname = () => {
    const { getSignature, signature } = this.props;
    if (signature === "") {
      getSignature();
    } else {
      this.setState({
        signature,
        isValid: true
      });
    }
  };
  handleChangeSignature = e => {
    this.setState(
      {
        signature: e.target.value
      },
      () => {
        if (this.state.signature.length > 2) {
          this.setState({
            isValid: true
          });
        } else {
          this.setState({
            isValid: false
          });
        }
      }
    );
  };
  renderForm = formProps => {
    const { classes, open, onCloseModal, employer, authorizedName } = this.props;
    const { openCongratulation, signature } = this.state;
    return (
      <DialogCommonComponent
        title="STP Declaration"
        styleTitle={{
          textAlign: "left",
          fontFamily: "AvenirNextLTPro-Demi",
          fontSize: 17,
          color: "#000000",
          opacity: 1,
          letterSpacing: "0.02px"
        }}
        style2={{
          textAlign: "center",
          letterSpacing: 0,
          color: "#ffffff",
          opacity: 1,
          fontSize: "15px",
          fontWeight: "bold",
          fontFamily: "AvenirNextLTPro-Demi"
        }}
        style={{ borderRadius: 5 }}
        size="lg"
        showDivider
        primaryButtonName="Lodge STP with ATO"
        onPrimaryButtonClick={this.handleSubmit}
        open={open}
        onCloseModal={onCloseModal}
        primaryButtonDisabled={!this.state.isValid}
      >
        <Grid container direction="row" className={classes.signatureContainer}>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              gutterBottom
              className={classes.signatureDescriptionText}
            >
              {/* {"EASY PAYSLIP PTY LTD"} */}
							{employer.company.abn.name ? employer.company.abn.name : employer.companyName}
							<br />
							{`${employer.company.address.addressLine1}, ${employer.company.address.townCity}, ${employer.company.address.state} ${employer.company.address.postCode}`}
							{/* {"Suite 7/701/ Military Road, Mosman NSW 2088"} */}
							<br />
							<br />
							{`I, ${authorizedName || employer.company.authorizedPerson.fullName} declare the information transmitted in this payroll report is true and correct and I am authorised to make this declaration`}
              <br />
              <br />

              {
                "By entering my name below and clicking the 'Lodge STP File with ATO' button, I authorise lodgement with Easy Payslip's"
              }
              <br />
              {"approved sending service provider's Auskey."}
            </Typography>
          </Grid>
          <Box m={2} />
          <Grid container direction="row" alignItems="center" justify="center">
            <Grid item xs={2}>
              <Typography variant="body1" className={classes.signatureText}>
                {"Signature"}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Paper className={clsx(classes.paperGridNotPadding)}>
                <TextField
                  id="signature"
                  label="Type your name here"
                  className={clsx(classes.textField, {
                    error:
                      formProps.errors.signature && formProps.touched.signature
                  })}
                  variant="filled"
                  fullWidth
                  value={signature}
                  onChange={this.handleChangeSignature}
                  onBlur={formProps.handleBlur}
                />
              </Paper>
            </Grid>
            <Grid item xs={1}>
              {!this.state.isValid ? (
                <img className={classes.iconInTop} src={lockImage} alt="" />
              ) : (
                <Icon
                  className={clsx(classes.checkIcon, {
                    active: this.state.isValid
                  })}
                >
                  {this.state.isValid ? "check_circle" : "cancel"}
                </Icon>
              )}
            </Grid>
          </Grid>
        </Grid>

        {openCongratulation ? (
          <DialogCommonComponent
            title="Send to STP"
            size="xs"
            open={openCongratulation}
            onCloseModal={this.redirectToPayroll}
            primaryButtonName="Ok"
            onPrimaryButtonClick={this.redirectToPayroll}
          >
            <Typography variant="h2">STP file sent.</Typography>
          </DialogCommonComponent>
        ) : null}
      </DialogCommonComponent>
    );
  };

  render() {
    return (
      <Formik
        initialValues={{ signature: "" }}
        validationSchema={this.validationSchema}
        onSubmit={this.handleSubmitSTP}
        render={this.renderForm}
        enableReinitialize
      />
    );
  }
}

ModalSettingDeclaration.propTypes = {
  employer: PropTypes.object,
  stpSummary: PropTypes.object
};

const mapStateToProps = state => {
  console.log(state);

  return {
    authorizedName: state.session.authorizedName
      ? state.session.authorizedName
      : state.session.userData.company
      ? (state.session.userData.company.authorizedPerson || {}).fullName
      : "",
    employer: state.session.userData,
    stpSummary: state.stp.reportSummary,
    isSendToSTP: state.stp.isSendToSTP,
    signature: state.yecp.signature
  };
};

const mapDispatchToProps = dispatch => ({
  getAuthorizedPerson: bindActionCreators(
    employerActions.getAuthorizedPerson,
    dispatch
  ),
  getSignature: bindActionCreators(
    yecpAction.getAuthorizedNameSignatureRequest,
    dispatch
  ),
  sendCloseYFSignatureRequest: bindActionCreators(
    yecpAction.sendCloseYFSignatureRequest,
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(SettingStyle)(ModalSettingDeclaration)));
