import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import { Divider, Grid, Paper, Typography, IconButton } from "@material-ui/core";
import Moment from "react-moment";
import * as singleTouchActions from "../../actions/SingleTouchActions";
import singleTouchStyle from "./singleTouchStyle";
import ModalSingleTouch from "./modalSingleTouch";
import STPOutstandingEmpty from "../../assets/images/STPOutstanding.empty.png";
import * as stpActions from "../../actions/STPActions";
import ModalSTPSummaryComponent from "./modalSTPSummaryComponent";
import { Analytics } from "../common";
import { getUTCToLocal, getLocalToUTC } from "./../../helpers/dateextensions";
import DeleteIcon from '@material-ui/icons/Delete';
import ModalRemoveSTP from "./modalRemoveSTP";
import { SnackbarCommonComponent } from "../common";

class SingleTouchOutstandingComponent extends Component {
  state = {
    showModalST: false,
    selectedST: {},
    stpData: "",
    showSummaryModal: false,
    showDeleteModal: false
  };

  handleClickOptionST = selectedST => {
    this.setState({
      selectedST,
      showModalST: true,
      showSummaryModal: false
    });
  };

  handleCloseModalST = () => {
    this.setState({
      showModalST: false
    });
  };

  handleDialogClick = () => {
    const { updateSTP, authorizedName } = this.props;
    const { selectedST } = this.state;
    selectedST.payPeriodEnd = getLocalToUTC(selectedST.payPeriodEnd);
    selectedST.payPeriodStart = getLocalToUTC(selectedST.payPeriodStart);
    selectedST.paymentDate = getLocalToUTC(selectedST.paymentDate);
    const STPData = JSON.parse(JSON.stringify(selectedST));
    STPData.authorizedName = authorizedName;
    updateSTP(STPData);
    this.handleCloseModalST();
  };

  handleGenerateReportSummary = stp => {
    // const { generateReportSummary } = this.props;
    // const payrolls = [...stp.payrolls];
    // debugger
    // const reportSummary = {
    // 	paymentDate: stp.paymentDate,
    // 	payPeriodStart: stp.payPeriodStart,
    // 	payPeriodEnd: stp.payPeriodEnd,
    // 	payrolls: payrolls
    // };

    // generateReportSummary(reportSummary);
    Analytics.initiateSTPAction();
    this.setState(() => ({
      stpData: stp,
      showSummaryModal: true,
      hideButton: true
    }));
  };

  handleOpenModalRemoveItem = (event, id, status) => {
		this.setState({
      showDeleteModal: true,
      deleteID: id,
      deleteSTPStatus: status
		})
	};

  handleCloseSummary = () => {
    const { clearReportSummary } = this.props;
    this.setState(() => ({
      showSummaryModal: false
    }));
    clearReportSummary();
  };

  handleCloseModalRemoveItem = () => {
    this.setState(() => ({
      showDeleteModal: false,
      showSummaryModal: false,
      showModalST: false,
      deleteID: null
    }))
  }

  handleDeleteSTP = () => {
    this.props.deleteSTP(this.state.deleteID);
    this.handleCloseModalRemoveItem();
  }

  redirectToCompletedTab = () => {
    const { redirectToCompletedTab } = this.props;
    this.setState(() => ({
      showSummaryModal: false
    }));
    redirectToCompletedTab();
  };

  render() {
    const { classes, lodgedList, invalidList, isLoading, message, error } = this.props;
    const { showModalST, selectedST, showSummaryModal, stpData, showDeleteModal, deleteSTPStatus } = this.state;

    console.log(showDeleteModal);

    return (
      <Typography component="div" className={classes.hiddenOverflow}>
        {lodgedList && lodgedList.length > 0 ? (
          <div>
            <Typography variant="h2" gutterBottom className="gray">
              <span className={clsx(classes.bullet, { yellow: true })}>• </span>
              To Be Lodged - Click File To Send
            </Typography>
            <Grid
              container
              direction="row"
              alignItems="flex-start"
              className={classes.CardTitle}
            >
              <Grid item xs={5}>
                <Paper className={clsx(classes.paperTitle, "Left")}>
                  Payroll Period
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper className={classes.paperTitle}>Payment Date</Paper>
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={2}>
                <Paper className={classes.paperTitle}>STP Lodged</Paper>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              {lodgedList.map(stp => (
                <Grid
                  item
                  xs={12}
                  onClick={() => this.handleGenerateReportSummary(stp)}
                  container
                  direction="row"
                  alignItems="flex-start"
                  className={clsx(classes.BorderCard)}
                  key={stp.id}
                >
                  <Grid item xs={5}>
                    <Paper className={clsx(classes.paperNoBorder, "Left")}>
                      <Moment utc locale="au" local={true} format="DD/MM/YYYY">
                        {stp.payPeriodStart}
                      </Moment>
                      &nbsp;-&nbsp;
                      <Moment utc locale="au" local={true} format="DD/MM/YYYY">
                        {stp.payPeriodEnd}
                      </Moment>
                    </Paper>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    style={
                      stp.isFinal
                        ? { textAlign: "center", paddingTop: 16 }
                        : { textAlign: "center" }
                    }
                  >
                    {stp.isFinal ? (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <span style={{ textAlign: "left" }}>
                          {`${stp.period}`}
                          <br />
                          Final
                        </span>
                      </div>
                    ) : (
                      <Paper className={classes.paperNoBorder}>
                        <Moment
                          utc
                          locale="au"
                          local={true}
                          format="DD/MM/YYYY"
                        >
                          {stp.paymentDate}
                        </Moment>
                      </Paper>
                    )}
                    </Grid>
                    <Grid item xs={3}/>
                    <Grid item xs={1} justify="center">
                      <Grid container justify="center">
                        <IconButton className={classes.button} aria-label="Delete"
                          color="black"
                          onClick={(event) => {
                            this.handleOpenModalRemoveItem(event, stp.id, stp.status);
                          }}
                        >
                          <DeleteIcon style={{ fontSize: 23 }} />
                        </IconButton>
                      </Grid>
                    </Grid>
                </Grid>
              ))}
            </Grid>
            {lodgedList && lodgedList.length > 0 ? (
              <Divider className={classes.BottomSpaceList} />
            ) : null}
          </div>
        ) : null}

        {invalidList && invalidList.length > 0 ? (
          <div>
            <Typography variant="h2" gutterBottom className="gray">
              <span className={clsx(classes.bullet, { orange: true })}>• </span>
              Action Required - Click File To Send
            </Typography>
            <Grid
              container
              direction="row"
              alignItems="flex-start"
              className={classes.CardTitle}
            >
              <Grid item xs={5}>
                <Paper className={clsx(classes.paperTitle, "Left")}>
                  Payroll Period
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper className={classes.paperTitle}>Payment Date</Paper>
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={2}>
                <Paper className={classes.paperTitle}>STP Lodged</Paper>
              </Grid>
            </Grid>
            <Grid container spacing={2} className={classes.BottomSpace}>
              {invalidList.map(stp => (
                <Grid
                  item
                  xs={12}
                  container
                  direction="row"
                  alignItems="flex-start"
                  className={clsx(classes.BorderCard, classes.option)}
                  onClick={() => {
                    this.handleClickOptionST(stp);
                  }}
                  key={stp.id}
                >
                  <Grid item xs={5}>
                    <Paper className={clsx(classes.paperNoBorder, "Left")}>
                      <Moment utc locale="au" local={true} format="DD/MM/YYYY">
                        {stp.payPeriodStart}
                      </Moment>
                      &nbsp;-&nbsp;
                      <Moment utc locale="au" local={true} format="DD/MM/YYYY">
                        {stp.payPeriodEnd}
                      </Moment>
                    </Paper>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    style={
                      stp.isFinal
                        ? { textAlign: "center", paddingTop: 16 }
                        : { textAlign: "center" }
                    }
                  >
                    {stp.isFinal ? (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <span style={{ textAlign: "left" }}>
                          {`${stp.period}`}
                          <br />
                          Final
                        </span>
                      </div>
                    ) : (
                      <Paper className={classes.paperNoBorder}>
                        <Moment
                          utc
                          locale="au"
                          local={true}
                          format="DD/MM/YYYY"
                        >
                          {stp.paymentDate}
                        </Moment>
                      </Paper>
                    )}
                  </Grid>
                  <Grid item xs={1} />
                  <Grid item xs={2}>
                    <Paper className={classes.paperNoBorder}>
                      <Moment utc locale="au" local={true} format="DD/MM/YYYY">
                        {stp.ato.dateSent}
                      </Moment>
                      {/* <Moment utc locale="au" local={true} format="DD/MM/YYYY">{stp.createdDate}</Moment> */}
                    </Paper>
                  </Grid>
                  <Grid item xs={1} justify="center">
                      <Grid container justify="center">
                        <IconButton className={classes.button} aria-label="Delete"
                          color="black"
                          onClick={(event) => {
                            this.handleOpenModalRemoveItem(event, stp.id, stp.status);
                          }}
                        >
                          <DeleteIcon style={{ fontSize: 23 }} />
                        </IconButton>
                      </Grid>
                    </Grid>
                </Grid>
              ))}
            </Grid>
          </div>
        ) : null}

        {(!lodgedList || lodgedList.length <= 0) &&
        (!invalidList || invalidList.length <= 0) &&
        !isLoading ? (
          <div className={classes.imageEmptyContainer}>
            <img
              className={classes.imageEmptyImage}
              src={STPOutstandingEmpty}
              alt=""
            />
            <Typography variant="body1" className="gray">
              {
                "When payslips have been created they will appear here, for you to submit to the ATO"
              }
            </Typography>
          </div>
        ) : null}

        {showSummaryModal && !showDeleteModal ? (
          <ModalSTPSummaryComponent
            redirectToCompletedTab={this.redirectToCompletedTab}
            open={showSummaryModal}
            summary={stpData}
            onCloseModal={this.handleCloseSummary}
          />
        ) : null}

        <ModalSingleTouch
          open={showModalST && !showDeleteModal}
          STPReport={selectedST}
          onPrimaryButtonClick={this.handleDialogClick}
          onCloseModal={this.handleCloseModalST}
        />

        <ModalRemoveSTP
          open={showDeleteModal}
          stpStatus={deleteSTPStatus}
          onPrimaryButtonClick={this.handleDeleteSTP}
          onCloseModal={this.handleCloseModalRemoveItem}
        />

      </Typography>
    );
  }
}

SingleTouchOutstandingComponent.propTypes = {
  generateReportSummary: PropTypes.func
};

const mapStateToProps = state => ({
  STPData: state.singleTouch.STPData,
  isLoading: state.singleTouch.isLoading,
  // loading: state.s
  authorizedName: state.session.userData.company
    ? (state.session.userData.company.authorizedPerson || {}).fullName
    : ""
});

const mapDispatchToProps = dispatch => ({
  updateSTP: bindActionCreators(singleTouchActions.updateSTPRequest, dispatch),
  clearSTPData: bindActionCreators(singleTouchActions.clearSTPData, dispatch),
  generateReportSummary: bindActionCreators(
    stpActions.generateReportSummaryRequest,
    dispatch
  ),
  deleteSTP: bindActionCreators(stpActions.deleteSTPRequest, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(singleTouchStyle)(SingleTouchOutstandingComponent));
