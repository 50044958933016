import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Grid, TextField, Button } from '@material-ui/core'
import { withStyles } from "@material-ui/core/styles";
import { bindActionCreators } from "redux";
import * as CouponActions from './../../actions/CouponActions'

import { PlanStyle } from "./subscriptionStyle";

class CouponComponent extends Component {
    constructor(props) {
        super(props)
    }
    state = {
        couponName: ''
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.coupon && nextProps.coupon.name) {
            this.setState({
                couponName: nextProps.coupon.name
            })
        }

    }

    handelChange = (value) => {
        this.setState({
            couponName: value.target.value
        })
    }
    handelClick = () => {
        const { couponRequest, handelClickSendcouponName } = this.props;
        let couponName = {
            name: this.state.couponName
        };
        handelClickSendcouponName(couponName)
    }

    onKeyPress(event) {
        const { handelClickSendcouponName } = this.props;
        if (event.charCode === 13) { // enter key pressed
            event.preventDefault();
            let couponName = {
                name: this.state.couponName
            };
            handelClickSendcouponName(couponName)
        }
    }

    render() {
        const { classes, formProps, coupon } = this.props;
        return (

            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center">
                <TextField
                    id="coupon"
                    placeholder='Enter Coupon'
                    onChange={this.handelChange}
                    className={classes.textCoupon}
                    value={this.state.couponName}
                    onKeyPress={(event) => this.onKeyPress(event)}
                />
                <Button variant="contained" color="primary" className={classes.buttonCoupon} onClick={this.handelClick}>
                    {"OK"}
                </Button>
            </Grid>
        )
    }
}

export default withStyles(PlanStyle)(CouponComponent);


