import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";

import { Formik, Field } from "formik";
import * as Yup from "yup";

import {
	Button, Grid, Typography, Paper
} from "@material-ui/core";

import { KeyboardDatePicker } from "@material-ui/pickers";
import PropTypes from "prop-types";
import reportsStyles from "./reportsStyle";
import * as reportActions from "../../actions/ReportActions";
import reportTypes from "../../constants/reportTypes";
import reportsEmpty from "../../assets/images/reports.empty.png";
import EmployeesComponent from "./employeesComponent";
import DialogCommonPDFComponent from "../common/dialogCommonPDFComponent";
import { getFirstDay, getLastDay, getLocalToUTC, getUTCToLocal, getUTCDate } from "../../helpers/dateextensions";
import { CardCommonComponent } from "../common";

const DatePickerField = ({
	field, form, classes, minDate, maxDate, ...other
}) => {
	const currentError = form.errors[field.name];
	return (
		<KeyboardDatePicker
			name={field.name}
			label={field.label}
			value={getUTCToLocal(field.value)}
			format="DD/MM/YYYY"
			fullWidth
			error={Boolean(currentError)}
			onError={(_, error) => form.setFieldError(field.name, error)}
			onChange={date => form.setFieldValue(field.name, date, true)}
			className={clsx(classes.textField, {
				errorDatePicker: Boolean(currentError)
			})}
			InputProps={{
				classes: {
					root: classes.keyboardDatePicker,
					input: classes.keyboardDatePickerInput
				}
			}}
			minDate={minDate}
			maxDate={maxDate}
			{...other}
		/>
	);
};

class ReportPayrollComponentDetail extends Component {
	validationSchema = Yup.object({
		startDate: Yup.date().required("Start Date is required"),
		finishDate: Yup.date()
			.required("Finish Date is required")
			.when("startDate", (startDate, schema) => startDate && schema.min(startDate, "The start date must be less than the end date.")),
		employeeIds: Yup.array().required("Selected employees")
	});

	state = {
		dialogOpen: false
	};

	componentWillReceiveProps = (nextProps) => {
		if ((nextProps.reportData || {}).urlReport) {
			this.setState({ dialogOpen: true });
		}
	};

	handleSubmitReport = async (values, actions) => {
		const { processReportPayrollActivityDetail } = this.props;

		actions.setSubmitting(false);
		values.finishDate = getLocalToUTC(values.finishDate)
		values.startDate = getLocalToUTC(values.startDate)

		await processReportPayrollActivityDetail({
			typeReport: reportTypes.PayrollActivityDetail,
			payrollActivityDetail: {
				startDate: values.startDate,
				endDate: values.finishDate,
				idsEmployees: values.employeeIds
			}
		});
	};

	handleDialogClose = () => {
		const { clearReportData } = this.props;
		this.setState({ dialogOpen: false });
		clearReportData({});
	};

	renderForm = (formProps) => {
		const {
			classes, employees, reportData, isLoading
		} = this.props;
		const { dialogOpen } = this.state;

		console.log('report data');
		console.log(reportData);
		let dates, fnameStart, fnameEnd;
		if (reportData && reportData.payrollActivity) {
			dates = reportData.payrollActivity;
		} else if (reportData && reportData.payrollActivityDetail) {
			dates = reportData.payrollActivityDetail;
		}

		if (dates) {
			fnameStart = dates.startDate.split("T")[0].split("-").reverse().join("");
			fnameEnd = dates.endDate.split("T")[0].split("-").reverse().join("");
		}

		return (
			<form className={classes.formContainer}>
				<Typography component="div">
					<Grid container alignItems="center" className={classes.gridContainer}>
						<Grid item xs={12} className={classes.gridRight}>
							<Button
								variant="contained"
								color="secondary"
								disabled={!formProps.isValid || isLoading}
								className={clsx(classes.buttonFinish, classes.gridRightClearButton, classes.orangeRectangularBtn, { [classes.disabledButtonSecondary]: !formProps.isValid || isLoading })}
								onClick={formProps.handleSubmit}
							>
								{"Process Report"}
							</Button>
						</Grid>
					</Grid>
					{(!employees || employees.length <= 0) && !isLoading ? (
						<div className={classes.imageEmpty}>
							<img className={classes.imageEmptyImage} src={reportsEmpty} alt="" />
						</div>
					) : null}
					{employees && employees.length > 0 ? (
						<div>
							<CardCommonComponent title="Payment Dates Between" complete={false} hideExpandCheck>
								<Grid container alignItems="flex-start">
									<Grid item xs={3}>
										<Paper className={classes.paperGrid}>
											<Field name="startDate" label="Start Date" component={DatePickerField} classes={classes} />
										</Paper>
									</Grid>
									<Grid item xs={3}>
										<Paper className={classes.paperGrid}>
											<Field name="finishDate" label="Finish Date" component={DatePickerField} classes={classes} />
										</Paper>
									</Grid>
								</Grid>
							</CardCommonComponent>
							<EmployeesComponent employees={employees} formProps={formProps} name="employeeIds" />
						</div>
					) : null}

					{reportData && reportData.urlReport ? <DialogCommonPDFComponent key="reportPayroll" title={reportData.typeReport == 6 ? `PADR_${fnameStart}_${fnameEnd}` : `PASR_${fnameStart}_${fnameEnd}`} open={dialogOpen} onCloseModal={this.handleDialogClose} url={reportData.urlReport} /> : null}
					<Grid container alignItems="center" className={classes.gridContainer}>
						<Grid item xs={12} className={classes.gridRight}>
							<Button
								variant="contained"
								color="secondary"
								disabled={!formProps.isValid || isLoading}
								className={clsx(classes.buttonFinish, classes.gridRightClearButton, classes.orangeRectangularBtn, { [classes.disabledButtonSecondary]: !formProps.isValid || isLoading })}
								onClick={formProps.handleSubmit}
							>
								{"Process Report"}
							</Button>
						</Grid>
					</Grid>
				</Typography>
			</form>
		);
	};

	render() {
		const { classes } = this.props;

		const dataReport = {
			startDate: getFirstDay(),
			finishDate: getLastDay(),
			employeeIds: []
		};

		return (
			<Grid container className={classes.root}>
				<Formik initialValues={{ ...dataReport }} validationSchema={this.validationSchema} onSubmit={this.handleSubmitReport} render={this.renderForm} enableReinitialize />
			</Grid>
		);
	}
}

ReportPayrollComponentDetail.propTypes = {
	employees: PropTypes.array,
	processReportPayrollActivityDetail: PropTypes.func
};

const mapStateToProps = state => ({
	reportData: state.reports.reportData,
	isLoading: state.reports.isLoading || state.employees.isLoading
});

const mapDispatchToProps = dispatch => ({
	processReportPayrollActivityDetail: bindActionCreators(reportActions.processReportPayrollActivityDetailRequest, dispatch),
	clearReportData: bindActionCreators(reportActions.clearReportData, dispatch)
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(reportsStyles)(ReportPayrollComponentDetail));

