import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Formik } from "formik";
import * as Yup from "yup";

import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";

import {
	Grid, Paper, TextField, InputAdornment
} from "@material-ui/core";
import PayrollStyle from "./payrollStyle";

import * as parameterTypes from "../../constants/parameterTypes";

import { DialogCommonComponent, NumberFormatCustomComponent } from "../common";
import { payrollAdditionalDefaultData } from "../../reducers/Payroll/PayrollInitialState";

class ModalAddBaseHourlyRate extends Component {
	validationSchema = Yup.object({
		name: Yup.string()
			.required("Selected Additional base rate")
			.test("name-default", "Selected Additional base rate", value => value !== "-"),
		rate: Yup.number().required("Rate is required"),
		hours: Yup.number().required("Quantity is required"),
		totalAmount: Yup.number().required("Total is required")
	});

	renderAdditionalOptions = () => {
		const { payrollBaseRateAdditionalList, classes } = this.props;
		const values = [
			<option key="option_0" value="-" className={classes.option}>
				Select option
			</option>
		];
		values.push(
			payrollBaseRateAdditionalList
				.sort((firstEl, secondEl) => firstEl.order < secondEl.order)
				.map(item => (
					<option key={`${item.id}`} value={`${item.value}`} className={classes.option}>
						{item.value}
					</option>
				))
		);

		return values;
	};

	handleSubmitBaseHourly = (value, actions) => {
		const { onAddValues } = this.props;
		actions.setSubmitting(false);
		onAddValues(value);
	};

	handleChangeValues = (name, event, formProps, nameTotal) => {
		const { value } = event.target;
		formProps.setFieldValue(name, value);
		const total = parseFloat(value * (name === "rate" ? formProps.values.hours : formProps.values.rate)).toFixed(2);
		formProps.setFieldValue(nameTotal, isNaN(total) ? 0 : total);
	};

	renderForm = (formProps) => {
		const {
			classes, open, onCloseModal, edit
		} = this.props;

		return (
			<DialogCommonComponent title="Additional base hourly rate" primaryButtonName={edit ? "Update" : "Save"} size="xs" onPrimaryButtonClick={formProps.handleSubmit} open={open} onCloseModal={onCloseModal}>
				<Grid container direction="row" alignItems="center">
					<Grid item xs={12}>
						<Paper className={classes.paperGrid}>
							<TextField
								id="name"
								label="Additional base hourly rate *"
								select
								variant="filled"
								fullWidth
								disabled={edit}
								value={formProps.values.name}
								onChange={formProps.handleChange}
								onBlur={formProps.handleBlur}
								className={clsx(classes.textField, {
									error: formProps.errors.name && formProps.touched.name
								})}
								SelectProps={{
									native: true,
									MenuProps: {
										className: classes.menu
									}
								}}
							>
								{this.renderAdditionalOptions()}
							</TextField>
						</Paper>
					</Grid>
					<Grid item xs={12}>
						<Paper className={classes.paperGrid}>
							<TextField
								id="description"
								label="Description"
								variant="filled"
								fullWidth
								value={formProps.values.description}
								onChange={(value) => {
									this.handleChangeValues("description", value, formProps);
								}}
								onBlur={formProps.handleBlur}
								className={clsx(classes.textField, {
									error: formProps.errors.description && formProps.touched.description
								})}
							/>
						</Paper>
					</Grid>
					<Grid item xs={6}>
						<Paper className={classes.paperGrid}>
							<TextField
								id="rate"
								label="Rate *"
								InputProps={{
									startAdornment: <InputAdornment position="start">$</InputAdornment>,
									inputComponent: NumberFormatCustomComponent,
									inputProps: {
										decimalScale: 4
									}
								}}
								variant="filled"
								fullWidth
								value={formProps.values.rate}
								onChange={(value) => {
									this.handleChangeValues("rate", value, formProps, "totalAmount");
								}}
								onBlur={formProps.handleBlur}
								className={clsx(classes.textField, {
									error: formProps.errors.rate && formProps.touched.rate
								})}
							/>
						</Paper>
					</Grid>
					<Grid item xs={6}>
						<Paper className={classes.paperGrid}>
							<TextField
								id="hours"
								label="Hours *"
								variant="filled"
								fullWidth
								InputProps={{
									inputComponent: NumberFormatCustomComponent,
									inputProps: {
										decimalScale: 2
									}
								}}
								value={formProps.values.hours}
								onChange={(value) => {
									this.handleChangeValues("hours", value, formProps, "totalAmount");
								}}
								onBlur={formProps.handleBlur}
								className={clsx(classes.textField, {
									error: formProps.errors.hours && formProps.touched.hours
								})}
							/>
						</Paper>
					</Grid>
					<Grid item xs={12}>
						<Paper className={classes.paperGrid}>
							<TextField
								id="totalAmount"
								label="Total *"
								fullWidth
								InputProps={{
									startAdornment: <InputAdornment position="start">$</InputAdornment>,
									readOnly: true,
									inputComponent: NumberFormatCustomComponent,
									inputProps: {
										decimalScale: 4
									}
								}}
								value={formProps.values.totalAmount}
								onChange={formProps.handleChange}
								onBlur={formProps.handleBlur}
								className={clsx("onlyReadTitle", {
									error: formProps.errors.totalAmount && formProps.touched.totalAmount
								})}
							/>
						</Paper>
					</Grid>
				</Grid>
			</DialogCommonComponent>
		);
	};

	render() {
		const { data } = this.props;

		const dataDefault = {
			...payrollAdditionalDefaultData,
			...data
		};

		return <Formik initialValues={{ ...dataDefault }} validationSchema={this.validationSchema} onSubmit={this.handleSubmitBaseHourly} render={this.renderForm} enableReinitialize />;
	}
}

ModalAddBaseHourlyRate.propTypes = {
	payrollBaseRateAdditionalList: PropTypes.array,

	onAddValues: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
	payrollBaseRateAdditionalList: state.parameters.parameterByTypeData[parameterTypes.PAYROLL_BASE_RATE_ADDITIONAL.parameterType]
});

export default connect(
	mapStateToProps,
	null
)(withStyles(PayrollStyle)(ModalAddBaseHourlyRate));
