import { createLogic } from "redux-logic";

import * as actionTypes from "../constants/actionTypes";
import * as apiResponses from "../constants/apiResponses";
import * as EasyPaySlipPtyActions from "../actions/EasyPaySlipPtyActions";
import * as sessionActions from "../actions/SessionActions";
import easyPaySlipPtyApi from "../api/EasyPaySlipPtyApi";
import commonApi from "../api/CommonApi";
import * as messages from "../constants/messages";
import * as route from "../constants/routes";
import { Analytics } from "../components/common";

// get Accountant Details
const getAccountantDetails = createLogic({
	type: actionTypes.ACTION_ACCOUNTANT_DETAILS,
	latest: true,
	async process({ httpClient, action }, dispatch, done) {
		try {
			
			const response = await easyPaySlipPtyApi.getAccountantDetails(httpClient);
			
			if (response.status === apiResponses.RESPONSE_OK_STATUS) {
				// debugger
				console.log(response.data.accountant);
				let api=EasyPaySlipPtyActions.getAccountantDetailsSuccess(response.data.accountant, response.status, response.data.message);
				// debugger;
				let userData=api.payload.newUserData;
				// debugger
				dispatch(sessionActions.userLoggingInSuccess(userData, userData.token, userData.enabled2FA, response.status.messageCode, response.status.message,userData.accountType));
				dispatch(api);
			} else {
				
				dispatch(EasyPaySlipPtyActions.getAccountantDetailsFailure(response.status, response.data.message));
			}
		} catch (err) {
			
			dispatch(EasyPaySlipPtyActions.getAccountantDetailsFailure("", err.message));
		}
		done();
	}
});

// update Accountant Details
const updateAccountantDetails = createLogic({
	type: actionTypes.ACTION_ACCOUNTANT_DETAILS_UPDATE,
	latest: true,
	async process({ httpClient, action }, dispatch, done) {
		try {
			
			const response = await easyPaySlipPtyApi.updateAccountantDetails(httpClient, action.payload.accountantDetailsData);
			
			if (response.status === apiResponses.RESPONSE_OK_STATUS) {
				
				dispatch(EasyPaySlipPtyActions.updateAccountantDetailsSuccess(response.status, response.data.message));
				dispatch(EasyPaySlipPtyActions.getAccountantDetails());
			} else {
				
				dispatch(EasyPaySlipPtyActions.updateAccountantDetailsFailure(response.status, response.data.message));
			}
		} catch (err) {
			
			dispatch(EasyPaySlipPtyActions.updateAccountantDetailsFailure("", err.message));
		}
		done();
	}
});

export default [getAccountantDetails, updateAccountantDetails];
