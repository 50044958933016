import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import {
	Button, Grid, Paper, Typography
} from "@material-ui/core";
import { Formik, getIn } from "formik";
import * as Yup from "yup";
import { AvatarCommonComponent } from "../common";
import EasyPaySlipPtyDetailsComponent from "./easyPaySlipPtyDetailsComponent";
import { getAccountantDetails, updateAccountantDetails } from "../../actions/EasyPaySlipPtyActions";
import EasyPaySlipPtyStyle from "./easyPaySlipPtyStyle";
import { getTodayTime } from "../../helpers/dateextensions";
import {
	validatePhoneNumber
  } from "../../helpers/extensions";

class EasyPaySlipPtyRegisterComponent extends Component {
	validationSchema = Yup.object({
		login: Yup.object().shape({
			email: Yup.string().email("Must be a valid email address").required("Email is required")
		}),
		details: Yup.object().shape({
			first: Yup.string().required("First name is required"),
			middle: Yup.string(),
			title: Yup.string().default("Accountant / Bookkeeper"),
			RAN: Yup.string().notRequired(),
			jobDesc: Yup.string().when("title", { is: "Other", then: Yup.string().required("Description is required"), otherwise: Yup.string().notRequired() }),
			last: Yup.string().required("Last name is required"),
			phone: Yup.string()
				.ensure()
				.required("Contact number is required").test("aussie-phone-validation", "Please enter a valid phone number", validatePhoneNumber),
		})
	});
	form = React.createRef();
	componentDidMount() {
		const { getAccountantDetails, employer } = this.props;
		employer.accountType === "accountant" && getAccountantDetails();
		this.form.current.validateForm();
	}

	handleSubmitSave = async (values, actions) => {
		const { updateAccountantDetails } = this.props;
		let accountantDetails = {
			login: {
				email: values.login.email
			},
			details: {
				...values.details,
				RAN: values.details.RAN,
				jobDesc: values.details.title === "Other" ? values.details.jobDesc : null,
			},
			id: values.id,
			status: values.status,
		}

		await updateAccountantDetails(accountantDetails);
		this.setState(prevState => ({ ...prevState, isComplete: true }));
	};
	checkIsComplete = (formProps) => {

		let finalResult = false;
		try {
			finalResult = this.validationSchema.isValidSync(formProps.values);
		} catch (err) {
			//console.error("Error validating personal details as complete: ", err);
		}
		return finalResult;
	}

	renderForm = (formProps) => {
		const { classes } = this.props;
		const logo = ({}).logo ? `${({}).logo}?${getTodayTime()}` : "";
		const isFormComplete = this.checkIsComplete(formProps);
		const isValid = isFormComplete;

		// console.clear()
		console.log('Error validating personal details as complete', formProps.errors);

		return (
			<div className={classes.root}>
				<Paper className={classes.paperContainer}>
					<Grid container alignItems="center">
						<AvatarCommonComponent text={`${formProps.values.details.first || formProps.values.details.last ?
							`${formProps.values.details.first ? formProps.values.details.first.trim().substring(0, 1) : ""}${formProps.values.details.last ? formProps.values.details.last.trim().substring(0, 1) : ""}` : "EP"} `}
							src={logo} accountantDetailsId={({}).id} isBig hover />
						<Typography variant="h1">{formProps.values.details.first || formProps.values.details.last ? `${formProps.values.details.first} ${formProps.values.details.last}` : "Your name"}</Typography>
					</Grid>
					<Grid container alignItems="center" justify="flex-end">
						<Button variant="contained" color="primary" disabled={!isValid} onClick={() => this.handleSubmitSave(formProps.values)}>
							Save
						</Button>
					</Grid>

					<EasyPaySlipPtyDetailsComponent isComplete={isValid} formProps={formProps} {...this.props} />

					{/* <Grid container alignItems="center" justify="flex-end">
						<Button variant="contained" color="primary" disabled={!isValid} onClick={formProps.handleSubmit}>
							Save
						</Button>
					</Grid> */}
				</Paper>
			</div>
		);
	};

	render() {
		const { accountantDetails } = this.props;

		const accountantDetailsDefaultData = {
			...accountantDetails,
			details: {
				...accountantDetails.details
			},
			login: {
				...accountantDetails.login,
			},
			id: accountantDetails.id,
			status: accountantDetails.status
		};

		return <Formik initialValues={{ ...accountantDetailsDefaultData }}
			validationSchema={this.validationSchema}
			onSubmit={this.handleSubmitSave}
			render={this.renderForm}
			ref={this.form}
			enableReinitialize="true" />;
	}
}

const mapStateToProps = state => ({
	accountantDetails: state.accountantDetails.accountantDetails,
	employer: state.session.userData,
});

const mapDispatchToProps = dispatch => ({
	getAccountantDetails: bindActionCreators(getAccountantDetails, dispatch),
	updateAccountantDetails: bindActionCreators(updateAccountantDetails, dispatch)
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(EasyPaySlipPtyStyle)(EasyPaySlipPtyRegisterComponent));
