import React, { Component } from "react";
import { connect } from "react-redux";
import clsx from "clsx";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
	Button, Grid, Paper, TextField, InputAdornment, FormHelperText
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { Formik, Form, getIn } from "formik";
import * as Yup from "yup";

import EmployeeStyles from "./employeeStyle";
import { isNumber } from "../../helpers/extensions";
import * as parameterTypes from "../../constants/parameterTypes";
import { getUTCToLocal, getLocalToUTC } from "../../helpers/dateextensions";

import {
	CardCommonComponent,
	SwitchCommonComponent,
	NumberFormatCustomComponent,
	SnackbarCommonComponent
} from "../common";


class EmployeePayrollComponent extends Component {
	validationSchema = Yup.object({
		payroll: Yup.object().shape({
			jobTitle: Yup.string(),
			employmentCategory: Yup.string().required("Employment term is required"),
			employmentDate: Yup.date()
				.typeError("Invalid employment date")
				.required("Employment start date is required"),
			status: Yup.string().required("Employment status is required"),
			payType: Yup.string().required("Payment basis is required"),
			annualSalary: Yup.number().typeError("Invalid annual salary"),
			payFrequency: Yup.string().required("Pay frequency is required"),
			payrollWeek: Yup.number().default(164.67)
				.typeError("Invalid hours in payroll week")
				.required("Hours in payroll week is required"),

			hourlyRate: Yup.number()
				.typeError("Invalid hourly Rate")
				.required("Hourly Rate is required"),
			payPeriod: Yup.number()
				.typeError("Invalid hours in pay period")
				.required("Hours in pay period is required"),
			overtime: Yup.bool(),
			allowances: Yup.bool(),
			bonus: Yup.bool(),
			deductions : Yup.bool(),
			commission: Yup.bool(),
			directorsFees: Yup.bool()
		})
	});

	constructor(props) {
		super(props);
		this.state = {
			isComplete: false,
			showError: false,
			isvalidation:false
		};
		this.showError = false;
	}

	componentWillReceiveProps = (nextProps) => {
		const { employeeData } = this.props;
		const { parentFormProps } = nextProps;





		let payrollWeekValue = parseFloat(nextProps.employeeData.payroll.payrollWeek);
		let payPeriodValue = parseFloat(nextProps.employeeData.payroll.payPeriod);
		let annualSalaryValue = parseFloat(nextProps.employeeData.payroll.annualSalary);
		let payFrequencyValue = nextProps.employeeData.payroll.payFrequency;
		payPeriodValue = payrollWeekValue;

		if (nextProps.employeeData && employeeData
			&& employeeData.payroll.payType == "Salary"
			&& (employeeData.payroll.payPeriod !== nextProps.employeeData.payroll.payPeriod
			|| employeeData.payroll.payrollWeek !== nextProps.employeeData.payroll.payrollWeek
			|| employeeData.payroll.annualSalary !== nextProps.employeeData.payroll.annualSalary
			|| employeeData.payroll.payFrequency !== nextProps.employeeData.payroll.payFrequency)
		) {
			if ((!isNumber(payPeriodValue) ? 0 : payPeriodValue) !== 0) {
				let hourlyRateValue = 0;

				switch (payFrequencyValue) {
					case "Fortnightly": {
						hourlyRateValue = annualSalaryValue / 26 / payPeriodValue;
						break;
					}
					case "Monthly": {
						hourlyRateValue = annualSalaryValue / 12 / payPeriodValue;
						break;
					}
					case "Weekly":
					default: {
						hourlyRateValue = annualSalaryValue / 52 / payPeriodValue;
						break;
					}
				}
				parentFormProps.setFieldValue("payroll.hourlyRate", hourlyRateValue.toFixed(4));
			}
		}

		if (employeeData.payroll.payType == "") {
			parentFormProps.setFieldValue("payroll.payType", "Hourly");
			parentFormProps.setFieldValue("payroll.annualSalary", 0);
			parentFormProps.setFieldValue("payroll.payPeriod", 0);
			parentFormProps.setFieldValue("payroll.payrollWeek", 164.67);
		}


	};

	renderOptions = (dataList, classes) => dataList.sort((firstEl, secondEl) => firstEl.order < secondEl.order)
		.map(item => (
			<option key={`${item.id}`} value={`${item.value}`} className={classes.option}>
				{item.value}
			</option>
		));

	handleEmploymentStartDateChange = (value, setFieldValue) => {
		const { parentFormProps } = this.props;
		setFieldValue("payroll.employmentDate", value);
		parentFormProps.setFieldValue("payroll.employmentDate", value);
	};

	handleEmployementStatusChange = (event, setFieldValue) => {
		const { parentFormProps } = this.props;
		const {
			target: { value: newStatusValue }
		} = event;

		setFieldValue("payroll.status", newStatusValue);
		parentFormProps.setFieldValue("payroll.status", newStatusValue);
	};

	handleSubmitSavePayroll = async (values, actions) => {
		const {
			saveEmployeeData, employerId, employeeId, parentFormProps
		} = this.props;
		// parentFormProps.values.detail.dob=getLocalToUTC(parentFormProps.values.detail.dob)
		parentFormProps.values.payroll.employmentDate=getLocalToUTC(parentFormProps.values.payroll.employmentDate)
		actions.setSubmitting(false);
		await saveEmployeeData(employerId, employeeId, { ...parentFormProps.values });
		this.setState(prevState => ({ ...prevState, isComplete: true }));
	};

	handleOnChange = (event, fromProps, parentFormProps) => {
		fromProps.setFieldValue(event.target.id, event.target.value);
		parentFormProps.setFieldValue(event.target.id, event.target.value);
	};

	handleSetValue = (name, value, formProps, parentFormProps) => {
		formProps.setFieldValue(name, value);
		parentFormProps.setFieldValue(name, value);
	};

	handleSetPayPeriod = (payFrequency, formProps, parentFormProps) => {
		const name = formProps.values.payroll.payrollWeek !== 0 ? "payroll.payrollWeek" : "payroll.payPeriod";
		switch (payFrequency) {
			case "Fortnightly": {
				this.handleSetValue(name, "76", formProps, parentFormProps);
				break;
			}
			case "Monthly": {
				this.handleSetValue(name, "164.67", formProps, parentFormProps);
				break;
			}
			case "Weekly":
			default: {
				this.handleSetValue(name, "38", formProps, parentFormProps);
				break;
			}
		}
	};

	handleCalculableFieldChange = (event, formProps, parentFormProps) => {

		this.handleSetValue(event.target.id, event.target.value, formProps, parentFormProps);
		let annualSalaryValue = parseFloat(formProps.values.payroll.annualSalary);
		if (event.target.id === "payroll.annualSalary") {
			annualSalaryValue = parseFloat(event.target.value);
		}

		let payFrequencyValue = formProps.values.payroll.payFrequency;
		if (event.target.id === "payroll.payFrequency") {
			payFrequencyValue = event.target.value;
			this.handleSetPayPeriod(event.target.value, formProps, parentFormProps);
		}

		let payTypeValue = formProps.values.payroll.payType;
		if (event.target.id === "payroll.payType") {
			payTypeValue = event.target.value;

			if (payTypeValue === "Salary" && parseFloat(annualSalaryValue === "" ? 0 : annualSalaryValue) !== 0) {
				this.handleSetValue("payroll.payrollWeek", 0, formProps, parentFormProps);
				this.handleSetPayPeriod(payFrequencyValue, formProps, parentFormProps);
			} else if (payTypeValue === "Salary" && parseFloat(annualSalaryValue === "" ? 0 : annualSalaryValue) === 0) {
				this.handleSetValue("payroll.payFrequency", "Weekly", formProps, parentFormProps);
				this.handleSetValue("payroll.payrollWeek", 38, formProps, parentFormProps);
				this.handleSetValue("payroll.payPeriod", 0, formProps, parentFormProps);
			} else {
				this.handleSetValue("payroll.annualSalary", 0, formProps, parentFormProps);
				this.handleSetValue("payroll.payPeriod", 0, formProps, parentFormProps);
			}

			if (payTypeValue === "Per Job / Unit" && formProps.values.payroll.status !== "Casual") {
				this.handleSetValue("payroll.status", "Casual", formProps, parentFormProps);
				this.setState({ showError: true });
			}
		}
	};

	checkIsComplete = (formProps) => {
		let finalResult = false;
		try {
			finalResult = this.validationSchema.isValidSync(formProps.values);
		} catch (err) {
			// console.error("Error validating payroll as complete: ", err);
		}
		return finalResult;
	};

	handleMessageClose = () => {
		setTimeout(() => { this.setState({ showError: false }); }, 5000);
	}

	renderForm = (formProps) => {
		const {
			classes, employeeCategory, employeeStatusList, employeePaymentBasisList, employeePaymentFrequencyList, parentFormProps
		} = this.props;
		const { isComplete, showError } = this.state;
		const isFormComplete = this.checkIsComplete(formProps);


		if(this.props.edit && formProps.errors.payroll){

			if (!this.state.isvalidation) {
				this.setState(
				  {
					isvalidation: true
				  },
				  () => {
					  console.log("payroll setting false");
					this.props.handleValidation(false);
				  }
				);
			  }
		}else if(this.props.edit && !formProps.errors.payroll){
			if (this.state.isvalidation) {
				this.setState(
				  {
					isvalidation: false
				  },
				  () => {
					  console.log("payroll setting validation true");
					this.props.handleValidation(true);
				  }
				);
			  }
		}
		return (
			<CardCommonComponent title="Payroll" complete={isFormComplete || isComplete} collapseExtend>
				<Form>
					<Grid container direction="row" alignItems="flex-start">
						<Grid item xs={4}>
							<Paper className={classes.paperGrid}>
								<TextField id="payroll.jobTitle" label="Job title" variant="filled" value={formProps.values.payroll.jobTitle} onChange={event => this.handleOnChange(event, formProps, parentFormProps)} onBlur={formProps.handleBlur} className={clsx(classes.textField)} fullWidth />
							</Paper>
						</Grid>
						<Grid item xs={4}>
							<Paper className={classes.paperGrid}>
								<TextField
									id="payroll.employmentCategory"
									label="Employment term *"
									variant="filled"
									value={formProps.values.payroll.employmentCategory}
									onChange={event => this.handleOnChange(event, formProps, parentFormProps)}
									onBlur={formProps.handleBlur}
									className={clsx(classes.textField, {
										error: getIn(formProps.errors, "payroll.employmentCategory") && getIn(formProps.touched, "payroll.employmentCategory")
									})}
									SelectProps={{
										native: true,
										MenuProps: {
											className: classes.menu
										}
									}}
									select
									fullWidth
								>
									{this.renderOptions(employeeCategory, classes)}
								</TextField>
								{getIn(formProps.errors, "payroll.employmentCategory") && getIn(formProps.touched, "payroll.employmentCategory") && <FormHelperText error>{formProps.errors.payroll.employmentCategory}</FormHelperText>}
							</Paper>
						</Grid>

						<Grid item xs={4}>
							<Paper style={{marginRight:20}} className={clsx(classes.paperGrid, { [classes.paperGridClearRight]: true })}>
								<KeyboardDatePicker type="date-local"
									id="payroll.employmentDate"
									label="Employment start date *"
									format="DD/MM/YYYY"
									value={getUTCToLocal(formProps.values.payroll.employmentDate)}
									onChange={(value) => {
										this.handleEmploymentStartDateChange(value, formProps.setFieldValue);
									}}
									onBlur={formProps.handleBlur}
									fullWidth
									className={clsx(classes.textField, {
										error: getIn(formProps.errors, "payroll.employmentDate") && getIn(formProps.touched, "payroll.employmentDate")
									})}
									InputProps={{
										classes: {
											root: classes.keyboardDatePicker,
											input: classes.keyboardDatePickerInput
										}
									}}
								/>
								{getIn(formProps.errors, "payroll.employmentDate") && getIn(formProps.touched, "payroll.employmentDate") && <FormHelperText error>{formProps.errors.payroll.employmentDate}</FormHelperText>}
							</Paper>
						</Grid>
						<Grid item xs={4}>
							<Paper className={classes.paperGrid}>
								<TextField
									id="payroll.status"
									label="Employment status *"
									variant="filled"
									value={formProps.values.payroll.status}
									onChange={(event) => {
										this.handleEmployementStatusChange(event, formProps.setFieldValue);
									}}
									onBlur={formProps.handleBlur}
									className={clsx(classes.textField, {
										error: getIn(formProps.errors, "payroll.status") && getIn(formProps.touched, "payroll.status")
									})}
									SelectProps={{
										native: true,
										MenuProps: {
											className: classes.menu
										}
									}}
									InputProps={{
										inputProps: {
											readOnly: formProps.values.payroll.payType === "Per Job / Unit" ? true : false
										}
									}}
									select={formProps.values.payroll.payType === "Per Job / Unit" ? false : true}
									disabled={formProps.values.payroll.payType === "Per Job / Unit" ? true : false}
									fullWidth
								>
									{this.renderOptions(employeeStatusList, classes)}
								</TextField>
								{getIn(formProps.errors, "payroll.status") && getIn(formProps.touched, "payroll.status") && <FormHelperText error>{formProps.errors.payroll.status}</FormHelperText>}
							</Paper>
						</Grid>
						<Grid item xs={4}>
							<Paper className={classes.paperGrid}>
								<TextField
									id="payroll.payType"
									label="Payment basis *"
									variant="filled"
									value={formProps.values.payroll.payType}
									onChange={event => this.handleCalculableFieldChange(event, formProps, parentFormProps)}
									onBlur={formProps.handleBlur}
									className={clsx(classes.textField, {
										error: getIn(formProps.errors, "payroll.payType") && getIn(formProps.touched, "payroll.payType")
									})}
									SelectProps={{
										native: true,
										MenuProps: {
											className: classes.menu
										}
									}}
									select
									fullWidth
								>
									{this.renderOptions(employeePaymentBasisList, classes)}
								</TextField>
								{getIn(formProps.errors, "payroll.payType") && getIn(formProps.touched, "payroll.payType") && <FormHelperText error>{formProps.errors.payroll.payType}</FormHelperText>}
							</Paper>
						</Grid>
						<Grid item xs={4}
							style={{ display: formProps.values.payroll.payType === "Quantity" ? "block" : "none" }}
						>

						</Grid>
						<Grid item xs={4}>
							<Paper
								className={clsx(classes.paperGrid, {
									[classes.paperGridClearRight]: true
								})}
								style={{ display: formProps.values.payroll.payType === "Salary" ? "block" : "none" }}
							>
								<TextField
									id="payroll.annualSalary"
									label="Annual salary *"
									variant="filled"
									InputProps={{
										startAdornment: <InputAdornment position="start">$</InputAdornment>,
										inputComponent: NumberFormatCustomComponent,
										inputProps: {
											decimalScale: 2,
											fixedDecimalScale: true
										}
									}}
									value={formProps.values.payroll.annualSalary}

									onChange={event => this.handleCalculableFieldChange(event, formProps, parentFormProps)}
									onBlur={formProps.handleBlur}
									disabled={formProps.values.payroll.payType !== "Salary"}
									className={clsx(classes.textField, {
										error: getIn(formProps.errors, "payroll.annualSalary") && getIn(formProps.touched, "payroll.annualSalary")
									})}
									fullWidth
								/>
								{getIn(formProps.errors, "payroll.annualSalary") && getIn(formProps.touched, "payroll.annualSalary") && <FormHelperText error>{formProps.errors.payroll.annualSalary}</FormHelperText>}
							</Paper>
						</Grid>
						<Grid item xs={4}>
							<Paper className={classes.paperGrid}>
								<TextField
									id="payroll.payFrequency"
									label="Pay frequency *"
									variant="filled"
									value={formProps.values.payroll.payFrequency}
									onChange={event => this.handleCalculableFieldChange(event, formProps, parentFormProps)}
									onBlur={formProps.handleBlur}
									className={clsx(classes.textField, {
										error: getIn(formProps.errors, "payroll.payFrequency") && getIn(formProps.touched, "payroll.payFrequency")
									})}
									SelectProps={{
										native: true,
										MenuProps: {
											className: classes.menu
										}
									}}
									select
									fullWidth
								>
									{this.renderOptions(employeePaymentFrequencyList, classes)}
								</TextField>
								{getIn(formProps.errors, "payroll.payFrequency") && getIn(formProps.touched, "payroll.payFrequency") && <FormHelperText error>{formProps.errors.payroll.payFrequency}</FormHelperText>}
							</Paper>
						</Grid>
						<Grid item xs={4}

						>
							{formProps.values.payroll.payrollWeek !== 0 ? (
								<Paper className={classes.paperGrid}
									style={{ display: formProps.values.payroll.payType === "Per Job / Unit" ? "none" : "block" }}
								>
									<TextField
										id="payroll.payrollWeek"
										label="Hours in period"
										variant="filled"
										type="number"
										value={formProps.values.payroll.payrollWeek}
										onChange={event => this.handleCalculableFieldChange(event, formProps, parentFormProps)}
										onBlur={formProps.handleBlur}
										className={clsx(classes.textField, {
											error: getIn(formProps.errors, "payroll.payrollWeek") && getIn(formProps.touched, "payroll.payrollWeek")
										})}
										fullWidth
									/>
									{getIn(formProps.errors, "payroll.payrollWeek") && getIn(formProps.touched, "payroll.payrollWeek") && <FormHelperText error>{formProps.errors.payroll.payrollWeek}</FormHelperText>}
								</Paper>
							) : (
									<Paper className={classes.paperGrid}
										style={{ display: formProps.values.payroll.payType === "Per Job / Unit" ? "none" : "block" }}
									>
										<TextField
											id="payroll.payPeriod"
											label="Hours in period"
											variant="filled"
											type="number"
											value={formProps.values.payroll.payPeriod}
											onChange={event => this.handleCalculableFieldChange(event, formProps, parentFormProps)}
											onBlur={formProps.handleBlur}
											className={clsx(classes.textField, {
												error: getIn(formProps.errors, "payroll.payPeriod") && getIn(formProps.touched, "payroll.payPeriod")
											})}
											fullWidth
										/>
										{getIn(formProps.errors, "payroll.payPeriod") && getIn(formProps.touched, "payroll.payPeriod") && <FormHelperText error>{formProps.errors.payroll.payPeriod}</FormHelperText>}
									</Paper>
								)}
						</Grid>
						<Grid item xs={4}
							style={{ display: formProps.values.payroll.payType === "Quantity" ? "block" : "none" }}
						>

						</Grid>

						<Grid item xs={4}
							style={{ display: formProps.values.payroll.payType === "Quantity" ? "block" : "none" }}
						>

						</Grid>

						<Grid item xs={4}

						>
							<Paper className={classes.paperGrid}
								style={{ display: formProps.values.payroll.payType === "Per Job / Unit" ? "none" : "block" }}
							>
								<TextField
									id="payroll.hourlyRate"
									label={formProps.values.payroll.payType === "Salary" ? "" : "Hourly rate *"}
									variant={formProps.values.payroll.payType === "Salary" ? "standard" : "filled"}
									type="number"
									value={formProps.values.payroll.hourlyRate}
									className={clsx(classes.onlyReadTitle,classes.textField, {
										error: getIn(formProps.errors, "payroll.hourlyRate") && getIn(formProps.touched, "payroll.hourlyRate"),
										onlyRead: !!(formProps.values.payroll.payType === "Salary")
									})}
									InputProps={{
										startAdornment: <InputAdornment position="start">$</InputAdornment>,
										readOnly: !!(formProps.values.payroll.payType === "Salary")
									}}
									onChange={event => this.handleOnChange(event, formProps, parentFormProps)}
									onBlur={formProps.handleBlur}
									fullWidth
								/>
								{getIn(formProps.errors, "payroll.hourlyRate") && getIn(formProps.touched, "payroll.hourlyRate") && <FormHelperText error>{formProps.errors.payroll.hourlyRate}</FormHelperText>}
							</Paper>
						</Grid>
						<Grid item xs={4}>
							<Paper className={classes.paperGrid}>
								<SwitchCommonComponent
									id="payroll.overtime"
									title="Overtime"
									checked={formProps.values.payroll.overtime}
									onChange={(event, checked) => this.handleOnChange({ ...event, target: { id: "payroll.overtime", value: checked } }, formProps, parentFormProps)}
									onBlur={formProps.handleBlur}
								/>
							</Paper>
						</Grid>
						<Grid item xs={4}>
							<Paper className={classes.paperGrid}>
								<SwitchCommonComponent
									id="payroll.allowances"
									title="Allowances"
									checked={formProps.values.payroll.allowances}
									onChange={(event, checked) => this.handleOnChange({ ...event, target: { id: "payroll.allowances", value: checked } }, formProps, parentFormProps)}
									onBlur={formProps.handleBlur}
								/>
							</Paper>
						</Grid>
						<Grid item xs={4}>
							<Paper className={classes.paperGrid}>
								<SwitchCommonComponent
									id="payroll.deductions"
									title="Deductions"
									checked={formProps.values.payroll.deductions }
									onChange={(event, checked) => this.handleOnChange({ ...event, target: { id: "payroll.deductions", value: checked } }, formProps, parentFormProps)}
									onBlur={formProps.handleBlur}
								/>
							</Paper>
						</Grid>
						<Grid item xs={4}>
							<Paper className={classes.paperGrid}>
								<SwitchCommonComponent
									id="payroll.bonus"
									title="Bonus"
									checked={formProps.values.payroll.bonus}
									onChange={(event, checked) => this.handleOnChange({ ...event, target: { id: "payroll.bonus", value: checked } }, formProps, parentFormProps)}
									onBlur={formProps.handleBlur}
								/>
							</Paper>
						</Grid>
						<Grid item xs={4}>
							<Paper className={classes.paperGrid}>
								<SwitchCommonComponent
									id="payroll.commission"
									title="Commission"
									checked={formProps.values.payroll.commission}
									onChange={(event, checked) => this.handleOnChange({ ...event, target: { id: "payroll.commission", value: checked } }, formProps, parentFormProps)}
									onBlur={formProps.handleBlur}
								/>
							</Paper>
						</Grid>
						<Grid item xs={4}>
							<Paper className={classes.paperGrid}>
								<SwitchCommonComponent
									id="payroll.directorsFees"
									title="Directors Fees"
									checked={formProps.values.payroll.directorsFees}
									onChange={(event, checked) => this.handleOnChange({ ...event, target: { id: "payroll.directorsFees", value: checked } }, formProps, parentFormProps)}
									onBlur={formProps.handleBlur}
								/>
							</Paper>
						</Grid>
						<Grid item xs={12} className={classes.gridRight}>
							<Button
								variant="outlined"
								color="secondary"
								onClick={formProps.handleSubmit}
								className={classes.buttonSaveDetails}
							>
								{"Save Payroll"}
							</Button>
						</Grid>
					</Grid>
				</Form>
				{showError ? <SnackbarCommonComponent variant="info" onCloseModal={this.handleMessageClose} open={showError} body="Payments Per Job / Unit only applies to Casual employees" /> : null}
			</CardCommonComponent>
		);
	};

	render() {
		const { employeeData } = this.props;
		return <Formik initialValues={{ ...employeeData }} validationSchema={this.validationSchema} onSubmit={this.handleSubmitSavePayroll} render={this.renderForm} enableReinitialize="true" />;
	}
}

EmployeePayrollComponent.propTypes = {
	employeeData: PropTypes.object.isRequired,
	employeeCategory: PropTypes.array,
	employeeStatusList: PropTypes.array,
	employeePaymentBasisList: PropTypes.array,
	employeePaymentFrequencyList: PropTypes.array,
	parentFormProps: PropTypes.object.isRequired,

	saveEmployeeData: PropTypes.func
};

const mapStateToProps = (state, ownProps) => ({
	employeeData: ownProps.parentFormProps.values,
	employeeCategory: state.parameters.parameterByTypeData[parameterTypes.PARAMETER_EMPLOYEE_CATEGORY.parameterType],
	employeeStatusList: state.parameters.parameterByTypeData[parameterTypes.PARAMETER_EMPLOYEE_STATUS.parameterType],
	employeePaymentBasisList: state.parameters.parameterByTypeData[parameterTypes.PARAMETER_EMPLOYEE_PAYROLL_PAY_BASIS.parameterType],
	employeePaymentFrequencyList: state.parameters.parameterByTypeData[parameterTypes.PARAMETER_EMPLOYEE_PAYROLL_PAY_FREQUENTLY.parameterType]
});

export default connect(
	mapStateToProps,
	null
)(withStyles(EmployeeStyles)(EmployeePayrollComponent));
