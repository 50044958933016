export const COMMON_LOGIN_PLATFORM = "Web";
export const STORAGE_SESSION_NAME = "eps_session";
export const STORAGE_ALL_PARAMETERS = "eps_all_parameters";
export const ONBOARD_PARAMETERS = "onboard_parameters";

export const COMMON_EMPLOYEE_TEMP_FULLNAME = "";
export const COMMON_EMPLOYEE_TEMP_JOBTITLE = "";

export const SUBSCRIPTION_NAME = "Easy Payslip Subscription";
export const SUBSCRIPTION_UPDATE_CARD = "Update Card Details";

export const DEEP_LINK = "easypayslip://open";
