import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Formik } from "formik";
import * as Yup from "yup";

import { withStyles } from "@material-ui/core/styles";
import {
	Divider, Grid, Paper, Typography, Icon, Link
} from "@material-ui/core";

import SettingStyle from "./settingStyle";

import * as employerActions from "../../actions/EmployerActions";
import lockImage from "../../assets/images/lock.png";
import { Input2FACommonComponent } from "../common";

class Formick2FAEmailComponent extends Component {
	validationSchema = Yup.object({
		ElementCodeNumber1: Yup.string().required("Element Code Number1 is required"),
		ElementCodeNumber2: Yup.string().required("Element Code Number2 is required"),
		ElementCodeNumber3: Yup.string().required("Element Code Number3 is required"),
		ElementCodeNumber4: Yup.string().required("Element Code Number4 is required"),
		ElementCodeNumber5: Yup.string().required("Element Code Number5 is required"),
		ElementCodeNumber6: Yup.string().required("Element Code Number6 is required")
	});

	constructor(props) {
		super(props);

		this.state = {
			empty: false,
			submit: true,
			isValidValidate: false
		};
	}


	componentWillReceiveProps(nextProps) {
		if (nextProps.error && this.props.error != nextProps.error) {
			this.setState({ empty: false });
		}
	}


	handleSubmitEmailValidate = (values, actions) => {
		const { employerEmailValidate, history, cleanMessages, isAccountant } = this.props;
		this.setState({ empty: true });
		cleanMessages();
		actions.setSubmitting(false);
		const code2fa = `${values.ElementCodeNumber1}${values.ElementCodeNumber2}${values.ElementCodeNumber3}${values.ElementCodeNumber4}${values.ElementCodeNumber5}${values.ElementCodeNumber6}`;
		employerEmailValidate(code2fa, history, isAccountant ? "accountant" : "employer");
	};

	// { values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, }
	renderForm = (formProps) => {
		const {
			classes, error, errorMessage
		} = this.props;
		formProps.isValid = this.state.empty;
		return (
			<form className={classes.container} onSubmit={formProps.handleSubmit}>

				<Grid container spacing={4} alignItems="center" justify="center" className={classes.containerControls}>
					<Grid item xs={12} className={classes.alignCenter}>
						<img className={classes.iconInTop} src={lockImage} alt="" />
					</Grid>
					<Grid item xs={12} className={classes.alignCenter}>
						<Input2FACommonComponent
							iconInTop={false}
							formik={this.totpValidateForm}
							formProps={formProps}
							error={false}
							errorMessage={errorMessage}
							submitOnChange={true} />
					</Grid>
				</Grid>
			</form>
		);
	};

	render() {
		return (
			<Formik
				ref={(innerForm) => {
					this.totpValidateForm = innerForm;
				}}
				initialValues={{
					code2fa: "",
					ElementCodeNumber1: "",
					ElementCodeNumber2: "",
					ElementCodeNumber3: "",
					ElementCodeNumber4: "",
					ElementCodeNumber5: "",
					ElementCodeNumber6: ""
				}}
				validationSchema={this.validationSchema}
				onSubmit={this.handleSubmitEmailValidate}
				render={this.renderForm}
			/>
		);
	}
}

Formick2FAEmailComponent.propTypes = {
	error: PropTypes.bool,
	errorMessage: PropTypes.string,
	employerEmailValidate: PropTypes.func,

	isAccountant: PropTypes.bool,
};

const mapStateToProps = state => ({
	isLoading: state.employer.isLoading,
	error: state.employer.error,
	errorMessage: state.employer.errorMessage,
	isValidValidate: state.employer.isValidValidate,

	isAccountant: state.session.isAccountant
});

const mapDispatchToProps = dispatch => ({
	employerEmailValidate: bindActionCreators(employerActions.employerEmailValidate, dispatch),
	cleanMessages: bindActionCreators(employerActions.cleanMessage, dispatch)
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(SettingStyle)(Formick2FAEmailComponent));
