import { createLogic } from "redux-logic";

import fyApi from "./../api/FYApi";
import * as fyAction from "../actions/FYActions";
import * as employerAction from "../actions/EmployerActions";
import * as actionTypes from "../constants/actionTypes";
import * as apiResponses from "../constants/apiResponses";

const updateActiveFY = createLogic({
  type: actionTypes.ACTION_FY_UPDATE_ACTIVE,
  latest: true,
  async process({ httpClient, action }, dispatch, done) {
    try {
      const response = await fyApi.updateActiveFY(
        httpClient,
        action.payload.fyname
      );

      dispatch(
        fyAction.updateActiveFYSuccess(
          response.message
        )
      );

      dispatch(employerAction.getEmployer());
      dispatch(fyAction.getAvailableFYs());
    } catch (err) {
      dispatch(
        fyAction.updateActiveFYFailure(
          err.response ? err.response.data.message : err
        )
      );
    }

    done();
  }
});

const getAvailableFYs = createLogic({
  type: actionTypes.ACTION_FY_GET_AVAILABLE,
  latest: true,
  async process({ httpClient, action }, dispatch, done) {
    try {
      const response = await fyApi.getAvailableFYs(
        httpClient
      );

      dispatch(
        fyAction.getAvailableFYsSuccess(
          response.data
        )
      );
    } catch (err) {
      dispatch(
        fyAction.getAvailableFYsFailure(
          err.response ? err.response.data.message : err
        )
      );
    }

    done();
  }
});

export default [
    getAvailableFYs,
    updateActiveFY
];
