import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Formik, getIn } from "formik";
import * as Yup from "yup";
import DeleteIcon from '@material-ui/icons/Delete';
import { withStyles } from "@material-ui/core/styles";

import {
    Grid, TableCell, TableRow, Paper, TextField, InputAdornment
} from "@material-ui/core";
import PayrollStyle from "./payrollStyle";
import clsx from "clsx";
import * as parameterTypes from "../../constants/parameterTypes";

import { NumberFormatCustomComponent } from "../common";

class EditOvertimeComponent extends Component {
    validationSchema = Yup.object({
        name: Yup.string()
            .required("Selected Additional base rate")
            .test("name-default", "Selected Additional base rate", value => value !== "-"),
        overtimeTotal: Yup.number().required("Rate is required"),
        totalAmount: Yup.number().required("Total is required")
    });
    handleChangeValues = (index, name, value, formProps, nameTotal, overtimeManualHourlyRate) => {
        let { parentProps } = this.props;
        const { overtimes } = parentProps.values.payroll;

        const valueFix = parseFloat(value).toFixed(2);
        const { baseHourly } = this.props;

        overtimes[index].overtimeTotal = value;

        formProps.setFieldValue(name, value);
        if (name === "overtimeManualHourlyRate" || overtimeManualHourlyRate) {
            overtimes[index].overtimeTotalAmount = parseFloat(valueFix * (name === "overtimeManualHourlyRate" ? formProps.values.overtimeTotal : formProps.values.overtimeManualHourlyRate));
            if (overtimes[index].overtimeTotalAmount !== 0) {
                overtimes[index].overtimeTotalAmount = overtimes[index].overtimeTotalAmount.toFixed(2);
            }
        } else {
            overtimes[index].overtimeTotalAmount = parseFloat(valueFix * baseHourly * (name === "overtimeManualRate" ? formProps.values.overtimeTotal : formProps.values.overtimeManualRate));
            if (overtimes[index].overtimeTotalAmount !== 0) {
                overtimes[index].overtimeTotalAmountal = overtimes[index].overtimeTotalAmount.toFixed(2);
            }
        }

        parentProps.setFieldValue("payroll.overtimes", overtimes);
    };
    renderForm = (formProps) => {
        const {
            classes, row, index, handleRemoveItem
        } = this.props;
        return (
            <TableRow key={row.name} className={classes.tableRow, classes.tableCustompayroll}>
                <TableCell className={classes.tableText, classes.tableCellHover}>

                    <TextField
                        id="rate"
                        InputProps={{
                            readOnly: true,
                        }}
                        defaultValue={row.overtime === "Manually Enter Hourly Rate" ? `$ ${row.overtimeManualHourlyRate}` : `x ${row.overtimeManualRate}`}
                        onChange={formProps.handelChange}

                        className={clsx(classes.textFieldCustomPayroll,{
                            error: getIn(formProps.errors, "name") && getIn(formProps.touched, "name")
                        })}
                    />

                </TableCell>
                <TableCell className={classes.tableText, classes.tableCellHover}>
                    <TextField
                        id="overtimeTotal"
                        defaultValue={row.overtimeTotal}
                        fullWidth
                        onBlur={formProps.handleBlur}
                        onChange={(value) => {
                            this.handleChangeValues(index, "overtimeTotal", value.target.value, formProps, "overtimeTotalAmount", formProps.values.overtime === "Manually Enter Hourly Rate")
                        }}
                        onBlur={formProps.handleBlur}
                        InputProps={{
                            inputComponent: NumberFormatCustomComponent,
                            inputProps: {
                                decimalScale: 2,
                                fixedDecimalScale: true,
                            }
                        }}
                        className={clsx('tableCellHover',classes.textFieldCustomPayroll, {
                            error: getIn(formProps.errors, "overtimeTotal") && getIn(formProps.touched, "overtimeTotal")
                        })}
                    />
                </TableCell>

                <TableCell className={classes.tableText, classes.tableCellHover}>

                    <TextField
                        id="overtimeTotalAmount"

                        fullWidth
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            readOnly: true,
                            inputComponent: NumberFormatCustomComponent,
                            inputProps: {
                                decimalScale: 2,
                                fixedDecimalScale: true
                            }
                        }}
                        value={row.overtimeTotalAmount}
                        onChange={formProps.handelChange}
                        className={clsx(classes.textFieldCustomPayroll,{
							error: getIn(formProps.errors, "overtimeTotalAmount") && getIn(formProps.touched, "overtimeTotalAmount")
						})}
                    />


                </TableCell>
                <TableCell className={classes.tableText, classes.tableCellHoverDelete} align="right">
                    <DeleteIcon
                        fontSize="large"
                        onClick={e => handleRemoveItem(e, index)}
                        className={classes.textFieldCustomPayrollDelete}
                    />

                </TableCell>
            </TableRow>
        )
    };

    render() {
        const {
            data, row, index, handleRemoveItem, handelChange, parentProps
        } = this.props;
        const dataDefault = {
            ...data,
            ...row,
            index,
            handleRemoveItem,
            handelChange,
            ...parentProps
        };
        return <Formik
            initialValues={{ ...dataDefault }}
            validationSchema={this.validationSchema}
            render={this.renderForm}
            enableReinitialize />;
    }
}

EditOvertimeComponent.propTypes = {
    handleRemoveItem: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    payrollBaseRateAdditionalList: state.parameters.parameterByTypeData[parameterTypes.PAYROLL_BASE_RATE_ADDITIONAL.parameterType]
});

export default connect(
    mapStateToProps,
    null
)(withStyles(PayrollStyle)(EditOvertimeComponent));
