
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import {
	Button, Grid, Paper, Typography
} from "@material-ui/core";
import { Formik } from "formik";
import clsx from 'clsx';
import * as Yup from "yup";
import employerStyle from "./employerStyle";
import ModalSetupBusinessInfoComponent from './ModalSetupBusinessInfoComponent'

import * as routes from "../../constants/routes";
import { AvatarCommonComponent } from "../common";
import ModalUpdateYourDetails from "./modalUpdateYourDetails";
import EmployerBusinessEntitlementComponent from "./employerBusinessEntitlementComponent";
import EmployerBusinessAddressComponent from "./employerBusinessAddressComponent";
import EmployerAuthorisedPersonComponent from "./employerAuthorisedPersonComponent";
import EmployerContactDetailsComponent from "./employerContactDetailsComponent";
import { employerUpdate, getEmployer, redirectToYourBusiness, employerUpdateBusinessInfo } from "../../actions/EmployerActions";
import { validatePostCode } from "../../helpers/extensions";
import { employerDefaultData } from "../../reducers/Employer/EmployerInitialState";
import { getTodayTime } from "../../helpers/dateextensions";
import { Redirect } from "react-router";

class EmployerRegisterComponent extends Component {
	state = {
		nextSlider: false,
		messageCode: 0,
		showModalBusinessInfo: false,
		isValidation: false
	}
	validationSchema = Yup.object({
		company: Yup.object().shape({
			abn: Yup.object(),
			wpn: Yup.object(),
			address: Yup.object().shape({
				addressLine1: Yup.string()
					.ensure()
					.required("Street line 1 is required"),
				addressLine2: Yup.string(),
				postCode: Yup.string()
					.typeError("Must be an Australian valid postcode")
					.required("Postcode is required")
					.test("postcode-validation", "Must be an Australian valid postcode", validatePostCode),
				townCity: Yup.string()
					.ensure()
					.required("Town/City is required"),
				state: Yup.string().required('State/Territory is required')
			}),
			authorizedPerson: Yup.object().shape({
				firstName: Yup.string()
					.ensure()
					.required("First name is required"),
				middleName: Yup.string(),
				lastName: Yup.string()
					.ensure()
					.required("Last name is required")
			})
		}),
		contact: Yup.object().shape({
			firstName: Yup.string()
				.ensure()
				.required("First name is required"),
			middleName: Yup.string(),
			lastName: Yup.string()
				.ensure()
				.required("Last name is required"),
			phoneNumber: Yup.string().matches(/^(0011[0-9]{2}?|(\+[0-9]{2})|0)?[0-9]{9}$/, {
				message: 'Please enter a valid phone number',
				excludeEmptyString: true
			})
				.ensure()
				.required("Contact number is required"),
			email: Yup.string()
				.email()
				.ensure()
			// .required("Email address is required")
		})
	});

	form = React.createRef();

	componentDidMount() {
		const { getEmployerInternal, businessInfo } = this.props;
		getEmployerInternal();
		this.form.current.validateForm();
		this.setState({
			showModalBusinessInfo: businessInfo
		})
	}
	isValidation = (isValidation) => {
		this.setState({
			isValidation
		})
	}
	handleSubmitSave = (values, actions) => {
		const { employerUpdateInternal, employerUpdateInternalBusinessInfo, employer } = this.props;
		actions.setSubmitting(false);

		this.state.isValidation ? employerUpdateInternalBusinessInfo({ ...values }) : employerUpdateInternal({ ...values });
		this.setState({
			isValidation: false
		})
	};

	componentWillMount = () => {
		const { } = this.props
		redirectToYourBusiness(false)
	}
	handelCloseBusinessInfoModal = () => {

		this.setState({
			showModalBusinessInfo: false
		})
	}

	renderForm = (formProps) => {
		const { classes, employer } = this.props;
		const logo = (employer.company || {}).logo ? `${(employer.company || {}).logo}?${getTodayTime()}` : "";

		return (
			<div className={classes.root}>
				<Paper className={classes.paper}>
					<Grid container alignItems="center">
						{!employer.initials || employer.initials.length <= 0 ? <AvatarCommonComponent text="YB" src={logo} employerId={(employer || {}).id} isBig hover /> :
							<AvatarCommonComponent hover isBig src={logo} text={employer.initials} employerId={(employer || {}).id} />}
						{!employer.companyName || employer.companyName.length <= 0 ? <Typography variant="h1">Your business</Typography> : null}
						<Typography component="h2" variant="h1">
							{employer.companyName}
						</Typography>
					</Grid>
					<Grid container alignItems="center" justify="flex-end">
						<Button
							variant="contained"
							color="secondary"
							disabled={!formProps.isValid}
							className={clsx(classes.buttonFinish, classes.orangeRectangularBtn, { [classes.disabledButtonSecondary]: !formProps.isValid })}
							onClick={formProps.handleSubmit}
						>
							{"Finish"}
						</Button>
					</Grid>

					<EmployerBusinessEntitlementComponent formProps={formProps} {...this.props} />
					<EmployerBusinessAddressComponent formProps={formProps} {...this.props} />
					<EmployerAuthorisedPersonComponent formProps={formProps} {...this.props} />
					<EmployerContactDetailsComponent formProps={formProps} {...this.props} />

					<Grid container alignItems="center" justify="flex-end">
						<Button
							variant="contained"
							color="secondary"
							disabled={!formProps.isValid}
							className={clsx(classes.buttonFinish, classes.orangeRectangularBtn, { [classes.disabledButtonSecondary]: !formProps.isValid })}
							onClick={formProps.handleSubmit}
						>
							{"Finish"}
						</Button>
						{/* <Button variant="contained" color="primary" disabled={!formProps.isValid} onClick={formProps.handleSubmit}>
							Save
						</Button> */}
					</Grid>
				</Paper>

				<ModalUpdateYourDetails />
				{this.state.showModalBusinessInfo && <ModalSetupBusinessInfoComponent
					open={this.state.showModalBusinessInfo} handelCloseBusinessInfoModal={this.handelCloseBusinessInfoModal}
					isValidation={this.isValidation} handleSubmitSave={this.handleSubmitSaveBusinessInfo}
					onPrimaryButtonClick={this.handlePrimaryClickModal} onCloseModal={this.handleCloseModalBusinessInfo} formProps={formProps} {...this.props} />}
				{/* {true && <ModalSetupBusinessInfoComponent open={true} handelCloseBusinessInfoModal={this.handelCloseBusinessInfoModal} isValidation={this.isValidation} handleSubmitSave={this.handleSubmitSaveBusinessInfo} onPrimaryButtonClick={this.handlePrimaryClickModal} onCloseModal={this.handleCloseModalBusinessInfo} formProps={formProps} {...this.props} />} */}
			</div>
		);
	};

	render() {
		const { employer, businessInfo, redirectToPaySlip2 } = this.props;

		if (redirectToPaySlip2) {
			return (
				<Redirect to={routes.PATH_PAYROLL_PROCESS} />
			)
		}
		const employerData = {
			isValidation: this.isValidation,
			...employerDefaultData,
			...employer,

			businessInfo,
			company: {
				...employerDefaultData.company,
				...employer.company,
				abn: {
					...employerDefaultData.company.abn,
					...employer.company.abn,
					entityType: {
						...employerDefaultData.company.abn.entityType,
						...employer.company.abn.entityType
					}
				},
				wpn: {
					...employerDefaultData.company.wpn,
					...employer.company.wpn
				},
				address: {
					...employerDefaultData.company.address,
					...employer.company.address
				},
				authorizedPerson: {
					...employerDefaultData.company.authorizedPerson,
					...employer.company.authorizedPerson
				}
			},
			contact: {
				...employerDefaultData.contact,
				...employer.contact,
			}
		};

		return <Formik initialValues={{ ...employerData }} validationSchema={this.state.isValidation || this.validationSchema} onSubmit={this.handleSubmitSave} render={this.renderForm} ref={this.form} enableReinitialize="true" />;
	}
}

const mapStateToProps = state => ({
	employer: state.employer.employerData,
	businessInfo: state.employer.redirectToYourBusiness,
	redirectToPaySlip2: state.employer.redirectToPaySlip
});

const mapDispatchToProps = dispatch => ({
	getEmployerInternal: bindActionCreators(getEmployer, dispatch),
	employerUpdateInternal: bindActionCreators(employerUpdate, dispatch),
	employerUpdateInternalBusinessInfo: bindActionCreators(employerUpdateBusinessInfo, dispatch),
	redirectToYourBusiness: bindActionCreators(redirectToYourBusiness, dispatch)
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(employerStyle)(EmployerRegisterComponent));






