import * as actionTypes from "../../constants/actionTypes";
import paymentInitialState from "./PaymentInitialState";

const paymentReducer = (state = paymentInitialState, action) => {
	switch (action.type) {
		case actionTypes.ACTION_PAYMENT_PROCESS_REQUEST: {
			return {
				...state,
				isLoading: true
			};
		}
		case actionTypes.ACTION_PAYMENT_PROCESS_REQUEST_SUCCESS: {
			return {
				...state,
				isLoading: false,
				paymentData: action.payload.paymentData,
				messageCode: action.payload.messageCode,
				message: action.payload.message
			};
		}
		case actionTypes.ACTION_PAYMENT_PROCESS_REQUEST_FAILURE: {
			return {
				...state,
				error: true,
				isLoading: false,
				errorMessage: action.payload.errorMessage,
				messageCode: action.payload.messageCode,
				message: action.payload.message
			};
		}
		case actionTypes.ACTION_PAYMENT_CLEAN_MESSAGE: {
			return {
				...state,
				error: false,
				isLoading: false,
				errorMessage: action.payload.errorMessage,
				messageCode: action.payload.messageCode,
				message: action.payload.message
			};
		}
		case actionTypes.ACTION_SESSION_LOGOUT: {
			return paymentInitialState;
		}
		default: {
			return {
				...state
			};
		}
	}
};

export default paymentReducer;
