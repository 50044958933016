import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";

import { LoadingComponent, SnackbarCommonComponent } from "../components/common";
import SidebarComponent from "../components/home/sidebarComponent";
import PayrollProcessComponent from "../components/payroll/payrollProcessComponent";
import { SessionUserStyles } from "./PagesStyle";
import { mapMessageCodeToIcon } from "../helpers/extensions";

class PayrollProcessPage extends Component {
	render() {
		const {
			classes, isLoading, message, messageCode, mixpanel
		} = this.props;

		return (
			// -200 px for right sidebar which was hiding the scrollbar
			<div className={classes.root}style={{ width: 'calc(100% - 200px)' }}>
				<CssBaseline />
				<SidebarComponent />
				<main className={classes.content}>
					<LoadingComponent isLoading={isLoading} />
					<SnackbarCommonComponent key="SnackBarNewEmployee" variant={mapMessageCodeToIcon(messageCode)} open={!!message} body={message} />
					<div className={classes.appBarSpacer} />
					<PayrollProcessComponent mixpanel={mixpanel} />
				</main>
			</div>
		);
	}
}

PayrollProcessPage.propTypes = {
	classes: PropTypes.object.isRequired,
	isLoading: PropTypes.bool,
	message: PropTypes.string
};
const mapStateToProps = state => ({
	isLoading: state.payroll.isLoading || state.stp.isLoading,
	messageCode: state.payroll.messageCode,
	message: state.payroll.message
});

export default connect(
	mapStateToProps,
	null
)(withStyles(SessionUserStyles)(PayrollProcessPage));
