import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import { Paper, Tab, Tabs, Typography } from "@material-ui/core";
import SwipeableViews from "react-swipeable-views";
import PropTypes from "prop-types";
import * as singleTouchActions from "../../actions/SingleTouchActions";
import { redirectToStpCompeleted } from "../../actions/YECPActions";
import singleTouchStyle from "./singleTouchStyle";
import SingleTouchOutstandingComponent from "./singleTouchOutstandingComponent";
import SingleTouchCompletedComponent from "./sigleTouchCompletedComponent";
import { SnackbarCommonComponent } from "../common";
// import ModalSTPSummaryComponent from "./modalSTPSummaryComponent";
import * as stpActions from "../../actions/STPActions";

class SingleTouchComponent extends Component {
  state = {
    tabValue: 0,
    redirectToSTP: false,
    error: false,
    success: false,
    message: false
    // showSummaryModal: false
  };

  componentWillReceiveProps = nextProps => {
    const { redirectFromYECP, error, success, message } = nextProps;
    if (redirectFromYECP) {
      this.setState(
        {
          tabValue: 1
        },
        () => {
          this.props.redirectToStpCompeleted(false);
        }
      );
    } else {
      this.setState({ error, success, message });
    }
  };
  componentDidMount() {
    const { getAllSTP, mixpanel, redirectToSTP2, redirectToSTP } = this.props;
    getAllSTP();
    this.setState({
      redirectToSTP: redirectToSTP2
    });
    setTimeout(() => {
      this.setState({
        redirectToSTP: false
      });
      redirectToSTP(false);
    }, 3000);
    mixpanel.track("SingleTouchPayroll.Screen");
  }
  componentWillMount() {
    const { redirectToSTP } = this.props;
    redirectToSTP(false);
  }

  // componentWillReceiveProps = (nextProps) => {
  // 	const { stpSummary } = nextProps;
  // 	debugger
  // 	if (stpSummary && stpSummary.urlResume && stpSummary.urlResume.length > 1) {
  // 		this.setState(() => ({
  // 			showSummaryModal: true
  // 		}));
  // 	}
  // };

  tabHandleChange = (event, newValue) => {
    const { clearReportSummary } = this.props;
    this.setState({
      tabValue: newValue,
      showSummaryModal: false
    });
    clearReportSummary();
  };

  tabHandleChangeIndex = index => {
    this.setState({ tabValue: index });
  };

  // handleCloseSummary = () => {
  // 	const { clearReportSummary } = this.props;
  // 	this.setState(() => ({
  // 		showSummaryModal: false
  // 	}));
  // 	clearReportSummary()
  // };

  handleCloseModal = () => {
    this.setState({ error: false, success: false });
  }

  render() {
    const { classes, STPList, stpSummary } = this.props;
    const { tabValue, showSummaryModal, error, message, success } = this.state;

    return (
      <div className={classes.root}>
        <Paper className={classes.paperContainer}>
          <Typography variant="h1" gutterBottom>
            Single Touch Payroll
          </Typography>
          <Typography>
            View and manage STP files that have been lodged or are ready to
            lodge with the ATO
          </Typography>
          <Tabs
            variant="scrollable"
            className={classes.tabs}
            indicatorColor="primary"
            value={tabValue}
            onChange={this.tabHandleChange}
          >
            <Tab label="Outstanding" className={classes.tab} wrapped />
            <Tab label="Completed" className={classes.tab} wrapped />
          </Tabs>
          <SwipeableViews
            index={tabValue}
            onChangeIndex={this.tabHandleChangeIndex}
          >
            <SingleTouchOutstandingComponent
              redirectToCompletedTab={event => this.tabHandleChange(event, 1)}
              {...this.props}
            />
            <SingleTouchCompletedComponent {...this.props} />
          </SwipeableViews>
        </Paper>

        {this.state.redirectToSTP && (
          <SnackbarCommonComponent
            open={true}
            autoHideDuration={4000}
            variant="info"
            body=" Lodge your STP File by selecting the option below"
          />
        )}

        <SnackbarCommonComponent
          open={error || success}
          autoHideDuration={4000}
          onCloseModal={this.handleCloseModal}
          variant={ error ? "error" : "success" }
          body={message}
        />

        {/* {showSummaryModal ? <ModalSTPSummaryComponent open={showSummaryModal}
					summary={stpSummary} onCloseModal={this.handleCloseSummary}
					redirectToCompletedTab={(event) => this.tabHandleChange(event, 1)} /> : null} */}
      </div>
    );
  }
}

SingleTouchComponent.propTypes = {
  STPList: PropTypes.array,
  pendingList: PropTypes.array,
  lodgedList: PropTypes.array,
  invalidList: PropTypes.array,
  completedList: PropTypes.array,
  getAllSTP: PropTypes.func
  // stpSummary: PropTypes.object,
};

const mapStateToProps = state => ({
  STPList: state.singleTouch.STPList,
  pendingList: state.singleTouch.pendingList,
  lodgedList: state.singleTouch.lodgedList,
  invalidList: state.singleTouch.invalidList,
  completedList: state.singleTouch.completedList,
  redirectToSTP2: state.singleTouch.redirectToSTP,
  redirectFromYECP: state.yecp.redirectToSTPCompleted,
  error: state.stp.error,
  success: state.stp.success,
  message: state.stp.message,
  // stpSummary: state.stp.reportSummary,
});

const mapDispatchToProps = dispatch => ({
  getAllSTP: bindActionCreators(singleTouchActions.getAllSTPRequest, dispatch),
  redirectToSTP: bindActionCreators(singleTouchActions.redirectToSTP, dispatch),
  clearReportSummary: bindActionCreators(
    stpActions.setCleanDataToStore,
    dispatch
  ),
  redirectToStpCompeleted: bindActionCreators(redirectToStpCompeleted, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(singleTouchStyle)(SingleTouchComponent));
