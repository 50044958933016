import * as actionTypes from "../constants/actionTypes";

export function userSignUp(email, password, history) {
	return {
		type: actionTypes.ACTION_SESSION_USER_SIGN_UP,
		payload: {
			isLoading: true,
			email,
			password,
			history
		}
	};
}

export function userSignUpSuccess(userData, token, enable2FA, messageCode, message) {
	return {
		type: actionTypes.ACTION_SESSION_USER_SIGN_UP_SUCCESS,
		payload: {
			userData,
			token,
			enable2FA,
			messageCode,
			message
		}
	};
}

export function userSignUpFailure(message) {
	return {
		type: actionTypes.ACTION_SESSION_USER_SIGN_UP_FAILURE,
		error: true,
		payload: {
			errorMessage: message
		}
	};
}

export function userForgotPassword(email, history) {
	return {
		type: actionTypes.ACTION_SESSION_USER_FORGOT_PASSWORD,
		payload: {
			isLoading: true,
			email,
			history
		}
	};
}

export function userForgotPasswordSuccess(messageCode, message) {
	return {
		type: actionTypes.ACTION_SESSION_USER_FORGOT_PASSWORD_SUCCESS,
		payload: {
			isSendMail: true,
			messageCode,
			message
		}
	};
}

export function userForgotPasswordFailure(messageCode, message) {
	return {
		type: actionTypes.ACTION_SESSION_USER_FORGOT_PASSWORD_FAILURE,
		error: true,
		payload: {
			isSendMail: false,
			messageCode,
			message
		}
	};
}

export function userForgotValidation(tokenForgot, history) {
	return {
		type: actionTypes.ACTION_SESSION_USER_FORGOT_VALIDATION,
		payload: {
			isLoading: true,
			tokenForgot,
			history
		}
	};
}

export function userForgotValidationSuccess(userData, token, enable2FA, messageCode, message) {
	return {
		type: actionTypes.ACTION_SESSION_USER_FORGOT_VALIDATION_SUCCESS,
		payload: {
			userData,
			token,
			enable2FA,
			messageCode,
			message
		}
	};
}

export function userForgotValidationFailure(messageCode, message) {
	return {
		type: actionTypes.ACTION_SESSION_USER_FORGOT_VALIDATION_FAILURE,
		error: true,
		payload: {
			messageCode,
			message
		}
	};
}

export function loginUnifyRequest(tokenUnify, history) {
	return {
		type: actionTypes.ACTION_SESSION_USER_LOGIN_UNIFY,
		payload: {
			isLoading: true,
			tokenUnify,
			history
		}
	};
}

export function loginUnifyRequestSuccess(userData, token, enable2FA, messageCode, message) {
	return {
		type: actionTypes.ACTION_SESSION_USER_LOGIN_UNIFY_SUCCESS,
		payload: {
			userData,
			token,
			enable2FA,
			method2FA: userData.method2FA,
			messageCode,
			message,
			isAccountant: false,
			isEmployee: true
		}
	};
}

export function loginUnifyRequestFailure(message) {

	return {
		type: actionTypes.ACTION_SESSION_USER_LOGIN_UNIFY_FAILURE,
		error: true,
		payload: {
			errorMessage: message
		}
	};
}

export function cleanMessage() {
	return {
		type: actionTypes.ACTION_EXTERNAL_CLEAN_MESSAGE,
		payload: {
			errorMessage: null,
			messageCode: null,
			message: null
		}
	};
}
