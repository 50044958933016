import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";
import { isMobile } from "react-device-detect";
import { LoadingComponent, SnackbarCommonComponent } from "../components/common";
import SidebarComponent from "../components/home/sidebarComponent";
import PlanComponent from "../components/subscription/planComponent";
import { SessionUserStyles } from "./PagesStyle";
import * as CouponActions from './../actions/CouponActions';
import { mapMessageCodeToIcon } from "../helpers/extensions";

class PlanPage extends Component {
	render() {
		const { classes, isLoading, mixpanel, messageCode, message, cleanMessageInternal } = this.props;

		return (
			<div className={classes.root}>
				<CssBaseline />
				{!isMobile ? <SidebarComponent /> : null}
				<main className={classes.content}>
					<LoadingComponent isLoading={isLoading} />
					<SnackbarCommonComponent key="SnackBarCoupon" variant={messageCode === 200 ? "" : mapMessageCodeToIcon(messageCode)}
						open={(!!message)} body={message}
						onCloseModal={cleanMessageInternal}
					/>
					<div className={classes.appBarSpacer} />
					<PlanComponent mixpanel={mixpanel} />
				</main>
			</div>
		);
	}
}

PlanPage.propTypes = {
	classes: PropTypes.object.isRequired,
	isLoading: PropTypes.bool
};

const mapStateToProps = state => ({
	isLoading: state.parameters.isLoading || state.subscription.isLoading || state.coupon.isLoading,
	messageCode: state.coupon.messageCode,
	message: state.coupon.message || state.coupon.errorMessage,
});

const mapDispatchToProps = dispatch => ({
	cleanMessageInternal: bindActionCreators(CouponActions.cleanMessage, dispatch)
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(SessionUserStyles)(PlanPage));
