import { createLogic } from "redux-logic";
import * as moment from "moment";

import parameterApi from "../api/ParameterApi";
import localStorageApi from "../api/LocalStorageApi";
import * as parameterActions from "../actions/ParameterActions";
import * as actionTypes from "../constants/actionTypes";
import * as apiResponses from "../constants/apiResponses";
import * as common from "../constants/common";
import { getDateToday } from "../helpers/dateextensions";

const getAllParamters = createLogic({
	type: actionTypes.ACTION_PARAMTERS_GETALL_REQUEST,
	latest: false,
	async process({ httpClient }, dispatch, done) {
		try {
			const cachedParameters = localStorageApi.getItem(common.STORAGE_ALL_PARAMETERS);
			
			if (cachedParameters && cachedParameters.date && moment().diff(moment(cachedParameters.date), "days") < 1) {
				dispatch(parameterActions.getAllParametersRequestSuccess(cachedParameters.data));
			} else {
				const parameterList = await parameterApi.getAllParameters(httpClient);
				if (parameterList.messageCode === apiResponses.RESPONSE_OK) {
					dispatch(parameterActions.getAllParametersRequestSuccess(parameterList.list));
					const storageParameters = {
						date: getDateToday(),
						data: parameterList.list
					};
					localStorageApi.setItem(common.STORAGE_ALL_PARAMETERS, storageParameters);
				} else {
					dispatch(parameterActions.getAllParametersRequestFailure(parameterList.messageCode, parameterList.message));
				}
			}
		} catch (err) {
			dispatch(parameterActions.getAllParametersRequestFailure("", err.message));
		}
		done();
	}
});

const getParamtersByType = createLogic({
	type: actionTypes.ACTION_PARAMTERS_GETBYTYPE_REQUEST,
	latest: false,
	async process({ httpClient, action }, dispatch, done) {
		try {
			const cachedParameters = localStorageApi.getItem(common.STORAGE_ALL_PARAMETERS);
			if (cachedParameters && cachedParameters.date && moment().diff(moment(cachedParameters.date), "days") < 1) {
				let temporaryParamterList = cachedParameters.data.filter(item => item.section === action.payload.section && item.typeSection === action.payload.typeSection);
				temporaryParamterList = temporaryParamterList.sort((a, b) => ((a.order > b.order) ? 1 : -1));
				dispatch(parameterActions.getParametersByTypeRequestSuccess(action.payload.parameterType, temporaryParamterList));
			} else {
				const parameterList = await parameterApi.getParameterByType(httpClient, action.payload.section, action.payload.typeSection);
				if (parameterList.messageCode === apiResponses.RESPONSE_OK) {
					let temporaryParamterList = parameterList.list;
					temporaryParamterList = temporaryParamterList.sort((a, b) => ((a.order > b.order) ? 1 : -1));
					dispatch(parameterActions.getParametersByTypeRequestSuccess(action.payload.parameterType, temporaryParamterList));
				} else {
					dispatch(parameterActions.getParametersByTypeRequestFailure(parameterList.messageCode, parameterList.message));
				}
			}
		} catch (err) {
			dispatch(parameterActions.getParametersByTypeRequestFailure("", err.message));
		}
		done();
	}
});

const getPlans = createLogic({
	type: actionTypes.ACTION_PARAMTERS_PLANS_REQUEST,
	latest: false,
	async process({ httpClient }, dispatch, done) {
		try {
			const plansList = await parameterApi.getPlans(httpClient);
			if (plansList.messageCode === apiResponses.RESPONSE_OK) {
				dispatch(parameterActions.getPlansRequestSuccess(plansList.list));
			} else {
				dispatch(parameterActions.getPlansRequestFailure(plansList.messageCode, plansList.message));
			}
		} catch (err) {
			dispatch(parameterActions.getPlansRequestFailure("", err.message));
		}
		done();
	}
});

export default [getAllParamters, getParamtersByType, getPlans];
