import React from "react";
import clsx from "clsx";
import { Button, Divider, Typography } from "@material-ui/core";

import DialogModalCommonComponent from "./dialogModalCommonComponent";
import DialogActionsCommonComponent from "./dialogActionsCommonComponent";
import DialogContentCommonComponent from "./dialogContentCommonComponent";
import DialogTitleCommonComponent from "./dialogTitleCommonComponent";
import downloadLogo from "./../../assets/images/download.png";

class DialogCommonComponent extends React.Component {
  render() {
    const {
      title,
      subtitle,
      subtitle2,
      textInfoStyle,
      textInfo,
      secondaryButtonNameDiscard,
      styleTitle,
      styleSubtitle,
      styleDiscard,
      styleSubtitle2,
      style,
      style2,
      btnBack,
      styleBtnBack,
      children,
      size,
      open,
      onCloseModal,
      showDivider,
      payslipDialog,
      background2FA,
      primaryButtonName,
      onPrimaryButtonClick,
      primaryButtonDisabled,
      secondaryButtonName,
      onSecondaryButtonClick,
      showScroll,
      downloadPdf,
      downloadStyle
    } = this.props;

    return (
      <div>
        <DialogModalCommonComponent
          onClose={onCloseModal}
          aria-labelledby={title}
          open={open}
          fullWidth
          maxWidth={size}
        >
          <DialogTitleCommonComponent id={title} onClose={onCloseModal}>
            <Typography aria-labelledby={subtitle} style={styleTitle}>
              {title}
            </Typography>
            <Typography
              aria-labelledby={subtitle}
              style={styleSubtitle}
              className="subtitle"
            >
              {subtitle}
            </Typography>
            <Typography
              aria-labelledby={subtitle}
              style={styleSubtitle2}
              className="subtitle"
            >
              {subtitle2}
            </Typography>
          </DialogTitleCommonComponent>
          {showDivider ? <Divider /> : null}
          <DialogContentCommonComponent
            className={clsx("", {
              divider: showDivider,
              background2FA,
              payslipDialog,
              showScroll
            })}
            style={downloadPdf ? { minHeight: 100 } : {}}
          >
            {children}
          </DialogContentCommonComponent>
          {downloadPdf && (
            <a
              style={downloadStyle}
              href={downloadPdf}
              target="_blank"
              download
            >
              <img src={downloadLogo} style={{ width: 18, height: 18 }} />
            </a>
          )}
          <DialogActionsCommonComponent
            className={clsx("", {
              background2FA
            })}
            style={
              secondaryButtonName === "any" ? { padding: "20px 14px 0 0" } : {}
            }
          >
            <div
              style={
                secondaryButtonName === "any"
                  ? { textAlign: "center", width: "100%" }
                  : {}
              }
            >
              <div style={{ width: "100%" }}>
                {textInfo && (
                  <Typography style={textInfoStyle}>{textInfo}</Typography>
                )}
              </div>
              <div
                style={
                  secondaryButtonName === "any"
                    ? { textAlign: "center", width: "100%" }
                    : { textAlign: "end" }
                }
              >
                {btnBack && (
                  <a onClick={onCloseModal} style={styleBtnBack}>
                    Back
                  </a>
                )}
                {secondaryButtonNameDiscard && onSecondaryButtonClick ? (
                  <a onClick={onSecondaryButtonClick} style={styleDiscard}>
                    {secondaryButtonNameDiscard || "Close"}
                  </a>
                ) : null}

                {secondaryButtonName &&
                secondaryButtonName !== "any" &&
                onSecondaryButtonClick ? (
                  <Button
                    onClick={onSecondaryButtonClick}
                    variant="outlined"
                    color="default"
                  >
                    {secondaryButtonName || "Close"}
                  </Button>
                ) : null}
                <Button
                  onClick={onPrimaryButtonClick}
                  variant="contained"
                  style={style}
                  className="btnpayslipscreen"
                  color="secondary"
                  disabled={primaryButtonDisabled}
                >
                  {style2 ? (
                    <span style={style2}>{primaryButtonName || "Save"}</span>
                  ) : (
                    primaryButtonName || "Save"
                  )}
                </Button>
              </div>
            </div>
          </DialogActionsCommonComponent>
        </DialogModalCommonComponent>
      </div>
    );
  }
}

export default DialogCommonComponent;
