const getAllEmployees = async (httpClient) => {
	const { goEpsApi } = window.envVariables;
	const response = await httpClient.get(`${goEpsApi}/api/v1/employee`);

	return response.data;
};

const getEmployeeById = async (httpClient, employeeId) => {
	const { goEpsApi } = window.envVariables;
	const response = await httpClient.get(`${goEpsApi}/api/v1/employee/${employeeId}`);

	return response.data;
};

const addEmployee = async (httpClient, employerId) => {
	const { goEpsApi } = window.envVariables;
	const response = await httpClient.post(`${goEpsApi}/api/v1/employee`, { employerId });

	return response.data;
};

const saveEmployeeDetails = async (httpClient, employerId, employeeId, employeeData) => {
	const { goEpsApi } = window.envVariables;
	const tempEmployeeData = {
		...employeeData,
		employerId,
		id: employeeId
	};
	const response = await httpClient.put(`${goEpsApi}/api/v1/employee`, tempEmployeeData);
	return response.data;
};

const uploadEmployeeImage = async (httpClient, employeeId, filename, imageFile) => {
	const { epsApi } = window.envVariables;
	const requestBody = {
		id: employeeId,
		image: {
			name: filename,
			image: imageFile
		}
	};

	const response = await httpClient.post(`${epsApi}/api/v2/Employee/AddImage`, requestBody);

	return response.data;
};

const activeOrInactiveEmployee = async (httpClient, requestData) => {
	const { goEpsApi } = window.envVariables;
	const response = await httpClient.put(`${goEpsApi}/api/v1/employee/activeorinactive`, requestData);
	return response.data;
};

export default {
	getAllEmployees,
	getEmployeeById,
	addEmployee,
	saveEmployeeDetails,
	uploadEmployeeImage,
	activeOrInactiveEmployee
};
