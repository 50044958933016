import { createLogic } from "redux-logic";

import yecpApi from "./../api/YECPApi";
import * as yecpAction from "../actions/YECPActions";
import * as actionTypes from "../constants/actionTypes";
import * as apiResponses from "../constants/apiResponses";

// -------------------- GET ALL YECP -------------------------
const getAllYECPRequest = createLogic({
  type: actionTypes.ACTION_GET_ALL_YECP_REQUEST,
  latest: true,
  async process({ httpClient }, dispatch, done) {
    try {
      const response = await yecpApi.getAllYECP(httpClient);
      if (response.status === apiResponses.RESPONSE_OK_SUCCESS_CODE) {
        dispatch(
          yecpAction.getAllYECPSuccess(
            response.data,
            response.messageCode,
            response.message
          )
        );
      } else {
        dispatch(
          yecpAction.getAllYECPFailure(response.messageCode, response.message)
        );
      }
    } catch (err) {
      dispatch(
        yecpAction.getAllYECPFailure(apiResponses.RESPONSE_FAIL, err.message)
      );
    }
    done();
  }
});

// -------------------- SEND CLOSE FY --------------------
const getCloseFY = createLogic({
  type: actionTypes.ACTION_SEND_CLOSE_FY_REQUEST,
  latest: true,
  async process({ httpClient, action }, dispatch, done) {
    try {
      const response = await yecpApi.getDataAboutCloseFY(
        httpClient,
        action.payload.requestData
      );
      if (response.status === apiResponses.RESPONSE_OK_SUCCESS_CODE) {
        dispatch(
          yecpAction.sendCloseYFSuccess(
            response.data,
            response.messageCode,
            response.message,
            action.payload.requestData
          )
        );
      } else {
        dispatch(
          yecpAction.sendCloseYFFailure(response.messageCode, response.message)
        );
      }
    } catch (err) {
      dispatch(
        yecpAction.sendCloseYFFailure(apiResponses.RESPONSE_FAIL, err.message)
      );
    }
    done();
  }
});

// -------------------- SEND CLOSE FY SIGNATURE ------------------------
const sendCloseYFSignature = createLogic({
  type: actionTypes.ACTION_SEND_CLOSE_FY_SIGNATURE_REQUEST,
  latest: true,
  async process({ httpClient, action }, dispatch, done) {
    try {
      const response = await yecpApi.sendFYCloseSignature(
        httpClient,
        action.payload.yecp,
        action.payload.requestData
      );
      // if (response.status === apiResponses.RESPONSE_OK_SUCCESS_CODE) {
      //   dispatch(
      //     yecpAction.sendCloseYFSignatureSuccess(
      //       response.data,
      //       response.messageCode,
      //       response.message
      //     )
      //   );
      // } else {
      //   dispatch(
      //     yecpAction.sendCloseYFSignatureFailure(
      //       response.messageCode,
      //       response.message
      //     )
      //   );
      // }
      dispatch(
        yecpAction.sendCloseYFSignatureSuccess(
          response.data,
          response.messageCode,
          response.message
        )
      );
    } catch (err) {
      dispatch(
        yecpAction.sendCloseYFSignatureFailure(
          apiResponses.RESPONSE_FAIL,
          err.message
        )
      );
    }
    done();
  }
});

// -------------------- GET AUTHORIZED NAME SIGNATURE ----------------
const getAuthroizedNameSignature = createLogic({
  type: actionTypes.ACTION_GET_AUTHORIZEDNAME_SIGNATURE_REQUEST,
  latest: true,
  async process({ httpClient }, dispatch, done) {
    try {
      const response = await yecpApi.getAuthorizedname(httpClient);

      dispatch(
        yecpAction.getAuthorizedNameSignatureSuccess(
          // response.data,
          response.authorizedName
          // response.messageCode,
          // response.message
        )
      );
    } catch (err) {
      dispatch(
        yecpAction.getAuthorizedNameSignatureFailure(
          apiResponses.RESPONSE_FAIL,
          err.message
        )
      );
    }
    done();
  }
});

// -------------------- PUT EMPLOYEES FOR CLOSE FY -------------------
const putEmployeesForCloseFY = createLogic({
  type: actionTypes.ACTION_PUT_EMPLOYEES_FOR_CLOSE_FY_REQUEST,
  latest: true,
  async process({ httpClient, action }, dispatch, done) {
    try {
      const response = await yecpApi.putEmployeesForCloseFY(
        httpClient,
        action.payload.yecp,
        action.payload.employees
      );
      // if (response.status === apiResponses.RESPONSE_OK_SUCCESS_CODE) {
      //   dispatch(
      //     yecpAction.putEployeesForCloseFYSuccess(
      //       response.data,
      //       response.messageCode,
      //       response.message
      //     )
      //   );
      // } else {
      //   dispatch(
      //     yecpAction.putEployeesForCloseFYFailure(
      //       response.messageCode,
      //       response.message
      //     )
      //   );
      // }
      dispatch(
        yecpAction.putEployeesForCloseFYSuccess(
          response.data,
          response.messageCode,
          response.message,
          action.payload.employeeList
        )
      );
    } catch (err) {
      dispatch(
        yecpAction.putEployeesForCloseFYFailure(
          apiResponses.RESPONSE_FAIL,
          err.message
        )
      );
    }
    done();
  }
});

// -------------------- EDIT EMPLOYEE PAYROLL FOR CLOSE FY ------------------
const editEmployeePayrollForCloseFY = createLogic({
  type: actionTypes.ACTION_EDIT_EMPLOYEE_PAYROLL_FOR_CLOSE_FY_REQUEST,
  latest: true,
  async process({ httpClient, action }, dispatch, done) {
    try {
      const response = await yecpApi.editEmployeePayrollForCloseFY(
        httpClient,
        action.payload.yecp,
        action.payload.id,
        action.payload.requestData
      );
      // if (response.status === apiResponses.RESPONSE_OK_SUCCESS_CODE) {
      //   dispatch(
      //     yecpAction.editEmployeePayrollForCloseFYSuccess(
      //       response.data,
      //       response.messageCode,
      //       response.message
      //     )
      //   );
      // } else {
      //   dispatch(
      //     yecpAction.editEmployeePayrollForCloseFYFailure(
      //       response.messageCode,
      //       response.message
      //     )
      //   );
      // }
      dispatch(
        yecpAction.editEmployeePayrollForCloseFYSuccess(
          response.data,
          response.messageCode,
          response.message,
          action.payload.formProps
        )
      );
    } catch (err) {
      dispatch(
        yecpAction.editEmployeePayrollForCloseFYFailure(
          apiResponses.RESPONSE_FAIL,
          err.message
        )
      );
    }
    done();
  }
});

// -------------------- SEND CLOSE FY AFTER EIDT EMPLOYEES ----------------------
const sendCloseFYAfterEditEmpolyees = createLogic({
  type: actionTypes.ACTION_SEND_CLOSE_FY_AFTER_EDIT_EMPLOYEES_REQUEST,
  latest: true,
  async process({ httpClient, action }, dispatch, done) {
    try {
      const response = await yecpApi.sendCloseFYAfterEditEmployees(
        httpClient,
        action.payload.yecp
      );
      // if (response.status === apiResponses.RESPONSE_OK_SUCCESS_CODE) {
      //   dispatch(
      //     yecpAction.sendCloseFYAfterEditEmployeesSuccess(
      //       response.data,
      //       response.messageCode,
      //       response.message
      //     )
      //   );
      // } else {
      //   dispatch(
      //     yecpAction.sendCloseFYAfterEditEmployeesFailure(
      //       response.messageCode,
      //       response.message
      //     )
      //   );
      // }
      dispatch(
        yecpAction.sendCloseFYAfterEditEmployeesSuccess(
          response,
          response.messageCode,
          response.message
        )
      );
    } catch (err) {
      dispatch(
        yecpAction.sendCloseFYAfterEditEmployeesFailure(
          apiResponses.RESPONSE_FAIL,
          err.message
        )
      );
    }

    done();
  }
});

export default [
  getAllYECPRequest,
  getCloseFY,
  sendCloseYFSignature,
  getAuthroizedNameSignature,
  putEmployeesForCloseFY,
  editEmployeePayrollForCloseFY,
  sendCloseFYAfterEditEmpolyees
];
