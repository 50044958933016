/*eslint-disable */
import moment from "moment-timezone";

export const TimeZone = "Australia/Sydney";
export const FormatDefault = "DD/MM/YYYY";

moment.locale("au");
moment.tz.setDefault(TimeZone);

export function getDate(){
	const date=moment.tz(TimeZone).utc(true);

	return date;
}

export function getDateToday() {
	const date = moment(new Date()).toDate();
	const dateToday = new Date(date.getFullYear(), date.getMonth(), date.getDate(),1,).toDateString();
	return moment(dateToday)
	.tz(TimeZone)
	.utc(false);
}

export function getFirstDay() {
	const date = getDate().toDate();

	const firstDay = new Date(date.getFullYear(), date.getMonth(), 1,).toDateString();
	return moment(firstDay)
		.tz(TimeZone)
		.utc(false);

}

export function getLastDay() {
	const date = getDate().toDate();
	const lastDay = new Date(date.getFullYear(), date.getMonth() + 1,0,).toDateString();
	return moment(lastDay)
		.tz(TimeZone)
		.utc(false);
}

export function getUTCDate(date) {
	let utcDate = date instanceof Date ? date : new Date(date);
	utcDate = new Date(utcDate.getFullYear(), utcDate.getMonth(), utcDate.getDate(),utcDate.getHours(),utcDate.getMinutes(),utcDate.getSeconds(),0).toISOString();

	return moment(utcDate)
	.tz(TimeZone)
	.utc(false);
}
export function getLocalToUTC(date) {
	let utcDate = date instanceof Date ? date : new Date(date);
	utcDate = new Date(utcDate.getFullYear(), utcDate.getMonth(), utcDate.getDate(),12,0,0,0).toISOString();
	return moment(utcDate)
	.tz(TimeZone)
	.utc(false);
}
export function getTodayTime() {
	const date = moment.tz(TimeZone).utc(false);
	return date.toDate().getTime();
}
export function getUTCToLocal(date){
	console.log(date);
	const utcTime=moment(date).local();
	console.log(utcTime);
	return utcTime;
}
export function getUTCToLocal1(date){
	const utcTime=moment(date).local();

	return utcTime;
}
