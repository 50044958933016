import React, { Component } from "react";
import {
  Grid,
  Paper,
  Button,
  Typography,
  Box,
  TextField,
  InputAdornment
} from "@material-ui/core";
import {
  AvatarCommonComponent,
  DialogCommonPDFComponent,
  SnackbarCommonComponent,
  NumberFormatCustomComponent,
  LoadingComponent,
  DialogConfirmCommonComponent
} from "../common";
import clsx from "clsx";
import ModalSettingDeclaration from "./modalSettingDeclaration";
import { withStyles } from "@material-ui/core/styles";
import settingStyle from "./settingStyle";
import DrawerComponent from "./drawerComponent";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { Formik, Field, getIn } from "formik";
import {
  getUTCDate,
  getLocalToUTC,
  getUTCToLocal
} from "../../helpers/dateextensions";
import * as routes from "../../constants/routes";

import { connect } from "react-redux";
import {
  editEmployeePayrollForCloseFYRequest,
  changeEmpolyeeListForCloseFY,
  sendCloseFYAfterEditEmployeesRequest,
  redirectToStpCompeleted
} from "./../../actions/YECPActions";
import { bindActionCreators } from "redux";

class EditEmployeesForCloseFY extends Component {
  constructor(props) {
    super(props);
    this.state = {
      employeeActive: 0,
      editList: [],
      isValid1: false,
      isValid2: false,
      openEnable2FA: false,
      showCloseFYModalPDF: false,
      openSettingModalDeclartion: false
    };

    this.handleEditList();
  }
  handleEditList = () => {
    const { employeeList } = this.props;
    let editList = this.state.editList;
    for (let i = 0; i < employeeList && employeeList.length; i++) {
      editList[i] = false;
    }
    this.setState({
      editList
    });
  };
  componentDidMount() {
    const { employeeList, history } = this.props;

    if (!employeeList || employeeList == undefined) {
      history.push(routes.PATH_SETTINGS);
    }
  }
  DatePickerField = ({ field, form, classes, minDate, maxDate, ...other }) => {
    const currentError = form.errors[field.name];
    if (
      currentError == undefined &&
      field.name == "startDate" &&
      this.state.isValid1
    ) {
      this.setState({
        isValid1: false
      });
    } else if (
      currentError != undefined &&
      field.name == "startDate" &&
      !this.state.isValid1
    ) {
      this.setState({
        isValid1: true
      });
    }

    if (
      currentError == undefined &&
      field.name == "endDate" &&
      this.state.isValid2
    ) {
      this.setState({
        isValid2: false
      });
    } else if (
      currentError != undefined &&
      field.name == "endDate" &&
      !this.state.isValid2
    ) {
      this.setState({
        isValid2: true
      });
    }
    return (
      <KeyboardDatePicker
        type="date-local"
        name={field.name}
        label={field.label}
        value={getUTCToLocal(field.value)}
        format="DD/MM/YYYY"
        fullWidth
        error={Boolean(currentError)}
        onError={(_, error) => form.setFieldError(field.name, error)}
        onChange={date => form.setFieldValue(field.name, date, true)}
        className={clsx(classes.textField, {
          errorDatePicker: Boolean(currentError)
        })}
        InputProps={{
          classes: {
            root: classes.keyboardDatePicker,
            input: classes.keyboardDatePickerInput
          }
        }}
        minDate={minDate}
        maxDate={maxDate}
        {...other}
      />
    );
  };
  componentWillReceiveProps = nextProps => {
    const { message2, message } = nextProps;
    const {
      employeeList,
      yecp,
      sendCloseFYAfterEditEmployeesRequest
    } = this.props;
    if (message && message === "Success") {
      this.setState(
        {
          openSettingModalDeclartion: false
        },
        () => {
          this.props.redirectToStpCompeleted(true);
        }
      );
      const { history } = this.props;
      history.push(routes.PATH_SINGLETOUCH_PAYROLL);
    }
    if (message2 === "Success") {
      let editList = this.state.editList;
      editList[this.state.employeeActive] = true;
      for (let i = 0; i < employeeList.length; i++) {
        if (!editList[i]) {
          return this.setState({
            employeeActive: i
          });
        }
      }
      this.setState({
        editList
      });
      if (editList.indexOf(false) < 0) {
        this.setState({
          showCloseFYModalPDF: true
        });
        sendCloseFYAfterEditEmployeesRequest(yecp);
      }
    }
  };

  handleEditAmounts = () => {
    const { history } = this.props;
    history.goBack();
    // debugger;
    // history.push(routes.PATH_SETTINGS_SELECT);
  };

  handelLodgeFile = () => {
    const { employer } = this.props;
    console.log(employer);
    if (!employer.login.enable2FA) {
      this.setState({
        openEnable2FA: true
      });
    } else {
      this.setState({
        openSettingModalDeclartion: true,
        showCloseFYModalPDF: false
      });
    }
  };
  handleCloseSettingModal = () => {
    this.setState(() => ({
      openSettingModalDeclartion: false
    }));
  };
  handleRedirect2FA = () => {
    const { history } = this.props;
    history.push(routes.PATH_SETTINGS_2FA);
  };

  handleCloseEnable2FA = () => {
    this.setState(() => ({
      openEnable2FA: false
    }));
  };

  handleChoiceEmployee = i => {
    this.setState({
      employeeActive: i
    });
  };

  closeModalPDF = () => {
    this.setState({
      showCloseFYModalPDF: false
    });
  };

  handleName = name1 => {
    let name = name1;
    let n = name.split(" ");
    let abb = n[0].slice(0, 1) + n[1].slice(0, 1);
    return abb.toUpperCase();
  };

  handleEditEmployee = formProps => {
    const {
      employeeList,
      yecp,
      changeEmpolyeeListForCloseFY,
      editEmployeePayrollForCloseFYRequest
    } = this.props;
    let requestData = {
      allowances: formProps.allowances,
      deductions: formProps.deductions,
      grossWages: parseFloat(formProps.grossWages),
      salarySacrifice: parseFloat(formProps.salarySacrifice),
      superGuarantee: parseFloat(formProps.superGuarantee),
      taxes: parseFloat(formProps.taxes)
    };
    for (let i = 0; i < Object.keys(requestData.allowances).length; i++) {
      let key = Object.keys(requestData.allowances)[i];
      requestData.allowances[key] = parseFloat(requestData.allowances[key]);
    }
    for (let i = 0; i < Object.keys(requestData.deductions).length; i++) {
      let key = Object.keys(requestData.deductions)[i];
      requestData.deductions[key] = parseFloat(requestData.deductions[key]);
    }
    let list = employeeList.concat();
    list[this.state.employeeActive] = formProps;

    editEmployeePayrollForCloseFYRequest(yecp, formProps.id, requestData, list);
  };

  handleChangeValues = (formProps, field, value, position) => {
    if (position === "deductions") {
      formProps.setFieldValue(
        `deductions.${field}`,
        value / 1 ? value / 1 : value === "0.00" ? 0 : ""
      );
    } else if (position === "allowances") {
      formProps.setFieldValue(
        `allowances.${field}`,
        value / 1 ? value / 1 : value === "0.00" ? 0 : ""
      );
    } else if (field === "taxes") {
      if (value == "0.00") {
        formProps.setFieldValue(`${field}`, 0);
      } else {
        value = Math.floor(value);
        formProps.setFieldValue(
          `${field}`,
          value / 1 ? value / 1 : value === "0.00" || value === "0" ? 0 : ""
        );
      }
    } else {
      formProps.setFieldValue(
        `${field}`,
        value / 1 ? value / 1 : value === "0.00" || value === "0" ? 0 : ""
      );
    }
  };

  handleChangeAfterFocus = (formProps, field, value, position) => {
    if (value == "") {
      if (position === "deductions") {
        formProps.setFieldValue(`deductions.${field}`, "0");
      } else if (position === "allowances") {
        formProps.setFieldValue(`allowances.${field}`, "0");
      } else if (field === "taxes") {
        formProps.setFieldValue(`${field}`, "0.00");
      } else {
        formProps.setFieldValue(`${field}`, "0");
      }
    }
  };

  handleNetPay = employee => {
    let netPay = 0;
    let allowance = 0;
    let taxes = employee.taxes;
    let salarySacrifice = employee.salarySacrifice;
    let deductions = 0;
    let grossWages = employee.grossWages;
    Object.keys(employee.deductions).map(key => {
      if (key === "workplace giving" || key === "fees") {
        deductions += employee.deductions[key];
      }
    });

    Object.keys(employee.allowances).map(key => {
      allowance += employee.allowances[key];
    });
    let sum = allowance - (taxes + deductions + salarySacrifice);
    return grossWages + sum;
  };

  renderForm = formProps => {
    const { classes } = this.props;
    const employee = formProps.values;
    let netPay = this.handleNetPay(employee);
    return (
      <form>
        <div style={{ width: "60%" }}>
          <Paper
            className={classes.editEmployeeForm}
            style={{ mmaxHeight: 500 }}
          >
            <Grid
              item
              xs={12}
              className={classes.editEmployeeBox}
              style={{ maxWidth: 464 }}
            >
              <Paper className={classes.paddingAll} style={{ minHeight: 1000 }}>
                <Grid container wrap="nowrap" spacing={2} alignItems="center">
                  <Grid item>
                    <AvatarCommonComponent
                      src={employee.imageUrl}
                      text={this.handleName(employee.name)}
                      isBig
                    />
                  </Grid>
                  <Grid item xs>
                    <Typography variant="h4" className={classes.subtitleHeader}>
                      {employee.name}
                    </Typography>
                    <Typography variant="body1" className="gray">
                      {employee.jobDesc && employee.jobDesc}
                    </Typography>
                  </Grid>
                </Grid>
{/*
                <Grid
                  container
                  direction="row"
                  alignItems="flex-start"
                  justify="space-around"
                  style={{ marginTop: 6 }}
                >
                  <Grid item xs={6}>
                    <Paper className={classes.paperGrid2}>
                      <Field
                        name="startDate"
                        label="Start Date"
                        component={this.DatePickerField}
                        classes={classes}
                        // minDate={getUTCDate(employer.financialYear.dateFrom)}
                        maxDate={employee.endDate}
                      />
                    </Paper>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    container
                    direction="row"
                    alignItems="flex-end"
                    justify="space-around"
                  >
                    <Paper className={classes.paperGrid2}>
                      <Field
                        name="endDate"
                        label="End Date"
                        component={this.DatePickerField}
                        classes={classes}
                        minDate={employee.startDate}
                        // maxDate={getUTCDate(employer.financialYear.dateTo)}
                      />
                    </Paper>
                  </Grid>
                </Grid> */}

                {/* start grossWages */}

                <Grid
                  container
                  direction="row"
                  alignItems="flex-start"
                  justify="space-around"
                  style={{ marginTop: 8 }}
                >
                  <Grid
                    item
                    xs={6}
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      height: 35
                    }}
                  >
                    <Typography
                      variant="body1"
                      className={classes.editEmployeeLabel}
                    >
                      Gross Wages
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={6}
                    container
                    direction="row"
                    alignItems="flex-end"
                    justify="space-around"
                  >
                    <Paper className={classes.paperGrid}>
                      <TextField
                        id="grossWages"
                        fullWidth
                        onBlur={() =>
                          this.handleChangeAfterFocus(
                            formProps,
                            "grossWages",
                            employee.grossWages
                          )
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                          inputComponent: NumberFormatCustomComponent,
                          inputProps: {
                            decimalScale: 2,
                            fixedDecimalScale: true
                          }
                        }}
                        value={employee.grossWages}
                        onChange={value => {
                          this.handleChangeValues(
                            formProps,
                            "grossWages",
                            value.target.value
                          );
                        }}
                        className={clsx(classes.textFieldEdit, {
                          error:
                            getIn(formProps.errors, "grossWages") &&
                            getIn(formProps.touched, "grossWages")
                        })}
                      />
                    </Paper>
                  </Grid>
                </Grid>

                {/* end grossWages */}

                {/* start Plus Taxable Allowances */}
                {Object.entries(employee.allowances).length != 0 && (
                  <Grid
                    container
                    direction="row"
                    alignItems="flex-start"
                    justify="space-around"
                  >
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        height: 35
                      }}
                    >
                      <Typography
                        variant="body1"
                        className={classes.editEmployeeLabelTitle}
                      >
                        Plus Taxable Allowances
                      </Typography>
                    </Grid>
                  </Grid>
                )}

                {Object.entries(employee.allowances).length != 0 &&
                  Object.keys(employee.allowances).map((key, i) => (
                    <Grid
                      container
                      direction="row"
                      alignItems="flex-start"
                      justify="space-around"
                      key={i}
                    >
                      <Grid
                        item
                        xs={6}
                        style={{
                          display: "flex",
                          justifyContent: "start",
                          alignItems: "center",
                          height: 35
                        }}
                      >
                        <Typography
                          variant="body1"
                          className={classes.editEmployeeLabel}
                        >
                          {key.charAt(0).toUpperCase() + key.slice(1)}
                        </Typography>
                      </Grid>

                      <Grid
                        item
                        xs={6}
                        container
                        direction="row"
                        alignItems="flex-end"
                        justify="space-around"
                      >
                        <Paper className={classes.paperGrid}>
                          <TextField
                            id={key}
                            fullWidth
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              ),
                              inputComponent: NumberFormatCustomComponent,
                              inputProps: {
                                decimalScale: 2,
                                fixedDecimalScale: true
                              }
                            }}
                            value={employee.allowances[key]}
                            onBlur={() =>
                              this.handleChangeAfterFocus(
                                formProps,
                                key,
                                employee.allowances[key],
                                "allowances"
                              )
                            }
                            onChange={value => {
                              this.handleChangeValues(
                                formProps,
                                key,
                                value.target.value,
                                "allowances"
                              );
                            }}
                            className={clsx(classes.textFieldEdit, {
                              error:
                                getIn(formProps.errors, { key }) &&
                                getIn(formProps.touched, { key })
                            })}
                          />
                        </Paper>
                      </Grid>
                    </Grid>
                  ))}

                {/* end Plus Taxable Allowances */}

                {/* Less Start*/}

                <Grid
                  container
                  direction="row"
                  alignItems="flex-start"
                  justify="space-around"
                >
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      height: 35
                    }}
                  >
                    <Typography
                      variant="body1"
                      className={classes.editEmployeeLabelTitle}
                    >
                      Less
                    </Typography>
                  </Grid>
                </Grid>
                {/* Less End*/}

                {/* start taxes */}

                <Grid
                  container
                  direction="row"
                  alignItems="flex-start"
                  justify="space-around"
                >
                  <Grid
                    item
                    xs={6}
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      height: 35
                    }}
                  >
                    <Typography
                      variant="body1"
                      className={classes.editEmployeeLabel}
                    >
                      Taxes
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={6}
                    container
                    direction="row"
                    alignItems="flex-end"
                    justify="space-around"
                  >
                    <Paper className={classes.paperGrid}>
                      <TextField
                        id="taxes"
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                          inputComponent: NumberFormatCustomComponent,
                          inputProps: {
                            decimalScale: 2,
                            fixedDecimalScale: true
                          }
                        }}
                        value={employee.taxes}
                        onBlur={() =>
                          this.handleChangeAfterFocus(
                            formProps,
                            "taxes",
                            employee.taxes
                          )
                        }
                        onChange={value => {
                          this.handleChangeValues(
                            formProps,
                            "taxes",
                            value.target.value
                          );
                        }}
                        className={clsx(classes.textFieldEdit, {
                          error:
                            getIn(formProps.errors, "taxes") &&
                            getIn(formProps.touched, "taxes")
                        })}
                      />
                    </Paper>
                  </Grid>
                </Grid>

                {/* end taxes */}
                {/* end d */}
                {Object.entries(employee.deductions).length != 0 &&
                  Object.keys(employee.deductions).map((key, i) => {
                    if (key === "workplace giving") {
                      return (
                        <Grid
                          container
                          direction="row"
                          alignItems="flex-start"
                          justify="space-around"
                          key={i}
                        >
                          <Grid
                            item
                            xs={6}
                            style={{
                              display: "flex",
                              justifyContent: "start",
                              alignItems: "center",
                              height: 35
                            }}
                          >
                            <Typography
                              variant="body1"
                              className={classes.editEmployeeLabel}
                            >
                              {key.charAt(0).toUpperCase() + key.slice(1)}
                            </Typography>
                          </Grid>

                          <Grid
                            item
                            xs={6}
                            container
                            direction="row"
                            alignItems="flex-end"
                            justify="space-around"
                          >
                            <Paper className={classes.paperGrid}>
                              <TextField
                                id={key}
                                fullWidth
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      $
                                    </InputAdornment>
                                  ),
                                  inputComponent: NumberFormatCustomComponent,
                                  inputProps: {
                                    decimalScale: 2,
                                    fixedDecimalScale: true
                                  }
                                }}
                                value={employee.deductions[key]}
                                onBlur={() =>
                                  this.handleChangeAfterFocus(
                                    formProps,
                                    key,
                                    employee.deductions[key],
                                    "deductions"
                                  )
                                }
                                onChange={value => {
                                  this.handleChangeValues(
                                    formProps,
                                    key,
                                    value.target.value,
                                    "deductions"
                                  );
                                }}
                                className={clsx(classes.textFieldEdit, {
                                  error:
                                    getIn(formProps.errors, { key }) &&
                                    getIn(formProps.touched, { key })
                                })}
                              />
                            </Paper>
                          </Grid>
                        </Grid>
                      );
                    }
                  })}
                {Object.entries(employee.deductions).length != 0 &&
                  Object.keys(employee.deductions).map((key, i) => {
                    if (key === "fees") {
                      return (
                        <Grid
                          container
                          direction="row"
                          alignItems="flex-start"
                          justify="space-around"
                          key={i}
                        >
                          <Grid
                            item
                            xs={6}
                            style={{
                              display: "flex",
                              justifyContent: "start",
                              alignItems: "center",
                              height: 35
                            }}
                          >
                            <Typography
                              variant="body1"
                              className={classes.editEmployeeLabel}
                            >
                              {key.charAt(0).toUpperCase() + key.slice(1)}
                            </Typography>
                          </Grid>

                          <Grid
                            item
                            xs={6}
                            container
                            direction="row"
                            alignItems="flex-end"
                            justify="space-around"
                          >
                            <Paper className={classes.paperGrid}>
                              <TextField
                                id={key}
                                fullWidth
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      $
                                    </InputAdornment>
                                  ),
                                  inputComponent: NumberFormatCustomComponent,
                                  inputProps: {
                                    decimalScale: 2,
                                    fixedDecimalScale: true
                                  }
                                }}
                                value={employee.deductions[key]}
                                onBlur={() =>
                                  this.handleChangeAfterFocus(
                                    formProps,
                                    key,
                                    employee.deductions[key],
                                    "deductions"
                                  )
                                }
                                onChange={value => {
                                  this.handleChangeValues(
                                    formProps,
                                    key,
                                    value.target.value,
                                    "deductions"
                                  );
                                }}
                                className={clsx(classes.textFieldEdit, {
                                  error:
                                    getIn(formProps.errors, { key }) &&
                                    getIn(formProps.touched, { key })
                                })}
                              />
                            </Paper>
                          </Grid>
                        </Grid>
                      );
                    }
                  })}
                {/* end taxes */}

                {employee.salarySacrifice != null && (
                  <Grid
                    container
                    direction="row"
                    alignItems="flex-start"
                    justify="space-around"
                  >
                    <Grid
                      item
                      xs={6}
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        height: 35
                      }}
                    >
                      <Typography
                        variant="body1"
                        className={classes.editEmployeeLabel}
                      >
                        Salary Sacrifice
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      xs={6}
                      container
                      direction="row"
                      alignItems="flex-end"
                      justify="space-around"
                    >
                      <Paper className={classes.paperGrid}>
                        <TextField
                          id="salarySacrifice"
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                            inputComponent: NumberFormatCustomComponent,
                            inputProps: {
                              decimalScale: 2,
                              fixedDecimalScale: true
                            }
                          }}
                          value={employee.salarySacrifice}
                          onBlur={() =>
                            this.handleChangeAfterFocus(
                              formProps,
                              "salarySacrifice",
                              employee.salarySacrifice
                            )
                          }
                          onChange={value => {
                            this.handleChangeValues(
                              formProps,
                              "salarySacrifice",
                              value.target.value
                            );
                          }}
                          className={clsx(classes.textFieldEdit, {
                            error:
                              getIn(formProps.errors, "salarySacrifice") &&
                              getIn(formProps.touched, "salarySacrifice")
                          })}
                        />
                      </Paper>
                    </Grid>
                  </Grid>
                )}
                {/* end taxes */}
                {/* end taxes */}

    
                <Typography
                  variant="body1"
                  className={classes.editEmployeeLabelTitle}
                >
                  With
                </Typography>
                {/* end taxes */}
                {employee.superGuarantee != null && (
                  <Grid
                    container
                    direction="row"
                    alignItems="flex-start"
                    justify="space-around"
                  >
                    <Grid
                      item
                      xs={6}
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        height: 35
                      }}
                    >
                      <Typography
                        variant="body1"
                        className={classes.editEmployeeLabel}
                      >
                        Super Guarantee
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      xs={6}
                      container
                      direction="row"
                      alignItems="flex-end"
                      justify="space-around"
                    >
                      <Paper className={classes.paperGrid}>
                        <TextField
                          id="superGuarantee"
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                            inputComponent: NumberFormatCustomComponent,
                            inputProps: {
                              decimalScale: 2,
                              fixedDecimalScale: true
                            }
                          }}
                          value={employee.superGuarantee}
                          onBlur={() =>
                            this.handleChangeAfterFocus(
                              formProps,
                              "superGuarantee",
                              employee.superGuarantee
                            )
                          }
                          onChange={value => {
                            this.handleChangeValues(
                              formProps,
                              "superGuarantee",
                              value.target.value
                            );
                          }}
                          className={clsx(classes.textFieldEdit, {
                            error:
                              getIn(formProps.errors, "superGuarantee") &&
                              getIn(formProps.touched, "superGuarantee")
                          })}
                        />
                      </Paper>
                    </Grid>
                  </Grid>
                )}
                {/* end taxes */}

                <Grid item xs={12} className={classes.gridRight}>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={clsx(
                      classes.btnProcessPayrol1,
                      classes.orangeRectangularBtn
                    )}
                    onClick={() => this.handleEditEmployee(formProps.values)}
                    // onClick={formProps.handleSubmit}
                    disabled={this.state.isValid1 || this.state.isValid2}
                  >
                    {"Continue"}
                  </Button>
                </Grid>
              </Paper>
            </Grid>
          </Paper>
        </div>
      </form>
    );
  };

  render() {
    const {
      classes,
      error,
      yecp,
      isLoading,
      employeeList,
      includeEmployees,
      history
    } = this.props;
    let {
      openEnable2FA,
      showCloseFYModalPDF,
      openSettingModalDeclartion
    } = this.state;
    if (employeeList == undefined) {
      return <p>go to setting</p>;
    } else
      return (
        <Paper xs={6} className={classes.editEmployee}>
          <Typography variant="h1" className={classes.editEmployeeTitle}>
            Review Employees {yecp} Final STP File Values
          </Typography>
          <Typography
            variant="body1"
            className={classes.editEmployeesdescription}
          >
            Review the values and adjust if necessary. When correct, hit the
            'Continue' button to bring up your next employee.
          </Typography>

          <Formik
            initialValues={
              employeeList && employeeList[this.state.employeeActive]
            }
            validationSchema={this.validationSchema}
            // onSubmit={this.handleEditEmployee}
            render={this.renderForm}
            enableReinitialize
          />

          <DrawerComponent
            handleChoiceEmployee={this.handleChoiceEmployee}
            {...this.props}
            employeeActive={this.state.employeeActive}
            editList={this.state.editList}
          />
          {includeEmployees && showCloseFYModalPDF && (
            <DialogCommonPDFComponent
              title={`${yecp} Final STP File Pre Lodgement Summary`}
              open={showCloseFYModalPDF}
              onCloseModal={this.closeModalPDF}
              url={
                this.props.closeFY.reportURL
                  ? this.props.closeFY.reportURL
                  : false
              }
              primaryButtonName="Lodge File"
              onPrimaryButtonClick={this.handelLodgeFile}
              downloadPdf={
                this.props.closeFY.reportURL
                  ? this.props.closeFY.reportURL
                  : false
              }
              downloadStyle={{
                position: "absolute",
                width: 54,
                height: 54,
                backgroundColor: "#F2F2F2",
                bottom: 130,
                right: 50,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "50%"
              }}
              style={{
                borderRadius: 5,
                height: 48,
                width: 154,
                padding: 0,
                marginRight: 16,
                background: "#FD5739 0% 0% no-repeat padding-box",
                opacity: 1
              }}
              style2={{
                textAlign: "center",
                letterSpacing: 0,
                color: "#ffffff",
                opacity: 1,
                fontWeigth: "bold",
                fontSize: "18px",
                fontFamily: "AvenirNextLTPro-Demi"
              }}
              styleDiscard={{
                color: "#004FDC",
                fontFamily: "AvenirNextLTPro-Demi",
                position: "absolute",
                left: 40,
                opacity: 1,
                bottom: 28,
                cursor: "pointer",
                fontSize: 16,
                letterSpacing: 0
              }}
              styleTitle={{
                textAlign: "left",
                letterSpacing: 0,
                color: "#000000",
                opacity: 1,
                fontSize: 17,
                fontFamily: "AvenirNextLTPro-Demi"
              }}
              secondaryButtonNameDiscard="Edit Amounts"
              onSecondaryButtonClick={this.handleEditAmounts}
              showScroll
              textInfo={`Click 'Lodge File' to submit Final ${yecp} STP File or click 'Edit Amounts' to make changes`}
              textInfoStyle={{
                textAlign: "end !important",
                fontFamily: "AvenirNextLTPro-Demi",
                marginBottom: 5,
                fontSize: 16,
                letterSpacing: 0,
                color: "#000000",
                opacity: 1
              }}
            />
          )}
          {openEnable2FA ? (
            <DialogConfirmCommonComponent
              open={openEnable2FA}
              title="Two-Factor Authentication is required"
              primaryButtonName="Enable"
              onPrimaryButtonClick={this.handleRedirect2FA}
              secondaryButtonName="Cancel"
              onSecondaryButtonClick={this.handleCloseEnable2FA}
              onCloseModal={this.handleCloseEnable2FA}
            >
              <Typography
                variant="body1"
                gutterBottom
                className={classes.description}
              >
                {
                  "We have added a new security layer to lodge STP files with ATO, enable the Two-Factor Authentication in 3 simple steps"
                }
              </Typography>
            </DialogConfirmCommonComponent>
          ) : null}
          {openSettingModalDeclartion ? (
            <ModalSettingDeclaration
              open={openSettingModalDeclartion}
              onCloseModal={this.handleCloseSettingModal}
              yecp={yecp}
            />
          ) : null}
          {error ? (
            <SnackbarCommonComponent
              onCloseModal={this.handleMessageClose}
              open={error}
              body="This employees already has a payslip for this time period."
            />
          ) : null}
          <LoadingComponent isLoading={isLoading} />
        </Paper>
      );
  }
}

const mapStateToProps = state => ({
  message2: state.yecp.message2,
  isLoading: state.yecp.isLoading,
  employeeList: state.yecp.employeeListForEdit,
  yecp: state.yecp.yecpName,
  closeFY: state.yecp.closeFY,
  includeEmployees: state.yecp.closeFY.includeEmployees,
  employer: state.session.userData,
  message: state.yecp.message
});

const mapDispatchToProps = dispatch => ({
  editEmployeePayrollForCloseFYRequest: bindActionCreators(
    editEmployeePayrollForCloseFYRequest,
    dispatch
  ),
  changeEmpolyeeListForCloseFY: bindActionCreators(
    changeEmpolyeeListForCloseFY,
    dispatch
  ),
  sendCloseFYAfterEditEmployeesRequest: bindActionCreators(
    sendCloseFYAfterEditEmployeesRequest,
    dispatch
  ),
  redirectToStpCompeleted: bindActionCreators(redirectToStpCompeleted, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(settingStyle)(EditEmployeesForCloseFY));
