import React, { Component } from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import {
	Button, Grid, Table, TableHead, TableBody, TableCell, TableRow, Typography, MenuItem
} from "@material-ui/core";

import { MenuItemComponent, SnackbarCommonComponent } from "../common";

import PayrollStyle from "./payrollStyle";
import ModalQuantity from "./modalQuantity";
import { payrollQuantitiesDefaultData } from "../../reducers/Payroll/PayrollInitialState";
import * as messages from "../../constants/messages";
import EditQuantityComponent from "./editQuantityComponent";

class QuantityComponent extends Component {
	constructor(props) {
		super(props)
		this.addQuantities(props);
	}
	state = {
		check: true,
		quantities: [],

		showModal: false,
		error: {
			show: false,
			message: ""
		},
		editItem: {
			isEditing: false,
			data: null,
			index: -1
		},
		test: false
	};

	componentDidMount() {
		const { selectedPayrollData, formProps } = this.props;
		const newQuantities = [];
		selectedPayrollData.employeeActive.quantities && selectedPayrollData.employeeActive.quantities.length > 0 && selectedPayrollData.employeeActive.quantities.map((value) => {
			newQuantities.push(this.setValueAdd(value));
		})
		formProps.setFieldValue("payroll.quantities", newQuantities);
	}

	handleClickOption = () => {
		this.setState(state => ({
			...state,
			showModal: true,
			error: {
				...state.error,
				show: false
			}
		}));
	};

	handleCloseModal = () => {
		this.setState(state => ({
			...state,
			showModal: false,
			error: {
				...state.error,
				show: false
			},
			editItem: {
				isEditing: false,
				data: null,
				index: -1
			}
		}));
	};

	setValueAdd = (value) => {
		console.log(value);
		let id = value.id;
		if (!id && id !== 0) {
			id = parseInt(value.name.replace("Job / Unit", "").trim()) - 1;
		}

		const valueAdd = {
			...payrollQuantitiesDefaultData,
			id: id,
			name: value.name ? value.name.trim() : `Job / Unit ${value.id + 1}`,
			description: value.description && value.description.trim(),
			quantity: parseFloat(value.quantity).toFixed(2),
			rate: parseFloat(value.rate).toFixed(4),
			totalAmount: value.totalAmount ? parseFloat(value.totalAmount).toFixed(2) : (value.quantity * value.rate).toFixed(2).toString()
		};
		return valueAdd;
	};

	handleAdd = (value) => {
		const { formProps } = this.props;
		const { quantities } = formProps.values.payroll;
		this.handleShowError(false, "");

		if (!quantities.some(item => value.name === item.name)) {
			const newQuantities = [...quantities];
			newQuantities.push(this.setValueAdd(value));
			formProps.setFieldValue("payroll.quantities", newQuantities);
			this.handleCloseModal();
		} else {
			this.handleShowError(true, messages.MESSAGE_PAYROLL_QUANTITIE_EXISTS);
		}
	};

	handleUpdate = (value) => {
		const { formProps } = this.props;
		const { editItem } = this.state;
		const { quantities } = formProps.values.payroll;
		const newQuantities = [...quantities];
		newQuantities.splice(editItem.index, 1);
		newQuantities.push(this.setValueAdd(value));
		formProps.setFieldValue("payroll.quantities", newQuantities);

		this.handleCloseModal();
	};

	handleRemoveItem = (event, index) => {
		const { formProps } = this.props;
		const { quantities } = formProps.values.payroll;
		const newQuantities = [...quantities];
		newQuantities.splice(index, 1);
		formProps.setFieldValue("payroll.quantities", newQuantities);
		if (this.state.check)
			this.setState({ check: false })
	};

	handleEditItem = (event, index) => {
		const { formProps } = this.props;
		const { quantities } = formProps.values.payroll;

		this.setState(state => ({
			...state,
			showModal: true,
			editItem: {
				isEditing: true,
				data: quantities[index],
				index
			}
		}));
	};

	handleShowError = (show, message) => {
		this.setState(state => ({
			...state,
			error: {
				show,
				message
			}
		}));
	};


	addQuantities(props) {
		const { selectedPayrollData, formProps } = props;
		const { quantities } = formProps.values.payroll;
		const newQuantities = [];

		if (selectedPayrollData.employeeActive
			&& selectedPayrollData.employeeActive.quantities
			&& selectedPayrollData.employeeActive.quantities.length > 0) {
			selectedPayrollData.employeeActive.quantities.map((value) => {
				newQuantities.push(this.setValueAdd(value));
			})
			this.setState({ "quantities": newQuantities, check: true });
		}
	}

	componentWillReceiveProps(nextProps) {
		const { selectedPayrollData } = nextProps;

		const selectedPayrollDataLast = this.props.selectedPayrollData;
		let check = (selectedPayrollData.employeeActive
			&& selectedPayrollData.employeeActive.quantities
			&& selectedPayrollData.employeeActive.quantities.length > 0);
		if (check) {
			if (selectedPayrollDataLast && selectedPayrollDataLast.employeeActive
				&& selectedPayrollDataLast.employeeActive.quantities
				&& selectedPayrollDataLast.employeeActive.quantities.length > 0) {
				if (selectedPayrollDataLast.employeeActive.quantities.length == selectedPayrollData.employeeActive.quantities.length) {
					check = false;
				}
			}
		}

		//if (check) {
		this.addQuantities(nextProps);
		//}

	}

	render() {
		const { classes, formProps, isFinishAllEmployees } = this.props;
		const { showModal, error, editItem, quantities } = this.state;


		// if (this.state.check && quantities && quantities.length > 0 && !(formProps.values.payroll.quantities && formProps.values.payroll.quantities.length > 0)) {
		// 	formProps.setFieldValue("payroll.quantities", quantities);

		// }

		const check = (formProps.values.payroll.quantities && formProps.values.payroll.quantities.length > 0);

		return (
			<Grid className={classes.gridContainer}>
				<Grid container direction="row" alignItems="flex-start">
					<Grid item xs={6}>
						<Typography variant="h4" gutterBottom>
							Per Job / Unit
						</Typography>
					</Grid>
					<Grid item xs={6} className={classes.gridRight}>
						<Button variant="outlined" color="secondary" className={classes.button} onClick={this.handleClickOption} disabled={isFinishAllEmployees}>
							+ Add Job / Unit
						</Button>
						{showModal ? <ModalQuantity open={showModal} edit={editItem.isEditing} data={editItem.data} onAddValues={editItem.isEditing ? this.handleUpdate : this.handleAdd} onCloseModal={this.handleCloseModal} /> : null}
					</Grid>
				</Grid>
				{!check ? (
					<Typography variant="h4" component="div" className={classes.placeHolder}>
						{"You haven’t added  Per Job / Unit"}
					</Typography>
				) : null}
				{check ? (
					<Table className={classes.table} size="medium">
						<TableHead className={classes.tableH}>
							<TableRow>
								<TableCell className={classes.tableTitle}>Description</TableCell>
								<TableCell className={classes.tableTitle}>Qty</TableCell>
								<TableCell className={classes.tableTitle}>Rate</TableCell>
								<TableCell className={classes.tableTitle}>Total</TableCell>
								<TableCell className={classes.tableTitle} align="right" />
							</TableRow>
						</TableHead>
						<TableBody>
							{formProps.values.payroll.quantities && formProps.values.payroll.quantities.length > 0 ? (
								<React.Fragment>
									{formProps.values.payroll.quantities.map((row, index) => (
										<EditQuantityComponent
											row={{...row, totalAmount: row.quantity * row.rate}}
											index={index}
											key={index}
											parentProps={formProps}
											handelChange={this.handelChange}
											handleRemoveItem={this.handleRemoveItem}
										/>
									))}
								</React.Fragment>
							) :

								<React.Fragment>
									{this.state.quantities1.map((row, index) => (
										<EditQuantityComponent
											row={{...row, totalAmount: row.quantity * row.rate}}
											index={index}
											key={index}
											parentProps={formProps}
											handelChange={this.handelChange}
											handleRemoveItem={this.handleRemoveItem}
										/>
									))}
								</React.Fragment>



							}
						</TableBody>
					</Table>
				) : null}

				{error && error.show ? <SnackbarCommonComponent variant="error" open={error.show} body={error.message} /> : null}
			</Grid>
		);
	}
}

QuantityComponent.propTypes = {
	formProps: PropTypes.object.isRequired
};

export default withStyles(PayrollStyle)(QuantityComponent);
