import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Card,
  CardHeader,
  CardContent,
  Grid,
  Icon,
  Paper,
  Typography
} from "@material-ui/core";
import SettingStyle from "./settingStyle";
import { SwitchCommonComponent } from "../common";
import * as common from "../../constants/common";
import localStorageApi from "../../api/LocalStorageApi";
import Modal2FAConfirmPasswordComponent from "./modal2FAConfirmPasswordComponent";
import { cleanMessage } from "../../actions/EmployerActions";
import * as routes from "../../constants/routes";
class TwoFactorAuthenticationComponent extends Component {
  constructor() {
    super();

    this.state = {
      showPopup2FA: false,
      enable2FA: false,
      showPopupConfirmPassword: false,
      isRevoke: false
    };
  }

  componentDidMount() {
    const { employer, isAccountant, accountantDetails } = this.props;

    if (isAccountant && accountantDetails && accountantDetails.login) {
      this.setState(state => ({
        ...state,
        enable2FA: accountantDetails.login.enabled2FA
      }));
    } else if (isAccountant && employer && employer.login) {
      this.setState(state => ({
        ...state,
        enable2FA: employer.login.enabled2FA
      }));
    } else if (employer.login) {
      this.setState(state => ({
        ...state,
        enable2FA: employer.login.enable2FA
      }));
    }
  }
  componentWillReceiveProps(nextProps) {
    const { employer, isAccountant } = nextProps;
    const userData = localStorageApi.getItem(common.STORAGE_SESSION_NAME);

    this.setState(state => ({
      ...state,
      enable2FA: isAccountant
        ? userData.login.enabled2FA
        : employer.login.enable2FA
    }));
  }

  // 	componentWillReceiveProps = (nextProps) => {
  // 		const { employer, accountantDetails } = nextProps;
  // debugger
  // 		var enabeled2fa = (this.props.employer && this.props.employer.login) ? this.props.employer.login.enabled2FA : this.state.enable2FA;
  // 		if (employer && employer.accountType === "accountant") {

  // 			if (accountantDetails && accountantDetails.login && accountantDetails.login.enabled2FA != enabeled2fa) {
  // 				this.setState(state => ({
  // 					//showPopupConfirmPassword: accountantDetails.login.enabled2FA,
  // 					enable2FA: accountantDetails.login.enabled2FA
  // 				}));
  // 			} else if (employer && employer.login && employer.login.enabled2FA != enabeled2fa) {
  // 				this.setState(state => ({
  // 					//	showPopupConfirmPassword: employer.login.enabled2FA,
  // 					enable2FA: employer.login.enabled2FA
  // 				}));
  // 			} else {
  // 				if (accountantDetails && accountantDetails.login && !accountantDetails.login.enabled2FA)
  // 					this.setState(state => ({
  // 						//showPopupConfirmPassword: accountantDetails.login.enabled2FA,
  // 						enable2FA: accountantDetails.login.enabled2FA
  // 					}));

  // 			}
  // 		} else if (employer && employer.login && employer.login.enable2FA != enabeled2fa) {

  // 			this.setState(state => ({
  // 				showPopupConfirmPassword: !employer.login.enable2FA ? false : true,
  // 				enable2FA: employer.login.enable2FA
  // 			}));
  // 		}

  // 	}

  handleChangeSwitch = event => {
    const { history, cleanMessages } = this.props;
    cleanMessages();
    this.setState({
      showPopup2FA: true
    });
    if (event.target.checked) {
      history.push(routes.PATH_SETTINGS_2FA_CHOICE);
      return 1;
    }
    // debugger
    const { employer, accountantDetails } = this.props;
    if (employer.accountType === "accountant") {
      const userData = localStorageApi.getItem(common.STORAGE_SESSION_NAME);
      if (!event.target.checked && userData.login.enabled2FA) {
        this.setState({
          showPopupConfirmPassword: true,
          isRevoke: true
        });
      }
    } else {
      if (!event.target.checked && employer.login.enable2FA) {
        this.setState({
          showPopupConfirmPassword: true,
          isRevoke: false
        });
      }
    }
  };

  handleRevokeAccess = () => {
    const { cleanMessages } = this.props;
    cleanMessages();
    // debugger
    this.setState({ showPopupConfirmPassword: true, isRevoke: true });
  };

  handleEnabled2FA = () => {
    this.setState({ showPopup2FA: false });
  };

  handleCloseModal = () => {
    const { isValidValidate } = this.props;
    this.setState({ showPopup2FA: false, enable2FA: isValidValidate });
  };

  handleCloseModalConfirmPassword = () => {
    const { isValidDisabled, employer, isValidRevoke } = this.props;
    const { isRevoke, showPopup2FA } = this.state;
    // debugger
    if (employer.accountType === "accountant") {
      if (isValidRevoke) {
        // debugger
        this.setState({
          showPopupConfirmPassword: false,
          showPopup2FA: !isRevoke,
          enable2FA: isValidRevoke
        });
      } else {
        // debugger
        this.setState({
          showPopupConfirmPassword: false,
          showPopup2FA: !isRevoke,
          enable2FA: employer.login ? employer.login.enabled2FA : false
        });
      }
    } else {
      //  debugger
      this.setState({
        showPopupConfirmPassword: isValidDisabled,
        showPopup2FA: isRevoke
        //enable2FA: employer.accountType === "accountant" ? !isValidRevoke : !isValidDisabled
      });
    }
  };

  render() {
    const { classes } = this.props;
    const {
      isRevoke,
      showPopupConfirmPassword,
      enable2FA,
      showPopup2FA
    } = this.state;
    console.log("showPopup2FA", showPopup2FA);
    console.log("enable2FA", enable2FA);
    console.log("isRevoke", isRevoke);
    console.log("showPopupConfirmPassword", showPopupConfirmPassword);
    // debugger
    return (
      <div className={classes.root}>
        <Paper className={classes.paperContainer}>
          <Typography variant="h1">Two-Factor Authentication</Typography>
          <Typography variant="body1">
            Two-Factor authentication is an extra layer of security for your
            Easy Payslip account designed to ensure that you&#39;re the only
            person who can access your account, even if someone knows your
            password.
          </Typography>

          <Box m={7} />

          <Grid container wrap="nowrap" spacing={2} alignItems="center">
            <Grid item xs={5}>
              {console.log("enable2FA", enable2FA)}
              <SwitchCommonComponent
                title="Enable Two-Factor Authentication"
                checked={enable2FA}
                onChange={this.handleChangeSwitch}
                value="enable2FA"
              />
            </Grid>
          </Grid>

          <Box m={2.5} />

          {enable2FA ? (
            <Card>
              <CardHeader
                avatar={<Icon className={classes.iconCheck}>check_circle</Icon>}
                title="Connect an authentication app"
                disableTypography
              />
              <CardContent>
                <Paper className={classes.cardRevoke}>
                  <Typography variant="body1" className="title" gutterBottom>
                    {"All good!"}
                  </Typography>
                  <Typography variant="body1">
                    {"Two-Factor authentication is enabled"}
                  </Typography>
                </Paper>
              </CardContent>
            </Card>
          ) : null}
        </Paper>
        {/* {showPopup2FA ? <Modal2FAConfigComponent onPrimaryButtonClick={this.handleEnabled2FA} open={showPopup2FA} onCloseModal={this.handleCloseModal} /> : null} */}
        {showPopup2FA && enable2FA ? (
          <Modal2FAConfirmPasswordComponent
            open={showPopup2FA || showPopupConfirmPassword}
            isRevoke={isRevoke}
            onCloseModal={this.handleCloseModalConfirmPassword}
          />
        ) : null}
      </div>
    );
  }
}

TwoFactorAuthenticationComponent.propTypes = {
  isValidDisabled: PropTypes.bool,
  isValidRevoke: PropTypes.bool,
  isValidValidate: PropTypes.bool,
  isAccountant: PropTypes.bool
};

const mapStateToProps = state => {
  return {
    error: state.employer.error,
    errorMessage: state.employer.errorMessage,
    employer: state.session.userData,
    accountant: state.accountantDetails.accountantDetails,
    isAccountant:
      !state.session.isEmployee && state.employersAccountant.isAccountant,

    isValidValidate: state.employer.isValidValidate,
    isValidDisabled: state.employer.isValidDisabled,
    isValidRevoke: state.employer.isValidRevoke,
    accountantDetails: state.accountantDetails.accountantDetails
  };
};

const mapDispatchToProps = dispatch => ({
  cleanMessages: bindActionCreators(cleanMessage, dispatch)
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(SettingStyle)(TwoFactorAuthenticationComponent))
);
