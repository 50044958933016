import * as actionTypes from "../../constants/actionTypes";
import employeeInitialState, { employeeDefaultData } from "./EmployeeInitialState";

const employeeReducer = (state = employeeInitialState, action) => {
	switch (action.type) {
		case actionTypes.ACTION_EMPLOYEES_REQUEST: {
			return {
				...state,
				isLoading: true
			};
		}
		case actionTypes.ACTION_EMPLOYEES_REQUEST_SUCCESS: {
			return {
				...state,
				isLoading: false,
				list: action.payload.list,
				activeEmployeesList: action.payload.activeEmployeesList,
				inactiveEmployeesList: action.payload.inactiveEmployeesList,
				unfinishedEmployeeList: action.payload.unfinishedEmployeeList,
				reportsEmployeeList: action.payload.reportsEmployeeList
			};
		}
		case actionTypes.ACTION_EMPLOYEES_REQUEST_FAILURE: {
			return {
				...state,
				error: true,
				isLoading: false,
				errorMessage: action.payload.errorMessage,
				messageCode: action.payload.messageCode,
				message: action.payload.message
			};
		}
		case actionTypes.ACTION_EMPLOYEE_SAVE_DATA_REQUEST: {
			return {
				...state,
				isLoading: true,
				isSaving: true,
				selectedEmployeeData: {
					...state.selectedEmployeeData
				}
			};
		}
		case actionTypes.ACTION_EMPLOYEE_SAVE_DATA_REQUEST_SUCCESS: {
			return {
				...state,
				isLoading: false,
				isSaving: false,
				selectedEmployeeData: {
					...employeeDefaultData,
					...action.payload.selectedEmployeeData
				},
				messageCode: action.payload.messageCode,
				message: action.payload.message,

			};
		}
		case actionTypes.ACTION_EMPLOYEE_SAVE_DATA_REQUEST_FAILURE: {
			return {
				...state,
				error: true,
				isLoading: false,
				isSaving: false,
				errorMessage: action.payload.errorMessage,
				messageCode: action.payload.messageCode,
				message: action.payload.errorMessage,
				selectedEmployeeData: {
					...state.selectedEmployeeData
				}
			};
		}
		case actionTypes.ACTION_EMPLOYEES_TEMPINFORMATION: {
			return {
				...state,
				tempFullName: action.payload.fullname || state.tempFullName,
				tempJobTitle: action.payload.jobTitle || state.tempJobTitle
			};
		}
		case actionTypes.ACTION_EMPLOYEE_DATA_REQUEST: {
			return {
				...state,
				isLoading: true,
				error: false,
				errorMessage: ""
			};
		}
		case actionTypes.ACTION_EMPLOYEE_DATA_REQUEST_SUCCESS: {
			return {
				...state,
				isLoading: false,
				error: false,
				errorMessage: "",
				selectedEmployeeData: {
					...employeeDefaultData,
					...action.payload.selectedEmployeeData
				}
			};
		}
		case actionTypes.ACTION_EMPLOYEE_DATA_REQUEST_FAILURE: {
			return {
				...state,
				error: true,
				isLoading: false,
				errorMessage: action.payload.errorMessage,
				messageCode: action.payload.messageCode,
				selectedEmployeeData: {
					...employeeDefaultData
				}
			};
		}
		case actionTypes.ACTION_EMPLOYEE_CLEAN_DATA: {
			return {
				...state,
				isLoading: false,
				error: false,
				errorMessage: "",
				selectedEmployeeData: {
					...employeeDefaultData,
					...action.payload.selectedEmployeeData
				}
			};
		}
		case actionTypes.ACTION_EMPLOYEES_UPDATE_STATUS: {
			return {
				...state,
				isCasual: action.payload.payrollStatus === "Casual"
			};
		}
		case actionTypes.ACTION_EMPLOYEE_UPLOAD_IMAGE_REQUEST: {
			return {
				...state,
				isLoading: true
			};
		}
		case actionTypes.ACTION_EMPLOYEE_UPLOAD_IMAGE_REQUEST_SUCCESS: {
			return {
				...state,
				isLoading: false,
				message: action.payload.sucessMessage,
				messageCode: action.payload.messageCode,
				selectedEmployeeData: {
					...state.selectedEmployeeData,
					detail: {
						...state.selectedEmployeeData.detail,
						imageUrl: action.payload.imageUpload.image.urlImage,
						imageUpload: {
							...action.payload.imageUpload.image
						}
					}
				}
			};
		}
		case actionTypes.ACTION_EMPLOYEE_UPLOAD_IMAGE_REQUEST_FAILURE: {
			return {
				...state,
				error: true,
				isLoading: false,
				message: action.payload.errorMessage,
				messageCode: action.payload.messageCode
			};
		}
		case actionTypes.ACTION_EMPLOYEE_ACTIVE_OR_INACTIVE: {
			return {
				...state,
				isLoading: true,
				employeeData: action.payload.employeeData
			};
		}
		case actionTypes.ACTION_EMPLOYEE_ACTIVE_OR_INACTIVE_SUCCESS: {
			return {
				...state,
				isLoading: false,
				employeeData: action.payload.employeeData,
				messageCode: action.payload.messageCode,
				message: action.payload.message
			};
		}
		case actionTypes.ACTION_EMPLOYEE_ACTIVE_OR_INACTIVE_FAILURE: {
			return {
				...state,
				error: true,
				isLoading: false,
				errorMessage: action.payload.errorMessage,
				messageCode: action.payload.messageCode,
				message: action.payload.message
			};
		}
		case actionTypes.ACTION_EMPLOYEE_CLEAN_MESSAGE: {
			return {
				...state,
				error: false,
				isLoading: false,
				errorMessage: action.payload.errorMessage,
				messageCode: action.payload.messageCode,
				message: action.payload.message
			};
		}
		case actionTypes.ACTION_EMPLOYEES_SELECT_TOGGLE: {
			const { employeeData, checked } = action.payload;
			const { selectedEmployeeList } = state;
			let tempEmployeeList = [...selectedEmployeeList];
			if (checked) {
				tempEmployeeList.push(employeeData);
			} else {
				tempEmployeeList = tempEmployeeList.filter(element => element.id !== employeeData.id);
			}
			return {
				...state,
				selectedEmployeeList: [...tempEmployeeList],
			};
		}
		case actionTypes.ACTION_EMPLOYEES_SELECT_ALL_TOGGLE: {
			const { checked } = action.payload;
			const { activeEmployeesList } = state;
			let tempEmployeeList = [];
			if (checked) {
				tempEmployeeList = [...activeEmployeesList];
			}

			return {
				...state,
				selectedEmployeeList: [...tempEmployeeList],
			};
		}
		case actionTypes.ACTION_SESSION_LOGOUT: {
			return employeeInitialState;
		}
		case actionTypes.ACTION_SAVE_EMPLOYEE_WITH_FINISH: {
			return {
				...state,
				saveEmployeeWithFinish: true
			}
		}
		default: {
			return {
				...state
			};
		}
	}
};

export default employeeReducer;
