import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
	Button, Checkbox, Divider, FormControlLabel, Grid, Icon, Paper, Typography
} from "@material-ui/core";

import ModalInactiveComponent from "./modalInactiveComponent";
import EmployeesContainerComponent from "./employeesContainerComponent";
import employeeStyle from "./employeeStyle";

import * as employeeActions from "../../actions/EmployeeActions";
import * as routes from "../../constants/routes";
import employeesEmpty from "../../assets/images/employees.empty.png";
import { employeeDefaultData } from "../../reducers/Employees/EmployeeInitialState";
import { Analytics, SubscriptionUpgradeComponent } from "../common";

class EmployeesComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			openInactiveDialog: false,
			openActiveDialog: false,
			openUpgradeDialog: false
		};
	}

	componentDidMount = async () => {
		const {
			getAllEmployees, selectAllEmployees, mixpanel
		} = this.props;
		selectAllEmployees(false);
		await getAllEmployees();

		mixpanel.track("Employees.Screen");
	};

	componentWillReceiveProps = (nextProps) => {
		const { activeEmployeeList, mixpanel } = nextProps;

		if (activeEmployeeList && activeEmployeeList.length > 0) {
			mixpanel.track("Employees.Screen", { "User.amountEmployees": activeEmployeeList.length });
		}
	};

	handleAddNewEmployeeClick = async () => {
		const {
			clearSelectedEmployee, history, activeEmployeeList, employer
		} = this.props;

		if (employer.subscription.quantityEmployees > activeEmployeeList.length) {
			Analytics.addEmployeeAction();
			await clearSelectedEmployee(employeeDefaultData);
			history.push(routes.PATH_EMPLOYEE_ADD);
		} else {
			this.handleOpenUpgrade();
		}
	};

	handleOpenUpgrade = () => {
		this.setState(prevState => ({ ...prevState, openUpgradeDialog: true }));
	};

	handleCloseUpgrade = () => {
		this.setState(prevState => ({ ...prevState, openUpgradeDialog: false }));
	};

	handleSelectToggleClick = (event, checked) => {
		const { selectAllEmployees } = this.props;

		selectAllEmployees(checked);
	};

	handleClickInactive = () => {
		this.setState(prevState => ({ ...prevState, openInactiveDialog: true }));
	};

	handleCloseActiveInactiveDialog = () => {
		this.setState(prevState => ({ ...prevState, openInactiveDialog: false, openActiveDialog: false }));
	};

	handleActivateInactivateButtonClick = (date) => {
		const { activeOrInactiveEmployees, selectedEmployeeList } = this.props;

		const finalEmployeeList = selectedEmployeeList.map(element => ({
			...element,
			active: !element.active,
			cessationDate: element.active ? date : undefined,
			commencementDate: !element.active ? date : undefined
		}));

		activeOrInactiveEmployees([...finalEmployeeList]);

		this.handleCloseActiveInactiveDialog();
	};

	handleProcessPayrollClick = () => {
		const { history } = this.props;
		history.push(routes.PATH_PAYROLL);
	};

	render() {
		const {
			classes, employeeList, activeEmployeeList, inactiveEmployeeList, unfinishedEmployeeList, selectedEmployeeList, isLoading
		} = this.props;

		const { openInactiveDialog, openUpgradeDialog } = this.state;

		return (
			<div className={classes.root}>
				<Paper className={classes.paperContainer}>
					<Typography variant="h1" gutterBottom>
						{"Employees"}
					</Typography>
					<Grid container alignItems="center" className={classes.gridContainer}>
						<Grid item xs={3} className={classes.gridLeft}>
							{(activeEmployeeList && activeEmployeeList.length > 0) || (inactiveEmployeeList && inactiveEmployeeList > 0) ? (
								<FormControlLabel control={<Checkbox color="primary" icon={<Icon className={classes.IconCheck}>panorama_fish_eye</Icon>} checkedIcon={<Icon className={classes.IconCheckActive}>check_circle</Icon>} onChange={this.handleSelectToggleClick} />} label="Select all" />
							) : null}
						</Grid>
						<Grid item xs={9} className={classes.gridRight}>
							<Button variant="outlined" color="secondary" className={classes.gridRightClearButton} disabled={!!(selectedEmployeeList.length <= 0)} onClick={this.handleClickInactive}>
								{"Make inactive"}
							</Button>
							<Button variant="outlined" color="secondary" className={classes.gridRightClearButton} disabled={!!(activeEmployeeList.length <= 0)} onClick={this.handleProcessPayrollClick}>
								{"Process Payroll"}
							</Button>
							<Button variant="contained" color="secondary" className={classes.gridRightClearButton} onClick={this.handleAddNewEmployeeClick}>
								{"+ Add Employee"}
							</Button>
						</Grid>
					</Grid>

					{employeeList && employeeList.length <= 0 && !isLoading ? (
						<div className={classes.imageEmptyContainer}>
							<img className={classes.imageEmptyImage} src={employeesEmpty} alt="" />
							<Typography variant="body1" className="gray">
								{"You don't have any employees yet."}
							</Typography>
						</div>
					) : null}

					{activeEmployeeList && activeEmployeeList.length > 0 ? (
						<div>
							<EmployeesContainerComponent title="Active Employees" employees={activeEmployeeList} />
						</div>
					) : null}

					{inactiveEmployeeList && inactiveEmployeeList.length > 0 ? (
						<div>
							<Divider className={classes.divider} />
							<EmployeesContainerComponent title="Inactive Employees" employees={inactiveEmployeeList} />
						</div>
					) : null}

					{unfinishedEmployeeList && unfinishedEmployeeList.length > 0 ? (
						<div>
							<Divider className={classes.divider} />
							<EmployeesContainerComponent title="Unfinished Employees" employees={unfinishedEmployeeList} />
						</div>
					) : null}
				</Paper>
				<ModalInactiveComponent open={openInactiveDialog} onCloseModal={this.handleCloseActiveInactiveDialog} onPrimaryButtonClick={this.handleActivateInactivateButtonClick} />
				{openUpgradeDialog ? <SubscriptionUpgradeComponent open={openUpgradeDialog} onCloseModal={this.handleCloseUpgrade} showMessageEmployees /> : null}
			</div>
		);
	}
}

EmployeesComponent.propTypes = {
	employeeList: PropTypes.array,
	activeEmployeeList: PropTypes.array,
	inactiveEmployeeList: PropTypes.array,
	unfinishedEmployeeList: PropTypes.array,
	selectedEmployeeList: PropTypes.array,
	employer: PropTypes.object,

	getAllEmployees: PropTypes.func,
	clearSelectedEmployee: PropTypes.func,
	selectAllEmployees: PropTypes.func,
	activeOrInactiveEmployees: PropTypes.func
};

const mapStateToProps = state => ({
	employeeList: state.employees.list,
	activeEmployeeList: state.employees.activeEmployeesList,
	inactiveEmployeeList: state.employees.inactiveEmployeesList,
	unfinishedEmployeeList: state.employees.unfinishedEmployeeList,
	selectedEmployeeList: state.employees.selectedEmployeeList,
	isLoading: state.employees.isLoading,
	employer: state.session.userData
});

const mapDispatchToProps = dispatch => ({
	getAllEmployees: bindActionCreators(employeeActions.employeesRequest, dispatch),
	clearSelectedEmployee: bindActionCreators(employeeActions.setCleanDataToStore, dispatch),
	selectAllEmployees: bindActionCreators(employeeActions.selectAllEmployeesToggle, dispatch),
	activeOrInactiveEmployees: bindActionCreators(employeeActions.activeOrInactiveEmployee, dispatch)
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(withStyles(employeeStyle)(EmployeesComponent)));
