import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter, Link as RouterLink } from "react-router-dom";

import PropTypes from "prop-types";
import {
  Box,
  Button,
  TextField,
  Grid,
  Link,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Typography,
  Checkbox,
  InputAdornment,
  Hidden,
} from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { withStyles } from "@material-ui/core/styles";
import { Formik } from "formik";
import clsx from "clsx";
import * as Yup from "yup";

import {
  LogoHeaderCommonComponent,
  LoadingComponent,
  Analytics,
} from "../common";
import LogoEasyPayslip from "../../assets/images/LOGOEasyPayslipHorizontal.svg";
import { DEEP_LINK } from "../../constants/common";
import * as routes from "../../constants/routes";
import * as settingsFiles from "../../constants/settingsFiles";
import * as sessionActions from "../../actions/SessionActions";
import LoginScreen from "../../assets/images/loginScreen.png";
import { ExternalUserStyle } from "./sessionStyle";
import { ZendeskScriptRemove, DeepOpen } from "../../scripts";
import logo from "../../assets/images/logo.svg";

class LoginComponent extends Component {
  validationSchema = Yup.object({
    email: Yup.string().email("Email is invalid").required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  constructor() {
    super();

    this.state = {
      showPassword: false,
    };
  }

  componentWillMount() {
    Analytics.LoginScreen();
    ZendeskScriptRemove();
    // DeepOpen(DEEP_LINK);
  }

  handleSubmitLogin = (values, actions) => {
    const { loggingIn, history } = this.props;
    actions.setSubmitting(false);
    loggingIn(values.email, values.password, history);
  };

  handleClickShowPassword = () => {
    const { showPassword } = this.state;
    this.setState({ showPassword: !showPassword });
  };

  // { values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, }
  renderForm = (formProps) => {
    const { classes, error, errorMessage } = this.props;
    const { showPassword } = this.state;

    return (
      <>
        <div
          style={{
            backgroundColor: "#303030",
            color: "white",
            padding: "8px 0px",
            //maxWidth: 1100,
            margin: "0 auto",
            width: "100%",
          }}
        >
          <p style={{ textAlign: "center", margin: 0, fontSize: 15 }}>
            Please note that Easy Payslip will be unavailable this weekend
            between
            <strong> 8 PM AEST Friday and 9AM AEST Monday</strong> due to
            planned maintenance.
          </p>
        </div>
        <Hidden smDown>
          <Grid
            className={classes.root}
            container
            justify="center"
            alignItems="flex-start"
          >
            <LogoHeaderCommonComponent />
            <Grid container className={classes.rootContainer}>
              <Grid
                container
                alignItems="center"
                spacing={2}
                className={classes.logoroot}
              >
                <img src={LogoEasyPayslip} alt="" />
              </Grid>

              <Grid item xs={6} className={classes.leftColumn2}>
                <Typography
                  variant="h1"
                  gutterBottom
                  className={classes.textFieldLogin}
                >
                  {"Log in"}
                </Typography>
                <Typography
                  variant="h1"
                  gutterBottom
                  className={classes.textFieldwelcome}
                >
                  {"Welcome Back!"}
                </Typography>
                <Typography
                  variant="h1"
                  gutterBottom
                  className={classes.textFieldwelcome2}
                >
                  {"Let’s log you in"}
                </Typography>

                <FormControl fullWidth className={classes.signupbox}>
                  <Link
                    component={RouterLink}
                    to={routes.PATH_SIGN_UP}
                    className={clsx({ medium: true })}
                    underline="none"
                    variant="inherit"
                  >
                    <Typography
                      variant="h1"
                      gutterBottom
                      className={classes.signupText}
                    >
                      {"Don’t have an account yet? "}
                    </Typography>
                    <Typography variant="h1" className={classes.signupText2}>
                      {"Sign up here"}
                    </Typography>
                  </Link>
                </FormControl>
              </Grid>
              <Grid item xs={6} className={classes.rigthColumn}>
                {/* <img src={LoginScreen} alt="" className={classes.rigthColumnImg} /> */}
                <form className={classes.container1}>
                  <FormControl fullWidth margin="normal">
                    <TextField
                      autoFocus
                      fullWidth
                      id="email"
                      label="Email*"
                      // placeholder="Email*"
                      placeholder="test@test.com"
                      type="email"
                      variant="filled"
                      value={formProps.values.email}
                      onChange={formProps.handleChange}
                      onBlur={formProps.handleBlur}
                      className={clsx(classes.textFieldLogIn, {
                        error:
                          formProps.errors.email && formProps.touched.email,
                      })}
                    />
                  </FormControl>
                  <FormControl fullWidth margin="normal">
                    <TextField
                      fullWidth
                      id="password"
                      label="Password*"
                      // placeholder='Password*'
                      type={showPassword ? "text" : "password"}
                      variant="filled"
                      autoComplete="current-password"
                      value={formProps.values.password}
                      onChange={formProps.handleChange}
                      onBlur={formProps.handleBlur}
                      ///	onFocus={()=>{alert("ddddddd")}}
                      className={clsx(classes.textFieldLogIn, {
                        error:
                          formProps.errors.password &&
                          formProps.touched.password,
                      })}
                      // InputProps={{
                      // 	endAdornment: (
                      // 		<InputAdornment position="end">
                      // 			<IconButton edge="end" aria-label="Toggle password visibility" onClick={this.handleClickShowPassword}>
                      // 				{showPassword ? <VisibilityOff /> : <Visibility />}
                      // 			</IconButton>
                      // 		</InputAdornment>
                      // 	)
                      // }}
                    />
                    <Grid
                      container
                      alignItems="flex-end"
                      justify="flex-end"
                      style={{ marginTop: 20 }}
                    >
                      <Link
                        component={RouterLink}
                        to={routes.PATH_FORGOT_PASSWORD}
                        className={clsx(classes.forgetpassword, {
                          small: true,
                          blue: true,
                        })}
                        underline="none"
                        variant="inherit"
                      >
                        {"Forgot password"}
                      </Link>
                    </Grid>
                  </FormControl>

                  <FormControl
                    fullWidth
                    margin="normal"
                    style={{ marginTop: 50 }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.loginBtn}
                      onClick={formProps.handleSubmit}
                      type="submit"
                    >
                      {"Let's Go"}
                    </Button>
                    {error && (
                      <FormHelperText error>{errorMessage}</FormHelperText>
                    )}
                  </FormControl>
                </form>
              </Grid>

              <FormControl fullWidth className={classes.textDownLogin}>
                <Typography
                  variant="subtitle1"
                  className={clsx(classes.textdowncondition, { small: true })}
                >
                  {`By continuing you accept our `}
                  <Link
                    target="_blank"
                    href={settingsFiles.SETTINGS_TERMS_OF_USE_lINK.url}
                    className={clsx("pointer", classes.textdownconditionLink, {
                      small: true,
                      blue: true,
                    })}
                    underline="none"
                    variant="inherit"
                  >
                    {settingsFiles.SETTINGS_TERMS_OF_USE_lINK.name}
                  </Link>
                  {` and `}
                  <Link
                    target="_blank"
                    href={settingsFiles.SETTINGS_PRIVACY_POLICY_lINK.url}
                    className={clsx("pointer", classes.textdownconditionLink, {
                      small: true,
                      blue: true,
                    })}
                    underline="none"
                    variant="inherit"
                  >
                    {settingsFiles.SETTINGS_PRIVACY_POLICY_lINK.name}
                  </Link>
                </Typography>
              </FormControl>
            </Grid>
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Grid
            className={classes.root}
            container
            justify="center"
            alignItems="flex-start"
          >
            <LogoHeaderCommonComponent center />
            <Typography
              variant="h1"
              gutterBottom
              className={classes.textFieldwelcome}
            >
              {"Welcome Back!"}
            </Typography>
            <Typography
              variant="h1"
              gutterBottom
              className={classes.textFieldwelcome2}
            >
              {"Let’s log you in"}
            </Typography>
            <Grid item className={classes.mobileContainer}>
              <form>
                <FormControl fullWidth margin="normal">
                  <TextField
                    autoFocus
                    fullWidth
                    id="email"
                    label="Email*"
                    placeholder="test@test.com"
                    type="email"
                    variant="filled"
                    value={formProps.values.email}
                    onChange={formProps.handleChange}
                    onBlur={formProps.handleBlur}
                    className={clsx(classes.textFieldLogIn, {
                      error: formProps.errors.email && formProps.touched.email,
                    })}
                  />
                </FormControl>
                <FormControl fullWidth margin="normal">
                  <TextField
                    fullWidth
                    id="password"
                    label="Password*"
                    // placeholder='Password*'
                    type={showPassword ? "text" : "password"}
                    variant="filled"
                    autoComplete="current-password"
                    value={formProps.values.password}
                    onChange={formProps.handleChange}
                    onBlur={formProps.handleBlur}
                    className={clsx(classes.textFieldLogIn, {
                      error:
                        formProps.errors.password && formProps.touched.password,
                    })}
                  />
                  <Grid
                    container
                    alignItems="flex-end"
                    justify="flex-end"
                    style={{ marginTop: 0 }}
                  >
                    <Link
                      component={RouterLink}
                      to={routes.PATH_FORGOT_PASSWORD}
                      className={clsx(classes.forgetpassword, {
                        small: true,
                        blue: true,
                      })}
                      underline="none"
                      variant="inherit"
                    >
                      {"Forgot password"}
                    </Link>
                  </Grid>
                </FormControl>

                <FormControl
                  fullWidth
                  margin="normal"
                  style={{ marginTop: 20 }}
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.loginBtn}
                    onClick={formProps.handleSubmit}
                    type="submit"
                  >
                    {"Let's Go"}
                  </Button>
                  {error && (
                    <FormHelperText error>{errorMessage}</FormHelperText>
                  )}
                </FormControl>
              </form>
              <FormControl fullWidth style={{ marginTop: 5 }}>
                <Typography
                  variant="subtitle1"
                  className={clsx(classes.textdowncondition, { small: true })}
                >
                  {`By continuing you accept our `}
                  <Link
                    target="_blank"
                    href={settingsFiles.SETTINGS_TERMS_OF_USE_lINK.url}
                    className={clsx("pointer", classes.textdownconditionLink, {
                      small: true,
                      blue: true,
                    })}
                    underline="none"
                    variant="inherit"
                  >
                    {settingsFiles.SETTINGS_TERMS_OF_USE_lINK.name}
                  </Link>
                  {` and `}
                  <Link
                    target="_blank"
                    href={settingsFiles.SETTINGS_PRIVACY_POLICY_lINK.url}
                    className={clsx("pointer", classes.textdownconditionLink, {
                      small: true,
                      blue: true,
                    })}
                    underline="none"
                    variant="inherit"
                  >
                    {settingsFiles.SETTINGS_PRIVACY_POLICY_lINK.name}
                  </Link>
                </Typography>
              </FormControl>
            </Grid>
          </Grid>
        </Hidden>
      </>
    );
  };

  render() {
    const { classes, isLoading } = this.props;
    return (
      <Grid container justify="center" className={classes.root}>
        <LoadingComponent isLoading={isLoading} />
        <Formik
          initialValues={{ email: "", password: "" }}
          validationSchema={this.validationSchema}
          onSubmit={this.handleSubmitLogin}
          render={this.renderForm}
        />
      </Grid>
    );
  }
}

LoginComponent.propTypes = {
  history: PropTypes.object,
  classes: PropTypes.object,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  isLoading: PropTypes.bool,

  loggingIn: PropTypes.func,
};

const mapStateToProps = (state) => ({
  isLoading: state.session.isLoading,
  error: state.session.error,
  errorMessage: state.session.errorMessage,
});

const mapDispatchToProps = (dispatch) => ({
  loggingIn: bindActionCreators(sessionActions.userLoggingIn, dispatch),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(ExternalUserStyle)(LoginComponent))
);
