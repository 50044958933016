import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
	Grid, List, ListItem, ListItemText
} from "@material-ui/core";
import Moment from "react-moment";
import singleTouchStyle from "./singleTouchStyle";
import { DialogCommonComponent } from "../common";

class ModalSingleTouch extends Component {
	render() {
		const {
			classes, onPrimaryButtonClick, open, onCloseModal, dense, STPReport
		} = this.props;

		return (
			<DialogCommonComponent
				title={(
					<div>
						<Moment utc locale="au" local={true} format="DD/MM/YYYY">{STPReport.payPeriodStart}</Moment>
						&nbsp;-&nbsp;
						<Moment utc locale="au" local={true} format="DD/MM/YYYY">{STPReport.payPeriodEnd}</Moment>
					</div>
				)}
				subtitle="Correct these issues before lodging this file with ATO"
				size="sm"
				onPrimaryButtonClick={onPrimaryButtonClick}
				open={open}
				onCloseModal={onCloseModal}
				primaryButtonName="Relodge STP file with ATO"
			>
				<Grid container direction="row" alignItems="center">
					<Grid item xs={12}>
						<List dense={dense}>
							{((STPReport.ato || {}).errorList || []).map((error, i) => (
								<ListItem className={classes.LeftList} key={`${error.errorNumber}_${i + 0}`}>
									<ListItemText primary={`• ${(error.errorNumber || {}).description}`} />
								</ListItem>
							))}
						</List>
					</Grid>
				</Grid>
			</DialogCommonComponent>
		);
	}
}

export default withStyles(singleTouchStyle)(ModalSingleTouch);
