import { green, blue } from "@material-ui/core/colors";
import { createMuiTheme, lighten } from "@material-ui/core/styles";

export default createMuiTheme({
  palette: {
    primary: {
      light: "#6D9FEE",
      main: "#005CFF",
      dark: "#004FDC",
      contrastText: "#fff"
    },
    secondary: {
      // light: '#F02F0F',
      main: "#FD5739",
      // dark: '#F02F0F',
      contrastText: "#fff"
    },
    gray: {
      light: "#F5F5F5",
      main: "#00000029",
      dark: "#707070",
      contrastText: "#000"
    },
    black: "#000",
    White: "#fff"
  },
  typography: {
    htmlFontSize: 15,
    useNextVariants: true,
    fontFamily: ["AvenirNextLTPro-Regular"]
  },
  root: {
    flexGrow: 1,
    overflow: "auto"
  },
  paperClear: {
    padding: "0 20px 20px 0",
    boxShadow: "none",
    backgroundColor: "transparent"
  },
  paperContainer: {
    padding: "0 20px 20px 0",
    boxShadow: "none",
    backgroundColor: "transparent",
    margin: "2px 0 2px 16px",
    width: 900,
    "&.isMobile": {
      width: "100%"
    }
  },
  colorActive: {
    color: "#007aff"
  },
  option: {
    cursor: "pointer"
  },
  keyboardDatePicker: {
    marginTop: "0px !important",
    backgroundColor: "#f9f9f9",
    borderRadius: 5
  },
  keyboardDatePickerInput: {
    padding: "20px 12px 5px"
  },
  blockLabel: {
    padding: 0,
    fontWeight: "normal"
  },
  errorField: {
    borderRadius: 5,
    "& .Mui-error": {
      border: "1px solid #FD5739"
    }
  },
  tableH: {
    borderBottom: "4px solid #ffffff"
  },
  tableRow: {
    background: "#f9f9f9",
    borderBottom: "4px solid #ffffff"
  },
  tableTitle: {
    fontSize: 15
  },
  tableText: {
    fontSize: 15,
    color: "#000"
    // padding: 10
  },
  borderTextField: {
    backgroundColor: "#ffffff",
    borderColor: "#dedede",
    borderWidth: "1px",
    borderStyle: "solid",
    padding: "5px 20px 5px 10px",
    marginRight: 20
  },
  switchSpace: {
    padding: "20px 10px 0",
    border: "1px solid #D8D8D8",
    borderRadius: "0 0 5px 5px",
    borderTop: "none",
    background: "#ffffff",
    marginTop: "-2px"
  },
  bullet: {
    fontSize: 17,
    marginRight: 17,
    "&.orange": {
      color: "#FD5739"
    },
    "&.yellow": {
      color: "#FF9700"
    },
    "&.green": {
      color: "#C1D24F"
    }
  },
  hiddenOverflow: {
    overflow: "hidden"
  },
  showScroll: {
    overflow: "auto",
    "&::-webkit-scrollbar": {
      "-webkit-appearance": "none"
    },
    "&::-webkit-scrollbar:vertical": {
      width: 11
    },
    "&::-webkit-scrollbar:horizontal": {
      height: 11
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: 8,
      border: "2px solid white",
      backgroundColor: "#7d848b"
    }
  },
  overrides: {
    MuiBadge: {
      badge: {
        height: "2rem",
        fontSize: "1.2rem",
        lineHeight: "2rem",
        right: -10,
        top: -10,
        width: "2rem"
      },
      colorPrimary: {
        backgroundColor: "#004FDC"
      },
      colorSecondary: {
        backgroundColor: "#C1D24F",
        border: "1px solid #ffffff"
      }
    },
    MuiButton: {
      root: {
        lineHeight: "2rem",
        minHeight: "3.8rem",
        minWidth: "140px",
        padding: "0 22px",
        margin: "0 0 20px 16px",
        textTransform: "unset",
        height: 40,
        borderRadius: 20,
        width: "max-content",
        "&:last-child": {
          marginRight: 0
        },
        "&:hover": {
          boxShadow:
            "0 1px 2px 0 rgba(0,0,0,0.2), 2px 3px 2px 0 rgba(0,0,0,0.1)"
        }
      },
      contained: {
        boxShadow: "none",
        "&:active": {
          boxShadow:
            "0 1px 2px 0 rgba(0,0,0,0.2), 2px 3px 2px 0 rgba(0,0,0,0.1)"
        }
      },
      label: {
        padding: ".5rem 1rem",
        fontFamily: "AvenirNextLTPro-Regular",
        fontSize: 18
      },
      containedPrimary: {
        backgroundColor: "#005CFF",
        color: "#fff !important",
        "&:hover": {
          backgroundColor: "#005CFF"
        }
      },
      containedSecondary: {
        backgroundColor: "#FD5739",
        color: "#fff !important",
        "&:hover": {
          backgroundColor: "#FD5739"
        }
      },
      outlined: {
        backgroundColor: "#fff",
        color: "#7d848b",
        border: "1px solid #7d848b",
        "&:hover": {
          backgroundColor: "#fff"
        }
      },
      outlinedSecondary: {
        backgroundColor: "#fff",
        color: "#007aff",
        border: "1px solid #007aff",
        "&:hover": {
          backgroundColor: "#fff",
          border: "1px solid #007aff"
        }
      },
      textPrimary: {
        "&:first-child": {
          background: "none",
          border: "none",
          color: "#004FDC !important",
          minWidth: 140,
          margin: "0 5px 20px 5px",
          "&:hover": {
            background: "none",
            border: "none"
          }
        },
        "&:last-child": {
          background: "none",
          border: "none",
          color: "#007aff !important",
          minWidth: 140,
          margin: "0 5px 20px 5px",
          "&:hover": {
            background: "none",
            border: "none"
          }
        }
      }
    },
    disabledButtonPrimary: {
      backgroundColor: "#005CFF !important",
      color: "#fff",
      boxShadow: "none",
      opacity: 0.5
    },
    disabledButtonSecondary: {
      backgroundColor: "#DFE0DF !important",
      color: "#fff",
      boxShadow: "none",
      opacity: 0.5
    },
    orangeRectangularBtn: {
      minWidth: "200px",
      borderRadius: '5px !important',
      opacity: 1,
      // width: 219,
      height: 40,
      textAlign: 'center',
      letterSpacing: 0,
      color: '#FFFFFF',
      "& span": {
        fontSize: "15px",
        fontFamily: 'AvenirNextLTPro-Demi !important',
        fontWeight: "bold",
      }
    },
    MuiCircularProgress: {
      colorPrimary: {
        color: "#007AFF"
      },
      colorSecondary: {
        color: green[500]
      }
    },
    MuiCheckbox: {
      root: {
        fontSize: 23,
        "&$checked": {
          color: "#007AFF !important"
        }
      }
    },
    MuiDialog: {
      container: {
        backgroundColor: "rgba(42, 56, 69, 0.7)"
      },
      paperWidthMd: {
        maxWidth: 640
      },
      paperWidthLg: {
        maxWidth: 980
      },
      paperWidthLg: {
        maxWidth: 1000
      }
    },
    MuiInput: {
      input: {
        fontSize: "1.4rem"
      },
      underline: {
        "&:before": {
          content: ""
        },
        "&:after": {
          content: ""
        }
      }
    },
    MuiListItem: {
      root: {
        borderRadius: 10,
        color: "#000000",
        fontFamily: "AvenirNextLTPro-Regular",
        "&.Mui-selected": {
          backgroundColor: "#FAFAFA",
          color: "#007AFF",
          fontFamily: "AvenirNextLTPro-Demi",
          "&:hover": {
            backgroundColor: "#FAFAFA",
            color: "#007AFF"
          }
        }
      },
      gutters: {
        paddingLeft: "1rem",
        paddingRight: "1rem"
      },
      button: {
        "&:hover": {
          backgroundColor: "#FAFAFA"
        }
      }
    },
    MuiListItemText: {
      root: {
        fontSize: "1.4rem"
      }
    },
    MuiTable: {
      root: {
        overflow: "visible"
      }
    },
    MuiTableBody: {
      root: {
        fontSize: "1.4rem",
        lineHeight: "1.25em"
      }
    },
    MuiTableCell: {
      root: {
        textAlign: "left",
        verticalAlign: "top"
      }
    },
    MuiTableRow: {
      root: {
        height: "auto"
      }
    },
    MuiTab: {
      root: {
        height: 38
      },
      textColorInherit: {
        maxWidth: "-webkit-fill-available"
      }
    },
    MuiTabs: {
      root: {
        minHeight: 40
      },
      flexContainer: {
        borderBottom: "1px solid #d8d8d8"
      },
      indicator: {
        height: 3
      }
    },
    MuiCard: {
      root: {
        border: "1px solid #E9E9E9",
        boxShadow: "none",
        borderRadius: 5
      }
    },
    MuiCardHeader: {
      content: {
        fontFamily: "AvenirNextLTPro-Bold",
        fontSize: 17
      }
    },
    MuiToolbar: {
      root: {
        height: "6.4rem",
        minHeight: "6.4rem",
        width: "100%"
      },
      gutters: {
        margin: "0 !important",
        padding: "0 !important"
      }
    },
    MuiTypography: {
      root: {
        lineHeight: "1.25em !important",
        fontFamily: "AvenirNextLTPro-Regular",
        fontSize: 15,
        "&.pointer": {
          marginTop: 10,
          cursor: "pointer"
        },
        "&.gray": {
          color: "#7D848B"
        },
        "&.title": {
          fontWeight: "bold"
        },
        "&.subtitle": {
          color: "#7D848B",
          marginTop: 6
        },
        "&.error": {
          color: "#FD5739"
        }
      },
      h1: {
        fontFamily: "AvenirNextLTPro-Demi",
        fontSize: 32
      },
      h2: {
        fontSize: 17,
        fontFamily: "AvenirNextLTPro-Regular",
        fontWeight: "600"
      },
      h3: {
        fontSize: 20,
        "&.headerLogo": {
          fontWeight: "bold",
          fontSize: 22
        }
      },
      h4: {
        fontFamily: "AvenirNextLTPro-Demi",
        fontSize: 17,
        fontWeight: "600",
        "&.subTitle": {
          fontSize: 15
        }
      },
      h5: {
        fontSize: 14
      },
      body1: {
        fontSize: 15,
        "&.small": {
          fontSize: 10
        },
        "&.bold": {
          fontFamily: "AvenirNextLTPro-Demi",
          fontWeight: "600"
        }
      },
      body2: {
        fontSize: 17
      },
      subtitle1: {
        fontSize: 12,
        color: "#7d848b",
        lineHeight: "normal",
        "&.orange": {
          color: "#EF4B36",
          display: "initial"
        },
        "&.small": {
          fontSize: 10
        }
      },
      subtitle2: {
        fontSize: 14,
        color: "#7d848b",
        lineHeight: "normal"
      },
      gutterBottom: {
        marginBottom: 15
      },
      caption: {
        fontSize: 15
      }
    },
    MuiFormLabel: {
      root: {
        fontSize: 15,
        lineHeight: "normal",
        color: "#9b9b9b",
        zIndex: 1
      }
    },
    MuiInputBase: {
      root: {
        fontFamily: "AvenirNextLTPro-Regular",
        fontSize: 14,
        color: "#000000",
        lineHeight: "normal"
      },
      input: {
        fontFamily: "AvenirNextLTPro-Regular",
        fontSize: 14,
        "&:-webkit-autofill": {
          background: "#f9f9f9 !important",
          borderRadius: 5
        },
        "&:-internal-autofill-selected": {
          background: "#f9f9f9 !important",
          borderRadius: 5
        }
      }
    },
    MuiFilledInput: {
      root: {
        backgroundColor: "#f5f5f5",
        borderRadius: 5,
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
        height: "-webkit-fill-available"
      },
      underline: {
        "&:before": {
          content: "none"
        },
        "&:after": {
          content: "none"
        }
      },
      input: {
        padding: "22px 12px 10px",
        WebkitBoxShadow: "0 0 0 0 #f5f5f5 inset",
        "&:-webkit-autofill": {
          WebkitBoxShadow: "0 0 0 1000px #f5f5f5 inset",
          height: 8,
          borderRadius: 5,
          "&:hover": {
            WebkitBoxShadow: "0 0 0 1000px #f5f5f5 inset",
            height: 8,
            borderRadius: 5,
            adornedEnd: {
              background: "#f5f5f5"
            }
          },
          "&:focus": {
            WebkitBoxShadow: "0 0 0 1000px #f5f5f5 inset",
            height: 8,
            borderRadius: 5
          }
        }
      },
      adornedEnd: {
        background: "#f9f9f9",
        "&:hover": {
          background: "#f9f9f9"
        }
      }
    },
    MuiInputLabel: {
      filled: {
        transform: "translate(12px, 50%) scale(1)",
        "&$shrink": {
          transform: "translate(12px, 5px) scale(0.75)"
        }
      },
      animated: {
        transform: "translate(12px, 50%) scale(1)",
        "&$shrink": {
          transform: "translate(12px, 5px) scale(0.75)"
        }
      }
    },
    MuiDivider: {
      root: {
        backgroundColor: "#D8D8D8",
        boxSizing: "border-box"
      }
    },
    MuiSelect: {
      icon: {
        backgroundColor: "#F1F1F1",
        width: 27,
        height: 40,
        top: 0,
        borderTopRightRadius: 5,
        borderBottomRightRadius: 5
      },
      select: {
        "&:focus": {
          backgroundColor: "transparent"
        }
      }
    },
    MuiMenu: {
      list: {
        fontSize: 15,
        paddingLeft: 10
      }
    },
    MuiFormHelperText: {
      root: {
        fontSize: 13
      },
      contained: {
        textAlign: "right"
      }
    },
    MuiMenuItem: {
      root: {
        fontSize: 15
      }
    },
    MuiPickersDatePickerRoot: {
      toolbar: {
        alignItems: "center"
      }
    },
    MuiPickersToolbar: {
      toolbar: {
        alignItems: "center",
        backgroundColor: "#F9F9F9"
      }
    },
    MuiPickersToolbarButton: {
      toolbarBtn: {
        margin: 0,
        minWidth: 180
      }
    },
    MuiPickersToolbarText: {
      toolbarTxt: {
        color: "#000"
      },
      toolbarBtnSelected: {
        color: "#000"
      }
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: "#007AFF",
        "&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.08)"
        }
      }
    },
    MuiPickersModal: {
      dialogRoot: {
        maxWidth: "initial"
      }
    },
    MuiLink: {
      root: {
        fontSize: 15,
        marginBottom: 10,
        color: "#000000",
        "&:last-child": {
          marginBottom: 0,
          marginLeft: 0
        },
        "&.block": {
          color: "#007AFF",
          display: "block",
          padding: 0,
          minHeight: "auto",
          height: "auto",
          marginLeft: 0,
          "&:hover": {
            boxShadow: "none",
            backgroundColor: "transparent"
          }
        },
        "&.small": {
          fontSize: 10,
          color: "#7D848B",
          "&.blue": {
            color: "#004FDC"
          }
        },
        "&.medium": {
          fontSize: 12,
          color: "#000"
        },
        "&.primaryColor": {
          color: "#004FDC"
        }
      }
    },
    MuiTextField: {
      root: {
        border: "1px solid transparent",
        borderRadius: 6,
        height: 41,
        "&.error": {
          border: "1px solid #FD5739",
          height: 42
        },
        "&:hover": {
          border: "1px solid transparent"
        },
        "&.phNumber": {
          "& .MuiFilledInput-root": {
            "& input": {
              "&[type=number]": {
                "&::-webkit-inner-spin-button": {
                  WebkitAppearance: "none"
                },
                "&::-webkit-outer-spin-button": {
                  WebkitAppearance: "none"
                }
              }
            }
          }
        },
        "&.onlyRead": {
          border: "1px solid #D8D8D8",
          padding: "8px 0",
          "& .MuiInputAdornment-positionStart": {
            margin: "11px 5px 12px 12px"
          },
          "& .MuiFilledInput-root": {
            backgroundColor: "transparent",
            "&:hover": {
              backgroundColor: "transparent"
            }
          },
          "& .MuiInput-input": {
            padding: "3px 0 7px",
            WebkitBoxShadow: "0 0 0 0 #f9f9f9 inset",
            backgroundColor: "transparent",
            "&:hover": {
              backgroundColor: "transparent"
            }
          }
        },
        "&.normalInput": {
          height: 41,
          position: "relative",
          transition: "background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
          borderRadius: 5,
          backgroundColor: "#f9f9f9",
          borderTopLeftRadius: "5px",
          borderTopRightRadius: "5px",
          "& .MuiInputAdornment-positionStart": {
            margin: "7px 5px 12px 12px"
          },
          "& .MuiFilledInput-root": {
            backgroundColor: "transparent",
            "&:hover": {
              backgroundColor: "transparent"
            }
          },
          "&:hover": {
            backgroundColor: "#D8D8D8"
          },
          "& .MuiInput-input": {
            padding: "3px 0px 7px",
            WebkitBoxShadow: "0 0 0 0 #f9f9f9 inset",
            backgroundColor: "transparent",
            "&:hover": {
              backgroundColor: "transparent"
            }
          }
        },
        "&.onlyReadTitle": {
          border: "1px solid #D8D8D8",
          padding: "inherit",
          "& .MuiInputAdornment-positionStart": {
            margin: "7px 5px 12px 12px"
          },
          "& .MuiFilledInput-root": {
            backgroundColor: "transparent",
            "&:hover": {
              backgroundColor: "transparent"
            }
          },
          "& .MuiInput-input": {
            padding: "3px 0 7px",
            WebkitBoxShadow: "0 0 0 0 #f9f9f9 inset",
            backgroundColor: "transparent",
            "&:hover": {
              backgroundColor: "transparent"
            }
          }
        },
        "&.errorDatePicker": {
          "& .MuiInputBase-formControl": {
            border: "1px solid #FD5739"
          }
        },
        "&.isMobile": {
          width: "100%"
        }
      }
    },
    MuiIconButton: {
      colorSecondary: {
        "&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.13) !important"
        },
        "&:focus": {
          backgroundColor: "rgba(0, 0, 0, 0.13) !important"
        }
      }
    },
    MuiPaper: {
      elevation1: {
        boxShadow: "none"
      }
    },
    MuiLinearProgress: {
      root: {
        backgroundColor: lighten("#FD5739", 0.5)
      },
      bar: {
        backgroundColor: "#FD5739"
      }
    },
    MuiSnackbarContent: {
      root: {
        backgroundColor: "#FFF"
      }
    }
  }
});
