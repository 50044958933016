import React, { Component } from "react";
import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Icon,
  Paper,
  Typography
} from "@material-ui/core";
import employeeStyle from "./reportsStyle";
import AvatarCommonComponent from "../common/avatarCommonComponent";

class EmployeesComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedAll: false,
      employees: JSON.parse(JSON.stringify(props.employees))
    };
  }

  handleSelectAll = e => {
    const { onChange, formProps, name, returnObject } = this.props;
    const { employees } = this.state;
    const selected = e.target.checked;
    this.setState({ selectedAll: e.target.checked });
    employees.forEach(employee => {
      employee.selected = selected;
    });

    if (onChange) {
      if (returnObject) {
        onChange(employees.filter(employee => employee.selected));
      } else {
        onChange(
          employees
            .filter(employee => employee.selected)
            .map(employee => employee.id)
        );
      }
    }

    if (formProps && name) {
      if (returnObject) {
        formProps.setFieldValue(
          name,
          employees.filter(employee => employee.selected)
        );
      } else {
        formProps.setFieldValue(
          name,
          employees
            .filter(employee => employee.selected)
            .map(employee => employee.id)
        );
      }
    }
  };

  handleSelect = (e, i) => {
    const {
      onChange,
      singleSelection = false,
      formProps,
      name,
      returnObject
    } = this.props;
    const { employees } = this.state;
    if (singleSelection) {
      employees.forEach(employee => {
        employee.selected = false;
      });
    }
    employees[i].selected = e.target.checked;
    this.setState({
      selectedAll: !employees.some(employee => !employee.selected)
    });

    if (onChange) {
      if (returnObject) {
        onChange(employees.filter(employee => employee.selected));
      } else {
        onChange(
          employees
            .filter(employee => employee.selected)
            .map(employee => employee.id)
        );
      }
    }

    if (formProps && name) {
      if (returnObject) {
        formProps.setFieldValue(
          name,
          employees.filter(employee => employee.selected)
        );
      } else {
        formProps.setFieldValue(
          name,
          employees
            .filter(employee => employee.selected)
            .map(employee => employee.id)
        );
      }
    }
  };

  render() {
    const { classes, singleSelection = false, error } = this.props;
    const { employees, selectedAll } = this.state;

    const listEmployees = employees.map((employee, index) => (
      <Paper className={classes.paper} key={employee.id}>
        <Grid container wrap="nowrap" spacing={2} alignItems="center">
          <Grid item>
            <Checkbox
              color="primary"
              icon={
                <Icon className={classes.IconCheck}>panorama_fish_eye</Icon>
              }
              checkedIcon={
                <Icon className={classes.IconCheckActive}>check_circle</Icon>
              }
              id={employee.id}
              value={employee.id}
              checked={(employee || {}).selected}
              onChange={event => {
                this.handleSelect(event, index);
              }}
            />
          </Grid>
          <Grid item>
            <AvatarCommonComponent
              text={employee.initials}
              hover={false}
              src={employee.imageUrl}
              employeeId={employee.id}
            />
          </Grid>
          <Grid item xs>
            <Typography variant="body1">{employee.name}</Typography>
            <Typography variant="subtitle1">{employee.jobTitle}</Typography>
          </Grid>
        </Grid>
      </Paper>
    ));

    return (
      <div className={classes.root}>
        <Typography
          variant="h4"
          gutterBottom
          className={clsx("", {
            error
          })}
        >
          {`Select employee${!singleSelection ? "s" : ""}`}
        </Typography>

        <Grid container alignItems="center" className={classes.gridContainer}>
          <Grid item xs={3} className={classes.gridLeft}>
            {!singleSelection && employees && employees.length > 0 ? (
              <FormControlLabel
                label={selectedAll ? "Deselect all" : "Select all"}
                control={
                  <Checkbox
                    color="primary"
                    icon={
                      <Icon className={classes.IconCheck}>
                        panorama_fish_eye
                      </Icon>
                    }
                    checkedIcon={
                      <Icon className={classes.IconCheckActive}>
                        check_circle
                      </Icon>
                    }
                    checked={selectedAll}
                    onChange={this.handleSelectAll}
                  />
                }
              />
            ) : null}
          </Grid>
        </Grid>

        {listEmployees}
      </div>
    );
  }
}

export default withStyles(employeeStyle)(EmployeesComponent);
