import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
	IconButton, Typography, DialogTitle as MuiDialogTitle
} from "@material-ui/core";

import { Close as CloseIcon } from "@material-ui/icons";

import { DialogTitleStyles } from "./commonStyles";

const DialogTitleCommonComponent = (props) => {
	const { children, classes, onClose } = props;

	return (
		<MuiDialogTitle disableTypography className={classes.root}>
			<Typography variant="h4" className={classes.title}>
				{children}
			</Typography>
			{onClose ? (
				<IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
};

export default withStyles(DialogTitleStyles)(DialogTitleCommonComponent);
