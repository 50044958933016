import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import {
	Button, Link, Grid, Paper, Typography
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";

import SettingStyle from "./settingStyle";
import * as routes from "../../constants/routes";
import * as settingsFiles from "../../constants/settingsFiles";

import { DialogCommonPDFComponent, SnackbarCommonComponent } from "../common";
import { getUTCDate, getDateToday } from "../../helpers/dateextensions";
import { cleanMessage } from "../../actions/EmployerActions";

import ModalNotificationComponent from "./modalNotificationsComponent";
import ModalCloseFYComponent from "./modalCloseFYComponent";
import ModalCloseFYDoneComponent from "./modalCloseFYDoneComponent";
import ModalCloseFYAuthorizedComponent from "./modalCloseFYAuthorizedComponent";

class SettingComponent extends Component {
	state = {
		PDF: {
			Open: false,
			Url: "",
			Name: ""
		},
		showModalNotification: false,
		showModalCloseFY: false,
		showModalCLoseFYDone: false,
		isValidCloseFY: false,
		showAuthorizedModal: false,
		showSnackBarGenerateLast: false
	};

	componentDidMount() {
		const {
			employer, cleanMessages, isGenerateLastPayments, mixpanel
		} = this.props;

		mixpanel.track("Settings.Screen");

		const now = getDateToday();
		// const fyYearTo = getUTCDate(employer.financialYear.dateTo);

		this.setState({
			// isValidCloseFY: employer.financialYear.year === now.toDate().getFullYear() || now >= fyYearTo,
			showSnackBarGenerateLast: isGenerateLastPayments
		});
		cleanMessages();
	}

	componentWillReceiveProps = (nextProps) => {
		const { isGenerateLastPayments } = nextProps;

		if (isGenerateLastPayments) {
			this.setState({ showSnackBarGenerateLast: isGenerateLastPayments });
			this.handleCloseAuthorizedModal();
		}
	};

	handleCloseFYOpen = () => {
		const { cleanMessages } = this.props;
		cleanMessages();

		this.setState({
			showModalCloseFY: true
		});
	};

	handleCloseFYClose = () => {
		this.setState({
			showModalCloseFY: false
		});
	};

	handleCloseFYDoneOpen = () => {
		const { cleanMessages } = this.props;
		cleanMessages();

		this.setState({
			showModalCLoseFYDone: true
		});
	};

	handleCloseFYDoneClose = () => {
		this.setState({
			showModalCLoseFYDone: false
		});
	};

	handleOpenModalPDF = settingConf => () => {
		this.setState({
			PDF: {
				Open: true,
				Url: settingConf.url,
				Name: settingConf.name
			}
		});
	};

	handleCloseModalPDF = () => {
		this.setState({
			PDF: {
				Open: false,
				Url: "",
				Name: ""
			}
		});
	};

	handleClickOptionNotification = () => {
		this.setState({
			showModalNotification: true
		});
	};

	handleCloseModalNotification = () => {
		this.setState(() => ({
			showModalNotification: false
		}));
	};

	handleOpenAuthorizedModal = () => {
		this.setState({ showAuthorizedModal: true });
	};

	handleCloseAuthorizedModal = () => {
		this.setState({ showAuthorizedModal: false });
	};

	handleCloseSnackBarGenerateLast = () => {
		this.setState({ showSnackBarGenerateLast: false });
	};

	render() {
		const { classes, employer } = this.props;
		const {
			PDF, showModalNotification, showModalCloseFY, showModalCLoseFYDone, isValidCloseFY, showAuthorizedModal, showSnackBarGenerateLast
		} = this.state;

		console.log(employer);
		let profileLink = "Profile";
		if (employer && employer.details) {
			profileLink = employer.details.first + " " + employer.details.last;
		}

		return (
			<div className={classes.root}>
				<Paper className={classes.paperContainer}>
					<Typography variant="h1" gutterBottom>
						Settings
					</Typography>
					<Typography variant="body1">Configure and adjust your business preferences</Typography>

					<Grid container spacing={4} className={classes.gridContainer}>
						<Grid item xs={6}>
							<Paper className={classes.settingPaper}>
								<Typography variant="h4" gutterBottom>
									{"Profile"}
								</Typography>

								<Link component={RouterLink} to={routes.PATH_ACCOUNTANT_REGISTER} className="block">
									{profileLink}
								</Link>
							</Paper>

							<Paper className={classes.settingPaper}>
								<Typography variant="h4" gutterBottom>
									{"Documents"}
								</Typography>

								<Button component={Link} onClick={this.handleOpenModalPDF(settingsFiles.SETTINGS_TFN_DECLARATION)} classes={{ root: "block", label: classes.blockLabel }}>
									{"TFN Declaration"}
								</Button>

								<Button component={Link} onClick={this.handleOpenModalPDF(settingsFiles.SETTINGS_EMPLOYEE_SUPERANNUATION)} classes={{ root: "block", label: classes.blockLabel }}>
									{"Superforms"}
								</Button>

								<Button component={Link} onClick={this.handleOpenModalPDF(settingsFiles.SETTINGS_PAYG_PAYMENT_SUMMARY)} classes={{ root: "block", label: classes.blockLabel }}>
									{"PAYG Payment Summary Statement"}
								</Button>

								<Button component={Link} onClick={this.handleOpenModalPDF(settingsFiles.SETTINGS_ATO_EMPLOYEE_PAYMENT_SUMMARY)} classes={{ root: "block", label: classes.blockLabel }}>
									{"ATO Employee Payment Summary Form"}
								</Button>

								<Button component={Link} onClick={this.handleOpenModalPDF(settingsFiles.SETTINGS_FAIR_WORK_INFO_STATEMENT)} classes={{ root: "block", label: classes.blockLabel }}>
									{"Fair Work Info Statement"}
								</Button>
							</Paper>

							<Paper className={classes.settingPaper}>
								<Typography variant="h4" gutterBottom>
									{"Information"}
								</Typography>

								<Button component={Link} onClick={this.handleOpenModalPDF(settingsFiles.SETTINGS_PRIVACY_POLICY)} classes={{ root: "block", label: classes.blockLabel }}>
									{"Privacy Policy"}
								</Button>

								<Button component={Link} onClick={this.handleOpenModalPDF(settingsFiles.SETTINGS_TERMS_OF_USE)} classes={{ root: "block", label: classes.blockLabel }}>
									{"Terms of Use"}
								</Button>
							</Paper>
						</Grid>

						<Grid item xs={6}>
							<Paper className={classes.settingPaper}>
								<Typography variant="h4" gutterBottom>
									{"Settings"}
								</Typography>

								<Link component={RouterLink} to={routes.PATH_SETTINGS_2FA} className="block">
									{"Two-Factor Authentication"}
								</Link>
							</Paper>

							{/* <Paper className={classes.settingPaper}>
								<Typography variant="h4" gutterBottom>
									{`Financial Year ${employer.financialYear.year}`}
								</Typography>

								<Typography variant="body1" gutterBottom>
									{"Process financial year close"}
								</Typography>

								<Button variant="contained" color="primary" className={classes.gridLeftClearButton} onClick={isValidCloseFY ? this.handleCloseFYOpen : this.handleCloseFYDoneOpen}>
									{"Process close"}
								</Button>

								{employer && employer.financialsYear && employer.financialsYear > 1 ? (
									<Button variant="contained" color="primary" className={classes.gridLeftClearButton} onClick={this.handleOpenAuthorizedModal}>
										{"Generate payments summaries last period"}
									</Button>
								) : null}
							</Paper> */}

							{/* <Paper className={classes.settingPaper}>
								<Typography variant="h4" gutterBottom>
									{"Additional User Access"}
								</Typography>

								<Link component={RouterLink} to={routes.MANAGE_ADDITIONAL_USER} className="block">
									{"Manage Additional Users"}
								</Link>
							</Paper> */}

						</Grid>
					</Grid>

					{showModalNotification ? <ModalNotificationComponent open={showModalNotification} onCloseModal={this.handleCloseModalNotification} /> : null}
					{PDF && PDF.Url && PDF.Url.length > 0 ? <DialogCommonPDFComponent title={PDF.Name} open={PDF.Open} onCloseModal={this.handleCloseModalPDF} url={PDF.Url} /> : null}
					{showModalCloseFY ? <ModalCloseFYComponent open={showModalCloseFY} onCloseModal={this.handleCloseFYClose} /> : null}
					{showModalCLoseFYDone ? <ModalCloseFYDoneComponent open={showModalCLoseFYDone} onCloseModal={this.handleCloseFYDoneClose} /> : null}
					{showAuthorizedModal ? <ModalCloseFYAuthorizedComponent open={showAuthorizedModal} onCloseModal={this.handleCloseAuthorizedModal} generateLastSummaries /> : null}
					{showSnackBarGenerateLast ? <SnackbarCommonComponent open={showSnackBarGenerateLast} body="The payments summaries have been generated" onCloseModal={this.handleCloseSnackBarGenerateLast} /> : null}

				</Paper>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	employer: state.session.userData,
	isGenerateLastPayments: state.employer.isGenerateLastPayments
});

const mapDispatchToProps = dispatch => ({
	cleanMessages: bindActionCreators(cleanMessage, dispatch)
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(SettingStyle)(SettingComponent));
