import * as actionTypes from "../../constants/actionTypes";
import onboardInitialize from "./OnboardInitialize";

// Initialise An Onboarding Session
const OnboardReducer = (state = onboardInitialize, action) => {
	switch (action.type) {
		case actionTypes.ACTION_SEND_ONBOARD: {
			return {
				...state,
				isLoading: true,
				OnboardingData: {
					...action.payload.OnboardingData
				}
			};
		}
		case actionTypes.ACTION_SEND_ONBOARD_SUCCESS: {
			return {
				...state,
				isLoading: false,
				sessionToken: action.payload.sessionToken,
				messageCode: action.payload.messageCode,
				message: action.payload.message
			};
		}
		case actionTypes.ACTION_SEND_ONBOARD_FAILURE: {
			return {
				...state,
				error: true,
				isLoading: false,
				messageCode: action.payload.messageCode,
				message: action.payload.message
			};
		}

		// Update Data Fields During Onboarding
		case actionTypes.ACTION_ONBOARD_UPDATE_REQUEST: {
			return {
				...state,
				isLoading: true,
				businessInf: {
					...action.payload.businessInf
				},
				businessData: {
					...action.payload.businessData
				},
				updateOnboardingSucsee: action.payload.updateOnboardingSucsee,
				currentIndex: action.payload.currentIndex,
			};
		}
		case actionTypes.ACTION_ONBOARD_UPDATE_REQUEST_SUCCESS: {
			return {
				...state,
				isLoading: false,
				messageCode: action.payload.messageCode,
				message: action.payload.message,
				updateOnboardingSucsee: action.payload.updateOnboardingSucsee,
				accountType: action.payload.accountType,
			};
		}
		case actionTypes.ACTION_ONBOARD_UPDATE_REQUEST_FAILURE: {
			return {
				...state,
				error: true,
				isLoading: false,
				messageCode: action.payload.messageCode,
				message: action.payload.message,
				updateOnboardingSucsee: action.payload.updateOnboardingSucsee
			};
		}

		// Complete The Initial Onboarding and Create Account
		case actionTypes.ACTION_ONBOARD_GET_REQUEST: {
			return {
				...state,
				isLoading: true,
			};
		}
		case actionTypes.ACTION_ONBOARD_GET_REQUEST_SUCCESS: {
			return {
				...state,
				isLoading: false,
				messageCode: action.payload.messageCode,
				message: action.payload.message,
				accountData: { ...action.payload.accountData }
			};
		}
		case actionTypes.ACTION_ONBOARD_GET_REQUEST_FAILURE: {
			return {
				...state,
				error: true,
				isLoading: false,
				messageCode: action.payload.messageCode,
				message: action.payload.message,
			};
		}

		// clean massage
		case actionTypes.ACTION_ONBOARD_CLEAN_MESSAGE: {
			return {
				...state,
				error: false,
				isLoading: false,
				errorMessage: action.payload.errorMessage,
				messageCode: action.payload.messageCode,
				message: action.payload.message,
				updateOnboardingSucsee: action.payload.updateOnboardingSucsee
			};
		}

		default: {
			return {
				...state
			};
		}
	}
};

export default OnboardReducer;
