import * as actionTypes from "../constants/actionTypes";

export function processReportRequest(reportData) {
	
	return {
		type: actionTypes.ACTION_REPORTS_PROCESS_REQUEST,
		payload: {
			isLoading: true,
			reportData
		}
	};
}

export function processReportRequestSuccess(reportData, messageCode, message) {
	return {
		type: actionTypes.ACTION_REPORTS_PROCESS_REQUEST_SUCCESS,
		payload: {
			reportData,
			messageCode,
			message
		}
	};
}

export function processReportRequestFailure(messageCode, errorMessage) {
	return {
		type: actionTypes.ACTION_REPORTS_PROCESS_REQUEST_FAILURE,
		error: true,
		payload: {
			messageCode,
			errorMessage
		}
	};
}

export function payslipsRequest(filterData) {
	return {
		type: actionTypes.ACTION_REPORTS_PAYSLIPS_REQUEST,
		payload: {
			isLoading: true,
			filterData
		}
	};
}

export function payslipsRequestSuccess(payslips, messageCode, message) {
	return {
		type: actionTypes.ACTION_REPORTS_PAYSLIPS_REQUEST_SUCCESS,
		payload: {
			payslips,
			messageCode,
			message
		}
	};
}

export function payslipsRequestFailure(messageCode, errorMessage) {
	return {
		type: actionTypes.ACTION_REPORTS_PAYSLIPS_REQUEST_FAILURE,
		error: true,
		payload: {
			messageCode,
			errorMessage
		}
	};
}

export function payrollSendEmailRequest(filterData) {
	return {
		type: actionTypes.ACTION_PAYROLL_SEND_EMAIL_REQUEST,
		payload: {
			isLoading: true,
			filterData
		}
	};
}

export function payrollSendEmailRequestSuccess(emailSent, messageCode, message) {
	return {
		type: actionTypes.ACTION_PAYROLL_SEND_EMAIL_REQUEST_SUCCESS,
		payload: {
			emailSent,
			messageCode,
			message
		}
	};
}

export function payrollSendEmailRequestFailure(messageCode, errorMessage) {
	return {
		type: actionTypes.ACTION_PAYROLL_SEND_EMAIL_REQUEST_FAILURE,
		error: true,
		payload: {
			messageCode,
			errorMessage
		}
	};
}

export function payrollDeleteRequest(id, filterData) {
	return {
		type: actionTypes.ACTION_PAYROLL_DELETE_REQUEST,
		payload: {
			isLoading: true,
			id,
			filterData
		}
	};
}

export function payrollDeleteRequestSuccess(deleted, messageCode, message) {
	return {
		type: actionTypes.ACTION_PAYROLL_DELETE_REQUEST_SUCCESS,
		payload: {
			deleted,
			messageCode,
			message
		}
	};
}

export function payrollDeleteRequestFailure(messageCode, errorMessage) {
	return {
		type: actionTypes.ACTION_PAYROLL_DELETE_REQUEST_FAILURE,
		error: true,
		payload: {
			messageCode,
			errorMessage
		}
	};
}

export function payrollDeleteRequestThunkSuccess(deleted, filterData, messageCode, successMessage) {
	return dispatch => Promise.resolve(dispatch(payrollDeleteRequestSuccess(deleted, messageCode, successMessage))).then(() => dispatch(payslipsRequest(filterData)));
}

export function clearReportData(reportData) {
	return {
		type: actionTypes.ACTION_REPORTS_CLEAN_DATA,
		payload: {
			reportData
		}
	};
}

export function clearPayslips(payslips) {
	return {
		type: actionTypes.ACTION_REPORTS_PAYSLIPS_CLEAN_DATA,
		payload: {
			payslips
		}
	};
}

// process Report PayrollActivityDetail
export function processReportPayrollActivityDetailRequest(reportData) {
	
	return {
		type: actionTypes.ACTION_REPORTS_PROCESS_ACTIVITY_DETAIL_REQUEST,
		payload: {
			isLoading: true,
			reportData
		}
	};
}

export function processReportPayrollActivityDetailRequestSuccess(reportData, messageCode, message) {
	return {
		type: actionTypes.ACTION_REPORTS_PROCESS_ACTIVITY_DETAIL_REQUEST_SUCCESS,
		payload: {
			reportData,
			messageCode,
			message
		}
	};
}

export function processReportPayrollActivityDetailRequestFailure(messageCode, errorMessage) {
	return {
		type: actionTypes.ACTION_REPORTS_PROCESS_ACTIVITY_DETAIL_REQUEST_FAILURE,
		error: true,
		payload: {
			messageCode,
			errorMessage
		}
	};
}


// clean Message
export function cleanMessage() {
	return {
		type: actionTypes.ACTION_REPORTS_CLEAN_MESSAGE,
		payload: {
			errorMessage: null,
			messageCode: null,
			message: null
		}
	};
}
