import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

import { Typography } from "@material-ui/core";
import SettingStyle from "./settingStyle";

import { DialogConfirmCommonComponent } from "../common";
import ModalCloseFYAuthorizedComponent from "./modalCloseFYAuthorizedComponent";

class ModalCloseFYComponent extends Component {
	state = {
		showAuthorizedModal: false
	};

	handleOpenAuthorizedModal = () => {
		this.setState({ showAuthorizedModal: true });
	};

	handleCloseAuthorizedModal = () => {
		this.setState({ showAuthorizedModal: false });
	};

	render() {
		const {
			classes, employer, open, onCloseModal
		} = this.props;
		const { showAuthorizedModal } = this.state;

		return (
			<div>
				<DialogConfirmCommonComponent open={open} title={`Process Close ${employer.financialYear.name}`} primaryButtonName="Yes" secondaryButtonName="No" onCloseModal={onCloseModal} onPrimaryButtonClick={this.handleOpenAuthorizedModal}>
					<Typography className={classes.centerText}>
						{`You are unable to process payslips in the new financial year until you have finalised and confirmed all ${employer.financialYear.name} Payslips have been created and the details are accurate.`}
						<br />
						<br />
						{"To do this you will need to Process a Financial Year Close."}
						<br />
						<br />
						{`After running the Financial Year Close Process, you will be unable to process any more Payslips for your employees in the ${employer.financialYear.year} financial year.`}
						<br />
						<br />
						{"The process will automatically generate your employee's Payment Summaries."}
						<br />
						<br />
						<b>Do you want to proceed to the Financial Year Close Process?</b>
					</Typography>
				</DialogConfirmCommonComponent>
				{showAuthorizedModal ? <ModalCloseFYAuthorizedComponent open={showAuthorizedModal} onCloseModal={this.handleCloseAuthorizedModal} /> : null}
			</div>
		);
	}
}

ModalCloseFYComponent.propTypes = {
	employer: PropTypes.object
};

const mapStateToProps = state => ({
	employer: state.session.userData
});

export default connect(
	mapStateToProps,
	null
)(withRouter(withStyles(SettingStyle)(ModalCloseFYComponent)));
