const employeeStyles = theme => ({
	root: {
		...theme.root,
		paddingBottom: 20
	},
	paper: {
		width: "100%",
		height: 65,
		borderRadius: 5,
		border: "1px solid #e9e9e9",
		backgroundColor: "#ffffff",
		padding: "10px 20px",
		boxShadow: "none",
		marginBottom: 12,
		"&:last-child": {
			marginBottom: 0
		},
		"&:hover": {
			boxShadow: "0 2px 2px 0 #D8D8D8"
		}
	},
	IconCheck: {
		fontSize: 23,
		color: "#D8D8D8"
	},
	IconCheckActive: {
		fontSize: 23,
		color: "#007aff"
	},
	alignItemMenu: {
		textAlign: "right"
	},
	divider: {
		marginBottom: 18,
		marginTop: 10
	},
	gridContainer: {
		marginBottom: 16
	},
	gridLeft: {
		paddingLeft: 30
	},
	gridRight: {
		textAlign: "right"
	},
	gridRightClearButton: {
		marginBottom: 0
	},
	imageEmptyContainer: {
		width: "100%",
		textAlign: "center",
		marginTop: 40
	},
	imageEmptyImage: {
		width: 580,
		marginLeft: -70,
		marginBottom: 20
	},
	subtitleHeader: {
		fontFamily: "AvenirNextLTPro-Regular",
		color: "#7D848B"
	},
	paperContainer: {
		...theme.paperContainer
	},
	paperGrid: {
		...theme.paperClear
	},
	paperGridClearRight: {
		paddingRight: 0
	},
	option: {
		...theme.option
	},
	keyboardDatePicker: {
		...theme.keyboardDatePicker
	},
	keyboardDatePickerInput: {
		...theme.keyboardDatePickerInput
	},
	borderTextField: {
		...theme.borderTextField
	},
	containerItem: {
		margin: -7
	},
	modaldescription: {
		textAlign: 'left',
		fontFamily: 'AvenirNextLTPro-Regular',
		fontSize: 20,
		letterSpacing: 0,
		color: '#000000',
		opacity: 1,
		lineHeight: 34,
	},
	modaldescription2: {
		textAlign: 'left',
		fontFamily: 'AvenirNextLTPro-Regular',
		fontSize: 20,
		letterSpacing: 0,
		color: '#000000',
		opacity: 1,
		lineHeight: 87

	},
	add_employee_rightColumn_text: {
		textAlign: 'left',
		marginTop: 31,

	},
	add_employee_rigthColumn: {
		textAlign: 'center !important',
		width: 299
	},
	add_employee_rigthColumnImg: {
		textAlign: 'center'
	},
	add_employee_btn_create_payslip: {
		textAlign: 'center',
		fontFamily: 'AvenirNextLTPro-MediumCn !important',
		fontSize: 18,
		marginLeft: 0,
		marginRight: 0,
		marginTop: 85,
		letterSpacing: 0,
		color: '#FFFFFF',
		opacity: 1,
		backgroundColor: '#004FDC',
		width: 299,
		height: 48,
		borderRadius: '5px !important',
		textAlign: 'center !important',

		"& span": {
			textAlign: 'center',
			fontFamily: 'AvenirNextLTPro-Demi !important',
			fontSize: 18,
			letterSpacing: 0,
			color: '#FFFFFF',
			opacity: 1
		},
		"&:hover": {
			backgroundColor: '#004FDC',
		}
	},
	rightColumn_link_doThisLater: {
		textAlign: 'center',
		fontFamily: 'AvenirNextLTPro-MediumCn',
		fontSize: 18,

		letterSpacing: 0,
		color: '#004FDC',
		opacity: 1,

	},
	add_employee_Link_Do: {
		textAlign: 'center',
		fontFamily: 'AvenirNextLTPro-Medium',
		fontSize: 16,
		cursor: 'pointer',
		letterSpacing: 0,
		color: '#004FDC',
		opacity: 1,
		lineHeight: 68
	},
	add_employee_dialogContainer: {
		width: '100%',
		height: '100%'
	},
	add_employee_logoroot: {
		marginLeft: 0

	},
	add_employee_headerLogo: {
		color: '#F02F0F',
		fontSize: 18
	},
	textFieldwelldone1: {
		textAlign: 'left',
		fontFamily: 'AvenirNextLTPro-Demi',
		fontSize: 36,
		letterSpacing: "-0.58px",
		color: '#000000',
		opacity: 1,
		marginTop: 38,
		lineHeight: 54,


	},
	textFieldwelldone2: {
		textAlign: 'left',
		fontFamily: 'AvenirNextLTPro-Demi',
		fontSize: 36,
		letterSpacing: "-0.58px",
		color: '#000000',
		opacity: 1,
		marginTop: -10,
		lineHeight: 54,
	},
	add_employee_imageLogo: {
		height: 41.4
	},
	textField: {
		height: "43px !important",
		backgroundColor: '#f5f5f5 !important',
		"& div": {
			// "& input":{
			// 	backgroundColor: '#f5f5f5 !important',

			// },
			height: "41px !important",
			"& div": {
				"& button": {
					"& span": {
						"& svg": {
							"& path": {
								color: 'red !important',
							}
						}
					}
				}
			}

		},
		"& label": {
			color: '#6D9FEE !important'
		}
	},
	// textField: {
	// 	height: "66px !important",
	// 	backgroundColor: '#f5f5f5',
	// 	"& div": {

	// 		height: "64px !important",
	// 		"& button": {
	// 			height: '42px !important',
	// 			"& svg": {
	// 				height: 21
	// 			},
	// 			"& span": {
	// 				"& svg": {
	// 					height: '21px !important'
	// 				}
	// 			}
	// 		},
	// 		"& input": {
	// 			height: "64px"
	// 		},
	// 		"& svg": {
	// 			height: '64px !important',
	// 			"&:hover": {
	// 				height: "21px !important"
	// 			}
	// 		}
	// 	},
	// 	"& label": {
	// 		color: '#6D9FEE !important'
	// 	},
	//
	// },
	firstTitle: {
		marginBottom: 60
	},
	buttonFinish: {
		minWidth: "200px",
		borderRadius: '5px !important',
		opacity: 1,
		// width: 219,
		height: 40,
		textAlign: 'center',
		letterSpacing: 0,
		color: '#FFFFFF',
		"& span": {
			fontSize: "15px",
			fontFamily: 'AvenirNextLTPro-Demi !important',
			fontWeight: "bold",
		}
	},
	buttonSaveDetails: {
		"& span": {
			fontSize: "18px",
			fontFamily: 'AvenirNextLTPro-Demi !important',
		}
	},
	orangeRectangularBtn: {
		minWidth: "200px",
		borderRadius: '5px !important',
		opacity: 1,
		// width: 219,
		height: 40,
		textAlign: 'center',
		letterSpacing: 0,
		color: '#FFFFFF',
		"& span": {
			fontSize: "15px",
			fontFamily: 'AvenirNextLTPro-Demi !important',
			fontWeight: "bold",
		}
	},
	disabledButtonSecondary: {
		...theme.overrides.disabledButtonSecondary
	}
});

export default employeeStyles;
