import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import moment from "moment-timezone";


import {
	Typography, Grid, Button, Paper, TextField
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";

import PayrollStyle from "./payrollStyle";

import EmployeesComponent from "../reports/employeesComponent";
import { CardCommonComponent, SubscriptionUpgradeComponent, SwitchCommonComponent, Analytics } from "../common";

import * as employeeActions from "../../actions/EmployeeActions";
import * as parameterActions from "../../actions/ParameterActions";
import * as payrollActions from "../../actions/PayrollActions";
import * as parameterTypes from "../../constants/parameterTypes";
import { payrollDefaultData } from "../../reducers/Payroll/PayrollInitialState";

import * as routes from "../../constants/routes";
import { getUTCDate, getLocalToUTC, getUTCToLocal } from "../../helpers/dateextensions";
import { SUBSCRIPTION_STATUS_ACTIVE, SUBSCRIPTION_STATUS_TRIAL } from "../../constants/subscriptionStatus";

const DatePickerField = ({
	field, form, classes, minDate, maxDate, ...other
}) => {

	const currentError = form.errors[field.name];
	return (
		<KeyboardDatePicker type="date-local"
			name={field.name}
			label={field.label}
			value={getUTCToLocal(field.value)}
			format="DD/MM/YYYY"
			fullWidth
			error={Boolean(currentError)}
			onError={(_, error) => form.setFieldError(field.name, error)}
			onChange={date => {console.log(field.name); console.log(date); return form.setFieldValue(field.name, date, true)}}
			className={clsx(classes.textField, {
				errorDatePicker: Boolean(currentError)
			})}
			InputProps={{
				classes: {
					root: classes.keyboardDatePicker,
					input: classes.keyboardDatePickerInput
				}
			}}
			minDate={minDate}
			maxDate={maxDate}
			{...other}
		/>
	);
};

class PayrollComponent extends Component {
	validationSchema = Yup.object({
		startDate: Yup.date().required("Start Date is required"),
		finishDate: Yup.date()
			.required("Finish Date is required")
			.when("startDate", (startDate, schema) => startDate && schema.min(new Date(startDate - 24 * 3600 * 1000), "The start date must be less than the end date.")),
		paymentDate: Yup.date().required("Payment Date is required"),
		paymentType: Yup.string().required("Payment Type is required"),
		employees: Yup.array().required("Selected employees")
	});

	state = {
		openUpgradeDialog: false
	};

	componentDidMount() {
		const { isFinalClean, getAllEmployees, loadParamters, mixpanel } = this.props;
		loadParamters(parameterTypes.PARAMETER_PAYMENT_TYPE_PAYROLL.parameterType, parameterTypes.PARAMETER_PAYMENT_TYPE_PAYROLL.section, parameterTypes.PARAMETER_PAYMENT_TYPE_PAYROLL.typeSection);
		isFinalClean();
		getAllEmployees();
		Analytics.PR_1InitiatePayrollScreen();
		// mixpanel.track("Payroll.Screen");
	}

	handleOpenUpgrade = () => {
		this.setState(prevState => ({ ...prevState, openUpgradeDialog: true }));
	};

	handleCloseUpgrade = () => {
		this.setState(prevState => ({ ...prevState, openUpgradeDialog: false }));
	};

	handleSubmitProcess = async (values, actions) => {
		const { savePayrollData, history, employer } = this.props;

		values.finishDate = getLocalToUTC(values.finishDate)
		values.paymentDate = getLocalToUTC(values.paymentDate)
		values.startDate = getLocalToUTC(values.startDate)

		if (employer && employer.subscription && employer.subscription.status !== SUBSCRIPTION_STATUS_ACTIVE && employer.subscription.status !== SUBSCRIPTION_STATUS_TRIAL) {
			this.handleOpenUpgrade();
		} else {
			actions.setSubmitting(false);
			const payrollData = {
				...values,
				listPayrollDataisFinal: null,
				employeeActive: {
					detail: {
						...values.employees[0]
					}
				}
			};
			await savePayrollData(payrollData, history, routes.PATH_PAYROLL_PROCESS);
		}
	};

	renderPaymentTypeOptions = () => {
		const { paymentTypeList, classes } = this.props;
		return paymentTypeList
			.sort((firstEl, secondEl) => firstEl.order < secondEl.order)
			.map(item => (
				<option key={`${item.id}`} value={`${item.value}`} className={classes.option}>
					{item.value}
				</option>
			));
	};

	renderForm = (formProps) => {
		const { classes, employees, employer, parentFormProps } = this.props;
		const { openUpgradeDialog } = this.state;

		if (!employer.financialYear) {
			employer.financialYear = {
				dateFrom: '2018-07-01T00:00:00Z',
				dateTo: '2019-06-30T00:00:00Z'
			};
		}

		return (
			<form>
				<Paper className={classes.paperContainer}>
					<Typography variant="h1" gutterBottom className={classes.payrollTitle}>
						Payroll
					</Typography>
					<Typography className={classes.payrollSubTitle}>Let’s enter some payroll dates</Typography>
					<Grid item xs={12} className={classes.gridRight}>
						<Button variant="contained"
							color="secondary"
							className={clsx(classes.btnProcessPayrol, classes.orangeRectangularBtn, { [classes.disabledButtonSecondary]: !formProps.isValid })}
							onClick={formProps.handleSubmit}
							disabled={!formProps.isValid}
						>
							Process Payroll
						</Button>
					</Grid>
					<CardCommonComponent title="Select dates and payment type" complete={false} hideExpandCheck>
						<Grid container direction="row" alignItems="flex-start" justify="space-around">
							<Grid item xs={3}>
								<Paper className={classes.paperGrid}>
									<Field name="startDate" label="Start Date" component={DatePickerField} classes={classes} />
								</Paper>
							</Grid>
							<Grid item xs={3}>
								<Paper className={classes.paperGrid}>
									<Field name="finishDate" label="Finish Date" component={DatePickerField} classes={classes} />
								</Paper>
							</Grid>
							<Grid item xs={3}>
								<Paper className={classes.paperGrid}>
									<Field name="paymentDate" label="Payment Date" component={DatePickerField} classes={classes} minDate={getUTCDate(employer.financialYear.dateFrom).subtract(16, "hours")} maxDate={getUTCDate(employer.financialYear.dateTo)} />
								</Paper>
							</Grid>
							<Grid item xs={3}>
								<Paper className={classes.paperGrid}>
									<TextField
										id="paymentType"
										label="Payment Type"
										select
										variant="filled"
										fullWidth
										value={formProps.values.paymentType}
										onChange={formProps.handleChange}
										onBlur={formProps.handleBlur}
										className={clsx(classes.textField, {
											error: formProps.errors.paymentType && formProps.touched.paymentType
										})}
										SelectProps={{
											native: true,
											MenuProps: {
												className: classes.menu
											}
										}}
									>
										{this.renderPaymentTypeOptions()}
									</TextField>
								</Paper>
							</Grid>
						</Grid>
					</CardCommonComponent>

					<Grid container direction="row" alignItems="flex-start">
						<Grid item xs={4}>
							<Paper className={classes.paperGrid}>
								<Paper className={classes.paper}>
									<SwitchCommonComponent
										id="payroll.isFinal"
										title="Final Payslip for Employee"
										checked={formProps.values.payroll.isFinal}
										onChange={formProps.handleChange}
										onBlur={formProps.handleBlur}
									/>
								</Paper>
							</Paper>
						</Grid>
					</Grid>

					{employees && employees.length > 0 ? <EmployeesComponent error={formProps.errors.employees && formProps.touched.employees} employees={employees} name="employees" formProps={formProps} returnObject /> : null}

					<Grid item xs={12} className={classes.gridRight}>
						<Button variant="contained" color="secondary"
							className={clsx(classes.btnProcessPayrol, classes.orangeRectangularBtn, { [classes.disabledButtonSecondary]: !formProps.isValid })}
							onClick={formProps.handleSubmit} disabled={!formProps.isValid}>
							Process Payroll
						</Button>
					</Grid>
				</Paper>
				{openUpgradeDialog ? <SubscriptionUpgradeComponent open={openUpgradeDialog} onCloseModal={this.handleCloseUpgrade} showMessagePayroll /> : null}
			</form>
		);
	};

	render() {
		const { classes, employer } = this.props;
		let { paymentDate } = payrollDefaultData;
		if (employer.financialYear && paymentDate > getUTCDate(employer.financialYear.dateTo)) {
			// paymentDate = getUTCDate(employer.financialYear.dateTo);
		}
		const dataPayroll = {
			...payrollDefaultData,
			employerId: employer.id,
			paymentDate
		};

		return (
			<Grid container className={classes.root}>
				<Formik initialValues={{ ...dataPayroll }} validationSchema={this.validationSchema} onSubmit={this.handleSubmitProcess} render={this.renderForm} enableReinitialize />
			</Grid>
		);
	}
}

PayrollComponent.propTypes = {
	employees: PropTypes.array,
	paymentTypeList: PropTypes.array,
	employer: PropTypes.object,

	getAllEmployees: PropTypes.func,
	loadParamters: PropTypes.func,
	savePayrollData: PropTypes.func
};

const mapStateToProps = state => ({
	employees: state.employees.activeEmployeesList,
	paymentTypeList: state.parameters.parameterByTypeData[parameterTypes.PARAMETER_PAYMENT_TYPE_PAYROLL.parameterType],
	employer: state.session.userData
});

const mapDispatchToProps = dispatch => ({
	getAllEmployees: bindActionCreators(employeeActions.employeesRequest, dispatch),
	loadParamters: bindActionCreators(parameterActions.getParametersByTypeRequest, dispatch),
	savePayrollData: bindActionCreators(payrollActions.savePayrollDataComplete, dispatch),
	isFinalClean: bindActionCreators(payrollActions.isFinalClean, dispatch)
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(withStyles(PayrollStyle)(PayrollComponent)));
