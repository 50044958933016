import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import { CssBaseline } from "@material-ui/core";
import AuthenticatedComponent from "./components/Session/AuthenticatedComponent";
import AuthenticatedComponentOnboard from "./components/Session/AuthenticatedComponentOnboard";
import AccountsPage from "./pages/AccountsPage";
import AccountCreatePage from "./pages/AccountCreatePage";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import EmployerRegisterPage from "./pages/EmployerRegisterPage";
import EmployeesPage from "./pages/EmployeesPage";
import NewEmployeePage from "./pages/NewEmployeePage";
import EditEmployeePage from "./pages/EditEmployeePage";

import * as routes from "./constants/routes";
import PayrollPage from "./pages/PayrollPage";
import PayrollProcessPage from "./pages/PayrollProcessPage";
import SettingsPage from "./pages/SettingsPage";
import TwoFactorAuthenticationPage from "./pages/TwoFactorAuthenticationPage";
import ManagerUserAccessComponentPage from "./pages/ManagerUserAccessComponentPage";
import TwoFactorChoiceAuthenticationPage from "./pages/TwoFactorChoiceAuthenticationPage";
import SignUpPage from "./pages/SignUpPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import Login2FAPage from "./pages/Login2FAPage";
import ReportsPage from "./pages/ReportsPage";
import PaymentPage from "./pages/PaymentPage";
import SingleTouchPage from "./pages/SingleTouchPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import SubscriptionPage from "./pages/SubscriptionPage";
import PlanPage from "./pages/PlanPage";
import RecoverPage from "./pages/RecoverPage";
import LoginUnifyPage from "./pages/LoginUnifyPage";
import EasyPaySlipPtyRegisterPage from "./pages/EasyPaySlipPtyRegisterPage";
import QuickSetupPage from "./pages/QuickSetupPage";
import QuickSetupSuccessPage from "./pages/QuickSetupSuccessPage";
import QuickSetupAccountantPage from "./pages/QuickSetupAccountantPage";
import QuickSetupAccountantSuccessPage from "./pages/QuickSetupAccountantSuccessPage";
import ChooseEmployeesForEditSTP from "./pages/selectEmployeeCLoseFy";
import EditEmployeeCLoseFy from "./pages/editEmployeeCLoseFy";

import logo from "./assets/images/LOGOEasyPayslipHorizontal.svg";

class MaintenancePage extends Component {
  render() {
    const {
      classes,
      isLoading,
      externalMessageCode,
      externalMessage,
      externalCleanMessageInternal,
      sessionMessageCode,
      sessionMessage,
      sessionCleanMessageInternal,
    } = this.props;

    return (
      <div>
        <CssBaseline />
        <div style={{ padding: 32, textAlign: "center" }}>
          <img style={{ maxWidth: 400 }} src={logo} />
          <h1 style={{ fontSize: 40, marginTop: 32 }}>Down for Maintenance</h1>
          <img
            style={{ maxWidth: 400 }}
            src="https://i.ibb.co/j8fk0BM/56c68b8b47efbb184ed2c2bc9c143239.png"
          />
          <p style={{ fontSize: 18 }}>
            We'll be back on <strong>Monday at 9AM</strong>. We apologise for
            the incovenience.
          </p>
        </div>
      </div>
    );
  }
}

// TOGGLE TO TURN ON MAINTENANCE PAGE
const isMaintenance = true;
class Root extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route
            path={routes.PATH_LOGIN}
            component={isMaintenance ? MaintenancePage : LoginPage}
          />
          {!isMaintenance && (
            <>
              <Route path={routes.PATH_SIGN_UP} component={SignUpPage} />
              <Route
                path={routes.PATH_FORGOT_PASSWORD}
                component={ForgotPasswordPage}
              />
              <Route
                path={`${routes.PATH_RECOVER_PASSWORD}/:id`}
                component={RecoverPage}
              />
              <Route
                path={`${routes.PATH_LOGIN_UNIFY}/:id`}
                component={LoginUnifyPage}
              />

              <AuthenticatedComponent
                path={routes.PATH_ACCOUNTS_CREATE}
                component={AccountCreatePage}
                {...this.props}
              />
              <AuthenticatedComponent
                path={routes.PATH_ACCOUNTS}
                component={AccountsPage}
                {...this.props}
              />
              <AuthenticatedComponent
                path={routes.PATH_RESET_PASSWORD}
                component={ResetPasswordPage}
                {...this.props}
              />

              <AuthenticatedComponent
                path={routes.PATH_LOGIN_2FA}
                component={Login2FAPage}
                {...this.props}
              />

              <AuthenticatedComponent
                path={routes.PATH_EMPLOYEE_ADD}
                component={NewEmployeePage}
                {...this.props}
              />
              <AuthenticatedComponent
                path={`${routes.PATH_EMPLOYEE_EDIT}/:id`}
                component={EditEmployeePage}
                {...this.props}
              />
              <AuthenticatedComponent
                path={routes.PATH_EMPLOYEE}
                component={EmployeesPage}
                {...this.props}
              />

              <AuthenticatedComponent
                path={routes.PATH_EMPLOYER_REGISTER}
                component={EmployerRegisterPage}
                {...this.props}
              />
              <AuthenticatedComponent
                path={routes.PATH_EMPLOYER}
                component={HomePage}
                {...this.props}
              />

              <AuthenticatedComponent
                path={routes.PATH_PAYROLL_PROCESS}
                component={PayrollProcessPage}
                {...this.props}
              />
              <AuthenticatedComponent
                path={routes.PATH_PAYROLL}
                component={PayrollPage}
                {...this.props}
              />

              <AuthenticatedComponent
                path={routes.PATH_REPORTS}
                component={ReportsPage}
                {...this.props}
              />
              <AuthenticatedComponent
                path={routes.PATH_PAYMENT_SUMMARY}
                component={PaymentPage}
                {...this.props}
              />
              <AuthenticatedComponent
                path={routes.PATH_SINGLE_TOUCH_PAYROLL}
                component={SingleTouchPage}
                {...this.props}
              />

              <AuthenticatedComponent
                path={routes.PATH_SUBSCRIPTION_PLAN}
                component={PlanPage}
                {...this.props}
              />
              <AuthenticatedComponent
                path={routes.PATH_SUBSCRIPTION}
                component={SubscriptionPage}
                {...this.props}
              />

              <AuthenticatedComponent
                path={routes.PATH_SETTINGS_2FA}
                component={TwoFactorAuthenticationPage}
                {...this.props}
              />
              <AuthenticatedComponent
                path={routes.PATH_SETTINGS_SELECT}
                component={ChooseEmployeesForEditSTP}
                {...this.props}
              />
              <AuthenticatedComponent
                path={routes.PATH_SETTINGS_SELECT_UPDATE}
                component={EditEmployeeCLoseFy}
                {...this.props}
              />
              <AuthenticatedComponent
                path={routes.MANAGE_ADDITIONAL_USER}
                component={ManagerUserAccessComponentPage}
                {...this.props}
              />
              <AuthenticatedComponent
                path={routes.PATH_SETTINGS_2FA_CHOICE}
                component={TwoFactorChoiceAuthenticationPage}
                {...this.props}
              />
              <AuthenticatedComponent
                path={routes.PATH_SETTINGS}
                component={SettingsPage}
                {...this.props}
              />
              <AuthenticatedComponent
                path={routes.PATH_ACCOUNTANT_REGISTER}
                component={EasyPaySlipPtyRegisterPage}
                {...this.props}
              />

              <AuthenticatedComponentOnboard
                path={routes.PATH_QUICK_SETUP}
                component={QuickSetupPage}
              />
              <AuthenticatedComponentOnboard
                path={routes.PATH_QU_SE_SUCCESS}
                component={QuickSetupSuccessPage}
              />

              <Route
                path={routes.PATH_QUICK_SETUP_ACCOUNTANT}
                component={QuickSetupAccountantPage}
              />
              <AuthenticatedComponentOnboard
                path={routes.PATH_QU_SE_ACCOUNTANT_SUCCESS}
                component={QuickSetupAccountantSuccessPage}
              />
            </>
          )}

          <Redirect to={routes.PATH_LOGIN} />
        </Switch>
      </Router>
    );
  }
}

export default Root;
