import React from "react";
import clsx from "clsx";
import withStyles from "@material-ui/core/styles/withStyles";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import WarningIcon from "@material-ui/icons/Warning";
import { SnackbarStyles } from "./commonStyles";

const variantIcon = {
	success: CheckCircleIcon,
	warning: WarningIcon,
	error: ErrorIcon,
	info: InfoIcon
};

const colorIcon = {
	success: "iconSuccess",
	error: "iconError",
	info: "iconInfo"
};

const SnackbarCommonComponent = (props) => {
	const {
		classes, body, open, variant, autoHideDuration, onCloseModal, ...others
	} = props;
	
	const Icon = variantIcon[variant || "success"];

	const anchorConfig = {
		vertical: "top",
		horizontal: "center"
	};

	const handleOnClose = () => {
		if (onCloseModal) {
			onCloseModal();
		}
	};

	if (open) {
		return (
			<Snackbar {...others} anchorOrigin={anchorConfig} autoHideDuration={autoHideDuration ? autoHideDuration : 5000} open={open} onClose={handleOnClose}>
				<SnackbarContent
					className={clsx(classes[variant || "success"], {
						[variant]: true
					})}
					aria-describedby="client-snackbar"
					message={(
						<span
							id="client-snackbar"
							className={clsx(classes.message, {
								[variant]: true
							})}
						>
							<Icon className={clsx(classes.icon, classes[colorIcon[variant || "success"]])} />
							{body}
						</span>
					)}
				/>
			</Snackbar>
		);
	}

	return null;
};

export default withStyles(SnackbarStyles)(SnackbarCommonComponent);
