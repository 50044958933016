import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Formik } from "formik";
import * as Yup from "yup";

import clsx from "clsx";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import {
	Box, Grid, Paper, TextField, Typography
} from "@material-ui/core";

import * as employerActions from "../../actions/EmployerActions";

import { DialogConfirmCommonComponent } from "../common";
import SettingStyle from "./settingStyle";

class ModalCloseFYAuthorizedComponent extends Component {
	validationSchema = Yup.object({
		firstName: Yup.string().required("First name is required"),
		lastName: Yup.string().required("Last name is required")
	});

	handleSubmitSTP = (value, actions) => {
		const {
			closeFY, history, generateLastSummaries, generateLastPaymentsRequest
		} = this.props;
		actions.setSubmitting(false);

		const valuesSend = {
			authorize_FirstName: value.firstName,
			authorize_LastName: value.lastName
		};

		if (!generateLastSummaries) {
			closeFY(valuesSend, history);
		} else {
			generateLastPaymentsRequest(valuesSend);
		}
	};

	renderForm = (formProps) => {
		const { classes, open, onCloseModal } = this.props;

		return (
			<DialogConfirmCommonComponent title="Enter Authorised Person" primaryButtonName="Process" onPrimaryButtonClick={formProps.handleSubmit} open={open} onCloseModal={onCloseModal} primaryButtonDisabled={!formProps.isValid || formProps.isSubmitting}>
				<Grid container direction="row" className={classes.signatureContainer}>
					<Grid item xs={12}>
						<Typography variant="body1" gutterBottom className={classes.centerText}>
							{"This name will be populated in the Payment Summary for Lodgment with the ATO"}
						</Typography>
					</Grid>
					<Box m={2} />
					<Grid container direction="row" alignItems="center" spacing={2} justify="center">
						<Grid item xs={5}>
							<Paper className={clsx(classes.paperGridNotPadding)}>
								<TextField
									id="firstName"
									label="First name"
									className={clsx(classes.textField, {
										error: formProps.errors.firstName && formProps.touched.firstName
									})}
									variant="filled"
									fullWidth
									value={formProps.values.firstName}
									onChange={formProps.handleChange}
									onBlur={formProps.handleBlur}
								/>
							</Paper>
						</Grid>
						<Grid item xs={5}>
							<Paper className={clsx(classes.paperGridNotPadding)}>
								<TextField
									id="lastName"
									label="Last name"
									className={clsx(classes.textField, {
										error: formProps.errors.lastName && formProps.touched.lastName
									})}
									variant="filled"
									fullWidth
									value={formProps.values.lastName}
									onChange={formProps.handleChange}
									onBlur={formProps.handleBlur}
								/>
							</Paper>
						</Grid>
					</Grid>
				</Grid>
			</DialogConfirmCommonComponent>
		);
	};

	render() {
		return <Formik initialValues={{ firstName: "", lastName: "" }} validationSchema={this.validationSchema} onSubmit={this.handleSubmitSTP} render={this.renderForm} enableReinitialize />;
	}
}

ModalCloseFYAuthorizedComponent.propTypes = {
	closeFY: PropTypes.func,
	generateLastPaymentsRequest: PropTypes.func,
	generateLastSummaries: PropTypes.bool
};

const mapDispatchToProps = dispatch => ({
	closeFY: bindActionCreators(employerActions.closeFYRequest, dispatch),
	generateLastPaymentsRequest: bindActionCreators(employerActions.generateLastPaymentsRequest, dispatch)
});

export default connect(
	null,
	mapDispatchToProps
)(withRouter(withStyles(SettingStyle)(ModalCloseFYAuthorizedComponent)));
