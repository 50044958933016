const ABNVerification = async (httpClient, abn) => {
	const { goEpsApi } = window.envVariables;
	const response = await httpClient.get(`${goEpsApi}/api/v1/abn?${abn}`);
	return response;
};

const WPNVerification = async (httpClient, wpn) => {
	const { goEpsApi } = window.envVariables;
	const response = await httpClient.get(`${goEpsApi}/api/v1/wpn?${wpn}`);
	return response;
};

export default {
	ABNVerification,
	WPNVerification
};
