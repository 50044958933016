import * as actionTypes from "../../constants/actionTypes";
import reportsPaymentInitialState from "./ReportsPaymentInitialState";

const reportsPaymentReducer = (state = reportsPaymentInitialState, action) => {
	switch (action.type) {
		case actionTypes.ACTION_REPORTS_PAYMENT_EMPLOYER_REQUEST: {
			return {
				...state,
				isLoading: true
			};
		}
		case actionTypes.ACTION_REPORTS_PAYMENT_EMPLOYER_REQUEST_SUCCESS: {
			return {
				...state,
				isLoading: false,
				paymentsEmployer: action.payload.paymentsEmployer,
				messageCode: action.payload.messageCode,
				message: action.payload.message
			};
		}
		case actionTypes.ACTION_REPORTS_PAYMENT_EMPLOYER_REQUEST_FAILURE: {
			return {
				...state,
				error: true,
				isLoading: false,
				errorMessage: action.payload.errorMessage,
				messageCode: action.payload.messageCode,
				message: action.payload.message
			};
		}

		case actionTypes.ACTION_REPORTS_PAYMENT_EMPLOYEE_REQUEST: {
			return {
				...state,
				isLoading: true
			};
		}
		case actionTypes.ACTION_REPORTS_PAYMENT_EMPLOYEE_REQUEST_SUCCESS: {
			return {
				...state,
				isLoading: false,
				paymentsEmployees: action.payload.paymentsEmployees,
				messageCode: action.payload.messageCode,
				message: action.payload.message
			};
		}
		case actionTypes.ACTION_REPORTS_PAYMENT_EMPLOYEE_REQUEST_FAILURE: {
			return {
				...state,
				error: true,
				isLoading: false,
				errorMessage: action.payload.errorMessage,
				messageCode: action.payload.messageCode,
				message: action.payload.message
			};
		}

		case actionTypes.ACTION_PAYMENT_SEND_EMAIL_REQUEST: {
			return {
				...state,
				isLoading: true
			};
		}
		case actionTypes.ACTION_PAYMENT_SEND_EMAIL_REQUEST_SUCCESS: {
			return {
				...state,
				isLoading: false,
				messageCode: action.payload.messageCode,
				message: action.payload.message
			};
		}
		case actionTypes.ACTION_PAYMENT_SEND_EMAIL_REQUEST_FAILURE: {
			return {
				...state,
				error: true,
				isLoading: false,
				errorMessage: action.payload.errorMessage,
				messageCode: action.payload.messageCode,
				message: action.payload.message
			};
		}
		case actionTypes.ACTION_REPORTS_CLEAN_MESSAGE: {
			return {
				...state,
				error: false,
				isLoading: false,
				errorMessage: action.payload.errorMessage,
				messageCode: action.payload.messageCode,
				message: action.payload.message
			};
		}
		case actionTypes.ACTION_SESSION_LOGOUT: {
			return reportsPaymentInitialState;
		}
		default: {
			return {
				...state
			};
		}
	}
};

export default reportsPaymentReducer;
