const getAllParameters = async (httpClient) => {
	const { epsApi } = window.envVariables;
	const response = await httpClient.post(`${epsApi}/api/v2/Parameter`, {});
	return response.data;
};

const getParameterByType = async (httpClient, section, typeSection) => {
	const { epsApi } = window.envVariables;
	const response = await httpClient.get(`${epsApi}/api/v2/Parameter?section=${section}&typeSection=${typeSection}`);
	return response.data;
};

const getPlans = async (httpClient) => {
	const { goEpsApi } = window.envVariables;
	const response = await httpClient.get(`${goEpsApi}/api/v1/parameter/plans`);
	return response.data;
};

export default {
	getAllParameters,
	getParameterByType,
	getPlans
};
