import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
	Checkbox, FormControlLabel, Grid, Icon, Paper, Typography
} from "@material-ui/core";
import employeeStyle from "./employeeStyle";
import EmployeeItemComponent from "./employeeItemComponent";

class EmployeesContainerComponent extends Component {
	render() {
		const {
			classes, title, employees, enableSelectAll
		} = this.props;

		const listEmployes = employees.map(employee => (
			<Paper className={classes.paper} key={employee.id}>
				<EmployeeItemComponent employee={employee} />
			</Paper>
		));

		return (
			<div className={classes.root}>
				<Typography variant="h4" gutterBottom>
					{title}
				</Typography>
				<Grid container alignItems="center" className={classes.gridContainer}>
					<Grid item xs={3} className={classes.gridLeft}>
						{enableSelectAll ? <FormControlLabel control={<Checkbox color="primary" icon={<Icon className={classes.IconCheck}>panorama_fish_eye</Icon>} checkedIcon={<Icon className={classes.IconCheckActive}>check_circle</Icon>} />} label="Select all" /> : null}
					</Grid>
				</Grid>
				{listEmployes}
			</div>
		);
	}
}

export default withStyles(employeeStyle)(EmployeesContainerComponent);
