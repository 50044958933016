import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Formik } from "formik";
import * as Yup from "yup";

import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";

import {
	Grid, Paper, TextField, Typography
} from "@material-ui/core";
import accountsStyle from "./accountsStyle";

import * as parameterTypes from "../../constants/parameterTypes";

import { DialogCommonComponent, NumberFormatCustomComponent } from "../common";

class modalAccountant extends Component {

	handleSubmitAccountant = (value, actions) => {

		const { onHandleUser } = this.props;
		actions.setSubmitting(false);
		onHandleUser(value, actions);
	};

	renderForm = (formProps) => {
		const {
			classes, open, onCloseModal, isDelete, data, isInvite
		} = this.props;

		return (
			<DialogCommonComponent styleTitle={{ fontWeight: "bold" }} title={isDelete ? `Remove Accountant` : `Invite Employer to Account`} primaryButtonName={isDelete ? "Remove" : "Invite"} size="xs" onPrimaryButtonClick={formProps.handleSubmit} open={open} onCloseModal={onCloseModal}>
				<Grid container direction="row" alignItems="center">
					<Typography variant="body1" gutterBottom>
						{isDelete && `You will remove your access to this account
						 by clicking the 'Remove' button below.
						`}
						{
							isInvite && `This will allow the employer access to their Easy Payslip account`
						}
					</Typography>
					<Typography variant="body1" gutterBottom>
						{isDelete && `If you need to access this account in the
						 future, you will need to be granted access
						  by the Primary Account Holder.
						`}
					</Typography>
					<Grid item xs={12}>

					</Grid>
				</Grid>
			</DialogCommonComponent>
		);
	};

	render() {
		const { data } = this.props;

		const dataDefault = {
			...data
		};

		return <Formik initialValues={{ ...dataDefault }} onSubmit={this.handleSubmitAccountant} render={this.renderForm} enableReinitialize />;
	}
}

modalAccountant.propTypes = {
	onHandleUser: PropTypes.func.isRequired,
	data: PropTypes.object
};

const mapStateToProps = state => ({

});

export default connect(
	mapStateToProps,
	null
)(withStyles(accountsStyle)(modalAccountant));
