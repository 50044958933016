const activateSubscription = async (httpClient, token, planId) => {
	const { goEpsApi } = window.envVariables;
	const requestData = {
		token: token.id,
		planId
	};

	const response = await httpClient.post(`${goEpsApi}/api/v1/subscription/activate`, requestData);
	return response;
};

const getStatusSubscription = async (httpClient) => {
	const { goEpsApi } = window.envVariables;

	const response = await httpClient.get(`${goEpsApi}/api/v1/subscription`);
	return response.data;
};

const addEmployeeExtraSubscription = async (httpClient, quantityEmployees) => {
	const { goEpsApi } = window.envVariables;
	const requestData = {
		quantityEmployees
	};

	const response = await httpClient.put(`${goEpsApi}/api/v1/subscription/addextraemployee`, requestData);
	return response.data;
};

const updateCreditCardSubscription = async (httpClient, token) => {
	const { goEpsApi } = window.envVariables;
	const requestData = {
		token: token.id
	};

	const response = await httpClient.put(`${goEpsApi}/api/v1/subscription/updatecreditcard`, requestData);

	return response.data;
};

const cancelSubscription = async (httpClient) => {
	const { goEpsApi } = window.envVariables;
	const response = await httpClient.delete(`${goEpsApi}/api/v1/subscription`);
	return response.data;
};

const addCoupon = async (httpClient, coupon) => {
	const { goEpsApi } = window.envVariables;
	const response = await httpClient.post(`${goEpsApi}/api/v1/subscription/coupon`, coupon);
	return response;
};

const getCoupon = async (httpClient) => {
	const { goEpsApi } = window.envVariables;
	const response = await httpClient.get(`${goEpsApi}/api/v1/subscription/coupon`);
	return response;
};

export default {
	activateSubscription,
	getStatusSubscription,
	addEmployeeExtraSubscription,
	updateCreditCardSubscription,
	cancelSubscription,
	addCoupon,
	getCoupon
};
