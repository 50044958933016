import * as actionTypes from "../constants/actionTypes";

// Send Coupon
export function couponRequest(coupon) {
    return {
        type: actionTypes.ACTION_SEND_COUPON,
        payload: {
            coupon
        }
    }
}
export function couponRequestSuccess(messageCode, message, item) {
    return {
        type: actionTypes.ACTION_SEND_COUPON_SUCCESS,
        payload: {
            message,
            messageCode,
            item
        }
    }
}

export function couponRequestFailure(messageCode, errorMessage) {
    return {
        type: actionTypes.ACTION_SEND_COUPON_FAILURE,
        error: true,
        payload: {
            messageCode,
            errorMessage,
            message: errorMessage
        }

    }
}

// Get Coupon
export function getCoupon(coupon) {
    return {
        type: actionTypes.ACTION_GET_COUPON,
        payload: {
            coupon
        }
    }
}
export function getCouponSuccess(messageCode, message, item) {
    
    return {
        type: actionTypes.ACTION_GET_COUPON_SUCCESS,
        payload: {
            messageCode,
            item
        }
    }
}

export function getCouponFailure(messageCode, errorMessage) {
    return {
        type: actionTypes.ACTION_GET_COUPON_FAILURE,
        error: true,
        payload: {
            messageCode,
            errorMessage,
            message: errorMessage
        }

    }
}

// clean Message
export function cleanMessage() {
    return {
        type: actionTypes.ACTION_COUPON_CLEAN_MESSAGE,
        payload: {
            errorMessage: null,
            messageCode: null,
            message: null,
            error: null
        }
    };
}