import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import Moment from "react-moment";
import PropTypes from "prop-types";
import { isMobile } from "react-device-detect";

import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import {
	Button, Grid, Paper, Typography
} from "@material-ui/core";

import { SnackbarCommonComponent } from "../common";
import StripeCheckout from "react-stripe-checkout";
import * as routes from "../../constants/routes";
import * as common from "../../constants/common";
import * as images from "../../constants/imagesPublic";
import * as subscriptionActions from "../../actions/SubscriptionActions";

import { PlanStyle } from "./subscriptionStyle";
import { getUTCToLocal } from './../../helpers/dateextensions'

class SubscriptionComponent extends Component {
	constructor() {
		super();

		this.state = {
			cardNumber: '',
			cardName: '',
			foundCard: false
		};
	}
	componentDidMount() {
		const { getStatusSubscription, mixpanel } = this.props;
		getStatusSubscription();
		mixpanel.track("Subscription.Screen");
	}


	componentWillReceiveProps = (nextProps) => {
		const { statusSubscriptionData, employer, error, errorMessage } = nextProps;
		if (statusSubscriptionData.cardNumber && statusSubscriptionData.cardNumber != "**** **** **** ") {
			this.setState({
				cardNumber: statusSubscriptionData.cardNumber,
				cardName: statusSubscriptionData.cardName,
				foundCard: true,
			});
		} else if (employer && employer.subscription) {
			if (employer.subscription.status != 0 && employer.subscription.status != 4) {
				this.setState({
					cardNumber: "VISA xxxx xxxx xxxx 0123",
					cardName: "VISA",
					foundCard: false,
				});
			}
		}

		this.setState({error: error, errorMessage: errorMessage});
	};

	handleMessageClose = () => {
		this.setState({ error: false, errorMessage: "", success: false });
	};

	onToken = (token) => {
		const { updateCreditCardSubscription, history } = this.props;
		updateCreditCardSubscription(token, history);
	};

	render() {
		const { classes, statusSubscriptionData, employer } = this.props;
		const { stripe } = window.envVariables;

		const { error, errorMessage } = this.state;

		// console.clear()
		console.log("cardNumber", this.state.cardNumber);
		console.log("error", error, errorMessage);
		return (
			<div className={classes.root}>
				<Paper className={clsx(classes.paperContainer, { isMobile })}>
					<Typography variant="h1" gutterBottom>
						{"Subscription and Payment"}
					</Typography>
					<Typography variant="body1">Upgrade or change your subscription</Typography>
					<Paper className={classes.paperClear}>
						<Grid container spacing={2} alignItems="center">
							<Grid item xs={isMobile ? 12 : 3}>
								<Typography variant="h4">Active Plan</Typography>
							</Grid>
							<Grid item xs={isMobile ? 12 : 3}>
								<Typography variant="body1">
									{statusSubscriptionData.planName ? `${statusSubscriptionData.planName.replace("10", employer.subscription.quantityEmployees)}` : null}
									{statusSubscriptionData.statusName ? `${statusSubscriptionData.statusName !== "Active" ? ` (${statusSubscriptionData.statusName})` : ""}` : null}
								</Typography>
							</Grid>
							{!isMobile ? <Grid item xs={6} /> : null}

							{
								statusSubscriptionData.planName != "1 Employee Free Plan" &&
								<>
									<Grid item xs={isMobile ? 12 : 3}>
										<Typography variant="body1">Automatically renewed on</Typography>
									</Grid>
									<Grid item xs={isMobile ? 12 : 3}>
										<Typography variant="body1" className={clsx("title", { [classes.roundedContent]: true })}>
											<Moment utc locale="au" local={true} format="DD/MM/YYYY">{statusSubscriptionData.dateExpired}</Moment>
										</Typography>
									</Grid>
									{!isMobile ? <Grid item xs={6} /> : null}
								</>
							}

							<Grid item xs={isMobile ? 12 : 3}>
								<Typography variant="body1">You will be charged</Typography>
							</Grid>
							<Grid item xs={isMobile ? 12 : 3}>
								<Typography variant="body1" className={clsx("title", { [classes.roundedContent]: true })}>
									{statusSubscriptionData.planCost ? `$ ${(statusSubscriptionData.planCost / 100).toFixed(2)}` : "$ 0.00"}
								</Typography>
							</Grid>
							{!isMobile ? <Grid item xs={6} /> : null}

							{
								statusSubscriptionData.planName != "1 Employee Free Plan" &&
								<>
									<Grid item xs={isMobile ? 12 : 3}>
										<Typography variant="body1">Days remaining</Typography>
									</Grid>
									<Grid item xs={isMobile ? 12 : 3}>
										<Typography variant="body1" className={clsx("title", { [classes.roundedContent]: true })}>
											{statusSubscriptionData.status != 1 ? statusSubscriptionData.dayTrial : statusSubscriptionData.daySubscription}
										</Typography>
									</Grid>
									{!isMobile ? <Grid item xs={6} /> : null}
								</>
							}

							<Grid container alignContent="flex-end" justify="flex-end">

								<Button variant="contained" color="secondary"
									className={clsx(classes.gridRightClearButton, classes.orangeRectangularBtn)}
									component={Link} to={routes.PATH_SUBSCRIPTION_PLAN}>
									{"Change or cancel"}
								</Button>

							</Grid>
						</Grid>
					</Paper>
					{this.state.cardName && this.state.cardNumber ? (
						<Paper className={classes.paperClear}>
							<Grid container spacing={2} alignItems="center">
								<Grid item xs={isMobile ? 12 : 3}>
									<Typography variant="h4">Payment details</Typography>
								</Grid>
								{!isMobile ? <Grid item xs={9} /> : null}

								<Grid item xs={isMobile ? 12 : 3}>
									<Typography variant="body1">Next Payment Date</Typography>
									{/* <Typography variant="body1">No current card found</Typography> */}
								</Grid>
								<Grid item xs={isMobile ? 12 : 3}>
									<Typography variant="body1" className={clsx("title", { [classes.roundedContent]: true })}>
										{
											statusSubscriptionData.statusName !== "Active" ?
												"" : <Moment utc locale="au" local={true} format="DD/MM/YYYY">{statusSubscriptionData.dateExpired}</Moment>
										}
									</Typography>
								</Grid>
								{!isMobile ? <Grid item xs={6} /> : null}

								{
									this.state.foundCard &&
									<>
										<Grid item xs={isMobile ? 3 : 1}>
											<Typography variant="body1">
												<i className={`pf pf-${this.state.cardName.toLowerCase()}`} />
											</Typography>
										</Grid>
										<Grid item xs={isMobile ? 9 : 6}>
											<Typography variant="body1" className="title">
												{this.state.cardNumber}
											</Typography>
										</Grid>
										{!isMobile ? <Grid item xs={3} /> : null}
									</>
								}
								{
									!this.state.foundCard &&
									<Grid item xs={isMobile ? 12 : 3}>
										<Typography variant="body1">No Current Card Found</Typography>
										{/* <Typography variant="body1">No current card found</Typography> */}
									</Grid>
								}

								<Grid container alignContent="flex-end" justify="flex-end">
									<StripeCheckout token={this.onToken} stripeKey={stripe.publishableKey} name={common.SUBSCRIPTION_NAME} email={employer.login.email} image={images.LOGO_PUBLIC} locale="auto" panelLabel={common.SUBSCRIPTION_UPDATE_CARD} allowRememberMe={false}>
										<Button variant="contained" color="secondary"
											className={clsx(classes.gridRightClearButton, classes.orangeRectangularBtn)}
										>
											{"Update card details"}
										</Button>
									</StripeCheckout>
								</Grid>
							</Grid>
						</Paper>
					) : null}
				</Paper>
				{error ? <SnackbarCommonComponent onCloseModal={this.handleMessageClose} variant="error" open={error} body={errorMessage} /> : null}
			</div>
		);
	}
}

SubscriptionComponent.propTypes = {
	statusSubscriptionData: PropTypes.object,

	getStatusSubscription: PropTypes.func,
	updateCreditCardSubscription: PropTypes.func
};

const mapStateToProps = state => ({
	statusSubscriptionData: state.subscription.statusSubscriptionData,
	error: state.subscription.error,
	errorMessage: state.subscription.errorMessage,
	employer: state.session.userData
});

const mapDispatchToProps = dispatch => ({
	getStatusSubscription: bindActionCreators(subscriptionActions.getStatusSubscription, dispatch),
	updateCreditCardSubscription: bindActionCreators(subscriptionActions.updateCreditCardSubscription, dispatch)
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(withStyles(PlanStyle)(SubscriptionComponent)));
