import * as actionTypes from "../constants/actionTypes";

export function updateActiveFY(fyname) {
  return {
    type: actionTypes.ACTION_FY_UPDATE_ACTIVE,
    loading: true,
    error: false,
    success: false,
    payload: {
        fyname
    }
  };
}

export function updateActiveFYSuccess(message) {
  return {
    type: actionTypes.ACTION_FY_UPDATE_ACTIVE_SUCCESS,
    error: false,
    success: true,
    loading: false,
    message: message,
    payload: {
      message
    }
  };
}

export function updateActiveFYFailure(message) {
  return {
    type: actionTypes.ACTION_FY_UPDATE_ACTIVE_FAILURE,
    error: true,
    success: false,
    loading: false,
    message: message,
    payload: {
      message
    }
  };
}


export function getAvailableFYs(fyname) {
    return {
      type: actionTypes.ACTION_FY_GET_AVAILABLE,
      loading: true,
      error: false,
      success: false,
      payload: {
          fyname
      }
    };
  }

  export function getAvailableFYsSuccess(fydata) {
    return {
      type: actionTypes.ACTION_FY_GET_AVAILABLE_SUCCESS,
      error: false,
      success: true,
      loading: false,
      payload: {
        fydata
      }
    };
  }

  export function getAvailableFYsFailure(message) {
    return {
      type: actionTypes.ACTION_FY_GET_AVAILABLE_FAILURE,
      error: true,
      success: false,
      loading: false,
      message: message,
      payload: {
        message
      }
    };
  }
