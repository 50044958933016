import initialState from "../../store/initialState";

export const accountantAdditionalDefaultData = {
  email: ""
};

const accountantInitialState = {
  ...initialState,
  accountants: [],
  accountantsData: {}
};

export default accountantInitialState;
