import React from "react";
import { withStyles } from "@material-ui/core/styles";

import { Grid, Switch, Typography } from "@material-ui/core";

import { SwitchStyles } from "./commonStyles";

const SwitchCommonComponent = ({ classes, title, ...props }) => (
	<Typography component="div" className={classes.container}>
		<Grid component="label" container alignItems="center" spacing={1}>
			<Grid item xs={9} className={classes.containerLeft}>
				{title}
			</Grid>
			<Grid item xs={3} className={classes.containerRight}>
				<Switch
					focusVisibleClassName={classes.focusVisible}
					disableRipple
					classes={{
						root: classes.root,
						switchBase: classes.switchBase,
						thumb: classes.thumb,
						track: classes.track,
						checked: classes.checked,
						input: classes.input
					}}
					{...props}
				/>
			</Grid>
		</Grid>
	</Typography>
);

export default withStyles(SwitchStyles)(SwitchCommonComponent);
