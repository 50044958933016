import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import {
	FormHelperText, Paper, Grid, TextField, Icon
} from "@material-ui/core";

import { TwoFaInputStyles } from "./commonStyles";

import lockImage from "../../assets/images/lock.png";

class Input2FACommonComponent extends Component {
	constructor() {
		super();
		this.componenetList = {};
		this.state = {
			value2FA: [],
			showIconVerification: false,
			submitForm: false
		};
	}

	handleChangeValue = nextField => (event) => {
		const { formProps } = this.props;

		const { value2FA: value2FAArray } = this.state;
		value2FAArray[event.target.id.replace("ElementCodeNumber", "") - 1] = event.target.value;

		this.setState({
			value2FA: value2FAArray,
			showIconVerification: false,
			submitForm: event.target.id === "ElementCodeNumber6"
		});

		if (this.componenetList[nextField] && event.target.value && event.target.value.length === 1) {
			this.componenetList[nextField].focus();
			document.getElementById(`${nextField}`).focus();
			document.getElementById(`${nextField}`).select();
		}

		formProps.setFieldValue(event.target.id, event.target.value);
	};

	isValidCode = () => {
		const { submitOnChange, formProps } = this.props;
		const { value2FA, showIconVerification, submitForm } = this.state;
		const code = value2FA.toString().replace(/,/g, "");
		const isValid = code.length === 6;

		if (isValid && !showIconVerification && submitForm && submitOnChange) {
			this.setState({
				showIconVerification: true
			});

			const { formik } = this.props;

			formik.executeSubmit();
		}

		if (isValid && !showIconVerification) {
			this.setState({
				showIconVerification: true
			});
		}

		return isValid && formProps.isValid;
	};

	render() {
		const {
			classes, formProps, iconInTop, error, errorMessage
		} = this.props;
		const { showIconVerification } = this.state;

		return (
			<Paper className={clsx(classes.paperGrid, { valid: this.isValidCode() })}>
				<Grid container spacing={4} alignItems="center" justify="center" className={classes.containerControls}>
					{iconInTop ? (
						<Grid item xs={12}>
							{showIconVerification ? (
								<Icon
									className={clsx(classes.iconInTop, {
										error
									})}
								>
									{!error ? "check_circle" : "cancel"}
								</Icon>
							) : (
								<img className={classes.iconInTop} src={lockImage} alt="" />
							)}
						</Grid>
					) : null}

					<Grid item xs={12} className={clsx("", { [classes.containerInputs]: !iconInTop })}>
						<TextField
							id="ElementCodeNumber1"
							ref={(c) => {
								this.componenetList.ElementCodeNumber1 = c;
							}}
							className={clsx(classes.textField, {
								[classes.textOneCharacter]: true,
								[classes.errorField]: !!formProps.errors.ElementCodeNumber1
							})}
							InputProps={{
								inputProps: {
									maxLength: "1"
								}
							}}
							maxLength="1"
							variant="filled"
							autoFocus
							error={!!formProps.errors.ElementCodeNumber1}
							onChange={this.handleChangeValue("ElementCodeNumber2")}
							value={formProps.values.ElementCodeNumber1}
							onBlur={formProps.handleBlur}
						/>
						<TextField
							id="ElementCodeNumber2"
							ref={(c) => {
								this.componenetList.ElementCodeNumber2 = c;
							}}
							className={clsx(classes.textField, {
								[classes.textOneCharacter]: true,
								[classes.errorField]: !!formProps.errors.ElementCodeNumber2
							})}
							InputProps={{
								inputProps: {
									maxLength: "1"
								}
							}}
							maxLength="1"
							variant="filled"
							error={!!formProps.errors.ElementCodeNumber2}
							onChange={this.handleChangeValue("ElementCodeNumber3")}
							value={formProps.values.ElementCodeNumber2}
							onBlur={formProps.handleBlur}
						/>
						<TextField
							id="ElementCodeNumber3"
							ref={(c) => {
								this.componenetList.ElementCodeNumber3 = c;
							}}
							className={clsx(classes.textField, {
								[classes.textOneCharacter]: true,
								[classes.errorField]: !!formProps.errors.ElementCodeNumber3
							})}
							InputProps={{
								inputProps: {
									maxLength: "1"
								}
							}}
							maxLength="1"
							variant="filled"
							error={!!formProps.errors.ElementCodeNumber3}
							onChange={this.handleChangeValue("ElementCodeNumber4")}
							value={formProps.values.ElementCodeNumber3}
							onBlur={formProps.handleBlur}
						/>
						<TextField
							id="ElementCodeNumber4"
							ref={(c) => {
								this.componenetList.ElementCodeNumber4 = c;
							}}
							className={clsx(classes.textField, {
								[classes.textOneCharacter]: true,
								[classes.errorField]: !!formProps.errors.ElementCodeNumber4
							})}
							InputProps={{
								inputProps: {
									maxLength: "1"
								}
							}}
							maxLength="1"
							variant="filled"
							error={!!formProps.errors.ElementCodeNumber4}
							onChange={this.handleChangeValue("ElementCodeNumber5")}
							value={formProps.values.ElementCodeNumber4}
							onBlur={formProps.handleBlur}
						/>
						<TextField
							id="ElementCodeNumber5"
							ref={(c) => {
								this.componenetList.ElementCodeNumber5 = c;
							}}
							className={clsx(classes.textField, {
								[classes.textOneCharacter]: true,
								[classes.errorField]: !!formProps.errors.ElementCodeNumber5
							})}
							InputProps={{
								inputProps: {
									maxLength: "1"
								}
							}}
							maxLength="1"
							variant="filled"
							error={!!formProps.errors.ElementCodeNumber5}
							onChange={this.handleChangeValue("ElementCodeNumber6")}
							value={formProps.values.ElementCodeNumber5}
							onBlur={formProps.handleBlur}
						/>
						<TextField
							id="ElementCodeNumber6"
							ref={(c) => {
								this.componenetList.ElementCodeNumber6 = c;
							}}
							className={clsx(classes.textField, {
								[classes.textOneCharacter]: true,
								[classes.errorField]: !!formProps.errors.ElementCodeNumber6
							})}
							InputProps={{
								inputProps: {
									maxLength: "1"
								}
							}}
							maxLength="1"
							variant="filled"
							error={!!formProps.errors.ElementCodeNumber6}
							onChange={this.handleChangeValue("ElementCodeNumber1")}
							value={formProps.values.ElementCodeNumber6}
							onBlur={formProps.handleBlur}
						/>
						{!iconInTop ? <Icon className={clsx(classes.checkIcon, { active: this.isValidCode(), [classes.errorRequest]: showIconVerification && !this.isValidCode() })}>{this.isValidCode() ? "check_circle" : "cancel"}</Icon> : null}
					</Grid>
					<Grid container item xs={12}>
						{error && <FormHelperText error>{errorMessage}</FormHelperText>}
					</Grid>
				</Grid>
			</Paper>
		);
	}
}

Input2FACommonComponent.propTypes = {
	classes: PropTypes.object.isRequired,
	formProps: PropTypes.object.isRequired,
	iconInTop: PropTypes.bool.isRequired,
	error: PropTypes.bool.isRequired
};

export default withStyles(TwoFaInputStyles)(Input2FACommonComponent);
