import * as actionTypes from "../constants/actionTypes";

export function getAllSTPRequest() {
	return {
		type: actionTypes.ACTION_STP_GET_REQUEST,
		payload: {
			isLoading: true
		}
	};
}

export function getAllSTPRequestSuccess(
	STPList,
	pendingList,
	lodgedList,
	invalidList,
	completedList,
	messageCode,
	message
) {
	return {
		type: actionTypes.ACTION_STP_GET_REQUEST_SUCCESS,
		payload: {
			STPList,
			pendingList,
			lodgedList,
			invalidList,
			completedList,
			messageCode,
			message
		}
	};
}

export function getAllSTPRequestFailure(messageCode, errorMessage) {
	return {
		type: actionTypes.ACTION_STP_GET_REQUEST_FAILURE,
		error: true,
		payload: {
			messageCode,
			errorMessage
		}
	};
}


export function updateSTPRequest(STPData) {
	return {
		type: actionTypes.ACTION_STP_UPDATE_REQUEST,
		payload: {
			isLoading: true,
			STPData
		}
	};
}

export function updateSTPRequestSuccess(
	STPData,
	messageCode,
	message
) {
	return {
		type: actionTypes.ACTION_STP_UPDATE_REQUEST_SUCCESS,
		payload: {
			STPData,
			messageCode,
			message
		}
	};
}

export function updateSTPRequestFailure(messageCode, errorMessage) {
	return {
		type: actionTypes.ACTION_STP_UPDATE_REQUEST_FAILURE,
		error: true,
		payload: {
			messageCode,
			errorMessage
		}
	};
}

export function clearSTPData(STPData) {
	return {
		type: actionTypes.ACTION_STP_CLEAN_DATA,
		payload: {
			STPData
		}
	};
}

export function redirectToSTP(showbaner){
	return{
		type:actionTypes.ACTION_REDIRECT_TO_STP,
		payload:{
			redirectToSTP:showbaner
		}
	}
}
