import * as actionTypes from "../../constants/actionTypes";
import yecpInitialize from "./YECPInitialize";

const yecpReducer = (state = yecpInitialize, action) => {
  switch (action.type) {
    // -------------------- GET ALL YECP ----------------------------
    case actionTypes.ACTION_GET_ALL_YECP_REQUEST:
      return {
        ...state,
        isLoading: true,
        messageCode: "",
        message: ""
      };
    case actionTypes.ACTION_GET_ALL_YECP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        yecpList: action.payload.yecpList.list,
        message: action.payload.message,
        messageCode: action.payload.messageCode
      };
    case actionTypes.ACTION_GET_ALL_YECP_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
        messageCode: action.payload.messageCode,
        message: action.payload.message
      };
    // -------------------- SEND CLOSE FY ---------------------
    case actionTypes.ACTION_EMPTY_CLOSE_FY:
      return {
        ...state,
        closeFY: {}
      };
    case actionTypes.ACTION_SEND_CLOSE_FY_REQUEST:
      return {
        ...state,
        isLoading: true,
        messageCode: "",
        message: ""
      };
    case actionTypes.ACTION_SEND_CLOSE_FY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        closeFY: action.payload.closeFY,
        message: action.payload.message,
        messageCode: action.payload.messageCode,
        yecpName: action.payload.yecp.name
      };
    case actionTypes.ACTION_SEND_CLOSE_FY_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
        message: action.payload.message,
        messageCode: action.payload.messageCode
      };

    // -------------------- GET AUTHORIZED NAME SIGNATURE ----------------------
    case actionTypes.ACTION_GET_AUTHORIZEDNAME_SIGNATURE_REQUEST:
      return {
        ...state,
        isLoading: true,
        messageCode: "",
        message: ""
      };
    case actionTypes.ACTION_GET_AUTHORIZEDNAME_SIGNATURE_SUCCESS:
      return {
        ...state,
        signature: action.payload.signature,
        isLoading: false,
        messageCode: action.payload.messageCode,
        message: action.payload.message
      };
    case actionTypes.ACTION_GET_AUTHORIZEDNAME_SIGNATURE_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload.message,
        messageCode: action.payload.messageCode
      };
    // --------------------- SEND CLOSE FY SIGNATURE --------------------------
    case actionTypes.ACTION_SEND_CLOSE_FY_SIGNATURE_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: "",
        messageCode: ""
      };
    case actionTypes.ACTION_SEND_CLOSE_FY_SIGNATURE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        message: action.payload.message,
        messageCode: action.payload.messageCode
      };
    case actionTypes.ACTION_SEND_CLOSE_FY_SIGNATURE_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload.message,
        messageCode: action.payload.messageCode
      };
    case actionTypes.ACTION_REDIRECT_TO_STP_COMPELETED:
      return {
        ...state,
        redirectToSTPCompleted: action.payload.redirect
      };

    // -------------------- PUT EMPLOYEES FOR CLOSE FY -----------------
    case actionTypes.ACTION_PUT_EMPLOYEES_FOR_CLOSE_FY_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: "",
        messageCode: ""
      };
    case actionTypes.ACTION_PUT_EMPLOYEES_FOR_CLOSE_FY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        message: action.payload.messageCode,
        messageCode: action.payload.message,
        data: action.payload.data,
        employeeListForEdit: action.payload.employeeList
      };
    case actionTypes.ACTION_PUT_EMPLOYEES_FOR_CLOSE_FY_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload.message,
        messageCode: action.payload.messageCode,
        error: true
      };
    // -------------------- EDIT EMPLOYEES PAYROLL FOR CLOSE FY -----------------
    case actionTypes.ACTION_EDIT_EMPLOYEE_PAYROLL_FOR_CLOSE_FY_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: "",
        message2: "", //test
        messageCode: ""
      };
    case actionTypes.ACTION_EDIT_EMPLOYEE_PAYROLL_FOR_CLOSE_FY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        messageCode: action.payload.messageCode,
        // message: action.payload.message,
        message2: action.payload.message, //test
        data: action.payload.data,
        employeeListForEdit: action.payload.formProps
      };
    case actionTypes.ACTION_EDIT_EMPLOYEE_PAYROLL_FOR_CLOSE_FY_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload.message,
        messageCode: action.payload.messageCode,
        error: true
      };
    // -------------------- SEND CLOSE FY AFTER EIDT EMPLOYEES ----------------------

    case actionTypes.ACTION_SEND_CLOSE_FY_AFTER_EDIT_EMPLOYEES_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: "",
        messageCode: "",
        message2: ""
      };
    case actionTypes.ACTION_SEND_CLOSE_FY_AFTER_EDIT_EMPLOYEES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        message: action.payload.message,
        messageCode: action.payload.messageCode,
        closeFY: action.payload.data
      };
    case actionTypes.ACTION_SEND_CLOSE_FY_AFTER_EDIT_EMPLOYEES_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload.message,
        messageCode: action.payload.messageCode,
        error: true
      };

    default:
      return state;
  }
};

export default yecpReducer;
