import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter, Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import {
	Box, Button, TextField, Grid, Link, FormControl, Typography
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Formik } from "formik";
import clsx from "clsx";
import mixpanel from "mixpanel-browser";
import * as Yup from "yup";

import { DEEP_LINK } from "../../constants/common";
import * as routes from "../../constants/routes";
import * as externalUsers from "../../actions/ExternalUserActions";
import * as OnboardActions from "../../actions/OnboardActions";
import slideImgTwo from "../../assets/images/slideImgTwo@2x.png";
import slideImgOne from "../../assets/images/slideImgOne.svg";
import slideImgTree from "../../assets/images/slideImgTree@2x.svg";
import slideImgFour from "../../assets/images/slideImgFour@2x.svg";
import slideImgFive from "../../assets/images/slideImgFive@2x.svg";
import slideImgSix from "../../assets/images/slideImgSix@2x.png";
import LogoEasyPayslip from "../../assets/images/LOGOEasyPayslipHorizontal.svg";
import StarRating from "../../assets/images/star_rating.png";
import GoogleLogo from "../../assets/images/google_logo.png";
import AppleLogo from "../../assets/images/apple_logo.png";
import { ExternalUserStyle } from "./sessionStyle";
import { DeepOpen } from "../../scripts";
import * as settingsFiles from "../../constants/settingsFiles";
import { Analytics } from "../common";


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

class SignUpComponent extends Component {
	state = {
		currentIndex: 0,
		showBottomBanner: false
	};
	validationSchema = Yup.object({
		email: Yup.string()
			.email("Email is invalid")
			.required("Email is required"),
		password: Yup.string().required("Password is required"),
		confirmPassword: Yup.string()
			.oneOf([Yup.ref("password"), null], "Passwords must match")
			.required("Confirm password is required")
	});

	componentWillMount() {
		DeepOpen(DEEP_LINK);
		console.log('resetting mixpanel');

		// const { mixpanel } = this.props;
		mixpanel.reset();
		Analytics.OnboardActionWC_1();
		setTimeout(() => {
			// console.log('setting property');
			mixpanel.people.set({'$Signup_Platform': "web"});
			// mixpanel.alias('at-test');
		}, 1000);
	}

	handleSubmitOnboard = (values, actions) => {
		const { onboardingRequest, history } = this.props;
		let onboard = {
			accountType: "employer",
		}
		mixpanel.track('OB_0Start.Action', {});
		onboardingRequest(onboard, history);
	};

	handleSubmitOnboardAccountant = (values, actions) => {
		const { onboardingRequest, history } = this.props;
		let onboard = {
			accountType: "accountant",
		}
		onboardingRequest(onboard, history);
	};

	afterChange = (currentIndex) => {
		switch (currentIndex) {
			case 0:
				Analytics.OnboardActionWC_1();
				break;
			case 1:
				Analytics.OnboardActionWC_2();
				break;
			case 2:
				Analytics.OnboardActionWC_3();
				break;
			case 3:
				Analytics.OnboardActionWC_4();
				break;
			case 4:
				Analytics.OnboardActionWC_5();
				break;
			case 5:
				Analytics.OnboardActionWC_6();
				break;
		}
		this.setState({ currentIndex: currentIndex })
	};

	beforeChange = (oldIndex, newIndex) => {
		setTimeout(() => {
			this.setState({ showBottomBanner: newIndex == 5 });
		}, 10);
	}

	render() {
		const { classes, error, errorMessage, onboard, onboardSessionid } = this.props;

		var settings = {
			dots: true,
			infinite: true,
			speed: 250,
			autoplaySpeed: 4000,
			slidesToShow: 1,
			slidesToScroll: 1,
			afterChange: (currentIndex) => this.afterChange(currentIndex),
			beforeChange: (oldIndex, newIndex) => this.beforeChange(oldIndex, newIndex),
			autoplay: true,
			fade: true,
		};

		return (
			<Grid container justify="center" className={classes.signupRoot}>
				<Grid className={classes.signupRoot} container alignItems="flex-start">
					<Grid container className={classes.rootContainer} style={{ height: "auto", padding: "50px", paddingRight: 0 }}>
						<Grid className={classes.logoEasyPayslip} item xs={12} >
							<img src={LogoEasyPayslip} alt="" />
						</Grid>
						<Grid xs={12}>
							<div className={classes.slideShow}>
								<Slider {...settings}>

									{/* slide ttem one */}
									<div>
										<div className={classes.slideShowItem}>
											<div>
												<div className={classes.titleWrapper}>
													<h1 className={classes.title} style={{ width: '283px' }}>
														Payslips & Single Touch Payroll <br />in 5 mins
                                               </h1>
													<label className={classes.subTitleOne} style={{ maxWidth: "initial", fontFamily: "AvenirNextLTPro-Regular", }}>
														Quick, Secure & Simple Setup
									        	</label>
												</div>
											</div>
											<div>
												<img src={slideImgOne} alt="" className={classes.rigthColumnImgSignUp} />
											</div>
										</div>
									</div>

									{/* slide ttem two */}
									<div>
										<div className={classes.slideShowItem}>
											<div>
												<div className={classes.titleWrapper}>
													<h1 className={classes.title}>
														ATO

														Approved
                                               </h1>
													<label className={classes.subTitleOne} style={{ maxWidth: "initial", fontFamily: "AvenirNextLTPro-Regular", marginBottom: 30 }}>
														Single Touch Payroll (STP)
													<br />
														at the touch of a button
									        	</label>
												</div>
											</div>
											<div>
												<img src={slideImgTwo} alt="" className={classes.rigthColumnImgSignUp} />
											</div>
										</div>
									</div>

									{/* slide ttem tree */}
									<div>
										<div className={classes.slideShowItem}>
											<div>
												<div className={classes.titleWrapper}>
													<h1 className={classes.title} style={{ width: '190px' }}>
														Easily Add Employees
                                               </h1>
													<label className={classes.subTitleOne} style={{ maxWidth: "initial", fontFamily: "AvenirNextLTPro-Regular", marginBottom: 30 }}>
														Track Leave, Super
												     <br />
														& Other Entitlements
									        	</label>
												</div>
											</div>
											<div>
												<img src={slideImgTree} alt="" className={classes.rigthColumnImgSignUp} />
											</div>
										</div>
									</div>

									{/* slide ttem four */}
									<div>
										<div className={classes.slideShowItem}>
											<div>
												<div className={classes.titleWrapper}>
													<h1 className={classes.title}>
														Create & Email Payslips
                                               </h1>
													<label className={classes.subTitleOne} style={{ maxWidth: "initial", fontFamily: "AvenirNextLTPro-Regular", marginBottom: 30 }}>
														Hourly, Salary, Overtime, per
													<br />
														Job/Unit, Allowances, Bonuses,
													 <br />
														Commissions & Deductions
									        	</label>
												</div>
											</div>
											<div>
												<img src={slideImgFour} alt="" className={classes.rigthColumnImgSignUp} />
											</div>
										</div>
									</div>

									{/* slide ttem five */}
									<div>
										<div className={classes.slideShowItem}>
											<div>
												<div className={classes.titleWrapper}>
													<h1 className={classes.title}>
														Simple

														Reporting
                                               </h1>
													<label className={classes.subTitleOne} style={{ maxWidth: "initial", fontFamily: "AvenirNextLTPro-Regular", marginBottom: 30 }}>
														Customised Summary
												<br />
														& Detail Reporting
									        	</label>
												</div>
											</div>
											<div>
												<img src={slideImgFive} alt="" className={classes.rigthColumnImgSignUp} />
											</div>
										</div>
									</div>

									{/* slide ttem six */}
									<div>
										<div className={classes.slideShowItem}>
											<div>
												<div className={classes.titleWrapper}>
													<h1 className={classes.title} style={{ width: '293px' }}>
														Great Local Customer Service
                                               </h1>
													<label className={classes.subTitleOne}>
														“Very easy to use on App
														<br />
														or PC for my payslips &
														<br />
														STP files, plus awesome
														<br />
														phone support, well done!”
									        	</label>
													<label className={classes.subTitleTwo}>
														- C. Wegner, Adelaide SA.
												</label>
												</div>
											</div>
											<div className={classes.rateWrapper}>
												<label className={classes.rate}>
													Rating 4.6
												</label>
												<img src={slideImgSix} alt="" className={classes.rigthColumnImgSignUp} />
											</div>
										</div>
									</div>

								</Slider>
								<Grid className={classes.container}>
									<Grid item xl={6} lg={6} md={6} sm={6} >
										<FormControl fullWidth margin="normal">
											<Box m={0.1} />
											<Button variant="contained" color="primary" className={classes.signupBtn} onClick={this.handleSubmitOnboard} type="submit">
												Start Free Trial
														</Button>
										</FormControl>
										<Typography variant="textFullAccessSignUp" className={classes.textFullAccessSignUp}>
											Full access to all features
						                     </Typography>
										<FormControl fullWidth style={{ marginTop: 20 }}>
											<Button color="primary" className={classes.signupBtn1, classes.accountantsSignup} onClick={this.handleSubmitOnboardAccountant} type="submit">
												Accountants sign up here
											</Button>
											{/* <Link component={RouterLink} to={routes.PATH_LOGIN}
												onClick={this.handleSubmitOnboardAccountant}
												className={clsx(classes.primaryColor, classes.accountantsSignup, "pointer", { primaryColor: true })} underline="none" variant="inherit">
												{"Accountants sign up here"}
											</Link> */}
											<Typography variant="textFullAccessSignUp" style={{ marginTop: 10 }}>
												<strong>JobKeeper</strong> information is provided for <strong>employers.</strong>
												<br />
												Employees please talk to your employer for more information.
											</Typography>
										</FormControl>
									</Grid>
									<Grid container fullWidth style={{ alignItems: "flex-end" }}>
										<Grid item xl={6} lg={6} md={6} sm={6} style={{ paddingBottom: "5px" }} >
											<Typography variant="body1" className={clsx("small")} style={{ color: "#000" }}>
												{`By continuing you accept our `}
												<Link target="_blank" href={settingsFiles.SETTINGS_TERMS_OF_USE_lINK.url} className={clsx("pointer", { small: true, primaryColor: true })} underline="none" variant="inherit">
													{settingsFiles.SETTINGS_TERMS_OF_USE_lINK.name}
												</Link>
												{` and `}
												<Link target="_blank" href={settingsFiles.SETTINGS_PRIVACY_POLICY_lINK.url} className={clsx("pointer", { small: true, primaryColor: true })} underline="none" variant="inherit">
													{settingsFiles.SETTINGS_PRIVACY_POLICY_lINK.name}
												</Link>
											</Typography>
										</Grid>
										<Grid item xl={6} lg={6} md={6} sm={6} style={{ display: "flex", flexDirection: "column", paddingLeft: "28px" }} >
											<FormControl fullwidth style={{ flexDirection: "row", marginTop: "5px", height: "60px" }}>
												<Grid className={classes.AppleLogoWrapperShow}>
													<img src={AppleLogo} style={{ width: "45px", height: "52px", marginRight: "10px" }} />
													<img src={GoogleLogo} style={{ width: "44px", height: "45px", marginTop: "8px" }} />
												</Grid>
											</FormControl>
											<Grid>
												<FormControl fullwidth>
													<Typography variant="subtitleSignUp" className={classes.subtitleSignUp}>
														21,455 small business users
								                        </Typography>
												</FormControl>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xl={6} lg={6} md={6} sm={6} >
										<Link component={RouterLink} to={routes.PATH_LOGIN} className={clsx(classes.SignIn, "pointer", { primaryColor: true })} underline="none" variant="inherit">
											{"Sign In"}
										</Link>
									</Grid>
								</Grid>
							</div>
						</Grid>
					</Grid>
				</Grid>
			</Grid >
		);
	}
}

SignUpComponent.propTypes = {
	history: PropTypes.object,
	classes: PropTypes.object,
	onboard: PropTypes.object,
	error: PropTypes.bool,
	errorMessage: PropTypes.string,
	onboardSessionid: PropTypes.string,
	signUp: PropTypes.func,
	onboardingRequest: PropTypes.func,
};

const mapStateToProps = state => ({
	error: state.external.error,
	errorMessage: state.external.errorMessage,
	onboard: state.onboard,
	message: state.onboard.message,
	onboardSessionid: state.onboard.sessionToken,
});

const mapDispatchToProps = dispatch => ({
	signUp: bindActionCreators(externalUsers.userSignUp, dispatch),
	onboardingRequest: bindActionCreators(OnboardActions.InitialiseAnOnboardingRequest, dispatch),
});

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(withStyles(ExternalUserStyle)(SignUpComponent))
);
