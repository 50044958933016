import React, { Component } from "react";
import { connect } from "react-redux";
import clsx from "clsx";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
	Button, Grid, Paper, TextField, FormHelperText
} from "@material-ui/core";
import { Formik, Form, getIn } from "formik";
import * as Yup from "yup";

import EmployeeStyles from "./employeeStyle";

import {
	CardCommonComponent, SwitchCommonComponent
} from "../common";

import * as parameterTypes from "../../constants/parameterTypes";
import { validateTfn } from "../../helpers/extensions";


class EmployeeTaxesComponent extends Component {
	validationSchema = Yup.object({
		taxe: Yup.object().shape({
			tfn: Yup.string()
				.required("tfn is required")
				.test("tfn-validation", "Use default TFN if unknown 000 000 000", validateTfn),
			taxResidency: Yup.string(),
			taxBand: Yup.string(),
			stsl: Yup.bool(),
		}),
	});

	constructor(props) {
		super(props);
		this.state = { isComplete: false };
	}

	handleSubmitSaveTaxes = async (values, actions) => {
		const {
			saveEmployeeData, employerId, employeeId, parentFormProps
		} = this.props;
		actions.setSubmitting(false);
		// console.log("PARENT VALUES: ", parentFormProps.values);
		await saveEmployeeData(employerId, employeeId, { ...parentFormProps.values });
		this.setState(prevState => ({ ...prevState, isComplete: true }));
	}

	renderOptions = (dataList, classes) => (
		dataList
			.sort((firstEl, secondEl) => firstEl.order < secondEl.order)
			.map(item => <option key={`${item.id}`} value={`${item.value}`} className={classes.option}>{item.value}</option>)
	);

	renderOptionsTaxBand = (dataList, classes, filterValue) => {
		const filteredData = dataList
			.sort((firstEl, secondEl) => firstEl.order < secondEl.order)
			.filter(item => item.filter === filterValue);

		return (
			filteredData.map(item => <option key={`${item.id}`} value={`${item.value}`} className={classes.option}>{item.value}</option>)
		);
	}

	handleOnChange = (event, fromProps, parentFormProps) => {
		fromProps.setFieldValue(event.target.id, event.target.value);
		parentFormProps.setFieldValue(event.target.id, event.target.value);
		// console.log("PARENT VALUES: ", parentFormProps.values);
	}

	checkIsComplete = (formProps) => {
		let finalResult = false;
		try {
			finalResult = this.validationSchema.isValidSync(formProps.values);
		} catch (err) {
			// console.error("Error validating taxes as complete: ", err);
		}
		return finalResult;
	}

	renderForm = (formProps) => {
		const {
			classes, employeeTaxResidencyList, employeeTaxBandList, parentFormProps
		} = this.props;
		const { isComplete } = this.state;
		const isFormComplete = this.checkIsComplete(formProps);

		return (
			<CardCommonComponent title="Taxes" complete={isFormComplete || isComplete} collapseExtend>
				<Form>
					<Grid container direction="row" alignItems="flex-start">
						<Grid item xs={4}>
							<Paper className={classes.paperGrid}>
								<TextField
									id="taxe.tfn"
									label="Tax file number *"
									variant="filled"
									type="number"
									value={formProps.values.taxe.tfn}
									onChange={event => this.handleOnChange(event, formProps, parentFormProps)}
									onBlur={formProps.handleBlur}
									className={clsx(classes.textField, "phNumber", {
										error: (getIn(formProps.errors, "taxe.tfn") && getIn(formProps.touched, "taxe.tfn"))
									})}
									fullWidth
								/>
								{(getIn(formProps.errors, "taxe.tfn") && getIn(formProps.touched, "taxe.tfn") && <FormHelperText error>{formProps.errors.taxe.tfn}</FormHelperText>)}
							</Paper>
						</Grid>
						<Grid item xs={4}>
							<Paper className={classes.paperGrid}>
								<TextField
									id="taxe.taxResidency"
									label="Tax residency"
									variant="filled"
									value={formProps.values.taxe.taxResidency}
									onChange={event => this.handleOnChange(event, formProps, parentFormProps)}
									onBlur={formProps.handleBlur}
									className={clsx(classes.textField, {
										error: (getIn(formProps.errors, "taxe.taxResidency") && getIn(formProps.touched, "taxe.taxResidency"))
									})}
									SelectProps={{
										native: true,
										MenuProps: {
											className: classes.menu
										}
									}}
									select
									fullWidth
								>
									{this.renderOptions(employeeTaxResidencyList, classes)}
								</TextField>
								{(getIn(formProps.errors, "taxe.taxResidency") && getIn(formProps.touched, "taxe.taxResidency") && <FormHelperText error>{formProps.errors.taxe.taxResidency}</FormHelperText>)}
							</Paper>
						</Grid>
						<Grid item xs={4}>
							<Paper className={clsx(classes.paperGrid, { [classes.paperGridClearRight]: true })}>
								<TextField
									id="taxe.taxBand"
									label="Tax band"
									variant="filled"
									value={formProps.values.taxe.taxBand}
									onChange={event => this.handleOnChange(event, formProps, parentFormProps)}
									onBlur={formProps.handleBlur}
									className={clsx(classes.textField, {
										error: (getIn(formProps.errors, "taxe.taxBand") && getIn(formProps.touched, "taxe.taxBand"))
									})}
									SelectProps={{
										native: true,
										MenuProps: {
											className: classes.menu
										}
									}}
									select
									fullWidth
								>
									{this.renderOptionsTaxBand(employeeTaxBandList, classes, formProps.values.taxe.taxResidency)}
								</TextField>
								{(getIn(formProps.errors, "taxe.taxBand") && getIn(formProps.touched, "taxe.taxBand") && <FormHelperText error>{formProps.errors.taxe.taxBand}</FormHelperText>)}
							</Paper>
						</Grid>
						<Grid item xs={4}>
							<Paper className={classes.paperGrid}>
								<SwitchCommonComponent
									id="taxe.stsl"
									title="Study &amp; training support loans"
									checked={formProps.values.taxe.stsl}
									onChange={(event, checked) => this.handleOnChange({ ...event, target: { id: "taxe.stsl", value: checked } }, formProps, parentFormProps)}
									onBlur={formProps.handleBlur}
								/>
							</Paper>
						</Grid>
						<Grid item xs={12} className={classes.gridRight}>
							<Button
								variant="outlined"
								color="secondary"
								// onClick={parentFormProps.handleSubmit}
								onClick={formProps.handleSubmit}
								className={classes.buttonSaveDetails}
							>
								{"Save Taxes"}
							</Button>
						</Grid>
					</Grid>
				</Form>
			</CardCommonComponent>
		);
	}

	render() {
		const { employeeData } = this.props;

		return (
			<Formik
				initialValues={{ ...employeeData }}
				validationSchema={this.validationSchema}
				onSubmit={this.handleSubmitSaveTaxes}
				render={this.renderForm}
				enableReinitialize="true"
			/>
		);
	}
}

EmployeeTaxesComponent.propTypes = {
	employeeData: PropTypes.object.isRequired,
	employeeTaxResidencyList: PropTypes.array,
	employeeTaxBandList: PropTypes.array,
	parentFormProps: PropTypes.object.isRequired,

	saveEmployeeData: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => ({
	employeeData: ownProps.parentFormProps.values,
	employeeTaxResidencyList: state.parameters.parameterByTypeData[parameterTypes.PARAMETER_EMPLOYEE_TAX_RESIDENCY.parameterType],
	employeeTaxBandList: state.parameters.parameterByTypeData[parameterTypes.PARAMETER_EMPLOYEE_TAX_BAND.parameterType],
});

export default connect(mapStateToProps, null)(withStyles(EmployeeStyles)(EmployeeTaxesComponent));
