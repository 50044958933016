import * as actionTypes from "../../constants/actionTypes";
import sessionInitialState from "./SessionInitialState";

const externalUserReducer = (state = sessionInitialState, action) => {
	switch (action.type) {
		case actionTypes.ACTION_SESSION_USER_SIGN_UP: {
			return {
				...state,
				isLoading: true,
				email: action.payload.email
			};
		}
		case actionTypes.ACTION_SESSION_USER_SIGN_UP_SUCCESS: {
			return {
				...state,
				isLoading: false,
				userData: action.payload.userData,
				token: action.payload.token,
				enable2FA: action.payload.enable2FA,
				messageCode: action.payload.messageCode,
				message: action.payload.message
			};
		}
		case actionTypes.ACTION_SESSION_USER_SIGN_UP_FAILURE: {
			return {
				...state,
				isLoading: false,
				error: true,
				errorMessage: action.payload.errorMessage
			};
		}
		case actionTypes.ACTION_SESSION_USER_FORGOT_PASSWORD: {
			return {
				...state,
				isLoading: true,
				email: action.payload.email
			};
		}
		case actionTypes.ACTION_SESSION_USER_FORGOT_PASSWORD_SUCCESS: {
			return {
				...state,
				isLoading: false,
				isSendMail: action.payload.isSendMail,
				messageCode: action.payload.messageCode,
				message: action.payload.message
			};
		}
		case actionTypes.ACTION_SESSION_USER_FORGOT_PASSWORD_FAILURE: {
			return {
				...state,
				isLoading: false,
				error: true,
				isSendMail: action.payload.isSendMail,
				messageCode: action.payload.messageCode,
				message: action.payload.message
			};
		}
		case actionTypes.ACTION_SESSION_USER_FORGOT_VALIDATION: {
			return {
				...state,
				isLoading: true,
				tokenForgot: action.payload.tokenForgot
			};
		}
		case actionTypes.ACTION_SESSION_USER_FORGOT_VALIDATION_SUCCESS: {
			return {
				...state,
				isLoading: false,
				userData: action.payload.userData,
				token: action.payload.token,
				enable2FA: action.payload.enable2FA,
				messageCode: action.payload.messageCode,
				message: action.payload.message
			};
		}
		case actionTypes.ACTION_SESSION_USER_FORGOT_VALIDATION_FAILURE: {
			return {
				...state,
				isLoading: false,
				error: true,
				errorMessage: action.payload.errorMessage
			};
		}
		case actionTypes.ACTION_SESSION_USER_LOGIN_UNIFY: {
			return {
				...state,
				isLoading: true,
				tokenUnify: action.payload.tokenUnify
			};
		}
		case actionTypes.ACTION_SESSION_USER_LOGIN_UNIFY_SUCCESS: {
			return {
				...state,
				isLoading: false,
				userData: action.payload.userData,
				token: action.payload.token,
				enable2FA: action.payload.enable2FA,
				messageCode: action.payload.messageCode,
				message: action.payload.message
			};
		}
		case actionTypes.ACTION_SESSION_USER_LOGIN_UNIFY_FAILURE: {
			return {
				...state,
				isLoading: false,
				error: true,
				messageCode: action.payload.messageCode,
				message: action.payload.message
			};
		}
		case actionTypes.ACTION_EXTERNAL_CLEAN_MESSAGE: {
			return {
				...state,
				error: false,
				isLoading: false,
				errorMessage: action.payload.errorMessage,
				messageCode: action.payload.messageCode,
				message: action.payload.message
			};
		}
		case actionTypes.ACTION_SESSION_LOGOUT: {
			return sessionInitialState;
		}
		default: {
			return {
				...state
			};
		}
	}
};

export default externalUserReducer;
