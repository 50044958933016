import * as actionTypes from "../../constants/actionTypes";
import stpInitialize from "./STPInitialize";

const stpReducer = (state = stpInitialize, action) => {
	switch (action.type) {
		case actionTypes.ACTION_STP_REPORT_SUMMARY_REQUEST: {
			return {
				...state,
				isLoading: true,
				messageCode: "",
				message: "",
				reportSummary: {
					...state.reportSummary,
					...action.payload.reportSummary
				}
			};
		}
		case actionTypes.ACTION_STP_REPORT_SUMMARY_REQUEST_SUCCESS: {
			return {
				...state,
				isLoading: false,
				reportSummary: {
					...action.payload.reportSummary
				},
				messageCode: action.payload.messageCode,
				message: action.payload.message
			};
		}
		case actionTypes.ACTION_STP_REPORT_SUMMARY_REQUEST_FAILURE: {
			return {
				...state,
				error: true,
				isLoading: false,
				messageCode: action.payload.messageCode,
				message: action.payload.message
			};
		}
		case actionTypes.ACTION_STP_SEND_REQUEST: {
			return {
				...state,
				isLoading: true,
				messageCode: "",
				message: "",
				sendToSTP: {
					...state.sendToSTP,
					...action.payload.sendToSTP
				},
				isSendToSTP: action.payload.isSendToSTP
			};
		}
		case actionTypes.ACTION_STP_SEND_REQUEST_SUCCESS: {
			return {
				...state,
				isLoading: false,
				sendToSTP: {
					...action.payload.sendToSTP
				},
				messageCode: action.payload.messageCode,
				message: action.payload.message,
				isSendToSTP: action.payload.isSendToSTP
			};
		}
		case actionTypes.ACTION_STP_SEND_REQUEST_FAILURE: {
			return {
				...state,
				error: true,
				isLoading: false,
				messageCode: action.payload.messageCode,
				message: action.payload.message,
				isSendToSTP: action.payload.isSendToSTP
			};
		}
		case actionTypes.ACTION_STP_DELETE_REQUEST: {
			return {
				...state,
				isLoading: true,
				error: false,
				success: false,
				messageCode: "",
				message: "",
			};
		}
		case actionTypes.ACTION_STP_DELETE_REQUEST_SUCCESS: {
			return {
				...state,
				isLoading: false,
				success: true,
				error: false,
				message: action.payload.message,
			};
		}
		case actionTypes.ACTION_STP_DELETE_REQUEST_FAILURE: {
			return {
				...state,
				error: true,
				isLoading: false,
				success: false,
				message: action.payload.message,
			};
		}
		case actionTypes.ACTION_STP_CLEAR_REQUEST: {
			return {
				...state,
				isLoading: true,
				messageCode: "",
				message: "",
				reportSummary: {
					...action.payload.reportSummary
				}
			};
		}
		case actionTypes.ACTION_STP_CLEAR_REQUEST_SUCCESS: {
			return {
				...state,
				isLoading: false,
				reportSummary: {
					...action.payload.reportSummary
				},
				messageCode: action.payload.messageCode,
				message: action.payload.message
			};
		}
		case actionTypes.ACTION_STP_CLEAR_REQUEST_FAILURE: {
			return {
				...state,
				error: true,
				isLoading: false,
				messageCode: action.payload.messageCode,
				message: action.payload.message
			};
		}
		case actionTypes.ACTION_SESSION_LOGOUT: {
			return stpInitialize;
		}
		default: {
			return {
				...state
			};
		}
	}
};

export default stpReducer;
