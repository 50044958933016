import React, { Component } from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import {
	Button, Grid, Table, TableHead, TableBody, TableCell, TableRow, Typography, MenuItem
} from "@material-ui/core";

import { MenuItemComponent, SnackbarCommonComponent } from "../common";

import PayrollStyle from "./payrollStyle";
import ModalAddOvertime from "./modalAddOvertime";
import { payrollOvertimeDefaultData } from "../../reducers/Payroll/PayrollInitialState";
import * as messages from "../../constants/messages";
import EditOvertimeComponent from "./editOvertimeComponent";

class OvertimeComponent extends Component {
	constructor(props) {
		super(props)
		this.addOvertimes();
	}

	state = {
		overtimes:[],
		showModal: false,
		error: {
			show: false,
			message: ""
		},
		editItem: {
			isEditing: false,
			data: null,
			index: -1
		}
	};

	handleClickOption = () => {
		this.setState(state => ({
			...state,
			showModal: true,
			error: {
				...state.error,
				show: false
			}
		}));
	};

	handleCloseModal = () => {
		this.setState(state => ({
			...state,
			showModal: false,
			error: {
				...state.error,
				show: false
			},
			editItem: {
				isEditing: false,
				data: null,
				index: -1
			}
		}));
	};

	setValueAdd = (value) => {
		if (value.overtime === "Manually Enter Hourly Rate") {
			return {
				...payrollOvertimeDefaultData,
				overtime: value.overtime,
				overtimeManualHourlyRate: value.overtimeManualHourlyRate,
				overtimeTotal: parseFloat(value.overtimeTotal).toFixed(2),
				overtimeTotalAmount: parseFloat(value.overtimeTotalAmount).toFixed(2)
			}
		}
		return {
			...payrollOvertimeDefaultData,
			overtime: value.overtime,
			overtimeManualRate: value.overtimeManualRate,
			overtimeTotal: parseFloat(value.overtimeTotal).toFixed(2),
				overtimeTotalAmount: parseFloat(value.overtimeTotalAmount).toFixed(2)
		};
	};

	handleAdd = (value) => {
		const { formProps } = this.props;
		const { overtimes } = formProps.values.payroll;

		this.handleShowError(false, "");

		if (overtimes.length >= 6) {
			this.handleShowError(true, messages.MESSAGE_PAYROLL_OVERTIME_LIMIT);
			return;
		}
		if (!overtimes.some(item => `${value.overtime}_${value.overtimeManualRate ? value.overtimeManualRate : value.overtimeManualHourlyRate}` === `${item.overtime}_${item.overtimeManualRate ? item.overtimeManualRate : item.overtimeManualHourlyRate}`)) {
			const newOvertimes = [...overtimes];
			newOvertimes.push(this.setValueAdd(value));
			formProps.setFieldValue("payroll.overtimes", newOvertimes);
			this.handleCloseModal();
		} else {
			this.handleShowError(true, messages.MESSAGE_PAYROLL_OVERTIME_EXISTS);
		}
	};

	handleUpdate = (value) => {
		const { formProps } = this.props;
		const { editItem } = this.state;
		const { overtimes } = formProps.values.payroll;

		const newOvertimes = [...overtimes];
		newOvertimes.splice(editItem.index, 1);

		newOvertimes.push(this.setValueAdd(value));

		formProps.setFieldValue("payroll.overtimes", newOvertimes);

		this.handleCloseModal();
	};

	handleRemoveItem = (event, index) => {
		const { formProps } = this.props;
		const { overtimes } = formProps.values.payroll;
		const newOvertimes = [...overtimes];
		newOvertimes.splice(index, 1);

		formProps.setFieldValue("payroll.overtimes", newOvertimes);
	};

	handleEditItem = (event, index) => {
		const { formProps } = this.props;
		const { overtimes } = formProps.values.payroll;

		this.setState(state => ({
			...state,
			showModal: true,
			editItem: {
				isEditing: true,
				data: overtimes[index],
				index
			}
		}));
	};

	handleShowError = (show, message) => {
		this.setState(state => ({
			...state,
			error: {
				show,
				message
			}
		}));
	};
	addOvertimes() {
		const { selectedPayrollData, formProps } = this.props;
		const { overtimes } = formProps.values.payroll;
		const newOvertimes = [...overtimes];
		if (selectedPayrollData.employeeActive
			&& selectedPayrollData.employeeActive.overtimes
			&& selectedPayrollData.employeeActive.overtimes.length > 0) { 
			selectedPayrollData.employeeActive.overtimes.map((value) => {
				newOvertimes.push(this.setValueAdd(value));
			})
			formProps.setFieldValue("payroll.overtimes", newOvertimes);
		}
	}
 
	componentWillReceiveProps(nextProps) {
		const { selectedPayrollData } = nextProps;
		const selectedPayrollDataLast = this.props.selectedPayrollData;
		let check = (selectedPayrollData.employeeActive
			&& selectedPayrollData.employeeActive.overtimes
			&& selectedPayrollData.employeeActive.overtimes.length > 0);
		if (check) {
			if (selectedPayrollDataLast && selectedPayrollDataLast.employeeActive
				&& selectedPayrollDataLast.employeeActive.overtimes
				&& selectedPayrollDataLast.employeeActive.overtimes.length > 0) {
				if (selectedPayrollDataLast.employeeActive.overtimes.length == selectedPayrollData.employeeActive.overtimes.length) {
					check = false;
				}
			}
		}
		if (check) {
			this.addOvertimes();
		}
	}

	render() {
		const { classes, formProps, isFinishAllEmployees } = this.props;
		const { showModal, error, editItem } = this.state;
		return (
			<Grid className={classes.gridContainer}>
				<Grid container direction="row" alignItems="flex-start">
					<Grid item xs={6}>
						<Typography variant="h4" gutterBottom>
							Overtime
						</Typography>
					</Grid>
					<Grid item xs={6} className={classes.gridRight}>
						<Button variant="outlined" color="secondary" onClick={this.handleClickOption} disabled={isFinishAllEmployees}>
							+ Add overtime
						</Button>
						{showModal ? (
							<ModalAddOvertime open={showModal} edit={editItem.isEditing} data={editItem.data} onAddValues={editItem.isEditing ? this.handleUpdate : this.handleAdd} onCloseModal={this.handleCloseModal} baseHourly={parseFloat(formProps.values.payroll.paymentTypeBaseHours)} />
						) : null}
					</Grid>
				</Grid>
				{!formProps.values.payroll.overtimes || formProps.values.payroll.overtimes.length <= 0 ? (
					<Typography variant="h4" component="div" className={classes.placeHolder}>
						{"You haven’t added overtime"}
					</Typography>
				) : null}
				{(formProps.values.payroll.overtimes && formProps.values.payroll.overtimes.length > 0) ? (
					<React.Fragment>
						<Table className={classes.table} size="medium">
							<TableHead className={classes.tableH}>
								<TableRow>
									<TableCell className={classes.tableTitle}>Rate</TableCell>
									<TableCell className={classes.tableTitle}>Hours</TableCell>
									<TableCell className={classes.tableTitle}>Total</TableCell>
									<TableCell className={classes.tableTitle} align="right" />
								</TableRow>
							</TableHead>
							<TableBody>

								{formProps.values.payroll.overtimes && formProps.values.payroll.overtimes.length > 0 ? (
									<React.Fragment>
										{formProps.values.payroll.overtimes.map((row, index) => (
											// <TableRow key={`${row.overtime}_${row.overtimeManualRate ? row.overtimeManualRate : row.overtimeManualHourlyRate}`} className={classes.tableRow}>
											// 	<TableCell className={classes.tableText} component="th" scope="row">
											// 		{row.overtime === "Manually Enter Hourly Rate" ? `$ ${row.overtimeManualHourlyRate}` : `x ${row.overtimeManualRate}`}
											// 	</TableCell>
											// 	<TableCell className={classes.tableText}>{row.overtimeTotal}</TableCell>
											// 	<TableCell className={classes.tableText}>{`$ ${row.overtimeTotalAmount}`}</TableCell>
											// 	<TableCell className={classes.tableText} align="right">
											// 		<MenuItemComponent>
											// 			<MenuItem onClick={e => this.handleEditItem(e, index)}>Edit</MenuItem>
											// 			<MenuItem onClick={e => this.handleRemoveItem(e, index)}>Delete</MenuItem>
											// 		</MenuItemComponent>
											// 	</TableCell>
											// </TableRow>

											<EditOvertimeComponent
											row={row}
											index={index}
											baseHourly={parseFloat(formProps.values.payroll.paymentTypeBaseHours)}
											key={index}
											parentProps={formProps}
											handelChange={this.handelChange}
											handleRemoveItem={this.handleRemoveItem}
											/>
										))}
									</React.Fragment>
								) : null}
							</TableBody>
						</Table>
					</React.Fragment>

				) : null}
				{error && error.show ? <SnackbarCommonComponent variant="error" open={error.show} body={error.message} /> : null}
			</Grid >
		);
	}
}

OvertimeComponent.propTypes = {
	formProps: PropTypes.object.isRequired
};

export default withStyles(PayrollStyle)(OvertimeComponent);
