import * as actionTypes from "../constants/actionTypes";

export function employeesRequest() {
	return {
		type: actionTypes.ACTION_EMPLOYEES_REQUEST,
		payload: {}
	};
}

export function employeesRequestSuccess(listData, activeList, inactiveList, unfinishList, reportsList, messageCode, message) {
	return {
		type: actionTypes.ACTION_EMPLOYEES_REQUEST_SUCCESS,
		payload: {
			list: listData,
			activeEmployeesList: activeList,
			inactiveEmployeesList: inactiveList,
			unfinishedEmployeeList: unfinishList,
			reportsEmployeeList: reportsList,
			messageCode,
			message
		}
	};
}

export function employeesRequestFailure(messageCode, errorMessage) {
	return {
		type: actionTypes.ACTION_EMPLOYEES_REQUEST_FAILURE,
		error: true,
		payload: {
			messageCode,
			errorMessage
		}
	};
}

export function getEmployeeDataRequest(employeeId) {
	return {
		type: actionTypes.ACTION_EMPLOYEE_DATA_REQUEST,
		payload: {
			employeeId
		}
	};
}

export function getEmployeeDataRequestSuccess(selectedEmployeeData, messageCode, message) {
	return {
		type: actionTypes.ACTION_EMPLOYEE_DATA_REQUEST_SUCCESS,
		payload: {
			selectedEmployeeData,
			messageCode,
			message
		}
	};
}

export function getEmployeeDataRequestFailure(messageCode, errorMessage) {
	return {
		type: actionTypes.ACTION_EMPLOYEE_DATA_REQUEST_FAILURE,
		error: true,
		payload: {
			messageCode,
			errorMessage
		}
	};
}

export function saveEmployeeDataRequest(employerId, employeeId, employeeData) {
	return {
		type: actionTypes.ACTION_EMPLOYEE_SAVE_DATA_REQUEST,
		payload: {
			employerId,
			employeeId,
			selectedEmployeeData: {
				...employeeData
			}
		}
	};
}

export function saveEmployeeDataRequestSuccess(employeeData, messageCode, message) {
	return {
		type: actionTypes.ACTION_EMPLOYEE_SAVE_DATA_REQUEST_SUCCESS,
		payload: {
			selectedEmployeeData: {
				...employeeData
			},
			messageCode,
			message
		}
	};
}

export function saveEmployeeDataRequestFailure(messageCode, errorMessage) {
	return {
		type: actionTypes.ACTION_EMPLOYEE_SAVE_DATA_REQUEST_FAILURE,
		error: true,
		payload: {
			messageCode,
			errorMessage
		}
	};
}

export function updateTempInformation(fullname, jobTitle) {
	return {
		type: actionTypes.ACTION_EMPLOYEES_TEMPINFORMATION,
		payload: {
			fullname,
			jobTitle
		}
	};
}

export function setCleanDataToStore(employeeData) {
	return {
		type: actionTypes.ACTION_EMPLOYEE_CLEAN_DATA,
		payload: {
			selectedEmployeeData: {
				...employeeData
			}
		}
	};
}

export function updatePayrollStatus(newStatus) {
	return {
		type: actionTypes.ACTION_EMPLOYEES_UPDATE_STATUS,
		payload: {
			payrollStatus: newStatus
		}
	};
}

export function uploadImageRequest(employeeId, filename, imageFile) {
	return {
		type: actionTypes.ACTION_EMPLOYEE_UPLOAD_IMAGE_REQUEST,
		payload: {
			employeeId,
			filename,
			imageFile
		}
	};
}

export function uploadImageRequestSuccess(imageUpload, messageCode, sucessMessage) {
	return {
		type: actionTypes.ACTION_EMPLOYEE_UPLOAD_IMAGE_REQUEST_SUCCESS,
		payload: {
			imageUpload,
			messageCode,
			sucessMessage,
		}
	};
}

export function uploadImageRequestFailure(messageCode, errorMessage) {
	return {
		type: actionTypes.ACTION_EMPLOYEE_UPLOAD_IMAGE_REQUEST_FAILURE,
		payload: {
			messageCode,
			errorMessage
		}
	};
}

export function finishEmployee(employerId, employeeId, employeeData, cleanEmployeeData, history, location) {

	return {
		type: actionTypes.ACTION_EMPLOYEE_FINISH_REQUEST,
		payload: {
			employerId,
			employeeId,
			employeeData,
			cleanEmployeeData,
			history,
			location,
		}
	};
}

export function activeOrInactiveEmployee(employeeData) {
	return {
		type: actionTypes.ACTION_EMPLOYEE_ACTIVE_OR_INACTIVE,
		payload: {
			employeeData
		}
	};
}

export function activeOrInactiveEmployeeSuccess(employeeData, messageCode, message) {
	return {
		type: actionTypes.ACTION_EMPLOYEE_ACTIVE_OR_INACTIVE_SUCCESS,
		payload: {
			employeeData,
			messageCode,
			message
		}
	};
}

export function activeOrInactiveEmployeeFailure(messageCode, errorMessage) {
	return {
		type: actionTypes.ACTION_EMPLOYEE_ACTIVE_OR_INACTIVE_FAILURE,
		error: true,
		payload: {
			messageCode,
			errorMessage
		}
	};
}

export function cleanMessage() {
	return {
		type: actionTypes.ACTION_EMPLOYEE_CLEAN_MESSAGE,
		payload: {
			errorMessage: null,
			messageCode: null,
			message: null
		}
	};
}

export function selectEmployeeToggle(employeeData, checked) {
	return {
		type: actionTypes.ACTION_EMPLOYEES_SELECT_TOGGLE,
		payload: {
			employeeData,
			checked
		}
	};
}

export function selectAllEmployeesToggle(checked) {
	return {
		type: actionTypes.ACTION_EMPLOYEES_SELECT_ALL_TOGGLE,
		payload: {
			checked,
		}
	};
}
export function addEmployeeWithFinish() {
	return {
		type: actionTypes.ACTION_SAVE_EMPLOYEE_WITH_FINISH,
	}
}