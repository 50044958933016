// -------------------- GET ALL YECP ------------------
const getAllYECP = async httpClient => {
  const { goEpsApi } = window.envVariables;
  const response = await httpClient.get(`${goEpsApi}/api/v1/fy/close`);
  return response;
};

// -------------------- SEND CLOSE FY ----------------
const getDataAboutCloseFY = async (httpClient, requestData) => {
  const { goEpsApi } = window.envVariables;
  const response = await httpClient.post(
    `${goEpsApi}/api/v1/fy/close`,
    requestData
  );
  return response;
};

// -------------------- SEND FY CLOSE SEND --------------------
const sendFYCloseSignature = async (httpClient, yecp, requestData) => {
  const { goEpsApi } = window.envVariables;
  const response = await httpClient.post(
    `${goEpsApi}/api/v1/fy/close/${yecp}/send`,
    requestData
  );
  return response.data;
};

// -------------------- GET AUTHORIZED NAME -----------------

const getAuthorizedname = async httpClient => {
  const { goEpsApi } = window.envVariables;
  const response = await httpClient.get(
    `${goEpsApi}/api/v1/stp/authorizedperson`
  );
  return response.data;
};

// -------------------- PUT EMPLOYEES FOR CLOSE FY ------------
const putEmployeesForCloseFY = async (httpClient, yecp, requestData) => {
  const { goEpsApi } = window.envVariables;
  const response = await httpClient.put(
    `${goEpsApi}/api/v1/fy/close/${yecp}`,
    requestData
  );
  return response.data;
};

// -------------------- EDIT EMPLOYEES PAYROLL FOR CLOSE FY ---------
const editEmployeePayrollForCloseFY = async (
  httpClient,
  yecp,
  id,
  requestData
) => {
  const { goEpsApi } = window.envVariables;
  const response = await httpClient.put(
    `${goEpsApi}/api/v1/fy/close/${yecp}/${id}`,
    requestData
  );
  return response.data;
};

// -------------------- SEND CLOSE FY AFTER EIDT EMPLOYEES ----------------------
const sendCloseFYAfterEditEmployees = async (httpClient, yecp) => {
  const { goEpsApi } = window.envVariables;
  const response = await httpClient.post(`${goEpsApi}/api/v1/fy/close/${yecp}`);
  return response.data;
};

export default {
  getAllYECP,
  getDataAboutCloseFY,
  sendFYCloseSignature,
  getAuthorizedname,
  putEmployeesForCloseFY,
  editEmployeePayrollForCloseFY,
  sendCloseFYAfterEditEmployees
};
