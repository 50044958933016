import React, { Component } from "react";
import { connect } from "react-redux";
import clsx from "clsx";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Paper,
  TextField,
  Typography,
  Button,
  FormHelperText
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { Formik, Form, getIn } from "formik";
import * as Yup from "yup";

import EmployeeStyles from "./employeeStyle";

import { CardCommonComponent } from "../common";
import {
  validatePostCode,
  validatePhoneNumber
} from "../../helpers/extensions";
import * as parameterTypes from "../../constants/parameterTypes";
import { employeeDefaultData } from "../../reducers/Employees/EmployeeInitialState";
import {
  getUTCDate,
  getUTCToLocal,
  getLocalToUTC
} from "../../helpers/dateextensions";

class EmployeePersonalDetailsComponent extends Component {
  validationSchema = Yup.object({
    detail: Yup.object().shape({
      firstName: Yup.string().required("First name is required"),
      middleName: Yup.string(),
      lastName: Yup.string().required("Last name is required"),
      dob: Yup.date()
        .typeError("Invalid date")
        .required("Date of birth is required"),
      phoneNumber: Yup.string().test(
        "phonenumber-validation",
        "Must be a valid Australian phone number",
        validatePhoneNumber
      ),
      email: Yup.string()
        .email("Must be a valid email address")
        .required("Email is required"),
      address: Yup.object().shape({
        addressLine1: Yup.string().required("Address line 1 is required"),
        addressLine2: Yup.string(),
        townCity: Yup.string().required("Town / City is required"),
        postCode: Yup.string()
          .typeError("Must be an Australian valid postcode")
          .required("Postcode is required")
          .test(
            "postcode-validation",
            "Must be an Australian valid postcode",
            validatePostCode
          ),
        state: Yup.string().required("State/Territory is required")
      })
    })
  });

  constructor(props) {
    super(props);
    this.state = {
      isComplete: false,
      isvalidation: false
    };
  }

  handleSubmitSaveDetails = async (values, actions) => {
    const {
      saveEmployeeData,
      employerId,
      employeeId,
      parentFormProps
    } = this.props;

    parentFormProps.values.detail.dob = getLocalToUTC(
      parentFormProps.values.detail.dob
    );
    // parentFormProps.values.payroll.employmentDate=getLocalToUTC(parentFormProps.values.payroll.employmentDate)
    actions.setSubmitting(false);
    await saveEmployeeData(employerId, employeeId, {
      ...parentFormProps.values
    });
    this.setState(prevState => ({ ...prevState, isComplete: true }));
  };

  handleDateOfBirthChange = (value, setFieldValue) => {
    const { parentFormProps } = this.props;
    setFieldValue("detail.dob", value);
    parentFormProps.setFieldValue("detail.dob", value);
  };

  renderAustraliaStateOptions = () => {
    const { stateList, classes } = this.props;
    return stateList
      .sort((firstEl, secondEl) => firstEl.order < secondEl.order)
      .map(item => (
        <option
          key={`${item.id}`}
          value={`${item.value}`}
          className={classes.option}
        >
          {item.value}
        </option>
      ));
  };

  handleOnChange = (event, fromProps, parentFormProps) => {
    fromProps.setFieldValue(event.target.id, event.target.value);
    parentFormProps.setFieldValue(event.target.id, event.target.value);

    const { isComplete } = this.state;
    const isFormComplete = this.checkIsComplete(fromProps);

    if (isFormComplete != isComplete) {
      this.setState({isComplete: isFormComplete});
    }
  };

  checkIsComplete = formProps => {
    let finalResult = false;
    try {
      finalResult = this.validationSchema.isValidSync(formProps.values);
    } catch (err) {
      // console.error("Error validating personal details as complete: ", err);
    }
    return finalResult;
  };
  componentWillReceiveProps = nextProps => {
    // this.setState({ isvalidation: true });
    console.log('loading props in personal');
    if (nextProps.parentFormProps.errors.entitlement || this.props.edit) {
      console.log(nextProps.parentFormProps);
      console.log(this.state.isvalidation);
      if (
        nextProps &&
        nextProps.parentFormProps &&
        (nextProps.parentFormProps.errors &&
        (nextProps.parentFormProps.errors.detail ||
          nextProps.parentFormProps.errors.taxe ||
          nextProps.parentFormProps.errors.payroll ||
          nextProps.parentFormProps.errors.superannuation)) ||
          (nextProps.parentFormProps.values && !this.checkIsComplete(nextProps.parentFormProps))
      ) {
        this.props.handleValidation(false);
        if (!this.state.isvalidation || this.props.refreshValidation) {
          this.setState(
            {
              isvalidation: true
            },
            () => {
              console.log("presonall setting false")
              this.props.handleValidation(false);
            }
          );
          // this.props.handleValidation(false);
          //   }
        }
      } else {
        this.props.handleValidation(true);
        if (this.state.isvalidation || this.props.refreshValidation) {
          this.setState(
            {
              isvalidation: false
            },
            () => {
              console.log("personal setting validation true");
              this.props.handleValidation(true);
            }
          );
          // this.props.handleValidation(true);
        }
      }
    }
  };

  renderForm = formProps => {
    const { classes, parentFormProps } = this.props;
    const { isComplete } = this.state;
    const isFormComplete = this.checkIsComplete(formProps);

    return (
      <CardCommonComponent
        title="Personal details"
        complete={isFormComplete || isComplete}
        collapseExtend
      >
        <Form>
          <Grid container direction="row" alignItems="flex-start">
            <Grid item xs={4}>
              <Paper className={classes.paperGrid}>
                <TextField
                  id="detail.firstName"
                  label="First name *"
                  variant="filled"
                  value={formProps.values.detail.firstName}
                  onChange={event =>
                    this.handleOnChange(event, formProps, parentFormProps)
                  }
                  onBlur={formProps.handleBlur}
                  className={clsx(classes.textField, {
                    error:
                      getIn(formProps.errors, "detail.firstName") &&
                      getIn(formProps.touched, "detail.firstName")
                  })}
                  fullWidth
                />
                {getIn(formProps.errors, "detail.firstName") &&
                  getIn(formProps.touched, "detail.firstName") && (
                    <FormHelperText error>
                      {formProps.errors.detail.firstName}
                    </FormHelperText>
                  )}
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper className={classes.paperGrid}>
                <TextField
                  id="detail.middleName"
                  label="Middle name (If applicable)"
                  variant="filled"
                  value={formProps.values.detail.middleName}
                  onChange={event =>
                    this.handleOnChange(event, formProps, parentFormProps)
                  }
                  onBlur={formProps.handleBlur}
                  className={clsx(classes.textField)}
                  fullWidth
                />
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper
                className={clsx(classes.paperGrid, {
                  [classes.paperGridClearRight]: true
                })}
              >
                <TextField
                  id="detail.lastName"
                  label="Last name *"
                  variant="filled"
                  value={formProps.values.detail.lastName}
                  onChange={event =>
                    this.handleOnChange(event, formProps, parentFormProps)
                  }
                  onBlur={formProps.handleBlur}
                  className={clsx(classes.textField, {
                    error:
                      getIn(formProps.errors, "detail.lastName") &&
                      getIn(formProps.touched, "detail.lastName")
                  })}
                  fullWidth
                />
                {getIn(formProps.errors, "detail.lastName") &&
                  getIn(formProps.touched, "detail.lastName") && (
                    <FormHelperText error>
                      {formProps.errors.detail.lastName}
                    </FormHelperText>
                  )}
              </Paper>
            </Grid>

            <Grid item xs={4}>
              <Paper className={classes.paperGrid}>
                <KeyboardDatePicker
                  type="date-local"
                  id="detail.dob"
                  label="Date of birth *"
                  format="DD/MM/YYYY"
                  value={getUTCToLocal(formProps.values.detail.dob)}
                  onChange={value => {
                    this.handleDateOfBirthChange(
                      value,
                      formProps.setFieldValue
                    );
                  }}
                  onBlur={formProps.handleBlur}
                  fullWidth
                  animateYearScrolling
                  disableFuture
                  className={clsx(classes.textField, {
                    error:
                      getIn(formProps.errors, "detail.dob") &&
                      getIn(formProps.touched, "detail.dob")
                  })}
                  InputProps={{
                    classes: {
                      root: classes.keyboardDatePicker,
                      input: classes.keyboardDatePickerInput
                    }
                  }}
                />
                {getIn(formProps.errors, "detail.dob") &&
                  getIn(formProps.touched, "detail.dob") && (
                    <FormHelperText error>
                      {formProps.errors.detail.dob}
                    </FormHelperText>
                  )}
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper className={classes.paperGrid}>
                <TextField
                  id="detail.phoneNumber"
                  label="Phone number"
                  variant="filled"
                  fullWidth
                  value={formProps.values.detail.phoneNumber}
                  onChange={event =>
                    this.handleOnChange(event, formProps, parentFormProps)
                  }
                  onBlur={formProps.handleBlur}
                  className={clsx(classes.textField, "phNumber", {
                    error:
                      getIn(formProps.errors, "detail.phoneNumber") &&
                      getIn(formProps.touched, "detail.phoneNumber")
                  })}
                  type="number"
                />
                {getIn(formProps.errors, "detail.phoneNumber") &&
                  getIn(formProps.touched, "detail.phoneNumber") && (
                    <FormHelperText error>
                      {formProps.errors.detail.phoneNumber}
                    </FormHelperText>
                  )}
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper
                className={clsx(classes.paperGrid, {
                  [classes.paperGridClearRight]: true
                })}
              >
                <TextField
                  id="detail.email"
                  label="Email *"
                  variant="filled"
                  fullWidth
                  value={formProps.values.detail.email}
                  onChange={event =>
                    this.handleOnChange(event, formProps, parentFormProps)
                  }
                  onBlur={formProps.handleBlur}
                  className={clsx(classes.textField, {
                    error:
                      getIn(formProps.errors, "detail.email") &&
                      getIn(formProps.touched, "detail.email")
                  })}
                  type="email"
                />
                {getIn(formProps.errors, "detail.email") &&
                  getIn(formProps.touched, "detail.email") && (
                    <FormHelperText error>
                      {formProps.errors.detail.email}
                    </FormHelperText>
                  )}
              </Paper>
            </Grid>
          </Grid>
          <Typography variant="h4" gutterBottom>
            Address details
          </Typography>

          <Grid container direction="row" alignItems="flex-start">
            <Grid item xs={4}>
              <Paper className={classes.paperGrid}>
                <TextField
                  id="detail.address.addressLine1"
                  label="Address line 1 *"
                  variant="filled"
                  InputProps={{
                    inputProps: {
                      maxLength: "37"
                    }
                  }}
                  value={formProps.values.detail.address.addressLine1}
                  onChange={event =>
                    this.handleOnChange(event, formProps, parentFormProps)
                  }
                  onBlur={formProps.handleBlur}
                  className={clsx(classes.textField, {
                    error:
                      getIn(formProps.errors, "detail.address.addressLine1") &&
                      getIn(formProps.touched, "detail.address.addressLine1")
                  })}
                  fullWidth
                />
                {getIn(formProps.errors, "detail.address.addressLine1") &&
                  getIn(formProps.touched, "detail.address.addressLine1") && (
                    <FormHelperText error>
                      {formProps.errors.detail.address.addressLine1}
                    </FormHelperText>
                  )}
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper className={classes.paperGrid}>
                <TextField
                  id="detail.address.addressLine2"
                  label="Address line 2 (If applicable)"
                  variant="filled"
                  InputProps={{
                    inputProps: {
                      maxLength: "37"
                    }
                  }}
                  maxLength="37"
                  value={formProps.values.detail.address.addressLine2}
                  onChange={event =>
                    this.handleOnChange(event, formProps, parentFormProps)
                  }
                  onBlur={formProps.handleBlur}
                  className={clsx(classes.textField)}
                  fullWidth
                />
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper
                className={clsx(classes.paperGrid, {
                  [classes.paperGridClearRight]: true
                })}
              >
                <TextField
                  id="detail.address.townCity"
                  label="Town/City *"
                  variant="filled"
                  value={formProps.values.detail.address.townCity}
                  onChange={event =>
                    this.handleOnChange(event, formProps, parentFormProps)
                  }
                  onBlur={formProps.handleBlur}
                  className={clsx(classes.textField, {
                    error:
                      getIn(formProps.errors, "detail.address.townCity") &&
                      getIn(formProps.touched, "detail.address.townCity")
                  })}
                  fullWidth
                />
                {getIn(formProps.errors, "detail.address.townCity") &&
                  getIn(formProps.touched, "detail.address.townCity") && (
                    <FormHelperText error>
                      {formProps.errors.detail.address.townCity}
                    </FormHelperText>
                  )}
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper className={classes.paperGrid}>
                <TextField
                  id="detail.address.postCode"
                  label="Postcode *"
                  variant="filled"
                  InputProps={{
                    inputProps: {
                      maxLength: "4"
                    }
                  }}
                  value={formProps.values.detail.address.postCode}
                  onChange={event =>
                    this.handleOnChange(event, formProps, parentFormProps)
                  }
                  onBlur={formProps.handleBlur}
                  className={clsx(classes.textField, {
                    error:
                      getIn(formProps.errors, "detail.address.postCode") &&
                      getIn(formProps.touched, "detail.address.postCode")
                  })}
                  fullWidth
                />
                {getIn(formProps.errors, "detail.address.postCode") &&
                  getIn(formProps.touched, "detail.address.postCode") && (
                    <FormHelperText error>
                      {formProps.errors.detail.address.postCode}
                    </FormHelperText>
                  )}
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper className={classes.paperGrid}>
                <TextField
                  id="detail.address.state"
                  label="State/Territory *"
                  variant="filled"
                  value={formProps.values.detail.address.state}
                  onChange={event =>
                    this.handleOnChange(event, formProps, parentFormProps)
                  }
                  onBlur={formProps.handleBlur}
                  className={clsx(classes.textField, {
                    error:
                      getIn(formProps.errors, "detail.address.state") &&
                      getIn(formProps.touched, "detail.address.state")
                  })}
                  SelectProps={{
                    native: true,
                    MenuProps: {
                      className: classes.menu
                    }
                  }}
                  select
                  fullWidth
                >
                  <option></option>
                  {this.renderAustraliaStateOptions()}
                </TextField>
                {getIn(formProps.errors, "detail.address.state") &&
                  getIn(formProps.touched, "detail.address.state") && (
                    <FormHelperText error>
                      {formProps.errors.detail.address.state}
                    </FormHelperText>
                  )}
              </Paper>
            </Grid>
            <Grid item xs={12} className={classes.gridRight}>
              <Button
                variant="outlined"
                color="secondary"
                // onClick={parentFormProps.handleSubmit}
                onClick={formProps.handleSubmit}
                className={classes.buttonSaveDetails}
              >
                {"Save Details"}
              </Button>
            </Grid>
          </Grid>
        </Form>
      </CardCommonComponent>
    );
  };

  render() {
    const { employeeData } = this.props;

    const employeeDataValues = {
      ...employeeDefaultData,
      ...employeeData,
      detail: {
        ...employeeDefaultData.detail,
        ...employeeData.detail,
        imageUpload: {
          ...employeeDefaultData.detail.imageUpload,
          ...employeeData.detail.imageUpload
        },
        address: {
          ...employeeDefaultData.detail.address,
          ...employeeData.detail.address
        },
        dob: employeeData.detail.dob
          ? employeeData.detail.dob
          : employeeDefaultData.detail.dob
      },
      payroll: {
        ...employeeDefaultData.payroll,
        ...employeeData.payroll,
        employmentDate: employeeData.payroll.employmentDate
          ? employeeData.payroll.employmentDate
          : employeeDefaultData.payroll.employmentDate
      },
      entitlement: {
        ...employeeDefaultData.entitlement,
        ...employeeData.entitlement
      },
      taxe: {
        ...employeeDefaultData.taxe,
        ...employeeData.taxe
      },
      superannuation: {
        ...employeeDefaultData.superannuation,
        ...employeeData.superannuation
      }
    };

    return (
      <Formik
        initialValues={{ ...employeeDataValues }}
        validationSchema={this.validationSchema}
        onSubmit={this.handleSubmitSaveDetails}
        render={this.renderForm}
        enableReinitialize="true"
      />
    );
  }
}

EmployeePersonalDetailsComponent.propTypes = {
  employeeData: PropTypes.object.isRequired,
  stateList: PropTypes.array,
  parentFormProps: PropTypes.object.isRequired,

  saveEmployeeData: PropTypes.func
};

const mapStateToProps = (state, ownProps) => ({
  employeeData: ownProps.parentFormProps.values,
  stateList:
    state.parameters.parameterByTypeData[
      parameterTypes.PARAMETER_STATES_AUSTRALIA.parameterType
    ]
});

export default connect(
  mapStateToProps,
  null
)(withStyles(EmployeeStyles)(EmployeePersonalDetailsComponent));
