import React from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { PropTypes } from "prop-types";
import axios from "axios";
import { CircularProgress } from "@material-ui/core";

import DialogCommonComponent from "./dialogCommonComponent";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class DialogCommonPDFComponent extends React.Component {
	constructor() {
		super();

		this.state = {
			NumPages: null
		};
	}

	onDocumentLoadSuccess = ({ numPages }) => {
		this.setState({ NumPages: numPages });
	};

	handleDownloadPDF = () => {
		const { url, title } = this.props;

		axios({
			url,
			method: "GET",
			responseType: "blob" // important
		}).then((response) => {
			const urlLink = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement("a");
			link.href = urlLink;
			link.setAttribute("download", `${title}.pdf`);
			document.body.appendChild(link);
			link.click();
			window.URL.revokeObjectURL(link);
		});
	};

	render() {
		const {
			title, open, style, style2, onCloseModal, url, primaryButtonName, onPrimaryButtonClick, secondaryButtonName, onSecondaryButtonClick, showScroll, hideButton
		} = this.props;

		const { NumPages } = this.state;

		return (
			<DialogCommonComponent
				title={title}
				open={open}
				onCloseModal={onCloseModal}
				size="lg"
				style={style}
				style2={style2}
				primaryButtonName={primaryButtonName || "Download"}
				onPrimaryButtonClick={onPrimaryButtonClick || this.handleDownloadPDF}
				secondaryButtonName={secondaryButtonName}
				onSecondaryButtonClick={onSecondaryButtonClick}
				showScroll={showScroll}
				hideButton={hideButton}
			>
				<Document file={`${url}`} onLoadSuccess={this.onDocumentLoadSuccess} loading={<CircularProgress />}>
					{Array.from(new Array(NumPages), (page, index) => (
						<Page key={`page_${index + 1}`} pageNumber={index + 1} renderAnnotationLayer width={920} />
					))}
				</Document>
			</DialogCommonComponent>
		);
	}
}

DialogCommonPDFComponent.propTypes = {
	title: PropTypes.string.isRequired,
	open: PropTypes.bool.isRequired,
	onCloseModal: PropTypes.func.isRequired,
	url: PropTypes.string.isRequired,
	showScroll: PropTypes.bool,
	hideButton: PropTypes.bool
};

export default DialogCommonPDFComponent;
