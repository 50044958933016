import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";
import { bindActionCreators } from "redux";
import { LoadingComponent, SnackbarCommonComponent } from "../components/common";

import * as employerActions from "../actions/EmployerActions";
import SidebarComponent from "../components/home/sidebarComponent";
import TwoFactorChoiceAuthenticationComponent from "../components/setting/twoFactorChoiceAuthenticationComponent";
import { SessionUserStyles } from "./PagesStyle";

class TwoFactorChoiceAuthenticationPage extends Component {
	render() {
		const { classes, isLoading, mixpanel, error, errorMessage, history, message, messageCode, cleanMessageInternal } = this.props;
		return (
			<div className={classes.root}>
				<CssBaseline />
				<SidebarComponent />
				<main className={classes.content}>
					<LoadingComponent isLoading={isLoading} />
					<SnackbarCommonComponent autoHideDuration={3000} open={!isLoading && message && messageCode === 200} body={message} onCloseModal={cleanMessageInternal} />
					<SnackbarCommonComponent variant="error" autoHideDuration={3000} open={!isLoading && error && messageCode !== 200} body={errorMessage} onCloseModal={cleanMessageInternal} />
					<div className={classes.appBarSpacer} />
					<TwoFactorChoiceAuthenticationComponent history={history} mixpanel={mixpanel} />
				</main>
			</div>
		);
	}
}

TwoFactorChoiceAuthenticationPage.propTypes = {
	classes: PropTypes.object.isRequired,
	isLoading: PropTypes.bool
};

const mapStateToProps = state => ({
	isLoading: state.employer.isLoading,
	error: state.employer.error,
	errorMessage: state.employer.errorMessage,
	message: state.employer.message,
	messageCode: state.employer.messageCode,
});
const mapDispatchToProps = dispatch => ({
	cleanMessageInternal: bindActionCreators(employerActions.cleanMessage, dispatch)
});
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(SessionUserStyles)(TwoFactorChoiceAuthenticationPage));
