import React, { Component } from "react";
import PropTypes from "prop-types";

import { getIn } from "formik";

import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import {
	Grid, Typography, Paper, TextField, InputAdornment
} from "@material-ui/core";

import PayrollStyle from "./payrollStyle";
import { NumberFormatCustomComponent } from "../common";

class commissionComponent extends Component {
	handleChangeBase = (name, event, formProps) => {

		let commissions=formProps.values.payroll.commissions;

		if(commissions.length === 0){
			commissions=[{
				description: "",
				amount: ""
			},
			{
				description: "",
				amount: ""
			}];

		}
		switch (event.target.id) {
			case "commissionsAmount":
		    	commissions[0].amount = event.target.value
				break;
			case "commissionsDescription":
				commissions[0].description = event.target.value
				break;
			case "commissionsAmount0":
				commissions[1].amount = event.target.value
				break;
			case "commissionsDescription0":
				commissions[1].description = event.target.value
				break;
			default:
				break;
		}

		formProps.setFieldValue("payroll.commissions", commissions);
	};

	render() {
		const { classes, formProps } = this.props;
		let commissions=formProps.values.payroll.commissions;

		return (
			<Grid className={classes.gridContainer}>
				<Typography variant="h4" gutterBottom>
					{"Commission"}
				</Typography>
				 <Grid container direction="row" alignItems="flex-start">

							<Grid item xs={4}>
								<Paper className={classes.paperGrid}>
									<TextField
										id={`commissionsAmount`}
										label="Amount"
										variant="filled"
										InputProps={{
											startAdornment: <InputAdornment position="start">$</InputAdornment>,
											inputComponent: NumberFormatCustomComponent,
											inputProps: {
												decimalScale: 2,
												fixedDecimalScale: true
											}
										}}
										value={commissions && commissions[0] ? commissions[0].amount : ''}
										onChange={(event) => {
											this.handleChangeBase(`commissionsAmount`, event, formProps);
										}}
										onBlur={formProps.handleBlur}
										className={clsx(classes.textField, {
											error: getIn(formProps.errors, `commissionsAmount`) && getIn(formProps.touched, `commissionsAmount`)
										})}
										fullWidth
									/>
								</Paper>
							</Grid>

							<Grid item xs={4}>
								<Paper className={classes.paperGrid}>
									<TextField
										id={`commissionsDescription`}
										label="Description"
										variant="filled"
										fullWidth
										value={commissions && commissions[0] ? commissions[0].description :''}
										onChange={(value) => {
											this.handleChangeBase(`commissionsDescription`, value, formProps);
										}}
										onBlur={formProps.handleBlur}
										className={clsx(classes.textField, {
											error: getIn(formProps.errors, `commissionsAmount`) && getIn(formProps.touched, `commissionsAmount`)
										})}
									/>
								</Paper>
							</Grid>
						</Grid>
						<Grid container direction="row" alignItems="flex-start">

				<Grid item xs={4}>
					<Paper className={classes.paperGrid}>
						<TextField
							id={`commissionsAmount0`}
							label="Amount"
							variant="filled"
							InputProps={{
								startAdornment: <InputAdornment position="start">$</InputAdornment>,
								inputComponent: NumberFormatCustomComponent,
								inputProps: {
									decimalScale: 2,
									fixedDecimalScale: true
								}
							}}
							value={commissions && commissions[1] ? commissions[1].amount : ''}
							onChange={(event) => {
								this.handleChangeBase(`commissionsAmount0`, event, formProps);
							}}
							onBlur={formProps.handleBlur}
							className={clsx(classes.textField, {
								error: getIn(formProps.errors, `commissionsAmount0`) && getIn(formProps.touched, `commissionsAmount0`)
							})}
							fullWidth
						/>
					</Paper>
				</Grid>

				<Grid item xs={4}>
					<Paper className={classes.paperGrid}>
						<TextField
							id={`commissionsDescription0`}
							label="Description"
							variant="filled"
							fullWidth
							value={commissions && commissions[1] ? commissions[1].description : ''}
							onChange={(value) => {
								this.handleChangeBase(`commissionsDescription0`, value, formProps);
							}}
							onBlur={formProps.handleBlur}
							className={clsx(classes.textField, {
								error: getIn(formProps.errors, `commissionsDescription0`) && getIn(formProps.touched, `commissionsDescription0`)
							})}
						/>
					</Paper>
				</Grid>
				</Grid>
			</Grid>
		);
	}
}

commissionComponent.propTypes = {
	formProps: PropTypes.object.isRequired
};

export default withStyles(PayrollStyle)(commissionComponent);
