import React from "react";
import clsx from "clsx";
import { Button, Divider, Typography, Grid } from "@material-ui/core";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import DialogModalCommonComponent from "../common/dialogModalCommonComponent";
import DialogActionsCommonComponent from "../common/dialogActionsCommonComponent";
import DialogContentCommonComponent from "../common/dialogContentCommonComponent";
import DialogTitleCommonComponent from "../common/dialogTitleCommonComponent";

class DialogCommonComponentNew extends React.Component {
	render() {
		const {
			title, subtitle, children, size, open, onCloseModal, showDivider, payslipDialog, background2FA, primaryButtonName, onPrimaryButtonClick,
			primaryButtonDisabled, secondaryButtonName, onSecondaryButtonClick, showScroll, stpSummary, hideButton, style, style2,
		} = this.props;

		return (
			<div>
				<DialogModalCommonComponent onClose={onCloseModal} aria-labelledby={title} open={open} fullWidth maxWidth={size}>
					<DialogTitleCommonComponent id={title} onClose={onCloseModal}>
						<Grid container justify="center">
							{title}
						</Grid>
						<Typography aria-labelledby={subtitle} className="subtitle">
							{subtitle}
						</Typography>
					</DialogTitleCommonComponent>
					{showDivider ? <Divider /> : null}
					<DialogContentCommonComponent
						className={clsx("", {
							divider: showDivider,
							background2FA,
							payslipDialog,
							showScroll
						})}
					>
						{children}
					</DialogContentCommonComponent>
					<DialogActionsCommonComponent
						className={clsx("", {
							background2FA
						})}
						style={{ padding: "20px 0px", justifyContent: "center" }}
					>
						{secondaryButtonName && onSecondaryButtonClick ? (
							<Button onClick={onSecondaryButtonClick} variant="outlined" color="default">
								{secondaryButtonName || "Close"}
							</Button>
						) : null}
						{!hideButton && <Button onClick={onPrimaryButtonClick} variant="contained" color="secondary" style={style} disabled={primaryButtonDisabled}>
							{style2 ? <span style={style2}>
								{primaryButtonName || "Save"}
							</span> : primaryButtonName || "Save"}
						</Button>}
					</DialogActionsCommonComponent>
				</DialogModalCommonComponent>
			</div>
		);
	}
}

DialogCommonComponentNew.propTypes = {
	stpSummary: PropTypes.object,
	hideButton: PropTypes.bool
};

const mapStateToProps = state => ({
	stpSummary: state.stp.reportSummary,
});

export default connect(
	mapStateToProps,
)(DialogCommonComponentNew);


