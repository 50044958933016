import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";

import { LoadingComponent } from "../components/common";
import SidebarComponent from "../components/home/sidebarComponent";
import TwoFactorAuthenticationComponent from "../components/setting/twoFactorAuthenticationComponent";
import { SessionUserStyles } from "./PagesStyle";

class TwoFactorAuthenticationPage extends Component {
	render() {
		const { classes, isLoading, mixpanel } = this.props;

		return (
			<div className={classes.root}>
				<CssBaseline />
				<SidebarComponent />
				<main className={classes.content}>
					<LoadingComponent isLoading={isLoading} />
					<div className={classes.appBarSpacer} />
					<TwoFactorAuthenticationComponent mixpanel={mixpanel} />
				</main>
			</div>
		);
	}
}

TwoFactorAuthenticationPage.propTypes = {
	classes: PropTypes.object.isRequired,
	isLoading: PropTypes.bool
};

const mapStateToProps = state => ({
	isLoading: state.employer.isLoading
});

export default connect(
	mapStateToProps,
	null
)(withStyles(SessionUserStyles)(TwoFactorAuthenticationPage));
