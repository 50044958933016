import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";

import * as routes from "../../constants/routes";
import { ZendeskScript } from "../../scripts";
import { getStatusSubscription } from "../../helpers/extensions";
import { Analytics } from "../common";

class AuthenticatedComponentOnboard extends Component {
	componentWillMount() {
		// ZendeskScript();
	}

	render() {
		ZendeskScript();
		const {
			component: InternalComponent, path, token
		} = this.props;

		if (token) {
			return <Route path={path} render={props => <InternalComponent {...props} />} />;
		}

		return <Redirect to={`${routes.PATH_SIGN_UP}`} />;
	}
}

AuthenticatedComponentOnboard.propTypes = {
	token: PropTypes.string.isRequired
};

const mapStateToProps = state => {
	return ({
		token: state.onboard.sessionToken,
	})
};

export default connect(
	mapStateToProps,
	null
)(AuthenticatedComponentOnboard);
