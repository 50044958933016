import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import {
	Grid, Paper, TextField, Typography
} from "@material-ui/core";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import { DialogCommonComponent } from "../common";
import AbnInformationComponent from "./abnInformationComponent";
import InvalidInformationComponent from "./invalidInformationComponent";
import employerStyle from "./employerStyle";
import { cleanMessage, clearAbnData, verifyAbn } from "../../actions/EmployerActions";
import { employerDefaultData } from "../../reducers/Employer/EmployerInitialState";
import mixpanel from "mixpanel-browser";
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

class ModalSetupABNComponent extends Component {
	state = {
		textAbn: "",
		userTradingName: "",
		confirmationChecked: false
	};

	componentWillReceiveProps = (nextProps) => {
		const { formProps } = this.props;

		console.log("next props");
		console.log(nextProps);

		let abnInfo = nextProps.abnInfo;
		if (!abnInfo && nextProps.formProps.values.company.abn) {
			abnInfo = nextProps.formProps.values.company.abn;
		}

		console.log(abnInfo);
		if (abnInfo && abnInfo.number != "" && (abnInfo.number !== formProps.values.company.abn.number || this.state.textAbn == "")) {
			const valueABN = {
				...employerDefaultData.company.abn,
				...abnInfo
			};
			formProps.setFieldValue("company.abn", valueABN);
			this.setState({ textAbn: valueABN.number });
		}
		if ((formProps.values.company.abn.userTradingName === '' || formProps.values.company.abn.userTradingName === 'undefined') && this.state.userTradingName !== '') {
			formProps.setFieldValue('company.abn.userTradingName', this.state.userTradingName)
		} else {
			this.setState({
				userTradingName: formProps.values.company.abn.userTradingName
			})
		}
	};

	handleOnChangeText = async (e) => {
		const { formProps, clearAbn, CleanMessage } = this.props;
		// formProps.setFieldValue("company.abn", {});
		// clearAbn();
		// CleanMessage();

		this.setState({ textAbn: e.target.value });
		if (e.target.value.length >= 11) {
			const { VerifyAbn } = this.props;
			const verifyAbnDebounced = AwesomeDebouncePromise(VerifyAbn, 600);
			await verifyAbnDebounced(e.target.value);
		}
	};

	handleClose = () => {
		console.log("cloning");
		const { formProps, CleanMessage } = this.props;
		if (!this.props.readonly) {
			formProps.setFieldValue("company.abn", {});
			this.setState({ textAbn: "" });
		}
		CleanMessage();
	};

	onPrimaryButtonClick = () => {
		const { onPrimaryButtonClick, clearAbn } = this.props;
		if (!this.props.readonly) {
			clearAbn();
			this.setState({ textAbn: "" });
		}
		onPrimaryButtonClick();
	};

	onCloseModal = () => {
		const { onCloseModal, clearAbn, formProps } = this.props;
		mixpanel.track("CancelABN.Action");
		if (!this.props.readonly) {
			formProps.setFieldValue("company.abn", {});
			this.setState({ textAbn: "" });

			clearAbn();
		}
		onCloseModal();
	};

	handleCheckConfirmation = () => {
		this.setState({ confirmationChecked: !this.state.confirmationChecked });
	};

	render() {
		const {
			classes, open, formProps, isLoading
		} = this.props;
		const { textAbn, confirmationChecked } = this.state;

		const ABNData = formProps.values.company.abn;

		return (
			<DialogCommonComponent title="Setup ABN" size="md" onPrimaryButtonClick={this.onPrimaryButtonClick} open={open} onCloseModal={this.onCloseModal} primaryButtonDisabled={isLoading || !this.state.confirmationChecked}>
				<Typography variant="body1" gutterBottom className={classes.description}>
					{"Provide your ABN details"}
				</Typography>
				<Grid container direction="row" alignItems="center">
					<Grid item xs={6}>
						<Paper className={classes.paperGrid}>
							<TextField disabled={this.props.readonly} id="company.abn.name" label="Enter your company ABN" className={classes.textField} variant="filled" value={textAbn} fullWidth onChange={this.handleOnChangeText} />
						</Paper>
					</Grid>
					<Grid item xs={6}>
						<Paper
							className={clsx(classes.paperGrid, {
								[classes.paperGridNotRight]: true
							})}
						>
							<TextField id="company.abn.userTradingName" label="Trading Name (If applicable)" className={classes.textField} variant="filled" value={formProps.values.company.abn.userTradingName} onChange={formProps.handleChange} onBlur={formProps.handleBlur} fullWidth />
						</Paper>
					</Grid>
				</Grid>
				<AbnInformationComponent readonly={this.props.readonly} border isPopup formProps={formProps} onClose={this.handleClose} />
				<InvalidInformationComponent border formProps={formProps} onClose={this.handleClose} />
				<br />
				{
					ABNData.name &&
						<Paper className={classes.paperContainer}>
							<Grid container alignItems="center">
								<Grid item xs={1}>
									<Checkbox
										style={{marginLeft: -10}}
										checkedIcon={<CheckBoxIcon fontSize={"large"} />}
										icon={<CheckBoxOutlineBlankIcon fontSize={"large"} />}
										checked={confirmationChecked}
										onChange={this.handleCheckConfirmation}
										inputProps={{ 'aria-label': 'primary checkbox', fontSize: 50 }}
									/>
								</Grid>
								<Grid item xs={11}>
									<Typography variant="body1">
										I confirm that I am authorised to set up this account and lodge Single Touch Payroll files on behalf of {ABNData.name}.
										<br /><br />
										Please be aware that the ATO may contact you directly regarding any STP files lodged.
									</Typography>
								</Grid>
							</Grid>
						</Paper>
				}
			</DialogCommonComponent>
		);
	}
}

const mapStateToProps = state => ({
	abnInfo: state.employer.abnInfo,
	isLoading: state.employer.isLoading
});

const mapDispatchToProps = dispatch => ({
	VerifyAbn: bindActionCreators(verifyAbn, dispatch),
	clearAbn: bindActionCreators(clearAbnData, dispatch),
	CleanMessage: bindActionCreators(cleanMessage, dispatch)
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(employerStyle)(ModalSetupABNComponent));
