import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import PropTypes from "prop-types";

import { Formik } from "formik";
import * as Yup from "yup";

import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import {
	Box, Typography, Grid, Paper, TextField, Icon
} from "@material-ui/core";

import PayrollStyle from "./payrollStyle";
import lockImage from "../../assets/images/lock.png";

import * as stpActions from "../../actions/STPActions";

import DialogCommonComponent from "./dialogCommonComponent";

class ModalSTPDeclaration extends Component {
	state = {
		openCongratulation: false,
		showPopup: false
	};

	validationSchema = Yup.object({
		signature: Yup.string().min(3)
			.required("Signature is required")
		// .test("match", "Signature not is valid.", (signature) => {
		// 	const { employer } = this.props;

		// 	return employer.company.authorizedPerson.fullName.toLowerCase() === signature.toLowerCase();
		// })
	});

	handleSubmitSTP = (value, actions) => {
		const { sendSTP, stpSummary, history, summary } = this.props;
		actions.setSubmitting(false);

		// const valuesSend = {
		// 	id: stpSummary.id,
		// 	authorizedName: value.signature
		// };
		const valuesSend = {
			id: summary.id,
			authorizedName: value.signature
		};

		sendSTP(valuesSend, history);
		this.setState({ showPopup: true });
	};

	redirectToCompletedTab = () => {
		const { redirectToCompletedTab } = this.props;
		this.setState({ openCongratulation: false });
		redirectToCompletedTab();
	};

	componentWillReceiveProps = (nextProps) => {
		const { isSendToSTP } = nextProps;
		const { showPopup } = this.state;

		if (isSendToSTP && showPopup) {
			this.setState({ openCongratulation: true, showPopup: false });
		}
	};

	renderForm = (formProps) => {
		const {
			classes, open, onCloseModal, employer
		} = this.props;
		const { openCongratulation } = this.state;

		return (
			<DialogCommonComponent title="STP Declaration"
				style2={{ textAlign: 'center', letterSpacing: 0, color: "#ffffff", opacity: 1, fontSize: "15px", fontWeight: "bold", fontFamily: 'AvenirNextLTPro-Demi' }}
				style={{ borderRadius: 5 }}
				size="lg" showDivider primaryButtonName="Lodge STP with ATO" onPrimaryButtonClick={formProps.handleSubmit} open={open} onCloseModal={onCloseModal} primaryButtonDisabled={!formProps.isValid}>
				<Grid container direction="row" className={classes.signatureContainer}>
					<Grid item xs={12}>
						<Typography variant="body1" gutterBottom className={classes.centerText}>
							{/* {"EASY PAYSLIP PTY LTD"} */}
							{employer.company.abn.name ? employer.company.abn.name : employer.companyName}
							<br />
							{`${employer.company.address.addressLine1}, ${employer.company.address.townCity}, ${employer.company.address.state} ${employer.company.address.postCode}`}
							{/* {"Suite 7/701/ Military Road, Mosman NSW 2088"} */}
							<br />
							<br />
							{`I, ${employer.company.authorizedPerson.fullName}, declare the information transmitted in this payroll report is true and correct and I am authorised to make this declaration`}
							<br />
							<br />
							{"By entering my name below and clicking the 'Lodge STP File with ATO' button, I authorise lodgement with Easy Payslip's approved sending service provider's Auskey."}
						</Typography>
					</Grid>
					<Box m={2} />
					<Grid container direction="row" alignItems="center" justify="center">
						<Grid item xs={2}>
							<Typography variant="body1" className={classes.centerText}>
								{"Signature"}
							</Typography>
						</Grid>
						<Grid item xs={3}>
							<Paper className={clsx(classes.paperGridNotPadding)}>
								<form onSubmit={formProps.handleSubmit}>
									<TextField
										id="signature"
										label="Type your name here"
										className={clsx(classes.textField, {
											error: formProps.errors.signature && formProps.touched.signature
										})}
										variant="filled"
										fullWidth
										value={formProps.values.signature}
										onChange={formProps.handleChange}
										onBlur={formProps.handleBlur}
									/>
								</form>
							</Paper>
						</Grid>
						<Grid item xs={1}>
							{!formProps.isValid ? <img className={classes.iconInTop} src={lockImage} alt="" /> : <Icon className={clsx(classes.checkIcon, { active: formProps.isValid })}>{formProps.isValid ? "check_circle" : "cancel"}</Icon>}
						</Grid>
					</Grid>
				</Grid>

				{openCongratulation ? (
					<DialogCommonComponent title="Send to STP" size="xs" open={openCongratulation} onCloseModal={this.redirectToCompletedTab} primaryButtonName="Ok"
						onPrimaryButtonClick={this.redirectToCompletedTab}>
						<Typography variant="h2">STP file sent.</Typography>
					</DialogCommonComponent>
				) : null}
			</DialogCommonComponent>
		);
	};

	render() {
		return <Formik initialValues={{ signature: "" }} validationSchema={this.validationSchema} onSubmit={this.handleSubmitSTP} render={this.renderForm} enableReinitialize />;
	}
}

ModalSTPDeclaration.propTypes = {
	employer: PropTypes.object,
	stpSummary: PropTypes.object,
	sendSTP: PropTypes.func,
	redirectToCompletedTab: PropTypes.func,
	isSendToSTP: PropTypes.bool
};

const mapStateToProps = state => ({
	employer: state.session.userData,
	stpSummary: state.stp.reportSummary,
	isSendToSTP: state.stp.isSendToSTP
});

const mapDispatchToProps = dispatch => ({
	sendSTP: bindActionCreators(stpActions.sendSTPRequest, dispatch)
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(withStyles(PayrollStyle)(ModalSTPDeclaration)));
