import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";

import { LoadingComponent } from "../components/common";
import ResetPasswordComponent from "../components/Session/ResetPasswordComponent";
import { ExternaUserStyles } from "./PagesStyle";

class ResetPasswordPage extends Component {
	render() {
		const { classes, isLoading, mixpanel } = this.props;
		return (
			<div className={classes.root}>
				<LoadingComponent isLoading={isLoading} />
				<CssBaseline />
				<ResetPasswordComponent mixpanel={mixpanel} />
			</div>
		);
	}
}

ResetPasswordPage.propTypes = {
	classes: PropTypes.object.isRequired,
	isLoading: PropTypes.bool
};

const mapStateToProps = state => ({
	isLoading: state.external.isLoading || state.session.isLoading
});

export default connect(
	mapStateToProps,
	null
)(withStyles(ExternaUserStyles)(ResetPasswordPage));
