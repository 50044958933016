import * as actionTypes from "../../constants/actionTypes";
import notificationInitialState from "./NotificationInitialState";

const notificationReducer = (state = notificationInitialState, action) => {
	switch (action.type) {
		case actionTypes.ACTION_NOTIFICATION_GET_REQUEST: {
			return {
				...state,
				isLoading: true,
				isUpdate: action.payload.isUpdate
			};
		}
		case actionTypes.ACTION_NOTIFICATION_GET_REQUEST_SUCCESS: {
			return {
				...state,
				isLoading: false,
				notificationData: action.payload.notificationData,
				messageCode: action.payload.messageCode,
				message: action.payload.message,
				isUpdate: action.payload.isUpdate
			};
		}
		case actionTypes.ACTION_NOTIFICATION_GET_REQUEST_FAILURE: {
			return {
				...state,
				error: true,
				isLoading: false,
				errorMessage: action.payload.errorMessage,
				messageCode: action.payload.messageCode,
				message: action.payload.message,
				isUpdate: action.payload.isUpdate
			};
		}
		case actionTypes.ACTION_NOTIFICATION_UPDATE_REQUEST: {
			return {
				...state,
				isLoading: true,
				notificationData: action.payload.notificationData,
				isUpdate: action.payload.isUpdate
			};
		}
		case actionTypes.ACTION_NOTIFICATION_UPDATE_REQUEST_SUCCESS: {
			return {
				...state,
				isLoading: false,
				notificationData: action.payload.notificationData,
				messageCode: action.payload.messageCode,
				message: action.payload.message,
				isUpdate: action.payload.isUpdate
			};
		}
		case actionTypes.ACTION_NOTIFICATION_UPDATE_REQUEST_FAILURE: {
			return {
				...state,
				error: true,
				isLoading: false,
				errorMessage: action.payload.errorMessage,
				messageCode: action.payload.messageCode,
				message: action.payload.message,
				isUpdate: action.payload.isUpdate
			};
		}
		case actionTypes.ACTION_NOTIFICATION_CLEAN_MESSAGE: {
			return {
				...state,
				error: false,
				isLoading: false,
				errorMessage: action.payload.errorMessage,
				messageCode: action.payload.messageCode,
				message: action.payload.message
			};
		}
		case actionTypes.ACTION_SESSION_LOGOUT: {
			return notificationInitialState;
		}
		default: {
			return {
				...state
			};
		}
	}
};

export default notificationReducer;
