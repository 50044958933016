import initialState from "../../store/initialState";

const stpInitialState = {
	...initialState,
	errorMessage: "",
	message: "",
	reportSummary: null
};

export default stpInitialState;
