const getNotification = async (httpClient) => {
	const { goEpsApi } = window.envVariables;
	const response = await httpClient.get(`${goEpsApi}/api/v1/notification`);
	return response.data;
};

const updateNotification = async (httpClient, requestData) => {
	const { goEpsApi } = window.envVariables;
	const response = await httpClient.put(`${goEpsApi}/api/v1/notification`, requestData);
	return response.data;
};

export default {
	getNotification,
	updateNotification
};
