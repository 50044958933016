import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import {
	Button, Card, CardHeader, CardContent, CardActions, Typography
} from "@material-ui/core";

import StripeCheckout from "react-stripe-checkout";
import { PlanItemStyle } from "./subscriptionStyle";

import * as subscriptionActions from "../../actions/SubscriptionActions";
import * as routes from "../../constants/routes";
import * as images from "../../constants/imagesPublic";
import * as subscriptionStatus from "../../constants/subscriptionStatus";
import { DialogCommonComponent, Analytics, SnackbarCommonComponent } from "../common";
import clsx from "clsx";

class PlanItemComponent extends Component {
	state = {
		openCongratulation: false,
		showPopup: false
	};

	onToken = (token) => {
		const { activateSubscription, plan, employer } = this.props;

		activateSubscription(token, plan.id, null, employer.subscription.status === subscriptionStatus.SUBSCRIPTION_STATUS_ACTIVE);
		this.setState({ showPopup: true });
	};

	componentWillReceiveProps = (nextProps) => {
		const { subscriptionData, error, errorMessage, success, message, isLoading } = nextProps;
		const { showPopup } = this.state;
		if (subscriptionData && showPopup && !error && !isLoading) {
			this.setState({ openCongratulation: true, showPopup: false });
		}

		console.log("props error", error, isLoading);

		this.setState({ error, errorMessage, success, message });

		if (error || success) {
			setTimeout(() => {
				this.handleMessageClose();
			}, 6000);
		}
	};

	redirectToSubscription = () => {
		const { history } = this.props;
		this.setState({ openCongratulation: false });

		history.push(routes.PATH_SUBSCRIPTION);
	};

	handleMessageClose = () => {
		this.setState({ error: false, success: false });
	};

	handleCancel = () => {
		const { cancelSubscription, history } = this.props;
		cancelSubscription(history);
		Analytics.CancelSubscriptionAction();
	};

	render() {
		const {
			classes, plan, employer, quantityEmployees, coupon
		} = this.props;
		const { openCongratulation, error, errorMessage, success, message } = this.state;
		const { stripe } = window.envVariables;
		let button;

		if (employer.subscription.status == subscriptionStatus.SUBSCRIPTION_STATUS_CANCELED || employer.subscription.typeSubscription != 2) {
			button = (
				<StripeCheckout token={this.onToken}
					stripeKey={stripe.publishableKey}
					name="Easy Payslip Subscription"
					description={plan.name}
					email={employer.login.email}
					image={images.LOGO_PUBLIC}
					panelLabel={coupon && coupon.percentageOff && ((plan.amount - ((plan.amount * coupon.percentageOff) / 100))) == 0 ? "Pay $0.00" : "Pay"}
					amount={coupon && coupon.percentageOff ? ((plan.amount - ((plan.amount * coupon.percentageOff) / 100))) : plan.amount}
					currency={plan.currency.toUpperCase()}
					locale="auto">
					<Button
						variant="contained"
						color="secondary"
						className={classes.cardButton}
						disabled={employer.subscription.typeSubscription == 1 && employer.subscription.status == subscriptionStatus.SUBSCRIPTION_STATUS_ACTIVE}
					>
						Purchase now
					</Button>
				</StripeCheckout>
			);
		} else if (employer.subscription.planId !== plan.id) {
			if (quantityEmployees > plan.maxEmployees) {
				button = (
					<Button variant="contained" color="secondary" className={classes.cardButton} disabled>
						Change
					</Button>
				);
			} else {
				button = (
					<StripeCheckout
						token={this.onToken}
						stripeKey={stripe.publishableKey}
						name="Easy Payslip Subscription"
						description={plan.name}
						email={employer.login.email}
						image={images.LOGO_PUBLIC}
						amount={coupon && coupon.percentageOff ? ((plan.amount - ((plan.amount * coupon.percentageOff) / 100))) : plan.amount}
						currency={plan.currency.toUpperCase()}
						locale="auto">
						<Button variant="contained" color="secondary" className={classes.cardButton}
							disabled={employer.subscription.typeSubscription == 1 && employer.subscription.status == subscriptionStatus.SUBSCRIPTION_STATUS_ACTIVE}
						>
							Change
						</Button>
					</StripeCheckout>
				);
			}
		} else {
			button = (
				<Button
					variant="outlined"
					color="secondary"
					className={classes.cardButton}
					onClick={this.handleCancel}
					disabled={(employer.subscription.typeSubscription == 1 && employer.subscription.status == subscriptionStatus.SUBSCRIPTION_STATUS_ACTIVE)}>
					Cancel
				</Button>
			);
		}

		return (
			<>
			<Card className={classes.card}>
				<CardHeader className={classes.cardHeader} title={plan.name} classes={{ title: classes.cardHeaderTitle }} />
				<CardContent className={classes.cardContent}>
					<Typography variant="h1" className={clsx(coupon && coupon.percentageOff && "plnCardPriceOff", classes.cardPrice, coupon && coupon.percentageOff && classes.plnCardPriceOff)} gutterBottom>
						{`$ ${(plan.amount / 100).toFixed(2)}`}
					</Typography>
					{coupon && coupon.percentageOff ? <Typography variant="h1" className={classes.cardPrice, classes.cardPriceOff} gutterBottom>
						{`$ ${((plan.amount - ((plan.amount * coupon.percentageOff) / 100)) / 100).toFixed(2)}`}
					</Typography>
						: null
					}
					<Typography variant="h5" className="gray">
						{coupon && coupon.duration ? coupon.duration : "Per Month"}
					</Typography>
					{button}
				</CardContent>
				<CardActions className={classes.cardActions} />

				{openCongratulation ? (
					<DialogCommonComponent
						title="Subscription"
						size="xs"
						open={openCongratulation}
						onCloseModal={this.redirectToSubscription}
						primaryButtonName="Ok"
						onPrimaryButtonClick={this.redirectToSubscription}>
						<Typography variant="h2">Thank you! Your Subscription is now active.</Typography>
					</DialogCommonComponent>
				) : null}
			</Card>
			{error ? <SnackbarCommonComponent onCloseModal={this.handleMessageClose} variant="error" open={error} body={errorMessage} /> : null}
			{success ? <SnackbarCommonComponent onCloseModal={this.handleMessageClose} variant="success" open={success} body={message} /> : null}
			</>
		);
	}
}

PlanItemComponent.propTypes = {
	activateSubscription: PropTypes.func,
	cancelSubscription: PropTypes.func,
	coupon: PropTypes.object,
};

const mapStateToProps = state => ({
	employer: state.session.userData,
	subscriptionData: state.subscription.subscriptionData,
	error: state.subscription.error,
	errorMessage: state.subscription.errorMessage,
	success: state.subscription.success,
	message: state.subscription.message,
	isLoading: state.subscription.isLoading,
});

const mapDispatchToProps = dispatch => ({
	activateSubscription: bindActionCreators(subscriptionActions.activateSubscription, dispatch),
	cancelSubscription: bindActionCreators(subscriptionActions.cancelSubscription, dispatch)
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(withStyles(PlanItemStyle)(PlanItemComponent)));
