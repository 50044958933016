import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";

import SidebarComponent from "../components/home/sidebarComponent";
import SingleTouchComponent from "../components/singleTouch/singleTouchComponent";
import { SessionUserStyles } from "./PagesStyle";
import { LoadingComponent } from "../components/common";

class SingleTouchPage extends Component {
	render() {
		const { classes, isLoading, mixpanel } = this.props;

		return (
			<div className={classes.root}>
				<CssBaseline />
				<SidebarComponent />
				<main className={classes.content}>
					<LoadingComponent isLoading={isLoading} />
					<div className={classes.appBarSpacer} />
					<SingleTouchComponent mixpanel={mixpanel} />
				</main>
			</div>
		);
	}
}

SingleTouchPage.propTypes = {
	classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	isLoading: state.singleTouch.isLoading || state.stp.isLoading,
});

export default connect(mapStateToProps, null)(withStyles(SessionUserStyles)(SingleTouchPage));
