import React, { Component } from "react";
import { connect } from "react-redux";

import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

import SettingStyle from "./settingStyle";

import { DialogConfirmCommonComponent } from "../common";
import { bindActionCreators } from "redux";
import { sendCloseYFRequest } from "./../../actions/YECPActions";

class ModalNewCloseFYComponent extends Component {
  state = {
    showAuthorizedModal: false
  };

  handleOpenAuthorizedModal = () => {
    const {
      sendCloseFY,
      yecp,
      onCloseModal,
      hasLodged,
      showCloseFYModalPDF
    } = this.props;
    let requestData = { name: yecp };
    sendCloseFY(requestData);
    if (hasLodged === false || hasLodged === true) {
      showCloseFYModalPDF(true);
      onCloseModal();
    }
  };
  componentWillReceiveProps = nextProps => {
    const { onCloseModal, showCloseFYModalPDF } = this.props;
    const { hasLodged } = nextProps;
    if (hasLodged === false || hasLodged === true) {
      showCloseFYModalPDF(true);
      onCloseModal();
    }
  };

  render() {
    const { classes, open, onCloseModal, yecp } = this.props;
    return (
      <div>
        <DialogConfirmCommonComponent
          open={open}
          title={`${yecp} Final STP File`}
          styleTitle={{
            textAlign: "left",
            fontFamily: "AvenirNextLTPro-Demi",
            letterSpacing: 0,
            color: "#000000",
            opacity: 1,
            fontSize: 17
          }}
          primaryButtonName="Proceed"
          secondaryButtonName="any"
          onCloseModal={onCloseModal}
          style={{ borderRadius: 5, height: 48, minWidth: 200 }}
          style2={{
            fontFamily: "AvenirNextLTPro-Demi",
            fontSize: "18px"
          }}
          onPrimaryButtonClick={this.handleOpenAuthorizedModal}
        >
          <p
            className={classes.textModal}
          >{`The '${yecp} Final STP File' enables you to: `}</p>
          <p className={classes.paragraphModal}>
            <span className={classes.textcircle}>• </span>
            <span
              className={classes.textModal1}
            >{`Make any adjustments to employee amounts and lodge the Final ${yecp} STP File for all employees or for selected employees only`}</span>
          </p>
          <p className={classes.paragraphModal}>
            <span className={classes.textcircle}>• </span>
            <span
              className={classes.textModal1}
            >{`Relodge the Final ${yecp} STP File to account for any changes to employee amounts that have previously been lodged `}</span>
          </p>

          <p className={classes.paragraphModal}>
            <span className={classes.textcircle}>• </span>
            <span
              className={classes.textModal1}
            >{`Lodge and/or Relodge separate ${yecp} Final STP Files for employees not previously included`}</span>
          </p>
          <p
            className={classes.textModal}
          >{`This will update the Employees payment details with the ATO and enable them to complete their ${yecp} individual tax return.`}</p>
        </DialogConfirmCommonComponent>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  hasLodged: state.yecp.closeFY.hasLodged
});
const mapDispatchToProps = dispatch => ({
  sendCloseFY: bindActionCreators(sendCloseYFRequest, dispatch)
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(SettingStyle)(ModalNewCloseFYComponent)));
