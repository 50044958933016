import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { Avatar, Icon, Typography } from "@material-ui/core";

import { AvatarStyles } from "./commonStyles";

import { uploadImageRequest } from "../../actions/EmployeeActions";
import { employerUpdate } from "../../actions/EmployerActions";

class AvatarCommonComponent extends Component {
	handleOpenFileClick = () => {
		this.imageInput.click();
	};

	handleFileInputOnChange = (event) => {
		const {
			employer, employerId, employeeId, uploadEmployeeImage, uploadEmployerImage
		} = this.props;

		if (event.target.files && event.target.files.length > 0) {
			const file = event.target.files[0];
			const fileName = file.name;
			const fileReader = new FileReader();

			fileReader.onload = () => {
				const arrayBuffer = fileReader.result;
				this.setState({ src: arrayBuffer });
				const array = arrayBuffer.replace(`data:${file.type};base64,`, "");

				if (employeeId) {
					uploadEmployeeImage(employeeId, fileName, array);
				} else if (employerId) {
					uploadEmployerImage({
						...employer,
						id: employerId,
						company: {
							...employer.company,
							imageUpload: { name: fileName, image: array }
						}
					});
				}
			};
			fileReader.readAsDataURL(event.target.files[0]);
		}
	};

	render() {
		const {
			classes, src, hover, text, isBig, isAct
		} = this.props;

		return (
			<div
				className={clsx(classes.avatarParent, {
					hoverParent: hover
				})}
			>
				<Avatar src={src} className={clsx("", { [classes.bigAvatar]: isBig, [classes.actAvatar]: isAct })}>
					{text}
				</Avatar>
				{hover ? (
					<div
						role="button"
						tabIndex="-1"
						className={clsx(classes.avatarOverlay, {
							hoverChildren: hover
						})}
						onClick={this.handleOpenFileClick}
					>
						<Icon className={classes.avatarOverlayIcon}> camera_alt </Icon>
						<Typography variant="caption" gutterBottom className={classes.avatarOverlayText}>
							{"Change Image"}
						</Typography>
						<input
							type="file"
							id="imageInput"
							accept="image/*"
							ref={(component) => {
								this.imageInput = component;
							}}
							style={{ display: "none" }}
							onChange={this.handleFileInputOnChange}
						/>
					</div>
				) : null}
			</div>
		);
	}
}

AvatarCommonComponent.propTypes = {
	classes: PropTypes.object.isRequired,
	hover: PropTypes.bool,
	src: PropTypes.string,
	text: PropTypes.string,
	isBig: PropTypes.bool,
	isAct: PropTypes.bool,

	employerId: PropTypes.string,
	employeeId: PropTypes.string
};

const mapStateToProps = state => ({
	employer: state.employer.employerData
});

const mapDispatchToProps = dispatch => ({
	uploadEmployeeImage: bindActionCreators(uploadImageRequest, dispatch),
	uploadEmployerImage: bindActionCreators(employerUpdate, dispatch)
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(AvatarStyles)(AvatarCommonComponent));
