import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Formik, getIn } from "formik";
import * as Yup from "yup";
import DeleteIcon from '@material-ui/icons/Delete';
import { withStyles } from "@material-ui/core/styles";

import {
	Grid, TableCell, TableRow, Paper, TextField, InputAdornment
} from "@material-ui/core";
import PayrollStyle from "./payrollStyle";
import clsx from "clsx";
import * as parameterTypes from "../../constants/parameterTypes";

import { NumberFormatCustomComponent } from "../common";

class editAdditionalBaseHourlyRateComponent extends Component {
	validationSchema = Yup.object({
		name: Yup.string()
			.required("Selected Additional base rate")
			.test("name-default", "Selected Additional base rate", value => value !== "-"),
		rate: Yup.number().required("Rate is required"),
		hours: Yup.number().required("Quantity is required"),
		totalAmount: Yup.number().required("Total is required")
	});
	handleChangeValues = (index, name, value) => {
		let { parentProps } = this.props;
		const { additionals } = parentProps.values.payroll;
		switch (name) {
			case "name":
				additionals[index].description = value;
				break;
			case "hours":
				additionals[index].totalAmount = additionals[index].rate * value
				additionals[index].hours = value
				break;
			case "rate":
				additionals[index].totalAmount = additionals[index].hours * value
				additionals[index].rate = value
				break;
			default:
				break;
		}
		parentProps.setFieldValue("payroll.additionals", additionals);
	};
	renderForm = (formProps) => {
		const {
			classes, row, index, handleRemoveItem
		} = this.props;


		return (
			<TableRow key={row.name} className={classes.tableRow, classes.tableCustompayroll}>
				<TableCell className={classes.tableText, classes.tableCellHover}>

					<TextField
						id="name"
						defaultValue={row.description ? row.description : row.name}
						onChange={(value) => {
							this.handleChangeValues(index, "name", value.target.value)
						}}
						onBlur={formProps.handleBlur}
						className={clsx('tableCellHover',classes.textFieldCustomPayroll, {
							error: getIn(formProps.errors, "name") && getIn(formProps.touched, "name")
						})}
					/>

				</TableCell>
				<TableCell className={classes.tableText, classes.tableCellHover}>
					<TextField
						id="hours"
						defaultValue={row.hours}
						fullWidth
						onBlur={formProps.handleBlur}
						onChange={(value) => {
							this.handleChangeValues(index, "hours", value.target.value)
						}}
						onBlur={formProps.handleBlur}
						InputProps={{
							inputComponent: NumberFormatCustomComponent,
							inputProps: {
								decimalScale: 2,
								fixedDecimalScale: true,
							}
						}}
						className={clsx('tableCellHover',classes.textFieldCustomPayroll, {
							error: getIn(formProps.errors, "hours") && getIn(formProps.touched, "hours")
						})}
					/>
				</TableCell>
				<TableCell className={classes.tableText, classes.tableCellHover}  >
					<TextField
						id="rate"
						fullWidth
						value={row.rate}
						onBlur={formProps.handleBlur}
						onChange={(value) => {
							this.handleChangeValues(index, "rate", value.target.value)
						}}
						onBlur={formProps.handleBlur}
						InputProps={{
							startAdornment: <InputAdornment position="start">$</InputAdornment>,
							inputComponent: NumberFormatCustomComponent,
							inputProps: {
								decimalScale: 2,
								fixedDecimalScale: true,

							}

						}}
						className={clsx('tableCellHover', classes.textFieldCustomPayroll,classes.textField,{
							error: getIn(formProps.errors, "rate") && getIn(formProps.touched, "rate")
						})}
					/>
				</TableCell>
				<TableCell className={classes.tableText, classes.tableCellHover}>

					<TextField
						id="total"

						fullWidth
						InputProps={{
							startAdornment: <InputAdornment position="start">$</InputAdornment>,
							readOnly: true,
							inputComponent: NumberFormatCustomComponent,
							inputProps: {
								decimalScale: 2,
								fixedDecimalScale: true
							}
						}}
						value={row.totalAmount}
						onChange={formProps.handelChange}
						className={clsx(classes.textFieldCustomPayroll,{
							error: getIn(formProps.errors, "total") && getIn(formProps.touched, "total")
						})}
					/>


				</TableCell>
				<TableCell className={classes.tableText, classes.tableCellHoverDelete} align="right">
					<DeleteIcon
						fontSize="small"
						onClick={e => handleRemoveItem(e, index)}
						className={classes.textFieldCustomPayrollDelete}
					/>

				</TableCell>
			</TableRow>
		)
	};

	render() {
		const {
			data, row, index, handleRemoveItem, handelChange, parentProps
		} = this.props;
		const dataDefault = {
			...data,
			...row,
			index,
			handleRemoveItem,
			handelChange,
			...parentProps
		};
		return <Formik
			initialValues={{ ...dataDefault }}
			validationSchema={this.validationSchema}
			render={this.renderForm}
			enableReinitialize />;
	}
}

editAdditionalBaseHourlyRateComponent.propTypes = {
	handleRemoveItem: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
	payrollBaseRateAdditionalList: state.parameters.parameterByTypeData[parameterTypes.PAYROLL_BASE_RATE_ADDITIONAL.parameterType]
});

export default connect(
	mapStateToProps,
	null
)(withStyles(PayrollStyle)(editAdditionalBaseHourlyRateComponent));
