import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
	Grid, Paper, TextField, Typography, FormHelperText
} from "@material-ui/core";
import clsx from "clsx";
import { getIn } from "formik";
import EmployerStyles from "./employerStyle";

import { CardCommonComponent } from "../common";

class EmployerContactDetailsComponent extends Component {
	render() {
		const { classes, formProps, isAccountant } = this.props;

		return (
			<CardCommonComponent title="Employer details" complete={!getIn(formProps.errors, "contact")}>
				<Typography variant="body1" gutterBottom>
					{
						isAccountant ?
							"Enter contact details below" :
							"Enter your contact details below"
					}
				</Typography>
				<Grid container direction="row" alignItems="flex-start">
					<Grid item xs={4}>
						<Paper className={classes.paperGrid}>
							<TextField
								id="contact.firstName"
								label="First name *"
								variant="filled"
								value={formProps.values.contact.firstName}
								onChange={formProps.handleChange}
								onBlur={formProps.handleBlur}
								className={clsx(classes.textField, {
									error: getIn(formProps.errors, "contact.firstName") && getIn(formProps.touched, "contact.firstName")
								})}
								fullWidth
							/>
							{getIn(formProps.errors, "contact.firstName") && getIn(formProps.touched, "contact.firstName") && <FormHelperText error>{formProps.errors.contact.firstName}</FormHelperText>}
						</Paper>
					</Grid>
					<Grid item xs={4}>
						<Paper className={classes.paperGrid}>
							<TextField id="contact.middleName" label="Middle name (If applicable)" className={classes.textField} variant="filled" value={formProps.values.contact.middleName} onChange={formProps.handleChange} onBlur={formProps.handleBlur} fullWidth />
						</Paper>
					</Grid>
					<Grid item xs={4}>
						<Paper className={classes.paperGrid}>
							<TextField
								id="contact.lastName"
								label="Last name *"
								variant="filled"
								value={formProps.values.contact.lastName}
								onChange={formProps.handleChange}
								onBlur={formProps.handleBlur}
								className={clsx(classes.textField, {
									error: getIn(formProps.errors, "contact.lastName") && getIn(formProps.touched, "contact.lastName")
								})}
								fullWidth
							/>
							{getIn(formProps.errors, "contact.lastName") && getIn(formProps.touched, "contact.lastName") && <FormHelperText error>{formProps.errors.contact.lastName}</FormHelperText>}
						</Paper>
					</Grid>
					<Grid item xs={4}>
						<Paper className={classes.paperGrid}>
							<TextField
								id="contact.phoneNumber"
								label="Account contact phone number *"
								variant="filled"
								value={formProps.values.contact.phoneNumber}
								onChange={formProps.handleChange}
								onBlur={formProps.handleBlur}
								className={clsx(classes.textField, {
									error: getIn(formProps.errors, "contact.phoneNumber") && getIn(formProps.touched, "contact.phoneNumber")
								})}
								fullWidth
							/>
							{getIn(formProps.errors, "contact.phoneNumber") && getIn(formProps.touched, "contact.phoneNumber") && <FormHelperText error>{formProps.errors.contact.phoneNumber}</FormHelperText>}
						</Paper>
					</Grid>
					<Grid item xs={4}>
						<Paper className={classes.paperGrid}>
							<TextField
								id="contact.email"
								label="Email address *"
								variant="filled"
								type="email"
								value={formProps.values.login && formProps.values.login.email}
								InputProps={{
									readOnly: !isAccountant
								}}
								onChange={formProps.handleChange}
								onBlur={formProps.handleBlur}
								disabled={!isAccountant}
								className={clsx(classes.textField, {
									error: getIn(formProps.errors, "contact.email") && getIn(formProps.touched, "contact.email")
								})}
								fullWidth
							/>
						</Paper>
					</Grid>
				</Grid>
			</CardCommonComponent>
		);
	}
}

export default withStyles(EmployerStyles)(EmployerContactDetailsComponent);
