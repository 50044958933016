import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Box, Button, Grid, Paper, Typography } from "@material-ui/core";
import { Formik } from "formik";

import ValidationSchema from "./employeeValidationSchema";
import EmployeeStyles from "./employeeStyle";
import EmployeePersonalDetailsComponent from "./employeePersonalDetailsComponent";
import EmployeePayrollComponent from "./employeePayrollComponent";
import EmployeeEntitlementsComponent from "./employeeEntitlementsComponent";
import EmployeeTaxesComponent from "./employeeTaxesComponent";
import EmployeeSuperannuationComponent from "./employeeSuperannuationComponent";

import { AvatarCommonComponent } from "../common";
import * as employeeActions from "../../actions/EmployeeActions";
import * as parameterActions from "../../actions/ParameterActions";
import * as parameterTypes from "../../constants/parameterTypes";
import * as routes from "../../constants/routes";
import * as extensions from "../../helpers/extensions";
import { employeeDefaultData } from "../../reducers/Employees/EmployeeInitialState";
import { getLocalToUTC } from "../../helpers/dateextensions";
import clsx from "clsx";

class EditEmployeeComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isValidation: false,
      shouldRefreshValidation: true
    };
  }
  componentDidMount() {
    const { loadParamters, getEmployeeData, match } = this.props;
    loadParamters(
      parameterTypes.PARAMETER_STATES_AUSTRALIA.parameterType,
      parameterTypes.PARAMETER_STATES_AUSTRALIA.section,
      parameterTypes.PARAMETER_STATES_AUSTRALIA.typeSection
    );

    loadParamters(
      parameterTypes.PARAMETER_EMPLOYEE_TAX_RESIDENCY.parameterType,
      parameterTypes.PARAMETER_EMPLOYEE_TAX_RESIDENCY.section,
      parameterTypes.PARAMETER_EMPLOYEE_TAX_RESIDENCY.typeSection
    );

    loadParamters(
      parameterTypes.PARAMETER_EMPLOYEE_TAX_BAND.parameterType,
      parameterTypes.PARAMETER_EMPLOYEE_TAX_BAND.section,
      parameterTypes.PARAMETER_EMPLOYEE_TAX_BAND.typeSection
    );

    loadParamters(
      parameterTypes.PARAMETER_EMPLOYEE_CATEGORY.parameterType,
      parameterTypes.PARAMETER_EMPLOYEE_CATEGORY.section,
      parameterTypes.PARAMETER_EMPLOYEE_CATEGORY.typeSection
    );

    loadParamters(
      parameterTypes.PARAMETER_EMPLOYEE_STATUS.parameterType,
      parameterTypes.PARAMETER_EMPLOYEE_STATUS.section,
      parameterTypes.PARAMETER_EMPLOYEE_STATUS.typeSection
    );

    loadParamters(
      parameterTypes.PARAMETER_EMPLOYEE_PAYROLL_PAY_BASIS.parameterType,
      parameterTypes.PARAMETER_EMPLOYEE_PAYROLL_PAY_BASIS.section,
      parameterTypes.PARAMETER_EMPLOYEE_PAYROLL_PAY_BASIS.typeSection
    );

    loadParamters(
      parameterTypes.PARAMETER_EMPLOYEE_PAYROLL_PAY_FREQUENTLY.parameterType,
      parameterTypes.PARAMETER_EMPLOYEE_PAYROLL_PAY_FREQUENTLY.section,
      parameterTypes.PARAMETER_EMPLOYEE_PAYROLL_PAY_FREQUENTLY.typeSection
    );

    getEmployeeData(match.params.id);

    this.setState({shouldRefreshValidation: true});
  }

  handleSubmitForm = async (values, actions) => {
    const { saveEmployeeData, employerId, employeeId } = this.props;
    actions.setSubmitting(false);

    await saveEmployeeData(employerId, employeeId, { ...values });
  };

  handleFinishEmployeeClick = async formProps => {
    const {
      employerId,
      employeeId,
      finishEmployeeData,
      history,
      employeeData
    } = this.props;
    formProps.values.detail.dob = getLocalToUTC(formProps.values.detail.dob);
    formProps.values.payroll.employmentDate = getLocalToUTC(
      formProps.values.payroll.employmentDate
    );
    const finalEmployeeData = {
      ...formProps.values,
      temporal: false,
      active: employeeData.temporal ? true : employeeData.active
    };
    await finishEmployeeData(
      employerId,
      employeeId,
      finalEmployeeData,
      employeeDefaultData,
      history,
      routes.PATH_EMPLOYEE
    );
  };
  handleValidation = isValidation => {
    if (this.state.isValidation != isValidation) {
      this.setState({
        isValidation,
        shouldRefreshValidation: false
      });
    }
  };

  renderForm = formProps => {
    const { classes, employeeData, employeeId } = this.props;

    console.log('validation');
    console.log(this.state.isValidation);

    return (
      <div className={classes.root}>
        <Paper className={classes.paperContainer}>
          <Grid container wrap="nowrap" spacing={2} alignItems="center">
            <Grid item>
              {!employeeData.detail.initials ||
                employeeData.detail.initials.length <= 0 ? (
                  <AvatarCommonComponent
                    text="NE"
                    src={employeeData.detail.imageUrl}
                    employeeId={employeeId}
                    isBig
                    hover
                  />
                ) : (
                  <AvatarCommonComponent
                    text={employeeData.detail.initials}
                    src={employeeData.detail.imageUrl}
                    employeeId={employeeId}
                    isBig
                    hover
                  />
                )}
            </Grid>
            <Grid item xs>
              {!extensions.getEmployeeFullname(employeeData) ||
                extensions.getEmployeeFullname(employeeData).length <= 0 ? (
                  <Typography variant="h1">New Employee</Typography>
                ) : null}
              <Typography variant="h1">
                {extensions.getEmployeeFullname(employeeData)}
              </Typography>
              <Typography variant="h4" className={classes.subtitleHeader}>
                {extensions.getEmployeeJobTitle(employeeData)}
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12} className={classes.gridRight}>
            <Button
              variant="contained"
              color="secondary"
              disabled={!this.state.isValidation}
              className={clsx(classes.orangeRectangularBtn, { [classes.disabledButtonSecondary]: !this.state.isValidation })}
              onClick={() => this.handleFinishEmployeeClick(formProps)}
            >
              {"Finish"}
            </Button>
          </Grid>

          <Box m={2.5} />

          <EmployeePersonalDetailsComponent
            {...this.props}
            parentFormProps={formProps}
            handleValidation={this.handleValidation}
            edit={true}
            refreshValidation={this.state.shouldRefreshValidation}
          />

          <EmployeePayrollComponent
            {...this.props}
            parentFormProps={formProps}
            handleValidation={this.handleValidation}
            edit={true}
          />

          <EmployeeEntitlementsComponent
            {...this.props}
            parentFormProps={formProps}
          />

          <EmployeeTaxesComponent {...this.props} parentFormProps={formProps} />

          <EmployeeSuperannuationComponent
            {...this.props}
            parentFormProps={formProps}
            handleFinishEmployeeClick={this.handleFinishEmployeeClick}
          />

          <Grid item xs={12} className={classes.gridRight}>
            <Button
              variant="contained"
              color="secondary"
              disabled={!this.state.isValidation}
              className={clsx(classes.buttonFinish, classes.orangeRectangularBtn, { [classes.disabledButtonSecondary]: !this.state.isValidation })}
              onClick={() => this.handleFinishEmployeeClick(formProps)}
            >
              {"Finish"}
            </Button>
          </Grid>
        </Paper>
      </div>
    );
  };

  render() {
    const { employeeData } = this.props;

    if (employeeDefaultData.active) {
      this.setState({isValidation: true});
    }

    return (
      <Formik
        initialValues={{ ...employeeData }}
        validationSchema={ValidationSchema}
        onSubmit={this.handleSubmitForm}
        render={this.renderForm}
        enableReinitialize={true}
      />
    );
  }
}

EditEmployeeComponent.propTypes = {
  token: PropTypes.string.isRequired,
  employerId: PropTypes.string.isRequired,
  employeeId: PropTypes.string,
  employeeData: PropTypes.object,

  saveEmployeeData: PropTypes.func,
  updateTempFullname: PropTypes.func,
  updateTempJobTitle: PropTypes.func,
  loadParamters: PropTypes.func,
  finishEmployeeData: PropTypes.func
};

const mapStateToProps = state => ({
  token: state.session.token,
  employerId: state.session.userData.employerId,
  employeeId: state.employees.selectedEmployeeData.id,
  employeeData: state.employees.selectedEmployeeData
});

const mapDispatchToProps = dispatch => ({
  getEmployeeData: bindActionCreators(
    employeeActions.getEmployeeDataRequest,
    dispatch
  ),
  saveEmployeeData: bindActionCreators(
    employeeActions.saveEmployeeDataRequest,
    dispatch
  ),
  updateTempFullname: bindActionCreators(
    employeeActions.updateTempInformation,
    dispatch
  ),
  updateTempJobTitle: bindActionCreators(
    employeeActions.updateTempInformation,
    dispatch
  ),
  loadParamters: bindActionCreators(
    parameterActions.getParametersByTypeRequest,
    dispatch
  ),
  finishEmployeeData: bindActionCreators(
    employeeActions.finishEmployee,
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(EmployeeStyles)(EditEmployeeComponent)));
