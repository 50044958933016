import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter, Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import {
	Box, Button, Grid, FormControl
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Analytics } from "../common";
import * as routes from "../../constants/routes";
import quSeSuccess from "../../assets/images/quSeSuccess.svg";
import LogoEasyPayslip from "../../assets/images/LOGOEasyPayslipHorizontal.svg";
import { ExternalUserStyle } from "./sessionStyle";
import * as OnboardActions from "../../actions/OnboardActions";

class QuickSetupAccountantSuccessComponent extends Component {

	componentDidMount() {
		Analytics.OB_5AccountantSuccessScreen();
	}

	ViewMyAccount = () => {
		const { history } = this.props;
		Analytics.OB_5AccountantSuccessAction();
		history.push(routes.PATH_ACCOUNTS);
	}

	handleDoThisLater = () => {
		Analytics.OB_5LaterEmployeeAction();
	}

	render() {
		const { classes, businessData } = this.props;

		return (
			<Grid container className={classes.quickSetupRoot}>
				<Grid className={classes.quickSetupRoot} container alignItems="flex-start">
					<Grid container className={classes.rootContainer} style={{ padding: "30px" }}>
						<Grid xs={12}>
							<div className={classes.slideShow}>
								<div>
									<div className={classes.slideShowItem}>
										<div>
											<div>
												<Grid xl={6} lg={6} md={6} sm={6} >
													<img src={LogoEasyPayslip} alt="" />
												</Grid>
												<div className={classes.titleWrapper1}>
													<h1 className={classes.title}>
														Success!
                                               </h1>
													<label className={classes.subTitleOne} style={{ width: "260px", fontFamily: "AvenirNextLTPro-Regular", marginTop: 32 }}>
														Now you can access any

														accounts you’ve been

														invited to join
									        	</label>
												</div>
											</div>
										</div>
										<div style={{ textAlign: "center" }}>
											<div item >
												<img style={{ marginTop: 20, width: "184px", height: "184px" }} src={quSeSuccess} alt="" />
											</div>
											<div item >
												<FormControl fullWidth margin="normal">
													<Box m={0.1} />
													<Button variant="contained" color="primary" style={{ width: "100%", marginTop: "65px" }} className={classes.signupBtn}
														onClick={this.ViewMyAccount}
														type="submit">
														View my account
										              	</Button>
												</FormControl>
											</div>
										</div>
									</div>
								</div>
							</div>
						</Grid>
					</Grid>
				</Grid>
			</Grid >
		);
	}
}

QuickSetupAccountantSuccessComponent.propTypes = {
	history: PropTypes.object,
	classes: PropTypes.object,
	onboard: PropTypes.object,
	businessData: PropTypes.object,
	error: PropTypes.bool,
	updateOnboardingSucsee: PropTypes.bool,
	errorMessage: PropTypes.string,
	onboardSessionid: PropTypes.string,
	signUp: PropTypes.func,
	onboardingRequest: PropTypes.func,
};

const mapStateToProps = state => ({
	error: state.external.error,
	errorMessage: state.external.errorMessage,
	onboard: state.onboard,
	message: state.onboard.message,
	onboardSessionid: state.onboard.sessionToken,
	businessData: state.onboard.businessData,
	messageCode: state.onboard.messageCode,
	updateOnboardingSucsee: state.onboard.updateOnboardingSucsee,
});

const mapDispatchToProps = dispatch => ({
	updateDataboarding: bindActionCreators(OnboardActions.updateDataDuringOnboarding, dispatch),
	cleanMessageOnboard: bindActionCreators(OnboardActions.cleanMessageOnboard, dispatch),
});

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(withStyles(ExternalUserStyle)(QuickSetupAccountantSuccessComponent))
);

