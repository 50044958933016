import * as actionTypes from "../../constants/actionTypes";
import payrollInitialState, { payrollDefaultData } from "./PayrollInitialState";
import { employeeDefaultData } from "../Employees/EmployeeInitialState";

const payrollReducer = (state = payrollInitialState, action) => {

	switch (action.type) {
		case actionTypes.ACTION_PAYROLL_SAVE_DATA_REQUEST: {
			return {
				...state,
				isLoading: true,
				messageCode: "",
				message: "",
				selectedPayrollData: {
					...state.payrollDefaultData
				}
			};
		}
		case actionTypes.ACTION_PAYROLL_SAVE_DATA_REQUEST_SUCCESS: {
			return {
				...state,
				isLoading: false,
				selectedPayrollData: {
					...payrollDefaultData,
					...action.payload.selectedPayrollData,
					employeeActive: {
						...employeeDefaultData,
						id: action.payload.selectedPayrollData.employeeActive.detail.id,
						detail: {
							...action.payload.selectedPayrollData.employeeActive.detail
						}
					}
				},
				messageCode: action.payload.messageCode,
				message: action.payload.message
			};
		}
		case actionTypes.ACTION_PAYROLL_SAVE_DATA_REQUEST_FAILURE: {
			return {
				...state,
				error: true,
				isLoading: false,
				messageCode: action.payload.messageCode,
				message: action.payload.message,
				selectedPayrollData: {
					...state.selectedPayrollData
				}
			};
		}
		case actionTypes.ACTION_PAYROLL_CLEAN_DATA: {
			return {
				...state,
				isLoading: false,
				error: false,
				messageCode: "",
				message: "",
				selectedPayrollData: {
					...payrollDefaultData,
					...action.payload.selectedPayrollData
				}
			};
		}
		case actionTypes.ACTION_PAYROLL_GET_EMPLOYEE_REQUEST: {
			return {
				...state,
				isLoading: true,
				error: false,
				messageCode: "",
				message: "",
				isNewEmployee: false,
				selectedPayrollData: {
					...state.selectedPayrollData
				}
			};
		}
		case actionTypes.ACTION_PAYROLL_GET_EMPLOYEE_REQUEST_SUCCESS: {
			return {
				...state,
				isLoading: false,
				error: false,
				messageCode: "",
				message: "",
				isNewEmployee: false,
				isFinalPayrollData: null,
				isFinishAllEmployees: state.selectedPayrollData.employees.length === 1 && state.selectedPayrollData.employees[0].isProcessed,
				selectedPayrollData: {
					...state.selectedPayrollData,
					employeeActive: action.payload.employeeData,
					payroll: {
						...state.selectedPayrollData.payroll,
						employeeId: action.payload.employeeData.id,
						employerId: action.payload.employeeData.employerId,
						payPeriodEnd: state.selectedPayrollData.finishDate,
						payPeriodStart: state.selectedPayrollData.startDate,
						paymentDate: state.selectedPayrollData.paymentDate,
						payType: state.selectedPayrollData.payType,
						paymentTypeBaseHours: parseFloat(isNaN(action.payload.employeeData.payroll.hourlyRate) ? 0 : action.payload.employeeData.payroll.hourlyRate).toFixed(4),
						paymentTypeHours: parseFloat((isNaN(action.payload.employeeData.payroll.payPeriod) ? 0 : action.payload.employeeData.payroll.payPeriod) || (isNaN(action.payload.employeeData.payroll.payrollWeek) ? 0 : action.payload.employeeData.payroll.payrollWeek)).toFixed(2),
						paymentTypeTotal: parseFloat(
							parseFloat(isNaN(action.payload.employeeData.payroll.hourlyRate) ? 0 : action.payload.employeeData.payroll.hourlyRate).toFixed(4)
							* parseFloat((isNaN(action.payload.employeeData.payroll.payrollWeek) ? 0 : action.payload.employeeData.payroll.payrollWeek)).toFixed(2)
						).toFixed(2),
						quantities: action.payload.employeeData.quantities,
						showAllowance: action.payload.employeeData.payroll.allowances,
						showEntitlements:
							action.payload.employeeData.entitlement && (action.payload.employeeData.entitlement.isAnnualLeavePay || action.payload.employeeData.entitlement.isSickLeavePay || action.payload.employeeData.entitlement.isPersonalLeavePay || action.payload.employeeData.entitlement.isLongServiceLeave),
						showOvertime: action.payload.employeeData.payroll.overtime,
						showBonus: action.payload.employeeData.payroll.bonus,
						showDirectorsFees: action.payload.employeeData.payroll.directorsFees,
						showDeductions: action.payload.employeeData.payroll.deductions,
						showCommission: action.payload.employeeData.payroll.commission,
						isAnnualLeave: action.payload.employeeData.entitlement && action.payload.employeeData.entitlement.isAnnualLeavePay,
						isSickLeave: action.payload.employeeData.entitlement && action.payload.employeeData.entitlement.isSickLeavePay,
						isPersonalLeave: action.payload.employeeData.entitlement && action.payload.employeeData.entitlement.isPersonalLeavePay,
						isLongServiceLeave: action.payload.employeeData.entitlement && action.payload.employeeData.entitlement.isLongServiceLeave
					}
				}
			};
		}
		case actionTypes.ACTION_PAYROLL_GET_EMPLOYEE_REQUEST_FAILURE: {
			return {
				...state,
				isLoading: false,
				error: true,
				messageCode: action.payload.messageCode,
				message: action.payload.message,
				isNewEmployee: false
			};
		}
		case actionTypes.ACTION_PAYROLL_ADD_REQUEST: {
			return {
				...state,
				isLoading: true,
				payroll: action.payload.payroll,
				messageCode: "",
				message: ""
			};
		}
		case actionTypes.ACTION_PAYROLL_ADD_REQUEST_SUCCESS: {
			return {
				...state,
				isLoading: false,
				payroll: action.payload.payroll,
				messageCode: "",
				message: ""
			};
		}
		case actionTypes.ACTION_PAYROLL_ADD_REQUEST_FAILURE: {
			return {
				...state,
				isLoading: false,
				error: true,
				messageCode: action.payload.messageCode,
				message: action.payload.message
			};
		}
		case actionTypes.ACTION_PAYROLL_SEND_MAIL_REQUEST: {
			return {
				...state,
				isLoading: true,
				isSendEmail: false,
				payroll: action.payload.payroll,
				messageCode: "",
				message: ""
			};
		}
		case actionTypes.ACTION_PAYROLL_SEND_MAIL_REQUEST_SUCCESS: {
			return {
				...state,
				isLoading: false,
				isSendEmail: true,
				messageCode: "",
				message: ""
			};
		}
		case actionTypes.ACTION_PAYROLL_SEND_MAIL_REQUEST_FAILURE: {
			return {
				...state,
				isLoading: false,
				isSendEmail: false,
				error: true,
				messageCode: action.payload.messageCode,
				message: action.payload.message
			};
		}
		case actionTypes.ACTION_PAYROLL_DELETED_REQUEST: {
			return {
				...state,
				isLoading: true,
				payroll: action.payload.payroll,
				messageCode: "",
				message: ""
			};
		}
		case actionTypes.ACTION_PAYROLL_DELETED_REQUEST_SUCCESS: {
			return {
				...state,
				isLoading: false,
				payroll: null,
				messageCode: "",
				message: "",
				deleted: action.payload.deleted
			};
		}
		case actionTypes.ACTION_PAYROLL_DELETED_REQUEST_FAILURE: {
			return {
				...state,
				isLoading: false,
				error: true,
				messageCode: action.payload.messageCode,
				message: action.payload.message
			};
		}
		case actionTypes.ACTION_PAYROLL_SAVE_PAYLOAD_DATA_REQUEST: {
			return {
				...state,
				isLoading: true,
				isSendEmail: false,
				isNewEmployee: false,
				messageCode: "",
				message: "",
				selectedPayrollData: {
					...state.selectedPayrollData
				},
				payroll: {
					...state.payroll
				},
				isFinishAllEmployees: action.payload.isFinishAllEmployees
			};
		}
		case actionTypes.ACTION_PAYROLL_SAVE_PAYLOAD_DATA_REQUEST_SUCCESS: {
			return {
				...state,
				isLoading: false,
				isSendEmail: false,
				isNewEmployee: true,
				selectedPayrollData: {
					...payrollDefaultData,
					...action.payload.selectedPayrollData,
					employeeActive: {
						...employeeDefaultData,
						id: action.payload.selectedPayrollData.employeeActive.detail.id,
						detail: {
							...action.payload.selectedPayrollData.employeeActive.detail
						}
					}
				},
				payroll: null,
				isFinishAllEmployees: action.payload.isFinishAllEmployees,
				messageCode: "",
				message: ""
			};
		}
		case actionTypes.ACTION_PAYROLL_SAVE_PAYLOAD_DATA_REQUEST_FAILURE: {
			return {
				...state,
				error: true,
				isLoading: false,
				isSendEmail: false,
				isNewEmployee: false,
				messageCode: action.payload.messageCode,
				message: action.payload.message,
				selectedPayrollData: {
					...state.selectedPayrollData
				},
				payroll: {
					...state.payrollProcessDefaultData
				},
				isFinishAllEmployees: action.payload.isFinishAllEmployees
			};
		}
		case actionTypes.ACTION_PAYROLL_VALIDATE_REQUEST: {
			return {
				...state,
				isLoading: true,
				messageCode: "",
				message: ""
			};
		}
		case actionTypes.ACTION_PAYROLL_VALIDATE_REQUEST_SUCCESS: {
			return {
				...state,
				isLoading: false,
				selectedPayrollData: {
					...state.selectedPayrollData,
					...action.payload.selectedPayrollData
				},
				messageCode: "",
				message: ""
			};
		}
		case actionTypes.ACTION_PAYROLL_VALIDATE_IS_FINAL_SUCCESS: {
			return {
				...state,
				listPayrollDataisFinal: action.payload.listPayrollDataisFinal
			};
		}
		case actionTypes.ACTION_PAYROLL_VALIDATE_REQUEST_FAILURE: {
			return {
				...state,
				isLoading: false,
				error: true,
				messageCode: action.payload.messageCode,
				message: action.payload.message
			};
		}
		case actionTypes.ACTION_SESSION_LOGOUT: {
			return payrollInitialState;
		}
		default: {
			return {
				...state
			};
		}
	}
};

export default payrollReducer;
