import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import {
	Card, CardHeader, Collapse, IconButton, Typography
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";

import { CardStyles } from "./commonStyles";

class CardCommonComponent extends Component {
	constructor() {
		super();
		this.state = {
			expanded: true
		};
	}

	handleExpandClick = () => {
		this.setState(state => ({ expanded: !state.expanded }));
	};

	render() {
		const {
			classes, children, title, complete, collapseExtend, hideExpandCheck
		} = this.props;

		const { expanded } = this.state;

		return (
			<Card
				className={clsx(classes.card, {
					[classes.cardClose]: !expanded
				})}
			>
				<CardHeader
					avatar={(
						<i className={clsx("material-icons", { [classes.iconIncomplete]: !complete, [classes.iconComplete]: complete, [classes.hideIcon]: hideExpandCheck })}>check_circle</i>
					)}
					action={(
						<IconButton
							className={clsx(classes.expand, {
								[classes.expandOpen]: expanded,
								[classes.hideIcon]: hideExpandCheck
							})}
							onClick={this.handleExpandClick}
							aria-expanded={expanded}
							aria-label="Show more"
						>
							<ExpandMore />
						</IconButton>
					)}
					title={<Typography variant="h4" className={clsx({ [classes.hideIconTitle]: hideExpandCheck })}>{title}</Typography>}
					classes={{
						avatar: clsx(classes.titleAvatar)
					}}
				/>
				<Collapse
					in={expanded}
					timeout="auto"
					unmountOnExit
					className={clsx(classes.collapse, {
						[classes.collapseExtend]: collapseExtend
					})}
				>
					{children}
				</Collapse>
			</Card>
		);
	}
}

CardCommonComponent.propTypes = {
	children: PropTypes.any,
	title: PropTypes.string,
	complete: PropTypes.bool,
	collapseExtend: PropTypes.bool,
	hideExpandCheck: PropTypes.bool,
};

export default withStyles(CardStyles)(CardCommonComponent);
