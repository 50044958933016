import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import {
  Drawer,
  Divider,
  List,
  Badge,
  ListItem,
  ListItemText
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import settingStyle from "./settingStyle";
import { AvatarCommonComponent } from "../common";

class DrawerComponent extends Component {
  onHandleChoiceEmployee = i => () => {
    this.props.handleChoiceEmployee(i);
  };
  handleName = name1 => {
    let name = name1;
    let n = name.split(" ");
    let abb = n[0].slice(0, 1) + n[1].slice(0, 1);
    return abb.toUpperCase();
  };
  render() {
    const { classes, editList, employeeList, employeeActive } = this.props;
    return (
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper
        }}
        anchor="right"
      >
        <div className={classes.toolbar} />
        <Divider />
        <List>
          {employeeList &&
            employeeList.map((employee, i) => (
              <ListItem
                button
                key={employee.id}
                onClick={this.onHandleChoiceEmployee(i)}
                className={classes.employeeActive}
                selected={employeeActive === i}
              >
                {editList[i] ? (
                  <Badge
                    className={classes.avatarBadge}
                    badgeContent={<CheckIcon />}
                    color="secondary"
                  >
                    &nbsp;
                  </Badge>
                ) : null}

                <AvatarCommonComponent
                  src={employee.imageUrl}
                  text={this.handleName(employee.name)}
                  isAct={employeeActive === i}
                />
                <ListItemText
                  className={classes.avatarText}
                  primary={employee.name}
                />
              </ListItem>
            ))}
        </List>
      </Drawer>
    );
  }
}

DrawerComponent.propTypes = {
  selectedPayrollData: PropTypes.object,
  handleChoiceEmployee: PropTypes.func
};

const mapStateToProps = state => ({
  selectedPayrollData: state.payroll.selectedPayrollData
});

export default connect(
  mapStateToProps,
  null
)(withStyles(settingStyle)(DrawerComponent));
