const initialState = {
	error: false,
	errorMessage: null,
	isLoading: false,
	isSaving: false,
	messageCode: null,
	message: null,
	isAccountant: true,
	isEmployee: false
};

export default initialState;
