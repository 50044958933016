const singleTouchStyle = theme => ({
	root: {
		...theme.root,
		paddingBottom: 20
	},
	paper: {
		width: "100%",
		height: 65,
		borderRadius: 5,
		border: "1px solid #e9e9e9",
		backgroundColor: "#ffffff",
		padding: "10px 20px",
		boxShadow: "none",
		marginBottom: 12,
		"&:last-child": {
			marginBottom: 0
		},
		"&:hover": {
			boxShadow: "0 2px 2px 0 #D8D8D8"
		}
	},
	paperTitle: {
		...theme.paper,
		border: "none",
		padding: "5px 26px",
		backgroundColor: "transparent",
		boxShadow: "none",
		marginBottom: "5px",
		fontZise: 15,
		color: "#000000",
		textAlign: "center",
		"&.Left": {
			textAlign: "left"
		}
	},
	bullet: {
		...theme.bullet
	},
	paperNoBorder: {
		...theme.paper,
		border: "none",
		padding: "16px 20px",
		boxShadow: "none",
		marginBottom: 0,
		color: "#24253D",
		textAlign: "center",
		"&.Left": {
			textAlign: "left"
		}
	},
	CardTitle: {
		padding: 2
	},
	BorderCard: {
		background: "#ffffff",
		margin: "10px 9px",
		borderRadius: 5,
		maxHeight: 65,
		boxShadow: "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)",
		cursor: "pointer"
	},
	IconCheck: {
		fontSize: 23,
		color: "#D8D8D8"
	},
	IconCheckActive: {
		fontSize: 23,
		color: "#007aff"
	},
	alignItemMenu: {
		textAlign: "right"
	},
	divider: {
		marginBottom: 18,
		marginTop: 10
	},
	gridContainer: {
		marginBottom: 16
	},
	gridLeft: {
		paddingLeft: 30
	},
	gridRight: {
		textAlign: "right"
	},
	gridRightClearButton: {
		marginBottom: 0
	},
	imageEmpty: {
		width: "10%",
		textAlign: "center",
		marginTop: 40
	},
	subtitleHeader: {
		fontFamily: "AvenirNextLTPro-Regular",
		color: "#7D848B"
	},
	paperContainer: {
		...theme.paperContainer
	},
	paperGrid: {
		...theme.paperClear
	},
	BottomSpace: {
		marginBottom: 45
	},
	BottomSpaceList: {
		marginBottom: 45,
		backgroundColor: "transparent"
	},
	paperGridClearRight: {
		paddingRight: 0
	},
	option: {
		...theme.option
	},
	keyboardDatePicker: {
		...theme.keyboardDatePicker
	},
	keyboardDatePickerInput: {
		...theme.keyboardDatePickerInput
	},
	tabs: {
		backgroundColor: "transparent",
		marginTop: theme.spacing(5),
		marginBottom: theme.spacing(5)
	},
	tab: {
		backgroundColor: "transparent",
		textTransform: "none",
		fontWeight: "200",
		color: "#7D848B",
		fontSize: theme.typography.pxToRem(27)
	},
	LeftList: {
		paddingLeft: 0,
		paddingRight: 0
	},
	imageEmptyContainer: {
		width: "100%",
		textAlign: "center",
		marginTop: 40
	},
	imageEmptyImage: {
		width: 440,
		marginBottom: 20
	},
	hiddenOverflow: {
		...theme.hiddenOverflow
	},
	switchroot: {
		width: 42,
		height: 26,
		padding: 0,
		margin: theme.spacing(1)
	},
	switchBase: {
		padding: 1,
		"&$checked": {
			transform: "translateX(16px)",
			color: theme.palette.common.white,
			"& + $track": {
				backgroundColor: "#007AFF",
				opacity: 1,
				border: "none"
			}
		},
		"&$focusVisible $thumb": {
			color: "#007AFF",
			border: "6px solid #fff"
		}
	},
	switchthumb: {
		width: 24,
		height: 24
	},
	switchtrack: {
		borderRadius: 12,
		backgroundColor: "#D8D8D8",
		opacity: 1,
		transition: theme.transitions.create(["background-color", "border"])
	},
	switchchecked: {},
	switchfocusVisible: {},
	switchcontainer: {
		border: "1px solid #D8D8D8",
		borderRadius: 5,
		cursor: "pointer"
	},
	switchcontainerLeft: {
		paddingLeft: "15px !important"
	},
	switchcontainerRight: {
		textAlign: "right"
	},
	switchinput: {
		marginTop: "0px !important"
	}
});

export default singleTouchStyle;
