import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import {
	Button, Grid, Paper, Typography, MenuItem, Table, TableHead, TableBody, TableCell, TableRow, Icon, IconButton
} from "@material-ui/core";

import DeleteIcon from '@material-ui/icons/Delete';

import { LoadingComponent, SnackbarCommonComponent } from "../common";

import settingStyle from "./settingStyle";
import ModalAddAccountant from "./modalAddAccountant";
import { payrollAdditionalDefaultData } from "../../reducers/Payroll/PayrollInitialState";
import * as messages from "../../constants/messages";
import * as additionalUserActions from "../../actions/AdditionalUserActions";
import AvatarCommonComponent from "../common/avatarCommonComponent";
import { mapMessageCodeToIcon } from "../../helpers/extensions";
import additionalUserEmpty from "../../assets/images/employees.empty.png";
class managerUserAccessComponent extends Component {
	constructor(props) {
		super(props);
	}

	state = {
		check: true,
		showModal: false,
		isDelete: false,
		accountantRevoke: {},
		deleteError: false,
		error: {
			show: false,
			message: ""
		},
		editItem: {
			isEditing: false,
			data: null,
			index: -1
		}
	};

	componentDidMount() {
		const { getInviteAdditionalUser } = this.props;
		getInviteAdditionalUser();
	}

	handleClickOption = () => {
		this.setState(state => ({
			...state,
			showModal: true,
			isDelete: false,
		}));
	};

	handleCloseModal = () => {
		this.setState(state => ({
			...state,
			showModal: false,
			isDelete: false,
			editItem: {
				isEditing: false,
				data: null,
				index: -1
			}
		}));
	};

	setValueAdd = (value) => {
		const valueAdd = {
			...payrollAdditionalDefaultData,
			name: value.name
		};
		return valueAdd;
	};

	handleAddUser = (value, actions) => {

		const { inviteAdditionalUser } = this.props;
		const valuesUser = {
			...value
		};
		// actions.setSubmitting(false);
		inviteAdditionalUser(valuesUser);
		this.handleCloseModal();

	};

	handleOpenModalRemoveItem = (event, accountant, index) => {
		
		this.setState({
			showModal: true,
			isDelete: true,
			deleteError: true,
			accountantRevoke: accountant
		})
	};

	handleRemoveItem = (value, actions) => {
		const { AdditionalUserRevoke } = this.props;
		AdditionalUserRevoke(value.id);
		this.handleCloseModal();
	};

	handleShowError = (show, message) => {
		this.setState(state => ({
			...state,
			error: {
				show,
				message
			}
		}));
	};

	render() {
		const { classes, messageCode, message, errorMessage, cleanMessageInternal, accountants, isSaving, isLoading } = this.props;
		const { showModal, error, editItem, isDelete, accountantRevoke, deleteError } = this.state;

		
		const accountantsList = accountants && accountants.map((accountant, index) => (
			<Paper className={classes.paper} key={accountant.id}>
				<Grid container wrap="nowrap" spacing={2} alignItems="center">
					<Grid item>
						<AvatarCommonComponent text={`${accountant.name ? accountant.name.substring(0, 1) : ""}`} hover={false} accountantId={accountant.id} />
					</Grid>
					<Grid item xs>
						<Typography variant="body1">{accountant.name ? accountant.name : accountant.email}</Typography>
					</Grid>
					<Grid>
						<IconButton className={classes.button} aria-label="Delete"
							color="black"
							onClick={(event) => {
								this.handleOpenModalRemoveItem(event, accountant, accountant.id);
							}
							}
						>
							<DeleteIcon style={{ fontSize: 23 }} />
						</IconButton>
					</Grid>
				</Grid>
			</Paper>
		));

		return (
			<Grid>
				
				<Paper className={classes.paperContainer}>
						<Grid container alignItems="center" style={{marginTop: "-32px" , marginBottom: "15px"}}>
							<Grid item xs={6} className={classes.gridLeft}>
								<Typography variant="h1" item xs={6} >
									{"Manager User Access"}
								</Typography>
							</Grid>
							<Grid item xs={6} className={classes.gridRight} style={{paddingTop: "23px"}}>
								<Button variant="contained" color="primary" className={classes.gridRightClearButton} onClick={this.handleClickOption}>
									{"+ Add User"}
								</Button>
							</Grid>
						</Grid>
						
						{showModal ? <ModalAddAccountant open={showModal} isDelete={isDelete} data={isDelete ? accountantRevoke : ""} onHandleUser={isDelete ? this.handleRemoveItem : this.handleAddUser} onCloseModal={this.handleCloseModal} /> : null}
					

						{(accountants && accountants.length <= 0 || accountants === null) && !isLoading ? (
							<div className={classes.imageEmptyContainer}>
								<img className={classes.imageEmptyImage} src={additionalUserEmpty} alt="" />
								<Typography variant="body1" className="gray">
									{"You don't have any additional users yet"}
								</Typography>
							</div>
						) : null}

						{accountants && accountants.length > 0 ? <Grid container alignItems="center">
							<Grid item xs={12}>
								{accountantsList}
							</Grid>
						</Grid>
							: null
						}
					</Paper>

			
				<LoadingComponent isLoading={isLoading} />

				<SnackbarCommonComponent key="SnackBarNewUserMessages"
					autoHideDuration={5000}
					variant={messageCode === 200 ? "" : mapMessageCodeToIcon(messageCode)}
					// open={(!!message)} body={messageCode === 200 && !deleteError ? "Invite email has been sent to accountant" : message}
					open={(!!message)} body={message}
					onCloseModal={cleanMessageInternal}
				/>
			</Grid>
		);
	}
}

managerUserAccessComponent.propTypes = {
	formProps: PropTypes.object.isRequired,
	inviteAdditionalUser: PropTypes.func,
	getInviteAdditionalUser: PropTypes.func,
	AdditionalUserRevoke: PropTypes.func,
	messageCode: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),
	message: PropTypes.string,
	errorMessage: PropTypes.string,
	accountants: PropTypes.array,
	isSaving: PropTypes.bool,
	isLoading: PropTypes.bool
};

const mapStateToProps = state => ({
	isLoading: state.AdditionalUserAccountant.isLoading || state.AdditionalUserAccountant.isSaving,
	messageCode: state.AdditionalUserAccountant.messageCode,
	errorMessage: state.AdditionalUserAccountant.errorMessage,
	message: state.AdditionalUserAccountant.message,
	accountants: state.AdditionalUserAccountant.accountants
});

const mapDispatchToProps = dispatch => ({
	inviteAdditionalUser: bindActionCreators(additionalUserActions.inviteAdditionalUser, dispatch),
	getInviteAdditionalUser: bindActionCreators(additionalUserActions.getInviteAdditionalUserAllData, dispatch),
	AdditionalUserRevoke: bindActionCreators(additionalUserActions.AdditionalUserRevoke, dispatch),
	cleanMessageInternal: bindActionCreators(additionalUserActions.cleanMessage, dispatch),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(withStyles(settingStyle)(managerUserAccessComponent)));
