import React, { Component } from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import {
	Button, Grid, Table, TableHead, TableBody, TableCell, TableRow, Typography, MenuItem
} from "@material-ui/core";

import { MenuItemComponent, SnackbarCommonComponent } from "../common";

import PayrollStyle from "./payrollStyle";
import ModalAddTaxableAllowance from "./modalAddTaxableAllowance";
import { payrollAllowancesDefaultData } from "../../reducers/Payroll/PayrollInitialState";
import * as messages from "../../constants/messages";
import EditTaxableAllowanceComponent from "./editTaxableAllowanceComponent";

class TaxableAllowanceComponent extends Component {
	constructor(props) {
		super(props)
	}
	state = {
		showModal: false,
		error: {
			show: false,
			message: ""
		},
		editItem: {
			isEditing: false,
			data: null,
			index: -1
		}
	};

	componentDidMount() {
		const { formProps } = this.props;
		formProps.setFieldValue("payroll.allowances", formProps.values.employeeActive.allowances);
	}

	handleClickOption = () => {
		this.setState(state => ({
			...state,
			showModal: true,
			error: {
				...state.error,
				show: false
			}
		}));
	};

	handleCloseModal = () => {
		this.setState(state => ({
			...state,
			showModal: false,
			error: {
				...state.error,
				show: false
			},
			editItem: {
				isEditing: false,
				data: null,
				index: -1
			}
		}));
	};

	setValueAdd = (value) => {
		const valueAdd = {
			...payrollAllowancesDefaultData,
			value: value.value,
			manualValue: value.manualValue,
			qty: parseFloat(value.qty).toFixed(2),
			amount: parseFloat(value.amount).toFixed(2),
			totalAmount: parseFloat(value.totalAmount).toFixed(2)
		};

		return valueAdd;
	};

	handleAdd = (value) => {
		const { formProps } = this.props;
		const { allowances } = formProps.values.payroll;
		let newAllowances = []
		this.handleShowError(false, "");

		if (!allowances) {
			const newAllowances = [];
			newAllowances.push(this.setValueAdd(value));

			formProps.setFieldValue("payroll.allowances", newAllowances);

			this.handleCloseModal();
			return;
		}

		if (allowances && allowances.length >= 10) {
			this.handleShowError(true, messages.MESSAGE_PAYROLL_ALLOWANCES_LIMIT);
			return;
		}

		if (allowances) {
			if (!allowances.some(item => `${value.value}_${value.manualValue}` === `${item.value}_${item.manualValue}`)) {
				newAllowances = [...allowances];
				newAllowances.push(this.setValueAdd(value));
				formProps.setFieldValue("payroll.allowances", newAllowances);
				this.handleCloseModal();
			} else {
				this.handleShowError(true, messages.MESSAGE_PAYROLL_ALLOWANCES_EXISTS);
			}
		} else {
			newAllowances.push(this.setValueAdd(value));
			formProps.setFieldValue("payroll.allowances", newAllowances);
			this.handleCloseModal();
		}



	};

	handleUpdate = (value) => {
		const { formProps } = this.props;
		const { editItem } = this.state;
		const { allowances } = formProps.values.payroll;

		const newAllowances = [...allowances];
		newAllowances.splice(editItem.index, 1);

		newAllowances.push(this.setValueAdd(value));

		formProps.setFieldValue("payroll.allowances", newAllowances);

		this.handleCloseModal();
	};

	handleRemoveItem = (event, index) => {
		const { formProps } = this.props;
		const { allowances } = formProps.values.payroll;
		const newAllowances = [...allowances];
		newAllowances.splice(index, 1);

		formProps.setFieldValue("payroll.allowances", newAllowances);
	};

	handleEditItem = (event, index) => {
		const { formProps } = this.props;
		const { allowances } = formProps.values.payroll;

		this.setState(state => ({
			...state,
			showModal: true,
			editItem: {
				isEditing: true,
				data: allowances[index],
				index
			}
		}));
	};

	handleShowError = (show, message) => {
		this.setState(state => ({
			...state,
			error: {
				show,
				message
			}
		}));
	};

	render() {
		const { classes, formProps, isFinishAllEmployees } = this.props;
		const { showModal, error, editItem } = this.state;

		return (
			<Grid className={classes.gridContainer}>
				<Grid container direction="row" alignItems="flex-start">
					<Grid item xs={6}>
						<Typography variant="h4" gutterBottom>
							Taxable allowances
						</Typography>
					</Grid>
					<Grid item xs={6} className={classes.gridRight}>
						<Button variant="outlined" color="secondary" onClick={this.handleClickOption} disabled={isFinishAllEmployees}>
							+ Add taxable allowances
						</Button>
						{showModal ? <ModalAddTaxableAllowance open={showModal} edit={editItem.isEditing} data={editItem.data} onAddValues={editItem.isEditing ? this.handleUpdate : this.handleAdd} onCloseModal={this.handleCloseModal} /> : null}
					</Grid>
				</Grid>
				{!formProps.values.payroll.allowances || formProps.values.payroll.allowances.length <= 0 ? (
					<Typography variant="h4" component="div" className={classes.placeHolder}>
						{"You haven’t added taxable allowances"}
					</Typography>
				) : null}
				{formProps.values.payroll.allowances && formProps.values.payroll.allowances.length > 0 ? (
					<Table className={classes.table} size="medium">
						<TableHead className={classes.tableH}>
							<TableRow>
								<TableCell className={classes.tableTitle}>Name</TableCell>
								<TableCell className={classes.tableTitle}>Quantity</TableCell>
								<TableCell className={classes.tableTitle}>Rate</TableCell>
								<TableCell className={classes.tableTitle}>Total</TableCell>
								<TableCell className={classes.tableTitle} align="right" />
							</TableRow>
						</TableHead>
						<TableBody>
							{formProps.values.payroll.allowances.map((row, index) => (
								// <TableRow key={`${row.value}_${row.manualValue}`} className={classes.tableRow}>
								// 	<TableCell className={classes.tableText} component="th" scope="row">
								// 		{`${row.value} ${row.manualValue}`}
								// 	</TableCell>
								// 	<TableCell className={classes.tableText}>{row.qty}</TableCell>
								// 	<TableCell className={classes.tableText}>{`$ ${row.amount}`}</TableCell>
								// 	<TableCell className={classes.tableText}>{`$ ${row.totalAmount}`}</TableCell>
								// 	<TableCell className={classes.tableText} align="right">
								// 		<MenuItemComponent>
								// 			<MenuItem onClick={e => this.handleEditItem(e, index)}>Edit</MenuItem>
								// 			<MenuItem onClick={e => this.handleRemoveItem(e, index)}>Delete</MenuItem>
								// 		</MenuItemComponent>
								// 	</TableCell>
								// </TableRow>


								<EditTaxableAllowanceComponent

									row={row}
									index={index}
									key={index}
									parentProps={formProps}
									handelChange={this.handelChange}
									handleRemoveItem={this.handleRemoveItem} />


							))}
						</TableBody>
					</Table>
				) : null}
				{error && error.show ? <SnackbarCommonComponent variant="error" open={error.show} body={error.message} /> : null}
			</Grid>
		);
	}
}

TaxableAllowanceComponent.propTypes = {
	formProps: PropTypes.object.isRequired
};

export default withStyles(PayrollStyle)(TaxableAllowanceComponent);
