import * as actionTypes from "../../constants/actionTypes";
import parameterInitialState from "./ParameterInitialState";

const parameterReducer = (state = parameterInitialState, action) => {
	switch (action.type) {
		case actionTypes.ACTION_PARAMTERS_GETALL_REQUEST: {
			return {
				...state,
				isLoading: true
			};
		}
		case actionTypes.ACTION_PARAMTERS_GETALL_REQUEST_SUCCESS: {
			return {
				...state,
				isLoading: false,
				parametersData: action.payload.parametersData
			};
		}
		case actionTypes.ACTION_PARAMTERS_GETALL_REQUEST_FAILURE: {
			return {
				...state,
				error: true,
				isLoading: false,
				errorMessage: action.payload.errorMessage,
				messageCode: action.payload.messageCode
			};
		}
		case actionTypes.ACTION_PARAMTERS_GETBYTYPE_REQUEST: {
			return {
				...state,
				isLoading: true
			};
		}
		case actionTypes.ACTION_PARAMTERS_GETBYTYPE_REQUEST_SUCCESS: {
			const tempParameterByType = {
				...state.parameterByTypeData
			};
			tempParameterByType[`${action.payload.parameterType}`] = action.payload.parametersData;
			return {
				...state,
				isLoading: false,
				parameterByTypeData: {
					...tempParameterByType
				}
			};
		}
		case actionTypes.ACTION_PARAMTERS_GETBYTYPE_REQUEST_FAILURE: {
			return {
				...state,
				error: true,
				isLoading: false,
				errorMessage: action.payload.errorMessage,
				messageCode: action.payload.messageCode
			};
		}
		case actionTypes.ACTION_PARAMTERS_PLANS_REQUEST: {
			return {
				...state,
				isLoading: true
			};
		}
		case actionTypes.ACTION_PARAMTERS_PLANS_REQUEST_SUCCESS: {
			return {
				...state,
				isLoading: false,
				plansData: action.payload.plansData
			};
		}
		case actionTypes.ACTION_PARAMTERS_PLANS_REQUEST_FAILURE: {
			return {
				...state,
				error: true,
				isLoading: false,
				errorMessage: action.payload.errorMessage,
				messageCode: action.payload.messageCode
			};
		}
		case actionTypes.ACTION_SESSION_LOGOUT: {
			return parameterInitialState;
		}
		default: {
			return {
				...state
			};
		}
	}
};

export default parameterReducer;
