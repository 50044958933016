import { createLogic } from "redux-logic";
import reportApi from "../api/ReportApi";
import payrollApi from "../api/PayrollApi";
import paymentApi from "../api/PaymentApi";
import * as reportActions from "../actions/ReportActions";
import * as reportPaymentActions from "../actions/ReportPaymentActions";
import * as actionTypes from "../constants/actionTypes";
import * as apiResponses from "../constants/apiResponses";
import * as messages from "../constants/messages";

const processReport = createLogic({
	type: actionTypes.ACTION_REPORTS_PROCESS_REQUEST,
	latest: true,
	async process({ httpClient, action }, dispatch, done) {
		try {
			const reportData = await reportApi.processReport(httpClient, action.payload.reportData);

			if (reportData.status === 200) {
				dispatch(reportActions.processReportRequestSuccess(reportData.data.item, reportData.data.messageCode, reportData.data.message));
			} else {
				dispatch(reportActions.processReportRequestFailure(reportData.data.messageCode, reportData.data.message));
			}
		} catch (err) {
			dispatch(reportActions.processReportRequestFailure("", err.message));
		}
		done();
	}
});

const getAllPayslips = createLogic({
	type: actionTypes.ACTION_REPORTS_PAYSLIPS_REQUEST,
	latest: true,
	async process({ httpClient, action }, dispatch, done) {
		try {
			const payslipsData = await payrollApi.getAllFilter(httpClient, action.payload.filterData);
			if (payslipsData.messageCode === apiResponses.RESPONSE_OK) {
				payslipsData.list = payslipsData.list.reduce((obj, item) => {
					const header = item.period;
					obj[header] = obj[header] || [];
					obj[header].push(item);
					return obj;
				}, {});

				dispatch(reportActions.payslipsRequestSuccess(payslipsData.list, payslipsData.messageCode, payslipsData.message));
			} else {
				dispatch(reportActions.payslipsRequestFailure(payslipsData.messageCode, payslipsData.message));
			}
		} catch (err) {
			dispatch(reportActions.payslipsRequestFailure("", err.message));
		}
		done();
	}
});

const sendEmail = createLogic({
	type: actionTypes.ACTION_PAYROLL_SEND_EMAIL_REQUEST,
	latest: true,
	async process({ httpClient, action }, dispatch, done) {
		try {
			const payslipData = await payrollApi.sendEmail(httpClient, action.payload.filterData);
			if (payslipData.messageCode === apiResponses.RESPONSE_OK) {
				dispatch(reportActions.payrollSendEmailRequestSuccess(true, payslipData.messageCode, messages.MESSAGE_EMAIL_SENT));
			} else {
				dispatch(reportActions.payrollSendEmailRequestFailure(payslipData.messageCode, payslipData.message));
			}
		} catch (err) {
			dispatch(reportActions.payrollSendEmailRequestFailure("", err.message));
		}
		done();
	}
});

const deleteById = createLogic({
	type: actionTypes.ACTION_PAYROLL_DELETE_REQUEST,
	latest: true,
	async process({ httpClient, action }, dispatch, done) {
		try {
			const payslipData = await payrollApi.deleteById(httpClient, action.payload.id);
			if (payslipData.messageCode === apiResponses.RESPONSE_OK) {
				dispatch(reportActions.payrollDeleteRequestThunkSuccess(true, action.payload.filterData, payslipData.messageCode, messages.MESSAGE_PAYSLIP_DELETED));
			} else {
				dispatch(reportActions.payrollDeleteRequestFailure(payslipData.messageCode, payslipData.message));
			}
		} catch (err) {
			dispatch(reportActions.payrollDeleteRequestFailure("", err.message));
		}
		done();
	}
});


const getAllPaymentsEmployer = createLogic({
	type: actionTypes.ACTION_REPORTS_PAYMENT_EMPLOYER_REQUEST,
	latest: true,
	async process({ httpClient }, dispatch, done) {
		try {
			const paymentsEmployerData = await paymentApi.getPaymentsEmployer(httpClient);
			if (paymentsEmployerData.messageCode === apiResponses.RESPONSE_OK) {
				dispatch(reportPaymentActions.paymentsEmployerRequestSuccess(paymentsEmployerData.list, paymentsEmployerData.messageCode, paymentsEmployerData.message));
			} else {
				dispatch(reportPaymentActions.paymentsEmployerRequestFailure(paymentsEmployerData.messageCode, paymentsEmployerData.message));
			}
		} catch (err) {
			dispatch(reportPaymentActions.paymentsEmployerRequestFailure("", err.message));
		}
		done();
	}
});

const getAllPaymentsEmployees = createLogic({
	type: actionTypes.ACTION_REPORTS_PAYMENT_EMPLOYEE_REQUEST,
	latest: true,
	async process({ httpClient }, dispatch, done) {
		try {
			const paymentsEmployeesData = await paymentApi.getPaymentsEmployees(httpClient);
			if (paymentsEmployeesData.messageCode === apiResponses.RESPONSE_OK) {
				paymentsEmployeesData.list = paymentsEmployeesData.list.reduce((obj, item) => {
					const header = item.period;
					if (header) {
						obj[header] = obj[header] || [];
						obj[header].push(item);
					}
					return obj;
				}, {});

				dispatch(reportPaymentActions.paymentsEmployeesRequestSuccess(paymentsEmployeesData.list, paymentsEmployeesData.messageCode, paymentsEmployeesData.message));
			} else {
				dispatch(reportPaymentActions.paymentsEmployeesRequestFailure(paymentsEmployeesData.messageCode, paymentsEmployeesData.message));
			}
		} catch (err) {
			dispatch(reportPaymentActions.paymentsEmployeesRequestFailure("", err.message));
		}
		done();
	}
});

const paymentSendEmail = createLogic({
	type: actionTypes.ACTION_PAYMENT_SEND_EMAIL_REQUEST,
	latest: true,
	async process({ httpClient, action }, dispatch, done) {
		try {
			const paymentData = await paymentApi.sendEmail(httpClient, action.payload.filterData);
			if (paymentData.messageCode === apiResponses.RESPONSE_OK) {
				dispatch(reportPaymentActions.paymentSendEmailRequestSuccess(true, paymentData.messageCode, messages.MESSAGE_EMAIL_SENT));
			} else {
				dispatch(reportPaymentActions.paymentSendEmailRequestFailure(paymentData.messageCode, paymentData.message));
			}
		} catch (err) {
			dispatch(reportPaymentActions.paymentSendEmailRequestFailure("", err.message));
		}
		done();
	}
});


const processReportPayrollActivityDetail = createLogic({
	type: actionTypes.ACTION_REPORTS_PROCESS_ACTIVITY_DETAIL_REQUEST,
	latest: true,
	async process({ httpClient, action }, dispatch, done) {
		try {
			const reportData = await reportApi.processReportPayrollActivityDetail(httpClient, action.payload.reportData);

			if (reportData.status === 200) {
				dispatch(reportActions.processReportPayrollActivityDetailRequestSuccess(reportData.data.item, reportData.data.messageCode, reportData.data.message));
			} else {
				dispatch(reportActions.processReportPayrollActivityDetailRequestFailure(reportData.data.messageCode, reportData.data.message));
			}
		} catch (err) {
			dispatch(reportActions.processReportPayrollActivityDetailRequestFailure("", err.message));
		}
		done();
	}
});

export default [processReport, getAllPayslips, sendEmail, deleteById, getAllPaymentsEmployer, getAllPaymentsEmployees, paymentSendEmail, processReportPayrollActivityDetail];
