import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import clsx from "clsx";
import IconButton from "@material-ui/core/IconButton";
import { MoreVert } from "@material-ui/icons";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import * as reportPaymentActions from "../../actions/ReportPaymentActions";
import AvatarCommonComponent from "../common/avatarCommonComponent";
import reportsStyle from "./reportsStyle";
import reportsEmpty from "../../assets/images/reports.empty.png";
import DialogCommonPDFComponent from "../common/dialogCommonPDFComponent";
import DialogConfirmCommonComponent from "../common/dialogConfirmCommonComponent";

class ReportEmployeesComponent extends Component {
	state = {
		anchorEl: null,
		payment: "",
		dialogSendEmailOpen: false,
		dialogOpen: false,
		url: ""
	};

	componentDidMount() {
		const { getAllPaymentsEmployees } = this.props;
		getAllPaymentsEmployees();
	}

	handleOpenMenu = (event, payment) => {
		this.setState({ anchorEl: event.currentTarget, payment });
		event.persist();
	};

	handleCloseMenu = () => {
		this.setState({ anchorEl: null });
	};

	handleMenuView = () => {
		const { payment } = this.state;
		this.setState({ dialogOpen: true, url: payment.urlResume });
		this.handleCloseMenu();
	};

	handleDialogClose = () => {
		this.setState({ dialogOpen: false });
	};

	handleMenuSendEmail = () => {
		this.setState({ dialogSendEmailOpen: true });
		this.handleCloseMenu();
	};

	handleSendEmail = () => {
		const { paymentSendEmail } = this.props;
		const { payment } = this.state;
		console.log(this.state);
		paymentSendEmail({ id: payment.id });
		this.setState({ payment: null, dialogSendEmailOpen: false });
	};

	handleCloseDialogSendEmail = () => {
		this.setState({ payment: null, dialogSendEmailOpen: false });
	};

	render() {
		const { classes, paymentsEmployees, isLoading } = this.props;
		const {
			anchorEl, dialogSendEmailOpen, dialogOpen, url
		} = this.state;

		const payments = payment => (
			<Paper className={clsx(classes.paper)} key={payment.id}>
				<Grid container wrap="nowrap" spacing={2} alignItems="center">
					<Grid item>
						<AvatarCommonComponent text={payment.employeeDetail.initials} hover={false} src={payment.employeeDetail.imageUrl} />
					</Grid>
					<Grid item xs>
						<Typography variant="body1">{payment.employeeDetail.name}</Typography>
						<Typography variant="subtitle1">{payment.employeeDetail.jobTitle}</Typography>
					</Grid>
					<Grid item md className={classes.alignItemMenu}>
						<IconButton onClick={e => this.handleOpenMenu(e, payment)}>
							<MoreVert />
						</IconButton>
					</Grid>
				</Grid>
			</Paper>
		);

		return (
			<Typography component="div">
				{(!paymentsEmployees || Object.keys(paymentsEmployees).length <= 0) && !isLoading ? (
					<div className={classes.imageEmpty}>
						<img className={classes.imageEmptyImage} src={reportsEmpty} alt="" />
					</div>
				) : null}

				{paymentsEmployees && Object.keys(paymentsEmployees).length > 0
					? Object.keys(paymentsEmployees).map(period => (
						<div key={period}>
							<Typography variant="body1" className="bold" gutterBottom>
								{period}
							</Typography>
							{paymentsEmployees[period].map(p => payments(p))}
						</div>
					  ))
					: null}

				<Menu anchorEl={anchorEl} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} open={Boolean(anchorEl)} onClose={this.handleCloseMenu} getContentAnchorEl={null} PaperProps={{ style: { width: 150 } }}>
					<MenuItem onClick={this.handleMenuView}>View</MenuItem>
					<MenuItem onClick={this.handleMenuSendEmail}>Resend email</MenuItem>
				</Menu>

				<DialogCommonPDFComponent key="reportEmployeePayment" title="Employee Payment Summary" open={dialogOpen} onCloseModal={this.handleDialogClose} url={url} />

				<DialogConfirmCommonComponent open={dialogSendEmailOpen} title="Send email" body="Are you sure?" primaryButtonName="Send" onPrimaryButtonClick={this.handleSendEmail} onCloseModal={this.handleCloseDialogSendEmail}>
					<Typography variant="body1" gutterBottom className={classes.description}>
						{"Are you sure?"}
					</Typography>
				</DialogConfirmCommonComponent>
			</Typography>
		);
	}
}

ReportEmployeesComponent.propTypes = {
	paymentsEmployees: PropTypes.object,
	getAllPaymentsEmployees: PropTypes.func
};

const mapStateToProps = state => ({
	paymentsEmployees: state.reportsPayment.paymentsEmployees,
	isLoading: state.employees.isLoading || state.payment.isLoading,
});

const mapDispatchToProps = dispatch => ({
	getAllPaymentsEmployees: bindActionCreators(reportPaymentActions.paymentsEmployeesRequest, dispatch),
	paymentSendEmail: bindActionCreators(reportPaymentActions.paymentSendEmailRequest, dispatch)
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(reportsStyle)(ReportEmployeesComponent));
