import initialState from "../../store/initialState";
import { getDateToday } from "../../helpers/dateextensions";

export const employeeDefaultData = {
  id: null,
  employerId: null,
  temporal: true,
  active: false,
  detail: {
    imageUpload: {
      urlImage: null,
      image: null,
      name: null,
    },
    initials: "",
    firstName: "",
    middleName: "",
    lastName: "",
    dob: getDateToday(),
    phoneNumber: "",
    email: "",
    address: {
      addressLine1: "",
      addressLine2: "",
      townCity: "",
      postCode: "",
      state: "Australian Capital Territory",
    },
    imageUrl: null,
    isProcessed: false,
  },
  quantities: [],
  payroll: {
    jobTitle: "",
    employmentCategory: "Permanent",
    employmentDate: getDateToday(),
    status: "Casual",
    payType: "",
    annualSalary: "0",
    quantityDesc: "",
    quantityCount: "0",
    quantityRate: "0",
    payFrequency: "Monthly",
    payrollWeek: "0",
    payrollPeriod: "0",
    hourlyRate: "0",
    overtime: false,
    allowances: false,
    bonus: false,
    commission: false,
    deductions: false,
    directorsFees: false,
  },
  entitlement: {
    isAnnualLeavePay: false,
    annualLeavePay: "0",
    isPersonalLeavePay: false,
    personalLeavePayCarrieOver: "0",
    isLongServiceLeave: false,
    longServiceLeaveCarrieOver: "0",
    isAnnualLeaveLoading: false,
  },
  taxe: {
    tfn: "000000000",
    taxResidency: "Resident",
    taxBand: "Tax free threshold",
    stsl: false,
  },
  superannuation: {
    superRate: "10",
    superFundName: "",
    usi: "",
    superFundAbn: "",
    membershipReference: "",
    salarySacrifice: "",
    additional: "",
    spouse: "",
  },
};

const employeeInitialState = {
  ...initialState,
  list: [],
  activeEmployeesList: [],
  inactiveEmployeesList: [],
  unfinishedEmployeeList: [],
  reportsEmployeeList: [],
  selectedEmployeeList: [],
  quantities: [],
  selectedEmployeeData: {
    ...employeeDefaultData,
  },
  isDetailsSaved: false,
  isPayrollSaved: false,
  isEntitlementsSaved: false,
  isTaxesSaved: false,
  isSuperannuationSaved: false,
  isCasual: true,
};

export default employeeInitialState;
