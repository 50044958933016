import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Formik } from "formik";
import { isMobile } from "react-device-detect";

import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import * as Yup from "yup";

import {
	Button, Paper, Grid, Typography, TextField, FormHelperText, Input, Snackbar, Card, Box
} from "@material-ui/core";

import { PlanStyle } from "./subscriptionStyle";
import PlanItemComponent from "./planItemComponent";

import * as employeeActions from "../../actions/EmployeeActions";
import * as parameterActions from "../../actions/ParameterActions";
import * as subscriptionActions from "../../actions/SubscriptionActions";
import * as subscriptionStatus from "../../constants/subscriptionStatus";
import * as subscriptionType from "../../constants/subscriptionType";
import * as CouponActions from './../../actions/CouponActions';
import CouponComponent from "./couponComponent";

class PlanComponent extends Component {
	validationSchema = Yup.object({
		extraEmployee: Yup.number("Invalid Extra Employee")
			.required("Extra Employee is required")
			.min(0, "Negative values are not allowed")
	});

	componentDidMount() {
		const { loadPlansList, getAllEmployees, mixpanel, getCoupon } = this.props;
		loadPlansList();
		getAllEmployees();
		getCoupon();
		mixpanel.track("SubscriptionPlans.Screen");
	}

	renderPlans = (plansList, classes, activeEmployeeList, coupon) => plansList.map(plan => (
		<Grid item xs={isMobile ? 12 : 4} className={clsx(classes.gridItem, { isMobile })} key={plan.id}>
			<PlanItemComponent key={plan.id} plan={plan} quantityEmployees={activeEmployeeList.length} coupon={coupon} />
		</Grid>
	));

	handleSubmitPlanExtra = (values, actions) => {
		const { addEmployeeExtraSubscription, history } = this.props;
		actions.setSubmitting(false);
		addEmployeeExtraSubscription(values.extraEmployee, history);
	};

	handelClickSendcouponName = (values, actions) => {
		const { couponRequest } = this.props;
		couponRequest(values);
	};

	// { values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, }
	renderForm = (formProps) => {
		const {
			classes, employer, plansList, activeEmployeeList, coupon
		} = this.props;

		return (
			<form className={classes.container}>
				<Paper className={clsx(classes.paperContainer, { isMobile })}>
					<Typography variant="h1" gutterBottom>
						{"Subscription"}
					</Typography>
					<Typography variant="body1">Select the plan you want to subscribe to</Typography>

					<Grid item xs={12} justify='center'>
						<CouponComponent formProps={formProps} {...this.props} handelClickSendcouponName={this.handelClickSendcouponName} coupon={coupon} />
					</Grid>

					{
						employer.subscription.typeSubscription == 1 && employer.subscription.status == subscriptionStatus.SUBSCRIPTION_STATUS_ACTIVE &&
						<Grid item justify='center' style={{marginTop: '20px', marginBottom: '-50px'}}>
							<Typography variant="body2" align="center">
								<Box fontWeight="fontWeightBold" m={1}>
								To access these plans or add more than 10 employees, first please cancel your existing subscription through your iphone Settings > Apple ID > Subscriptions > Easy Payslip > Cancel
								</Box>
							</Typography>
						</Grid>
					}

					<Grid container alignContent="center" justify="center" className={classes.gridContainer}>
						{this.renderPlans(plansList, classes, activeEmployeeList, coupon)}
					</Grid>

					{
						(employer.subscription.status != subscriptionStatus.SUBSCRIPTION_STATUS_ACTIVE || employer.subscription.quantityEmployees < 10) &&
						<Grid item justify='left' style={{marginTop: '50px'}}>
							<Typography variant="body1" align="left">
								<Box m={1}>  {/* fontWeight="fontWeightBold" */}
								*For more than 10 employees please select 1-10 plan, then return to subscriptions to add additional employees
								</Box>
							</Typography>
						</Grid>
					}


					{employer.subscription.status === subscriptionStatus.SUBSCRIPTION_STATUS_ACTIVE && employer.subscription.typeSubscription === subscriptionType.SUBSCRIPTION_TYPE_STRIPE && employer.subscription.quantityEmployees >= 10 ? (
						<Grid container alignContent="center" justify="center">
							<Grid item xs={11}>
								<Paper className={classes.paperClear}>
									<Typography variant="h2" gutterBottom>
										Extra employees
									</Typography>
									<Typography variant="body1" gutterBottom>
										{"*11 employees and over is $2pm per additional employee"}
									</Typography>
									<TextField
										id="extraEmployee"
										label="Enter extra employee"
										variant="filled"
										className={clsx(classes.textField, {
											error: formProps.errors.extraEmployee && formProps.touched.extraEmployee,
											isMobile
										})}
										InputProps={{
											inputProps: { min: 0, step: 1 }
										}}
										type="number"
										min="0"
										step="1"
										// oninput="validity.valid||(value='');"
										value={formProps.values.extraEmployee}
										onChange={formProps.handleChange}
										onBlur={formProps.handleBlur}
									/>
									{formProps.errors.extraEmployee && formProps.touched.extraEmployee && <FormHelperText error>{formProps.errors.extraEmployee}</FormHelperText>}
									<Grid item xs={12} className={classes.gridRight}>
										<Button variant="contained" color="primary" onClick={formProps.handleSubmit}>
											Save
										</Button>
									</Grid>
								</Paper>
							</Grid>
						</Grid>
					) : null}
				</Paper>

			</form>
		);
	};

	render() {
		const { classes, employer } = this.props;

		return (
			<div className={classes.root}>
				<Formik
					initialValues={{
						extraEmployee: employer.subscription.quantityEmployees <= 10 ? 0 : employer.subscription.quantityEmployees - 10
					}}
					validationSchema={this.validationSchema}
					onSubmit={this.handleSubmitPlanExtra}
					render={this.renderForm}
				/>
			</div>
		);
	}
}

PlanComponent.propTypes = {
	plansList: PropTypes.array,
	coupon: PropTypes.array,
	getAllEmployees: PropTypes.func,
	loadPlansList: PropTypes.func,
	addEmployeeExtraSubscription: PropTypes.func,
	getCoupon: PropTypes.func,
};

const mapStateToProps = state => ({
	plansList: state.parameters.plansData,
	employer: state.session.userData,
	activeEmployeeList: state.employees.activeEmployeesList,
	coupon: state.coupon.item,
});

const mapDispatchToProps = dispatch => ({
	loadPlansList: bindActionCreators(parameterActions.getPlansRequest, dispatch),
	addEmployeeExtraSubscription: bindActionCreators(subscriptionActions.addEmployeeExtraSubscription, dispatch),
	getAllEmployees: bindActionCreators(employeeActions.employeesRequest, dispatch),
	couponRequest: bindActionCreators(CouponActions.couponRequest, dispatch),
	getCoupon: bindActionCreators(CouponActions.getCoupon, dispatch),
	cleanMessage: bindActionCreators(CouponActions.cleanMessage, dispatch)
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(PlanStyle)(PlanComponent));
