import * as common from "../constants/common";
import * as actionTypes from "../constants/actionTypes";
import localStorageApi from "../api/LocalStorageApi";

export function activateSubscription(token, planId, history,plan) {
	return {
		type: actionTypes.ACTION_SUBSCRIPTION_ACTIVATE,
		payload: {
			isLoading: true,
			token,
			planId,
			history,
			plan
		}
	};
}

export function activateSubscriptionSuccess(subscriptionData, messageCode, message) {

	const userData = localStorageApi.getItem(common.STORAGE_SESSION_NAME);
	userData.subscription = subscriptionData;
	localStorageApi.setItem(common.STORAGE_SESSION_NAME, userData);

	return {
		type: actionTypes.ACTION_SUBSCRIPTION_ACTIVATE_SUCCESS,
		payload: {
			subscriptionData,
			messageCode,
			message
		}
	};
}

export function activateSubscriptionFailure(message) {
	return {
		type: actionTypes.ACTION_SUBSCRIPTION_ACTIVATE_FAILURE,
		error: true,
		payload: {
			errorMessage: message
		}
	};
}

export function getStatusSubscription(history) {
	return {
		type: actionTypes.ACTION_SUBSCRIPTION_GET_STATUS,
		payload: {
			isLoading: true,
			history
		}
	};
}

export function getStatusSubscriptionSuccess(statusSubscriptionData, messageCode, message) {
	return {
		type: actionTypes.ACTION_SUBSCRIPTION_GET_STATUS_SUCCESS,
		payload: {
			statusSubscriptionData,
			messageCode,
			message
		}
	};
}

export function getStatusSubscriptionFailure(message) {
	return {
		type: actionTypes.ACTION_SUBSCRIPTION_GET_STATUS_FAILURE,
		error: true,
		payload: {
			errorMessage: message
		}
	};
}

export function addEmployeeExtraSubscription(quantityEmployees, history) {
	return {
		type: actionTypes.ACTION_SUBSCRIPTION_EMPLOYEE_EXTRA,
		error: false,
		payload: {
			error: false,
			isLoading: true,
			quantityEmployees,
			history
		}
	};
}

export function addEmployeeExtraSubscriptionSuccess(subscriptionData, messageCode, message) {
	const userData = localStorageApi.getItem(common.STORAGE_SESSION_NAME);
	userData.subscription = subscriptionData;
	localStorageApi.setItem(common.STORAGE_SESSION_NAME, userData);

	return {
		type: actionTypes.ACTION_SUBSCRIPTION_EMPLOYEE_EXTRA_SUCCESS,
		error: false,
		payload: {
			subscriptionData,
			messageCode,
			message,
			error: false,
			errorMessage: '',
		}
	};
}

export function addEmployeeExtraSubscriptionFailure(message) {
	return {
		type: actionTypes.ACTION_SUBSCRIPTION_EMPLOYEE_EXTRA_FAILURE,
		error: true,
		payload: {
			error: true,
			errorMessage: message
		}
	};
}

export function updateCreditCardSubscription(token, history) {
	return {
		type: actionTypes.ACTION_SUBSCRIPTION_UPDATE_CREDIT_CARD,
		payload: {
			isLoading: true,
			token,
			history
		}
	};
}

export function updateCreditCardSubscriptionSuccess(subscriptionData, messageCode, message) {
	const userData = localStorageApi.getItem(common.STORAGE_SESSION_NAME);
	userData.subscription = subscriptionData;
	localStorageApi.setItem(common.STORAGE_SESSION_NAME, userData);

	return {
		type: actionTypes.ACTION_SUBSCRIPTION_UPDATE_CREDIT_CARD_SUCCESS,
		error: false,
		payload: {
			errorMessage: "",
			subscriptionData,
			messageCode,
			message
		}
	};
}

export function updateCreditCardSubscriptionFailure(message) {
	return {
		type: actionTypes.ACTION_SUBSCRIPTION_UPDATE_CREDIT_CARD_FAILURE,
		error: true,
		payload: {
			errorMessage: message
		}
	};
}

export function cancelSubscription(history) {
	return {
		type: actionTypes.ACTION_SUBSCRIPTION_CANCEL,
		payload: {
			isLoading: true,
			history
		}
	};
}

export function cancelSubscriptionSuccess(subscriptionData, messageCode, message) {
	const userData = localStorageApi.getItem(common.STORAGE_SESSION_NAME);
	userData.subscription = subscriptionData;
	localStorageApi.setItem(common.STORAGE_SESSION_NAME, userData);

	return {
		type: actionTypes.ACTION_SUBSCRIPTION_CANCEL_SUCCESS,
		payload: {
			subscriptionData,
			messageCode,
			message
		}
	};
}

export function cancelSubscriptionFailure(message) {
	return {
		type: actionTypes.ACTION_SUBSCRIPTION_CANCEL_FAILURE,
		error: true,
		payload: {
			errorMessage: message
		}
	};
}
