import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";

import SidebarComponent from "../components/home/sidebarComponent";
import { SessionUserStyles } from "./PagesStyle";
import PaymentComponent from "../components/payment/paymentComponent";
import { LoadingComponent, SnackbarCommonComponent } from "../components/common";
import { mapMessageCodeToIcon } from "../helpers/extensions";
import { cleanMessage } from "../actions/PaymentActions";

class PaymentPage extends Component {
	render() {
		const {
			classes, isLoading, messageCode, message, cleanMessageInternal, mixpanel
		} = this.props;

		return (
			<div className={classes.root}>
				<CssBaseline />
				<SidebarComponent />
				<main className={classes.content}>
					<LoadingComponent isLoading={isLoading} />
					<SnackbarCommonComponent key="SnackBarUpdatePayment" variant={mapMessageCodeToIcon(messageCode)} open={(!!message)} body={message} onCloseModal={cleanMessageInternal} />
					<div className={classes.appBarSpacer} />
					<PaymentComponent mixpanel={mixpanel} />
				</main>
			</div>
		);
	}
}

PaymentPage.propTypes = {
	classes: PropTypes.object.isRequired,
	isLoading: PropTypes.bool,
	messageCode: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),
	message: PropTypes.string
};

const mapStateToProps = state => ({
	isLoading: state.employees.isLoading || state.payment.isLoading,
	messageCode: state.payment.messageCode,
	message: state.payment.message || state.payment.errorMessage
});

const mapDispatchToProps = dispatch => ({
	cleanMessageInternal: bindActionCreators(cleanMessage, dispatch)
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(SessionUserStyles)(PaymentPage));
