import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import {
	Checkbox, Grid, Icon, IconButton, Menu, MenuItem, Typography
} from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";

import employeeStyle from "./employeeStyle";

import { AvatarCommonComponent, SubscriptionUpgradeComponent, Analytics } from "../common";
import * as routes from "../../constants/routes";
import ModalInactiveComponent from "./modalInactiveComponent";
import ModalActiveComponent from "./modalActiveComponent";
import { activeOrInactiveEmployee, selectEmployeeToggle } from "../../actions/EmployeeActions";
import { getLocalToUTC } from "../../helpers/dateextensions";

class EmployeeItemComponent extends Component {
	state = {
		anchorEl: null,
		openInactiveDialog: false,
		openActiveDialog: false,
		employeeSelected: null,
		openUpgradeDialog: false
	};

	handleOpenMenu = (event) => {
		// console.log("OPEN MENU");
		this.setState({ anchorEl: event.currentTarget });
		event.persist();
	};

	handleCloseMenu = () => {
		this.setState({ anchorEl: null });
	};

	handleClickEdit = () => {
		const { history, employee } = this.props;
		Analytics.editEmployeeAction();
		// console.log("EDIT EMPLOYEE");
		history.push(`${routes.PATH_EMPLOYEE_EDIT}/${employee.id}`);
	};

	handleClickActiveInactive = (employee) => {
		this.setState({ employeeSelected: employee });

		if (employee.active) {
			this.setState({ openInactiveDialog: true });
		} else {
			const { activeEmployeeList, employer } = this.props;
			if (employer.subscription.quantityEmployees >= activeEmployeeList.length + 1) {
				this.setState({ openActiveDialog: true });
			} else {
				this.handleOpenUpgrade();
			}
		}

		this.handleCloseMenu();
	};

	handleOpenUpgrade = () => {
		this.setState(prevState => ({ ...prevState, openUpgradeDialog: true }));
	};

	handleCloseUpgrade = () => {
		this.setState(prevState => ({ ...prevState, openUpgradeDialog: false }));
	};

	handlePrimaryClickDialog = (date) => {
		const { activeOrInactiveEmployees } = this.props;
		const { employeeSelected } = this.state;
		date=getLocalToUTC(date)
		activeOrInactiveEmployees([
			{
				...employeeSelected,
				active: !employeeSelected.active,
				cessationDate: employeeSelected.active ?date: undefined,
				commencementDate: !employeeSelected.active ?date: undefined
			}
		]);
		this.handleCloseDialog();
	};

	handleCloseDialog = () => {
		this.setState({ openInactiveDialog: false });
		this.setState({ openActiveDialog: false });
	};

	handleSelectToggleClick = (event, checked) => {
		const { selectEmployeeToggleInternal, employee } = this.props;

		selectEmployeeToggleInternal(employee, checked);
	};

	isChecked = () => {
		const { selectedEmployeeList, employee } = this.props;

		const isCheckedValue = selectedEmployeeList.some(element => element.id === employee.id);
		return isCheckedValue;
	};

	render() {
		const { classes, employee } = this.props;
		const {
			anchorEl, openActiveDialog, openInactiveDialog, openUpgradeDialog
		} = this.state;

		return (
			<div>
				<Grid container wrap="nowrap" spacing={2} alignItems="center" className={classes.containerItem}>
					<Grid item xs={1}>
						<Checkbox color="primary" icon={<Icon className={classes.IconCheck}>panorama_fish_eye</Icon>} checkedIcon={<Icon className={classes.IconCheckActive}>check_circle</Icon>} id={employee.id} checked={this.isChecked()} onChange={this.handleSelectToggleClick} />
					</Grid>
					<Grid item xs={1} onClick={this.handleClickEdit} className={classes.option}>
						<AvatarCommonComponent employeeId={employee.id} text={`${employee.firstName ? employee.firstName.substring(0, 1) : ""}${employee.lastName ? employee.lastName.substring(0, 1) : ""}`} hover={false} src={employee.imageUrl} />
					</Grid>
					<Grid item xs={10} onClick={this.handleClickEdit} className={classes.option}>
						<Typography variant="body1">{employee.name}</Typography>
						<Typography variant="subtitle1">{employee.jobTitle}</Typography>
					</Grid>
					<Grid item md className={classes.alignItemMenu}>
						<IconButton onClick={this.handleOpenMenu}>
							<MoreVert />
						</IconButton>
						<Menu
							anchorEl={anchorEl}
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "right"
							}}
							open={Boolean(anchorEl)}
							onClose={this.handleCloseMenu}
							getContentAnchorEl={null}
							PaperProps={{
								style: {
									width: 150
								}
							}}
						>
							<MenuItem onClick={this.handleClickEdit}>Edit</MenuItem>
							{!employee.temporal ? (
								<MenuItem
									onClick={() => {
										this.handleClickActiveInactive(employee);
									}}
								>
									{employee.active ? "Inactivate" : "Reactivate"}
								</MenuItem>
							) : null}
						</Menu>
					</Grid>
				</Grid>
				<ModalInactiveComponent open={openInactiveDialog} onCloseModal={this.handleCloseDialog} onPrimaryButtonClick={this.handlePrimaryClickDialog} />
				<ModalActiveComponent open={openActiveDialog} onCloseModal={this.handleCloseDialog} onPrimaryButtonClick={this.handlePrimaryClickDialog} />
				{openUpgradeDialog ? <SubscriptionUpgradeComponent open={openUpgradeDialog} onCloseModal={this.handleCloseUpgrade} showMessageEmployees /> : null}
			</div>
		);
	}
}

EmployeeItemComponent.propTypes = {
	employee: PropTypes.object.isRequired,
	selectedEmployeeList: PropTypes.array,
	activeEmployeeList: PropTypes.array,
	employer: PropTypes.object,

	activeOrInactiveEmployees: PropTypes.func,
	selectEmployeeToggleInternal: PropTypes.func
};

const mapStateToProps = state => ({
	activeEmployeeList: state.employees.activeEmployeesList,
	selectedEmployeeList: state.employees.selectedEmployeeList,
	employer: state.session.userData
});

const mapDispatchToProps = dispatch => ({
	activeOrInactiveEmployees: bindActionCreators(activeOrInactiveEmployee, dispatch),
	selectEmployeeToggleInternal: bindActionCreators(selectEmployeeToggle, dispatch)
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(withStyles(employeeStyle)(EmployeeItemComponent)));
