import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Paper, TextField, Box, Card, CardActionArea, Typography } from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";
import settingStyle from "./settingStyle";
import CheckIcon from '@material-ui/icons/Check';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

import { DialogCommonComponent, SwitchCommonComponent } from "../common";
import * as notificationActions from "../../actions/NotificationActions";
import DialogCommonComponentNew from "./dialogCommonComponentNew";
import { updateActiveFY } from "../../actions/FYActions";
var moment = require('moment');

class ModaSelectFY extends Component {
    state = {
        selectedFY: "",
        previousFY: "",
        modalType: "selection",
        dateFrom: "",
        dateTo: ""
    }

	componentDidMount() {
		if (this.props.fyData) {
            this.props.fyData.list.forEach(fy => {
                if (fy.active) {
                    this.setState({ selectedFY: fy.name, previousFY: fy.name, dateFrom: this.convertTimeFormat(fy.dateFrom), dateTo: this.convertTimeFormat(fy.dateTo) });
                }
            });
        }
	}

	handleSubmitSave = (values, actions) => {

    };

    handleUpdateFY = (selectedFY) => {
        this.props.updateActiveFY(selectedFY);
    }

	componentWillReceiveProps = (nextProps) => {
        if (nextProps.success) {
            if (this.state.selectedFY < this.state.previousFY) {
                this.setState({ modalType: "backInfo" });
            } else {
                this.setState({ modalType: "forwardInfo" });
            }
        }
    };

    handleSelectYear = (year, dateFrom, dateTo) => {
        this.setState({
            selectedFY: year,
            dateFrom: dateFrom,
            dateTo: dateTo
        });
    }

    renderFYListItem = (year, periodFrom, periodTo, available, selected) => {
        return (
            <Card borderRadius={6} border={1} borderColor="#DFDFE1" style={{height: 72, marginBottom: 20}}>
                <CardActionArea style={{ height: "100%", padding: 13 }} disabled={!available} onClick={() => { this.handleSelectYear(year, periodFrom, periodTo); }}>
                    <Grid container justify="space-between">
                        <Grid direction="column" justify="space-between" style={{ height: "100%" }}>
                            <Typography variant="h4" style={{ marginBottom: 5 }}>
                                {year}
                            </Typography>
                            <Typography variant="subtitle1" style={{ color: "#09090991", fontSize: 15 }}>
                                {
                                    available ? periodFrom + " - " + periodTo : "Not Available"
                                }
                            </Typography>
                        </Grid>
                        <Grid item style={{ marginRight: 15, marginTop: 5 }}>
                        {
                            available &&
                            (
                                selected ?
                                    <CheckCircleIcon htmlColor="#005CFF" style={{ width: 30, height: 30 }} />
                                    : <RadioButtonUncheckedIcon htmlColor="#DFDFE1" style={{ width: 30, height: 30 }} />
                            )
                        }
                        </Grid>
                    </Grid>
                </CardActionArea>
            </Card>
        );
    }

    convertTimeFormat = (timeString) => {
        return timeString.split("T")[0].split("-").reverse().join("/");
    }

	render() {
        const { onCloseModal, fyData } = this.props;

        // const selectFYData = {
        //     "list":[{"name":"FY2019","active":false,"dateFrom":"2018-07-01T00:00:00.000Z","dateTo":"2019-06-30T12:59:59.000Z","available":true},{"name":"FY2020","active":true,"dateFrom":"2019-07-01T00:00:00.000Z","dateTo":"2020-06-30T12:59:59.000Z","available":true},{"name":"FY2021","active":false,"dateFrom":"2020-07-01T00:00:00.000Z","dateTo":"2021-06-30T12:59:59.000Z","available":false}]
        // };

        if (this.state.modalType == "selection") {
            return (
                <DialogCommonComponentNew
                    title="Select Financial Year" size="xs" onPrimaryButtonClick={() => {this.handleUpdateFY(this.state.selectedFY)}} primaryButtonDisabled={this.state.selectedFY == this.state.previousFY} open onCloseModal={onCloseModal}
                    style={{ borderRadius: 5, height: 40, minWidth: 200, marginLeft: 0, marginBottom: 0, marginTop: -15 }}
                    style2={{
                        fontFamily: "AvenirNextLTPro-Demi",
                        fontSize: "15px"
                    }}
                    primaryButtonName="Proceed">
                    {fyData && fyData.list.map(fyItem => {
                        return this.renderFYListItem(fyItem.name, this.convertTimeFormat(fyItem.dateFrom), this.convertTimeFormat(fyItem.dateTo), fyItem.available, fyItem.name == this.state.selectedFY);
                    })}
                </DialogCommonComponentNew>
            );
        } else if (this.state.modalType == "backInfo") {
            return (
                <DialogCommonComponentNew
                    title={"You have moved your Account back to " + this.state.selectedFY} size="xs" onPrimaryButtonClick={onCloseModal} primaryButtonDisabled={false} open
                    style={{ borderRadius: 5, height: 40, minWidth: 200, marginLeft: 0, marginBottom: 0 }}
                    style2={{
                        fontFamily: "AvenirNextLTPro-Demi",
                        fontSize: "15px"
                    }}
                    primaryButtonName="Continue">
                        <Typography variant="body1" align="center" style={{ marginBottom: 15, color: "#505050" }}>
                            This means you are only able to process payslips with a Payment Date between {moment(this.state.dateFrom, "DD/MM/YYYY").format("D MMM YYYY")} to {moment(this.state.dateTo, "DD/MM/YYYY").format("D MMM YYYY")}.
                        </Typography>
                        <Typography variant="body1" align="center" style={{ marginBottom: 15, color: "#505050" }}>
                            If you do create Payslips then you should also Lodge an updated '{this.state.selectedFY} Final STP File' for those employees.
                        </Typography>
                        <Typography variant="body1" align="center" style={{ marginBottom: 15, color: "#505050" }}>
                            Please note that if you have already created payslips in {this.state.previousFY}, and now create further payslips in {this.state.selectedFY}, any Leave Entitlement Balances will be updated based on the most recently created payslip.
                        </Typography>
                        <Typography variant="body1" align="center" style={{ color: "#505050" }}>
                            Please refer to the <a href="https://easypayslip.zendesk.com/hc/en-us/articles/360001469915" target="_blank">Help Guide Processing Payslips</a> in Prior Financial Years.
                        </Typography>
                </DialogCommonComponentNew>
            );
        } else {
            return (
                <DialogCommonComponentNew
                    title={"You are now in " + this.state.selectedFY} size="xs" onPrimaryButtonClick={onCloseModal} primaryButtonDisabled={false} open
                    style={{ borderRadius: 5, height: 40, minWidth: 200, marginLeft: 0, marginBottom: 0 }}
                    style2={{
                        fontFamily: "AvenirNextLTPro-Demi",
                        fontSize: "15px"
                    }}
                    primaryButtonName="Continue">
                        <Typography variant="body1" align="center" style={{ marginBottom: 15, color: "#505050" }}>
                            This means you are only able to process payslips with a Payment Date between {moment(this.state.dateFrom, "DD/MM/YYYY").format("D MMM YYYY")} to {moment(this.state.dateTo, "DD/MM/YYYY").format("D MMM YYYY")}.
                        </Typography>
                        <Typography variant="body1" align="center" style={{ marginBottom: 15, color: "#505050" }}>
                            If you have not already done so, you may want to lodge a Final STP File for {this.state.previousFY}.
                        </Typography>
                        <Typography variant="body1" align="center" style={{ marginBottom: 15, color: "#505050" }}>
                            This will update your Employees payment details with the ATO and enable them to complete their individual tax return.
                        </Typography>
                        <Typography variant="body1" align="center" style={{ color: "#505050" }}>
                            You can do this by clicking '{this.state.previousFY} Final STP File' in the Settings screen.
                        </Typography>
                </DialogCommonComponentNew>
            );
        }
	};
}

ModaSelectFY.propTypes = {
};

const mapStateToProps = state => ({
    fyData: state.employer.fydata,
    success: state.employer.success
});

const mapDispatchToProps = dispatch => ({
    updateActiveFY: bindActionCreators(updateActiveFY, dispatch),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(settingStyle)(ModaSelectFY));
