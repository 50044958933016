import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Paper, TextField } from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";
import settingStyle from "./settingStyle";

import { DialogCommonComponent, SwitchCommonComponent } from "../common";
import * as notificationActions from "../../actions/NotificationActions";
import notificationInitialState from "../../reducers/Notification/NotificationInitialState";

class ModalNotificationsComponent extends Component {
	validationSchema = Yup.object({
		sendEmployer: Yup.boolean(),
		sendEmployee: Yup.boolean(),
		emailEmployer: Yup.string().when("sendEmployer", {
			is: false,
			then: Yup.string()
				.email("Email is invalid")
				.notRequired(),
			otherwise: Yup.string().notRequired()
		}),
		emailEmployee: Yup.string().when("sendEmployee", {
			is: false,
			then: Yup.string()
				.email("Email is invalid")
				.notRequired(),
			otherwise: Yup.string().notRequired()
		})
	});

	componentDidMount() {
		const { getNotification } = this.props;
		getNotification();
	}

	handleSubmitSave = (values, actions) => {
		const { updateNotification } = this.props;
		actions.setSubmitting(false);

		updateNotification({
			...values,
			emailEmployer: !values.sendEmployer ? values.emailEmployer : undefined,
			emailEmployee: !values.sendEmployee ? values.emailEmployee : undefined
		});
	};

	componentWillReceiveProps = (nextProps) => {
		if (nextProps.isUpdate) {
			const { onCloseModal } = this.props;

			if (onCloseModal) {
				onCloseModal();
			}
		}
	};

	renderForm = (formProps) => {
		const { classes, open, onCloseModal } = this.props;

		return (
			<DialogCommonComponent title="Notifications" size="md" onPrimaryButtonClick={formProps.handleSubmit} primaryButtonDisabled={!formProps.isValid} open={open} onCloseModal={onCloseModal}>
				<form>
					<Grid container direction="row" alignItems="flex-start">
						<Grid item xs={6}>
							<Paper className={classes.paperGrid}>
								<SwitchCommonComponent id="sendEmployer" title="Send Payslip to Employer" checked={Boolean(formProps.values.sendEmployer)} onChange={formProps.handleChange} onBlur={formProps.handleBlur} value={formProps.values.sendEmployer} />
							</Paper>
						</Grid>
						<Grid item xs={6} className={classes.paperGrid}>
							{!formProps.values.sendEmployer ? (
								<Paper
									className={clsx(classes.paperGrid, {
										[classes.paperGridClearRight]: true
									})}
								>
									<TextField
										id="emailEmployer"
										label="New email recipient"
										variant="filled"
										fullWidth
										className={clsx(classes.textField, {
											error: formProps.errors.emailEmployer && formProps.touched.emailEmployer
										})}
										type="email"
										onChange={formProps.handleChange}
										onBlur={formProps.handleBlur}
										value={formProps.values.emailEmployer}
									/>
								</Paper>
							) : null}
						</Grid>
						<Grid item xs={6}>
							<Paper className={classes.paperGrid}>
								<SwitchCommonComponent id="sendEmployee" title="Send Payslip to Employee" checked={Boolean(formProps.values.sendEmployee)} onChange={formProps.handleChange} onBlur={formProps.handleBlur} value={formProps.values.sendEmployee} />
							</Paper>
						</Grid>
						<Grid item xs={6} className={classes.paperGrid}>
							{!formProps.values.sendEmployee ? (
								<Paper
									className={clsx(classes.paperGrid, {
										[classes.paperGridClearRight]: true
									})}
								>
									<TextField
										id="emailEmployee"
										label="New email recipient"
										variant="filled"
										fullWidth
										className={clsx(classes.textField, {
											error: formProps.errors.emailEmployee && formProps.touched.emailEmployee
										})}
										type="email"
										onChange={formProps.handleChange}
										onBlur={formProps.handleBlur}
										value={formProps.values.emailEmployee}
									/>
								</Paper>
							) : null}
						</Grid>
					</Grid>
				</form>
			</DialogCommonComponent>
		);
	};

	render() {
		const { notificationData } = this.props;

		const notification = {
			...notificationInitialState.notificationData,
			...notificationData
		};

		return <Formik initialValues={{ ...notification }} validationSchema={this.validationSchema} onSubmit={this.handleSubmitSave} render={this.renderForm} ref={this.form} enableReinitialize />;
	}
}

ModalNotificationsComponent.propTypes = {
	notificationData: PropTypes.object,
	getNotification: PropTypes.func,
	updateNotification: PropTypes.func
};

const mapStateToProps = state => ({
	notificationData: state.notification.notificationData,
	isUpdate: state.notification.isUpdate
});

const mapDispatchToProps = dispatch => ({
	getNotification: bindActionCreators(notificationActions.getNotificationRequest, dispatch),
	updateNotification: bindActionCreators(notificationActions.updateNotificationRequest, dispatch)
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(settingStyle)(ModalNotificationsComponent));
