const inviteAdditionalUser = async (httpClient, requestData) => {
	const { goEpsApi } = window.envVariables;
	const response = await httpClient.post(`${goEpsApi}/api/v1/employer/invite`, requestData);
	return response;
};

const getInviteAdditionalUser = async httpClient => {
	const { goEpsApi } = window.envVariables;

	const response = await httpClient.get(`${goEpsApi}/api/v1/employer/accountants`);
	return response;
};

const AdditionalUserRevoke = async (httpClient, accountantId) => {

	const { goEpsApi } = window.envVariables;

	const response = await httpClient.delete(`${goEpsApi}/api/v1/employer/accountants/${accountantId}`);
	return response;
};

export default {
	inviteAdditionalUser,
	getInviteAdditionalUser,
	AdditionalUserRevoke
};


