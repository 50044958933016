// Session
export const ACTION_SESSION_USER_LOGGING_IN = "ACTION_SESSION_USER_LOGGING_IN";
export const ACTION_SESSION_USER_LOGGING_IN_SUCCESS =
  "ACTION_SESSION_USER_LOGGING_IN_SUCCESS";
export const ACTION_SESSION_USER_LOGGING_IN_FAILURE =
  "ACTION_SESSION_USER_LOGGING_IN_FAILURE";
//  EMPLOYER
export const ACTION_SESSION_EMPLOYER_LOGGING_IN =
  "ACTION_SESSION_EMPLOYER_LOGGING_IN";
export const ACTION_SESSION_EMPLOYER_LOGGING_IN_SUCCESS =
  "ACTION_SESSION_EMPLOYER_LOGGING_IN_SUCCESS";
export const ACTION_SESSION_EMPLOYER_LOGGING_IN_FAILURE =
  "ACTION_SESSION_EMPLOYER_LOGGING_IN_FAILURE";

export const ACTION_SESSION_USER_LOGGING_2FA =
  "ACTION_SESSION_USER_LOGGING_2FA";
export const ACTION_SESSION_USER_LOGGING_2FA_SUCCESS =
  "ACTION_SESSION_USER_LOGGING_2FA_SUCCESS";
export const ACTION_SESSION_USER_LOGGING_2FA_FAILURE =
  "ACTION_SESSION_USER_LOGGING_2FA_FAILURE";

export const ACTION_SESSION_USER_LOGGING_2FA_EMAIL =
  "ACTION_SESSION_USER_LOGGING_2FA_EMAIL";
export const ACTION_SESSION_USER_LOGGING_2FA_EMAIL_SUCCESS =
  "ACTION_SESSION_USER_LOGGING_2FA_EMAIL_SUCCESS";
export const ACTION_SESSION_USER_LOGGING_2FA_EMAIL_FAILURE =
  "ACTION_SESSION_USER_LOGGING_2FA_EMAIL_FAILURE";

export const ACTION_SESSION_USER_SIGN_UP = "ACTION_SESSION_USER_SIGN_UP";
export const ACTION_SESSION_USER_SIGN_UP_SUCCESS =
  "ACTION_SESSION_USER_SIGN_UP_SUCCESS";
export const ACTION_SESSION_USER_SIGN_UP_FAILURE =
  "ACTION_SESSION_USER_SIGN_UP_FAILURE";

export const ACTION_SESSION_USER_FORGOT_PASSWORD =
  "ACTION_SESSION_USER_FORGOT_PASSWORD";
export const ACTION_SESSION_USER_FORGOT_PASSWORD_SUCCESS =
  "ACTION_SESSION_USER_FORGOT_PASSWORD_SUCCESS";
export const ACTION_SESSION_USER_FORGOT_PASSWORD_FAILURE =
  "ACTION_SESSION_USER_FORGOT_PASSWORD_FAILURE";

export const ACTION_SESSION_USER_CHANGE_PASSWORD =
  "ACTION_SESSION_USER_CHANGE_PASSWORD";
export const ACTION_SESSION_USER_CHANGE_PASSWORD_SUCCESS =
  "ACTION_SESSION_USER_CHANGE_PASSWORD_SUCCESS";
export const ACTION_SESSION_USER_CHANGE_PASSWORD_FAILURE =
  "ACTION_SESSION_USER_CHANGE_PASSWORD_FAILURE";

export const ACTION_SESSION_USER_FORGOT_VALIDATION =
  "ACTION_SESSION_USER_FORGOT_VALIDATION";
export const ACTION_SESSION_USER_FORGOT_VALIDATION_SUCCESS =
  "ACTION_SESSION_USER_FORGOT_VALIDATION_SUCCESS";
export const ACTION_SESSION_USER_FORGOT_VALIDATION_FAILURE =
  "ACTION_SESSION_USER_FORGOT_VALIDATION_FAILURE";

export const ACTION_SESSION_USER_LOGIN_UNIFY =
  "ACTION_SESSION_USER_LOGIN_UNIFY";
export const ACTION_SESSION_USER_LOGIN_UNIFY_SUCCESS =
  "ACTION_SESSION_USER_LOGIN_UNIFY_SUCCESS";
export const ACTION_SESSION_USER_LOGIN_UNIFY_FAILURE =
  "ACTION_SESSION_USER_LOGIN_UNIFY_FAILURE";

export const ACTION_SESSION_USER_UPDATE_INFO =
  "ACTION_SESSION_USER_UPDATE_INFO";
export const ACTION_SESSION_UPDATE_AUTHORIZED_PERSON =
  "ACTION_SESSION_UPDATE_AUTHORIZED_PERSON";

export const ACTION_SESSION_LOGOUT = "ACTION_SESSION_LOGOUT";

export const ACTION_SESSION_LOGOUT_API = "ACTION_SESSION_LOGOUT_API";
export const ACTION_SESSION_LOGOUT_API_SUCCESS =
  "ACTION_SESSION_LOGOUT_API_SUCCESS";
export const ACTION_SESSION_LOGOUT_API_FAILURE =
  "ACTION_SESSION_LOGOUT_API_FAILURE";

export const ACTION_EXTERNAL_CLEAN_MESSAGE = "ACTION_EXTERNAL_CLEAN_MESSAGE";

export const ACTION_SESSION_CLEAN_MESSAGE = "ACTION_SESSION_CLEAN_MESSAGE";

// Employee
export const ACTION_EMPLOYEES_REQUEST = "ACTION_EMPLOYEES_REQUEST";
export const ACTION_EMPLOYEES_REQUEST_SUCCESS =
  "ACTION_EMPLOYEES_REQUEST_SUCCESS";
export const ACTION_EMPLOYEES_REQUEST_FAILURE =
  "ACTION_EMPLOYEES_REQUEST_FAILURE";

export const ACTION_EMPLOYEES_SELECT_TOGGLE = "ACTION_EMPLOYEES_SELECT_TOGGLE";
export const ACTION_EMPLOYEES_SELECT_ALL_TOGGLE =
  "ACTION_EMPLOYEES_SELECT_ALL_TOGGLE";

export const ACTION_EMPLOYEE_DATA_REQUEST = "ACTION_EMPLOYEE_DATA_REQUEST";
export const ACTION_EMPLOYEE_DATA_REQUEST_SUCCESS =
  "ACTION_EMPLOYEE_DATA_REQUEST_SUCCESS";
export const ACTION_EMPLOYEE_DATA_REQUEST_FAILURE =
  "ACTION_EMPLOYEE_DATA_REQUEST_FAILURE";

export const ACTION_EMPLOYEE_SAVE_DATA_REQUEST =
  "ACTION_EMPLOYEE_SAVE_DATA_REQUEST";
export const ACTION_EMPLOYEE_SAVE_DATA_REQUEST_SUCCESS =
  "ACTION_EMPLOYEE_SAVE_DATA_REQUEST_SUCCESS";
export const ACTION_EMPLOYEE_SAVE_DATA_REQUEST_FAILURE =
  "ACTION_EMPLOYEE_SAVE_DATA_REQUEST_FAILURE";

export const ACTION_EMPLOYEE_FINISH_REQUEST = "ACTION_EMPLOYEE_FINISH_REQUEST";

export const ACTION_EMPLOYEE_UPLOAD_IMAGE_REQUEST =
  "ACTION_EMPLOYEE_UPLOAD_IMAGE_REQUEST";
export const ACTION_EMPLOYEE_UPLOAD_IMAGE_REQUEST_SUCCESS =
  "ACTION_EMPLOYEE_UPLOAD_IMAGE_REQUEST_SUCCESS";
export const ACTION_EMPLOYEE_UPLOAD_IMAGE_REQUEST_FAILURE =
  "ACTION_EMPLOYEE_UPLOAD_IMAGE_REQUEST_FAILURE";

export const ACTION_EMPLOYEE_CLEAN_DATA = "ACTION_EMPLOYEE_CLEAN_DATA";
export const ACTION_EMPLOYEES_TEMPINFORMATION =
  "ACTION_EMPLOYEES_TEMPINFORMATION";
export const ACTION_EMPLOYEES_UPDATE_STATUS = "ACTION_EMPLOYEES_UPDATE_STATUS";

export const ACTION_EMPLOYEE_ACTIVE_OR_INACTIVE =
  "ACTION_EMPLOYEE_ACTIVE_OR_INACTIVE";
export const ACTION_EMPLOYEE_ACTIVE_OR_INACTIVE_SUCCESS =
  "ACTION_EMPLOYEE_ACTIVE_OR_INACTIVE_SUCCESS";
export const ACTION_EMPLOYEE_ACTIVE_OR_INACTIVE_FAILURE =
  "ACTION_EMPLOYEE_ACTIVE_OR_INACTIVE_FAILURE";

export const ACTION_EMPLOYEE_CLEAN_MESSAGE = "ACTION_EMPLOYEE_CLEAN_MESSAGE";
export const ACTION_SAVE_EMPLOYEE_WITH_FINISH =
  "ACTION_SAVE_EMPLOYEE_WITH_FINISH";

// Reports
export const ACTION_REPORTS_PROCESS_REQUEST = "ACTION_REPORTS_PROCESS_REQUEST";
export const ACTION_REPORTS_PROCESS_REQUEST_SUCCESS =
  "ACTION_REPORTS_PROCESS_REQUEST_SUCCESS";
export const ACTION_REPORTS_PROCESS_REQUEST_FAILURE =
  "ACTION_REPORTS_PROCESS_REQUEST_FAILURE";

export const ACTION_REPORTS_PAYSLIPS_REQUEST =
  "ACTION_REPORTS_PAYSLIPS_REQUEST";
export const ACTION_REPORTS_PAYSLIPS_REQUEST_SUCCESS =
  "ACTION_REPORTS_PAYSLIPS_REQUEST_SUCCESS";
export const ACTION_REPORTS_PAYSLIPS_REQUEST_FAILURE =
  "ACTION_REPORTS_PAYSLIPS_REQUEST_FAILURE";

export const ACTION_REPORTS_CLEAN_DATA = "ACTION_REPORTS_CLEAN_DATA";
export const ACTION_REPORTS_PAYSLIPS_CLEAN_DATA =
  "ACTION_REPORTS_PAYSLIPS_CLEAN_DATA";

export const ACTION_REPORTS_CLEAN_MESSAGE = "ACTION_REPORTS_CLEAN_MESSAGE";

export const ACTION_REPORTS_PROCESS_ACTIVITY_DETAIL_REQUEST =
  "ACTION_REPORTS_PROCESS_ACTIVITY_DETAIL_REQUEST";
export const ACTION_REPORTS_PROCESS_ACTIVITY_DETAIL_REQUEST_SUCCESS =
  "ACTION_REPORTS_PROCESS_ACTIVITY_DETAIL_REQUEST_SUCCESS";
export const ACTION_REPORTS_PROCESS_ACTIVITY_DETAIL_REQUEST_FAILURE =
  "ACTION_REPORTS_PROCESS_ACTIVITY_DETAIL_REQUEST_FAILURE";

// Reports Payroll
export const ACTION_PAYROLL_SEND_EMAIL_REQUEST =
  "ACTION_PAYROLL_SEND_EMAIL_REQUEST";
export const ACTION_PAYROLL_SEND_EMAIL_REQUEST_SUCCESS =
  "ACTION_PAYROLL_SEND_EMAIL_REQUEST_SUCCESS";
export const ACTION_PAYROLL_SEND_EMAIL_REQUEST_FAILURE =
  "ACTION_PAYROLL_SEND_EMAIL_REQUEST_FAILURE";

export const ACTION_PAYROLL_DELETE_REQUEST = "ACTION_PAYROLL_DELETE_REQUEST";
export const ACTION_PAYROLL_DELETE_REQUEST_SUCCESS =
  "ACTION_PAYROLL_DELETE_REQUEST_SUCCESS";
export const ACTION_PAYROLL_DELETE_REQUEST_FAILURE =
  "ACTION_PAYROLL_DELETE_REQUEST_FAILURE";

export const ACTION_PAYROLL_SAVE_DATA_REQUEST =
  "ACTION_PAYROLL_SAVE_DATA_REQUEST";
export const ACTION_PAYROLL_SAVE_DATA_REQUEST_SUCCESS =
  "ACTION_PAYROLL_SAVE_DATA_REQUEST_SUCCESS";
export const ACTION_PAYROLL_SAVE_DATA_REQUEST_FAILURE =
  "ACTION_PAYROLL_SAVE_DATA_REQUEST_FAILURE";

export const ACTION_PAYROLL_GET_EMPLOYEE_REQUEST =
  "ACTION_PAYROLL_GET_EMPLOYEE_REQUEST";
export const ACTION_PAYROLL_GET_EMPLOYEE_REQUEST_SUCCESS =
  "ACTION_PAYROLL_GET_EMPLOYEE_REQUEST_SUCCESS";
export const ACTION_PAYROLL_GET_EMPLOYEE_REQUEST_FAILURE =
  "ACTION_PAYROLL_GET_EMPLOYEE_REQUEST_FAILURE";

export const ACTION_PAYROLL_CLEAN_DATA = "ACTION_PAYROLL_CLEAN_DATA";

export const ACTION_PAYROLL_ADD_REQUEST = "ACTION_PAYROLL_ADD_REQUEST";
export const ACTION_PAYROLL_ADD_REQUEST_SUCCESS =
  "ACTION_PAYROLL_ADD_REQUEST_SUCCESS";
export const ACTION_PAYROLL_ADD_REQUEST_FAILURE =
  "ACTION_PAYROLL_ADD_REQUEST_FAILURE";

export const ACTION_PAYROLL_SEND_MAIL_REQUEST =
  "ACTION_PAYROLL_SEND_MAIL_REQUEST";
export const ACTION_PAYROLL_SEND_MAIL_REQUEST_SUCCESS =
  "ACTION_PAYROLL_SEND_MAIL_REQUEST_SUCCESS";
export const ACTION_PAYROLL_SEND_MAIL_REQUEST_FAILURE =
  "ACTION_PAYROLL_SEND_MAIL_REQUEST_FAILURE";

export const ACTION_PAYROLL_DELETED_REQUEST = "ACTION_PAYROLL_DELETED_REQUEST";
export const ACTION_PAYROLL_DELETED_REQUEST_SUCCESS =
  "ACTION_PAYROLL_DELETED_REQUEST_SUCCESS";
export const ACTION_PAYROLL_DELETED_REQUEST_FAILURE =
  "ACTION_PAYROLL_DELETED_REQUEST_FAILURE";

export const ACTION_PAYROLL_SAVE_PAYLOAD_DATA_REQUEST =
  "ACTION_PAYROLL_SAVE_PAYLOAD_DATA_REQUEST";
export const ACTION_PAYROLL_SAVE_PAYLOAD_DATA_REQUEST_SUCCESS =
  "ACTION_PAYROLL_SAVE_PAYLOAD_DATA_REQUEST_SUCCESS";
export const ACTION_PAYROLL_SAVE_PAYLOAD_DATA_REQUEST_FAILURE =
  "ACTION_PAYROLL_SAVE_PAYLOAD_DATA_REQUEST_FAILURE";
export const ACTION_PAYROLL_VALIDATE_IS_FINAL_SUCCESS =
  "ACTION_PAYROLL_VALIDATE_IS_FINAL_SUCCESS";

export const ACTION_PAYROLL_VALIDATE_REQUEST =
  "ACTION_PAYROLL_VALIDATE_REQUEST";
export const ACTION_PAYROLL_VALIDATE_REQUEST_SUCCESS =
  "ACTION_PAYROLL_VALIDATE_REQUEST_SUCCESS";
export const ACTION_PAYROLL_VALIDATE_REQUEST_FAILURE =
  "ACTION_PAYROLL_VALIDATE_REQUEST_FAILURE";

export const ACTION_PAYROLL_SAVE_PAYLOAD_DATA_COMPLETE =
  "ACTION_PAYROLL_SAVE_PAYLOAD_DATA_COMPLETE";

export const ACTION_PAYROLL_SEND_MAIL_NEXT = "ACTION_PAYROLL_SEND_MAIL_NEXT";

export const ACTION_FY_UPDATE_ACTIVE = "ACTION_FY_UPDATE_ACTIVE";
export const ACTION_FY_UPDATE_ACTIVE_SUCCESS = "ACTION_FY_UPDATE_ACTIVE_SUCCESS";
export const ACTION_FY_UPDATE_ACTIVE_FAILURE = "ACTION_FY_UPDATE_ACTIVE_FAILURE";

export const ACTION_FY_GET_AVAILABLE = "ACTION_FY_GET_AVAILABLE";
export const ACTION_FY_GET_AVAILABLE_SUCCESS = "ACTION_FY_GET_AVAILABLE_SUCCESS";
export const ACTION_FY_GET_AVAILABLE_FAILURE = "ACTION_FY_GET_AVAILABLE_FAILURE";


// STP
export const ACTION_STP_REPORT_SUMMARY_REQUEST =
  "ACTION_STP_REPORT_SUMMARY_REQUEST";
export const ACTION_STP_REPORT_SUMMARY_REQUEST_SUCCESS =
  "ACTION_STP_REPORT_SUMMARY_REQUEST_SUCCESS";
export const ACTION_STP_REPORT_SUMMARY_REQUEST_FAILURE =
  "ACTION_STP_REPORT_SUMMARY_REQUEST_FAILURE";

export const ACTION_STP_SEND_REQUEST = "ACTION_STP_SEND_REQUEST";
export const ACTION_STP_SEND_REQUEST_SUCCESS =
  "ACTION_STP_SEND_REQUEST_SUCCESS";
export const ACTION_STP_SEND_REQUEST_FAILURE =
  "ACTION_STP_SEND_REQUEST_FAILURE";

export const ACTION_STP_DELETE_REQUEST = "ACTION_STP_DELETE_REQUEST";
export const ACTION_STP_DELETE_REQUEST_SUCCESS = "ACTION_STP_DELETE_REQUEST_SUCCESS";
export const ACTION_STP_DELETE_REQUEST_FAILURE = "ACTION_STP_DELETE_REQUEST_FAILURE";

export const ACTION_STP_CLEAR_REQUEST = "ACTION_STP_CLEAR_REQUEST";
export const ACTION_STP_CLEAR_REQUEST_SUCCESS =
  "ACTION_STP_CLEAR_REQUEST_SUCCESS";
export const ACTION_STP_CLEAR_REQUEST_FAILURE =
  "ACTION_STP_CLEAR_REQUEST_FAILURE";

// Reports Payment
export const ACTION_REPORTS_PAYMENT_EMPLOYER_REQUEST =
  "ACTION_REPORTS_PAYMENT_EMPLOYER_REQUEST";
export const ACTION_REPORTS_PAYMENT_EMPLOYER_REQUEST_SUCCESS =
  "ACTION_REPORTS_PAYMENT_EMPLOYER_REQUEST_SUCCESS";
export const ACTION_REPORTS_PAYMENT_EMPLOYER_REQUEST_FAILURE =
  "ACTION_REPORTS_PAYMENT_EMPLOYER_REQUEST_FAILURE";

export const ACTION_REPORTS_PAYMENT_EMPLOYEE_REQUEST =
  "ACTION_REPORTS_PAYMENT_EMPLOYEE_REQUEST";
export const ACTION_REPORTS_PAYMENT_EMPLOYEE_REQUEST_SUCCESS =
  "ACTION_REPORTS_PAYMENT_EMPLOYEE_REQUEST_SUCCESS";
export const ACTION_REPORTS_PAYMENT_EMPLOYEE_REQUEST_FAILURE =
  "ACTION_REPORTS_PAYMENT_EMPLOYEE_REQUEST_FAILURE";

export const ACTION_PAYMENT_SEND_EMAIL_REQUEST =
  "ACTION_PAYMENT_SEND_EMAIL_REQUEST";
export const ACTION_PAYMENT_SEND_EMAIL_REQUEST_SUCCESS =
  "ACTION_PAYMENT_SEND_EMAIL_REQUEST_SUCCESS";
export const ACTION_PAYMENT_SEND_EMAIL_REQUEST_FAILURE =
  "ACTION_PAYMENT_SEND_EMAIL_REQUEST_FAILURE";

// Payment
export const ACTION_PAYMENT_PROCESS_REQUEST = "ACTION_PAYMENT_PROCESS_REQUEST";
export const ACTION_PAYMENT_PROCESS_REQUEST_SUCCESS =
  "ACTION_PAYMENT_PROCESS_REQUEST_SUCCESS";
export const ACTION_PAYMENT_PROCESS_REQUEST_FAILURE =
  "ACTION_PAYMENT_PROCESS_REQUEST_FAILURE";

export const ACTION_PAYMENT_CLEAN_MESSAGE = "ACTION_PAYMENT_CLEAN_MESSAGE";

// Employer
export const ACTION_AUTHORIZED_PERSON_REQUEST =
  "ACTION_AUTHORIZED_PERSON_REQUEST";
export const ACTION_AUTHORIZED_PERSON_REQUEST_SUCCESS =
  "ACTION_AUTHORIZED_PERSON_REQUEST_SUCCESS";
export const ACTION_AUTHORIZED_PERSON_REQUEST_FAILURE =
  "ACTION_AUTHORIZED_PERSON_REQUEST_FAILURE";

export const ACTION_EMPLOYER_CLEAN_DATA = "ACTION_EMPLOYER_CLEAN_DATA";

export const ACTION_EMPLOYER_EMAIL_GENERATE = "ACTION_EMPLOYER_EMAIL_GENERATE";
export const ACTION_EMPLOYER_EMAIL_GENERATE_SUCCESS =
  "ACTION_EMPLOYER_EMAIL_GENERATE_SUCCESS";
export const ACTION_EMPLOYER_EMAIL_GENERATE_FAILURE =
  "ACTION_EMPLOYER_EMAIL_GENERATE_FAILURE";

export const ACTION_EMPLOYER_EMAIL_VALIDATE = "ACTION_EMPLOYER_EMAIL_VALIDATE";
export const ACTION_EMPLOYER_EMAIL_VALIDATE_SUCCESS =
  "ACTION_EMPLOYER_EMAIL_VALIDATE_SUCCESS";
export const ACTION_EMPLOYER_EMAIL_VALIDATE_FAILURE =
  "ACTION_EMPLOYER_EMAIL_VALIDATE_FAILURE";

//2FA Text
export const ACTION_EMPLOYER_TEXT_GENERATE = "ACTION_EMPLOYER_TEXT_GENERATE";
export const ACTION_EMPLOYER_TEXT_GENERATE_SUCCESS =
  "ACTION_EMPLOYER_TEXT_GENERATE_SUCCESS";
export const ACTION_EMPLOYER_TEXT_GENERATE_FAILURE =
  "ACTION_EMPLOYER_TEXT_GENERATE_FAILURE";

export const ACTION_EMPLOYER_TEXT_VALIDATE = "ACTION_EMPLOYER_TEXT_VALIDATE";
export const ACTION_EMPLOYER_TEXT_VALIDATE_SUCCESS =
  "ACTION_EMPLOYER_TEXT_VALIDATE_SUCCESS";
export const ACTION_EMPLOYER_TEXT_VALIDATE_FAILURE =
  "ACTION_EMPLOYER_TEXT_VALIDATE_FAILURE";

//Login 2FA Text
export const ACTION_SESSION_USER_LOGGING_2FA_TEXT =
  "ACTION_SESSION_USER_LOGGING_2FA_TEXT";
export const ACTION_SESSION_USER_LOGGING_2FA_TEXT_SUCCESS =
  "ACTION_SESSION_USER_LOGGING_2FA_TEXT_SUCCESS";
export const ACTION_SESSION_USER_LOGGING_2FA_TEXT_FAILURE =
  "ACTION_SESSION_USER_LOGGING_2FA_TEXT_FAILURE";

// topt
export const ACTION_EMPLOYER_TOPT_GENERATE = "ACTION_EMPLOYER_TOPT_GENERATE";
export const ACTION_EMPLOYER_TOPT_GENERATE_SUCCESS =
  "ACTION_EMPLOYER_TOPT_GENERATE_SUCCESS";
export const ACTION_EMPLOYER_TOPT_GENERATE_FAILURE =
  "ACTION_EMPLOYER_TOPT_GENERATE_FAILURE";

export const ACTION_EMPLOYER_TOPT_VALIDATE = "ACTION_EMPLOYER_TOPT_VALIDATE";
export const ACTION_EMPLOYER_TOPT_VALIDATE_SUCCESS =
  "ACTION_EMPLOYER_TOPT_VALIDATE_SUCCESS";
export const ACTION_EMPLOYER_TOPT_VALIDATE_FAILURE =
  "ACTION_EMPLOYER_TOPT_VALIDATE_FAILURE";

export const ACTION_EMPLOYER_TOPT_REVOKE = "ACTION_EMPLOYER_TOPT_REVOKE";
export const ACTION_EMPLOYER_TOPT_REVOKE_SUCCESS =
  "ACTION_EMPLOYER_TOPT_REVOKE_SUCCESS";
export const ACTION_EMPLOYER_TOPT_REVOKE_FAILURE =
  "ACTION_EMPLOYER_TOPT_REVOKE_FAILURE";

export const ACTION_EMPLOYER_TOPT_DISABLED = "ACTION_EMPLOYER_TOPT_DISABLED";
export const ACTION_EMPLOYER_TOPT_DISABLED_SUCCESS =
  "ACTION_EMPLOYER_TOPT_DISABLED_SUCCESS";
export const ACTION_EMPLOYER_TOPT_DISABLED_FAILURE =
  "ACTION_EMPLOYER_TOPT_DISABLED_FAILURE";

export const ACTION_EMPLOYER_REQUEST = "ACTION_EMPLOYER_REQUEST";
export const ACTION_EMPLOYER_REQUEST_SUCCESS =
  "ACTION_EMPLOYER_REQUEST_SUCCESS";
export const ACTION_EMPLOYER_REQUEST_FAILURE =
  "ACTION_EMPLOYER_REQUEST_FAILURE";

export const ACTION_EMPLOYER_UPDATE = "ACTION_EMPLOYER_UPDATE";
export const ACTION_EMPLOYER_UPDATE_SUCCESS = "ACTION_EMPLOYER_UPDATE_SUCCESS";
export const ACTION_EMPLOYER_UPDATE_FAILURE = "ACTION_EMPLOYER_UPDATE_FAILURE";

export const ACTION_EMPLOYER_UPDATE_BUSINESS_INFO =
  "ACTION_EMPLOYER_UPDATE_BUSINESS_INFO";
export const ACTION_EMPLOYER_UPDATE_BUSINESS_INFO_SUCCESS =
  "ACTION_EMPLOYER_UPDATE_BUSINESS_INFO_SUCCESS";
export const ACTION_EMPLOYER_UPDATE_BUSINESS_INFO_FAILURE =
  "ACTION_EMPLOYER_UPDATE_BUSINESS_INFO_FAILURE";

export const ACTION_EMPLOYER_VERIFY_ABN = "ACTION_EMPLOYER_VERIFY_ABN";
export const ACTION_EMPLOYER_VERIFY_ABN_SUCCESS =
  "ACTION_EMPLOYER_VERIFY_ABN_SUCCESS";
export const ACTION_EMPLOYER_VERIFY_ABN_FAILURE =
  "ACTION_EMPLOYER_VERIFY_ABN_FAILURE";

export const ACTION_EMPLOYER_ABN_CLEAR_DATA = "ACTION_EMPLOYER_ABN_CLEAR_DATA";

export const ACTION_EMPLOYER_VERIFY_WPN = "ACTION_EMPLOYER_VERIFY_WPN";
export const ACTION_EMPLOYER_VERIFY_WPN_SUCCESS =
  "ACTION_EMPLOYER_VERIFY_WPN_SUCCESS";
export const ACTION_EMPLOYER_VERIFY_WPN_FAILURE =
  "ACTION_EMPLOYER_VERIFY_WPN_FAILURE";

export const ACTION_EMPLOYER_WPN_CLEAR_DATA = "ACTION_EMPLOYER_WPN_CLEAR_DATA";

export const ACTION_EMPLOYER_CLEAN_MESSAGE = "ACTION_EMPLOYER_CLEAN_MESSAGE";

export const ACTION_EMPLOYER_CLOSE_FY_REQUEST =
  "ACTION_EMPLOYER_CLOSE_FY_REQUEST";
export const ACTION_EMPLOYER_CLOSE_FY_REQUEST_SUCCESS =
  "ACTION_EMPLOYER_CLOSE_FY_REQUEST_SUCCESS";
export const ACTION_EMPLOYER_CLOSE_FY_REQUEST_FAILURE =
  "ACTION_EMPLOYER_CLOSE_FY_REQUEST_FAILURE";

export const ACTION_EMPLOYER_GENERATE_LAST_PAYMENTS_REQUEST =
  "ACTION_EMPLOYER_GENERATE_LAST_PAYMENTS_REQUEST";
export const ACTION_EMPLOYER_GENERATE_LAST_PAYMENTS_REQUEST_SUCCESS =
  "ACTION_EMPLOYER_GENERATE_LAST_PAYMENTS_REQUEST_SUCCESS";
export const ACTION_EMPLOYER_GENERATE_LAST_PAYMENTS_REQUEST_FAILURE =
  "ACTION_EMPLOYER_GENERATE_LAST_PAYMENTS_REQUEST_FAILURE";

// Parameters
export const ACTION_PARAMTERS_GETALL_REQUEST =
  "ACTION_PARAMTERS_GETALL_REQUEST";
export const ACTION_PARAMTERS_GETALL_REQUEST_SUCCESS =
  "ACTION_PARAMTERS_GETALL_REQUEST_SUCCESS";
export const ACTION_PARAMTERS_GETALL_REQUEST_FAILURE =
  "ACTION_PARAMTERS_GETALL_REQUEST_FAILURE";

export const ACTION_PARAMTERS_GETBYTYPE_REQUEST =
  "ACTION_PARAMTERS_GETBYTYPE_REQUEST";
export const ACTION_PARAMTERS_GETBYTYPE_REQUEST_SUCCESS =
  "ACTION_PARAMTERS_GETBYTYPE_REQUEST_SUCCESS";
export const ACTION_PARAMTERS_GETBYTYPE_REQUEST_FAILURE =
  "ACTION_PARAMTERS_GETBYTYPE_REQUEST_FAILURE";

export const ACTION_PARAMTERS_PLANS_REQUEST = "ACTION_PARAMTERS_PLANS_REQUEST";
export const ACTION_PARAMTERS_PLANS_REQUEST_SUCCESS =
  "ACTION_PARAMTERS_PLANS_REQUEST_SUCCESS";
export const ACTION_PARAMTERS_PLANS_REQUEST_FAILURE =
  "ACTION_PARAMTERS_PLANS_REQUEST_FAILURE";

// Subscriptions
export const ACTION_SUBSCRIPTION_ACTIVATE = "ACTION_SUBSCRIPTION_ACTIVATE";
export const ACTION_SUBSCRIPTION_ACTIVATE_SUCCESS =
  "ACTION_SUBSCRIPTION_ACTIVATE_SUCCESS";
export const ACTION_SUBSCRIPTION_ACTIVATE_FAILURE =
  "ACTION_SUBSCRIPTION_ACTIVATE_FAILURE";

export const ACTION_SUBSCRIPTION_GET_STATUS = "ACTION_SUBSCRIPTION_GET_STATUS";
export const ACTION_SUBSCRIPTION_GET_STATUS_SUCCESS =
  "ACTION_SUBSCRIPTION_GET_STATUS_SUCCESS";
export const ACTION_SUBSCRIPTION_GET_STATUS_FAILURE =
  "ACTION_SUBSCRIPTION_GET_STATUS_FAILURE";

export const ACTION_SUBSCRIPTION_EMPLOYEE_EXTRA =
  "ACTION_SUBSCRIPTION_EMPLOYEE_EXTRA";
export const ACTION_SUBSCRIPTION_EMPLOYEE_EXTRA_SUCCESS =
  "ACTION_SUBSCRIPTION_EMPLOYEE_EXTRA_SUCCESS";
export const ACTION_SUBSCRIPTION_EMPLOYEE_EXTRA_FAILURE =
  "ACTION_SUBSCRIPTION_EMPLOYEE_EXTRA_FAILURE";

export const ACTION_SUBSCRIPTION_UPDATE_CREDIT_CARD =
  "ACTION_SUBSCRIPTION_UPDATE_CREDIT_CARD";
export const ACTION_SUBSCRIPTION_UPDATE_CREDIT_CARD_SUCCESS =
  "ACTION_SUBSCRIPTION_UPDATE_CREDIT_CARD_SUCCESS";
export const ACTION_SUBSCRIPTION_UPDATE_CREDIT_CARD_FAILURE =
  "ACTION_SUBSCRIPTION_UPDATE_CREDIT_CARD_FAILURE";

export const ACTION_SUBSCRIPTION_CANCEL = "ACTION_SUBSCRIPTION_CANCEL";
export const ACTION_SUBSCRIPTION_CANCEL_SUCCESS =
  "ACTION_SUBSCRIPTION_CANCEL_SUCCESS";
export const ACTION_SUBSCRIPTION_CANCEL_FAILURE =
  "ACTION_SUBSCRIPTION_CANCEL_FAILURE";

// Single Touch
export const ACTION_STP_GET_REQUEST = "ACTION_STP_GET_REQUEST";
export const ACTION_STP_GET_REQUEST_SUCCESS = "ACTION_STP_GET_REQUEST_SUCCESS";
export const ACTION_STP_GET_REQUEST_FAILURE = "ACTION_STP_GET_REQUEST_FAILURE";

export const ACTION_STP_UPDATE_REQUEST = "ACTION_STP_UPDATE_REQUEST";
export const ACTION_STP_UPDATE_REQUEST_SUCCESS =
  "ACTION_STP_UPDATE_REQUEST_SUCCESS";
export const ACTION_STP_UPDATE_REQUEST_FAILURE =
  "ACTION_STP_UPDATE_REQUEST_FAILURE";

export const ACTION_STP_CLEAN_DATA = "ACTION_STP_CLEAN_DATA";

// Notification
export const ACTION_NOTIFICATION_GET_REQUEST =
  "ACTION_NOTIFICATION_GET_REQUEST";
export const ACTION_NOTIFICATION_GET_REQUEST_SUCCESS =
  "ACTION_NOTIFICATION_GET_REQUEST_SUCCESS";
export const ACTION_NOTIFICATION_GET_REQUEST_FAILURE =
  "ACTION_NOTIFICATION_GET_REQUEST_FAILURE";

export const ACTION_NOTIFICATION_UPDATE_REQUEST =
  "ACTION_NOTIFICATION_UPDATE_REQUEST";
export const ACTION_NOTIFICATION_UPDATE_REQUEST_SUCCESS =
  "ACTION_NOTIFICATION_UPDATE_REQUEST_SUCCESS";
export const ACTION_NOTIFICATION_UPDATE_REQUEST_FAILURE =
  "ACTION_NOTIFICATION_UPDATE_REQUEST_FAILURE";

export const ACTION_NOTIFICATION_CLEAN_MESSAGE =
  "ACTION_NOTIFICATION_CLEAN_MESSAGE";

// add additional user;
export const ACTION_INVITE_ADDITIONAL_USER = "ACTION_INVITE_ADDITIONAL_USER";
export const ACTION_INVITE_ADDITIONAL_USER_SUCCESS =
  "ACTION_INVITE_ADDITIONAL_USER_SUCCESS";
export const ACTION_INVITE_ADDITIONAL_USER_FAILURE =
  "ACTION_INVITE_ADDITIONAL_USER_FAILURE";
export const ACTION_INVITE_ADDITIONAL_USER_CLEAN_MESSAGE =
  "ACTION_INVITE_ADDITIONAL_USER_CLEAN_MESSAGE";

// get all add additional user;
export const ACTION_INVITE_ADDITIONAL_USER_DATA_REQUEST =
  "ACTION_INVITE_ADDITIONAL_USER_DATA_REQUEST";
export const ACTION_INVITE_ADDITIONAL_USER_DATA_REQUEST_SUCCESS =
  "ACTION_INVITE_ADDITIONAL_USER_DATA_REQUEST_SUCCESS";
export const ACTION_INVITE_ADDITIONAL_USER_DATA_REQUEST_FAILURE =
  "ACTION_INVITE_ADDITIONAL_USER_DATA_REQUEST_FAILURE";

// Revoke additional user;
export const ACTION_ADDITIONAL_USER_REVOKE = "ACTION_ADDITIONAL_USER_REVOKE";
export const ACTION_ADDITIONAL_USER_REVOKET_SUCCESS =
  "ACTION_ADDITIONAL_USER_REVOKET_SUCCESS";
export const ACTION_ADDITIONAL_USER_REVOKE_FAILURE =
  "ACTION_ADDITIONAL_USER_REVOKE_FAILURE";

// Add Coupon
export const ACTION_SEND_COUPON = "ACTION_SEND_COUPON";
export const ACTION_SEND_COUPON_SUCCESS = "ACTION_SEND_COUPON_SUCCESS";
export const ACTION_SEND_COUPON_FAILURE = "ACTION_SEND_COUPON_FAILURE";
export const ACTION_COUPON_CLEAN_MESSAGE = "ACTION_COUPON_CLEAN_MESSAGE";

// Get Coupon
export const ACTION_GET_COUPON = "ACTION_GET_COUPON";
export const ACTION_GET_COUPON_SUCCESS = "ACTION_GET_COUPON_SUCCESS";
export const ACTION_GET_COUPON_FAILURE = "ACTION_GET_COUPON_FAILURE";

// RedirectToSTP
export const ACTION_REDIRECT_TO_STP = "ACTION_REDIRECT_TO_STP";
// RedirectToYourBusiness
export const ACTION_REDIRECT_TO_YOUR_BUSINESS =
  "ACTION_REDIRECT_TO_YOUR_BUSINESS";
// get all List Linked Employer Accounts;
export const ACTION_LIST_LINKED_EMPLOYER_ACCOUNTS =
  "ACTION_LIST_LINKED_EMPLOYER_ACCOUNTS";
export const ACTION_LIST_LINKED_EMPLOYER_ACCOUNTS_SUCCESS =
  "ACTION_LIST_LINKED_EMPLOYER_ACCOUNTS_SUCCESS";
export const ACTION_LIST_LINKED_EMPLOYER_ACCOUNTS_FAILURE =
  "ACTION_LIST_LINKED_EMPLOYER_ACCOUNTS_FAILURE";

// Revoke Linked Employer Accounts ;
export const ACTION_LIST_LINKED_EMPLOYER_ACCOUNTS_REVOKE =
  "ACTION_LIST_LINKED_EMPLOYER_ACCOUNTS_REVOKE";
export const ACTION_LIST_LINKED_EMPLOYER_ACCOUNTS_REVOKE_SUCCESS =
  "ACTION_LIST_LINKED_EMPLOYER_ACCOUNTS_REVOKE_SUCCESS";
export const ACTION_LIST_LINKED_EMPLOYER_ACCOUNTS_REVOKE_FAILURE =
  "ACTION_LIST_LINKED_EMPLOYER_ACCOUNTS_REVOKE_FAILURE";
export const ACTION_LIST_LINKED_EMPLOYER_ACCOUNTS_MESSAGE =
  "ACTION_LIST_LINKED_EMPLOYER_ACCOUNTS_MESSAGE";

// Login As Linked Employer
export const ACTION_LOGIN_AS_LINKED_EMPLOYER =
  "ACTION_LOGIN_AS_LINKED_EMPLOYER";
export const ACTION_LOGIN_AS_LINKED_EMPLOYER_SUCCESS =
  "ACTION_LOGIN_AS_LINKED_EMPLOYER_SUCCESS";
export const ACTION_LOGIN_AS_LINKED_EMPLOYER_FAILURE =
  "ACTION_LOGIN_AS_LINKED_EMPLOYER_FAILURE";

// create a new employer from an accountant account
export const ACTION_ACCOUNTANT_CREATE_EMPLOYER = "ACTION_ACCOUNTANT_CREATE_EMPLOYER";
export const ACTION_ACCOUNTANT_CREATE_EMPLOYER_SUCCESS = "ACTION_ACCOUNTANT_CREATE_EMPLOYER_SUCCESS";
export const ACTION_ACCOUNTANT_CREATE_EMPLOYER_FAILURE = "ACTION_ACCOUNTANT_CREATE_EMPLOYER_FAILURE";

// accountant inviting an employer they created
export const ACTION_ACCOUNTANT_INVITE_EMPLOYER = "ACTION_ACCOUNTANT_INVITE_EMPLOYER";
export const ACTION_ACCOUNTANT_INVITE_EMPLOYER_SUCCESS = "ACTION_ACCOUNTANT_INVITE_EMPLOYER_SUCCESS";
export const ACTION_ACCOUNTANT_INVITE_EMPLOYER_FAILURE = "ACTION_ACCOUNTANT_INVITE_EMPLOYER_FAILURE";

// get Accountant Details
export const ACTION_ACCOUNTANT_DETAILS = "ACTION_ACCOUNTANT_DETAILS";
export const ACTION_ACCOUNTANT_DETAILS_SUCCESS =
  "ACTION_ACCOUNTANT_DETAILS_SUCCESS";
export const ACTION_ACCOUNTANT_DETAILS_FAILURE =
  "ACTION_ACCOUNTANT_DETAILS_FAILURE";
export const ACTION_ACCOUNTANT_DETAILS_CLEAN_MESSAGE =
  "ACTION_INVITE_ADDITIONAL_USER_CLEAN_MESSAGE";

// update Accountant Details
export const ACTION_ACCOUNTANT_DETAILS_UPDATE =
  "ACTION_ACCOUNTANT_DETAILS_UPDATE";
export const ACTION_ACCOUNTANT_DETAILS_UPDATE_SUCCESS =
  "ACTION_ACCOUNTANT_DETAILS_UPDATE_SUCCESS";
export const ACTION_ACCOUNTANT_DETAILS_UPDATE_FAILURE =
  "ACTION_ACCOUNTANT_DETAILS_UPDATE_FAILURE";
export const ACTION_ACCOUNTANT_DETAILS_UPDATE_MESSAGE =
  "ACTION_ACCOUNTANT_DETAILS_UPDATE_MESSAGE";

// disable nextSlid
export const ACTION_NEXT_SLID = "ACTION_NEXT_SLID";
// Onboard
export const ACTION_SEND_ONBOARD = "ACTION_SEND_ONBOARD";
export const ACTION_SEND_ONBOARD_SUCCESS = "ACTION_SEND_ONBOARD_SUCCESS";
export const ACTION_SEND_ONBOARD_FAILURE = "ACTION_SEND_ONBOARD_FAILURE";
export const ACTION_ONBOARD_CLEAN_MESSAGE = "ACTION_ONBOARD_CLEAN_MESSAGE";

// Update Data Fields During Onboarding
export const ACTION_ONBOARD_UPDATE_REQUEST = "ACTION_ONBOARD_UPDATE_REQUEST";
export const ACTION_ONBOARD_UPDATE_REQUEST_SUCCESS =
  "ACTION_ONBOARD_UPDATE_REQUEST_SUCCESS";
export const ACTION_ONBOARD_UPDATE_REQUEST_FAILURE =
  "ACTION_ONBOARD_UPDATE_REQUEST_FAILURE";

// Complete The Initial Onboarding and Create Account
export const ACTION_ONBOARD_GET_REQUEST = "ACTION_ONBOARD_GET_REQUEST";
export const ACTION_ONBOARD_GET_REQUEST_SUCCESS =
  "ACTION_ONBOARD_GET_REQUEST_SUCCESS";
export const ACTION_ONBOARD_GET_REQUEST_FAILURE =
  "ACTION_ONBOARD_GET_REQUEST_FAILURE";

// save temp request data payroll
export const ACTION_TEMP_REQUEST_DATA_PAYROLL =
  "ACTION_TEMP_REQUEST_DATA_PAYROLL";
export const ACTION_TEMP_REQUEST_DATA_REDIRECT_TO_PAYSLIP =
  "ACTION_TEMP_REQUEST_DATA_REDIRECT_TO_PAYSLIP";

// get all YECP
export const ACTION_GET_ALL_YECP_REQUEST = "ACTION_GET_ALL_YECP_REQUEST";
export const ACTION_GET_ALL_YECP_SUCCESS = "ACTION_GET_ALL_YECP_SUCCESS";
export const ACTION_GET_ALL_YECP_FAILURE = "ACTION_GET_ALL_YECP_FAILURE";

// send close FY
export const ACTION_SEND_CLOSE_FY_REQUEST = "ACTION_SEND_CLOSE_FY_REQUEST";
export const ACTION_SEND_CLOSE_FY_SUCCESS = "ACTION_SEND_CLOSE_FY_SUCCESS";
export const ACTION_SEND_CLOSE_FY_FAILURE = "ACTION_SEND_CLOSE_FY_FAILURE";

// close yf send signature
export const ACTION_SEND_CLOSE_FY_SIGNATURE_REQUEST =
  "ACTION_SEND_CLOSE_FY_SIGNATURE_REQUEST";
export const ACTION_SEND_CLOSE_FY_SIGNATURE_SUCCESS =
  "ACTION_SEND_CLOSE_FY_SIGNATURE_SUCCESS";
export const ACTION_SEND_CLOSE_FY_SIGNATURE_FAILURE =
  "ACTION_SEND_CLOSE_FY_SIGNATURE_FAILURE";

// getAuthorizedname for signature
export const ACTION_GET_AUTHORIZEDNAME_SIGNATURE_REQUEST =
  "ACTION_GET_AUTHORIZEDNAME_SIGNATURE_REQUEST";
export const ACTION_GET_AUTHORIZEDNAME_SIGNATURE_SUCCESS =
  "ACTION_GET_AUTHORIZEDNAME_SIGNATURE_SUCCESS";
export const ACTION_GET_AUTHORIZEDNAME_SIGNATURE_FAILURE =
  "ACTION_GET_AUTHORIZEDNAME_SIGNATURE_FAILURE";
// empty close FY
export const ACTION_EMPTY_CLOSE_FY = "ACTION_EMPTY_CLOSE_FY";
// redirect to stp compeleted
export const ACTION_REDIRECT_TO_STP_COMPELETED =
  "ACTION_REDIRECT_TO_STP_COMPELETED";

// put emplyees for close FY
export const ACTION_PUT_EMPLOYEES_FOR_CLOSE_FY_REQUEST =
  "ACTION_PUT_EMPLOYEES_FOR_CLOSE_FY_REQUEST";
export const ACTION_PUT_EMPLOYEES_FOR_CLOSE_FY_SUCCESS =
  "ACTION_PUT_EMPLOYEES_FOR_CLOSE_FY_SUCCESS";
export const ACTION_PUT_EMPLOYEES_FOR_CLOSE_FY_FAILURE =
  "ACTION_PUT_EMPLOYEES_FOR_CLOSE_FY_FAILURE";

// edit employee payroll for close FY
export const ACTION_EDIT_EMPLOYEE_PAYROLL_FOR_CLOSE_FY_REQUEST =
  "ACTION_EDIT_EMPLOYEE_PAYROLL_FOR_CLOSE_FY_REQUEST";
export const ACTION_EDIT_EMPLOYEE_PAYROLL_FOR_CLOSE_FY_SUCCESS =
  "ACTION_EDIT_EMPLOYEE_PAYROLL_FOR_CLOSE_FY_SUCCESS";
export const ACTION_EDIT_EMPLOYEE_PAYROLL_FOR_CLOSE_FY_FAILURE =
  "ACTION_EDIT_EMPLOYEE_PAYROLL_FOR_CLOSE_FY_FAILURE";

// change employee list for close fy -----------------------
export const ACTION_CHANGE_EMPLOYEE_LIST_FOR_CLOSE_FY =
  "ACTION_CHANGE_EMPLOYEE_LIST_FOR_CLOSE_FY";

// send close FY after edit employees
export const ACTION_SEND_CLOSE_FY_AFTER_EDIT_EMPLOYEES_REQUEST =
  "ACTION_SEND_CLOSE_FY_AFTER_EDIT_EMPLOYEES_REQUEST";
export const ACTION_SEND_CLOSE_FY_AFTER_EDIT_EMPLOYEES_SUCCESS =
  "ACTION_SEND_CLOSE_FY_AFTER_EDIT_EMPLOYEES_SUCCESS";
export const ACTION_SEND_CLOSE_FY_AFTER_EDIT_EMPLOYEES_FAILURE =
  "ACTION_SEND_CLOSE_FY_AFTER_EDIT_EMPLOYEES_FAILURE";
