/*eslint-disable */
import * as common from "../constants/common";
import * as apiResponses from "../constants/apiResponses";
import { SUBSCRIPTION_STATUS_CANCELED, SUBSCRIPTION_STATUS_ACTIVE, SUBSCRIPTION_STATUS_UNPAID, SUBSCRIPTION_STATUS_TRIAL, SUBSCRIPTION_STATUS_PAST_DUE } from "../constants/subscriptionStatus";
import { SUBSCRIPTION_TYPE_TRIAL, SUBSCRIPTION_TYPE_ITUNES } from "./../constants/subscriptionType";

export function getEmployeeFullname(employeeData) {
	let finalEmployeeName = common.COMMON_EMPLOYEE_TEMP_FULLNAME;
	if (employeeData && employeeData.detail && employeeData.detail.firstName) {
		finalEmployeeName = `${finalEmployeeName}${employeeData.detail.firstName}`;
	}

	if (employeeData && employeeData.detail && employeeData.detail.middleName) {
		finalEmployeeName = `${finalEmployeeName} ${employeeData.detail.middleName}`;
	}

	if (employeeData && employeeData.detail && employeeData.detail.lastName) {
		finalEmployeeName = `${finalEmployeeName} ${employeeData.detail.lastName}`;
	}
	return finalEmployeeName;
}
export function getEmployeeJobTitle(employeeData) {
	if (employeeData && employeeData.payroll && employeeData.payroll.jobTitle) {
		return employeeData.payroll.jobTitle;
	}
	return common.COMMON_EMPLOYEE_TEMP_JOBTITLE;
}
export function mapMessageCodeToIcon(messageCode) {
	switch (messageCode) {
		case apiResponses.RESPONSE_FAIL:
		case apiResponses.RESPONSE_NOTCOMPLETE:
		case apiResponses.RESPONSE_PARTIAL:
		case apiResponses.RESPONSE_BADREQUEST:
		case apiResponses.RESPONSE_INVALIDDATA:
		case apiResponses.RESPONSE_NOTFOUND:
		case apiResponses.RESPONSE_UNAUTHORIZED:
		case apiResponses.RESPONSE_BADREQUESTPOPUP: {
			return "error";
		}
		case apiResponses.RESPONSE_OK:
		case 200:
		case apiResponses.RESPONSE_FOUND: {
			return "success";
		}
		case apiResponses.RESPONSE_NODATE:
		case apiResponses.RESPONSE_EMPLOYEECONTAINPAYROLL:
		case apiResponses.RESPONSE_EMPLOYEEISINACTIVE:
		case apiResponses.RESPONSE_EMPLOYEENOTFOUND: {
			return "warning";
		}
		default: {
			return "info";
		}
	}
}

export function validateTfn(value) {
	let finalValidation = false;
	try {
		if (value && (value.length == 8 || value.length == 9)) {
			if (value.length === 8 && value === "00000000") {
				return false;
			}

			let weightValidator = [];
			let tfnAccumulator = 0;
			let stringValue = value;
			stringValue = stringValue.replace(/\s+/g, "");
			stringValue = stringValue.replace(/[-]/g, "");
			if (/^[0-9]+$/.test(stringValue) === false) {
				return finalValidation;
			}

			if (stringValue.length == 8) {
				weightValidator = [10, 7, 8, 4, 6, 3, 5, 1];
			} else if (stringValue.length == 9) {
				weightValidator = [10, 7, 8, 4, 6, 3, 5, 2, 1];
			} else {
				return finalValidation;
			}

			const tfnArray = stringValue.split("");
			weightValidator.forEach((element, index) => {
				tfnAccumulator = tfnAccumulator + element * parseInt(tfnArray[index]);
			});

			const remainder = tfnAccumulator % 11;
			finalValidation = remainder == 0;
		}
	} catch (err) {
		console.error("Error validating TFN: ", err);
	}
	return finalValidation;
}

export function validatePostCode(value) {

	let finalValidation = false;
	try {
		if (value && value.toString().length == 4) {
			let stringValue = value.toString();
			stringValue = stringValue.replace(/\s+/g, "");
			if (/^[0-9]+$/.test(stringValue) === false) {
				return finalValidation;
			}

			finalValidation = /^(0[289][0-9]{2})|([1345689][0-9]{3})|(2[0-8][0-9]{2})|(290[0-9])|(291[0-4])|(7[0-4][0-9]{2})|(7[8-9][0-9]{2})$/.test(stringValue);
		}
	} catch (err) {
		console.error("Error validating postcode: ", err);
	}

	return finalValidation;
}

export function validatePhoneNumber(value) {
	let finalValidation = true;
	try {
		if (value) {
			finalValidation = /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/.test(value);
		}
	} catch (err) {
		console.error("Error validating postcode: ", err);
	}
	return finalValidation;
}

export function validatePhoneNumbeAccountant(value) {
	let finalValidation = true;
	try {
		if (value) {
			// finalValidation = /^((+[0-9]{2})|0)?[0-9]{9}$/.test(value);
			finalValidation = /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/.test(value);
		}
	} catch (err) {
		console.error("Error validating phone number: ", err);
	}
	return finalValidation;
}

export function isNumber(value) {
	let finalValidation = false;
	try {
		finalValidation = !isNaN(parseFloat(value));
	} catch (error) {
		console.error("Error validating is number: ", err);
	}
	return finalValidation;
}

export function isNumberWithDecimal(value, decimalDigits) {
	let finalValidation = false;
	try {
		const regex = RegExp(`^\\d*(\\.\\d{0,${decimalDigits}})?$`, "g");
		finalValidation = regex.test(value);
	} catch (err) {
		console.error("Error validating number with decimal", err);
	}
	return finalValidation;
}

export function getStatusSubscription(subscription) {
	if (!subscription) {
		return "";
	}

	if (subscription.status === SUBSCRIPTION_STATUS_CANCELED) {
		return "Cancelled Plan";
	}

	if (subscription.typeSubscription !== SUBSCRIPTION_TYPE_TRIAL && subscription.status === SUBSCRIPTION_STATUS_ACTIVE) {
		return `Paid through ${subscription.typeSubscription === SUBSCRIPTION_TYPE_ITUNES ? "iTunes" : "Stripe"}`;
	}

	let status = "";

	switch (subscription.status) {
		case SUBSCRIPTION_STATUS_TRIAL:
			status = "";
			break;
		case SUBSCRIPTION_STATUS_ACTIVE:
			status = "Active";
			break;
		case SUBSCRIPTION_STATUS_PAST_DUE:
			status = "Past Due";
			break;
		case SUBSCRIPTION_STATUS_UNPAID:
			status = "Un Paid";
			break;
		case SUBSCRIPTION_STATUS_CANCELED:
			status = "Canceled";
			break;

		default:
			status = "";
			break;
	}

	return `${subscription.typeSubscription === SUBSCRIPTION_TYPE_TRIAL ? "Free Trial" : "Paid"} ${status}`;
}

