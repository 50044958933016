import initialState from "../../store/initialState";

const singleTouchInitialState = {
	...initialState,
	STPList: [],
	pendingList: [],
	lodgedList: [],
	invalidList: [],
	completedList: [],
	STPData: {},
	redirectToSTP: false
};

export default singleTouchInitialState;
