const getAllFilter = async (httpClient, requestData) => {
	const { goEpsApi } = window.envVariables;
	const response = await httpClient.post(`${goEpsApi}/api/v1/reports/payslips`, requestData);
	return response.data;
};

const sendEmail = async (httpClient, requestData) => {
	const { goEpsApi } = window.envVariables;
	const response = await httpClient.post(`${goEpsApi}/api/v1/payroll/send`, requestData);
	return response;
};

const deleteById = async (httpClient, id) => {
	const { goEpsApi } = window.envVariables;
	const response = await httpClient.delete(`${goEpsApi}/api/v1/payroll/${id}`);
	return response.data;
};

const addPayroll = async (httpClient, requestData) => {
	const { goEpsApi } = window.envVariables;
	// console.log(requestData);
	let response = await httpClient.post(`${goEpsApi}/api/v1/payroll/create`, requestData);


	return response.data;
};

const validatePayroll = async (httpClient, requestData) => {
	const { goEpsApi } = window.envVariables;
	const response = await httpClient.post(`${goEpsApi}/api/v1/payroll/validate`, requestData);
	return response.data;
};

export default {
	getAllFilter,
	sendEmail,
	deleteById,
	addPayroll,
	validatePayroll
};
