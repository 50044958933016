import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import {
  AppBar,
  CssBaseline,
  Drawer,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import clsx from "clsx";

import logo from "../../assets/images/logo.svg";
import SignOutIcon from "../../assets/images/icons/icon-signout.svg";
import MenuItemComponent from "./menuItemComponent";
// import AccountTreeIcon from '@material-ui/icons/AccountTree';
import menuHome, { menuAccounts } from "../../constants/menu";
import * as routes from "../../constants/routes";
import AvatarCommon from "../common/avatarCommonComponent";
import { getTodayTime } from "../../helpers/dateextensions";
import { getAccountantDetails } from "../../actions/EasyPaySlipPtyActions";
import { bindActionCreators } from "redux";

const drawerWidth = 260;

const styles = (theme) => ({
  root: {
    ...theme.hiddenOverflow,
    display: "flex",
  },
  toolbar: {
    paddingRight: 24,
  },
  toolbarIcon: {
    ...theme.mixins.toolbar,
    display: "flex",
    marginBottom: 10,
    "@media (min-width: 600px)": {
      minHeight: 60,
    },
  },
  appBarShift: {
    marginLeft: drawerWidth,
    backgroundColor: "#fff",
    width: `calc(100% - ${drawerWidth}px)`,
    boxShadow: "none",
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  logoContainer: {
    margin: "0 8px",
    minWidth: "initial",
  },
  logo: {
    width: 40,
    height: 40,
  },
  logoEmployer: {
    width: 40,
    height: 40,
    borderRadius: "50%",
  },
  logoText: {
    padding: "0 9px",
    "&.companyName": {
      ...theme.hiddenOverflow,
      display: "contents",
      wordWrap: "break-word",
      width: 150,
      whiteSpace: "normal",
      textOverflow: "ellipsis",
      height: 40,
    },
  },
  logoTitle: {
    fontFamily: "AvenirNextLTPro-Bold",
    fontWeight: "600",
    fontSize: 15,
    lineHeight: 20,
  },
  toolbarIconEmployer: {
    ...theme.mixins.toolbar,
    display: "flex",

    borderRadius: 10,
    height: 50,
    minHeight: 50,
    "@media (min-width: 600px)": {
      minHeight: 50,
    },
  },
  toolbarIconEmployerIcon: {
    margin: "0 8px",
    minWidth: "initial",
  },
  toolbarIconEmployerPrimary: {
    color: "inherit",
    fontFamily: "inherit",
    fontWeight: "normal",
    marginLeft: 10,
    fontSize: 15,
    lineHeight: 20,
  },
  toolbarMenu: {
    ...theme.mixins.toolbar,
    display: "flex",
    minHeight: 50,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    padding: "0 16px 0 11px",
    boxShadow: "2px 0 4px 0 #E9E9E9",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    height: "100vh",
    overflow: "auto",
  },
  chartContainer: {
    marginLeft: -22,
  },
  tableContainer: {
    height: 320,
  },
  h5: {
    marginBottom: theme.spacing(2),
  },
  footerAppBar: {
    top: "auto",
    bottom: 0,
    width: drawerWidth,
    left: 0,
    backgroundColor: "transparent",
    boxShadow: "none",
    padding: "0 16px 0 11px",
  },
  footerSignOut: {
    ...theme.mixins.toolbar,
    display: "flex",
    borderRadius: 10,
    height: 50,
    minHeight: 50,
    "@media (min-width: 600px)": {
      minHeight: 50,
    },
  },
  footerSignOutIcon: {
    margin: "0 8px",
  },
  footerSignOutText: {
    padding: "0 9px",
  },
  footerSignOutPrimary: {
    fontFamily: "AvenirNextLTPro-Regular",
    fontWeight: "normal",
    fontSize: 15,
    lineHeight: 20,
  },
});

class SidebarComponent extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    const { getAccountantDetails, employer } = this.props;
    employer.accountType === "accountant" && getAccountantDetails();
  }
  render() {
    const {
      classes,
      employer,
      match,
      accountantDetails,
      isAccountant,
      isEmployee,
      isEmployeeLinkedAccountant,
    } = this.props;
    const companyLogo = (employer.company || {}).logo
      ? `${(employer.company || {}).logo}?${getTodayTime()}`
      : "";

    return (
      <div className={classes.root}>
        <CssBaseline />
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper),
          }}
        >
          <div className={classes.toolbarIcon}>
            <ListItem button component={Link} to={routes.PATH_EMPLOYEE}>
              <ListItemIcon className={classes.logoContainer}>
                <img src={logo} alt="" className={classes.logo} />
              </ListItemIcon>
              <ListItemText
                primary="Easy Payslip"
                classes={{
                  root: clsx(classes.logoText),
                  primary: clsx(classes.logoTitle),
                }}
              />
            </ListItem>
          </div>
          {isEmployeeLinkedAccountant ? (
            <MenuItemComponent
              items={[
                {
                  name: "Accounts",
                  icon: "iconTree",
                  url: routes.PATH_ACCOUNTS,
                },
              ]}
            />
          ) : (
            <></>
          )}

          {isAccountant ? (
            <div className={classes.toolbarIconEmployer}>
              <ListItem
                button
                component={Link}
                to={routes.PATH_ACCOUNTANT_REGISTER}
                selected={match.path.includes(routes.PATH_ACCOUNTANT_REGISTER)}
              >
                <ListItemIcon className={classes.toolbarIconEmployerIcon}>
                  {!accountantDetails.details ||
                  (accountantDetails.details <= 0 &&
                    !accountantDetails.details.first &&
                    !accountantDetails.details.last) ? (
                    <AvatarCommon
                      text="EP"
                      src={companyLogo}
                      className={classes.logoEmployer}
                    />
                  ) : (
                    <AvatarCommon
                      employerId={employer.id}
                      src={companyLogo}
                      text={`${
                        accountantDetails.details.first ||
                        accountantDetails.details.last
                          ? `${
                              accountantDetails.details.first
                                ? accountantDetails.details.first
                                    .trim()
                                    .substring(0, 1)
                                : ""
                            }${
                              accountantDetails.details.last
                                ? accountantDetails.details.last
                                    .trim()
                                    .substring(0, 1)
                                : ""
                            }`
                          : "EP"
                      } `}
                      className={classes.logoEmployer}
                    />
                  )}
                </ListItemIcon>
                {!accountantDetails.details ||
                accountantDetails.details <= 0 ? (
                  <ListItemText
                    primary={"Your name"}
                    classes={{
                      root: clsx(classes.logoText, {
                        companyName: true,
                      }),
                      primary: clsx(classes.toolbarIconEmployerPrimary),
                    }}
                  />
                ) : null}

                {accountantDetails && accountantDetails.details && (
                  <ListItemText
                    primary={`${accountantDetails.details.first} ${accountantDetails.details.last}`}
                    classes={{
                      root: clsx(classes.logoText, {
                        companyName: true,
                      }),
                      primary: clsx(classes.toolbarIconEmployerPrimary),
                    }}
                  />
                )}
              </ListItem>
            </div>
          ) : (
            <div className={classes.toolbarIconEmployer}>
              <ListItem
                button
                component={Link}
                to={routes.PATH_EMPLOYER_REGISTER}
                selected={match.path.includes(routes.PATH_EMPLOYER_REGISTER)}
              >
                <ListItemIcon className={classes.toolbarIconEmployerIcon}>
                  {!employer.initials || employer.initials.length <= 0 ? (
                    <AvatarCommon
                      text="YB"
                      src={companyLogo}
                      className={classes.logoEmployer}
                    />
                  ) : (
                    <AvatarCommon
                      employerId={employer.id}
                      src={companyLogo}
                      text={employer.initials}
                      className={classes.logoEmployer}
                    />
                  )}
                </ListItemIcon>
                {!employer.companyName || employer.companyName.length <= 0 ? (
                  <ListItemText
                    primary="Your Business"
                    classes={{
                      root: clsx(classes.logoText, {
                        companyName: true,
                      }),
                      primary: clsx(classes.toolbarIconEmployerPrimary),
                    }}
                  />
                ) : null}
                <ListItemText
                  primary={employer.companyName}
                  classes={{
                    root: clsx(classes.logoText, {
                      companyName: true,
                    }),
                    primary: clsx(classes.toolbarIconEmployerPrimary),
                  }}
                />
              </ListItem>
            </div>
          )}
          {
            // employer.accountType == "accountant" && isAccountant ? <MenuItemComponent items={menuAccounts} />
            isEmployee || isEmployeeLinkedAccountant ? (
              <MenuItemComponent items={menuHome} />
            ) : (
              <MenuItemComponent items={menuAccounts} />
            )
          }
          <div
            style={{
              backgroundColor: "#505050",
              textAlign: "center",
              padding: 8,
              flexDirection: "row",
              display: "flex",
              borderRadius: 8,
            }}
          >
            <p
              style={{
                fontSize: 15,
                color: "white",
                flexShrink: 1,
                flexWrap: "wrap",
                whiteSpace: "pre-line",
                lineHeight: "25px",
              }}
            >
              Please note that Easy Payslip will be unavailable this weekend
              between{" "}
              <strong style={{ color: "#FFFFFF" }}>
                8 PM AEST Friday and 9AM AEST Monday
              </strong>{" "}
              due to planned maintenance.
            </p>
          </div>
          <AppBar position="fixed" className={classes.footerAppBar}>
            <MenuItemComponent
              items={[{ name: "Sign Out", url: routes.PATH_LOGOUT }]}
              logo={SignOutIcon}
            />
          </AppBar>
        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let accountant =
    !state.session.isEmployee && state.employersAccountant.isAccountant;

  return {
    employer: state.session.userData,
    isAccountant: accountant,

    isEmployeeLinkedAccountant: state.employersAccountant.isEmployee,
    isEmployee: !accountant,
    accountantDetails: state.accountantDetails.accountantDetails,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAccountantDetails: bindActionCreators(getAccountantDetails, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(SidebarComponent)));
