
const updateActiveFY = async (httpClient, fyName) => {
    const { goEpsApi } = window.envVariables;
    const response = await httpClient.put(`${goEpsApi}/api/v1/fy`, { name: fyName });
    return response;
};

const getAvailableFYs = async (httpClient) => {
    const { goEpsApi } = window.envVariables;
    const response = await httpClient.get(`${goEpsApi}/api/v1/fy/list`);
    return response;
}

export default {
    getAvailableFYs,
    updateActiveFY,
};
