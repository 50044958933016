import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Formik, getIn } from "formik";
import * as Yup from "yup";
import DeleteIcon from '@material-ui/icons/Delete';
import { withStyles } from "@material-ui/core/styles";

import {
    TableCell, TableRow, Paper, TextField, InputAdornment, IconButton
} from "@material-ui/core";
import PayrollStyle from "./payrollStyle";
import clsx from "clsx";
import * as parameterTypes from "../../constants/parameterTypes";

import { NumberFormatCustomComponent } from "../common";

class DeductionsItemComponent extends Component {
    state = {
        isEdit: true
    };

    validationSchema = Yup.object({
        description: Yup.string()
            .required("Selected Deductions")
            .test("name-default", "Selected Deductions", value => value !== "-"),
        total: Yup.number().required("Total is required")
    });

    // handleChangeValues = (index, name, value) => {
    //     let { parentProps } = this.props;
    //     const { deductions } = parentProps.values.payroll;
    //     const newDeductions = [...deductions];
    //     newDeductions[index][`${name}`] = value
    //     parentProps.setFieldValue("payroll.deductions", newDeductions);
    // };

    renderForm = (formProps) => {
        const {
            classes, row, index, handleRemoveItem ,handleChangeValues
        } = this.props;
        console.log("row", row)
        return (
            <TableRow
                key={row.name}
                className={clsx("tableCustompayDeduc", classes.tableRow, classes.tableCustompayroll)}
            >
                <TableCell width={"60%"}
                    className={clsx(row.type === "Manual entry" ? "tableTd" : "", classes.tableText, classes.TableTd)}
                >
                   {row.type === "Manual entry" ? <TextField
                        id="description"
                        defaultValue={row.description ? row.description : row.type}                       
                        onChange={(value) => {
                            handleChangeValues(index, "description", value.target.value)
                        }}
                        fullWidth
                        onBlur={formProps.handleBlur}
                        className={clsx(classes.textFieldCustomPayroll, {
                            error: getIn(row.type === "Manual entry" ? formProps.errors : "", "description") && getIn(formProps.touched, "description")
                        })}
                    />
                    :
                    row.type
                }
                </TableCell>

                <TableCell
                    className={clsx("tableTd", classes.tableText, classes.TableTdCell)}
                >
                    <TextField
                        id="total"
                        fullWidth
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            inputComponent: NumberFormatCustomComponent,
                            inputProps: {
                                decimalScale: 2,
                                fixedDecimalScale: true
                            }
                        }}
                        value={row.total}
                        onChange={(value) => {
                            handleChangeValues(index, "total", value.target.value)
                        }}
                        className={clsx(classes.textFieldCustomPayroll, {
                            error: getIn(formProps.errors, "total") && getIn(formProps.touched, "total")
                        })}
                    />
                </TableCell>

                <TableCell className={classes.tableText, classes.TableTd} align="right">
                    <IconButton onClick={e => handleRemoveItem(e, index)}>
                        <DeleteIcon
                            fontSize="large"
                        />
                    </IconButton>

                    {/* <DeleteIcon
                        fontSize="large"
                        onClick={e => handleRemoveItem(e, index)}
                        className={classes.textFieldCustomPayrollDelete}
                    /> */}

                </TableCell>

            </TableRow>
        )
    };

    render() {
        const {
            data, row, index, handleRemoveItem, handleChangeValues, handelChange, parentProps
        } = this.props;
        const dataDefault = {
            ...data,
            ...row,
            index,
            handleRemoveItem,
            handleChangeValues,
            handelChange,
            ...parentProps
        };
        return <Formik
            initialValues={{ ...dataDefault }}
            validationSchema={this.validationSchema}
            render={this.renderForm}
            // onSubmit={handelChange}
            enableReinitialize />;
    }
}

DeductionsItemComponent.propTypes = {
    handleRemoveItem: PropTypes.func.isRequired,
    handleChangeValues: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    payrollBaseRateAdditionalList: state.parameters.parameterByTypeData[parameterTypes.PAYROLL_BASE_RATE_ADDITIONAL.parameterType]
});

export default connect(
    mapStateToProps,
    null
)(withStyles(PayrollStyle)(DeductionsItemComponent));
