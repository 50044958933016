import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Paper, Typography, Button, Box, Link, Dialog } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { DialogCommonComponent, Analytics } from "../common";
import * as routes from "../../constants/routes";
import { Redirect } from 'react-router'
import clsx from "clsx";
import employeeStyle from "./employeeStyle";
import { getDateToday } from "../../helpers/dateextensions";
import logo from "../../assets/images/LOGOEasyPayslipHorizontal.svg";
import logo2 from "../../assets/images/addEmployee.png";


class ModalAddEmployee extends Component {
	constructor(props) {
		super(props);

		this.state = {
			date: props.date || getDateToday(),
			onCloseModal: true,
			redirect: false
		};
	}
	componentDidMount = () => {
		Analytics.OB_6EmployeeSuccessScreen()
	}
	handleClose = () => {

		//TODO addd event
		this.props.open = false;
	};
	handleChangeDate = (date) => {
		this.setState({ date });
	};
	createSamplePaySlip = () => {
		Analytics.PR_0SampleAction();
		this.setState({
			redirectToPayRoll: true
		})

	}
	doThisLater = () => {

		Analytics.OB_6LaterPayslipAction();
		this.setState({
			redirectToEmployee: true
		})
		// onCloseModal()

	}

	render() {
		const {
			classes, open, onPrimaryButtonClick, onCloseModal
		} = this.props;
		const { date, redirectToPayRoll, redirectToEmployee } = this.state;
		if (redirectToPayRoll) {
			return (<Redirect to={routes.PATH_PAYROLL} />)
		} else if (redirectToEmployee) {
			return (<Redirect to={routes.PATH_EMPLOYEE} />)
		}
		return (
			<Dialog
				open={open}
				onClose={this.doThisLater}
				PaperProps={{
					style: {
						minHeight: '558px',
						minWidth: '788px',
						padding: '52px',
						borderRadius: '8px'
					},
				}}
			>
				<Grid container className={classes.add_employee_dialogContainer}>
					<Grid item xs={6} className={classes.leftColumn} style={{ marginTop: 10 }}>
						<Grid container alignItems="center" spacing={2} className={classes.add_employee_logoroot}>
							<a href="https://www.easypayslip.com.au/" style={{ all: "unset", cursor: "pointer" }}>
								<Grid item>
									<img src={logo} alt="" className={classes.add_employee_imageLogo} />
								</Grid>
							</a>
							{/* <a href="https://www.easypayslip.com.au/" style={{ all: "unset", cursor: "pointer", marginLeft: "8px" }}>
								<Grid item>
									<Typography variant="h3" className={classes.add_employee_headerLogo}>
										{"Easy Payslip"}
									</Typography>
								</Grid>
							</a> */}
						</Grid>
						<Typography variant="h1" gutterBottom className={classes.textFieldwelldone1}>
							{"Well done,"}
						</Typography>
						<Typography variant="h1" gutterBottom className={classes.textFieldwelldone2}>
							{"Employee added!"}
						</Typography>


						<Grid style={{ width: 220, marginTop: 23 }}>
							<Typography variant="inherit" gutterBottom className={classes.modaldescription}>
								{"Now let’s see how easy it is to create a payslip"}
							</Typography>
						</Grid>
						<Grid xs={12} className={classes.add_employee_rightColumn_text}>
							<Typography variant="inherit" gutterBottom className={classes.modaldescription2}>
								{"It will take just a minute"}
							</Typography>
						</Grid>


					</Grid>
					<Grid item xs={6} className={classes.add_employee_rigthColumn}>
						<img src={logo2} style={{ width: 269, height: 269, minHeight: 269, minWidth: 269 }} alt="" className={classes.add_employee_rigthColumnImg} />


						<Button variant="contained" color="primary" onClick={this.createSamplePaySlip} className={classes.add_employee_btn_create_payslip}>
							{"Create Sample Payslip"}
						</Button>
						<Grid xs={12} className={classes.add_employee_rightColumn_link_doThisLater} onClick={this.doThisLater}>
							<Typography variant="inherit" gutterBottom className={classes.add_employee_Link_Do}>
								{"Do this later"}
							</Typography>
						</Grid>

					</Grid>
				</Grid>
			</Dialog >
		);
	}
}

export default connect(
	null,
	null
)(withStyles(employeeStyle)(ModalAddEmployee));
