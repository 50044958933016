import { createLogic } from "redux-logic";
import { detect } from "detect-browser";
import publicIp from "public-ip";

import * as actionTypes from "../constants/actionTypes";
import * as apiResponses from "../constants/apiResponses";
import * as statusEmployer from "../constants/statusEmployer";
import * as common from "../constants/common";
import * as routes from "../constants/routes";
import * as employerActions from "../actions/EmployerActions";
import * as externalUsers from "../actions/ExternalUserActions";
import * as sessionActions from "../actions/SessionActions";
import externalUserApi from "../api/ExternalUserApi";
import localStorageApi from "../api/LocalStorageApi";
import { Analytics } from "../components/common";
import mixpanel from "mixpanel-browser";

const signUpUser = createLogic({
	type: actionTypes.ACTION_SESSION_USER_SIGN_UP,
	latest: true,
	async process({ httpClient, action }, dispatch, done) {
		try {
			const ip = await publicIp.v4();
			const browser = detect();

			const response = await externalUserApi.signUpUser(httpClient, action.payload.email, action.payload.password, ip, browser);

			if (response.status === apiResponses.RESPONSE_OK_SUCCESS_CODE) {

				// const userData = response.item;
				// userData.email = response.item.login.email;
				// userData.token = response.item.login.device.sessionToken;
				// userData.enable2FA = response.item.login.enable2FA;
				// userData.messageCode = response.messageCode;
				// userData.message = response.message;

				const userData = response.data;
				userData.token = response.data.sessionToken;
				userData.messageCode = response.status;
				userData.message = response.data.message;
				userData.accountType = response.data.accountType;
				userData.valid2FA = response.data.valid2FA;
				userData.method2FA = response.data.method2FA;
				// userData.enable2FA = response.data.enable2FA;

				console.log(userData);

				if (userData.accountType=="accountant") {

					userData.accountantId = response.data.accountantId;
					userData.email = action.payload.email;
					mixpanel.reset();
					mixpanel.identify(userData.accountantId);
					Analytics.accountSignupAction(action.payload, userData);

					dispatch(externalUsers.userSignUpSuccess(userData, userData.token, userData.enable2FA, response.status.messageCode, response.data.message));
					dispatch(sessionActions.userLoggingInSuccess(userData, userData.token, userData.enable2FA, response.status.messageCode, response.status.message));

					localStorageApi.setItem(common.STORAGE_SESSION_NAME, userData);

				    	// redirect after login information is updated.
							// if (...) {
							// 	// 1) has filled out their details,
							// 	// 2) has enabled 2fa for their account and
							// 	// 3) is only linked to 1 employer account
							// 	//  action.payload.history.push(routes. ?);
							// } else {
							// 	// route into companies list
							// 	action.payload.history.push(routes.PATH_ACCOUNTS);
							// }

					action.payload.history.push(routes.PATH_ACCOUNTANT_REGISTER);


				} else if (userData.accountType=="employer") {

					userData.employerId = response.data.employerId;
					userData.email = action.payload.email;
					userData.status = 0;
					mixpanel.reset();
					mixpanel.identify(userData.employerId);
					Analytics.accountSignupAction(action.payload, userData);

					dispatch(externalUsers.userSignUpSuccess(userData, userData.token, userData.enable2FA, response.status.messageCode, response.data.message));
					dispatch(sessionActions.userLoggingInSuccess(userData, userData.token, userData.enable2FA, response.status.messageCode, response.status.message));
					dispatch(sessionActions.employerLoggingInSuccess(userData, userData.token, userData.enable2FA, response.status.messageCode, response.status.message));
					dispatch(employerActions.getEmployer());

					localStorageApi.setItem(common.STORAGE_SESSION_NAME, userData);

					// redirect after login information is updated.
					action.payload.history.push(routes.PATH_EMPLOYER_REGISTER);
				}
			} else {
				throw new Error(response.message);
			}

		} catch (err) {
			dispatch(externalUsers.userSignUpFailure(err.response.data.message));
		}
		done();
	}
});

const forgotPasswordUser = createLogic({
	type: actionTypes.ACTION_SESSION_USER_FORGOT_PASSWORD,
	latest: true,
	async process({ httpClient, action }, dispatch, done) {
		try {
			const response = await externalUserApi.forgotPasswordUser(httpClient, action.payload.email);

			if (response.status === 200) {
				dispatch(externalUsers.userForgotPasswordSuccess(response.status, response.data.message));

				action.payload.history.push(routes.PATH_LOGIN);
			} else {
				dispatch(externalUsers.userForgotPasswordFailure(response.status, response.data.message));
			}
		} catch (err) {
			dispatch(externalUsers.userForgotPasswordFailure(err.response.status, err.response.data.message));
		}
		done();
	}
});

const forgotValidationUser = createLogic({
	type: actionTypes.ACTION_SESSION_USER_FORGOT_VALIDATION,
	latest: true,
	async process({ httpClient, action }, dispatch, done) {
		try {
			const ip = await publicIp.v4();
			const browser = detect();

			const response = await externalUserApi.forgotValidationUser(httpClient, action.payload.tokenForgot, ip, browser);

			if (response.messageCode === apiResponses.RESPONSE_OK) {
				const userData = response;
				// userData.email = response.item.login.email;
				userData.token = response.sessionToken;
				// userData.enable2FA = response.item.login.enable2FA
				userData.enable2FA = false;  // Unknown at this point
				userData.login = {email: "fake@placeholder.com"};  // Unknown at this point
				userData.messageCode = response.messageCode;
				userData.message = response.message;

				dispatch(externalUsers.userForgotValidationSuccess(userData, userData.token, userData.enable2FA, response.messageCode, response.message));
				dispatch(sessionActions.userLoggingInSuccess(userData, userData.token, userData.enable2FA, response.messageCode, response.message));

				localStorageApi.setItem(common.STORAGE_SESSION_NAME, userData);

				action.payload.history.push(routes.PATH_RESET_PASSWORD);
			} else {
				dispatch(externalUsers.userForgotValidationFailure(response.messageCode, response.message));

				action.payload.history.push(routes.PATH_LOGIN);
			}
		} catch (err) {
			dispatch(externalUsers.userForgotValidationFailure(err.message));
			action.payload.history.push(routes.PATH_LOGIN);
		}
		done();
	}
});

const loginUnifyRequest = createLogic({
	type: actionTypes.ACTION_SESSION_USER_LOGIN_UNIFY,
	latest: true,
	async process({ httpClient, action }, dispatch, done) {
		try {
			const ip = await publicIp.v4();
			const browser = detect();

			const response = await externalUserApi.loginUnifyRequest(httpClient, action.payload.tokenUnify, ip, browser);

			if (response.messageCode === apiResponses.RESPONSE_OK) {
				const userData = response.item;
				userData.email = response.item.login.email;
				userData.token = response.item.login.device.sessionToken;
				userData.enable2FA = response.item.login.enable2FA;
				userData.method2FA = response.item.login.method2FA;
				userData.messageCode = response.messageCode;
				userData.message = response.message;
				userData.accountType = "employer";
				userData.valid2FA = true;
				userData.accountStatus = 1;
				userData.method2FA = "email";

				mixpanel.reset();
				mixpanel.identify(response.item.id);

				dispatch(externalUsers.loginUnifyRequestSuccess(userData, userData.token, userData.enable2FA, response.messageCode, response.message));
				dispatch(sessionActions.employerLoggingInSuccess(userData, userData.token, userData.enable2FA, response.messageCode, response.message));
				localStorageApi.setItem(common.STORAGE_SESSION_NAME, userData);

				action.payload.history.push(routes.PATH_SUBSCRIPTION);
			} else {
				action.payload.history.push(routes.PATH_LOGIN);
			}
		} catch (err) {
			dispatch(externalUsers.loginUnifyRequestFailure(err.message));
			action.payload.history.push(routes.PATH_LOGIN);
		}
		done();
	}
});

export default [signUpUser, forgotPasswordUser, forgotValidationUser, loginUnifyRequest];
