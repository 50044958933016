import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Formik } from "formik";

import { withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import * as Yup from "yup";

import {
	Grid, Paper, FormHelperText, Typography, TextField, InputAdornment, IconButton
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import SettingStyle from "./settingStyle";

import * as employerActions from "../../actions/EmployerActions";
import { DialogCommonComponent } from "../common";

class Modal2FAConfirmPasswordComponent extends Component {
	validationSchema = Yup.object({
		password: Yup.string().required("Password is required")
	});

	constructor(props) {
		super(props);

		this.state = {
			open: props.open,
			showPassword: false
		};
	}

	handleSubmitTOPTRevoke = (values, actions) => {
		const { employerTOPTRevoke, history, employer } = this.props;


		let password = {
			password: values.password
		}

		if (password.password) {
			password = password.password;
		}
		// console.clear()

		actions.setSubmitting(false);

		employerTOPTRevoke(password, history,employer.accountType);
	};

	componentWillReceiveProps = (nextProps) => {
		// debugger
		if (nextProps.isValidRevoke || nextProps.isValidDisabled) {
			this.setState({ open: false });
		//   debugger
			nextProps.onCloseModal();
		}
	};

	handleSubmitTOPTDisabled = (values, actions) => {

		const { employerTOPTDisabled, history } = this.props;
		actions.setSubmitting(false);
		employerTOPTDisabled(values.password, history);
	};

	handleClickShowPassword = () => {
		const { showPassword } = this.state;
		this.setState({ showPassword: !showPassword });
	};

	// { values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, }
	renderForm = (formProps) => {
		const {
			classes, onCloseModal, isRevoke, error, errorMessage
		} = this.props;

		const { open, showPassword } = this.state;

		return (
			<form className={classes.container} onSubmit={formProps.handleSubmit}>
				<DialogCommonComponent title={`${isRevoke ? "Revoke" : "Disabled"} Two-Factor Authentication (2FA)`} size="xs" showDivider primaryButtonName={isRevoke ? "Revoke access" : "Disable access"} onPrimaryButtonClick={formProps.handleSubmit} open={open} onCloseModal={onCloseModal}>
					<Typography variant="body1" gutterBottom className={classes.description}>
						{"Enter your password for security"}
					</Typography>
					<Grid container direction="row" alignItems="center">
						<Grid item xs={12}>
							<Paper className={clsx(classes.paperGrid, { [classes.paperGridNotRight]: true })}>
								<TextField
									fullWidth
									id="password"
									label="Password*"
									type={showPassword ? "text" : "password"}
									variant="filled"
									autoComplete="current-password"
									value={formProps.values.password}
									onChange={formProps.handleChange}
									onBlur={formProps.handleBlur}
									className={clsx(classes.textField, {
										error: formProps.errors.password && formProps.touched.password
									})}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton edge="end" aria-label="Toggle password visibility" onClick={this.handleClickShowPassword}>
													{showPassword ? <VisibilityOff /> : <Visibility />}
												</IconButton>
											</InputAdornment>
										)
									}}
								/>
							</Paper>
							{error && <FormHelperText error>{errorMessage}</FormHelperText>}
						</Grid>
					</Grid>
				</DialogCommonComponent>
			</form>
		);
	};

	render() {
		const { classes, isRevoke } = this.props;

		return (
			<Grid container justify="center" className={classes.root}>
				<Formik
					ref={(innerForm) => {
						this.totpRevokeForm = innerForm;
					}}
					initialValues={{
						password: ""
					}}
					validationSchema={this.validationSchema}
					onSubmit={isRevoke ? this.handleSubmitTOPTRevoke : this.handleSubmitTOPTDisabled}
					render={this.renderForm}
				/>
			</Grid>
		);
	}
}

Modal2FAConfirmPasswordComponent.propTypes = {
	error: PropTypes.bool,
	errorMessage: PropTypes.string,
	isValidRevoke: PropTypes.bool,
	isValidDisabled: PropTypes.bool,
	employerTOPTRevoke: PropTypes.func,
	employerTOPTDisabled: PropTypes.func,
	isAccountant: PropTypes.bool
};

const mapStateToProps = state => ({
	isValidRevoke: state.employer.isValidRevoke,
	isValidDisabled: state.employer.isValidDisabled,
	error: state.employer.error,
	errorMessage: state.employer.errorMessage,
	isAccountant: state.session.isAccountant,
	employer: state.session.userData,
});

const mapDispatchToProps = dispatch => ({
	employerTOPTRevoke: bindActionCreators(employerActions.employerTOPTRevoke, dispatch),
	employerTOPTDisabled: bindActionCreators(employerActions.employerTOPTDisabled, dispatch)
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(SettingStyle)(Modal2FAConfirmPasswordComponent));
