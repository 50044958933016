import * as actionTypes from "../../constants/actionTypes";
import CouponInitialState from './CouponInitialState';

const couponReducer = (state = CouponInitialState, action) => {
    switch (action.type) {

        // Send Coupon
        case actionTypes.ACTION_SEND_COUPON:
            return {
                ...state,
                isLoading: true,
            }
        case actionTypes.ACTION_SEND_COUPON_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error: false,
                message: action.payload.message,
                messageCode: action.payload.messageCode,
                item: action.payload.item,
            }
        case actionTypes.ACTION_SEND_COUPON_FAILURE:
            return {
                ...state,
                error: true,
                isLoading: false,
                message: action.payload.message,
                errorMessage: action.payload.errorMessage,
                messageCode: action.payload.messageCode,
            };
        case actionTypes.ACTION_COUPON_CLEAN_MESSAGE:
            return {
                ...state,
                error: false,
                isLoading: false,
                errorMessage: action.payload.errorMessage,
                messageCode: action.payload.messageCode,
                message: action.payload.message
            };

        // Get Coupon
        case actionTypes.ACTION_GET_COUPON:
            return {
                ...state,
                isLoading: true,
            }
        case actionTypes.ACTION_GET_COUPON_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error: false,
                message: action.payload.message,
                messageCode: action.payload.messageCode,
                item: action.payload.item,
            }
        case actionTypes.ACTION_GET_COUPON_FAILURE:
            return {
                ...state,
                error: true,
                isLoading: false,
                message: action.payload.message,
                errorMessage: action.payload.errorMessage,
                messageCode: action.payload.messageCode,
            };

        // clean massage
        case actionTypes.ACTION_COUPON_CLEAN_MESSAGE: {
            return {
                ...state,
                error: false,
                isLoading: false,
                errorMessage: action.payload.errorMessage,
                messageCode: action.payload.messageCode,
                message: action.payload.message
            };
        }

        default:
            return {
                ...state
            };
    }

}

export default couponReducer;