import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import { LoadingStyes } from "./commonStyles";
import CustomLoadingBarComponent from "./customLoadingBarComponent";

class LoadingComponent extends Component {
	render() {
		const { isLoading, classes } = this.props;
		if (isLoading) {
			return (
				<div className={classes.root}>
					<CustomLoadingBarComponent />
				</div>
			);
		}

		return null;
	}
}

LoadingComponent.propTypes = {
	isLoading: PropTypes.bool.isRequired,
};

export default withStyles(LoadingStyes)(LoadingComponent);
