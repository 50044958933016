import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Formik } from "formik";
import * as Yup from "yup";

import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Paper, TextField, InputAdornment } from "@material-ui/core";

import PayrollStyle from "./payrollStyle";

import * as parameterTypes from "../../constants/parameterTypes";

import { DialogCommonComponent, NumberFormatCustomComponent } from "../common";
import { payrollAllowancesDefaultData } from "../../reducers/Payroll/PayrollInitialState";

class ModalAddUntaxedAllowance extends Component {
  validationSchema = Yup.object({
    value: Yup.string()
      .required("Selected untaxed allowance")
      .test(
        "name-default",
        "Selected untaxed allowance",
        value => value !== "-"
      ),
    manualValue: Yup.string().when("value", {
      is: "Manual Entry",
      then: Yup.string().required("Manual value is required")
    }),
    qty: Yup.number().required("QTY is required"),
    amount: Yup.number().required("Amount is required"),
    totalAmount: Yup.number().required("Total Amount is required")
  });

  renderAllowancesOptions = () => {
    const { payrollAllowancesList, classes } = this.props;
    const values = [
      <option key="option_0" value="-" className={classes.option}>
        Select option
      </option>
    ];

    values.push(
      payrollAllowancesList
        .filter(el => ["Manual Entry", "Travel", "Meals"].includes(el.value))
        .sort((firstEl, secondEl) => firstEl.order < secondEl.order)
        .map(item => (
          <option
            key={`${item.id}`}
            value={`${item.value}`}
            className={classes.option}
          >
            {item.value}
          </option>
        ))
    );

    return values;
  };

  handleSubmitAllowance = (value, actions) => {
    const { onAddValues } = this.props;
    actions.setSubmitting(false);
    onAddValues(value);
  };

  handleChangeValues = (name, event, formProps, nameTotal) => {
    const { value } = event.target;
    const valueFix = parseFloat(value).toFixed(2);

    formProps.setFieldValue(name, value);

    const total = parseFloat(
      valueFix *
        (name === "qty" ? formProps.values.amount : formProps.values.qty)
    ).toFixed(2);
    formProps.setFieldValue(nameTotal, isNaN(total) ? 0 : total);
  };

  renderForm = formProps => {
    const { classes, open, onCloseModal, edit } = this.props;

    return (
      <DialogCommonComponent
        title="Add untaxed allowance"
        primaryButtonName={edit ? "Update" : "Save"}
        size="xs"
        onPrimaryButtonClick={formProps.handleSubmit}
        open={open}
        onCloseModal={onCloseModal}
      >
        <Grid container direction="row" alignItems="center">
          <Grid item xs={12}>
            <Paper className={classes.paperGrid}>
              <TextField
                id="value"
                label="Untaxed allowance type *"
                select
                variant="filled"
                fullWidth
                disabled={edit}
                value={formProps.values.value}
                onChange={formProps.handleChange}
                onBlur={formProps.handleBlur}
                className={clsx(classes.textField, {
                  error: formProps.errors.value && formProps.touched.value
                })}
                SelectProps={{
                  native: true,
                  MenuProps: {
                    className: classes.menu
                  }
                }}
              >
                {this.renderAllowancesOptions()}
              </TextField>
            </Paper>
          </Grid>
          {formProps.values.value === "Manual Entry" ? (
            <Grid item xs={12}>
              <Paper className={classes.paperGrid}>
                <TextField
                  id="manualValue"
                  label="Description *"
                  variant="filled"
                  fullWidth
                  disabled={edit}
                  value={formProps.values.manualValue}
                  onChange={formProps.handleChange}
                  onBlur={formProps.handleBlur}
                  className={clsx(classes.textField, {
                    error:
                      formProps.errors.manualValue &&
                      formProps.touched.manualValue
                  })}
                />
              </Paper>
            </Grid>
          ) : null}
          <Grid item xs={6}>
            <Paper className={classes.paperGrid}>
              <TextField
                id="qty"
                label="Quantity *"
                variant="filled"
                fullWidth
                InputProps={{
                  inputComponent: NumberFormatCustomComponent,
                  inputProps: {
                    decimalScale: 2
                  }
                }}
                value={formProps.values.qty}
                onChange={event => {
                  this.handleChangeValues(
                    "qty",
                    event,
                    formProps,
                    "totalAmount"
                  );
                }}
                onBlur={formProps.handleBlur}
                className={clsx(classes.textField, {
                  error: formProps.errors.qty && formProps.touched.qty
                })}
              />
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper className={classes.paperGrid}>
              <TextField
                id="amount"
                label="Rate *"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                  inputComponent: NumberFormatCustomComponent,
                  inputProps: {
                    decimalScale: 4
                  }
                }}
                variant="filled"
                fullWidth
                value={formProps.values.amount}
                onChange={event => {
                  this.handleChangeValues(
                    "amount",
                    event,
                    formProps,
                    "totalAmount"
                  );
                }}
                onBlur={formProps.handleBlur}
                className={clsx(classes.textField, {
                  error: formProps.errors.amount && formProps.touched.amount
                })}
              />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.paperGrid}>
              <TextField
                id="totalAmount"
                label="Total *"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                  readOnly: true,
                  inputComponent: NumberFormatCustomComponent,
                  inputProps: {
                    decimalScale: 4
                  }
                }}
                value={formProps.values.totalAmount}
                onChange={formProps.handleChange}
                onBlur={formProps.handleBlur}
                className={clsx("onlyReadTitle", {
                  onlyRead: true,
                  error:
                    formProps.errors.totalAmount &&
                    formProps.touched.totalAmount
                })}
              />
            </Paper>
          </Grid>
        </Grid>
      </DialogCommonComponent>
    );
  };

  render() {
    const { data } = this.props;

    const dataDefault = {
      ...payrollAllowancesDefaultData,
      ...data
    };

    return (
      <Formik
        initialValues={{ ...dataDefault }}
        validationSchema={this.validationSchema}
        onSubmit={this.handleSubmitAllowance}
        render={this.renderForm}
        enableReinitialize
      />
    );
  }
}

ModalAddUntaxedAllowance.propTypes = {
  payrollAllowancesList: PropTypes.array,
  onAddValues: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  payrollAllowancesList:
    state.parameters.parameterByTypeData[
      parameterTypes.PARAMETER_ALLOWANCE_PAYROLL.parameterType
    ]
});

export default connect(
  mapStateToProps,
  null
)(withStyles(PayrollStyle)(ModalAddUntaxedAllowance));
