import initialState from "../../store/initialState";

export const businessDefaultData = {
	businessName: "",
	email: "",
	password: "",
	fullName: "",
	firstName: "",
	lastName: "",
	phone: "",
	title: "",
	relationship: ""
}

const OnboardInitialState = {
	...initialState,
	businessData: {
		...businessDefaultData
	}
};

export default OnboardInitialState;
