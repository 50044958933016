import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { Typography } from "@material-ui/core";
import PayrollStyle from "./payrollStyle";
import {
  DialogCommonPDFComponent,
  DialogConfirmCommonComponent,
  Analytics
} from "../common";
import ModalSTPDeclaration from "./modalSTPDeclaration";
import * as routes from "../../constants/routes";

class ModalSTPSummaryComponent extends Component {
  state = {
    openSTPModal: false,
    openEnable2FA: false
  };

  handleOpenEnable2FA = () => {
    this.setState(() => ({
      openEnable2FA: true
    }));
  };

  handleCloseEnable2FA = () => {
    this.setState(() => ({
      openEnable2FA: false
    }));
  };

  handleOpenSTPModal = () => {
    Analytics.processSTPAction();
    this.setState(() => ({
      openSTPModal: true
    }));
  };

  handleCloseModal = () => {
    const { onCloseModal } = this.props;
    if (onCloseModal) {
      onCloseModal();
    }
  };

  handleCloseSTPModal = () => {
    this.setState(() => ({
      openSTPModal: false
    }));
  };

  handleRedirect2FA = () => {
    const { history } = this.props;
    history.push(routes.PATH_SETTINGS_2FA);
  };

  render() {
    const { classes, open, summary, employer } = this.props;
    const { openSTPModal, openEnable2FA } = this.state;

    return (
      <div className={classes.root}>
        <DialogCommonPDFComponent
          title="Payroll Summary"
          open={open}
          onCloseModal={this.handleCloseModal}
          url={(summary || {}).urlResume}
          style2={{
            textAlign: "center",
            letterSpacing: 0,
            color: "#ffffff",
            opacity: 1,
            fontSize: "15px",
            fontWeight: "bold",
            fontFamily: "AvenirNextLTPro-Demi"
          }}
          style={{ borderRadius: 5 }}
          primaryButtonName="Lodge STP File with ATO"
          onPrimaryButtonClick={
            (employer.accountType == "accountant"
            ? employer.login.enabled2FA
            : employer.login.enable2FA)
              ? this.handleOpenSTPModal
              : this.handleOpenEnable2FA
          }
        />
        {openSTPModal ? (
          <ModalSTPDeclaration
            open={openSTPModal}
            onCloseModal={this.handleCloseSTPModal}
          />
        ) : null}
        {openEnable2FA ? (
          <DialogConfirmCommonComponent
            open={openEnable2FA}
            title="Two-Factor Authentication is required"
            primaryButtonName="Enable"
            onPrimaryButtonClick={this.handleRedirect2FA}
            secondaryButtonName="Cancel"
            onSecondaryButtonClick={this.handleCloseEnable2FA}
            onCloseModal={this.handleCloseEnable2FA}
          >
            <Typography
              variant="body1"
              gutterBottom
              className={classes.description}
            >
              {
                "We have added a new security layer to lodge STP files with ATO, enable the Two-Factor Authentication in 3 simple steps"
              }
            </Typography>
          </DialogConfirmCommonComponent>
        ) : null}
      </div>
    );
  }
}

ModalSTPSummaryComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  summary: PropTypes.object,
  open: PropTypes.bool.isRequired,
  employer: PropTypes.object
};

const mapStateToProps = state => ({
  employer: state.session.userData
});

export default connect(
  mapStateToProps,
  null
)(withRouter(withStyles(PayrollStyle)(ModalSTPSummaryComponent)));
