const PayrollStyle = theme => ({
  root: {
    ...theme.root
  },
  Grid: {
    margin: "-18px 0 2px -4px"
  },
  gridRight: {
    textAlign: "right"
  },
  gridContainer: {
    marginBottom: 50,
    marginTop: 50
  },
  paperContainer: {
    ...theme.paperContainer
  },
  paperContainerProcess: {
    ...theme.paperContainer,
    maxWidth: 900,
    width: "calc(100% - 200px)"
  },
  paperGrid: {
    ...theme.paperClear
  },
  paperGridNotPadding: {
    ...theme.paperClear,
    padding: 0
  },
  keyboardDatePicker: {
    ...theme.keyboardDatePicker
  },
  keyboardDatePickerInput: {
    ...theme.keyboardDatePickerInput
  },
  gridGap: {
    gridGap: theme.spacing(1)
  },
  placeHolder: {
    backgroundColor: "#f9f9f9",
    width: "100%",
    color: "#dedede",
    textAlign: "center",
    padding: "30px 0",
    margin: 0
  },
  borderTextField: {
    ...theme.borderTextField
  },
  upTemp: {
    marginTop: "-20px"
  },
  drawerPaper: {
    width: 200,
    ...theme.hiddenOverflow
  },
  margen: {
    margin: "20px 0"
  },
  paddingAll: {
    padding: "20px"
  },
  employeeActive: {
    borderRadius: "10px 0 0 10px",
    margin: "5px 0 0 20px",
    "&:hover": {
      background: "#f5f5f5",
      "&.avatarActText": {
        color: "inherit"
      }
    }
  },
  avatarText: {
    ...theme.hiddenOverflow,
    color: "inherit",
    fontWeight: "600",
    marginLeft: 10,
    fontSize: "12px",
    overflowWrap: "break-word",
    textOverflow: "ellipsis",
    marginRight: 25,
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical"
  },
  avatarActText: {
    ...theme.hiddenOverflow,
    color: "#007AFF",
    fontWeight: "600",
    marginLeft: 10,
    display: "block",
    fontSize: "12px",
    overflowWrap: "break-word",
    textOverflow: "ellipsis",
    marginRight: 25
  },
  avatarBadge: {
    position: "absolute",
    left: 0,
    "& span": {
      color: '#fff'
    }
  },
  tableH: {
    ...theme.tableH
  },
  tableRow: {
    ...theme.tableRow
  },
  tableTitle: {
    ...theme.tableTitle
  },
  tableText: {
    ...theme.tableText,
    paddingRight: "0px"
  },
  TableTd: {
    verticalAlign: "middle",
    padding: "3px 10px 3px 5px"
  },
  TableTdCell: {
    verticalAlign: "middle",
    padding: "3px 0 3px 5px"
  },
  h6: {
    fontSize: "30px"
  },
  centerText: {
    textAlign: "center"
  },
  switchSpace: {
    ...theme.switchSpace,
    padding: "20px 10px 20px"
  },
  signatureContainer: {
    padding: "60px 0"
  },
  checkIcon: {
    color: "#BBBBBB",
    fontSize: 30,
    marginLeft: 10,
    "&.active": {
      color: "#C1D24F"
    }
  },
  iconInTop: {
    width: 30,
    height: 30,
    fontSize: 30,
    marginLeft: 10
  },

  tableCustompayroll: {
    ...theme.tableRow,
    "&:hover": {
      "& td > .tableCellHover": {
        backgroundColor: "#e8e8e8"
      }
    }
  },
  textFieldCustomPayroll: {
    height: 30,
    padding: "0px 0px 0px 10px",
    maxHeight: 30,
    width: "100%",
  },
  textFieldCustomPayrollDelete: {
    height: 40,
    width: 40,
    borderRadius: 20,
    padding: 9,
    color: "#6c6c6c",
    margin: "auto auto",
    "&:hover": {
      backgroundColor: "#e8e8e8"
    }
  },
  tableCellHover: {
    padding: "14px 14px 11px 14px"
  },
  tableCellHoverDelete: {
    padding: "8px 0px 0px 0px"
  },
  btnProcessPayrol: {
    // width: "199px !important",
    // height: "48px !important",
    // opacity: 1,
    // borderRadius: "5px !important",

    // "& span": {
    //   textAlign: "center",
    //   fontFamily: "AvenirNextLTPro-Demi",
    //   fontSize: 18,
    //   letterSpacing: 0
    // }
  },
  disabledButtonSecondary: {
    ...theme.overrides.disabledButtonSecondary
  },
  orangeRectangularBtn: {
    ...theme.overrides.orangeRectangularBtn
  },
  btnProcessPayrol1: {
    // width: "200px !important",
    // height: "48px !important",
    // opacity: 1,
    // borderRadius: "5px !important",

    // "& span": {
    //   textAlign: "center",
    //   // fontFamily: "AvenirNextLTPro-Demi",
    //   // fontSize: 18,
    //   letterSpacing: 0
    // }
  },
  textField: {
    height: "43px !important",
    backgroundColor: "#f5f5f5 !important",
    "& div": {
      // "& input":{
      // 	backgroundColor: '#f5f5f5 !important',

      // },
      height: "41px !important",
      "& div": {
        "& button": {
          "& span": {
            "& svg": {
              "& path": {
                color: "red !important"
              }
            }
          }
        }
      }
    },
    "& label": {
      color: "#6D9FEE !important"
    }
  },
  titleDescription: {
    marginTop: 10,
    marginBottom: 60
  },
  payrollTitle: {
    textAlign: "left",
    fontFamily: "AvenirNextLTPro-Demi",
    letterSpacing: 0,
    lineHeight: 28,
    fontSize: 36,
    color: "#000000",
    opacity: 1
  },
  payrollSubTitle: {
    textAlign: "left",
    fontFamily: "AvenirNextLTPro-Medium",
    fontSize: "18px",
    letterSpacing: 0,
    color: "#000000",
    opacity: 1,
    lineHeight: 28
  },
  reviewPayrollBox: {
    marginTop: 70
  }
});

export default PayrollStyle;
