import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Box, Button, Grid, Paper, Typography } from "@material-ui/core";
import { Formik } from "formik";

import ValidationSchema from "./employeeValidationSchema";
import EmployeeStyles from "./employeeStyle";
import EmployeePersonalDetailsComponent from "./employeePersonalDetailsComponent";
import EmployeePayrollComponent from "./employeePayrollComponent";
import EmployeeEntitlementsComponent from "./employeeEntitlementsComponent";
import EmployeeTaxesComponent from "./employeeTaxesComponent";
import EmployeeSuperannuationComponent from "./employeeSuperannuationComponent";

import { AvatarCommonComponent } from "../common";
import * as employeeActions from "../../actions/EmployeeActions";
import * as parameterActions from "../../actions/ParameterActions";
import * as parameterTypes from "../../constants/parameterTypes";
import * as routes from "../../constants/routes";
import * as extensions from "../../helpers/extensions";
import { employeeDefaultData } from "../../reducers/Employees/EmployeeInitialState";
import ModalAddEmployee from "./modalAddEmployee";
import { getLocalToUTC } from "../../helpers/dateextensions";

class NewEmployeeComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      isValidation: false,
    };
  }
  componentDidMount() {
    const {
      loadParamters,
      clearSelectedEmployee,
      activeEmployeeList,
      employer,
      history,
    } = this.props;

    this.setState({
      showModal: false,
    });
    console.log("Setting showModal to false...");

    if (
      history.action !== "PUSH" ||
      employer.subscription.quantityEmployees <= activeEmployeeList.length
    ) {
      history.push(routes.PATH_EMPLOYEE);
    }

    clearSelectedEmployee(employeeDefaultData);

    loadParamters(
      parameterTypes.PARAMETER_STATES_AUSTRALIA.parameterType,
      parameterTypes.PARAMETER_STATES_AUSTRALIA.section,
      parameterTypes.PARAMETER_STATES_AUSTRALIA.typeSection
    );

    loadParamters(
      parameterTypes.PARAMETER_EMPLOYEE_TAX_RESIDENCY.parameterType,
      parameterTypes.PARAMETER_EMPLOYEE_TAX_RESIDENCY.section,
      parameterTypes.PARAMETER_EMPLOYEE_TAX_RESIDENCY.typeSection
    );

    loadParamters(
      parameterTypes.PARAMETER_EMPLOYEE_TAX_BAND.parameterType,
      parameterTypes.PARAMETER_EMPLOYEE_TAX_BAND.section,
      parameterTypes.PARAMETER_EMPLOYEE_TAX_BAND.typeSection
    );

    loadParamters(
      parameterTypes.PARAMETER_EMPLOYEE_CATEGORY.parameterType,
      parameterTypes.PARAMETER_EMPLOYEE_CATEGORY.section,
      parameterTypes.PARAMETER_EMPLOYEE_CATEGORY.typeSection
    );

    loadParamters(
      parameterTypes.PARAMETER_EMPLOYEE_STATUS.parameterType,
      parameterTypes.PARAMETER_EMPLOYEE_STATUS.section,
      parameterTypes.PARAMETER_EMPLOYEE_STATUS.typeSection
    );

    loadParamters(
      parameterTypes.PARAMETER_EMPLOYEE_PAYROLL_PAY_BASIS.parameterType,
      parameterTypes.PARAMETER_EMPLOYEE_PAYROLL_PAY_BASIS.section,
      parameterTypes.PARAMETER_EMPLOYEE_PAYROLL_PAY_BASIS.typeSection
    );

    loadParamters(
      parameterTypes.PARAMETER_EMPLOYEE_PAYROLL_PAY_FREQUENTLY.parameterType,
      parameterTypes.PARAMETER_EMPLOYEE_PAYROLL_PAY_FREQUENTLY.section,
      parameterTypes.PARAMETER_EMPLOYEE_PAYROLL_PAY_FREQUENTLY.typeSection
    );
  }

  handleSubmitForm = async (values, actions) => {
    const { employerId, employeeId, finishEmployeeData, history } = this.props;
    actions.setSubmitting(false);

    const finalEmployeeData = {
      ...values,
      temporal: false,
    };

    await finishEmployeeData(
      employerId,
      employeeId,
      finalEmployeeData,
      employeeDefaultData,
      history,
      routes.PATH_EMPLOYEE
    );
  };

  handleFinishEmployeeClick = async (formProps) => {
    const {
      employerId,
      employeeId,
      finishEmployeeData,
      history,
      employeeList,
    } = this.props;

    const finalEmployeeData = {
      ...formProps.values,
      temporal: false,
      active: true,
    };
    if (employeeList && employeeList.length > 0) {
      await finishEmployeeData(
        employerId,
        employeeId,
        finalEmployeeData,
        employeeDefaultData,
        history,
        routes.PATH_EMPLOYEE
      );
    } else {
      await finishEmployeeData(
        employerId,
        employeeId,
        finalEmployeeData,
        employeeDefaultData,
        history,
        null
      );
    }
  };
  componentWillReceiveProps = () => {
    const { employeeList, message } = this.props;

    if (employeeList && employeeList.length === 0 && message) {
      this.showModal();
    }
  };
  showModal = () => {
    this.setState({
      showModal: true,
    });
  };

  handleCloseModal = () => {
    this.setState({
      showModal: false,
    });
  };
  handleValidation = (isValidation) => {
    if (this.state.isValidation != isValidation) {
      this.setState({
        isValidation,
      });
    }
  };

  renderForm = (formProps) => {
    const { classes, employeeData, employeeId } = this.props;

    return (
      <div className={classes.root}>
        <form>
          <Paper className={classes.paperContainer}>
            <Grid
              container
              wrap="nowrap"
              spacing={2}
              alignItems="center"
              className={classes.firstTitle}
            >
              <Grid item>
                {!employeeData.detail.initials ||
                employeeData.detail.initials.length <= 0 ? (
                  <AvatarCommonComponent
                    text="NE"
                    src={employeeData.detail.imageUrl}
                    employeeId={employeeId}
                    isBig
                    hover
                  />
                ) : (
                  <AvatarCommonComponent
                    text={employeeData.detail.initials}
                    src={employeeData.detail.imageUrl}
                    employeeId={employeeId}
                    isBig
                    hover
                  />
                )}
              </Grid>
              <Grid item xs>
                {!extensions.getEmployeeFullname(employeeData) ||
                extensions.getEmployeeFullname(employeeData).length <= 0 ? (
                  <Typography variant="h1">New Employee</Typography>
                ) : null}
                <Typography variant="h1">
                  {extensions.getEmployeeFullname(employeeData)}
                </Typography>
                <Typography variant="h4" className={classes.subtitleHeader}>
                  {extensions.getEmployeeJobTitle(employeeData)}
                </Typography>
              </Grid>
            </Grid>

            <Grid item xs={12} className={classes.gridRight}>
              <Button
                variant="contained"
                color="secondary"
                disabled={!this.state.isValidation}
                className={classes.buttonFinish}
                onClick={() => this.handleFinishEmployeeClick(formProps)}
              >
                {"Finish"}
              </Button>
            </Grid>

            <Box m={2.5} />

            <EmployeePersonalDetailsComponent
              {...this.props}
              parentFormProps={formProps}
              handleValidation={this.handleValidation}
              edit={true}
            />

            <EmployeePayrollComponent
              {...this.props}
              parentFormProps={formProps}
            />

            <EmployeeEntitlementsComponent
              {...this.props}
              parentFormProps={formProps}
            />

            <EmployeeTaxesComponent
              {...this.props}
              parentFormProps={formProps}
            />

            <EmployeeSuperannuationComponent
              {...this.props}
              parentFormProps={formProps}
            />

            <Grid item xs={12} className={classes.gridRight}>
              <Button
                variant="contained"
                color="secondary"
                disabled={!this.state.isValidation}
                className={classes.buttonFinish}
                onClick={() => this.handleFinishEmployeeClick(formProps)}
              >
                {"Finish"}
              </Button>
            </Grid>
          </Paper>
        </form>
        {this.state.showModal && (
          <ModalAddEmployee
            onPrimaryButtonClick={this.handleCloseModal}
            open={this.state.showModal}
            onCloseModal={this.handleCloseModal}
          />
        )}
        {/* {<ModalAddEmployee onPrimaryButtonClick={this.handleCloseModal} open={true} onCloseModal={this.handleCloseModal} />} */}
      </div>
    );
  };

  render() {
    const { employeeData } = this.props;
    return (
      <Formik
        initialValues={{ ...employeeData }}
        validationSchema={ValidationSchema}
        onSubmit={this.handleSubmitForm}
        render={this.renderForm}
        enableReinitialize="true"
      />
    );
  }
}

NewEmployeeComponent.propTypes = {
  token: PropTypes.string.isRequired,
  employerId: PropTypes.string.isRequired,
  employeeId: PropTypes.string,
  employeeData: PropTypes.object,
  employer: PropTypes.object,
  activeEmployeeList: PropTypes.array,

  saveEmployeeData: PropTypes.func,
  updateTempFullname: PropTypes.func,
  updateTempJobTitle: PropTypes.func,
  loadParamters: PropTypes.func,
  clearSelectedEmployee: PropTypes.func,
  finishEmployeeData: PropTypes.func,
};

const mapStateToProps = (state) => ({
  token: state.session.token,
  employerId: state.session.userData.employerId,
  employeeId: state.employees.selectedEmployeeData.id,
  employeeData: state.employees.selectedEmployeeData,
  employer: state.session.userData,
  activeEmployeeList: state.employees.activeEmployeesList,
  employeeList: state.employees.list,
  isLoading: state.employees.isLoading,
  message: state.employees.saveEmployeeWithFinish,
});

const mapDispatchToProps = (dispatch) => ({
  saveEmployeeData: bindActionCreators(
    employeeActions.saveEmployeeDataRequest,
    dispatch
  ),
  updateTempFullname: bindActionCreators(
    employeeActions.updateTempInformation,
    dispatch
  ),
  updateTempJobTitle: bindActionCreators(
    employeeActions.updateTempInformation,
    dispatch
  ),
  loadParamters: bindActionCreators(
    parameterActions.getParametersByTypeRequest,
    dispatch
  ),
  clearSelectedEmployee: bindActionCreators(
    employeeActions.setCleanDataToStore,
    dispatch
  ),
  finishEmployeeData: bindActionCreators(
    employeeActions.finishEmployee,
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(EmployeeStyles)(NewEmployeeComponent)));
