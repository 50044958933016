import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import { Typography } from "@material-ui/core";
import DialogCommonComponent from "./dialogCommonComponent";
import { SubscriptionUpgradeStyles } from "./commonStyles";

import * as routes from "../../constants/routes";

class SubscriptionUpgradeComponent extends Component {
	handleGoToSubscription = () => {
		const { history, onCloseModal } = this.props;
		history.push(routes.PATH_SUBSCRIPTION_PLAN);

		if (onCloseModal) {
			onCloseModal();
		}
	};

	render() {
		const {
			employer, open, showMessageEmployees, showMessagePayroll
		} = this.props;

		return (
			<DialogCommonComponent open={open} size="xs" onCloseModal={this.handleGoToSubscription} onPrimaryButtonClick={this.handleGoToSubscription} primaryButtonName="Ok" title={showMessagePayroll ? "Unable to process payroll" : "Unable to add employees"}>
				{showMessageEmployees ? <Typography>{`Your current plan allows up to ${employer.subscription.quantityEmployees} employees, Please upgrade your plan in Settings to add more employees.`}</Typography> : null}
				{showMessagePayroll ? <Typography>Active your plan subscription to proceed</Typography> : null}
			</DialogCommonComponent>
		);
	}
}

SubscriptionUpgradeComponent.propTypes = {
	employer: PropTypes.object
};

const mapStateToProps = state => ({
	employer: state.session.userData
});

export default connect(
	mapStateToProps,
	null
)(withRouter(withStyles(SubscriptionUpgradeStyles)(SubscriptionUpgradeComponent)));
