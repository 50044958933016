import backgroundFooter from "../../assets/images/olas.footer.png";

const planStyle = theme => ({
	root: {
		...theme.root
	},
	paperContainer: {
		...theme.paperContainer
	},
	paperClear: {
		...theme.paperClear,
		backgroundColor: "#FFF",
		marginTop: 36,
		borderRadius: 5,
		padding: "15px 20px 10px 50px",
		border: "1px solid #E9E9E9"
	},
	paper: {
		...theme.paper,
		width: 766,
		height: 220,
		border: "1px solid #E9E9E9",
		borderRadius: 5,
		boxShadow: "none"
	},
	gridContainer: {
		marginTop: 84
	},
	gridItem: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		"&.isMobile": {
			marginBottom: 20
		}
	},
	roundedContent: {
		borderRadius: 20,
		border: "1px solid #D8D8D8",
		width: 134,
		textAlign: "center",
		padding: "11px 26px",
		height: 40
	},
	textField: {
		...theme.textField,
		width: 338
	},
	gridRight: {
		marginTop: 28,
		textAlign: "right"
	},
	textCoupon: {
		borderBottom: "3px solid #FD5739",
		borderRadius: 0,
		width: 110,
		height: 35,
		"&:hover": {
			borderBottom: "3px solid #FD5739",
		},
		"&>div>input": {
			fontSize: "medium",
			fontWeight: "bold"
		}

	},
	buttonCoupon: {
		marginLeft: 15,
		marginBottom: 5,
		minWidth: 50,
		borderRadius: 10,
		padding: 0,
		cursor: "pointer",
	},
	gridRightClearButton: {
		borderRadius: '5px !important',
		"& span": {
			fontFamily: 'AvenirNextLTPro-Demi !important',
		}
	},
	orangeRectangularBtn: {
		...theme.overrides.orangeRectangularBtn
	},
});

const planItemStyle = () => ({
	card: {
		width: 200,
		borderRadius: 30,
		border: "none",
		boxShadow: "-1px 2px 28px 0px rgba(150,150,150,0.23)"
	},
	cardHeader: {
		backgroundColor: "#FD5739",
		height: 36,
		padding: 0
	},
	cardHeaderTitle: {
		color: "#FFF",
		textAlign: "center",
		fontSize: 17
	},
	cardContent: {
		textAlign: "center",
		padding: 0
	},
	cardPrice: {
		fontSize: 36,
		marginTop: 45
	},
	percentageOff: {
		marginTop: 0,
	},
	plnCardPriceOff: {
		position: "relative",
		// "&::before": {
		// 	content: "",
		// 	position: "absolute",
		// 	width: "100px",
		// 	height: "3px",
		// 	backgroundColor: "red",
		// 	transform: "rotate(-25deg)",
		// }
	},
	cardButton: {
		margin: "46px 0 0 0",
		padding: 0,
		minWidth: 135
	},
	cardActions: {
		marginTop: 26,
		height: 38,
		backgroundImage: `url(${backgroundFooter})`,
		backgroundSize: "100% 100%",
		backgroundRepeat: "no-repeat"
	},

});

export { planStyle as PlanStyle, planItemStyle as PlanItemStyle };
