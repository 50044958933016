import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";

import { Formik, Field } from "formik";
import * as Yup from "yup";

import {
	Button, Grid, Paper, Typography
} from "@material-ui/core";

import { KeyboardDatePicker } from "@material-ui/pickers";
import paymentStyles from "./paymentStyle";
import * as employeeActions from "../../actions/EmployeeActions";
import * as paymentActions from "../../actions/PaymentActions";
import EmployeesComponent from "../reports/employeesComponent";
import DialogConfirmCommonComponent from "../common/dialogConfirmCommonComponent";
import ModalAuthorisedPerson from "./modalAuthorisedPerson";
import * as routes from "../../constants/routes";
import { getUTCDate } from "../../helpers/dateextensions";
import { CardCommonComponent, DialogCommonPDFComponent } from "../common";
import reportsEmpty from "../../assets/images/reports.empty.png";

const DatePickerField = ({
	field, form, classes, minDate, maxDate, ...other
}) => {
	const currentError = form.errors[field.name];
	return (
		<KeyboardDatePicker type="date-local"
			name={field.name}
			label={field.label}
			value={field.value}
			format="DD/MM/YYYY"
			fullWidth
			error={Boolean(currentError)}
			onError={(_, error) => form.setFieldError(field.name, error)}
			onChange={date => form.setFieldValue(field.name, date, true)}
			className={clsx(classes.textField, {
				errorDatePicker: Boolean(currentError)
			})}
			InputProps={{
				classes: {
					root: classes.keyboardDatePicker,
					input: classes.keyboardDatePickerInput
				}
			}}
			minDate={minDate}
			maxDate={maxDate}
			{...other}
		/>
	);
};

class PaymentComponent extends Component {
	validationSchema = Yup.object({
		startDate: Yup.date().required("Start Date is required"),
		finishDate: Yup.date()
			.required("Finish Date is required")
			.when("startDate", (startDate, schema) => startDate && schema.min(startDate, "The start date must be less than the end date.")),
		employeeIds: Yup.array().required("Selected employees"),
		firstName: Yup.string().required("First Name is required"),
		lastName: Yup.string().required("Last Name is required")
	});

	state = {
		dialogProcessOpen: true,
		dialogAuthorisedPersonOpen: false,
		dialogPDFOpen: false
	};

	componentDidMount() {
		const { getAllEmployees, mixpanel } = this.props;
		getAllEmployees();

		mixpanel.track("PaymentSummaries.Screen");
	}

	componentWillReceiveProps = (nextProps) => {
		if ((nextProps.paymentData || {}).urlResume) {
			this.setState({ dialogAuthorisedPersonOpen: false, dialogPDFOpen: true });
		}
	};

	handleSubmitPayment = async (values, actions) => {
		actions.setSubmitting(false);
		const { processPayment } = this.props;
		await processPayment({
			dateFrom: values.startDate,
			dateTo: values.finishDate,
			employerId: values.employerId,
			employeeId: values.employeeIds[0],
			authorisedFirstName: values.firstName,
			authorisedLastName: values.lastName
		});
	};

	handleCancelDialogProcess = () => {
		this.setState({ dialogProcessOpen: false });
		const { history } = this.props;
		history.push(routes.PATH_EMPLOYEE);
	};

	handlePrimaryDialogProcess = () => {
		this.setState({ dialogProcessOpen: false });
	};

	handleOpenDialogAuthorizedPerson = () => {
		this.setState({ dialogAuthorisedPersonOpen: true });
	};

	handleCloseDialogAuthorizedPerson = () => {
		this.setState({ dialogAuthorisedPersonOpen: false });
	};

	handleCloseDialogPDF = () => {
		this.setState({ dialogPDFOpen: false });
	};

	processReport = (formProps, firstName, lastName) => {
		formProps.setFieldValue("firstName", firstName);
		formProps.setFieldValue("lastName", lastName);

		formProps.handleSubmit();
	};

	renderForm = (formProps) => {
		const {
			classes, employees, financialYear, paymentData, isLoading
		} = this.props;
		const {
			dialogProcessOpen, dialogAuthorisedPersonOpen, dialogPDFOpen
		} = this.state;

		return (
			<div className={classes.root}>
				<Grid item sm={12} lg={9} className={classes.Grid}>
					<Paper className={classes.paperContainer}>
						<Typography variant="h1" gutterBottom>
							Payment Summaries
						</Typography>
						<Typography>Select your employees and period to create Individual Payment Summaries</Typography>

						<Grid container alignItems="center" className={classes.gridContainer}>
							<Grid item xs={12} className={classes.gridRight}>
								<Button variant="contained" color="primary" className={classes.gridRightClearButton} onClick={this.handleOpenDialogAuthorizedPerson} disabled={formProps.values.employeeIds.length === 0 || isLoading}>
									{"Process Report"}
								</Button>
							</Grid>
						</Grid>
						{employees && employees.length <= 0 && !isLoading ? (
							<div className={classes.imageEmptyContainer}>
								<img className={classes.imageEmptyImage} src={reportsEmpty} alt="" />
								<Typography variant="body1" className="gray">
									{"You haven't processed any payment summaries yet."}
								</Typography>
							</div>
						) : null}
						{employees && employees.length > 0 ? (
							<div>
								<CardCommonComponent title="Select dates" complete={false} hideExpandCheck>
									<Grid container alignItems="flex-start">
										<Grid item xs={3}>
											<Paper className={classes.paperGrid}>
												<Field name="startDate" label="Start Date" component={DatePickerField} classes={classes} minDate={getUTCDate(financialYear.dateFrom)} maxDate={getUTCDate(financialYear.dateTo)} />
											</Paper>
										</Grid>
										<Grid item xs={3}>
											<Paper className={classes.paperGrid}>
												<Field name="finishDate" label="Finish Date" component={DatePickerField} classes={classes} minDate={getUTCDate(financialYear.dateFrom)} maxDate={getUTCDate(financialYear.dateTo)} />
											</Paper>
										</Grid>
									</Grid>
								</CardCommonComponent>
								<EmployeesComponent employees={employees} formProps={formProps} name="employeeIds" singleSelection />
							</div>
						) : null}

						<DialogConfirmCommonComponent open={dialogProcessOpen} title="Attention" primaryButtonName="Proceed" onPrimaryButtonClick={this.handlePrimaryDialogProcess} onCloseModal={this.handleCancelDialogProcess}>
							<Typography variant="body1" gutterBottom className={classes.description}>
								<span>
									{"If you run this report your won't be able to process any further payslips for this employee within this Financial Year."}
									<br />
									<br />
									{"Do you want to proceed and create their Individual Payment Summary?"}
								</span>
							</Typography>
						</DialogConfirmCommonComponent>
					</Paper>
				</Grid>

				<ModalAuthorisedPerson open={dialogAuthorisedPersonOpen} onCloseModal={this.handleCloseDialogAuthorizedPerson} formPropsParent={formProps} onPrimaryButtonClick={this.processReport} />
				{paymentData && paymentData.urlResume ? <DialogCommonPDFComponent key="paymentSummary" title="Payment Summary" open={dialogPDFOpen} onCloseModal={this.handleCloseDialogPDF} url={paymentData.urlResume} /> : null}
			</div>
		);
	};

	render() {
		const { classes, financialYear, employerId } = this.props;

		const dataReport = {
			startDate: getUTCDate(financialYear.dateFrom),
			finishDate: getUTCDate(financialYear.dateTo),
			employeeIds: [],
			employerId
		};

		return (
			<Grid container className={classes.root}>
				<Formik initialValues={{ ...dataReport }} validationSchema={this.validationSchema} onSubmit={this.handleSubmitPayment} render={this.renderForm} enableReinitialize />
			</Grid>
		);
	}
}

PaymentComponent.propTypes = {
	employees: PropTypes.array,
	getAllEmployees: PropTypes.func
};

const mapStateToProps = state => ({
	isLoading: state.employees.isLoading || state.payment.isLoading,
	employerId: state.session.userData.employerId,
	employees: state.employees.reportsEmployeeList,
	paymentData: state.payment.paymentData,
	financialYear: state.session.userData.financialYear
});

const mapDispatchToProps = dispatch => ({
	getAllEmployees: bindActionCreators(employeeActions.employeesRequest, dispatch),
	processPayment: bindActionCreators(paymentActions.processPaymentRequest, dispatch)
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(withStyles(paymentStyles)(PaymentComponent)));
