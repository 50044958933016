import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import {
	Grid, IconButton, Paper, Typography
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { connect } from "react-redux";
import employerStyle from "./employerStyle";

class WpnInformationComponent extends Component {
	render() {
		const {
			classes, border, onClose, error, errorMessage
		} = this.props;

		return (
			<div>
				{error && errorMessage ? (
					<Paper
						className={clsx(classes.paperContainer, {
							[classes.paperContainerBorder]: border
						})}
					>
						<Grid container direction="row" alignItems="center">
							<Grid item xs={8} className={classes.errorContent}>
								<Typography>{errorMessage}</Typography>
								<IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
									<CloseIcon />
								</IconButton>
							</Grid>
						</Grid>
					</Paper>
				) : null}
			</div>
		);
	}
}

const mapStateToProps = state => ({
	error: state.employer.error,
	errorMessage: state.employer.errorMessage
});

export default connect(
	mapStateToProps,
	null
)(withStyles(employerStyle)(WpnInformationComponent));
