const externaUserStyles = () => ({
	root: {
		display: "flex",
		flexGrow: 1,
		height: "100vh",
		backgroundColor: "#ffffff"
	}
});

const sessionUserStyles = theme => ({
	root: {
		display: "flex"
	},
	appBarSpacer: theme.mixins.toolbar,
	content: {
		...theme.showScroll,
		flexGrow: 1,
		padding: "0 24px 80px 24px",
		height: "100vh"
	}
});

export { externaUserStyles as ExternaUserStyles, sessionUserStyles as SessionUserStyles };
