import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Formik } from "formik";
import * as Yup from "yup";

import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import {
	Grid, Paper, TextField, InputAdornment
} from "@material-ui/core";

import PayrollStyle from "./payrollStyle";

import * as parameterTypes from "../../constants/parameterTypes";

import { DialogCommonComponent, NumberFormatCustomComponent } from "../common";
import { payrollDeductionsDefaultData } from "../../reducers/Payroll/PayrollInitialState";
import payrollDeductionsList from './../../helpers/payrollDeductionsList.json';


class ModalAddDeductions extends Component {
	validationSchema = Yup.object({
		type: Yup.string().required("Selected deductions").test("name-default", "Selected deductions", type => type !== "-"),
		description: Yup.string(),
		total: Yup.number().required("Total is required")
	});

	renderDeductionsOptions = () => {
		const { classes } = this.props;
		const values = [
			<option key="option_0" value="-" className={classes.option}>
				Select option
			</option>
		];

		values.push(payrollDeductionsList
			.sort((firstEl, secondEl) => firstEl.order < secondEl.order)
			.map(item => (
				<option key={`${item.id}`} value={`${item.value}`} className={classes.option}>
					{item.value}
				</option>
			)));

		return values;
	};

	handleSubmitDeduction = (value, actions) => {
		const { onAddValues } = this.props;
		actions.setSubmitting(false);
		onAddValues(value);
	};

	handleChangeValues = (name, event, formProps) => {
		const { value } = event.target;
		formProps.setFieldValue(name, value);
	};

	renderForm = (formProps) => {
		const {
			classes, open, onCloseModal, edit
		} = this.props;

		return (
			<DialogCommonComponent title="Add deductions" primaryButtonName={edit ? "Update" : "Save"} size="xs" onPrimaryButtonClick={formProps.handleSubmit} open={open} onCloseModal={onCloseModal}>
				<Grid container direction="row" alignItems="center">
					<Grid item xs={12}>
						<Paper className={classes.paperGrid}>
							<TextField
								id="type"
								label="Deductions type *"
								select
								variant="filled"
								fullWidth
								disabled={edit}
								value={formProps.values.type}
								onChange={formProps.handleChange}
								onBlur={formProps.handleBlur}
								className={clsx(classes.textField, {
									error: formProps.errors.type && formProps.touched.type
								})}
								SelectProps={{
									native: true,
									MenuProps: {
										className: classes.menu
									}
								}}
							>
								{this.renderDeductionsOptions()}
							</TextField>
						</Paper>
					</Grid>
					{formProps.values.type === "Manual entry" ? (
						<Grid item xs={12}>
							<Paper className={classes.paperGrid}>
								<TextField
									id="description"
									label="Description"
									variant="filled"
									fullWidth
									disabled={edit}
									value={formProps.values.description}
									onChange={formProps.handleChange}
									onBlur={formProps.handleBlur}
									className={clsx(classes.textField, {
										error: formProps.errors.description && formProps.touched.description
									})}
								/>
							</Paper>
						</Grid>
					) : null}

					<Grid item xs={12}>
						<Paper className={classes.paperGrid}>
							<TextField
								id="total"
								label="Total *"
								variant="filled"
								fullWidth
								InputProps={{
									startAdornment: <InputAdornment position="start">$</InputAdornment>,
									inputComponent: NumberFormatCustomComponent,
									inputProps: {
										decimalScale: 2,
										fixedDecimalScale: true
									}
								}}
								value={formProps.values.total}
								onChange={formProps.handleChange}
								onBlur={formProps.handleBlur}
								className={clsx(classes.textField, {
									error: formProps.errors.total && formProps.touched.total
								})}
							/>
						</Paper>
					</Grid>
				</Grid>
			</DialogCommonComponent>
		);
	};

	render() {
		const { data } = this.props;

		const dataDefault = {
			...payrollDeductionsDefaultData,
			...data
		};

		return <Formik initialValues={{ ...dataDefault }} validationSchema={this.validationSchema} onSubmit={this.handleSubmitDeduction} render={this.renderForm} enableReinitialize />;
	}
}

ModalAddDeductions.propTypes = {
	// payrollDeductionsList: PropTypes.array,
	onAddValues: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
	// payrollDeductionsList: {}
});

export default connect(
	mapStateToProps,
	null
)(withStyles(PayrollStyle)(ModalAddDeductions));
