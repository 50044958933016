import React, { Component } from "react";
import PropTypes, { number } from "prop-types";

import { getIn } from "formik";

import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import {
	Grid, Paper, TextField, InputAdornment
} from "@material-ui/core";

import { SwitchCommonComponent } from "../common";
import PayrollStyle from "./payrollStyle";

class ManualOverwriteComponent extends Component {
	handleChangePAYG = (e, formProps) => {

		let value = e.target.value
		let char = value[value.length - 1]
		if (char && !char.match(/[^\d-]/)) {
			formProps.setFieldValue(e.target.id, value)
		} else if (value.length === 0) {
			formProps.setFieldValue(e.target.id, value)
		}

	}
	render() {
		const { classes, formProps, isFinishAllEmployees } = this.props;

		return (
			<Grid item xs={9}>
				<Paper className={classes.paperGrid}>
					<SwitchCommonComponent id="payroll.isOverwrite" title="Manual overwrite" checked={Boolean(formProps.values.payroll.isOverwrite)} onChange={formProps.handleChange} onBlur={formProps.handleBlur} disabled={isFinishAllEmployees} />
					{formProps.values.payroll.isOverwrite ? (
						<Grid container direction="row" alignItems="flex-start" className={classes.switchSpace}>
							<Grid item xs={6} className={classes.paperGrid}>
								<TextField
									id="payroll.overwrite_PAYG"
									label="PAYG Withholding"
									InputProps={{
										startAdornment: <InputAdornment position="start">$</InputAdornment>,

									}}
									variant="filled"
									helperText="Amount to be withheld"
									fullWidth
									// type='number'

									value={formProps.values.payroll.overwrite_PAYG}
									onChange={event => this.handleChangePAYG(event, formProps)}
									onBlur={formProps.handleBlur}
									className={clsx(classes.textField, {
										error: getIn(formProps.errors, "payroll.overwrite_PAYG") && getIn(formProps.touched, "payroll.overwrite_PAYG")
									})}
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									id="payroll.overwrite_Super"
									label="Superannuation"
									InputProps={{
										startAdornment: <InputAdornment position="start">$</InputAdornment>
									}}
									variant="filled"
									helperText="Amount of super to be contributed"
									fullWidth
									value={formProps.values.payroll.overwrite_Super}
									onChange={formProps.handleChange}
									onBlur={formProps.handleBlur}
									className={clsx(classes.textField, {
										error: getIn(formProps.errors, "payroll.overwrite_Super") && getIn(formProps.touched, "payroll.overwrite_Super")
									})}
								/>
							</Grid>
						</Grid>
					) : null}
				</Paper>
			</Grid>
		);
	}
}

ManualOverwriteComponent.propTypes = {
	formProps: PropTypes.object.isRequired
};

export default withStyles(PayrollStyle)(ManualOverwriteComponent);
