
const getItem = (localStorageName) => {
	const storageItem = localStorage.getItem(localStorageName);
	if (storageItem) {
		let json = JSON.parse(storageItem);
		if (localStorageName == "eps_all_parameters") {
			// let find = json.data.find(i => i.value === "Quantity");
			// if (!find) {
			// 	json.data.push({
			// 		"id": "c653c3c5-6e8c-45e6-871f-d8833e0b5426",
			// 		"order": 2,
			// 		"section": "Employee",
			// 		"type": "ParameterDAO",
			// 		"typeSection": "Payroll_pay_basis",
			// 		"value": "Quantity"
			// 	});
			// }
			let find = json.data.find(i => i.value === "Per Job / Unit");
			if (!find) {
				json.data.push({
					"id": "c653c3c5-6e8c-45e6-871f-d8833e0b5426",
					"order": 2,
					"section": "Employee",
					"type": "ParameterDAO",
					"typeSection": "Payroll_pay_basis",
					"value": "Per Job / Unit"
				});
			}
			let Laundry = json.data.find(i => i.typeSection === "Allowance" && i.value == "Manual Entry");
			if (Laundry) {
				Laundry.value = "Laundry";
			} else {
				json.data.push({
					"id": 3,
					"section": "Process_Payroll",
					"typeSection": "Allowance",
					"value": "Laundry",
					"order": 3
				});
			}

			let Transport = json.data.find(i => i.typeSection === "Allowance" && i.value == "Transport");
			if (!Transport) {
				json.data.push({
					"id": 4,
					"section": "Process_Payroll",
					"typeSection": "Allowance",
					"value": "Transport",
					"order": 4
				});
			}
			let Car = json.data.find(i => i.typeSection === "Allowance" && i.value == "Car");
			if (!Car) {
				json.data.push({
					"id": 5,
					"section": "Process_Payroll",
					"typeSection": "Allowance",
					"value": "Car",
					"order": 5
				});
			}
			let Manual_Entry = json.data.find(i => i.typeSection === "Allowance" && i.value == "Manual Entry");
			if (!Manual_Entry) {
				json.data.push({
					"id": 6,
					"section": "Process_Payroll",
					"typeSection": "Allowance",
					"value": "Manual Entry",
					"order": 6
				});
			}
		}
		return json;
	}
	return null;
};

const setItem = (localStorageName, localStorageItem) => {

	localStorage.setItem(localStorageName, JSON.stringify(localStorageItem));
};

const deleteItem = (localStorageName) => {
	localStorage.removeItem(localStorageName);
};

export default {
	getItem,
	setItem,
	deleteItem
};
