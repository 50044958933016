import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
	Grid, List, ListItem, ListItemText, Typography
} from "@material-ui/core";
import Moment from "react-moment";
import singleTouchStyle from "./singleTouchStyle";
import DialogCommonComponentNew from "./dialogCommonComponentNew";

class ModalRemoveSTP extends Component {
	render() {
		const {
			classes, onPrimaryButtonClick, open, onCloseModal, stpStatus
		} = this.props;

		return (
			<DialogCommonComponentNew
				styleTitle={{
					textAlign: "center",
					fontFamily: "AvenirNextLTPro-Demi",
					letterSpacing: 0,
					color: "#000000",
					opacity: 1,
					fontSize: 17
				}}
				title={ stpStatus == 3 ? "Remove STP File Not Lodged with ATO" : "Remove Action Required STP File" }
				size="xs"
				onPrimaryButtonClick={onPrimaryButtonClick}
				open={open}
				onCloseModal={onCloseModal}
				primaryButtonName="Remove Outstanding STP File"
				style={{ borderRadius: 5, height: 40, minWidth: 200, marginLeft: 0, marginBottom: 0 }}
				style2={{
					fontFamily: "AvenirNextLTPro-Demi",
					fontSize: "15px"
				}}
			>
				<Grid container direction="row" alignItems="center">
					{
						stpStatus == 3 ?
							<Typography variant="body1" gutterBottom align="center">
								This will hide the outstanding STP file, and is to be used when submission to the ATO is not required.
								<br/>
								<a href="https://easypayslip.zendesk.com/hc/en-us/articles/360001390176" target="_blank">Click here</a> for more information.
							</Typography>
							:
							<Typography variant="body1" gutterBottom align="center">
								This will hide the Action Required STP file, and is to be used when submission to the ATO is unabled / not going to be relodged with the ATO.
							</Typography>
					}
					{
						stpStatus == 3 ?
							<Typography variant="body1" gutterBottom align="center">
								This should not be used for incorrect payslip data. If incorrect, payslips should be deleted by running the <a href="/reports/payslips">payslips report</a>, and then re-create if necessary.
							</Typography>
							:
							<Typography variant="body1" gutterBottom align="center">
								Please note that if you do regenerate this row, you will need to regenerate the payslips and/or Final STP file in order to relodge these files with the ATO, after making any required corrections.
							</Typography>
					}
					{
						stpStatus == 3 ?
							<Typography variant="body1" gutterBottom align="center">
								<a href="https://easypayslip.zendesk.com/hc/en-us/articles/360000218075-How-to-Delete-a-Payslip" target="_blank">Deleting payslips</a> will automatically remove them from STP files yet to be lodged.
							</Typography>
							:
							<></>
					}
				</Grid>
			</DialogCommonComponentNew>
		);
	}
}

export default withStyles(singleTouchStyle)(ModalRemoveSTP);
