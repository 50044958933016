import * as Event from './event'
import mixpanel from "mixpanel-browser";
import moment from 'moment';
import branch from 'branch-sdk';
import request from 'request';

console.log("INITIALISING MIXPANEL");
const BRANCH_KEY = 'key_live_ccTwR0vHRpAsDNdlUn3GFdlatDggEqSj';
mixpanel.init(window.envVariables.mixpanelId);
branch.init(BRANCH_KEY);
// mixpanel.config.debug = true;

class Analytics {

    /**
     * @param {email,password} user
     */
    static accountSignupAction(user, employer) {
        // mixpanel.identify(employer.id || employer.employerId);
        mixpanel.track(Event.AccountSignupAction, {
            email: user.email
            // password: user.password,
        });

        mixpanel.people.set({
            $AccountSignupDate: moment().format('YYYY-MM-DD HH:mm:ss'),
            $Signup_Platform: 'web',
            $email: user.email,
            $subscription_status: 'Trialing',
            $subscription_type: 'Trial'
        });

        branch.setIdentity(employer.id);
        branch.logEvent("sign_up", null, (err) => { if (err) console.log(`Branch err: ${err}`) });
    }
    /**
     * @param {company,contact} data
     */
    static completeRegisterAction(props) {
        try {
            if (window.isOpen == true) {
                mixpanel.track(Event.OB_8ABNWPNAction, props);
                mixpanel.track(Event.OB_9ContactBAction, props);
                // mixpanel.track(Event.OB_10, props);
                mixpanel.track(Event.CompleteRegisterAction, props);
                // branch.setIdentity(employer.id);
                window.isOpen = false;
                branch.logEvent("COMPLETE_REGISTRATION", null, null, null, (err) => { if (err) console.log(`Branch err: ${err}`) });
            }
        } catch (ex) {
            // no log
        }

    }

    static completeOnlyRegisterAction(props) {
        try {
            if (window.isOpen == true) {
                // mixpanel.track(Event.OB_8ABNWPNAction, props);
                // mixpanel.track(Event.OB_9ContactBAction, props);
                // mixpanel.track(Event.OB_10, props);
                mixpanel.track(Event.CompleteRegisterAction, props);
                window.isOpen = false;
            }
        } catch (ex) {
            // no log
        }

    }


    static loginAction() {
        console.log("Sending login event branchio")
        branch.logEvent("LOGIN", null, null, null, (err) => { if (err) console.log(`Branch err: ${err}`) });
    }

    static addEmployeeAction() {
        mixpanel.track(Event.AddEmployeeAction);
    }

    static saveEmployeeAction() {
        mixpanel.track(Event.SaveEmployeeAction);
    }

    // static initiatePayrollAction() {
    //     mixpanel.track(Event.InitiatePayrollAction);
    // }

    static submitPayrollAction() {
        mixpanel.track(Event.SubmitPayrollAction);
    }

    // static sendPayrollAction() {
    //     mixpanel.track(Event.SendPayrollAction);
    // }

    static initiateSTPAction() {
        mixpanel.track(Event.InitiateSTPAction);
    }

    static processSTPAction() {
        mixpanel.track(Event.ProcessSTPAction);
    }

    static sendSTPAction() {
        mixpanel.track(Event.SendSTPAction);
    }

    static editEmployeeAction() {
        mixpanel.track(Event.EditEmployeeAction);
    }

    static mapSubStatus(status) {
        const statuses = {
            0: "Trialing",
            1: "Active",
            2: "Past Due",
            3: "Unpaid",
            4: "Canceled"
        };

        return statuses[status];
    }

    static mapSubType(type) {
        const types = {
            0: "Trial",
            1: "ITunes",
            2: "Stripe"
        };

        return types[type];
    }

    static updateProfileInfoAction(employer) {

        // console.log("TRADING: ", employer.company ? (employer.company.abn ? (employer.company.abn.userTradingName || employer.company.abn.tradingName) : employer.company.wpn.tradingName) : "");
        console.log(employer);
        mixpanel.reset();
        mixpanel.identify(employer.id);
        mixpanel.people.set({
            $ABN: (employer.company && employer.company.abn) ? employer.company.abn.number : '',
            $STP_First: employer.company ? employer.company.authorizedPerson.firstName : "",
            $STP_Middle: employer.company ? employer.company.authorizedPerson.middleName : "",
            $STP_Last: employer.company ? employer.company.authorizedPerson.lastName : "",
            $Employer_First: employer.company ? employer.contact.firstName : "",
            $Employer_Middle: employer.contact ? employer.contact.middleName : "",
            $Employer_Last: employer.contact ? employer.contact.lastName : "",
            $Employer_Phone: employer.contact ? employer.contact.phoneNumber : '',
            $Entity_Name: employer.company ? employer.company.abn.name || employer.company.wpn.name : "",
            $Trading_Name: employer.company ? (employer.company.abn ? (employer.company.abn.userTradingName || employer.company.abn.tradingName) : employer.company.wpn.tradingName) : "",
            $Days_Trial: employer.subscription ? employer.subscription.dayTrial : '',
            $subscription_status: employer.subscription ? Analytics.mapSubStatus(employer.subscription.status) : '',
            $subscription_type: employer.subscription ? Analytics.mapSubType(employer.subscription.typeSubscription) : ''
        });
    }
    static CancelSubscriptionAction() {
        mixpanel.track(Event.CancelSubscriptionAction);
    }
    static ChangeSubscriptionAction() {
        mixpanel.track(Event.ChangeSubscriptionAction);
    }
    static NewSubscriptionAction() {
        mixpanel.track(Event.NewSubscriptionAction);
    }
    static LoginScreen() {
        mixpanel.track(Event.LoginScreen);
    }
    static LoginAction() {
        mixpanel.track(Event.LoginAction);
    }
    static OB_6EmployeeSuccessScreen() {
        mixpanel.track(Event.OB_6EmployeeSuccessScreen);
    }
    static PR_0SampleAction() {
        mixpanel.track(Event.PR_0SampleAction);
    }
    static OB_6LaterPayslipAction() {
        mixpanel.track(Event.OB_6LaterPayslipAction);
    }
    static PR_1InitiatePayrollScreen() {
        mixpanel.track(Event.PR_1InitiatePayrollScreen);
    }
    static PR_1InitiatePayrollAction() {
        mixpanel.track(Event.PR_1InitiatePayrollAction);
    }
    static PR_2ReviewPayrollAction() {
        mixpanel.track(Event.PR_2ReviewPayrollAction);
    }
    static PR_2ReviewPayrollScreen() {
        mixpanel.track(Event.PR_2ReviewPayrollScreen);
    }
    static OB_7CompleteBusinessInfoAction() {
        mixpanel.track(Event.OB_7CompleteBusinessInfoAction);
    }
    static OB_7ReviewDraftPayslipScreen() {
        mixpanel.track(Event.OB_7ReviewDraftPayslipScreen);
    }
    static OnboardActionWC_1() {
        mixpanel.track(Event.OnboardActionWC_1);
    }
    static OnboardActionWC_2() {
        mixpanel.track(Event.OnboardActionWC_2);
    }
    static OnboardActionWC_3() {
        mixpanel.track(Event.OnboardActionWC_3);
    }
    static OnboardActionWC_4() {
        mixpanel.track(Event.OnboardActionWC_4);
    }
    static OnboardActionWC_5() {
        mixpanel.track(Event.OnboardActionWC_5);
    }
    static OnboardActionWC_6() {
        mixpanel.track(Event.OnboardActionWC_6);
    }



    // onbord
    static updateProfileNameAction(businessData, id) {
        // mixpanel.identify(id);
        mixpanel.people.set({
            $name: businessData.businessName ? businessData.businessName : "",
            $email: businessData.email ? businessData.email : "",
            $phone: businessData.phone ? businessData.phone : "",
            $first_name: businessData.firstName ? businessData.firstName : "",
            $last_name: businessData.lastName ? businessData.lastName : "",
            $title: businessData.title ? businessData.title : "",
            $relationship: businessData.relationship ? businessData.relationship : "",
        });
    }

    static OB_1BusinessNameScreen() {
        mixpanel.track(Event.OB_1BusinessNameScreen);
    }
    static OB_1BusinessNameAction() {
        mixpanel.track(Event.OB_1BusinessNameAction);
    }

    static OB_2EmailScreen() {
        mixpanel.track(Event.OB_2EmailScreen);
    }
    static OB_2EmailAction() {
        mixpanel.track(Event.OB_2EmailAction);
    }

    static OB_3PasswordScreen() {
        mixpanel.track(Event.OB_3PasswordScreen);
    }
    static AccountSignupAction() {
        mixpanel.track(Event.AccountSignupAction);
        mixpanel.people.set({
            $AccountSignupDate: moment().toISOString(),
            $Signup_Platform: 'web',
            $subscription_status: 'Trialing',
            $subscription_type: 'Trial'
        });
    }

    static MAAAccountSignupAction() {
        mixpanel.track(Event.MAAAccountSignupAction);
        mixpanel.people.set({
            $AccountSignupDate: moment().toISOString(),
            $Signup_Platform: 'web',
            $subscription_status: 'Trialing',
            $subscription_type: 'Trial'
        });
    }

    static OB_5EmployerSuccessScreen() {
        mixpanel.track(Event.OB_5EmployerSuccessScreen);
    }
    static OB_5LaterEmployeeAction() {
        mixpanel.track(Event.OB_5LaterEmployeeAction);
    }


    static OB_4ContactAScreen() {
        mixpanel.track(Event.OB_4ContactAScreen);
    }
    static OB_4ContactAAction() {
        mixpanel.track(Event.OB_4ContactAAction);
    }

    static OB_RoleScreen() {
        mixpanel.track(Event.OB_RoleScreen);
    }

    static OB_0Start() {
        mixpanel.track("OB_0Start.Action");
    }

    static OB_RoleAction() {
        mixpanel.track(Event.OB_RoleAction);
    }

    static OB_5AccountantSuccessScreen() {
        mixpanel.track(Event.OB_5AccountantSuccessScreen);
    }

    static OB_5AccountantSuccessAction() {
        mixpanel.track(Event.OB_5AccountantSuccessAction);
    }
    static PR_3ReviewPayslipScreen() {
        mixpanel.track(Event.PR_3ReviewPayslipScreen);
    }
    static PR_3SendPayslipAction() {
        mixpanel.track(Event.PR_3SendPayslipAction);
    }
    static PR_DiscardPayslipAction() {
        mixpanel.track(Event.PR_DiscardPayslipAction);
    }

    static OB_8ABNWPNScreen() {
        console.log('mixpanel tracking');
        mixpanel.track(Event.OB_8ABNWPNScreen);
    }

    static OB_8ABNWPNAction() {
        mixpanel.track(Event.OB_8ABNWPNAction);
    }

    static OB_9ContactBScreen() {
        console.log('mixpanel tracking222');
        mixpanel.track(Event.OB_9ContactBScreen);
    }

    static OB_9ContactBAction() {
        mixpanel.track(Event.OB_9ContactBAction);
    }

    static OB_10STPContactScreen() {
        mixpanel.track(Event.OB_10STPContactScreen);
    }



}


export default Analytics;
