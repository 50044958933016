import * as actionTypes from "../constants/actionTypes";
import localStorageApi from "../api/LocalStorageApi";
import * as common from "../constants/common";

// get all List Linked Employer Accounts
export function getListLinkedEmployerAccounts() {

	return {
		type: actionTypes.ACTION_LIST_LINKED_EMPLOYER_ACCOUNTS,
		payload: {}
	};
}

export function getListLinkedEmployerAccountsSuccess(employersAccountant, messageCode, message) {

	return {
		type: actionTypes.ACTION_LIST_LINKED_EMPLOYER_ACCOUNTS_SUCCESS,
		payload: {
			employersAccountant: employersAccountant,
			messageCode,
			message
		}
	};
}

export function getListLinkedEmployerAccountsFailure(messageCode, errorMessage) {

	return {
		type: actionTypes.ACTION_LIST_LINKED_EMPLOYER_ACCOUNTS_FAILURE,
		error: true,
		payload: {
			messageCode,
			errorMessage
		}
	};
}


export function inviteEmployer(employerId) {
	return {
		type: actionTypes.ACTION_ACCOUNTANT_INVITE_EMPLOYER,
		payload: {
			isLoading: true,
			employerId,
		}
	};
}

export function inviteEmployerSuccess(message) {
	return {
		type: actionTypes.ACTION_ACCOUNTANT_INVITE_EMPLOYER_SUCCESS,
		error: false,
		isLoading: false,
		payload: {
			message
		}
	};
}

export function inviteEmployerFailure(message) {
	return {
		type: actionTypes.ACTION_ACCOUNTANT_INVITE_EMPLOYER_FAILURE,
		error: true,
		isLoading: false,
		payload: {
			message
		}
	};
}


export function createEmployer(employerData, history) {
	return {
		type: actionTypes.ACTION_ACCOUNTANT_CREATE_EMPLOYER,
		payload: {
			isLoading: true,
			employerData,
			history
		}
	};
}

export function createEmployerSuccess(employerData, message) {
	return {
		type: actionTypes.ACTION_ACCOUNTANT_CREATE_EMPLOYER_SUCCESS,
		payload: {
			employerData,
			message
		}
	};
}

export function createEmployerFailure(message) {
	return {
		type: actionTypes.ACTION_ACCOUNTANT_CREATE_EMPLOYER_FAILURE,
		error: true,
		payload: {
			message
		}
	};
}

// revoke accountant
export function linkedEmployerAccountsRevoke(employerId) {
	return {
		type: actionTypes.ACTION_LIST_LINKED_EMPLOYER_ACCOUNTS_REVOKE,
		payload: {
			employerId
		}
	};
}

export function linkedEmployerAccountsRevokeSuccess(messageCode, message) {
	return {
		type: actionTypes.ACTION_LIST_LINKED_EMPLOYER_ACCOUNTS_REVOKE_SUCCESS,
		payload: {
			messageCode,
			message
		}
	};
}

export function linkedEmployerAccountsRevokeFailure(messageCode, errorMessage) {
	return {
		type: actionTypes.ACTION_LIST_LINKED_EMPLOYER_ACCOUNTS_REVOKE_FAILURE,
		error: true,
		payload: {
			messageCode,
			errorMessage
		}
	};
}

// Login As Linked Employer
export function loginAsLinkedEmployer(employerId, history) {
	return {
		type: actionTypes.ACTION_LOGIN_AS_LINKED_EMPLOYER,
		payload: {
			employerId,
			history
		}
	};
}

export function loginAsLinkedEmployerSuccess(employerId, messageCode, message,history) {

	return {
		type: actionTypes.ACTION_LOGIN_AS_LINKED_EMPLOYER_SUCCESS,
		payload: {
			employerId,
			messageCode,
			message,
			isAccountant: false,
			isEmployee: true,
			history
		}
	};
}

export function loginAsLinkedEmployerFailure(messageCode, errorMessage) {

	return {
		type: actionTypes.ACTION_LOGIN_AS_LINKED_EMPLOYER_FAILURE,
		error: true,
		payload: {
			messageCode,
			errorMessage
		}
	};
}

// cleanMessage
export function cleanMessage() {

	return {
		type: actionTypes.ACTION_LIST_LINKED_EMPLOYER_ACCOUNTS_MESSAGE,
		payload: {
			errorMessage: null,
			messageCode: null,
			message: null,
			isAccountant: true,
			isEmployee: false,
			isEmployeeLinkedAccount: false
		}
	};
}
