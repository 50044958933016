import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
	Grid, Paper, TextField, Typography, FormHelperText
} from "@material-ui/core";
import clsx from "clsx";
import { getIn } from "formik";
import EmployerStyles from "./employerStyle";

import { CardCommonComponent } from "../common";

class EmployerAuthorisedPersonComponent extends Component {
	render() {
		const { classes, formProps } = this.props;

		return (
			<CardCommonComponent title="Authorised person for STP lodgement" complete={!getIn(formProps.errors, "company.authorizedPerson")}>
				<Typography variant="body1" gutterBottom>
					{"The person noted below is responsible for lodging STP files on behalf of the business"}
				</Typography>
				<Grid container direction="row" alignItems="flex-start">
					<Grid item xs={4}>
						<Paper className={classes.paperGrid}>
							<TextField
								id="company.authorizedPerson.firstName"
								label="First name *"
								variant="filled"
								value={formProps.values.company.authorizedPerson.firstName}
								onChange={formProps.handleChange}
								onBlur={formProps.handleBlur}
								className={clsx(classes.textField, {
									error: getIn(formProps.errors, "company.authorizedPerson.firstName") && getIn(formProps.touched, "company.authorizedPerson.firstName")
								})}
								fullWidth
							/>
							{getIn(formProps.errors, "company.authorizedPerson.firstName") && getIn(formProps.touched, "company.authorizedPerson.firstName") && <FormHelperText error>{formProps.errors.company.authorizedPerson.firstName}</FormHelperText>}
						</Paper>
					</Grid>
					<Grid item xs={4}>
						<Paper className={classes.paperGrid}>
							<TextField id="company.authorizedPerson.middleName" label="Middle name (If applicable)" className={classes.textField} variant="filled" value={formProps.values.company.authorizedPerson.middleName} onChange={formProps.handleChange} onBlur={formProps.handleBlur} fullWidth />
						</Paper>
					</Grid>
					<Grid item xs={4}>
						<Paper className={classes.paperGrid}>
							<TextField
								id="company.authorizedPerson.lastName"
								label="Last name *"
								variant="filled"
								value={formProps.values.company.authorizedPerson.lastName}
								onChange={formProps.handleChange}
								onBlur={formProps.handleBlur}
								className={clsx(classes.textField, {
									error: getIn(formProps.errors, "company.authorizedPerson.lastName") && getIn(formProps.touched, "company.authorizedPerson.lastName")
								})}
								fullWidth
							/>
							{getIn(formProps.errors, "company.authorizedPerson.lastName") && getIn(formProps.touched, "company.authorizedPerson.lastName") && <FormHelperText error>{formProps.errors.company.authorizedPerson.lastName}</FormHelperText>}
						</Paper>
					</Grid>
				</Grid>
			</CardCommonComponent>
		);
	}
}

export default withStyles(EmployerStyles)(EmployerAuthorisedPersonComponent);
