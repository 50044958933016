import * as actionTypes from "../../constants/actionTypes";
import accountantInitialState from "./AccountantInitialState";

const AdditionalUserAccountantReducer = (state = accountantInitialState, action) => {
	switch (action.type) {
		// GENERATE
		case actionTypes.ACTION_INVITE_ADDITIONAL_USER: {
			
			return {
				...state,
				isLoading: true,
				isSaving: true,
			};
		}
		case actionTypes.ACTION_INVITE_ADDITIONAL_USER_SUCCESS: {
			
			return {
				...state,
				isSaving: false,
				messageCode: action.payload.messageCode,
				message: action.payload.message
			};
		}
		case actionTypes.ACTION_INVITE_ADDITIONAL_USER_FAILURE: {
			
			return {
				...state,
				error: true,
				isSaving: false,
				messageCode: action.payload.messageCode,
				message: action.payload.errorMessage,
			};
		}
	
		// clean massage
		case actionTypes.ACTION_INVITE_ADDITIONAL_USER_CLEAN_MESSAGE: {
			return {
				...state,
				error: false,
				isLoading: false,
				errorMessage: action.payload.errorMessage,
				messageCode: action.payload.messageCode,
				message: action.payload.message
			};
		}

		// get all additional user
		case actionTypes.ACTION_INVITE_ADDITIONAL_USER_DATA_REQUEST: {
			
			return {
				...state,
				isLoading: true
			};
		}
		case actionTypes.ACTION_INVITE_ADDITIONAL_USER_DATA_REQUEST_SUCCESS: {
			
			return {
				...state,
				isLoading: false,
				accountants: action.payload.accountants
			};
		}
		case actionTypes.ACTION_INVITE_ADDITIONAL_USER_DATA_REQUEST_FAILURE: {
			
			return {
				...state,
				error: true,
				isLoading: false,
				errorMessage: action.payload.errorMessage,
				messageCode: action.payload.messageCode,
			};
		}

		// revoke additional user
		case actionTypes.ACTION_ADDITIONAL_USER_REVOKE: {
			
			return {
				...state,
				isLoading: true
			};
		}
		case actionTypes.ACTION_ADDITIONAL_USER_REVOKET_SUCCESS: {
			
			return {
				...state,
				isLoading: false,
				message: action.payload.message,
				messageCode: action.payload.messageCode,
			};
		}
		case actionTypes.ACTION_ADDITIONAL_USER_REVOKE_FAILURE: {
			
			return {
				...state,
				error: true,
				isLoading: false,
				errorMessage: action.payload.errorMessage,
				messageCode: action.payload.messageCode,
			};
		}
		
		default: {
			return {
				...state
			};
		}

	}
};

export default AdditionalUserAccountantReducer;
