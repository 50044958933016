import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
	Grid, Paper, TextField, Typography
} from "@material-ui/core";
import * as Yup from "yup";
import { Formik, getIn } from "formik";
import clsx from "clsx";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { DialogCommonComponent } from "../common";
import paymentStyle from "./paymentStyle";

class ModalAuthorisedPerson extends Component {
	validationSchema = Yup.object({
		name: Yup.string().required("Name is required"),
		lastName: Yup.string().required("Last Name is required")
	});

	handleSubmit = (values, actions) => {
		const { onPrimaryButtonClick, formPropsParent } = this.props;
		actions.setSubmitting(false);
		if (onPrimaryButtonClick) {
			onPrimaryButtonClick(formPropsParent, values.name, values.lastName);
		}
	};

	renderForm = (formProps) => {
		const {
			classes, open, onCloseModal, onSecondaryButtonClick, isLoading
		} = this.props;

		return (
			<DialogCommonComponent
				open={open}
				title="Enter authorised person"
				size="sm"
				primaryButtonName="Continue"
				onPrimaryButtonClick={formProps.handleSubmit}
				primaryButtonDisabled={!formProps.isValid || isLoading}
				secondaryButtonName="Cancel"
				onSecondaryButtonClick={onSecondaryButtonClick || onCloseModal}
				onCloseModal={onCloseModal}
			>
				<Grid container direction="row" alignItems="flex-start">
					<Typography variant="body1" gutterBottom>
						This name will be populated in the Payment Summary for Lodgment with the ATO
					</Typography>
					<Grid item xs={6}>
						<Paper className={classes.paperGrid}>
							<TextField
								id="name"
								label="Name"
								variant="filled"
								value={formProps.values.name}
								onChange={formProps.handleChange}
								onBlur={formProps.handleBlur}
								className={clsx(classes.textField, {
									error: getIn(formProps.errors, "name") && getIn(formProps.touched, "name")
								})}
								fullWidth
							/>
						</Paper>
					</Grid>
					<Grid item xs={6}>
						<Paper className={classes.paperGrid}>
							<TextField
								id="lastName"
								label="Last name"
								variant="filled"
								value={formProps.values.lastName}
								onChange={formProps.handleChange}
								onBlur={formProps.handleBlur}
								className={clsx(classes.textField, {
									error: getIn(formProps.errors, "lastName") && getIn(formProps.touched, "lastName")
								})}
								fullWidth
							/>
						</Paper>
					</Grid>
				</Grid>
			</DialogCommonComponent>
		);
	};

	render() {
		return <Formik initialValues={{ name: "", lastName: "" }} validationSchema={this.validationSchema} onSubmit={this.handleSubmit} render={this.renderForm} enableReinitialize="true" />;
	}
}

const mapStateToProps = state => ({
	isLoading: state.employees.isLoading || state.payment.isLoading
});

export default connect(
	mapStateToProps,
	null
)(withRouter(withStyles(paymentStyle)(ModalAuthorisedPerson)));
