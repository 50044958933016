import initialState from "../../store/initialState";

export const employerDefaultData = {
	company: {
		abn: {
			entityType: {
				code: "",
				description: ""
			},
			name: "",
			number: "",
			tradingName: "",
			userTradingName: ""
		},
		wpn: {
			name: "",
			number: ""
		},
		address: {
			addressLine1: "",
			addressLine2: "",
			country: "",
			postCode: "",
			townCity: "",
			state: "Australian Capital Territory"
		},
		authorizedPerson: {
			email: "",
			firstName: "",
			middleName: "",
			lastName: "",
			fullName: "",
			phoneNumber: "",
			name: ""
		}
	},
	contact: {
		email: "",
		firstName: "",
		middleName: "",
		lastName: "",
		fullName: "",
		phoneNumber: ""
	}
};

const employerInitialState = {
	...initialState,
	manualSetupKey: "",
	qrCodeImage: "",
	isValidValidate: false,
	isValidRevoke: false,
	isValidDisabled: false,
	employerData: {
		...employerDefaultData
	},
	abnInfo: null,
	wpnInfo: null,
	redirectToYourBusiness:false
};

export default employerInitialState;
