import { createLogic } from "redux-logic";


import * as actionTypes from "../constants/actionTypes";
import * as apiResponses from "../constants/apiResponses";
import couponApi from '../api/SubscriptionApi';
import * as couponActions from "../actions/CouponActions";

// Send Coupon
const SendCoupon = createLogic({
    type: actionTypes.ACTION_SEND_COUPON,
    latest: true,
    async process({ httpClient, action }, dispatch, done) {
        try {
            const response = await couponApi.addCoupon(httpClient, action.payload.coupon);
            if (response.status === apiResponses.RESPONSE_OK_STATUS) {
                dispatch(couponActions.couponRequestSuccess(response.status, response.data.message, response.data.item))
            } else {
                dispatch(couponActions.couponRequestFailure(response.status, response.data.message))
            }
        } catch (err) {
            dispatch(couponActions.couponRequestFailure(err.response.status, err.response.data.message))

        }
        done();
    }
})

// get Coupon
const getCoupon = createLogic({
    type: actionTypes.ACTION_GET_COUPON,
    latest: true,
    async process({ httpClient, action }, dispatch, done) {
        try {
            const response = await couponApi.getCoupon(httpClient, action.payload.coupon);
            if (response.status === apiResponses.RESPONSE_OK_STATUS) {
                dispatch(couponActions.getCouponSuccess(response.status, response.data.message, response.data.item))
            } else {
                dispatch(couponActions.getCouponFailure(response.status, response.data.message))
            }
        } catch (err) {
            dispatch(couponActions.getCouponFailure(err.response.status, err.response.data.message))

        }
        done();
    }
})

export default [SendCoupon, getCoupon];
