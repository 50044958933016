import { createLogic } from "redux-logic";

import * as actionTypes from "../constants/actionTypes";
import * as apiResponses from "../constants/apiResponses";
import * as AdditionalUserActions from "../actions/AdditionalUserActions";
import AdditionalUserApi from "../api/AdditionalUserApi";

// invite Add itionalUser
const inviteAdditionalUser = createLogic({
	type: actionTypes.ACTION_INVITE_ADDITIONAL_USER,
	latest: true,
	async process({ httpClient, action }, dispatch, done) {
		try {

			const response = await AdditionalUserApi.inviteAdditionalUser(httpClient, action.payload.email);

			if (response.status === apiResponses.RESPONSE_OK_STATUS) {

				dispatch(AdditionalUserActions.inviteAdditionalUserSuccess(response.status, response.data.message));
				dispatch(AdditionalUserActions.getInviteAdditionalUserAllData());
			} else {

				dispatch(AdditionalUserActions.inviteAdditionalUserFailure(response.status, response.data.message));
			}
		} catch (err) {

			dispatch(AdditionalUserActions.inviteAdditionalUserFailure(err.response.status, err.response.data.message));
		}
		done();
	}
});

// get Additional User
const getInviteAdditionalUserAllData = createLogic({
	type: actionTypes.ACTION_INVITE_ADDITIONAL_USER_DATA_REQUEST,
	latest: true,
	async process({ httpClient, action }, dispatch, done) {
		try {

			const response = await AdditionalUserApi.getInviteAdditionalUser(httpClient);

			if (response.status === apiResponses.RESPONSE_OK_STATUS) {

				dispatch(AdditionalUserActions.getInviteAdditionalUserAllDataSuccess(response.data.accountants));
			} else {

				dispatch(AdditionalUserActions.getInviteAdditionalUserAllDataFailure(response.messageCode, response.message));
			}
		} catch (err) {

			dispatch(AdditionalUserActions.getInviteAdditionalUserAllDataFailure("", err.message));
		}
		done();
	}
});

// Revoke Additional User
const AdditionalUserRevoke = createLogic({
	type: actionTypes.ACTION_ADDITIONAL_USER_REVOKE,
	latest: true,
	async process({ httpClient, action }, dispatch, done) {
		try {

			const response = await AdditionalUserApi.AdditionalUserRevoke(httpClient, action.payload.accountantId);

			if (response.status === apiResponses.RESPONSE_OK_STATUS) {

				dispatch(AdditionalUserActions.AdditionalUserRevokeSuccess(response.status, response.data.message));
				dispatch(AdditionalUserActions.getInviteAdditionalUserAllData());
			} else {

				dispatch(AdditionalUserActions.AdditionalUserRevokeFailure(response.status, response.data.message));
			}
		} catch (err) {

			dispatch(AdditionalUserActions.AdditionalUserRevokeFailure(err.response.status, err.message));
		}
		done();
	}
});

export default [inviteAdditionalUser, getInviteAdditionalUserAllData, AdditionalUserRevoke];
