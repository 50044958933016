import initialState from "../../store/initialState";

const sessionInitialState = {
	...initialState,
	userData: null,
	email: null,
	token: null,
	enable2FA: false
};

export default sessionInitialState;
