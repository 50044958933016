const processReport = async (httpClient, requestData) => {
	const { goEpsApi } = window.envVariables;
	let response;
	if (requestData.typeReport == "PayrollActivity") {
		response = await httpClient.post(`${goEpsApi}/api/v1/reports/pasr`, requestData.payrollActivity);
	} else {
		response = await httpClient.post(`${goEpsApi}/api/v1/reports/padr`, requestData.payrollActivityDetail);
	}
	return response;
};

const processReportPayrollActivityDetail = async (httpClient, requestData) => {

	const { goEpsApi } = window.envVariables;
	const response = await httpClient.post(`${goEpsApi}/api/v1/reports/padr`, requestData.payrollActivityDetail);

	return response;
};

export default {
	processReport,
	processReportPayrollActivityDetail
};
