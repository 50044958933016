import React, { Component } from "react";
import PropTypes from "prop-types";

import { getIn } from "formik";

import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import {
	Grid, Typography, Paper, TextField, InputAdornment
} from "@material-ui/core";

import PayrollStyle from "./payrollStyle";
import { NumberFormatCustomComponent } from "../common";

class DirectorsFeesComponent extends Component {
	handleChangeBase = (name, event, formProps) => {
		let directorsFees=formProps.values.payroll.directorsFees;

		if(Object.entries(directorsFees).length === 0 && directorsFees.constructor === Object){

			directorsFees={
				description: "",
				amount: ""
			}
		}
		switch (event.target.id) {
			case "directorsFeesAmount":
				directorsFees.amount = event.target.value
				break;
			case "directorsFeesDescription":
				directorsFees.description = event.target.value
				break;
			default:
				break;
		}


		formProps.setFieldValue("payroll.directorsFees", directorsFees);
	};

	render() {
		const { classes, formProps } = this.props;

		let directorsFees = formProps.values.payroll.directorsFees;

		return (
			<Grid className={classes.gridContainer}>
				<Typography variant="h4" gutterBottom>
					{"Directors Fees"}
				</Typography>

				<Grid container direction="row" alignItems="flex-start">
					<Grid item xs={4}>
						<Paper className={classes.paperGrid}>
							<TextField
								id={`directorsFeesAmount`}
								label="Amount"
								variant="filled"
								InputProps={{
									startAdornment: <InputAdornment position="start">$</InputAdornment>,
									inputComponent: NumberFormatCustomComponent,
									inputProps: {
										decimalScale: 2,
										fixedDecimalScale: true
									}
								}}
								value={directorsFees ? directorsFees.amount : ""}
								onChange={(event) => {
									this.handleChangeBase(`directorsFeesAmount`, event, formProps);
								}}
								onBlur={formProps.handleBlur}
								className={clsx(classes.textField, {
									error: getIn(formProps.errors, `directorsFeesAmount`) && getIn(formProps.touched, `directorsFeesAmount`)
								})}
								fullWidth
							/>
						</Paper>
					</Grid>

					<Grid item xs={4}>
						<Paper className={classes.paperGrid}>
							<TextField
								id={`directorsFeesDescription`}
								label="Description"
								variant="filled"
								fullWidth
								value={ directorsFees ? directorsFees.description : ""}
								onChange={(value) => {
									this.handleChangeBase(`directorsFeesDescription`, value, formProps);
								}}
								onBlur={formProps.handleBlur}
								className={clsx(classes.textField, {
									error: getIn(formProps.errors, `directorsFeesDescription`) && getIn(formProps.touched, `directorsFeesDescription`)
								})}
							/>
						</Paper>
					</Grid>
				</Grid>
			</Grid>
		);
	}
}

DirectorsFeesComponent.propTypes = {
	formProps: PropTypes.object.isRequired
};

export default withStyles(PayrollStyle)(DirectorsFeesComponent);
