import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { DialogModalStyles } from "./commonStyles";
import DialogCommonComponent from "./dialogCommonComponent";

class DialogConfirmCommonComponent extends Component {
  render() {
    const {
      title,
      children,
      primaryButtonName,
      onPrimaryButtonClick,
      style,
      style2,
      styleTitle,
      secondaryButtonName,
      onSecondaryButtonClick,
      open,
      onCloseModal,
      primaryButtonDisabled
    } = this.props;

    return (
      <DialogCommonComponent
        size="xs"
        title={title}
        primaryButtonName={primaryButtonName}
        onPrimaryButtonClick={onPrimaryButtonClick}
        secondaryButtonName={secondaryButtonName || "Cancel"}
        onSecondaryButtonClick={onSecondaryButtonClick || onCloseModal}
        open={open}
        style={style}
        styleTitle={styleTitle}
        style2={style2}
        onCloseModal={onCloseModal}
        primaryButtonDisabled={primaryButtonDisabled}
      >
        {children}
      </DialogCommonComponent>
    );
  }
}

export default withStyles(DialogModalStyles)(DialogConfirmCommonComponent);
