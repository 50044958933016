import * as actionTypes from "../../constants/actionTypes";
import EmployerAccountantState from "./EmployerAccountantState";

const EmployerAccountantReducer = (state = EmployerAccountantState, action) => {
	switch (action.type) {

		case actionTypes.ACTION_ACCOUNTANT_INVITE_EMPLOYER: {
			return {
				...state,
				isLoading: true
			}
		}

		case actionTypes.ACTION_ACCOUNTANT_INVITE_EMPLOYER_FAILURE: {
			return {
				...state,
				isLoading: false,
				error: true,
				messageCode: 400,
				errorMessage: action.payload.message
			}
		}

		case actionTypes.ACTION_ACCOUNTANT_INVITE_EMPLOYER_SUCCESS: {
			return {
				...state,
				isLoading: false,
				error: false,
				messageCode: 200,
				message: action.payload.message
			}
		}

		case actionTypes.ACTION_ACCOUNTANT_CREATE_EMPLOYER: {
			return {
				...state,
				isLoading: true
			}
		}

		case actionTypes.ACTION_ACCOUNTANT_CREATE_EMPLOYER_FAILURE: {
			return {
				...state,
				isLoading: false,
				error: true,
				messageCode: 400,
				message: action.payload.message
			}
		}

		case actionTypes.ACTION_ACCOUNTANT_CREATE_EMPLOYER_SUCCESS: {
			return {
				...state,
				isLoading: false,
				error: false,
				messageCode: 200,
				message: action.payload.message
			}
		}

		// Get List Linked Employer Accounts
		case actionTypes.ACTION_LIST_LINKED_EMPLOYER_ACCOUNTS: {

			return {
				...state,
				isLoading: true
			};
		}
		case actionTypes.ACTION_LIST_LINKED_EMPLOYER_ACCOUNTS_SUCCESS: {

			return {
				...state,
				isLoading: false,
				isEmployee: false,
				isAccountant: true,
				employersAccountant: action.payload.employersAccountant,
				message: action.payload.sucessMessage,
				messageCode: action.payload.messageCode,
			};
		}
		case actionTypes.ACTION_LIST_LINKED_EMPLOYER_ACCOUNTS_FAILURE: {

			return {
				...state,
				error: true,
				isLoading: false,
				errorMessage: action.payload.errorMessage,
				messageCode: action.payload.messageCode,
			};
		}

		// revoke List Linked Employer Accounts
		case actionTypes.ACTION_LIST_LINKED_EMPLOYER_ACCOUNTS_REVOKE: {

			return {
				...state,
				isLoading: true
			};
		}
		case actionTypes.ACTION_LIST_LINKED_EMPLOYER_ACCOUNTS_REVOKE_SUCCESS: {

			return {
				...state,
				isLoading: false,
				message: action.payload.message,
				messageCode: action.payload.messageCode,
			};
		}
		case actionTypes.ACTION_LIST_LINKED_EMPLOYER_ACCOUNTS_REVOKE_FAILURE: {

			return {
				...state,
				error: true,
				isLoading: false,
				errorMessage: action.payload.errorMessage,
				messageCode: action.payload.messageCode,
			};
		}

		// Login As Linked Employer
		case actionTypes.ACTION_LOGIN_AS_LINKED_EMPLOYER: {

			return {
				...state,
				isLoading: true
			};
		}
		case actionTypes.ACTION_LOGIN_AS_LINKED_EMPLOYER_SUCCESS: {

			return {
				...state,
				isLoading: false,
				isAccountant: action.payload.isAccountant,
				isEmployee: action.payload.isEmployee,
				employersAccountant: action.payload.employersAccountant,
				message: action.payload.sucessMessage,
				messageCode: action.payload.messageCode,
			};
		}
		case actionTypes.ACTION_LOGIN_AS_LINKED_EMPLOYER_FAILURE: {

			return {
				...state,
				error: true,
				isLoading: false,
				errorMessage: action.payload.errorMessage,
				messageCode: action.payload.messageCode,
			};
		}


		// clean Message
		case actionTypes.ACTION_LIST_LINKED_EMPLOYER_ACCOUNTS_MESSAGE: {
			return {
				...state,
				error: false,
				isLoading: false,
				errorMessage: action.payload.errorMessage,
				messageCode: action.payload.messageCode,
				message: action.payload.message,
				isAccountant: action.payload.isAccountant,
				isEmployee: action.payload.isEmployee,
				// employersAccountant: {
				// 	...state.employersAccountant,
				// 	isEmployee: false
				// }
			};
		}


		default: {
			return {
				...state
			};
		}

	}
};

export default EmployerAccountantReducer;
