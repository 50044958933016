import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";
import { SnackbarCommonComponent, LoadingComponent } from "../components/common";
import { bindActionCreators } from "redux";
import { mapMessageCodeToIcon } from "../helpers/extensions";
import QuickSetupSuccessComponent from "../components/Session/QuickSetupSuccessComponent";
import { ExternaUserStyles } from "./PagesStyle";
import { cleanMessageOnboard } from "../actions/OnboardActions";

class QuickSetupSuccessPage extends Component {
	render() {
		const { classes, mixpanel, isLoading, messageCode, message, cleanMessageInternal } = this.props;

		return (
			<div className={classes.root}>
				<CssBaseline />
				<QuickSetupSuccessComponent mixpanel={mixpanel} />
			</div>
		);
	}
}

QuickSetupSuccessPage.propTypes = {
	classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	isLoading: state.onboard.isLoading,
	messageCode: state.onboard.messageCode,
	message: state.onboard.message
});

const mapDispatchToProps = dispatch => ({
	cleanMessageInternal: bindActionCreators(cleanMessageOnboard, dispatch)
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(ExternaUserStyles)(QuickSetupSuccessPage));