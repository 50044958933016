import React, { Component } from "react";
import PropTypes from "prop-types";


import { getIn } from "formik";
import { withStyles } from "@material-ui/core/styles";
import {
	Button, Grid, Table, TableHead, TableBody, TableCell, TableRow, Typography,
} from "@material-ui/core";

import { MenuItemComponent, SnackbarCommonComponent, NumberFormatCustomComponent } from "../common";

import PayrollStyle from "./payrollStyle";
import ModalAddBaseHourlyRate from "./modalAddBaseHourlyRate";
import { payrollAdditionalDefaultData } from "../../reducers/Payroll/PayrollInitialState";
import * as messages from "../../constants/messages";
import EditAdditionalBaseHourlyRateComponent from "./editAdditionalBaseHourlyRateComponent";

class AdditionalBaseHourlyRateComponent extends Component {

	constructor(props) {
		super(props);
		this.addAdditionals(props);
	}
	addAdditionals(props) {
		const { selectedPayrollData } = props;
		const { formProps } = this.props;
		if (selectedPayrollData.employeeActive) {
			const newAdditionals = [];
			if (selectedPayrollData.employeeActive.additionals) {
				selectedPayrollData.employeeActive.additionals.map((value) => {
					newAdditionals.push(this.setValueAdd(value));
				})
			}
			// console.log("Adding new additionals ", newAdditionals)
			this.setState({ "additionals": newAdditionals });
		}
	}

	state = {
		additionals: [],
		check: true,
		showModal: false,
		error: {
			show: false,
			message: ""
		},
		editItem: {
			isEditing: false,
			data: null,
			index: -1
		},
		sajad:true
	};

	handleClickOption = () => {
		this.setState(state => ({
			...state,
			showModal: true,
			error: {
				...state.error,
				show: false
			}
		}));
	};

	handleCloseModal = () => {
		this.setState(state => ({
			...state,
			showModal: false,
			error: {
				...state.error,
				show: false
			},
			editItem: {
				isEditing: false,
				data: null,
				index: -1
			}
		}));
	};

	setValueAdd = (value) => {
		const valueAdd = {
			...payrollAdditionalDefaultData,
			name: value.name,
			description: value.description,
			hours: parseFloat(value.hours).toFixed(2),
			rate: parseFloat(value.rate).toFixed(4),
			totalAmount: parseFloat(value.totalAmount).toFixed(2)
		};

		return valueAdd;
	};

	handleAdd = (value) => {
		const { formProps } = this.props;
		const { additionals } = formProps.values.payroll;
		this.handleShowError(false, "");
		if (!additionals.some(item => value.name === item.name)) {
			const newAdditionals = [...additionals];
			newAdditionals.push(this.setValueAdd(value));
			formProps.setFieldValue("payroll.additionals", newAdditionals);
			this.handleCloseModal();
		} else {
			this.handleShowError(true, messages.MESSAGE_PAYROLL_ADDITIONAL_EXISTS);
		}
	};

	handleUpdate = (value) => {
		const { formProps } = this.props;
		const { editItem } = this.state;
		const { additionals } = formProps.values.payroll;
		const newAdditionals = [...additionals];
		newAdditionals[editItem.index]=this.setValueAdd(value);
		formProps.setFieldValue("payroll.additionals", newAdditionals);
		this.handleCloseModal();
	};

	handleRemoveItem = (event, index) => {
		const { formProps } = this.props;
		const { additionals } = formProps.values.payroll;
		const newAdditionals = [...additionals];
		newAdditionals.splice(index, 1);

		formProps.setFieldValue("payroll.additionals", newAdditionals);
		if (this.state.check)
			this.setState({ check: false })
	};

	handleEditItem = (event, index) => {
		const { formProps } = this.props;
		const { additionals } = formProps.values.payroll;

		this.setState(state => ({
			...state,
			showModal: true,
			editItem: {
				isEditing: true,
				data: additionals[index],
				index
			}
		}));
	};

	handleShowError = (show, message) => {
		this.setState(state => ({
			...state,
			error: {
				show,
				message
			}
		}));
	};

	componentWillReceiveProps(nextProps) {
		const { selectedPayrollData } = nextProps;
		const selectedPayrollDataLast = this.props.selectedPayrollData;
		let check = (selectedPayrollData.employeeActive
			&& selectedPayrollData.employeeActive.additionals
			&& selectedPayrollData.employeeActive.additionals.length > 0);
		if (check) {
			if (selectedPayrollDataLast && selectedPayrollDataLast.employeeActive
				&& selectedPayrollDataLast.employeeActive.additionals
				&& selectedPayrollDataLast.employeeActive.additionals.length > 0) {
				if (selectedPayrollDataLast.employeeActive.additionals.length == selectedPayrollData.employeeActive.additionals.length) {
					check = false;
				}
			}
		}

		// if (check) {
		this.addAdditionals(nextProps);
		// }
	}
	handelChange = (index, name,value) => {
		const { formProps } = this.props;
		const { additionals } = formProps.values.payroll;

		switch (name) {
			case "name":
				additionals[index].description = value;
				break;
			case "hours":
				additionals[index].totalAmount = additionals[index].rate * value
				additionals[index].hours = value
				break;
			case "rate":
				additionals[index].totalAmount = additionals[index].hours * value
				additionals[index].rate = value
				break;
			default:
				break;
		}
		this.props.formProps.setFieldValue("payroll.additionals", additionals);
		this.setState({
			sajad:!this.state.sajad
		})

	}
	render() {
		const { classes, formProps, isFinishAllEmployees } = this.props;
		const { showModal, error, editItem, additionals } = this.state;
		if (this.state.check && additionals && additionals.length > 0 && !(formProps.values.payroll.additionals && formProps.values.payroll.additionals.length > 0)) {
			formProps.setFieldValue("payroll.additionals", additionals)
		}
		const check = (formProps.values.payroll.additionals && formProps.values.payroll.additionals.length > 0);
		return (
			<Grid className={classes.gridContainer}>
				<Grid container direction="row" alignItems="flex-start">
					<Grid item xs={6}>
						<Typography variant="h4" gutterBottom>
							Additional base hourly rate
						</Typography>
					</Grid>
					<Grid item xs={6} className={classes.gridRight}>
						<Button variant="outlined" color="secondary" className={classes.button} onClick={this.handleClickOption} disabled={isFinishAllEmployees}>
							+ Add base hourly rate
						</Button>
						{showModal ? <ModalAddBaseHourlyRate open={showModal} edit={editItem.isEditing} data={editItem.data} onAddValues={editItem.isEditing ? this.handleUpdate : this.handleAdd} onCloseModal={this.handleCloseModal} /> : null}
					</Grid>
				</Grid>

				{!check ? (
					<Typography variant="h4" component="div" className={classes.placeHolder}>
						{"You haven’t added additional base hourly rate"}
					</Typography>
				) : null}
				{check ? (
					<Table className={classes.table} size="medium">
						<TableHead className={classes.tableH}>
							<TableRow>
								<TableCell className={classes.tableTitle}>Description</TableCell>
								<TableCell className={classes.tableTitle}>Hours</TableCell>
								<TableCell className={classes.tableTitle}>Rate</TableCell>
								<TableCell className={classes.tableTitle}>Total</TableCell>
								<TableCell className={classes.tableTitle} align="right" />
							</TableRow>
						</TableHead>
						<TableBody>
							{formProps.values.payroll.additionals && formProps.values.payroll.additionals.length > 0 ? (
								<React.Fragment>
									{formProps.values.payroll.additionals.map((row, index) => (

										<EditAdditionalBaseHourlyRateComponent
											row={row}
											index={index}
											key={row.name}
											parentProps={formProps}
											handelChange={this.handelChange}
											handleRemoveItem={this.handleRemoveItem} />

									))}
								</React.Fragment>
							) : null}
						</TableBody>
					</Table>
				) : null}

				{error && error.show ? <SnackbarCommonComponent variant="error" open={error.show} body={error.message} /> : null}
			</Grid>
		);
	}
}

AdditionalBaseHourlyRateComponent.propTypes = {
	formProps: PropTypes.object.isRequired
};

export default withStyles(PayrollStyle)(AdditionalBaseHourlyRateComponent);
