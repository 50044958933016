import initialState from "../../store/initialState";

const notificationInitialState = {
	...initialState,
	notificationData: {
		emailEmployer: "",
		emailEmployee: "",
		sendEmployer: true,
		sendEmployee: true
	}
};

export default notificationInitialState;
