import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import {
	List, ListItem, ListItemIcon, ListItemText
} from "@material-ui/core";
import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";

import * as icons from "../../assets/images/icons";
import * as routes from "../../constants/routes";
import { cleanSessionApi } from "../../actions/SessionActions";

const styles = theme => ({
	listItemMenu: {
		...theme.mixins.toolbar,
		display: "flex",
		borderRadius: 10,
		height: 50,
		minHeight: 50,
		width: 233,
		"@media (min-width: 600px)": {
			minHeight: 50
		}
	},
	listItemMenuIcon: {
		margin: "0 8px",
		minWidth: "initial"
	},
	listItemMenuImg: {
		width: 40,
		height: 40
	},
	listItemMenuText: {
		padding: "0 9px"
	},
	listItemMenuPrimary: {
		fontFamily: "inherit",
		fontWeight: "normal",
		fontSize: 15,
		lineHeight: 20,
		color: "inherit"
	}
});

class MenuItemComponent extends Component {
	handleLogout = () => {
		const { cleanSessionLogout, history } = this.props;
		cleanSessionLogout();
		history.push(routes.PATH_LOGOUT);
	};

	renderData = (men) => {
		const { classes, logo } = this.props;

		return [
			<ListItemIcon className={classes.listItemMenuIcon} key={`${men.name}_ListItemIcon`}>
				<img src={logo || icons[men.icon]} alt="" className={classes.listItemMenuImg} />
			</ListItemIcon>,
			<ListItemText
				primary={men.name}
				classes={{
					root: clsx(classes.listItemMenuText),
					primary: clsx(classes.listItemMenuPrimary)
				}}
				key={`${men.name}_ListItemText`}
			/>
		];
	};

	renderListItem = (men, index) => {
		const { classes, match } = this.props;

		return (
			<div key={`${men.name}_Div`}>
				{men.url === routes.PATH_LOGOUT ? (
					<ListItem button onClick={this.handleLogout} className={classes.listItemMenu} selected={match.path.includes(men.url)} key={men.name} tabIndex={index}>
						{this.renderData(men)}
					</ListItem>
				) : (
					<ListItem button component={Link} to={men.url} className={classes.listItemMenu} selected={match.path.includes(men.url)} key={men.name} tabIndex={index}>
						{this.renderData(men)}
					</ListItem>
				)}
			</div>
		);
	};

	render() {
		const { items } = this.props;

		const listItems = items.map((men, index) => this.renderListItem(men, index));

		return <List>{listItems}</List>;
	}
}

const mapDispatchToProps = dispatch => ({
	cleanSessionLogout: bindActionCreators(cleanSessionApi, dispatch)
});

export default connect(
	null,
	mapDispatchToProps
)(withRouter(withStyles(styles)(MenuItemComponent)));
